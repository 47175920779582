import React from 'react';
import { mapDetails } from '@mobble/models/src/model/MapDetail';
import { pointToBoundingBox } from '@mobble/models/src/model/MapGeometry';
import { Task } from '@mobble/models/src/model/Task';
import { TaskGeometry } from '@mobble/models/src/model/TaskGeometry';

import { Button } from '@src/components';

import { useTaskMapView } from '@src/screens/Tasks/hooks/useTaskMapView';
import { Box } from '@src/stories/Components/Layout/Box';
import { Map } from '@src/stories/Map';
import { makeMapItemTask } from '../../Map/Items/helper';

import styles from './taskMap.scss';

export interface PaddockMapProps {
  userId: string;
  task: Task;
  taskGeometries: TaskGeometry[];
  onMaximise: () => void;
}

export const TaskMap: React.FC<PaddockMapProps> = ({
  userId,
  task,
  taskGeometries,

  onMaximise,
}) => {
  const mapItemTask = makeMapItemTask({
    taskGeometries: taskGeometries,
    userId,
  })({ task });

  const { items } = useTaskMapView();

  if (!mapItemTask?.point) {
    return null;
  }

  const boundingBox = pointToBoundingBox(mapItemTask.point, 0.0075);

  if (!boundingBox) {
    return null;
  }

  const overrideMapProperties = {
    boundingBox,
    items: [...items, mapItemTask],
    mapDetails: [...mapDetails],
  };

  return (
    <Box className={styles.container}>
      <Button
        icon="maximise"
        size="small"
        intent="neutral"
        round
        outline
        onClick={onMaximise}
        className={styles.button}
      />

      <Map
        static
        types="display"
        overrideMapProperties={overrideMapProperties}
      />
    </Box>
  );
};
