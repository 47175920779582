const packageJson = require('../../package.json');

export class VersionSingleton {
  private static instance: VersionSingleton;
  private packageVersion: string = packageJson.version;

  public static getInstance(): VersionSingleton {
    if (!VersionSingleton.instance) {
      VersionSingleton.instance = new VersionSingleton();
    }
    return VersionSingleton.instance;
  }

  public getPackageVersion(): string {
    return this.packageVersion;
  }
}

export const getClientVersion = () => {
  return versionSingleton.getPackageVersion();
};

export const versionSingleton = VersionSingleton.getInstance();
