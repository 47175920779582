import React from 'react';
import { defineMessages } from 'react-intl';
import { useMessages } from '@mobble/i18n';
import { UserRole, type User } from '@mobble/models/src/model/User';
import { Box } from '@src/stories/Components/Layout/Box';
import { Button, ButtonProps } from '@src/stories/Components/UX/Button';
import { useDialog } from '@src/hooks/useDialog';

export interface SettingsPropertyUserOperationsProps {
  user: User;
  loading?: boolean;
  onRemove: () => void;
  onChangeRole: (role: UserRole) => void;
}

export interface userRoleValues {
  userRole: UserRole;
}

const messages = defineMessages({
  'settings.property.users.user.delete.confirm.cancel': {
    description: 'settings.property.users.user.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'settings.property.users.user.delete.confirm.confirm': {
    description: 'settings.property.users.user.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'settings.property.users.user.delete.confirm.description': {
    description: 'settings.property.users.user.delete.confirm.description',
    defaultMessage: 'This will delete the user.',
  },
  'settings.property.users.user.delete.confirm.title': {
    description: 'settings.property.users.user.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'settings.property.users.user.delete.promote_user.admin.button.label': {
    description:
      'settings.property.users.user.delete.promote_user.admin.button.label',
    defaultMessage: 'Promote to Admin',
  },
  'settings.property.users.user.delete.demote_user.user.button.label': {
    description:
      'settings.property.users.user.delete.demote_user.user.button.label',
    defaultMessage: 'Demote to User',
  },
  'settings.property.users.user.delete.promote_user.user.button.label': {
    description:
      'settings.property.users.user.delete.promote_user.user.button.label',
    defaultMessage: 'Promote to User',
  },
  'settings.property.users.user.delete.demote_user.viewOnly.button.label': {
    description:
      'settings.property.users.user.delete.demote_user.viewOnly.button.label',
    defaultMessage: 'Demote to View Only',
  },
  'settings.property.users.user.delete.button.label': {
    description: 'settings.property.users.user.delete.button.label',
    defaultMessage: 'Remove user',
  },
});

export const SettingsPropertyUserOperations: React.FC<
  SettingsPropertyUserOperationsProps
> = ({ user, loading, onRemove, onChangeRole }) => {
  const strings = useMessages(messages);
  const { alert, close } = useDialog();

  const handleConfirmRemove = () => {
    alert(
      strings['settings.property.users.user.delete.confirm.title'],
      strings['settings.property.users.user.delete.confirm.description'],
      [
        {
          label: strings['settings.property.users.user.delete.confirm.cancel'],
          outline: true,
          intent: 'secondary',
          onClick: () => {
            close();
          },
        },
        {
          label: strings['settings.property.users.user.delete.confirm.confirm'],
          type: 'submit',
          intent: 'destructive',
          onClick: () => {
            onRemove();
            close();
          },
        },
      ]
    );
  };

  const buttons = [
    // promote to admin
    user.role === UserRole.User
      ? {
          icon: 'chevrons-up',
          intent: 'secondary',
          loading: loading,
          disabled: loading,
          outline: true,
          label:
            strings[
              'settings.property.users.user.delete.promote_user.admin.button.label'
            ],
          onClick: () => onChangeRole(UserRole.Admin),
        }
      : null,
    // demote to user
    user.role === UserRole.Admin
      ? {
          icon: 'chevron-down',
          intent: 'secondary',
          loading: loading,
          disabled: loading,
          outline: true,
          label:
            strings[
              'settings.property.users.user.delete.demote_user.user.button.label'
            ],
          onClick: () => onChangeRole(UserRole.User),
        }
      : null,
    // promote to user
    user.role === UserRole.ViewOnly
      ? {
          icon: 'chevron-up',
          intent: 'secondary',
          loading: loading,
          disabled: loading,
          outline: true,
          label:
            strings[
              'settings.property.users.user.delete.promote_user.user.button.label'
            ],
          onClick: () => onChangeRole(UserRole.User),
        }
      : null,
    // demote to view only
    user.role === UserRole.User
      ? {
          icon: 'chevron-down',
          intent: 'secondary',
          loading: loading,
          disabled: loading,
          outline: true,
          label:
            strings[
              'settings.property.users.user.delete.demote_user.viewOnly.button.label'
            ],
          onClick: () => onChangeRole(UserRole.ViewOnly),
        }
      : null,
    {
      intent: 'destructive',
      icon: 'trash',
      loading: loading,
      disabled: loading,
      label: strings['settings.property.users.user.delete.button.label'],
      onClick: handleConfirmRemove,
    },
  ].filter(Boolean) as ButtonProps[];

  return (
    <Box spacing={2}>
      {buttons.map((button, i) => (
        <Box key={i} spacing={2} flex>
          <Button flex {...button} />
        </Box>
      ))}
    </Box>
  );
};
