import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  MapAsset,
  MapAssetType,
  mapAssetTypeToGeometryFeatureType,
} from '@mobble/models/src/model/MapAsset';
import { pointsToLineString } from '@mobble/models/src/model/MapGeometry';
import { base64decode } from '@mobble/shared/src/core/String';
import {
  useExtStatus,
  useMapAsset,
  useProperties,
} from '@mobble/store/src/hooks';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  MapAssetCreateForm,
  type MapAssetCreateFormValues,
} from '@src/stories/Views/MapAsset/MapAssetCreateForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';
import { useFormError } from '@src/hooks/useFormError';

export const MapAssetCreateHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      modal
      title={formatMessage({
        defaultMessage: 'Add new map asset',
        description: 'screen.title.map_asset_create',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Map',
            description: 'screen.title.map',
          }),
          href: ROUTE_NAME.MAP_OVERVIEW,
        },
      ]}
      onClose={goBack}
    />
  );
};

export const MapAssetCreate: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const { create } = useMapAsset('_', propertyId);
  const extStatus = useExtStatus('mapAssets', 'create', propertyId);
  const formLoading = extStatus?.loading;
  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Map Asset',
      description: 'screen.title.map_asset_detail',
    }),
    active: Boolean(extStatus?.error),
  });

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (prelude) {
    return prelude;
  }

  const initialValues: Partial<MapAssetCreateFormValues> = {
    points: props.route.params?.points
      ? JSON.parse(base64decode(props.route.params.points))
      : undefined,
    type: props.route.params?.mapAssetType as MapAssetType,
  };

  const handleSubmit = (formValues: MapAssetCreateFormValues) => {
    if (!properties.selected) {
      return Promise.reject();
    }

    const featureType = mapAssetTypeToGeometryFeatureType(
      formValues.type as MapAssetType
    );

    const geometry =
      featureType === 'Point'
        ? formValues.points![0]
        : pointsToLineString(formValues.points ?? []);

    const newMapAsset = {
      propertyId: properties.selected.id,
      name: formValues.name,
      description: formValues.description ?? '',
      map: {
        type: formValues.type as MapAssetType,
        geometry,
      },
    } as Omit<MapAsset, 'id'>;

    create(newMapAsset)
      .then((mapAsset) => {
        // TODO: replace with Toast/Alert
        console.log(`Map Asset '${mapAsset.name}' successfully created`);
        console.log(mapAsset);
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MapAssetCreateForm
      initialValues={initialValues}
      error={formError}
      loading={formLoading}
      onCancel={goBack}
      onTouched={() => {}}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_MAP_ASSET_CREATE,
  header: MapAssetCreateHeader,
  component: MapAssetCreate,
  modal: true,
};
