import { type ApiPrelude } from '../types';
import { CustomMapLayer, CustomMapFeature } from '@mobble/models';

const decodeCustomMapLayer =
  (propertyId: string) =>
  (raw: any): CustomMapLayer => {
    return {
      id: raw?.id || '',
      propertyId,
      name: raw?.name || '',
      description: raw?.description || '',
      properties: raw?.properties || {},
    };
  };

const decodeCustomMapLayers =
  (propertyId: string) =>
  (data: any[]): CustomMapLayer[] => {
    return data.map(decodeCustomMapLayer(propertyId));
  };

const GET_CUSTOM_MAP_LAYERS = `
  query GetCustomMapLayers($propertyId: ID!) {
    customMapLayers(propertyId: $propertyId) {
      nodes {
        id
        name
        description
        properties
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({ parentId }: { parentId: string }): Promise<{ entities: any[] }> => {
    const response = await prelude.graphql({
      query: GET_CUSTOM_MAP_LAYERS,
      variables: { propertyId: parentId },
    });

    const entities = decodeCustomMapLayers(parentId)(
      response?.data?.customMapLayers?.nodes
    );

    await Promise.all(
      entities.map(async (entity) => {
        const { entities: features } = await getFeature(prelude)({
          parentId: `${parentId}_${entity.id}`,
        });

        entity.features = features;
      })
    );

    return { entities };
  };

const EDIT_CUSTOM_MAP_LAYER = `
  mutation EditCustomMapLayers($input: EditCustomMapLayerInput!) {
    editCustomMapLayer(input: $input) {
      customMapLayer {
        id
        propertyId
        name
        description
        properties
      }
    }
  }
`;

export const update =
  (prelude: ApiPrelude) =>
  async ({
    updatedEntity,
  }: {
    updatedEntity: CustomMapLayer;
  }): Promise<CustomMapLayer> => {
    const input = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      name: updatedEntity.name,
      description: updatedEntity.description,
      properties: updatedEntity.properties,
    };

    const response = await prelude.graphql({
      query: EDIT_CUSTOM_MAP_LAYER,
      variables: { input },
    });

    const customMapLayer = decodeCustomMapLayer(updatedEntity.propertyId)(
      response?.data?.editCustomMapLayer?.customMapLayer
    );

    const { entities: features } = await getFeature(prelude)({
      parentId: `${customMapLayer.propertyId}_${customMapLayer.id}`,
    });

    customMapLayer.features = features;

    return customMapLayer;
  };

const decodeCustomMapFeature =
  (propertyId: string) =>
  (raw: any): CustomMapFeature => {
    return {
      id: raw?.featureId || '',
      layerId: raw?.layerId || '',
      propertyId,
      name: raw?.name || '',
      geometryType: raw?.geometryType || '',
      properties: raw?.properties || {},
      geometry: raw?.geometry || {},
    };
  };

const decodeCustomMapFeatures =
  (propertyId: string) =>
  (data: any[]): CustomMapFeature[] => {
    return data.map(decodeCustomMapFeature(propertyId));
  };

const GET_CUSTOM_MAP_FEATURES = `
  query GetCustomMapFeatures($propertyId: ID!, $layerId: ID!) {
    customMapFeatures(propertyId: $propertyId, id: $layerId) {
      nodes {
        featureId
        layerId
        name
        geometryType
        properties
        geometry
      }
    }
  }
`;

export const getFeature =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{ entities: CustomMapFeature[] }> => {
    const [propertyId, layerId] = parentId.split('_');

    const response = await prelude.graphql({
      query: GET_CUSTOM_MAP_FEATURES,
      variables: { propertyId, layerId },
    });

    const entities = decodeCustomMapFeatures(parentId)(
      response?.data?.customMapFeatures?.nodes
    );

    return { entities };
  };
