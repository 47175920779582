import React from 'react';
import { Color } from '@mobble/colors';
import { Icon } from '@mobble/icon';
import styles from './checkbox.scss';

export interface CheckboxProps {
  selected?: boolean;
  disabled?: boolean;
  muted?: boolean;
  size?: 'small' | 'normal';
}

export const Checkbox: React.FC<CheckboxProps> = ({
  selected,
  disabled,
  muted,
  size = 'normal',
}) => {
  const classNames = [
    styles.checkbox,
    styles[`size_${size}`],
    disabled ? styles.disabled : false,
    selected ? styles.selected : false,
    muted ? styles.muted : false,
  ]
    .filter(Boolean)
    .join(' ');
  return (
    <div className={classNames}>
      <Icon name="check-fat" color={Color.White} />
    </div>
  );
};
