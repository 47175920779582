import React, { ChangeEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Color } from '@mobble/colors';
import { type I18nItem } from '@mobble/i18n';
import { useActionQueue } from '@mobble/store/src/hooks/actionQueue';
import { useNetworkIsOnline } from '@mobble/store/src/hooks/network';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { usePendingOverdueTasksCount } from '@mobble/store/src/hooks/tasks';

import { Button } from '@src/components';
import { useTitle } from '@src/hooks/useTitle';
import { Box } from '@src/stories/Components/Layout/Box';
import { ResponsiveMobileOrDesktop } from '@src/stories/Components/Layout/Responsive';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Icon, type IconName } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { ClickableArea } from '@src/stories/Components/UX/ClickableArea';

import styles from './screenHeader.scss';

export interface ScreenHeaderProps {
  breadcrumbs?: { title: I18nItem | string; href: string }[];
  quickLinks?: { title: string; href: string }[];
  title: I18nItem | string;
  modal?: boolean;
  tickForClose?: boolean;
  actions?: ScreenHeaderAction[];
  onClose?: () => void;
  onGoBack?: () => void;
  onToggleDrawer?: () => void;
}

interface ScreenHeaderAction {
  label: string;
  icon: IconName;
  href?: string | (() => void);
}

export const ScreenHeader: React.FC<ScreenHeaderProps> = (props) => {
  useTitle(props.title);

  return (
    <ResponsiveMobileOrDesktop
      mobile={<ScreenHeaderMobile {...props} />}
      desktop={<ScreenHeaderDesktop {...props} />}
    />
  );
};

export const ScreenHeaderDesktop: React.FC<ScreenHeaderProps> = ({
  title,
  breadcrumbs,
  quickLinks,
  actions,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleQuicklinkChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    navigate(ev.target.value);
  };

  return (
    <Box
      className={styles.screenHeaderDesktop}
      spacing={{ top: 4, left: 4, bottom: 0, right: 4 }}
    >
      <HStack alignment="center">
        <Text variant="screen-title" i18n={title} tagName="h1" />

        <Spacer flex />
        {actions &&
          actions.map((action, index) => (
            <ClickableArea key={`${index}`} href={action.href}>
              <Button
                aria-label={action.label}
                icon={action.icon}
                round
                outline
                intent="secondary"
              />
            </ClickableArea>
          ))}
      </HStack>

      {breadcrumbs?.length > 0 && (
        <Box className={styles.breadcrumbs}>
          <HStack>
            {breadcrumbs.map((crumb) => (
              <HStack key={JSON.stringify(crumb)} flex={false}>
                <Clickable href={crumb.href}>
                  <Text variant="small" i18n={crumb.title} />
                </Clickable>
                <Icon name="chevron-right" size="small" />
              </HStack>
            ))}

            {quickLinks && quickLinks.length > 0 ? (
              <select
                defaultValue={location.pathname}
                onChange={handleQuicklinkChange}
                className={styles.quickLinks}
              >
                {quickLinks.map((ql) => (
                  <option key={ql.href} value={ql.href}>
                    {ql.title}
                  </option>
                ))}
              </select>
            ) : (
              <Text variant="small" i18n={title} />
            )}
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export const ScreenHeaderMobile: React.FC<ScreenHeaderProps> = ({
  title,
  modal,
  actions = [],
  tickForClose,
  onGoBack,
  onToggleDrawer,
  onClose,
}) => {
  const isOnline = useNetworkIsOnline();
  const colorScheme = getColorScheme({ modal, isOnline });
  const properties = useProperties();
  const pendingOverdueTasksCount = usePendingOverdueTasksCount(
    properties.selected?.id
  );
  const { actionCount } = useActionQueue(properties.selected?.id);
  const leftActions = [];

  if (onGoBack) {
    leftActions.push(
      <ClickableArea center key="go-back" href={onGoBack}>
        <Box className={styles.screenHeaderClickableIcon}>
          <Icon color={colorScheme.color} name="chevron-left" />
        </Box>
      </ClickableArea>
    );
  }

  if (onToggleDrawer) {
    leftActions.push(
      <ClickableArea center key="hamburger" href={onToggleDrawer}>
        <Box className={styles.screenHeaderClickableIcon}>
          <Icon color={colorScheme.color} name="hamburger" />
          {pendingOverdueTasksCount || actionCount ? (
            <Box className={styles.badge} />
          ) : null}
        </Box>
      </ClickableArea>
    );
  }

  if (!isOnline && !modal) {
    leftActions.push(<Icon color={colorScheme.color} name="offline" />);
  }

  const rightActions = actions.map((action, index) => (
    <ClickableArea center key={`${index}`} href={action.href}>
      <Box className={styles.screenHeaderClickableIcon}>
        <Icon name={action.icon} color={colorScheme.color} />
      </Box>
    </ClickableArea>
  ));

  if (modal) {
    rightActions.push(
      <ClickableArea center key="modal-close" href={onClose}>
        <Box className={styles.screenHeaderClickableIcon}>
          <Icon
            name={tickForClose ? 'check' : 'close'}
            color={colorScheme.color}
          />
        </Box>
      </ClickableArea>
    );
  }

  return (
    <Box background={colorScheme.background} className={styles.screenHeader}>
      <Box>
        <Box className={styles.titleContainer}>
          <Text
            align="center"
            i18n={typeof title !== 'string' ? title : undefined}
            variant="screen-title"
            tagName="h1"
            color={colorScheme.color}
          >
            {typeof title === 'string' ? title : undefined}
          </Text>
        </Box>
        <HStack alignment="center">
          {leftActions.map((item, index) => (
            <React.Fragment key={`${index}`}>{item}</React.Fragment>
          ))}

          <Spacer flex />

          {rightActions.map((item, index) => (
            <React.Fragment key={`${index}`}>{item}</React.Fragment>
          ))}
        </HStack>
      </Box>
    </Box>
  );
};

const colorScheme = {
  normal: {
    background: undefined,
    color: undefined,
  },
  offline: {
    background: Color.WashedRed,
    color: Color.White,
  },
  modal: {
    background: Color.Green,
    color: Color.White,
  },
};

const getColorScheme = ({
  modal,
  isOnline,
}: {
  modal?: boolean;
  isOnline?: boolean;
}) => {
  if (modal) {
    return colorScheme.modal;
  } else if (!isOnline) {
    return colorScheme.offline;
  }
  return colorScheme.normal;
};
