import { type PaddockStockingRate } from '@mobble/models/src/model/PaddockStockingRate';
import { type ApiPrelude } from '../types';
import { fromRawDate, toISO8601 } from '@mobble/shared/src/core/Date';

const GET_PADDOCK_STOCKING_RATES = `
  query GetPaddockStockingRates(
    $propertyId: ID!
    $paddockId: ID!
    $start: Date!
    $end: Date!
  ) {
    paddockStockingRates(
      propertyId: $propertyId
      paddockId: $paddockId
      start: $start
      end: $end
    ) {
      start
      end
      data {
        day
        
        rollingAreaAverageDSE
        rollingAverageDSE

        totalHeadSheep
        totalDSESheep
        areaDSESheep
        
        totalHeadCattle
        totalDSECattle
        areaDSECattle
        
        totalHeadGoats
        totalDSEGoats
        areaDSEGoats
        
        totalHeadOther
        totalDSEOther
      }
    }
  }
`;

const decodePaddockStockingRate =
  (propertyId: string, paddockId: string) =>
  (raw: any): PaddockStockingRate => {
    const date = fromRawDate(raw.day);

    return {
      paddockId,
      propertyId,

      dateFormatted: date.format('YYYY-MM-DD'),
      dateRaw: raw.day,

      byLivestockType: Object.entries(raw).reduce((acc, [key, value]) => {
        if (key.startsWith('totalHead')) {
          const [_, livestockType] = key.split('totalHead');
          acc[livestockType.toLowerCase()] = {
            head: value,
            DSE: raw[`totalDSE${livestockType}`],
            areaDSE: raw[`areaDSE${livestockType}`],
          };
        }

        return acc;
      }, {}),

      rollingAverage: {
        DSE: raw.rollingAverageDSE,
        areaDSE: raw.rollingAreaAverageDSE,
      },
    };
  };

const decodePaddockStockingRates =
  (propertyId: string, paddockId: string) =>
  (raw: any[]): PaddockStockingRate[] =>
    raw.map(decodePaddockStockingRate(propertyId, paddockId));

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string; // propertyId_paddockId
  }): Promise<{ entities: PaddockStockingRate[] }> => {
    const [propertyId, paddockId] = parentId.split('_');
    const variables = {
      propertyId,
      paddockId,
      start: '2000-01-01T00:00:00Z',
      end: toISO8601(new Date()),
    };
    const response = await prelude.graphql({
      query: GET_PADDOCK_STOCKING_RATES,
      variables,
    });

    const entities = decodePaddockStockingRates(
      propertyId,
      paddockId
    )(response?.data?.paddockStockingRates?.data ?? []);

    return { entities };
  };
