import { useRootSelector, useRootDispatch } from './root';
import {
  proxyUseEntities,
  proxyUseEntity,
} from '../reducers/productApplications';

export const useProductApplications = (
  propertyId?: string,
  productId?: string,
  inventoryType?: 'chemical' | 'feed'
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.productApplications);

  const parentId =
    propertyId && productId
      ? `${propertyId}_${productId}_${inventoryType}`
      : undefined;

  return proxyUseEntities({
    parentId,
    dispatch,
    state,
  });
};

export const useProductApplication = (
  productApplicationId: string,
  propertyId?: string
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.productApplications);

  return proxyUseEntity({
    entityId: productApplicationId,
    parentId: propertyId,
    dispatch,
    state,
  });
};
