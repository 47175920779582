import React from 'react';
import { type I18nItem, makeQuantityFormatMessageItem } from '@mobble/i18n';
import { Settings } from '@mobble/models/src/model/Settings';
import { roundNumberTo } from '@mobble/shared/src/core/Number';
import * as Quantity from '@mobble/shared/src/core/Quantity';
import { useSettings } from '@mobble/store/src/hooks/settings';

import { store } from '@src/store';
import { Text, type TextProps } from '../UI/Text';

export { makeQuantityFormatMessageItem };

export type LocaleQuantityProps = TextProps & {
  quantity: Quantity.Quantities;
};

// TODO; move to core/Number?
const printNumber = (number: number): string => {
  const [whole, decimal] = String(number).split('.');
  const prettyWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return decimal ? `${prettyWhole}.${decimal}` : prettyWhole;
};

/**
 * @deprecated The method is deprecated, use makeQuantityFormatMessageItem instead
 */
export const makeQuantityI18nItem = (
  quantity: Quantity.Quantities,
  unit?: typeof quantity.unit,
  boldValue = false
): I18nItem => {
  const value = printNumber(
    Number(
      roundNumberTo(1)(
        unit ? Quantity.convertTo(unit)(quantity).value : quantity.value
      )
    )
  );
  return {
    key: `generic.quantity.unit.${unit || quantity.unit}.value`,
    params: {
      '%VALUE': boldValue ? `**${value}**` : value,
    },
  };
};

/**
 * @deprecated The method is deprecated, use makeQuantityFormatMessageItem instead
 */
export const quantityToLocaleI18nItem = (
  quantity: Quantity.Quantities,
  unit?: typeof quantity.unit
): I18nItem => {
  const settingUnit = unitFromSettingsForQuantityType(
    store.getState().settings as Settings
  )(quantity.type);

  return makeQuantityI18nItem(quantity, unit || settingUnit);
};

export const unitFromSettingsForQuantityType =
  (settings: Settings) => (quantityType: Quantity.Type) => {
    switch (quantityType) {
      case Quantity.Type.Area:
        return settings.areaUnit;
      case Quantity.Type.Volume:
        return settings.volumeUnit;
      case Quantity.Type.Mass:
        return settings.massUnit;
      case Quantity.Type.Length:
        return settings.lengthUnit;
      case Quantity.Type.Distance:
        return settings.distanceUnit;
      case Quantity.Type.MassVolume:
        return settings.massVolumeUnit;
    }
  };

export const LocaleQuantity: React.FC<LocaleQuantityProps> = ({
  quantity,
  ...rest
}) => {
  const { settings } = useSettings();
  const unit = unitFromSettingsForQuantityType(settings)(quantity.type);
  return <Text {...rest} i18n={makeQuantityI18nItem(quantity, unit)} />;
};
