import React from 'react';
import { formatDate } from '@mobble/shared/src/core/Date';
import { Color, colorToHex } from '@mobble/colors';
import { Text } from '@src/stories/Components/UI/Text';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import { Button } from '@src/stories/Components/UX/Button';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { Box } from '@src/stories/Components/Layout/Box';
import styles from './settingsBilling.scss';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import {
  toContactEmailURL,
  toContactPhoneURL,
} from '@src/screens/Settings/hooks/useBilling';
import { Icon } from '@src/stories/Components/UI/Icon';
import { useI18n } from '@mobble/i18n/src';
import {
  getProductValues,
  MobbleSubscription,
  StripeProduct,
  SubscriptionStatus,
} from '@mobble/models/src/model/Organisation';

export const SettingsBillingOverview = ({
  currentUserCount,
  subscription,
  billingActionError,
  products,
  trialEnded,
  displayOptions,
  contactPhone,
  contactEmail,
  daysUntilTrialEnd,

  onClickManageSubscription,
  getCheckoutLink,
}: {
  currentUserCount: number;
  subscription: MobbleSubscription | null;
  billingActionError: boolean;
  products: StripeProduct[] | null;
  trialEnded: boolean;
  displayOptions: boolean;
  contactPhone: string;
  contactEmail: string;
  daysUntilTrialEnd: number;

  onClickManageSubscription: () => void;
  getCheckoutLink: (priceId: string) => void;
}) => {
  const { formatMessage } = useI18n();

  const SubscriptionCard = () => {
    if (!subscription) {
      return <LoadingCard />;
    }

    if (trialEnded) {
      return <TrialEndedCard />;
    }

    if (subscription.status === SubscriptionStatus.trialing) {
      return (
        <TrialSubscriptionCard
          subscription={subscription}
          daysUntilTrialEnd={daysUntilTrialEnd}
        />
      );
    }

    if (subscription.status === SubscriptionStatus.active) {
      return (
        <CurrentSubscriptionCard
          subscription={subscription}
          currentUserCount={currentUserCount}
          onClickManageSubscription={onClickManageSubscription}
        />
      );
    }

    return (
      <CancelledSubscriptionCard
        onClickManageSubscription={onClickManageSubscription}
        cancelledDate={formatDate(subscription.currentPeriodEnd)}
        subscriptionName={subscription.subscriptionName}
      />
    );
  };

  const ErrorCard = () => (
    <Box
      style={{
        border: `2px solid ${colorToHex(Color.WashedRed)}`,
        borderRadius: 4,
      }}
      spacing={1}
    >
      <HStack alignment="center">
        <Icon name="alert-triangle" color={Color.WashedRed} size="large" />
        <Spacer x={1} />
        <Text
          color={Color.WashedRed}
          i18n={formatMessage({
            defaultMessage:
              'An error occurred. Please try again later or contact support.',
            description: 'billing.error-card.text',
          })}
          bold
        />
      </HStack>
    </Box>
  );

  return (
    <Box className={styles.container}>
      <Box>
        {billingActionError && (
          <>
            <ErrorCard />
            <Spacer y={2} />
          </>
        )}

        <SubscriptionCard />
        {products && displayOptions && (
          <>
            <Box className={styles.productList} spacing={{ top: 2 }}>
              {products?.map((product) => {
                const { name, price, interval, canPurchase, description } =
                  getProductValues(product);
                return (
                  <PricingOption
                    key={product.id}
                    name={name}
                    price={price}
                    interval={interval}
                    canPurchase={canPurchase}
                    description={description}
                    onUpgradePlanClick={() =>
                      getCheckoutLink(product.prices[0].id)
                    }
                  />
                );
              })}
            </Box>
          </>
        )}
      </Box>
      <Box mobileSpacing={{ top: 1 }}>
        <ContactCard contactEmail={contactEmail} contactPhone={contactPhone} />
      </Box>
    </Box>
  );
};

const CurrentSubscriptionCard = ({
  subscription,
  currentUserCount,
  onClickManageSubscription,
}: {
  subscription: MobbleSubscription;
  currentUserCount: number;
  onClickManageSubscription: () => void;
}) => (
  <Box className={styles.border} spacing={2}>
    <Box className={styles.header}>
      <Box>
        <Text tagName="h1" bold variant="larger">
          {subscription.subscriptionName}
        </Text>

        <Box>
          {subscription?.cancelAt && (
            <Text
              color={Color.Red}
              i18n={{
                key: 'billing.current-subscription.card.cancel-at',
                params: { '%DATE': formatDate(subscription.cancelAt) },
              }}
            />
          )}
        </Box>
      </Box>

      <div className={styles.manageSubscriptionButtonDesktop}>
        <Button
          onClick={onClickManageSubscription}
          label={{
            key: 'billing.current-subscription.card.manage-subscription',
          }}
          outline
        />
      </div>
    </Box>
    <VStack>
      <Text
        bold
        variant="larger"
        i18n={{
          key: 'billing.current-subscription.card.price',
          params: { '%VALUE': subscription.billingAmount / 100 },
        }}
      />
      <Spacer y={2} />
      <Text
        bold
        i18n={{
          key: 'billing.current-subscription.card.max-user-count',
          params: { '%VALUE': subscription.maxUsers },
        }}
      />
      <Text
        bold
        i18n={{
          key: 'billing.current-subscription.card.users-in-use',
          params: {
            '%CURRENT_USER_COUNT': currentUserCount,
            '%MAX_USER_COUNT': subscription.maxUsers,
          },
        }}
      />
      <Spacer y={6} />
    </VStack>
    <Box className={styles.datesContainer}>
      <Box flex>
        <Text
          i18n={{
            key: 'billing.current-subscription.card.current-period-start',
            params: {
              '%DATE': formatDate(subscription.currentPeriodStart),
            },
          }}
        />
      </Box>
      <Text
        i18n={{
          key: 'billing.current-subscription.card.current-period-end',
          params: {
            '%DATE': formatDate(subscription.currentPeriodEnd),
          },
        }}
      />
    </Box>
    <div className={styles.manageSubscriptionButtonMobile}>
      <Button
        onClick={onClickManageSubscription}
        label={{
          key: 'billing.current-subscription.card.manage-subscription',
        }}
        outline
        flex
        size="small"
      />
    </div>
  </Box>
);

const CancelledSubscriptionCard = ({
  onClickManageSubscription,
  cancelledDate,
  subscriptionName,
}: {
  onClickManageSubscription: () => void;
  cancelledDate: string;
  subscriptionName: string;
}) => (
  <Box className={styles.border} spacing={2}>
    <Text
      bold
      variant="larger"
      i18n={{ key: 'billing.cancelled-subscription.card.title' }}
    />
    <Spacer y={2} />
    <Text
      variant="larger"
      i18n={{
        key: 'billing.cancelled-subscription.card.subscription-name',
        params: {
          '%VALUE': subscriptionName,
        },
      }}
    />
    <Spacer y={2} />
    <Text
      variant="larger"
      i18n={{
        key: 'billing.cancelled-subscription.card.subscription-end-date',
        params: {
          '%DATE': cancelledDate,
        },
      }}
    />
    <Spacer y={4} />
    <Button
      onClick={onClickManageSubscription}
      label={{ key: 'billing.cancelled-subscription.card.manage-subscription' }}
      outline
    />
  </Box>
);

const TrialEndedCard = () => (
  <Box className={styles.border} spacing={2}>
    <Text
      bold
      variant="larger"
      i18n={{ key: 'billing.trial-ended.card.title' }}
    />
    <Spacer y={2} />
    <Text i18n={{ key: 'billing.trial-ended.card.tag-title' }} />
    <Spacer y={2} />
    <Text i18n={{ key: 'billing.trial-ended.card.description' }} />
    <Spacer y={2} />
  </Box>
);

const TrialSubscriptionCard = ({
  subscription,
  daysUntilTrialEnd,
}: {
  subscription: MobbleSubscription;
  daysUntilTrialEnd: number;
}) => (
  <Box className={styles.border} spacing={2}>
    <Text
      bold
      variant="larger"
      i18n={{ key: 'billing.trial-subscription.card.title' }}
    />

    <Spacer y={1} />
    <VStack>
      <Text
        bold
        variant="larger"
        color={Color.Green}
        i18n={{ key: 'billing.trial-subscription.card.price' }}
      />

      <Spacer y={4} />
      <HStack>
        <Box className={styles.border} spacing={2}>
          <Text
            bold
            color={Color.Grey}
            i18n={{
              key: 'billing.trial-subscription.card.days-until-end',
              params: {
                '%VALUE': daysUntilTrialEnd,
              },
            }}
          />
        </Box>
      </HStack>
      <Spacer y={4} />

      <Text
        i18n={{
          key: 'billing.trial-subscription.card.end-date',
          params: {
            '%DATE': formatDate(subscription.currentPeriodEnd),
          },
        }}
      />
    </VStack>
  </Box>
);

const PricingOption = ({
  name,
  price,
  interval,
  canPurchase,
  description,
  onUpgradePlanClick,
}: {
  name: string;
  price: number;
  interval: string;
  canPurchase: boolean;
  description: string;
  onUpgradePlanClick: () => void;
}) => (
  <Box className={styles.border} spacing={2}>
    <HStack>
      <VStack>
        <Text
          bold
          variant="larger"
          i18n={{
            key: 'billing.pricing-option.card.price',
            params: {
              '%VALUE': price,
              '%INTERVAL': interval,
            },
          }}
        />
        <Spacer y={1} />
        <Text variant="larger">{name}</Text>
      </VStack>
      <Box className={styles.toolbarButtonWrapper}>
        <div className={styles.purchaseButton}>
          <Button
            className={styles.purchaseButton}
            onClick={onUpgradePlanClick}
            disabled={!canPurchase}
            label={{ key: 'billing.pricing-option.card.purchase' }}
            outline
          />
        </div>
        {!canPurchase && (
          <div className={styles.tooltip}>
            <Text
              align="center"
              i18n={{
                key: 'billing.pricing-optionsToEndpoint.card.cannot-purchase',
              }}
            />
          </div>
        )}
      </Box>
    </HStack>
    <VStack>
      <Spacer y={3} />
      <Text>{description}</Text>
      <Spacer y={1} />
    </VStack>
  </Box>
);

const LoadingCard = () => (
  <Box className={styles.border} height={32}>
    <VStack alignment="center">
      <HStack alignment="center">
        <Spinner color={Color.DarkGrey} />
      </HStack>
    </VStack>
  </Box>
);

const ContactCard = ({
  contactEmail,
  contactPhone,
}: {
  contactEmail: string;
  contactPhone: string;
}) => (
  <Box className={styles.border}>
    <Box spacing={2} flex background={Color.AlmostWhite}>
      <Text
        bold
        variant="larger"
        i18n={{ key: 'billing.contact-support.card.title' }}
      />
    </Box>
    <Box spacing={2}>
      <Text
        i18n={{
          key: 'billing.contact-support.card.description',
        }}
      />
      <Spacer y={2} />
      <Clickable href={toContactEmailURL(contactEmail)}>
        <Box className={styles.contactButton} spacing={1}>
          <VStack alignment="center">
            <Text bold color={Color.Green}>
              {contactEmail}
            </Text>
          </VStack>
        </Box>
      </Clickable>

      <Spacer y={1} />
      <Clickable href={toContactPhoneURL(contactPhone)}>
        <Box className={styles.contactButton} spacing={1}>
          <VStack alignment="center">
            <Text bold color={Color.Green}>
              {contactPhone}
            </Text>
          </VStack>
        </Box>
      </Clickable>
    </Box>
  </Box>
);
