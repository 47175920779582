import React from 'react';
import { useI18n, I18nItem } from '@mobble/i18n';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Icon } from '@mobble/icon';
import styles from './chartWrapper.scss';
import { Clickable } from '../UX/Clickable';

export interface ChartWrapperProps {
  title: I18nItem | string;
  titleHref?: string;
  options?: ChartWrapperOption[];
  children?: React.ReactNode;
  className?: string;
  noPadding?: boolean;
}

export interface ChartWrapperOption {
  options: {
    value: string | number;
    label: I18nItem | string;
    selected?: boolean;
  }[];
  onChange: (value: string) => void;
}

export const ChartWrapper: React.FC<ChartWrapperProps> = ({
  title,
  titleHref,
  options,
  children,
  className,
  noPadding,
}) => {
  const { translate } = useI18n();

  return (
    <Box className={[styles.chartWrapper, className]}>
      <Box className={styles.chartWrapperHeader}>
        <Box className={styles.chartWrapperTitle}>
          <Clickable href={titleHref} disabled={!titleHref}>
            <Text
              variant="card-title"
              i18n={title}
              underline={Boolean(titleHref)}
            />
          </Clickable>
        </Box>
        {options && (
          <Box className={styles.chartWrapperOptions}>
            {options.map((option, index) => {
              const selected = option.options.find((o) => o.selected);

              return (
                <Box key={index} className={styles.selectWrapper}>
                  <select
                    onChange={(e) => option.onChange(e.target.value)}
                    value={selected?.value}
                  >
                    {option.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {typeof option.label === 'string'
                          ? option.label
                          : translate(option.label)}
                      </option>
                    ))}
                  </select>
                  <Icon name="chevron-down" size="small" />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      <Box
        className={[
          styles.chartWrapperContent,
          noPadding ? styles.noPadding : '',
        ].join(' ')}
      >
        {children}
      </Box>
    </Box>
  );
};
