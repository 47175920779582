import { type MessageDescriptor } from 'react-intl';
import { Quantities, convertTo } from '@mobble/shared/src/core/Quantity';
import { roundNumberTo } from '@mobble/shared/src/core/Number';

type MessageWithValues = [MessageDescriptor, Record<string, string | number>];

const printNumber = (number: number): string => {
  const [whole, decimal] = String(number).split('.');
  const prettyWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return decimal ? `${prettyWhole}.${decimal}` : prettyWhole;
};

export const makeQuantityFormatMessageItem = (
  quantity: Quantities,
  unit?: typeof quantity.unit
): MessageWithValues => {
  const value = printNumber(
    Number(
      roundNumberTo(1)(unit ? convertTo(unit)(quantity).value : quantity.value)
    )
  );

  return [
    {
      id: `generic.quantity.unit.${unit || quantity.unit}.value`,
      defaultMessage: [],
    },
    {
      VALUE: value,
    },
  ];
};
