import React from 'react';
import isEqual from 'lodash/isEqual';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { Paddock } from '@mobble/models/src/model/Paddock';
import { Casualty } from '@mobble/models/src/model/Casualty';
import { Color } from '@mobble/colors';
import { Text } from '../../Components/UI/Text';
import { TimelineEvent } from '../../Components/UI/TimelineEvent';
import { Spacer } from '../../Components/Layout/Spacer';
import { HStack } from '../../Components/Layout/Stack';
import { Avatar } from '../../Components/UI/Avatar';
import { Clickable } from '@src/stories/Components/UX/Clickable';

interface CasualtyCardProps {
  casualty: Casualty;
  paddock: Paddock;
  last?: boolean;
  onClick: (casualty: Casualty) => void;
}

export const CasualtyCard: React.FC<CasualtyCardProps> = ({
  casualty,
  paddock,
  onClick,
  last,
}) => {
  return (
    <Clickable
      fullWidth
      href={typeof onClick === 'function' ? () => onClick(casualty) : onClick}
    >
      <TimelineEvent
        title={`${casualty.number} ${toMobDisplayName(casualty.mob)}`}
        date={casualty.date}
        last={last}
        color={Color.DarkGrey}
      >
        <Text color={Color.DarkGrey}>{paddock?.name}</Text>
        <Spacer y={1} />
        <HStack alignment="center">
          <Avatar size="tiny" name={casualty.createdBy.name} />
          <Spacer x={0.5} />
          <Text color={Color.Grey} variant="tiny">
            {casualty.createdBy.name}
          </Text>
        </HStack>
      </TimelineEvent>
    </Clickable>
  );
};

export const MemoisedCasualtyCard = React.memo(CasualtyCard, (prev, next) => {
  return isEqual(prev.casualty, next.casualty) && prev.last === next.last;
});
