import React, { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

import styles from './toggle.scss';
const cx = classNames.bind(styles);

type PickedProps =
  | 'aria-label'
  | 'autoFocus'
  | 'checked'
  | 'className'
  | 'disabled'
  | 'name'
  | 'onBlur'
  | 'onChange'
  | 'onFocus'
  | 'style'
  | 'tabIndex'
  | 'value';

export interface ToggleProps
  extends Pick<ComponentPropsWithoutRef<'input'>, PickedProps> {
  /**
   * Used to bind input and label
   */
  id: string;

  /**
   * 	Toggle label -- alternative set `aria-label`
   */
  label?: string;

  /**
   * Indeterminate state of checkbox, overwrites checked
   */
  indeterminate?: boolean;

  /**
   * Size of the toggle, defaults to medium
   */
  size?: 'small' | 'medium' | 'large';
}

/**
 * Toggle is an alternatively styled Checkbox
 */
export const Toggle: React.FC<ToggleProps> = ({
  id,
  label,
  checked,
  indeterminate,
  size,
  className,
  style,
  ...others
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const rootClasses = cx(
    {
      Toggle: true,
      [size]: true,
    },
    className
  );

  // Set indeterminate state of checkbox
  useEffect(() => {
    if (inputEl?.current && typeof indeterminate === 'boolean') {
      inputEl.current.indeterminate = indeterminate;
    }
  }, [inputEl, indeterminate]);

  return (
    <div className={rootClasses} style={style}>
      <label>
        {typeof label === 'string' ? (
          <span className={styles.labelText}>{label}</span>
        ) : (
          label
        )}
        <input
          id={id}
          type="checkbox"
          ref={inputEl}
          role="switch"
          checked={checked}
          {...others}
        />
      </label>
    </div>
  );
};
