import React from 'react';
import {
  type BoundingBox,
  isValidBoundingBox,
} from '@mobble/models/src/model/MapGeometry';
import { cameraPanToUserLocation } from './User';
import { useMap } from '@src/stories/Mapbox/lib/React/Context';
import { useMapPluginsContext } from '../Context';

const getStoredViewport = (name?: string): null | BoundingBox => {
  if (!name) {
    return null;
  }

  const stored = JSON.parse(
    localStorage.getItem(`map:viewport:${name}`) ?? '[]'
  );
  if (isValidBoundingBox(stored)) {
    return stored;
  }
  return null;
};

const storeViewport = (name: string, boundingBox: BoundingBox) => {
  localStorage.setItem(`map:viewport:${name}`, JSON.stringify(boundingBox));
};

export const Effect: React.FC = () => {
  const map = useMap();
  const { mapProperties, name } = useMapPluginsContext();

  const boundingBox = (() => {
    const viewport = getStoredViewport(name);
    if (viewport) {
      return viewport;
    } else if (isValidBoundingBox(mapProperties.boundingBox)) {
      return mapProperties.boundingBox;
    }

    return null;
  })();

  const onViewportChanged = (ev: any) => {
    const NE_SW = ev.target.getBounds().toArray();
    storeViewport(name, [...NE_SW[0], ...NE_SW[1]] as any);
  };

  React.useEffect(() => {
    const setBounds = (bounds) => {
      map.fitBounds(
        [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]],
        ],
        { linear: true, animate: true }
      );
    };

    if (name) {
      map.on('moveend', onViewportChanged);
    }

    if (boundingBox && mapProperties.items.length > 0) {
      setBounds(boundingBox);
    } else {
      cameraPanToUserLocation(map).catch((err) => {
        console.error(err);
      });
    }

    return () => {
      if (name) {
        map.off('moveend', onViewportChanged);
      }
    };
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};

export default {
  Effect,
};
