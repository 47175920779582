import React from 'react';
import styles from './appScreen.scss';

export interface AppScreenProps {
  children: React.ReactNode;
}

export const AppScreen: React.FC<AppScreenProps> = ({ children }) => (
  <div className={styles.appScreen}>{children}</div>
);
