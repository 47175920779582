import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { FilterPerProperty } from '../lib/filter';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { InventoryItemUsage } from '@mobble/models/src/model/InventoryItem';
import { InventoryCategory } from '@mobble/models';

export interface InventoryItemUsageState {
  extStatus: ExtStatusPerActionPerParent;
  entities: InventoryItemUsage[];
  filter: FilterPerProperty;
  sort: {};
}

export const defaultState: InventoryItemUsageState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const name = 'inventoryItemUsage';
const toParentId = ({ propertyId, id }: InventoryItemUsage) =>
  `${propertyId}_${id}`;

const entitySlice = entitySliceFactory<InventoryItemUsageState>({
  name,
  initialState: defaultState,
  toParentId,
  onGetAll: mobbleService.api.inventoryItems.getInventoryItemUsage,
  onCreate: () => Promise.reject(),
  onUpdate: () => Promise.reject(),
  onDelete: () => Promise.reject(),

  extraReducers: (builder) => {
    builder.addCase(
      thunkGetInventoryItemUsageOfDateRange.fulfilled,
      (state, action) => {
        state.entities = action.payload.entities;
      }
    );
  },
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;
export const { updateExtStatus } = entitySlice.slice.actions as any;

export const thunkGetInventoryItemUsageOfDateRange = createAsyncThunk<
  { entities: InventoryItemUsage[] },
  {
    propertyId: string;
    id: string;
    type: InventoryCategory;
    startDate: string;
    endDate: string;
  }
>(`${name}/get-date-range`, async (args) => {
  const parentId = `${args.propertyId}__${args.id}__${args.type}`;

  return mobbleService.api.inventoryItems.getInventoryItemUsage({
    parentId: parentId,
    startDate: args.startDate,
    endDate: args.endDate,
  });
});

export default entitySlice.slice.reducer;
