import Fuse from 'fuse.js';
import { RawDate } from '@mobble/shared/src/core/Date';
import {
  convertLength,
  makeQuantityOfLength,
  sum,
  type QuantityOfLength,
} from '@mobble/shared/src/core/Quantity';
import { Property } from './Property';
import { FilterItem, groupFilter } from './Filter';

export interface RainGauge {
  id: string;
  propertyId: Property['id'];
  lastReading?: LastRainGaugeReading;
  name: string;
  readingYears: number[];
  status: string;
  mtd: QuantityOfLength;
  ytd: QuantityOfLength;
  totals: RainGaugeYearMonthTotal[];
}

export interface RainGaugeYearMonthTotal {
  year: number;
  month: number;
  total: QuantityOfLength;
}

export interface LastRainGaugeReading {
  id: string;
  createdByID: string | null;
  date: RawDate;
  value: QuantityOfLength;
}

export interface MakeRainGaugeInput {
  propertyId: Property['id'];
  name: string;
}

export const makeRainGauge = ({
  name,
  propertyId,
}: MakeRainGaugeInput): Omit<RainGauge, 'id'> => ({
  propertyId,
  name,
  readingYears: [],
  status: 'active',
  mtd: makeQuantityOfLength('mm', 0),
  ytd: makeQuantityOfLength('mm', 0),
  totals: [],
});

export type RainGaugeTotalYear = {
  year: number;
  entities: RainGaugeYearMonthTotal[];
};

export const getRainGaugeYearsAvailable = (rainGauge: RainGauge): number[] =>
  [...new Set([...rainGauge.readingYears, new Date().getFullYear()])].sort(
    (a, b) => a - b
  );

export const getRainGaugeTotalsPerMonthForYearInMm =
  (rainGauge: RainGauge) => (year: number) => {
    const readingsPerMonth = new Array(12).fill(null).map((_, i) => {
      const month = i + 1;
      const reading = rainGauge.totals.find(
        (a) => a.year === year && a.month === month
      );
      return reading
        ? {
            ...reading,
            total: convertLength('mm')(reading.total).value,
          }
        : {
            year,
            month,
            total: 0,
          };
    });
    const valuesInMm = readingsPerMonth.map((a) => a.total);
    const min = Math.min(...valuesInMm);
    const max = Math.max(...valuesInMm);

    return {
      readingsPerMonth,
      min,
      max,
    };
  };

export const totalForYear = (rainGauge: RainGauge) => (year: number) => {
  const totalsForSelectedYear = rainGauge.totals
    .filter((a) => a.year === year)
    .map(({ total }) => total);

  return sum(totalsForSelectedYear) ?? makeQuantityOfLength('mm', 0);
};

export const sortByName = (a: RainGauge, b: RainGauge) =>
  a.name.localeCompare(b.name);

export const filterRainGauges = (
  rainGauges: RainGauge[],
  filter?: FilterItem[]
): RainGauge[] => {
  if (!filter || filter.length === 0) {
    return rainGauges;
  }
  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredPaddocks =
    searchQuery && searchQuery.type === 'search'
      ? searchRainGauges(rainGauges, searchQuery.value)
      : rainGauges;

  return preFilteredPaddocks;
};
export const searchRainGauges = (
  rainGauges: RainGauge[],
  searchQuery: string
): RainGauge[] =>
  new Fuse(rainGauges, {
    keys: [{ name: 'name', weight: 6 }],
  })
    .search(searchQuery)
    .map((a) => a.item);
