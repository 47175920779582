import { Mob } from '@mobble/models/src/model/Mob';
import { Paddock, mobsOnPaddock } from '@mobble/models/src/model/Paddock';
import {
  ConfiguredPropertyTypeGroup,
  getUniqueConfiguredPropertyTypes,
} from '@mobble/models/src/model/Property';
import { useMobs, usePaddocks, useProperties } from '@mobble/store/src/hooks';
import { arrayUnique } from '../core/Array';

export const usePaddocksFilterItems = () => {
  const properties = useProperties();
  const paddocks = usePaddocks(properties.selected?.id);
  const mobs = useMobs(properties.selected?.id);

  const getFilterTypesFromConfiguredPropertyTypeGroup = (
    group: ConfiguredPropertyTypeGroup
  ) =>
    getUniqueConfiguredPropertyTypes(
      properties.selected ? [properties.selected] : []
    )(group).map((a) => ({
      value: a.type,
    }));

  const getMobParamValues = (param: keyof Mob) => (entities: Paddock[]) => {
    const acc: string[] = [];
    entities.forEach((paddock) => {
      const paddockMobs = mobsOnPaddock(mobs.entities)(paddock);
      paddockMobs.forEach((mob) => {
        if (Array.isArray(mob[param])) {
          (mob[param] as string[]).forEach((value) => value && acc.push(value));
        } else if (
          typeof mob[param] === 'string' ||
          typeof mob[param] === 'number'
        ) {
          acc.push(String(mob[param]));
        }
      });
    });
    return arrayUnique(acc).map((value) => ({ value }));
  };

  // TODO: migrate these strings
  return () => [
    {
      title: { key: 'paddocks.filter.sections.paddock_type.title' },
      group: 'paddock_type',
      type: 'select-multiple',
      data: getFilterTypesFromConfiguredPropertyTypeGroup(
        ConfiguredPropertyTypeGroup.paddockType
      ),
    },
    {
      title: { key: 'paddocks.filter.sections.livestock_type.title' },
      group: 'livestock_type',
      type: 'select-multiple',
      data: () => getMobParamValues('type')(paddocks.entities),
    },
    {
      title: { key: 'paddocks.filter.sections.age.title' },
      type: 'select-multiple',
      group: 'age',
      data: (entities: Paddock[]) =>
        getMobParamValues('ages')(entities).sort(
          (a, b) => Number(b.value) - Number(a.value)
        ),
    },
    {
      title: { key: 'paddocks.filter.sections.breed.title' },
      type: 'select-multiple',
      group: 'breed',
      data: getMobParamValues('breed'),
    },
    {
      title: { key: 'paddocks.filter.sections.gender.title' },
      type: 'select-multiple',
      group: 'gender',
      data: getMobParamValues('gender'),
    },
    {
      title: { key: 'paddocks.filter.sections.class.title' },
      type: 'select-multiple',
      group: 'class',
      data: getMobParamValues('classes'),
    },
  ];
};
