export interface LoggerInterface {
  log: (source: string, message: string, level?: LogLevel) => void;
  error: (source: string, message: string) => void;
  warn: (source: string, message: string) => void;
  info: (source: string, message: string) => void;
  debug: (source: string, message: string) => void;
  getLog: () => LogItem[];
}

export enum LogLevel {
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
  DEBUG = 'debug',
}

export interface LogItem {
  source: string;
  message: string;
  level: LogLevel;
  timestamp: number;
}

class Logger implements LoggerInterface {
  private static instance: Logger;
  //
  private logItems: LogItem[] = [];
  private listeners: ((logItem: LogItem) => void)[] = [];
  private LOG_ITEMS_LIMIT = 200;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new Logger();
    }
    return this.instance;
  }

  public log = (
    source: string,
    message: string,
    level: LogLevel = LogLevel.DEBUG
  ) => {
    const logItem: LogItem = {
      source,
      message,
      level,
      timestamp: Date.now().valueOf(),
    };

    this.logItems.unshift(logItem);

    if (this.logItems.length > this.LOG_ITEMS_LIMIT) {
      this.logItems.pop();
    }

    this.listeners.forEach((listener) => listener(logItem));

    // if (typeof __DEV__ !== 'undefined' && __DEV__) {
    //   console.log(`[${level}] [${source}] ${message}`);
    // }
  };

  public error = (source: string, message: string) => {
    this.log(source, message, LogLevel.ERROR);
  };

  public warn = (source: string, message: string) => {
    this.log(source, message, LogLevel.WARN);
  };

  public info = (source: string, message: string) => {
    this.log(source, message, LogLevel.INFO);
  };

  public debug = (source: string, message: string) => {
    this.log(source, message, LogLevel.DEBUG);
  };

  public getLog = () => {
    return this.logItems;
  };

  public clear = () => {
    this.logItems = [];
  };

  public addListener = (f: (logItem: LogItem) => void) => {
    this.listeners.push(f);
  };

  public removeListener = (f: (logItem: LogItem) => void) => {
    this.listeners = this.listeners.filter((listener) => listener !== f);
  };
}

export const instance = Logger.getInstance();

export default instance;
