import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Formik, Form, type FormikErrors, type FormikTouched } from 'formik';
import classNames from 'classnames/bind';
import * as Yup from 'yup';

import { useI18n, useMessages } from '@mobble/i18n';
import { type FetcherError } from '@mobble/service/src/lib/Fetcher';

import { Button, Checkbox, ListSelect, Text } from '@src/components';

import {
  countries,
  countriesAndCode,
  farmRoles,
} from '@src/screens/Auth/hooks/useGetCountryFromQueryParams';

import { Input } from '@src/stories/Components/UX/Input';
import { InputError } from '@src/stories/Components/UX/InputError';

import styles from './signUpForm.scss';
const cx = classNames.bind(styles);

export interface SignUpFormProps {
  loading?: boolean;
  error?: FetcherError;
  initialValues?: Partial<SignUpFormValues>;
  onSubmit: (values: SignUpFormValues) => void;
}

export interface SignUpFormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  farmRole: string;
  country: string;
  phone: string;
  address: string;
  termsAgreed: boolean;
}

interface TextType {
  id: string;
  type: 'text' | 'password';
  placeholder: string;
  password?: boolean;
  autoComplete?: string;
  onChange: (value: string) => void;
  value: string;
  error: string;
}

interface SelectorType
  extends Omit<TextType, 'autoComplete' | 'type' | 'onChange'> {
  type: 'select';
  options: { value: string; label: string }[];
  onChange: (value: (string | number)[]) => void;
  // value: string; check should b string[] ?
}

const messages = defineMessages({
  'address.error': {
    defaultMessage: 'Insert your address',
    description: 'auth.sign_up.form.address.error',
  },
  'address.label': {
    defaultMessage: 'Business Address',
    description: 'auth.sign_up.form.address.label',
  },

  'country.error': {
    defaultMessage: 'Select your country',
    description: 'auth.sign_up.form.country.error',
  },
  'country.label': {
    defaultMessage: 'Country',
    description: 'auth.sign_up.form.country.label',
  },

  'email.error': {
    defaultMessage: 'Insert your Email',
    description: 'auth.sign_up.form.email.error',
  },
  'email.label': {
    defaultMessage: 'Email',
    description: 'auth.sign_up.form.email.label',
  },

  'farmRole.error': {
    defaultMessage: 'Select your farm role',
    description: 'auth.sign_up.form.farmRole.error',
  },
  'farmRole.label': {
    defaultMessage: 'Farm role',
    description: 'auth.sign_up.form.farmRole.label',
  },

  'firstName.error': {
    defaultMessage: 'Insert your first name',
    description: 'auth.sign_up.form.firstName.error',
  },
  'firstName.label': {
    defaultMessage: 'First name',
    description: 'auth.sign_up.form.firstName.label',
  },

  'lastName.error': {
    defaultMessage: 'Insert your last name',
    description: 'auth.sign_up.form.lastName.error',
  },
  'lastName.label': {
    defaultMessage: 'Last name',
    description: 'auth.sign_up.form.lastName.label',
  },

  'password.error': {
    defaultMessage: 'Insert your password',
    description: 'auth.sign_up.form.password.error',
  },
  'password.label': {
    defaultMessage: 'Password',
    description: 'auth.sign_up.form.password.label',
  },

  'phone.error': {
    defaultMessage: 'Insert your phone number',
    description: 'auth.sign_up.form.phone.error',
  },
  'phone.label': {
    defaultMessage: 'Phone number',
    description: 'auth.sign_up.form.phone.label',
  },

  submit: {
    defaultMessage: 'Start Free Trial',
    description: 'auth.sign_up.form.submit',
  },
  'submit.label': {
    defaultMessage: 'Sign Up',
    description: 'auth.sign_up.form.submit.label',
  },
  'termsAgreed.error': {
    defaultMessage: 'Must Accept Terms and Conditions',
    description: 'auth.sign_up.form.termsAgreed.error',
  },
  title: {
    defaultMessage: 'Start a 21 day free trial',
    description: 'auth.sign_up.form.title',
  },
});

const constructSignUpForm = ({
  values,
  setValues,
  errors,
  touched,
  strings,
}: {
  values: Partial<SignUpFormValues>;
  setValues: (values: Partial<SignUpFormValues>) => void;
  errors: FormikErrors<Partial<SignUpFormValues>>;
  touched: FormikTouched<Partial<SignUpFormValues>>;
  strings: any;
}): ((TextType | SelectorType) | (TextType | SelectorType)[])[] => [
  [
    {
      id: 'firstName',
      type: 'text',
      autoComplete: 'given-name',
      placeholder: strings['firstName.label'],
      onChange: (value: string) => setValues({ ...values, firstName: value }),
      value: values.firstName,
      error: errors.firstName && touched.firstName ? errors.firstName : null,
    },
    {
      id: 'lastName',
      type: 'text',
      autoComplete: 'family-name',
      placeholder: strings['lastName.label'],
      onChange: (value: string) => setValues({ ...values, lastName: value }),
      value: values.lastName,
      error: errors.lastName && touched.lastName ? errors.lastName : null,
    },
  ],
  {
    id: 'farmRole',
    type: 'select',
    options: farmRoles.map((role) => ({
      ...role,
      selected: role.value === values.farmRole,
    })),
    placeholder: strings['farmRole.label'],
    onChange: (value) => {
      setValues({
        ...values,
        farmRole: value[0] ? String(value[0]) : '',
      });
    },
    value: values.farmRole,
    error: errors.farmRole && touched.farmRole ? errors.farmRole : null,
  },
  {
    id: 'email',
    type: 'text',
    autoComplete: 'email',
    placeholder: strings['email.label'],
    onChange: (value: string) => setValues({ ...values, email: value }),
    value: values.email,
    error: errors.email && touched.email ? errors.email : null,
  },
  {
    id: 'country',
    type: 'select',
    options: countries.map((country) => ({
      ...country,
      selected: country.value === values.country,
    })),
    placeholder: strings['country.label'],
    onChange: (value) => {
      const phone = countriesAndCode.find((c) => c.value === value[0])?.phone;

      setValues({
        ...values,
        country: value[0] ? String(value[0]) : '',
        ...(phone ? { phone: '+' + phone } : {}),
      });
    },
    value: values.country,
    error: errors.country && touched.country ? errors.country : null,
  },
  {
    id: 'phone',
    type: 'text',
    autoComplete: 'tel-national',
    placeholder: strings['phone.label'],
    onChange: (value: string) => setValues({ ...values, phone: value }),
    value: values.phone,
    error: errors.phone && touched.phone ? errors.phone : null,
  },
  {
    id: 'address',
    type: 'text',
    placeholder: strings['address.label'],
    onChange: (value: string) => setValues({ ...values, address: value }),
    value: values.address,
    error: errors.address && touched.address ? errors.address : null,
  },
];

export const FormItem = ({ option }: { option: TextType | SelectorType }) => {
  const labelWrapperClasses = cx({
    labelWrapper: true,
    labelVisible: Boolean(option.value),
  });

  return (
    <div className={styles.FormItem}>
      <div className={labelWrapperClasses}>
        <Text tagName="label" htmlFor={option.id} variant="tiny">
          {option.placeholder}
        </Text>
      </div>

      {option?.type === 'select' ? (
        <>
          <ListSelect
            id={option.id}
            options={option.options}
            placeholder={option.placeholder}
            sortFunction={(options) => options}
            hideHeader
            onChange={(value) => {
              option.onChange(value);
            }}
          />
          <InputError error={option.error} />
        </>
      ) : (
        <>
          <Input
            id={option.id}
            type={option.type}
            placeholder={option.placeholder}
            autoComplete={option.autoComplete}
            value={option.value}
            onChange={(value: string) => option.onChange(value)}
          />
          <InputError error={option.error} />
        </>
      )}
    </div>
  );
};

export const SignUpForm: React.FC<SignUpFormProps> = ({
  error,
  loading,
  initialValues,
  onSubmit,
}) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages);

  const errorMessage =
    error?.code === 409
      ? formatMessage(
          {
            defaultMessage:
              'You already have a log in registered in Mobble, if you would like to start a trial please reach out to {supportEmail}',
            description: 'auth.sign_up.form.error.409',
          },
          {
            supportEmail: (
              <a href="mailto:hello@mobble.io?subject=Trial" target="blank">
                <FormattedMessage
                  description="Mobble support email address"
                  defaultMessage="hello@mobble.io"
                />
              </a>
            ),
          }
        )
      : formatMessage(
          {
            defaultMessage: 'An error occured: {error}',
            description: 'auth.sign_up.form.error',
          },
          {
            error: error?.message,
          }
        );

  const SignUpSchema = Yup.object().shape({
    firstName: Yup.string().required(strings['firstName.error']),
    lastName: Yup.string().required(strings['lastName.error']),
    farmRole: Yup.string().required(strings['farmRole.error']),
    email: Yup.string()
      .email(strings['email.error'])
      .required(strings['email.error']),
    country: Yup.string().required(strings['country.error']),
    phone: Yup.string()
      .min(6, strings['phone.error'])
      .required(strings['phone.error']),
    address: Yup.string().required(strings['address.error']),
    password: Yup.string().required(strings['password.error']),
    termsAgreed: Yup.boolean().oneOf([true], strings['termsAgreed.error']),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={SignUpSchema}
        enableReinitialize
        isInitialValid
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setValues }) => {
          const formOptions = constructSignUpForm({
            values,
            setValues,
            errors,
            touched,
            strings,
          });
          return (
            <Form className={styles.SignUpForm}>
              <header>
                <Text align="center" tagName="h1" bold variant="card-title">
                  {strings.title}
                </Text>
              </header>

              <>
                {formOptions.map((option, index) => {
                  if (Array.isArray(option)) {
                    return (
                      <div key={index} className={styles.inputStack}>
                        {option.map((item, optionIndex) => (
                          <FormItem
                            option={item}
                            key={index + '+' + optionIndex}
                          />
                        ))}
                      </div>
                    );
                  }
                  return <FormItem key={index} option={option} />;
                })}
              </>

              <footer>
                <FormItem
                  option={{
                    id: 'password',
                    type: 'password',
                    autoComplete: 'new-password',
                    placeholder: strings['password.label'],
                    onChange: (value: string) =>
                      setValues({ ...values, password: value }),
                    value: values.password,
                    error:
                      errors.password && touched.password
                        ? errors.password
                        : null,
                  }}
                />

                <Checkbox
                  id="termsAgreed"
                  size="small"
                  label={
                    <span className={styles.termsLabel}>
                      {formatMessage(
                        {
                          description: 'termsAgreed.label',
                          defaultMessage:
                            'I have read and agree with the {customerAgreement} and {privacyPolicy}',
                        },
                        {
                          customerAgreement: (
                            <a
                              href="https://www.mobble.io/legal/customer-agreement"
                              target="blank"
                            >
                              <FormattedMessage
                                description="Mobble Customer Agreement page title"
                                defaultMessage="Mobble Customer Agreement"
                              />
                            </a>
                          ),
                          privacyPolicy: (
                            <a
                              href="https://www.mobble.io/legal/privacy-policy"
                              target="blank"
                            >
                              <FormattedMessage
                                description="Mobble Privacy Policy page title"
                                defaultMessage="Mobble Privacy Policy"
                              />
                            </a>
                          ),
                        }
                      )}
                    </span>
                  }
                  checked={values.termsAgreed}
                  onChange={() => {
                    setValues({
                      ...values,
                      termsAgreed: !values.termsAgreed,
                    });
                  }}
                  className={styles.termsAgreedCheckbox}
                />
                <InputError
                  error={
                    errors.termsAgreed && touched.termsAgreed
                      ? errors.termsAgreed
                      : null
                  }
                />

                <div role="alert" className={styles.formError}>
                  {error && <Text tagName="p">{errorMessage}</Text>}
                </div>

                <Button type="submit" loading={loading} flex>
                  {strings.submit}
                </Button>
              </footer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
