import { MobbleApp } from '@mobble/models/src/model/MobbleApps';
import { type ApiPrelude } from '../types';
import { Paddock } from '@mobble/models/src/model/Paddock';
import { makeQuantityOfArea } from '@mobble/shared/src/core/Quantity';

export const decodeMobbleApps = (raw: any[]): MobbleApp[] => {
  return raw.map(decodeMobbleApp);
};

export const decodeMobbleApp = (raw: any): MobbleApp => {
  return {
    id: raw.integrationProviderId,
    enabled: raw.status === 'enabled',
  };
};

const GET_MOBBLE_APPS = `
  query OrganisationIntegrations($organisationId: ID!) {
    organisationIntegrations(organisationId: $organisationId) {
      integrationProviderId
      status
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    organisationId,
  }: {
    organisationId: string;
  }): Promise<{ entities: MobbleApp[] }> => {
    const variables = {
      organisationId,
    };

    const response = await prelude.graphql({
      query: GET_MOBBLE_APPS,
      variables,
    });

    const entities = decodeMobbleApps(
      response.data.organisationIntegrations ?? []
    );

    return {
      entities,
    };
  };

const GET_MOBBLE_APP_AUTH_URI = `
  query OAuthAuthoriseLink(
    $integrationProviderId: String!
    $organisationId: String!
    $context: String!
  ) {
    oAuthAuthoriseLink(
      input: {
        integrationProviderId: $integrationProviderId
        organisationId: $organisationId
        context: $context
      }
    ) {
      integrationProviderId
      authorizeURI
    }
  }
`;

export interface MobbleAppAuthURI {
  authorizeURI: string;
  integrationProviderId: string;
}

const decodeMobbleAuthURI = (raw: any): MobbleAppAuthURI => {
  return {
    authorizeURI: raw.authorizeURI,
    integrationProviderId: raw.integrationProviderId,
  };
};

export const createMobbleAuthURI =
  (prelude: ApiPrelude) =>
  async ({
    integrationId,
    organisationId,
    context,
  }: {
    integrationId: string;
    organisationId: string;
    context: string;
  }): Promise<{ entity: MobbleAppAuthURI }> => {
    const variables = {
      integrationProviderId: integrationId,
      organisationId,
      context,
    };

    const response = await prelude.graphql({
      query: GET_MOBBLE_APP_AUTH_URI,
      variables,
    });

    const mobbleAuthURI = decodeMobbleAuthURI(response.data.oAuthAuthoriseLink);

    return {
      entity: mobbleAuthURI,
    };
  };

const ENABLE_MOBBLE_APP = `
  mutation EnableOrganisationIntegration(
    $input: OrganisationIntegrationInput!
  ) {
    enableOrganisationIntegration(input: $input) {
      integrationProviderId
      status
    }
  } 
  `;

export const enableMobbleApp =
  (prelude: ApiPrelude) =>
  async ({
    integrationId,
    organisationId,
  }: {
    integrationId: string;
    organisationId: string;
  }): Promise<{ entity: MobbleApp }> => {
    const variables = {
      input: {
        integrationProviderId: integrationId,
        organisationId,
      },
    };

    const response = await prelude.graphql({
      query: ENABLE_MOBBLE_APP,
      variables,
    });

    const mobbleApp = decodeMobbleApp(
      response.data.enableOrganisationIntegration
    );

    return {
      entity: mobbleApp,
    };
  };

const DISABLE_MOBBLE_APP = `
  mutation DisableOrganisationIntegration(
    $input: OrganisationIntegrationInput!
  ) {
    disableOrganisationIntegration(input: $input) {
      integrationProviderId
      status
    }
  }
`;

export const disableMobbleApp =
  (prelude: ApiPrelude) =>
  async ({
    integrationId,
    organisationId,
  }: {
    integrationId: string;
    organisationId: string;
  }): Promise<{ entity: MobbleApp }> => {
    const variables = {
      input: {
        integrationProviderId: integrationId,
        organisationId,
      },
    };

    const response = await prelude.graphql({
      query: DISABLE_MOBBLE_APP,
      variables,
    });

    const mobbleApp = decodeMobbleApp(
      response.data.disableOrganisationIntegration
    );

    return {
      entity: mobbleApp,
    };
  };

const AUTHENTICATE_MOBBLE_APP = `
  query OAuthCallback($state: String!, $code: String!) {
    oAuthCallback(input: { state: $state, code: $code }) {
      id
      attributes {
        key
        value
      }
    }
  }
  `;

interface AuthenticatedAttributes {
  key: string;
  value: string;
}

interface AuthenticatedResponse {
  id: string;
  attributes: AuthenticatedAttributes[];
}

export const authenticateStateAndCode =
  (prelude: ApiPrelude) =>
  async ({
    state,
    code,
  }: {
    state: string;
    code: string;
  }): Promise<{ entity: any }> => {
    const variables = {
      state,
      code,
    };

    const response = await prelude.graphql({
      query: AUTHENTICATE_MOBBLE_APP,
      variables,
    });

    const authenticatedResponse = response.data
      .oAuthCallback as AuthenticatedResponse;

    return {
      entity: authenticatedResponse,
    };
  };

// AgWorld paddock imports

export interface AgWorldProperty {
  name: string;
  paddocks: Paddock[];
}

const GET_AGWORLD_PADDOCKS = `
   mutation agWorldPaddocks($organisationId: String!) {
    agWorldPaddocks(input: { organisationId: $organisationId }) {
      name
      paddocks {
        name
        paddockType {
          title
        }
        size {
          unit
          value
        }
        geometry {
          type
          coordinates
        }
      }
    }
  }
`;

const decodeAgWorldPaddock =
  (propertyId: string) =>
  (propertyIndex: number) =>
  (agWorldPaddock: any, paddockIndex: number): Paddock => ({
    type: 'paddock',
    id: `${propertyIndex}-${paddockIndex}`,
    propertyId: propertyId,
    name: agWorldPaddock.name,
    properties: {
      size: makeQuantityOfArea(
        agWorldPaddock?.size?.unit,
        agWorldPaddock?.size?.value
      ),
    },

    geometry: agWorldPaddock?.geometry
      ? {
          polygon: {
            coordinates: agWorldPaddock.geometry.coordinates,
            type: 'Polygon',
          },
        }
      : null,

    mobs: null,
  });

const decodeAgWorldProperty =
  (propertyId: string) =>
  (agWorldPaddocks: any, propertyIndex: number): AgWorldProperty => ({
    name: agWorldPaddocks.name,
    paddocks: agWorldPaddocks.paddocks.map(
      decodeAgWorldPaddock(propertyId)(propertyIndex)
    ),
  });

const formatAgWorldProperties =
  (propertyId: string) =>
  (agWorldPaddocks: any): AgWorldProperty[] =>
    agWorldPaddocks.map(decodeAgWorldProperty(propertyId));

export const getAgWorldPaddocks =
  (prelude: ApiPrelude) =>
  async ({
    organisationId,
    propertyId,
  }: {
    organisationId: string;
    propertyId: string;
  }): Promise<{ entities: AgWorldProperty[] }> => {
    const variables = {
      organisationId,
    };

    const response = await prelude.graphql({
      query: GET_AGWORLD_PADDOCKS,
      variables,
    });

    const entities = formatAgWorldProperties(propertyId)(
      response.data.agWorldPaddocks
    );

    return {
      entities,
    };
  };
