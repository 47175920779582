import { type BaseEntity } from '@mobble/models/src/model/BaseEntity';
import { hash } from '@mobble/shared/src/core/String';
import { PreludeStatus } from './EntitySliceFactoryPrelude';

export type ExtStatus<A extends string = string, S = Status> = {
  action: A;
  status: S;
  updatedAt: number;
  succeededAt?: number;
  error?: string;
  loading?: boolean;
  extCursor?: ExtCursor;
};

export interface ExtCursor {
  total: number;
  cursorNext?: string;
}

export enum Status {
  Loading = 'status_loading',
  Failed = 'status_failed',
  Succeeded = 'status_succeeded',
}

export interface ExtFilterParams {
  [key: string]: string | number | boolean | string[] | number[];
}

/**
 * Why a Record instead of a Map?
 * Because this status is persisted in the local storage and we want to be able to serialize it (easily).
 */

export type ExtStatusPerAction<A extends string = string> = Record<
  A,
  ExtStatus<A>
>;

export type ExtStatusPerActionPerParent<A extends string = string> = Record<
  BaseEntity['id'],
  ExtStatusPerAction<A>
>;

export const requestStatusToStatus = (requestStatus: string): Status => {
  switch (requestStatus) {
    case 'pending':
      return Status.Loading;
    case 'fulfilled':
      return Status.Succeeded;
    case 'rejected':
      return Status.Failed;
    default:
      return Status.Succeeded;
  }
};

export const makeExtKey = (
  parentId: BaseEntity['id'],
  extFilter?: ExtFilterParams
) => (extFilter ? `${parentId}--${hash(extFilter)}` : parentId);

export const getForProperty =
  (extStatusPerActionPerParent: ExtStatusPerActionPerParent) =>
  (
    parentId: BaseEntity['id'],
    extFilter?: ExtFilterParams
  ): undefined | ExtStatusPerAction => {
    const key = makeExtKey(parentId, extFilter);
    return extStatusPerActionPerParent[key];
  };

export const getForAction =
  (extStatusPerAction: ExtStatusPerAction) =>
  <A extends string = string>(action: A): undefined | ExtStatus => {
    return extStatusPerAction[action];
  };

export const getForPropertyAndAction =
  (extStatusPerActionPerParent: ExtStatusPerActionPerParent) =>
  (parentId: BaseEntity['id'], extFilter?: ExtFilterParams) =>
  <A extends string = string>(action: A): undefined | ExtStatus => {
    const extStatusPerAction = getForProperty(extStatusPerActionPerParent)(
      parentId,
      extFilter
    );
    if (extStatusPerAction) {
      return getForAction(extStatusPerAction)(action);
    }
  };

export const updateStatusForAction =
  <A extends string = string>(extStatusPerAction: ExtStatusPerAction<A>) =>
  (
    extStatus: Pick<ExtStatus, 'action' | 'status'> & Partial<ExtStatus>
  ): ExtStatusPerAction<A> => {
    const curStatus = getForAction(extStatusPerAction)(extStatus.action);
    const curTime = new Date().getTime();
    return {
      ...extStatusPerAction,
      [extStatus.action]: {
        ...extStatus,
        updatedAt: extStatus?.updatedAt ?? curTime,
        succeededAt:
          extStatus.status === Status.Succeeded
            ? curTime
            : curStatus?.succeededAt,
        extCursor: {
          ...curStatus?.extCursor,
          ...extStatus?.extCursor,
        },
      },
    };
  };

export const updateStatusForXAndAction =
  <A extends string = string>(
    extStatusPerActionPerParent: ExtStatusPerActionPerParent<A>
  ) =>
  (parentId: BaseEntity['id'], extFilter?: ExtFilterParams) =>
  (
    extStatus: Pick<ExtStatus, 'action' | 'status'> & Partial<ExtStatus>
  ): ExtStatusPerActionPerParent<A> => {
    if (extFilter) {
      const key = makeExtKey(parentId, extFilter);
      return {
        ...extStatusPerActionPerParent,
        [key]: updateStatusForAction(
          (extStatusPerActionPerParent[key] || {}) as ExtStatusPerAction<A>
        )(extStatus),
      };
    }

    const updated = {
      ...extStatusPerActionPerParent,
      [parentId]: updateStatusForAction(
        (extStatusPerActionPerParent[parentId] || {}) as ExtStatusPerAction<A>
      )(extStatus),
    };

    const entries = Object.entries(updated);
    const cleared = entries.reduce((acc, [k, v]) => {
      if (k === parentId || !k.startsWith(parentId)) {
        return {
          ...acc,
          [k]: v,
        };
      }
      return acc;
    }, {});

    return cleared;
  };

export const updateStatusForParentAndPayloadAction =
  <A extends string = string>(
    extStatusPerActionPerParent: ExtStatusPerActionPerParent<A>
  ) =>
  (parentId: string, extFilter?: ExtFilterParams) =>
  (action: {
    type: string;
    meta: {
      requestId: string;
      requestStatus: string;
    };
    payload?: any;
  }): ExtStatusPerActionPerParent<A> => {
    const typePrefix = action.type
      .split('/')
      .filter((part: string) => part !== action.meta.requestStatus)
      .join('/');

    const status = requestStatusToStatus(action.meta.requestStatus);

    return updateStatusForXAndAction<A>(extStatusPerActionPerParent)(
      parentId,
      extFilter
    )({
      action: typePrefix,
      status,
      error: (action as any).error
        ? String((action as any).error?.message || (action as any).error)
        : undefined,
      loading: status === Status.Loading,
      extCursor: action.payload?.extCursor,
    });
  };

export const updateStatusForPayloadAction =
  <A extends string = string>(extStatusPerAction: ExtStatusPerAction<A>) =>
  (action: {
    type: string;
    meta: {
      requestId: string;
      requestStatus: string;
    };
    payload?: any;
  }): ExtStatusPerAction<A> => {
    const typePrefix = action.type
      .split('/')
      .filter((part: string) => part !== action.meta.requestStatus)
      .join('/');

    const status = requestStatusToStatus(action.meta.requestStatus);

    return updateStatusForAction<A>(extStatusPerAction)({
      action: typePrefix,
      status,
      error: (action as any).error
        ? String((action as any).error?.message || (action as any).error)
        : undefined,
      loading: status === Status.Loading,
      extCursor: action.payload?.extCursor,
    });
  };

export const extStatusToPreludeStatus = (
  extStatus?: null | ExtStatus,
  entitiesLoaded?: boolean
): PreludeStatus => {
  if (entitiesLoaded) {
    return PreludeStatus.Ready;
  }
  if (!extStatus) {
    return PreludeStatus.Loading;
  } else if (extStatus.status === Status.Loading) {
    return PreludeStatus.Loading;
  } else if (extStatus.status === Status.Failed) {
    if (extStatus.succeededAt || entitiesLoaded) {
      return PreludeStatus.Ready;
    }
    return PreludeStatus.Error;
  }
  return PreludeStatus.Ready;
};

export const checkEntityExpiration = (
  expiryInSeconds: number,
  extStatus: ExtStatus
) => {
  const now = new Date().getTime();
  const secondsAgo = extStatus?.updatedAt
    ? (now - extStatus.updatedAt) / 1000
    : -1;
  const hasExpired = secondsAgo > 0 && secondsAgo > expiryInSeconds;
  return { hasExpired, secondsAgo };
};
