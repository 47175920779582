import React from 'react';

import { Color } from '@mobble/colors';
import { getLivestockAgeColor } from '@mobble/models/src/model/Mob';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';

import { BadgeList } from '@src/components';
import { Box } from '@src/stories/Components/Layout/Box';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import { Table, type TableProps } from '@src/stories/Components/Layout/Table';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Text } from '@src/stories/Components/UI/Text';

import { type RootItem } from './usePropertyReportForRange';

export interface LivestockReconciliationReportProps {
  propertyTypes: ConfiguredPropertyType[];
  reportData: RootItem[] | null;
}

export const LivestockReconciliationReport: React.FC<
  LivestockReconciliationReportProps
> = ({ propertyTypes, reportData }) => {
  if (!reportData) {
    return (
      <VStack alignment="center">
        <Box spacing={4}>
          <Spinner color={Color.Black} />
        </Box>
      </VStack>
    );
  }

  const fields = ['type', 'breed', 'gender'];

  const sortRootItemsAlphabetically = (rootItems: RootItem[]) =>
    rootItems.sort((a, b) => {
      for (const field of fields) {
        if (a[field] > b[field]) return 1;
        if (a[field] < b[field]) return -1;
      }
      return 0;
    });

  const renderClasses = (arr: (string | number)[]) => {
    const badges = arr.map((a) => ({
      label: String(a),
    }));
    return (
      <BadgeList defaultColor={'var(--neutrals-bg-default)'} badges={badges} />
    );
  };

  const renderAges = (type: string, arr: (string | number)[]) => {
    const badges = arr.map((a) => ({
      label: String(a),
      color: getLivestockAgeColor(propertyTypes, type)(Number(a)),
    }));
    return <BadgeList badges={badges} />;
  };

  const UnClassed = () => (
    <Text
      i18n={{
        key: 'reports.livestock_reconciliation.table.item.empty',
      }}
    />
  );

  const tableData = toTableExtendedData(
    sortRootItemsAlphabetically(reportData)
  );

  const tableProps: TableProps<RootItem> = {
    title: { key: 'reports.livestock_reconciliation.table.title' },
    showTitle: true,
    columns: [
      {
        key: 'type',
        toValue: (item) => item.type,
      },
      {
        key: 'breed',
        toValue: (item) => item.breed,
      },
      {
        key: 'gender',
        toValue: (item) => item.gender,
        totals: false,
      },
      {
        key: 'starting_head',
        toValue: (item) => item.totals.startingHead,
        totals: true,
      },
      {
        key: 'natural_increase',
        toValue: (item) => item.totals.naturalIncrease,
        totals: true,
      },
      {
        key: 'purchased',
        toValue: (item) => item.totals.purchased,
        totals: true,
      },
      {
        key: 'casualties',
        toValue: (item) => item.totals.casualties,
        totals: true,
      },
      {
        key: 'sold',
        toValue: (item) => item.totals.sold,
        totals: true,
      },
      // {
      //   key: 'other',
      //   toValue: (item) => item.totals.other,
      //   totals: true,
      // },
      {
        key: 'closing_head',
        toValue: (item) => item.totals.closingHead,
        totals: true,
      },
      {
        key: 'diff_head',
        toValue: (item) => {
          return item.totals.closingHead - item.totals.startingHead;
        },
        render: (value: number, item) => {
          if (value === 0) {
            return null;
          }
          const isIncrease = value >= 0;
          const color = isIncrease ? Color.Green : Color.Red;
          const icon = isIncrease ? 'arrow-up' : 'arrow-down';
          return (
            <HStack alignment="center">
              <Icon size="small" name={icon} color={color} />
              <Text bold color={color} variant="body">
                {value}
              </Text>
            </HStack>
          );
        },
      },
    ].map((c) => ({
      ...c,
      label: {
        key: `reports.livestock_reconciliation.table.heading.colum.${c.key}`,
      },
    })),
    data: tableData,
    renderColSpan: (item, depth) => {
      if (item._title) {
        if (Array.isArray(item._title)) {
          if (depth === 1) {
            if (!item._title.length) {
              return <UnClassed />;
            }
            return renderClasses(item._title);
          } else if (depth === 2) {
            return renderAges(item.type, item._title);
          } else {
            return <Text variant="body">{item._title.join(', ')}</Text>;
          }
        }
      }
      return <Text variant="body">{item._title}</Text>;
    },
  };

  return <Table {...tableProps} />;
};

const toTableExtendedData = (data: RootItem[]) => {
  return data.map((rootItem) => {
    return {
      ...rootItem,
      _children: [
        ...Object.entries(rootItem.byClasses).map(([key, val]) => ({
          ...val,
          _title: JSON.parse(key),
          _span: 3,
          _children: [
            ...Object.entries(val.byAges).map(([key, val]) => ({
              ...val,
              _title: JSON.parse(key),
              _span: 3,
            })),
          ],
        })),
      ],
    };
  }, []);
};
