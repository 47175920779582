import React from 'react';
import { useNavigate } from 'react-router';

import { useI18n } from '@mobble/i18n';
import { filterPurchases, Purchase } from '@mobble/models/src/model/Purchase';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useMobs,
  usePaddocks,
  useProperties,
  usePurchases,
  useSetting,
} from '@mobble/store/src/hooks';

import { useLinking } from '@src/hooks/useLinking';
import { usePurchasesFilterItems } from '@src/hooks/usePurchasesFilterItems';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { makePurchasesTableColumns } from '@src/stories/Views/Purchases/List/purchasesTableColumns';
import { MemoisedPurchaseCard } from '@src/stories/Views/Purchases/PurchaseCard';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const SummaryPurchasesHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.summary_purchases',
        defaultMessage: 'Purchases',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.summaries',
            defaultMessage: 'Summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SUMMARIES_LIST);
      }}
    />
  );
};

export const SummaryPurchases: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const currencySymbol = useSetting('currencySymbol') as string;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const purchases = usePurchases(propertyId);
  const paddocks = usePaddocks(propertyId);
  const mobs = useMobs(propertyId);
  const filterItems = usePurchasesFilterItems();
  const navigate = useNavigate();

  const { refresh } = useEntitiesRefresher(
    [mobs, paddocks, purchases],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      mobs.prelude,
      purchases.prelude,
      paddocks.prelude,
    ],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const onClick = (purchase: Purchase) => {
    navigate(
      toPath(ROUTE_NAME.PURCHASE_DETAIL, {
        purchaseId: purchase.id,
      })
    );
  };

  const viewerProps: EntitiesViewerProps<Purchase> = {
    provider: purchases,
    onEmpty: formatMessage({
      defaultMessage: 'There are no purchases to display',
      description: 'purchases.table.empty',
    }),
    onRefresh: refresh,
    applyFilter: filterPurchases,
    filterItems,
    onClickTableRow: onClick,
    renderEntityCard: (purchase) => {
      return (
        <Box spacing={1}>
          <MemoisedPurchaseCard purchase={purchase} onClick={onClick} />
        </Box>
      );
    },
    tableColumns: makePurchasesTableColumns({
      paddocks: paddocks.entities,
      currencySymbol,
      propertyTypes: properties.selected.types,
      formatMessage,
    }),
  };
  return <EntitiesViewer key={properties.selected?.id} {...viewerProps} />;
};

export default {
  name: ROUTE_NAME.SUMMARY_PURCHASES,
  header: SummaryPurchasesHeader,
  component: SummaryPurchases,
};
