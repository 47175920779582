import React, { ComponentPropsWithoutRef, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './Tooltip.scss';
const cx = classNames.bind(styles);

export interface TooltipProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  /**
   * Unique identifier
   */
  id: string;

  /**
   * Text to display
   */
  label: string;

  /**
   * Set position of tooltip, defaults to `right`
   */
  position?: 'right' | 'left';

  /**
   * Set size of tooltip, defaults to `medium`
   */
  size?: 'small' | 'medium';

  /**
   * Single child element
   */
  children: React.ReactElement;
}

/**
 * Tooltips are used to label and icon or provide a contextual description of an icon.
 */
export const Tooltip: React.FC<TooltipProps> = ({
  id,
  label = 'Tooltip',
  position = 'right',
  size = 'medium',
  children,
  className,
  ...others
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const rootClasses = cx(
    {
      Tooltip: true,
      [position]: true,
      [size]: true,
      hidden: isHidden,
    },
    className
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFocused, isHovered]);

  // add hidden class when ESC key is pressed
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && (isFocused || isHovered)) {
      setIsHidden(true);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setIsHidden(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsHidden(false);
  };

  return (
    <div className={rootClasses} {...others}>
      {React.cloneElement(children, {
        'aria-labelledby': id,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })}
      <div role="tooltip" id={id}>
        {label}
      </div>
    </div>
  );
};

export default Tooltip;
