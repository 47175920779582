import { useLocation } from 'react-router';

export const useGetQueryParams = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const state = queryParams.get('state');
  const code = queryParams.get('code');

  return {
    state,
    code,
  };
};
