import { type ApiPrelude } from '../types';
import { fromRawDate, toISO8601 } from '@mobble/shared/src/core/Date';

const GET_PROPERTY_REPORT_FOR_RANGE = `
  query GetPropertyReportForRange(
    $propertyId: ID!
    $range: ReportQueryDatesInput!
  ) {
    propertyReportForRange(propertyId: $propertyId, range: $range) {
      start {
        type
        breed
        gender
        totalNumber
        totalSold
        totalIncome
        averagePricePerHead
        totalCasualties
        totalNaturalIncrease
        totalPurchased
        totalPurchasedPrice
        averagePurchasePricePerHead
        totalDse
        classes
        ages
        paddockId
        __typename
      }
      end {
        type
        breed
        gender
        totalNumber
        totalSold
        totalIncome
        averagePricePerHead
        totalCasualties
        totalNaturalIncrease
        totalPurchased
        totalPurchasedPrice
        averagePurchasePricePerHead
        totalDse
        classes
        ages
        paddockId
        __typename
      }
      __typename
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    propertyId,
    range,
  }: {
    propertyId: string;
    range: {
      start: Date;
      end: Date;
    };
  }): Promise<PropertyReportForRange> => {
    const variables = {
      propertyId,
      range: {
        start: toISO8601(range.start),
        end: toISO8601(range.end),
      },
    };
    const response = await prelude.graphql({
      query: GET_PROPERTY_REPORT_FOR_RANGE,
      variables,
    });

    return response?.data?.propertyReportForRange;
  };

export type PropertyReportForRange = {
  start: PropertyReportForRangeRow[];
  end: PropertyReportForRangeRow[];
};

export type PropertyReportForRangeRow = {
  ages: number[];
  averagePricePerHead: number;
  averagePurchasePricePerHead: number;
  breed: string;
  classes: string[];
  gender: string;
  paddockId: string | number;
  totalCasualties: number;
  totalDse: number;
  totalIncome: number;
  totalNaturalIncrease: number;
  totalNumber: number;
  totalPurchased: number;
  totalPurchasedPrice: number;
  totalSold: number;
  type: string;
};
