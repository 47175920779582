import React from 'react';

import { useAuthentication } from '@mobble/shared/src/hooks/useAuthentication';
import { useGetUser, useUpdateUser } from '@mobble/store/src/hooks/auth';

import { Container } from '@src/stories/Components/Layout/Container';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { Text } from '@src/stories/Components/UI/Text';
import { AuthWrapper } from '@src/stories/Views/Auth/AuthWrapper';
import {
  SetUpDisplayNameForm,
  SetUpDisplayNameFormValues,
} from '@src/stories/Views/SetUp/SetUpDisplayNameForm';

import * as ROUTE_NAME from '../config/routeNames';

export const SetUpDisplayName = () => {
  const [loading, setLoading] = React.useState(false);
  const { updateUser } = useUpdateUser();
  const user = useGetUser();
  const { signOut } = useAuthentication();

  const handleSubmit = (formValues: SetUpDisplayNameFormValues) => {
    if (!user) {
      return;
    }
    setLoading(true);
    updateUser({
      ...user,
      name: formValues.displayName,
    }).then(() => {
      setLoading(false);
    });
  };

  const handleCancel = () => {
    signOut();
  };

  return (
    <AuthWrapper>
      <Text
        align="center"
        variant="screen-title"
        bold
        i18n={{ key: 'setup.displayName.form.title' }}
      />
      <Spacer y={3} />
      <Text
        align="center"
        variant="screen-title"
        bold
        i18n={{ key: 'setup.displayName.form.message' }}
      />
      <Spacer y={6} />

      <Container width="small">
        <SetUpDisplayNameForm
          loading={loading}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </Container>
    </AuthWrapper>
  );
};

export default {
  name: ROUTE_NAME.SETUP_DISPLAY_NAME,
  component: SetUpDisplayName,
};
