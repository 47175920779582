import React from 'react';
import isEqual from 'lodash/isEqual';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { Color } from '@mobble/colors';
import { Text } from '../../Components/UI/Text';
import { TimelineEvent } from '../../Components/UI/TimelineEvent';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Purchase } from '@mobble/models/src/model/Purchase';
import { useSetting } from '@mobble/store/src/hooks/settings';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';
import { Spacer } from '@src/stories/Components/Layout/Spacer';

interface PurchaseCardProps {
  purchase: Purchase;
  last?: boolean;
  onClick: (purchase: Purchase) => void;
}

export const PurchaseCard: React.FC<PurchaseCardProps> = ({
  purchase,
  onClick,
  last,
}) => {
  const currencySymbol = useSetting('currencySymbol') as string;
  return (
    <Clickable
      fullWidth
      href={typeof onClick === 'function' ? () => onClick(purchase) : onClick}
    >
      <TimelineEvent
        title={`${purchase.mob.size} ${toMobDisplayName(purchase.mob)}`}
        date={purchase.date}
        last={last}
        color={Color.Red}
      >
        <Text
          i18n={{
            key: 'summary.purchases.card.price_per_head',
            params: {
              '%VALUE': formatMonetary(
                purchase.pricePerHeadCents,
                currencySymbol
              ),
            },
          }}
        />
        <Spacer y={1} />
        <Text
          i18n={{
            key: 'summary.purchases.card.total_spend',
            params: {
              '%VALUE': formatMonetary(
                purchase.pricePerHeadCents * purchase.mob.size,
                currencySymbol
              ),
            },
          }}
        />
        {purchase?.sellerName && (
          <>
            <Spacer y={1} />
            <Text
              i18n={{
                key: 'summary.purchases.card.seller',
                params: {
                  '%VALUE': purchase.sellerName,
                },
              }}
            />
          </>
        )}

        <Spacer y={1} />
      </TimelineEvent>
    </Clickable>
  );
};

export const MemoisedPurchaseCard = React.memo(PurchaseCard, (prev, next) => {
  return isEqual(prev.purchase, next.purchase) && prev.last === next.last;
});
