import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities } from '../reducers/paddockStockingRates';

export const usePaddockStockingRates = (
  propertyId?: string,
  paddockId?: string
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddockStockingRates);

  const parentId =
    propertyId && paddockId ? `${propertyId}_${paddockId}` : undefined;

  return proxyUseEntities({
    parentId,
    dispatch,
    state,
  });
};
