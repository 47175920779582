import Fuse from 'fuse.js';
import { type FilterItem, filterMatches, groupFilter } from './Filter';
export interface CustomMapLayer {
  id: string;
  propertyId: string;
  name: string;
  description: string;
  properties: any;
  features?: CustomMapFeature[];
}

export interface CustomMapFeature {
  id: string;
  layerId: string;
  propertyId: string;
  name: string;
  geometryType: 'Polygon';
  properties: any;
  geometry: any;
}

export const findCustomMapLayer =
  (customMapLayers: CustomMapLayer[]) =>
  (id: string): CustomMapLayer | undefined =>
    customMapLayers.find((customMapLayer) => customMapLayer.id === id);

export const filterCustomMapLayers = (
  customMapLayers: CustomMapLayer[],
  filter?: FilterItem[]
): CustomMapLayer[] => {
  if (!filter || filter.length === 0) {
    return customMapLayers;
  }
  const grouped = [...groupFilter(filter)];

  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredCustomMapLayer =
    searchQuery && searchQuery.type === 'search'
      ? searchCustomMapLayers(customMapLayers, searchQuery.value)
      : customMapLayers;

  return preFilteredCustomMapLayer.filter((customMapLayer) =>
    grouped.every(([_, filters]) =>
      filters.some(filterItemMatchesCustomMapLayer(customMapLayer))
    )
  );
};

export const searchCustomMapLayers = (
  customMapLayer: CustomMapLayer[],
  searchQuery: string
): CustomMapLayer[] => {
  const fuse = new Fuse(customMapLayer, {
    keys: [
      { name: 'name', weight: 2 },
      { name: 'description', weight: 1 },
    ],
    threshold: 0.3,
    shouldSort: true,
  });
  return fuse.search(searchQuery).map((a) => a.item);
};

export const filterItemMatchesCustomMapLayer =
  (customMapLayer: CustomMapLayer) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'id':
        return matches(customMapLayer.id);
      case 'search':
        return true;
      default:
        return true;
    }
  };
