import { type Property } from './Property';

export interface Preference {
  propertyId?: Property['id'];
  key: string;
  value: PreferenceValue;
}

export type PreferenceValue = null | string | boolean | number;

export const isEqual = (a: Preference) => (b: Preference) => {
  return a.propertyId === b.propertyId && a.key === b.key;
};

export const findPreference =
  (preferences: Preference[]) =>
  (key: string, propertyId?: string): undefined | Preference =>
    preferences.find((p) => p.key === key && p.propertyId === propertyId);

export const findPreferenceValue =
  (preferences: Preference[]) =>
  (key: string, propertyId?: string): PreferenceValue =>
    findPreference(preferences)(key, propertyId)?.value ?? null;
