import { type Property } from './Property';
import { type Task } from './Task';
import { type Point } from './MapGeometry';

export interface TaskGeometry {
  id: string;
  propertyId: Property['id'];
  taskId: Task['id'];
  point: Point;
}

export const findTaskGeometry =
  (taskGeometries: TaskGeometry[]) =>
  (taskId: Task['id']): TaskGeometry | undefined =>
    taskGeometries.find((p) => p.taskId === taskId);
