import React from 'react';
import { useI18n } from '@mobble/i18n';
import { Box } from '@src/stories/Components/Layout/Box';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Color } from '@mobble/colors';
import { arrayIntersperse } from '@mobble/shared/src/core/Array';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { Text } from '@src/stories/Components/UI/Text';

export interface AuthHelpProps {
  forgotPassword?: string;
  openHelp?: string;
  signIn?: string;
  signUp?: string;
}

export const AuthHelp: React.FC<AuthHelpProps> = ({
  forgotPassword,
  openHelp,
  signIn,
  signUp,
}) => {
  const { formatMessage } = useI18n();

  const links = [
    forgotPassword ? (
      <Clickable
        key="forgotPassword"
        color={Color.Black}
        href={forgotPassword}
        icon="help-circle"
        label={formatMessage({ defaultMessage: 'Forgot password?', description: 'auth.sign_in.help.forgot_password.label' })}
      />
    ) : null,
    openHelp ? (
      <Clickable
        key="help"
        color={Color.Black}
        href={openHelp}
        icon="info"
        label={formatMessage({ defaultMessage: 'Mobble help center', description: 'auth.sign_in.help.more_information.label' })}
      />
    ) : null,
    signIn ? (
      <VStack key="signIn" alignment="center">
        <HStack spacing={2}>
          <Text
            i18n={formatMessage({ defaultMessage: 'Already have an account?', description: 'auth.sign_in.help.already_have_account.label' })}
          />
          <Spacer x={1} />
          <Clickable
            color={Color.Blue}
            href={signIn}
            bold
            label={formatMessage({ defaultMessage: 'Sign In', description: 'auth.sign_in.help.sign_in.label' })}
          />
        </HStack>
      </VStack>
    ) : null,
    signUp ? (
      <VStack key="signUp" alignment="center">
        <HStack spacing={2}>
          <Text i18n={formatMessage({ defaultMessage: `Don't have an account?`, description: 'auth.sign_up.help.dont_have_account.label' })} />
          <Spacer x={1} />
          <Clickable
            color={Color.Blue}
            href={signUp}
            bold
            label={formatMessage({ defaultMessage: 'Sign Up', description: 'auth.sign_up.help.sign_in.label' })}
          />
        </HStack>
      </VStack>
    ) : null,
  ].filter(Boolean) as JSX.Element[];

  return (
    <Box mobileSpacing={2}>
      <Spacer y={4} />
      <VStack>
        {arrayIntersperse(
          (index) => (
            <Spacer key={index} y={2} />
          ),
          links
        )}
      </VStack>
    </Box>
  );
};
