import { Type } from '@mobble/shared/src/core/Quantity';
import { type ApiPrelude } from '../types';
import { ProductApplication } from '@mobble/models/src/model/ProductApplication';

const GET_CHEMICAL_APPLICATIONS = `
  query GetChemicalApplications($propertyId: ID!, $id: ID!){
    getChemicalApplications(propertyId: $propertyId, id: $id) {
      nodes {
        id
        class
        mobIDs
        batchID
        date
        paddockIDs
        createdByID
        quantityUnit
        quantityAppliedTotal
        mobActionID
        paddockActionID
      }
    }
  }
`;

const GET_FEED_APPLICATIONS = `
  query GetFeedApplications($propertyId: ID!, $id: ID!){
    getFeedApplications(propertyId: $propertyId, id: $id) {
      nodes {
        id
        class
        mobIDs
        batchID
        date
        paddockIDs
        createdByID
        quantityUnit
        quantityAppliedTotal
        mobActionID
        paddockActionID
      }
    }
  }
`;

const decodedecodeProductApplications =
  (propertyId: string, productId: string, inventoryType: 'chemical' | 'feed') =>
  (raw: any): ProductApplication[] => {
    return raw.map(
      decodeProductApplication(propertyId, productId, inventoryType)
    );
  };

const decodeProductApplication =
  (propertyId: string, productId: string, inventoryType: 'chemical' | 'feed') =>
  (raw: any): ProductApplication => {
    return {
      id: raw.id,
      propertyId,
      class: raw.class,
      inventoryType,
      mobIds: raw.mobIDs,
      productId: productId,
      batchId: raw.batchID,
      date: raw.date,
      paddockIds: raw.paddockIDs,
      createdById: raw.createdByID,
      quantity: {
        type: Type.MassVolume,
        unit: raw.quantityUnit,
        value: raw.quantityAppliedTotal,
      },
      mobActionId: raw.mobActionID ?? null,
      paddockActionId: raw.paddockActionID ?? null,
    };
  };

const getChemicalApplications =
  (prelude: ApiPrelude) =>
  async ({
    propertyId,
    id,
  }: {
    propertyId: string;
    id: string;
  }): Promise<{ entities: ProductApplication[] }> => {
    const response = await prelude.graphql({
      query: GET_CHEMICAL_APPLICATIONS,
      variables: {
        propertyId: propertyId,
        id: id,
      },
    });

    const entities = decodedecodeProductApplications(
      propertyId,
      id,
      'chemical'
    )(response?.data?.getChemicalApplications?.nodes ?? []);

    return { entities };
  };

const getFeedApplications =
  (prelude: ApiPrelude) =>
  async ({
    propertyId,
    id,
  }: {
    propertyId: string;
    id: string;
  }): Promise<{ entities: ProductApplication[] }> => {
    const response = await prelude.graphql({
      query: GET_FEED_APPLICATIONS,
      variables: {
        propertyId: propertyId,
        id: id,
      },
    });

    const entities = decodedecodeProductApplications(
      propertyId,
      id,
      'feed'
    )(response?.data?.getFeedApplications?.nodes ?? []);

    return { entities };
  };

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{ entities: ProductApplication[] }> => {
    const [propertyId, productId, type] = parentId.split('_');

    if (type === 'chemical') {
      return await getChemicalApplications(prelude)({
        propertyId,
        id: productId,
      });
    }

    return await getFeedApplications(prelude)({ propertyId, id: productId });
  };
