import React, { ComponentPropsWithoutRef, ReactElement } from 'react';
import classNames from 'classnames/bind';

import { type InputProps } from '../Input';
import InputError from '../InputError';

import styles from './InputContainer.scss';
const cx = classNames.bind(styles);

export interface InputContainerProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  /**
   * Used to bind input and label
   */
  id: string;

  /**
   * Text to display
   * Set appropriate `aria-` attributes if not using a label
   */
  label?: string;

  /**
   * Show error message below the input
   */
  error?: string;

  /**
   * Required field
   */
  required?: boolean;

  /**
   * A single `Input` component
   */
  children: ReactElement<InputProps>;
}

/**
 * InputContainer wraps an `Input` component and displays a label and optional error message
 */
const InputContainer: React.FC<InputContainerProps> = ({
  id,
  label,
  children,
  error,
  required,
  className,
  ...others
}) => {
  const rootClasses = cx(
    {
      InputContainer: true,
      hasError: !!error,
      required: required,
    },
    className
  );

  return (
    <div className={rootClasses} {...others}>
      {label && (
        <label id={`${id}-label`} htmlFor={id}>
          {label}
        </label>
      )}
      {children}
      <InputError error={error} />
    </div>
  );
};

export default InputContainer;
