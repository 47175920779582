import React from 'react';
import { Box } from '@src/stories/Components/Layout/Box';
import { PurchaseInfoTable } from '@src/stories/Views/Purchases/PurchaseInfoTable';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

import {
  usePurchaseScreen,
  usePurchaseScreenHeader,
} from './usePurchaseScreen';
import styles from './purchaseDetail.scss';

export const PurchasesDetailHeader: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const { purchaseId } = route.params;
  const props = usePurchaseScreenHeader(purchaseId);

  return <ScreenHeader {...props} />;
};

export const PurchasesDetail: React.FC<ScreenRendererProps> = ({ route }) => {
  const { purchaseId } = route.params;
  const { prelude, purchase, paddock, properties } =
    usePurchaseScreen(purchaseId);

  if (prelude) {
    return prelude;
  }

  return (
    <Box className={styles.purchaseInfoTabTable}>
      <PurchaseInfoTable
        purchase={purchase.entity}
        paddock={paddock.entity}
        propertyTypes={properties.selected?.types}
      />
    </Box>
  );
};

export default {
  name: ROUTE_NAME.PURCHASE_DETAIL,
  header: PurchasesDetailHeader,
  component: PurchasesDetail,
};
