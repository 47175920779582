import { type PaddockGroupedStockingRate } from '@mobble/models/src/model/PaddockGroupedStockingRate';
import { type ApiPrelude } from '../types';

const GET_PADDOCK_GROUPED_STOCKING_RATES = `
  query paddockGroupedStockingRates($propertyId: ID!) {
    paddockGroupedStockingRates(propertyId: $propertyId) {
      totalCount
      nodes {
        paddockId
        stockingRate {
          stockingRate
          stockingRateByArea
          totalSheep
          totalCattle
          totalGoats
          totalOther
        }
      }
    }
  }
`;

const decodePaddockGroupedStockingRate =
  (propertyId: string) =>
  (raw: any): PaddockGroupedStockingRate => {
    return {
      id: raw.paddockId,
      propertyId,
      paddockId: raw.paddockId,
      stockingRate: {
        stockingRate: raw.stockingRate.stockingRate,
        stockingRateByArea: raw.stockingRate.stockingRateByArea,
        totalSheep: raw.stockingRate.totalSheep,
        totalCattle: raw.stockingRate.totalCattle,
        totalGoats: raw.stockingRate.totalGoats,
        totalOther: raw.stockingRate.totalOther,
      },
    };
  };

const decodePaddockGroupedStockingRates =
  (propertyId: string) =>
  (raw: any[]): PaddockGroupedStockingRate[] =>
    raw.map(decodePaddockGroupedStockingRate(propertyId));

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{ entities: PaddockGroupedStockingRate[] }> => {
    const response = await prelude.graphql({
      query: GET_PADDOCK_GROUPED_STOCKING_RATES,
      variables: {
        propertyId: parentId,
      },
    });

    const entities = decodePaddockGroupedStockingRates(parentId)(
      response?.data?.paddockGroupedStockingRates?.nodes ?? []
    );
    return { entities };
  };
