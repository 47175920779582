import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';

import Text from '../Text';

import styles from './InputError.scss';
const cx = classNames.bind(styles);

export interface InputErrorProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * Error message
   */
  error?: string;

  /**
   * Size of the error message, default is 'medium'
   */
  size?: 'small' | 'medium';
}

/**
 * InputError displays an error message. It's usually used with `InputContainer`.
 */
const InputError: React.FC<InputErrorProps> = ({
  error,
  size = 'medium',
  className,
  ...others
}) => {
  const rootClasses = cx(
    {
      InputError: true,
    },
    className
  );

  return (
    <div role="alert" className={rootClasses} {...others}>
      {error && (
        <Text variant={size === 'medium' ? 'body' : 'small'} tagName="p">
          {error}
        </Text>
      )}
    </div>
  );
};

export default InputError;
