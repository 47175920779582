import React from 'react';
import { Box } from '@src/stories/Components/Layout/Box';
import { AgWorldProperty } from '@mobble/service/src/ext/mobble-apps';
import { Checkbox } from '@src/stories/Components/UI/Checkbox';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import { Color } from '@mobble/colors';
import { Text } from '@src/stories/Components/UI/Text';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Button } from '@src/stories/Components/UX/Button';

import styles from './importAgWorldPaddocks.scss';

interface ImportAgWorldPaddocksFormProps {
  propertyPaddocks: AgWorldProperty[];
  isSubmittable: boolean;
  isSubmitting: boolean;
  onSubmit: () => void;
  paddockImportSuccess: boolean;
  paddockImportErrors: any;
  loadingProgress: number;
  onClickContinue: () => void;
  selectAllPaddocksOfProperty: (propertyName: string) => void;
  areAllPaddocksOfPropertySelected: (propertyName: string) => boolean;
  selectPaddock: (paddockId: string) => void;
  isPaddockSelected: (paddockId: string) => boolean;
}

export const ImportAgWorldPaddocksForm: React.FC<
  ImportAgWorldPaddocksFormProps
> = ({
  propertyPaddocks,
  isSubmittable,
  isSubmitting,
  onSubmit,
  paddockImportSuccess,
  paddockImportErrors,
  loadingProgress,
  onClickContinue,
  selectAllPaddocksOfProperty,
  areAllPaddocksOfPropertySelected,
  selectPaddock,
  isPaddockSelected,
}) => {
  return (
    <Box>
      <VStack alignment="center">
        <Box className={styles.container}>
          <Box className={styles.tile}>
            <Box className={styles.tileInner}>
              <Header />
              {propertyPaddocks.length && !isSubmitting && !paddockImportSuccess
                ? propertyPaddocks.map((property) => (
                    <Box key={property.name}>
                      <VStack>
                        <Box spacing={1}>
                          <ListItem
                            onClick={() =>
                              selectAllPaddocksOfProperty(property.name)
                            }
                            selected={areAllPaddocksOfPropertySelected(
                              property.name
                            )}
                            name={property.name}
                          />
                        </Box>
                        <Box className={styles.paddockList}>
                          {property.paddocks.map((paddock) => (
                            <>
                              <Box spacing={1.5}>
                                <ListItem
                                  onClick={() => selectPaddock(paddock.id)}
                                  selected={isPaddockSelected(paddock.id)}
                                  name={paddock.name}
                                />
                              </Box>
                              <Spacer y={0.25} />
                              <Box
                                height={0.25}
                                flex
                                background={Color.AlmostWhite}
                              />
                            </>
                          ))}
                        </Box>
                      </VStack>
                    </Box>
                  ))
                : null}

              {!propertyPaddocks.length ? (
                <VStack alignment="center">
                  <Box spacing={2}>
                    <Spinner color={Color.Green} />
                  </Box>
                </VStack>
              ) : null}

              {isSubmitting ? (
                <VStack alignment="center">
                  <Box spacing={2}>
                    <Spinner color={Color.Green} />
                  </Box>
                  <Spacer y={2} />
                  <Text bold>{loadingProgress}%</Text>
                </VStack>
              ) : null}

              {paddockImportSuccess ? (
                <VStack alignment="center">
                  <Box spacing={2}>
                    <Text
                      i18n={{ key: 'paddock-import.form.finished.label' }}
                      bold
                      variant="larger"
                    />
                  </Box>
                </VStack>
              ) : null}

              {paddockImportErrors ? (
                <VStack alignment="center">
                  <Spacer y={2} />
                  <HStack alignment="center">
                    <Icon name="error" color={Color.LightGrey} size="normal" />
                    <Spacer x={2} />
                    <Text
                      i18n={{
                        key: 'paddock-import.form.failed.label',
                        params: {
                          '%NUMBER': paddockImportErrors?.length || 0,
                        },
                      }}
                      variant="small"
                      color={Color.Grey}
                    />
                  </HStack>
                </VStack>
              ) : null}

              <Spacer y={4} />

              {isSubmittable ? (
                <VStack alignment="center">
                  <Button
                    label={{ key: 'paddock-import.form.import.button' }}
                    onClick={onSubmit}
                  />
                </VStack>
              ) : null}

              {paddockImportSuccess ? (
                <VStack alignment="center">
                  <Button
                    label={{ key: 'paddock-import.form.continue.button' }}
                    onClick={onClickContinue}
                  />
                </VStack>
              ) : null}

              <Spacer y={2} />
            </Box>
          </Box>
        </Box>
      </VStack>
      <Spacer y={2} />
    </Box>
  );
};

const Header = () => (
  <VStack alignment="center">
    <Spacer y={4} />
    <HStack>
      <img
        src={require('@assets/mobble-apps-icons/agworld.png')}
        className={styles.logo}
      />
      <Spacer x={2} />
      <Icon name="arrow-right" color={Color.Grey} />
      <Spacer x={2} />
      <img
        src={require('@assets/mobble-apps-icons/mobble.png')}
        className={styles.logo}
      />
    </HStack>
    <Spacer y={3} />
    <Text i18n={{ key: 'paddock-import.form.title' }} bold />
    <Spacer y={3} />
  </VStack>
);

const ListItem = ({
  onClick,
  selected,
  name,
}: {
  onClick: () => void;
  selected: boolean;
  name: string;
}) => (
  <HStack alignment="center">
    <Clickable href={onClick}>
      <Checkbox selected={selected} />
    </Clickable>
    <Spacer x={2} />
    <Text>{name}</Text>
  </HStack>
);
