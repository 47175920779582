import { MobAction } from '@mobble/models/src/model/MobAction';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { FilterPerProperty } from '../lib/filter';
import { thunkGetAll as thunkGetAllMobs } from './mobs';
import { thunkGetAll as thunkGetAllHistoricEvents } from './historicEvents';

export interface MobActionsState {
  entities: MobAction[];
  extStatus: ExtStatusPerActionPerParent;
  filter: FilterPerProperty;
  sort: {};
}

export const defaultState: MobActionsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const name = 'mobActions';
const toParentId = ({ propertyId }: MobAction) => propertyId;

const entitySlice = entitySliceFactory<MobActionsState>({
  name,
  initialState: defaultState,
  toParentId,
  onGetAll: mobbleService.api.mobActions.get,
  onFind: mobbleService.api.mobActions.find,
  onCreate: mobbleService.api.mobActions.create,
  onUpdate: mobbleService.api.mobActions.update,
  onDelete: mobbleService.api.mobActions.remove,
  afterMutation: async ({ entity }, dispatch) => {
    dispatch(thunkGetAllMobs({ parentId: toParentId(entity) }));
    dispatch(thunkGetAllHistoricEvents({ parentId: toParentId(entity) }));
  },
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
