// mhttps://github.com/mapbox/mapbox-gl-draw-static-mode
import { DrawCustomMode } from '@mapbox/mapbox-gl-draw';

export const StaticMode: DrawCustomMode = {
  onSetup: function () {
    this.setActionableState({
      trash: false,
      combineFeatures: false,
      uncombineFeatures: false,
    });
    return {};
  },

  toDisplayFeatures: function (state, geojson, display) {
    display(geojson);
  },
};
