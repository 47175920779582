import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import {
  Casualty,
  findCasualtiesForMob,
} from '@mobble/models/src/model/Casualty';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { Paddock, paddockForMob } from '@mobble/models/src/model/Paddock';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useCasualties,
  useMob,
  useMobs,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

import { useGetIndividualMobActions } from '@src/hooks/useIndividualMobActions';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { HistoricEvents } from '@src/screens/HistoricEvents/HistoricEvents';
import { Box } from '@src/stories/Components/Layout/Box';
import { CasualtiesViewer } from '@src/stories/Views/Casualty/List/CasualtiesViewer';
import { Provider } from '@src/stories/Views/Entities/types';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { TabBar } from '@src/stories/Views/Misc/TabBar';
import { MobInfoTable } from '@src/stories/Views/Mob/MobInfoTable';
import { MobActionsViewer } from '@src/stories/Views/MobAction/List/MobActionsViewer';
import { ChartMonthlyCasualtiesByMob } from '@src/stories/Views/Paddock/Charts/ChartCasualtiesOverTime';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import styles from './mobDetail.scss';

type MobDetailMobileTab = 'info' | 'history';

export const MobDetailHeader: React.FC<ScreenRendererProps> = (props) => {
  const { mobId = '', paddockId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const mob = useMob(mobId);
  const mobs = useMobs(properties.selected?.id);
  const paddocks = usePaddocks(
    mob.entity?.propertyId ?? properties.selected?.id
  );
  const mobName = mob.entity ? toMobDisplayName(mob.entity) : '';

  const quickLinks = mobs.entities
    .map((m) => {
      const p = paddockForMob(paddocks.entities)(m);
      if (!p) {
        return null;
      }
      return {
        title: toMobDisplayName(m, true),
        href: toPath(ROUTE_NAME.MOB_DETAIL, {
          paddockId: p.id,
          mobId: m.id,
        }),
      };
    })
    .filter(Boolean)
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <ScreenHeader
      title={
        mobName ||
        formatMessage({
          defaultMessage: 'Mob details',
          description: 'screen.title.mob_detail',
        })
      }
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Mobs',
            description: 'screen.title.mobs',
          }),
          href: ROUTE_NAME.MOBS_LIST,
        },
      ]}
      quickLinks={quickLinks}
      actions={[
        {
          label: formatMessage(
            {
              defaultMessage: 'Edit "{TITLE}"',
              description: 'screen.title.edit_x',
            },
            {
              TITLE: mobName,
            }
          ),
          icon: 'edit',
          href: toPath(ROUTE_NAME.MOB_DETAIL_EDIT, { paddockId, mobId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const MobDetail: React.FC<ScreenRendererProps> = (props) => {
  const { mobId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const casualties = useCasualties(propertyId);
  const mob = useMob(mobId, propertyId);
  const mobActions = useGetIndividualMobActions(mobId, propertyId);
  const paddocks = usePaddocks(propertyId);

  useEntitiesRefresher([casualties, mobActions, paddocks], propertyId);

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
    notFound: [mob.entity],
  });

  if (prelude) {
    return prelude;
  }

  const paddock = paddockForMob(paddocks.entities)(mob.entity);
  const casualtiesForMob: Provider<Casualty> = {
    ...casualties,
    entities: findCasualtiesForMob(casualties.entities)(mobId),
  };

  const [activeTab, setActiveTab] = useState<MobDetailMobileTab>(
    (hash.slice(1) as MobDetailMobileTab) || 'info'
  );

  const tabs = [
    {
      label: formatMessage({
        defaultMessage: 'Info',
        description: 'screen.title.mob_detail_info',
      }),
      value: 'info',
      isActive: activeTab === 'info',
      onClick: (tab) => {
        setActiveTab(tab);
        window.location.hash = `#${tab}`;
      },
    },
    {
      label: formatMessage({
        defaultMessage: 'History',
        description: 'screen.title.mob_detail_history',
      }),
      value: 'history',
      isActive: activeTab === 'history',
      onClick: (tab) => {
        setActiveTab(tab);
        window.location.hash = `#${tab}`;
      },
    },
  ];

  const handleClickPaddock = (paddock: Paddock) => {
    navigate(toPath(ROUTE_NAME.PADDOCK_DETAIL, { paddockId: paddock.id }));
  };

  return (
    <>
      <TabBar
        tabs={tabs}
        backgroundColor={Color.Purple}
        className={styles.tabBar}
      />
      <Box className={styles.mobDetailLayout}>
        <Box
          className={[
            styles.mobTabInfo,
            styles.tab,
            activeTab === 'info' ? styles.active : null,
          ]}
        >
          <Box className={styles.mobTabInfoTable}>
            <MobInfoTable
              mob={mob.entity}
              paddock={paddock}
              onClickPaddock={handleClickPaddock}
            />
          </Box>
        </Box>
        <Box
          className={[
            styles.tab,
            activeTab === 'history' ? styles.active : null,
          ]}
        >
          <HistoricEvents
            title={formatMessage({
              defaultMessage: 'History',
              description: 'screen.title.mob_detail_history',
            })}
            className={styles.mobTabHistoryTimeline}
            asList
            extFilter={{ mobId }}
          />
        </Box>
        <Box className={[styles.mobTabCasualties, styles.tab]}>
          <CasualtiesViewer
            title={formatMessage({
              defaultMessage: 'Casualties',
              description: 'mob_detail.casualties.title',
            })}
            showTitle
            provider={casualtiesForMob}
          />
        </Box>
        <Box className={[styles.mobActionsTable, styles.tab]}>
          <MobActionsViewer
            title={formatMessage({
              description: 'screen.title.summary_mob_actions',
              defaultMessage: 'Mob actions',
            })}
            showTitle
            provider={mobActions}
          />
        </Box>
        {casualtiesForMob.entities.length > 0 && (
          <Box className={styles.mobTabChartCasualties}>
            <ChartMonthlyCasualtiesByMob
              casualties={casualtiesForMob.entities}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default {
  name: ROUTE_NAME.MOB_DETAIL,
  header: MobDetailHeader,
  component: MobDetail,
};
