import { versionSingleton } from '../interfaces/Version';

export const useVersion = () => {
  const version = versionSingleton.getPackageVersion();

  const reference = version ?? '';

  return {
    reference,
    package: version,
  };
};
