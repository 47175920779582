import { ResponsiveLine } from '@nivo/line';
import { theme } from '@src/stories/Components/Charts/config';
import { Text } from '@src/stories/Components/UI/Text';
import styles from './chartInventoryItemUsageGraph.scss';
import { useEffect, useState } from 'react';

export const ChartInventoryItemUsageGraph = ({
  data,
  unit,
  maxY,
}: {
  data: any;
  unit: string;
  maxY: number;
}) => {
  // set the graph to an empty array and then set the data after 100ms
  // this is to prevent the graph from rerendering and deforming the graph
  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    setChartData([]);
    const dataTimeout = setTimeout(() => {
      setChartData(data);
    }, 100);

    return () => clearTimeout(dataTimeout);
  }, [JSON.stringify(data)]);

  return (
    <ResponsiveLine
      animate={true}
      margin={{ top: 10, right: 10, bottom: 50, left: 40 }}
      data={chartData}
      pointSize={0}
      colors={{ datum: 'color' }}
      lineWidth={3}
      curve="stepAfter"
      enableArea={true}
      areaOpacity={0.6}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        useUTC: false,
        precision: 'day',
      }}
      xFormat="time:%d-%m-%Y"
      yScale={{
        type: 'linear',
        stacked: true,
        min: 0,
        max: maxY + maxY * 0.1,
      }}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'slices',
        'points',
        'mesh',
        'legends',
      ]}
      axisBottom={{
        format: '%b %Y',
        tickRotation: -45,
      }}
      theme={theme}
      enablePointLabel={false}
      enableSlices={'x'}
      enableCrosshair={true}
      sliceTooltip={({ slice }) => {
        const date = slice.points[0].data.xFormatted as string;
        const value = slice.points[0].data.yFormatted as number;

        return <GraphTooltip date={date} value={value} unit={unit} />;
      }}
    />
  );
};

const GraphTooltip = ({
  date,
  value,
  unit,
}: {
  date: string;
  value: number;
  unit: string;
}) => (
  <div className={styles.tooltip}>
    <Text tagName="h1" variant="small" bold>
      {date}
    </Text>

    <table border={0} cellPadding={0} cellSpacing={0}>
      <tbody>
        <tr>
          <td>
            <Text variant="small">{value}</Text>
          </td>
          <td>
            <Text variant="small" bold align="right">
              {unit}
            </Text>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
