import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/taskGeometries';

export const useTaskGeometries = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.taskGeometries);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const useTaskGeometry = (taskId: string, propertyId: string) => {
  const dispatch = useRootDispatch();

  const state = useRootSelector((s) => s.taskGeometries);

  return proxyUseEntity({
    entityId: taskId,
    parentId: propertyId,
    dispatch,
    state,
  });
};
