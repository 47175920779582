import { type I18nDictionary } from './i18n-dictionary';

export interface I18nItem {
  key?: string;
  defaultTranslation?: string;
  params?: Record<string, string | number>;
  pluralise?: number;
}

export type Translate = ReturnType<typeof translate>;

export const translate =
  (dict: I18nDictionary) =>
  ({ key, defaultTranslation, params, pluralise }: I18nItem): string => {
    defaultTranslation = defaultTranslation ?? key;

    if (key && pluralise === 1) {
      params = { ...params, '%COUNT': 1 };
      key = `${key}.single`;
    } else if (key && typeof pluralise !== 'undefined') {
      params = { ...params, '%COUNT': pluralise };
      key = `${key}.multiple`;
    }

    const translation = key
      ? dict?.get(key) ?? defaultTranslation
      : defaultTranslation;

    if (!translation) {
      return undefined;
    }

    if (params) {
      return Object.entries(params).reduce(
        (acc, [a, value]) => acc.replace(new RegExp(a, 'g'), String(value)),
        translation
      );
    }

    return translation;
  };
