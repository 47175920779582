import * as Turf from '@turf/turf';
import {
  BBox,
  Feature,
  LineString,
  MultiPolygon,
  Point,
  Polygon,
  Position,
} from 'geojson';
import polylabel from 'polylabel';
import {
  makeQuantityOfDistance,
  makeQuantityOfArea,
  type QuantityOfArea,
  type QuantityOfDistance,
  DistanceUnits,
  convertDistance,
  operate,
} from '@mobble/shared/src/core/Quantity';

export interface LatLng {
  latitude: number;
  longitude: number;
}

// GeoJson realm
export type FeaturePoint = Feature<Point>;
export type FeaturePolygon = Feature<Polygon>;
export type FeatureLineString = Feature<LineString>;
export type BoundingBox = BBox;
export { Position, Point, Feature, MultiPolygon, Polygon, BBox, LineString };

export interface Line {
  a: Point;
  b: Point;
  distance: QuantityOfDistance;
  shape: Feature<LineString>;
}

export type Closest = {
  point: Point;
  distance: QuantityOfDistance;
};

export const defaultBoundingBox: [number, number, number, number] = [
  143.896700102, -42.960585524, 172.638268692, -19.157008156,
];

export const latLngToPosition = (latLng: LatLng): Position => {
  if (!latLng) {
    return null;
  }
  return [latLng.longitude, latLng.latitude];
};

export const positionToPoint = (position: Position): Point => {
  if (!position) {
    return null;
  }
  return Turf.point(position).geometry;
};

export const pointToPosition = (point: Point): Position => {
  if (!point) {
    return null;
  }
  return point.coordinates;
};

export const latLngToPoint = (latLng: LatLng): Point => {
  if (!latLng) {
    return null;
  }
  return featurePointToPoint(latLngToFeaturePoint(latLng));
};

export const latLngToFeaturePoint = (latLng: LatLng): FeaturePoint => {
  if (!latLng) {
    return null;
  }
  return Turf.point(latLngToPosition(latLng));
};

export const featurePointToPoint = (featurePoint: FeaturePoint): Point => {
  if (!featurePoint) {
    return null;
  }
  return featurePoint.geometry;
};

export const pointToFeaturePoint = (point: Point): FeaturePoint => {
  if (!point) {
    return null;
  }
  return Turf.feature(point);
};

export const pointsToLineString = (points: Point[]): LineString => {
  if (!points || points.length < 2) {
    return null;
  }
  return Turf.lineString(points.map(pointToPosition)).geometry;
};

export const featurePolygonToPolygon = (
  featurePolygon: FeaturePolygon
): Polygon => {
  if (!featurePolygon) {
    return null;
  }
  return featurePolygon.geometry;
};

export const polygonToLabelCenterPoint = (
  polygon: Polygon | FeaturePolygon
): Point => {
  if (!polygon || !polygon.type) {
    return null;
  }

  const coordinates =
    polygon.type === 'Feature'
      ? polygon.geometry.coordinates
      : polygon.coordinates;

  if (!coordinates || coordinates.length === 0 || !coordinates[0][0]) {
    return null;
  }

  const [longitude, latitude] = polylabel(coordinates, 0.001);
  return latLngToPoint({ latitude, longitude });
};

export const polygonCenter = (polygon: Polygon): Point => {
  if (
    !polygon ||
    !polygon.type ||
    !polygon.coordinates ||
    !polygon.coordinates[0] ||
    !polygon.coordinates[0][0]
  ) {
    return null;
  }

  const [longitude, latitude] = Turf.center(polygon).geometry.coordinates;
  return latLngToPoint({ latitude, longitude });
};

export const polygonToFeaturePolygon = (polygon: Polygon): FeaturePolygon => {
  if (
    !polygon ||
    !polygon.coordinates ||
    !polygon.coordinates[0] ||
    !polygon.coordinates[0][0]
  ) {
    return null;
  }
  return Turf.feature(polygon);
};

export const polygonsToBoundingBox = (polygons: Polygon[]): BoundingBox => {
  if (!polygons || polygons.length === 0) {
    return null;
  }

  return Turf.bbox(
    Turf.featureCollection(polygons.map(polygonToFeaturePolygon))
  );
};

export const polygonsAndPointsToBoundingBox = (
  polygons: Polygon[],
  points: Point[],
  size?: number
): BoundingBox => {
  if (!polygons?.length && !points?.length) {
    return null;
  }

  if (!size && !polygons.length && points.length === 1) {
    return pointToBoundingBox(points[0], 0.0015);
  }

  const featurePolygons = polygons.map(polygonToFeaturePolygon);
  const featurePoints = points.map(pointToFeaturePoint);
  const bbox = Turf.bbox(
    Turf.featureCollection([...featurePolygons, ...featurePoints] as any)
  );

  if (size) {
    return enlargeBoundingBox(bbox, size);
  }
  return bbox;
};

export const pointToBoundingBox = (
  point: Point,
  size?: number
): BoundingBox => {
  if (!point) {
    return null;
  }

  const [longitude, latitude] = point.coordinates;
  const sizeInDegrees = size ?? 0.0005;
  return [
    longitude - sizeInDegrees,
    latitude - sizeInDegrees,
    longitude + sizeInDegrees,
    latitude + sizeInDegrees,
  ];
};

export const pointsToBoundingBox = (
  points: Point[],
  size?: number
): BoundingBox => {
  if (!points || points.length === 0) {
    return null;
  }

  const featurePoints = points.map(pointToFeaturePoint);
  const bbox = Turf.bbox(Turf.featureCollection(featurePoints));
  if (size) {
    return enlargeBoundingBox(bbox, size);
  }
  return bbox;
};

export const boundingBoxToFeaturePolygon = (
  boundingBox: BoundingBox
): FeaturePolygon => {
  if (!boundingBox) {
    return null;
  }
  return Turf.bboxPolygon(boundingBox);
};

export const pointsToFeaturePolygon = (
  points: Point[]
): null | FeaturePolygon => {
  if (!points || points?.length < 3) {
    return null;
  }
  const coordinates = [[...points, points[0]].map((a) => a.coordinates)];
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates,
    },
  };
};

export const pointsToFeatureLineString = (
  points: Point[]
): null | FeatureLineString => {
  if (!points || points?.length < 2) {
    return null;
  }
  const coordinates = points.map((a) => a.coordinates);
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates,
    },
  };
};

export const polygonIsInBoundingBox = (
  polygon: Polygon,
  boundingBox: BoundingBox
): boolean => {
  const polygonFeature = polygonToFeaturePolygon(polygon);
  const boundingBoxPolygon = boundingBoxToFeaturePolygon(boundingBox);

  const intersect = Turf.intersect(
    Turf.featureCollection([polygonFeature, boundingBoxPolygon])
  );

  return (
    (intersect?.geometry?.coordinates &&
      intersect.geometry.coordinates.length > 0) ??
    false
  );
};

export const pointIsInBoundingBox = (
  point: Point,
  boundingBox: BoundingBox
): boolean => {
  if (!point || !boundingBox) {
    return false;
  }
  const boundingBoxPolygon = boundingBoxToFeaturePolygon(boundingBox);
  const featurePoint = Turf.feature(point);
  return Turf.booleanContains(boundingBoxPolygon, featurePoint);
};

export const pointIsInPolygon = (point: Point, polygon: Polygon): boolean => {
  if (
    point?.coordinates?.length !== 2 ||
    !polygon ||
    polygon?.coordinates?.length === 0
  ) {
    return false;
  }
  return Turf.booleanPointInPolygon(point, polygon);
};

export const pointsToLines = (points: Point[]): Line[] => {
  if (!points || points.length < 2) {
    return [];
  }

  let lines: Line[] = [];
  for (let i = 1; i < points.length; i++) {
    const a = points[i - 1] as Point;
    const b = points[i] as Point;
    const distance = pointsToDistance([a, b]);
    lines.push({
      a,
      b,
      distance,
      shape: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [a.coordinates, b.coordinates],
        },
      },
    });
  }
  return lines;
};

export const pointsToDistance = (
  points: Point[],
  unit: DistanceUnits = 'm'
): QuantityOfDistance => {
  if (!points) {
    return makeQuantityOfDistance('m', 0);
  }

  const distance = (() => {
    if (points.length < 2) {
      return makeQuantityOfDistance('m', 0);
    }
    const meters = Turf.length(
      Turf.lineString(points.map((p) => p.coordinates)),
      {
        units: 'meters',
      }
    );

    return makeQuantityOfDistance('m', meters);
  })();
  return convertDistance(unit)(distance);
};

export const distanceFromPointToPolygon = (
  from: Point,
  to: Polygon,
  unit: DistanceUnits = 'km'
): QuantityOfDistance => {
  if (!from || !to) {
    return makeQuantityOfDistance('m', 0);
  }

  const perimeterOfPolyon = Turf.lineString(to.coordinates[0]);
  const distanceToNearestPointInMeters = Turf.pointToLineDistance(
    from,
    perimeterOfPolyon,
    {
      units: 'meters',
    }
  );

  return convertDistance(unit)(
    makeQuantityOfDistance('m', distanceToNearestPointInMeters)
  );
};

export const polygonToArea = (polygon: Polygon): QuantityOfArea => {
  if (!polygon || polygon.coordinates[0].length < 3) {
    return makeQuantityOfArea('m2', 0);
  }
  const squareMeters = Turf.area(polygon);
  return makeQuantityOfArea('m2', squareMeters);
};

export const polygonToPoints = (polygon: Polygon): Point[] => {
  let points =
    polygon?.coordinates?.[0]
      ?.map((coordinates) => {
        if (coordinates && coordinates.length === 2) {
          return {
            type: 'Point',
            coordinates,
          };
        }
        return null;
      })
      ?.filter((point) => point !== null) ?? [];

  // Remove last coordinate if it's the same as the first
  if (
    points.length > 2 &&
    JSON.stringify(points[0]) === JSON.stringify(points[points.length - 1])
  ) {
    points = points.slice(0, -1);
  }

  return points as Point[];
};

export const enlargeBoundingBox = (
  bbox: BoundingBox,
  marginInMeters: number
): BoundingBox => {
  if (!isValidBoundingBox(bbox)) {
    console.error(
      `Attempted to enlarge invalid bounding box ${JSON.stringify(bbox)}`
    );
    return bbox;
  }

  const boundingBoxPolygon = boundingBoxToFeaturePolygon(bbox);
  const marginInDegrees = Turf.lengthToDegrees(marginInMeters, 'meters');

  const buffered = Turf.buffer(boundingBoxPolygon, marginInDegrees, {
    units: 'degrees',
  });

  const newBBox = polygonsToBoundingBox([buffered.geometry as Polygon]);
  return newBBox;
};

export const closestPoint = (
  point: Point,
  polygons: Polygon[],
  opts?: { maxDistance?: QuantityOfDistance; anywhere?: boolean }
): null | Closest => {
  const qLessThan = operate((a, b) => {
    return a.value <= b.value;
  });
  const closest = polygons.reduce<null | Closest>(
    (a: null | Closest, b: Polygon) => {
      const closestCorner = closestCornerOnPolygon(point, b);

      const cornerCloseEnough =
        closestCorner && opts?.maxDistance
          ? qLessThan(closestCorner.distance, opts.maxDistance)
          : false;

      if (
        closestCorner &&
        cornerCloseEnough &&
        (a === null || qLessThan(closestCorner.distance, a.distance))
      ) {
        return closestCorner;
      }

      const closestP = closestPointOnPolygon(point, b);
      const pointCloseEnough =
        closestP && opts?.maxDistance
          ? qLessThan(closestP.distance, opts.maxDistance)
          : !!closestPoint;
      if (
        closestP &&
        pointCloseEnough &&
        (a === null || qLessThan(closestP.distance, a.distance))
      ) {
        return closestP;
      }

      return a;
    },
    null
  );

  return closest;
};

export const closestCornerOnPolygon = (
  point: Point,
  polygon: Polygon
): null | Closest => {
  const closest = (polygon.coordinates[0] as Position[]).reduce<Closest | null>(
    (a: null | Closest, b: Position) => {
      const pointB = positionToPoint(b);
      const distance = pointsToDistance([point, pointB]);
      if (a === null || distance.value < a.distance.value) {
        return {
          point: pointB,
          distance,
        };
      }
      return a;
    },
    null
  );

  return closest;
};

export const closestPointOnPolygon = (
  point: Point,
  polygon: Polygon
): Closest => {
  const closest = Turf.nearestPointOnLine(
    Turf.lineString(polygon.coordinates[0] as Position[], { units: 'miles' }),
    point
  );
  return {
    point: closest.geometry,
    distance: makeQuantityOfDistance('mi', closest.properties?.dist ?? 0),
  };
};

export const distanceBetweenPointAndFeature = (
  a: Point,
  b: Point | Polygon | LineString
): QuantityOfDistance => {
  const center = Turf.centerOfMass(b);
  const distance = Turf.distance(a, center, { units: 'miles' });

  return makeQuantityOfDistance('mi', distance ?? 0);
};

export const isValidBoundingBox = (
  boundingBox: undefined | null | number[]
) => {
  if (!boundingBox || boundingBox.length !== 4) {
    return false;
  }

  if (
    boundingBox.some((value) => isNaN(value) || Math.abs(value) === Infinity)
  ) {
    return false;
  }

  return true;
};

export const lineStringToPolygon = (lineString: LineString): Polygon => {
  if (
    !lineString ||
    !lineString.coordinates ||
    lineString.coordinates.length < 2
  ) {
    return null;
  }

  return {
    type: 'Polygon',
    coordinates: [lineString.coordinates],
  };
};

export const centerOfBoundingBox = (bbox: BoundingBox): Point => {
  if (!isValidBoundingBox(bbox)) {
    return null;
  }
  const center = Turf.center(boundingBoxToFeaturePolygon(bbox));
  return center.geometry;
};

export const resizeBoundingBoxByFactor = (
  bbox: BoundingBox,
  factor: number
): BoundingBox => {
  if (!isValidBoundingBox(bbox)) {
    console.error(
      `Attempted to enlarge invalid bounding box ${JSON.stringify(bbox)}`
    );
    return bbox;
  }

  const polygon = boundingBoxToFeaturePolygon(bbox);
  const resized = resizePolygon(polygon.geometry, factor);
  const newBBox = polygonsToBoundingBox([resized]);

  return newBBox;
};

export const resizePolygon = (polygon: Polygon, percentage: number) => {
  if (!polygon || !polygon.coordinates || polygon.coordinates.length === 0) {
    return null;
  }

  const center = Turf.centerOfMass(polygon);
  const resized = Turf.transformScale(polygon, percentage, {
    origin: center,
  });

  return resized;
};

export const bearingTowardsPoint = (a: Point, b: Point): number => {
  if (!a || !b) {
    return null;
  }
  return Turf.bearing(a, b);
};

export const translateBoundingBox = (
  bbox: BoundingBox,
  bearing: number,
  distance: number
) => {
  const polygon = boundingBoxToFeaturePolygon(bbox);

  const translated = Turf.transformTranslate(polygon, distance, bearing, {
    units: 'meters',
  });

  const newBBox = polygonsToBoundingBox([translated.geometry as Polygon]);
  return newBBox;
};

export const distanceToEdge = (
  polygon: Polygon,
  point: Point
): QuantityOfDistance => {
  const line = Turf.lineString(polygon.coordinates[0] as Position[]);
  const distance = Turf.pointToLineDistance(point, line, { units: 'meters' });
  return makeQuantityOfDistance('m', distance ?? 0);
};

export const translatePoint = (
  point: Point,
  bearing: number,
  distance: number
): Point => {
  if (!point) {
    return null;
  }
  const translated = Turf.transformTranslate(point, distance, bearing, {
    units: 'meters',
  });

  return translated;
};
