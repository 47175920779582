import React from 'react';
import isEqual from 'lodash/isEqual';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { Color } from '@mobble/colors';
import { Text } from '../../Components/UI/Text';
import { TimelineEvent } from '../../Components/UI/TimelineEvent';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { NaturalIncrease } from '@mobble/models/src/model/NaturalIncrease';

interface NaturalIncreaseCardProps {
  naturalIncrease: NaturalIncrease;
  last?: boolean;
  onClick: (naturalIncrease: NaturalIncrease) => void;
}

export const NaturalIncreaseCard: React.FC<NaturalIncreaseCardProps> = ({
  naturalIncrease,
  onClick,
  last,
}) => {
  return (
    <Clickable
      fullWidth
      href={
        typeof onClick === 'function' ? () => onClick(naturalIncrease) : onClick
      }
    >
      <TimelineEvent
        title={`${naturalIncrease.mob.size} ${toMobDisplayName(
          naturalIncrease.mob
        )}`}
        date={naturalIncrease.date}
        last={last}
        color={Color.Orange}
      >
        <Text color={Color.DarkGrey}>{naturalIncrease.paddock.name}</Text>
      </TimelineEvent>
    </Clickable>
  );
};

export const MemoisedNaturalIncreaseCard = React.memo(
  NaturalIncreaseCard,
  (prev, next) => {
    return (
      isEqual(prev.naturalIncrease, next.naturalIncrease) &&
      prev.last === next.last
    );
  }
);
