import { type RainGaugeReading } from '@mobble/models/src/model/RainGaugeReading';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { thunkGetAll as thunkRainGaugesGetAll } from './rainGauges';

export interface RainGaugeReadingsState {
  entities: RainGaugeReading[];
  extStatus: ExtStatusPerActionPerParent;
  filter: {};
  sort: {};
}

export const defaultState: RainGaugeReadingsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<RainGaugeReadingsState>({
  name: 'rainGaugeReadings',
  initialState: defaultState,
  toParentId: ({ propertyId, rainGaugeId }: RainGaugeReading) =>
    `${propertyId}_${rainGaugeId}`,
  onGetAll: mobbleService.api.rainGaugeReadings.get,
  onCreate: ({ entity }, dispatch) =>
    mobbleService.api.rainGaugeReadings
      .create({ entity })
      .then((rainGaugeReading) => {
        dispatch(
          thunkRainGaugesGetAll({ parentId: rainGaugeReading.propertyId })
        );
        return rainGaugeReading;
      }),
  onUpdate: mobbleService.api.rainGaugeReadings.update,
  onDelete: mobbleService.api.rainGaugeReadings.remove,
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
