import React, { Fragment, useEffect } from 'react';
import { defineMessages, FormattedDate } from 'react-intl';

import { useI18n, useMessages } from '@mobble/i18n';
import { User, UserRole } from '@mobble/models';

import { Button, Icon, Text } from '@src/components';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import { Avatar } from '@src/stories/Components/UI/Avatar';

import styles from './settingsPropertyMobbleConnectUserView.scss';

const messages = defineMessages({
  'farm_advisor.capabilities.1': {
    description: 'settings.property.mobble_connect.farm_advisor.capabilities.1',
    defaultMessage: 'Access to entire property',
  },
  'farm_advisor.capabilities.2': {
    description: 'settings.property.mobble_connect.farm_advisor.capabilities.2',
    defaultMessage: "Can't make edits (outside of tasks)",
  },
  'farm_advisor.capabilities.3': {
    description: 'settings.property.mobble_connect.farm_advisor.capabilities.3',
    defaultMessage: 'Can add tasks/recommendations for the farm',
  },
  'farm_advisor.capabilities.4': {
    description: 'settings.property.mobble_connect.farm_advisor.capabilities.4',
    defaultMessage: 'Can use Property Chat',
  },
  'map_view.capabilities.1': {
    description: 'settings.property.mobble_connect.map_view.capabilities.1',
    defaultMessage: 'Access to map only',
  },
  'map_view.capabilities.2': {
    description: 'settings.property.mobble_connect.map_view.capabilities.2',
    defaultMessage: "Can't make edits",
  },
  'map_view.capabilities.3': {
    description: 'settings.property.mobble_connect.map_view.capabilities.3',
    defaultMessage: 'Can view tasks assigned to them',
  },
  'map_view.capabilities.4': {
    description: 'settings.property.mobble_connect.map_view.capabilities.4',
    defaultMessage: 'Can use Property Chat',
  },
  'map_view.capabilities.5': {
    description: 'settings.property.mobble_connect.map_view.capabilities.5',
    defaultMessage: 'Role will only have access for a maximum of 30 days',
  },
  'stock_agent.capabilities.1': {
    description: 'settings.property.mobble_connect.stock_agent.capabilities.1',
    defaultMessage: 'Access to sales, purchases, livestock totals and mobs',
  },
  'stock_agent.capabilities.2': {
    description: 'settings.property.mobble_connect.stock_agent.capabilities.2',
    defaultMessage: 'Can add/edit sales and purchases',
  },
  'stock_agent.capabilities.3': {
    description: 'settings.property.mobble_connect.stock_agent.capabilities.3',
    defaultMessage: "Can't make edits (outside of sales and purchases)",
  },
  'stock_agent.capabilities.4': {
    description: 'settings.property.mobble_connect.stock_agent.capabilities.4',
    defaultMessage: 'Can use Property Chat',
  },
  'accountant.capabilities.1': {
    description: 'settings.property.mobble_connect.accountant.capabilities.1',
    defaultMessage:
      'Access to sales, purchases, livestock totals, natural increases, casualties and mobs',
  },
  'accountant.capabilities.2': {
    description: 'settings.property.mobble_connect.accountant.capabilities.2',
    defaultMessage: "Can't make edits",
  },
  'accountant.capabilities.3': {
    description: 'settings.property.mobble_connect.accountant.capabilities.3',
    defaultMessage: 'Can use Property Chat',
  },
  'auditor.capabilities.1': {
    description: 'settings.property.mobble_connect.auditor.capabilities.1',
    defaultMessage:
      'Access to mobs, paddocks, map, inventories, summaries, reports and tasks',
  },
  'auditor.capabilities.2': {
    description: 'settings.property.mobble_connect.auditor.capabilities.2',
    defaultMessage: "Can't make edits",
  },
  'auditor.capabilities.3': {
    description: 'settings.property.mobble_connect.auditor.capabilities.3',
    defaultMessage: 'Role will only have access for a maximum of 30 days',
  },
});

export const UserCard = ({
  user,
  onEditButtonClicked,
}: {
  user: User;
  onEditButtonClicked?: () => void;
}) => {
  const { formatMessage } = useI18n();

  const userName = user.name || '-';
  const email = user.email || '-';

  return (
    <Box className={styles.UserCard}>
      <HStack alignment="center">
        <HStack alignment="center">
          <Avatar name={userName} size="small" />
          <Box spacing={2}>
            <VStack>
              <Text tagName="h1" bold>
                {userName}
              </Text>
              <Text variant="tiny">{email}</Text>
              {user.expires ? (
                <Text variant="tiny">
                  {formatMessage(
                    {
                      description:
                        'settings.property.mobble_connect.user.expires.label',
                      defaultMessage: 'End access date: {date}',
                    },
                    {
                      date: <FormattedDate value={user.expires} />,
                    }
                  )}
                </Text>
              ) : null}
            </VStack>
          </Box>
        </HStack>
        {onEditButtonClicked ? (
          <Button
            aria-label={formatMessage({
              description: 'settings.property.mobble_connect.edit.user',
              defaultMessage: 'Edit user',
            })}
            icon="edit"
            intent="neutral"
            onClick={onEditButtonClicked}
          />
        ) : null}
      </HStack>
    </Box>
  );
};

const EmptyPosition = ({
  role,
  onInviteButtonClicked,
}: {
  role: string;
  onInviteButtonClicked: () => void;
}) => {
  const { formatMessage } = useI18n();

  return (
    <div className={styles.emptyPosition}>
      <div className={styles.placeholder}>
        <Icon name="mail" />
        <Text bold>------------</Text>
      </div>
      <Button
        label={formatMessage(
          {
            description: 'settings.property.mobble_connect.invite',
            defaultMessage: 'Invite {ROLE}',
          },
          {
            ROLE: role,
          }
        )}
        size="small"
        onClick={onInviteButtonClicked}
      />
    </div>
  );
};

export interface MobbleConnectViewProps {
  onEditButtonClicked: (id: string) => void;
  onInviteButtonClicked: () => void;
  role: UserRole;
  users: User[];
  remainingSlots: number;
  maxUsersOfRole: number;
}

export const MobbleConnectUserView: React.FC<MobbleConnectViewProps> = ({
  onEditButtonClicked,
  onInviteButtonClicked,
  role,
  users,
  remainingSlots,
  maxUsersOfRole,
}) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages);
  const roleString = formatMessage({
    id: `user_role.${role}`,
    defaultMessage: [],
  });

  const onUserCardEditButtonClicked = (user: User) => () =>
    user.id ? onEditButtonClicked(user.id) : null;

  return (
    <Box className={styles.settingsPropertyMobbleConnectUserView}>
      <Box className={styles.settingsPropertyMobbleConnectUsersList}>
        {users.map((user) => (
          <UserCard
            key={user.id}
            user={user}
            onEditButtonClicked={onUserCardEditButtonClicked(user)}
          />
        ))}

        {Array.from({ length: remainingSlots }).map((_, index) => (
          <EmptyPosition
            key={index}
            role={roleString}
            onInviteButtonClicked={onInviteButtonClicked}
          />
        ))}
      </Box>
      <Spacer y={4} />
      <Box className={styles.settingsPropertyMobbleConnectDescriptionContainer}>
        <Text variant="card-title" tagName="h2">
          {formatMessage(
            {
              description: 'settings.property.mobble_connect.capabilities',
              defaultMessage: '{ROLE} Capabilities',
            },
            {
              ROLE: roleString,
            }
          )}
        </Text>

        <ul>
          {Object.keys(strings)
            .filter((key) => key.startsWith(role))
            .map((key) => (
              <Text key={key} tagName="li">
                {strings[key]}
              </Text>
            ))}
        </ul>

        <Spacer y={1} />

        <Text tagName="p">
          {formatMessage(
            {
              description:
                'settings.property.mobble_connect.capabilities.description',
              defaultMessage:
                'You can have up to {VALUE} of these users per property.',
            },
            {
              VALUE: maxUsersOfRole,
            }
          )}
        </Text>
      </Box>
    </Box>
  );
};
