import { createAsyncThunk, current } from '@reduxjs/toolkit';
import {
  type RainGauge,
  type RainGaugeYearMonthTotal,
} from '@mobble/models/src/model/RainGauge';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { thunkGetAll as thunkRainGaugeReadingsGetAll } from './rainGaugeReadings';

export interface RainGaugesState {
  entities: RainGauge[];
  extStatus: ExtStatusPerActionPerParent;
  filter: {};
  sort: {};
}

export const defaultState: RainGaugesState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const name = 'rainGauges';

const entitySlice = entitySliceFactory<RainGaugesState>({
  name,
  initialState: defaultState,
  toParentId: ({ propertyId }: RainGauge) => propertyId,
  onGetAll: mobbleService.api.rainGauges.get,
  onCreate: mobbleService.api.rainGauges.create,
  onUpdate: mobbleService.api.rainGauges.update,
  onDelete: mobbleService.api.rainGauges.remove,
  afterQuery: async ({ entities }, dispatch) => {
    entities.forEach((rainGauge) => {
      dispatch(
        thunkRainGaugeReadingsGetAll({
          parentId: `${rainGauge.propertyId}_${rainGauge.id}`,
        })
      );
    });
  },
  extraReducers: (builder) => {
    builder.addCase(thunkGetRainGaugeYearTotal.fulfilled, (state, action) => {
      const curState = current(state);
      return {
        ...curState,
        entities: curState.entities.map((rainGauge: RainGauge) => {
          if (rainGauge.id === action.meta.arg.rainGaugeId) {
            const updatedTotals = [
              ...rainGauge.totals.filter(
                (total: RainGaugeYearMonthTotal) =>
                  total.year !== action.meta.arg.year
              ),
              ...action.payload.entities,
            ];

            return {
              ...rainGauge,
              totals: updatedTotals,
            };
          }
          return rainGauge;
        }),
      };
    });
  },
});

export const thunkGetRainGaugeYearTotal = createAsyncThunk<
  { entities: RainGaugeYearMonthTotal[] },
  { propertyId: string; rainGaugeId: string; year: number }
>(`${name}/getRainGaugeYearTotal`, async (args) => {
  return mobbleService.api.rainGauges.getYearMonthTotal({
    parentId: args.propertyId,
    rainGaugeId: args.rainGaugeId,
    year: args.year,
  });
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
