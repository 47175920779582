import { type PaddockGroupedStockingRate } from '@mobble/models/src/model/PaddockGroupedStockingRate';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';

export interface PaddockGroupedStockingRateState {
  extStatus: ExtStatusPerActionPerParent;
  entities: PaddockGroupedStockingRate[];
  filter: {};
  sort: {};
}

export const defaultState: PaddockGroupedStockingRateState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<PaddockGroupedStockingRateState>({
  name: 'paddockGroupedStockingRate',
  initialState: defaultState,
  toParentId: ({ propertyId }: PaddockGroupedStockingRate) => propertyId,
  onGetAll: mobbleService.api.paddockGroupedStockingRates.get,
  onCreate: () => Promise.reject(),
  onUpdate: () => Promise.reject(),
  onDelete: () => Promise.reject(),
});

export const { thunkGetAll, proxyUseEntities, proxyUseEntity } = entitySlice;
export const { flush } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
