import { Settings } from '@mobble/models/src/model/Settings';
import { useRootDispatch, useRootSelector } from './root';
import { updateSettings, defaultState } from '../reducers/settings';

export interface UseSettingsResponse {
  settings: Settings;
  update: (values: Partial<Settings>) => void;
}

export const useSettings = (): UseSettingsResponse => {
  const dispatch = useRootDispatch();
  const settings = {
    ...defaultState,
    ...useRootSelector((state) => state.settings),
  };

  const update = (values: Partial<Settings>) => {
    dispatch(updateSettings(values));
  };

  return {
    settings,
    update,
  };
};

export const useSetting = (key: keyof Settings) => {
  const settings = {
    ...defaultState,
    ...useRootSelector((state) => state.settings),
  };
  return settings[key];
};
