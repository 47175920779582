import { type PaddockGroupGeometry } from '@mobble/models/src/model/PaddockGroupGeometry';
import { type ApiPrelude } from '../types';

const GET_MAP_PADDOCK_GROUPS = `
  query GetMapPaddockGroups($propertyId: ID!) {
    mapPaddockGroups(propertyId: $propertyId) {
      nodes {
        paddockGroupId
        geometry
      }
    }
  }
`;

const decodePaddockGroupGeometry =
  (propertyId: string) =>
  (raw: any): PaddockGroupGeometry => {
    return {
      id: raw?.paddockGroupId,
      propertyId,
      paddockGroupId: raw?.paddockGroupId,
      polygon: raw?.geometry,
    };
  };

const decodePaddockGroupGeometries =
  (propertyId: string) =>
  (raw: any[]): PaddockGroupGeometry[] =>
    raw.map(decodePaddockGroupGeometry(propertyId));

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{ entities: PaddockGroupGeometry[] }> => {
    const response = await prelude.graphql({
      query: GET_MAP_PADDOCK_GROUPS,
      variables: {
        propertyId: parentId,
      },
    });
    const entities = decodePaddockGroupGeometries(parentId)(
      response?.data?.mapPaddockGroups?.nodes ?? []
    );
    return { entities };
  };
