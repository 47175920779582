import { arrayUnique } from '@mobble/shared/src/core/Array';
import { fromRawDate } from '@mobble/shared/src/core/Date';
import { Mob } from '@mobble/models/src/model/Mob';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { usePurchases } from '@mobble/store/src/hooks/purchases';

export const usePurchasesFilterItems = () => {
  const properties = useProperties();
  const purchases = usePurchases(properties.selected?.id);

  const getMobParamValues = (mobs: Mob[]) => (param: keyof Mob) =>
    arrayUnique(
      mobs.reduce((acc: string[], mob) => {
        if (Array.isArray(mob[param])) {
          return [...acc, ...(mob[param] as string[]).map((value) => value)];
        } else if (
          typeof mob[param] === 'string' ||
          typeof mob[param] === 'number'
        ) {
          return [...acc, mob[param]];
        }
        return acc;
      }, [])
    ).map((value) => ({ value }));

  const toMobParamValue = getMobParamValues(
    purchases.entities.map((e) => e.mob)
  );

  return () => [
    {
      type: 'date-range',
      group: 'dateRange',
      title: { key: 'purchases.filter.group.type.date_range.label' },
      meta: {
        years: Array.from(
          new Set(
            purchases.entities.map((entity) =>
              fromRawDate(entity.date).year()
            )
          )
        ).slice(0, 5),
      },
    },
    {
      title: { key: 'purchases.filter.group.breed.label' },
      type: 'select-multiple',
      group: 'breed',
      data: toMobParamValue('breed'),
    },
    {
      title: { key: 'purchases.filter.group.gender.label' },
      type: 'select-multiple',
      group: 'gender',
      data: toMobParamValue('gender'),
    },
  ];
};
