export const promiseSequential = (
  tasks: (() => Promise<any>)[]
): Promise<void> =>
  tasks.reduce(
    (promise, task) => promise.then(task).then(null),
    Promise.resolve()
  );

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const promiseSequentialWithSleep = (
  tasks: (() => Promise<any>)[],
  /* istanbul ignore next */
  sleepMs = 500
): Promise<void> =>
  tasks.reduce(
    (promise, task) => promise.then(task).then(() => sleep(sleepMs)),
    Promise.resolve()
  );
