export const getTitle = (html: string): null | string => {
  if (!html) {
    return null;
  }
  const titleRegex = /<title>(.*?)<\/title>/;
  const match = html.match(titleRegex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
};
