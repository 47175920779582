import { interpolateSlope } from '@mobble/shared/src/core/Number';
import {
  makeQuantityOfDistance,
  QuantityOfDistance,
  operate,
} from '@mobble/shared/src/core/Quantity';
import {
  closestCornerOnPolygon,
  closestPointOnPolygon,
} from '@mobble/models/src/model/MapGeometry';

import {
  type Point,
  type Polygon,
} from '@mobble/models/src/model/MapGeometry';

export const snapPoint = (
  point: Point,
  polygons: Polygon[],
  options: { threshold?: number; zoom?: number }
) => {
  const maxDistance = makeQuantityOfDistance('m', 75);
  if (options.zoom) {
    maxDistance.value = interpolateSlope(
      15,
      75 * (options.threshold ?? 1),
      18.5,
      5 * (options.threshold ?? 1)
    )(options.zoom);
  }

  const closest = closestPoint(point, polygons, {
    maxDistance,
    anywhere: true,
  });

  if (closest) {
    return closest.point;
  }

  return point;
};

type Closest = {
  point: Point;
  distance: QuantityOfDistance;
};

export const closestPoint = (
  point: Point,
  polygons: Polygon[],
  opts?: { maxDistance?: QuantityOfDistance; anywhere?: boolean }
): null | Closest => {
  const qLessThan = operate((a, b) => {
    return a.value <= b.value;
  });
  const closest = polygons.reduce<null | Closest>(
    (a: null | Closest, b: Polygon) => {
      const closestCorner = closestCornerOnPolygon(point, b);

      const cornerCloseEnough =
        closestCorner && opts?.maxDistance
          ? qLessThan(closestCorner.distance, opts.maxDistance)
          : false;

      if (
        closestCorner &&
        cornerCloseEnough &&
        (a === null || qLessThan(closestCorner.distance, a.distance))
      ) {
        return closestCorner;
      }

      const closestP = closestPointOnPolygon(point, b);
      const pointCloseEnough =
        closestP && opts?.maxDistance
          ? qLessThan(closestP.distance, opts.maxDistance)
          : !!closestPoint;
      if (
        closestP &&
        pointCloseEnough &&
        (a === null || qLessThan(closestP.distance, a.distance))
      ) {
        return closestP;
      }

      return a;
    },
    null
  );

  return closest;
};
