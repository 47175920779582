export interface Quantity<T extends Type, Unit extends Units> {
  type: T;
  unit: Unit;
  value: number;
}

export enum Type {
  Area = 'area',
  Mass = 'mass',
  Volume = 'volume',
  Length = 'length',
  Distance = 'distance',
  MassVolume = 'mass_volume',
}

export type AnyQuantity = Quantity<Type, Units>;

export const makeQuantity = <T extends Type, Unit extends Units>(
  type: T,
  unit: Unit | undefined,
  value: number
): Quantity<T, Unit> => ({
  type,
  unit,
  value,
});

export const massUnits = ['kg', 'g', 'mg', 't'] as const;
export type MassUnits = (typeof massUnits)[number];

export const areaUnits = [
  'ha', // hectare
  'acre',
  'm2',
  'km2',
] as const;
export type AreaUnits = (typeof areaUnits)[number];

export const volumeUnits = ['L', 'ml'] as const;
export type VolumeUnits = (typeof volumeUnits)[number];

export const lengthUnits = ['mm', 'cm', 'in'] as const;
export type LengthUnits = (typeof lengthUnits)[number];

export const distanceUnits = ['m', 'km', 'yd', 'mi'] as const;
export type DistanceUnits = (typeof distanceUnits)[number];

export const massVolumeUnits = [...massUnits, ...volumeUnits, 'Units'] as const;
export type MassVolumeUnits = (typeof massVolumeUnits)[number];

export const units = [
  ...massUnits,
  ...areaUnits,
  ...volumeUnits,
  ...lengthUnits,
  ...distanceUnits,
  ...massVolumeUnits,
] as const;
export type Units =
  | LengthUnits
  | DistanceUnits
  | AreaUnits
  | MassUnits
  | VolumeUnits
  | MassVolumeUnits;

export const unitsForType = <T extends Type>(type: T): string[] => {
  switch (type) {
    case Type.Area:
      return areaUnits as unknown as string[];
    case Type.Mass:
      return massUnits as unknown as string[];
    case Type.Volume:
      return volumeUnits as unknown as string[];
    case Type.Length:
      return lengthUnits as unknown as string[];
    case Type.MassVolume:
      return massVolumeUnits as unknown as string[];
    case Type.Distance:
      return distanceUnits as unknown as string[];
  }
  throw new Error(`Invalid type (${type})`);
};

export interface UserUnitSettings {
  areaUnit: AreaUnits;
  volumeUnit: VolumeUnits;
  massUnit: MassUnits;
  lengthUnit: LengthUnits;
  distanceUnit: DistanceUnits;
  massVolumeUnit: MassVolumeUnits;
}

export type QuantityOfMass = Quantity<Type.Mass, MassUnits>;
export const makeQuantityOfMass = (unit: MassUnits, value: number) =>
  makeQuantity(Type.Mass, unit, value);

export type QuantityOfArea = Quantity<Type.Area, AreaUnits>;
export const makeQuantityOfArea = (unit: AreaUnits, value: number) =>
  makeQuantity(Type.Area, unit, value);

export type QuantityOfVolume = Quantity<Type.Volume, VolumeUnits>;
export const makeQuantityOfVolume = (unit: VolumeUnits, value: number) =>
  makeQuantity(Type.Volume, unit, value);

export type QuantityOfLength = Quantity<Type.Length, LengthUnits>;
export const makeQuantityOfLength = (unit: LengthUnits, value: number) =>
  makeQuantity(Type.Length, unit, value);

export type QuantityOfDistance = Quantity<Type.Distance, DistanceUnits>;
export const makeQuantityOfDistance = (unit: DistanceUnits, value: number) =>
  makeQuantity(Type.Distance, unit, value);

export type QuantityOfMassVolume = Quantity<Type.MassVolume, MassVolumeUnits>;
export const makeQuantityOfMassVolume = (
  unit: MassVolumeUnits = 'kg',
  value: number = 0
) => makeQuantity(Type.MassVolume, unit, value);

export type Quantities =
  | QuantityOfArea
  | QuantityOfMass
  | QuantityOfVolume
  | QuantityOfLength
  | QuantityOfDistance
  | QuantityOfMassVolume;

export const map =
  <Q = QuantityOfArea | QuantityOfMass>(fn: (a: Q) => Q) =>
  (a: Q) => {
    return fn(a);
  };

const CONVERT_TABLE_MASS: Record<MassUnits, number> = {
  kg: 1,
  g: 0.001,
  mg: 0.000001,
  t: 1000,
};

export const convertMass =
  (target: MassUnits = 'kg', precision = 5) =>
  (quantity: QuantityOfMass): QuantityOfMass => {
    return converter<Type.Mass, typeof target>(CONVERT_TABLE_MASS, precision)(
      target,
      quantity
    );
  };

const CONVERT_TABLE_VOLUME: Record<VolumeUnits, number> = {
  L: 1,
  ml: 0.001,
};

export const convertVolume =
  (target: VolumeUnits = 'L', precision = 5) =>
  (quantity: QuantityOfVolume): QuantityOfVolume => {
    return converter<Type.Volume, typeof target>(
      CONVERT_TABLE_VOLUME,
      precision
    )(target, quantity);
  };

const CONVERT_TABLE_MASS_VOLUME = {
  ...CONVERT_TABLE_MASS,
  ...CONVERT_TABLE_VOLUME,
  Units: 1,
};

export const convertMassVolume =
  (target: MassVolumeUnits = 'L', precision = 5) =>
  (quantity: QuantityOfMassVolume): QuantityOfMassVolume => {
    return converter<Type.MassVolume, typeof target>(
      CONVERT_TABLE_MASS_VOLUME,
      precision
    )(target, quantity);
  };

const CONVERT_TABLE_AREA: Record<AreaUnits, number> = {
  ha: 100000000,
  acre: 40468564.224,
  m2: 10000,
  km2: 1000000000000,
};

export const convertArea =
  (target: AreaUnits = 'ha', precision = 5) =>
  (quantity: QuantityOfArea): QuantityOfArea => {
    return converter<Type.Area, typeof target>(CONVERT_TABLE_AREA, precision)(
      target,
      quantity
    );
  };

const CONVERT_TABLE_LENGTH: Record<LengthUnits, number> = {
  cm: 1,
  mm: 0.1,
  in: 2.54,
};

export const convertLength =
  (target: LengthUnits = 'cm', precision = 5) =>
  (quantity: QuantityOfLength): QuantityOfLength => {
    return converter<Type.Length, typeof target>(
      CONVERT_TABLE_LENGTH,
      precision
    )(target, quantity);
  };

const CONVERT_TABLE_DISTANCE: Record<DistanceUnits, number> = {
  m: 0.001,
  km: 1,
  yd: 0.0009144,
  mi: 1.609344,
};

export const convertDistance =
  (target: DistanceUnits = 'm', precision = 5) =>
  (quantity: QuantityOfDistance): QuantityOfDistance => {
    return converter<Type.Distance, typeof target>(
      CONVERT_TABLE_DISTANCE,
      precision
    )(target, quantity);
  };

const converter =
  <T extends Type, Unit extends Units>(
    table: Record<Unit, number>,
    precision = 5
  ) =>
  (target: Unit, quantity: Quantity<T, Unit>): Quantity<T, Unit> => {
    if (table[quantity.unit] === undefined) {
      throw new Error(`Invalid unit (${quantity.unit})`);
    }
    if (table[target] === undefined) {
      throw new Error(`Invalid unit (${target})`);
    }

    const a = table[quantity.unit];
    const b = table[target];

    const updatedValue = parseFloat(
      (quantity.value * (a / b)).toFixed(precision)
    );

    return makeQuantity(quantity.type, target, updatedValue);
  };

export const convertTo =
  <Q extends Quantities>(target: Q['unit'], precision = 5) =>
  (quantity: Q): Q => {
    switch (quantity.type) {
      case Type.Area:
        return convertArea(target as AreaUnits, precision)(quantity) as Q;
      case Type.Mass:
        return convertMass(target as MassUnits, precision)(quantity) as Q;
      case Type.Volume:
        return convertVolume(target as VolumeUnits, precision)(quantity) as Q;
      case Type.Length:
        return convertLength(target as LengthUnits, precision)(quantity) as Q;
      case Type.Distance:
        return convertDistance(
          target as DistanceUnits,
          precision
        )(quantity) as Q;
      case Type.MassVolume:
        return convertMassVolume(
          target as MassVolumeUnits,
          precision
        )(quantity) as Q;
    }
  };

export const changeTo =
  <Q extends Quantities>(target: Q['unit'], precision = 5) =>
  (quantity: Q): Quantity<Type, Q['unit']> => {
    const type = unitToQuantityType(target, quantity);

    return makeQuantity(type, target, quantity.value);
  };

export const unitToQuantityType = <Q extends Quantities>(
  unit: Units,
  quantity?: Q
): Type => {
  if (areaUnits.includes(unit as any)) {
    return Type.Area;
  } else if (massUnits.includes(unit as any)) {
    if (quantity && quantity.type === Type.MassVolume) {
      return Type.MassVolume;
    }
    return Type.Mass;
  } else if (volumeUnits.includes(unit as any)) {
    if (quantity && quantity.type === Type.MassVolume) {
      return Type.MassVolume;
    }
    return Type.Volume;
  } else if (lengthUnits.includes(unit as any)) {
    return Type.Length;
  } else if (distanceUnits.includes(unit as any)) {
    return Type.Distance;
  } else if (massVolumeUnits.includes(unit as any)) {
    return Type.MassVolume;
  }

  throw Error(`Invalid unit (${unit})`);
};

export const operate =
  <Q extends Quantities, Result = Q>(fn: (a: Q, b: Q) => Result) =>
  (a: Q, b: Q): Result => {
    if (a.type !== b.type) {
      throw new Error('Cannot operate on different types');
    }
    const bConverted = convertTo<Q>(a.unit)(b);
    const result = fn(a, bConverted);

    return result;
  };

export const add = <Q extends Quantities>(a: Q, b: Q): Q =>
  makeQuantity(a.type, a.unit, a.value + b.value) as typeof a;

export const subtract = <Q extends Quantities>(a: Q, b: Q): Q =>
  makeQuantity(a.type, a.unit, a.value - b.value) as Q;

export const multiply = <Q extends Quantities>(multiplier: number, a: Q): Q =>
  makeQuantity(a.type, a.unit, a.value * multiplier) as Q;

export const sum = <Q extends Quantities>(quantities: Q[]): Q => {
  const [first, ...rest] = quantities;
  return rest.reduce((acc, q) => operate(add)(acc, q), first);
};

export const isQuantity = (value: any): value is AnyQuantity => {
  return (
    value &&
    typeof value.type === 'string' &&
    typeof value.unit === 'string' &&
    value.value !== undefined &&
    value.value !== null &&
    typeof value.value === 'number'
  );
};

export const defaultAmountReceived = {
  unit: 't',
  value: 0,
};
