import React, { useState } from 'react';
import classNames from 'classnames/bind';

import { Chat } from '@mobble/models/src/model/Chat';
import { formatDateAndTime } from '@mobble/shared/src/core/Date';

import { Text } from '@src/stories/Components/UI/Text';

import styles from './ChatPill.scss';
const cx = classNames.bind(styles);

export const ChatPill = ({
  item,
  isUserAuthor,
  className,
}: {
  item: Chat;
  isUserAuthor: boolean;
  className?: string;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const rootClasses = cx({
    ChatPill: true,
    isUserAuthor,
    expanded,
    className,
  });

  const handleMessageClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={rootClasses}>
      <Text className={styles.name}>{item.user.name}</Text>

      <Text tagName="p" className={styles.message} onClick={handleMessageClick}>
        {item.body}
      </Text>

      <Text className={styles.datetime}>{formatDateAndTime(item.date)}</Text>
    </div>
  );
};
