import React, { ChangeEvent, ComponentPropsWithoutRef, useEffect } from 'react';
import classNames from 'classnames/bind';

import Radio, { RadioProps } from '../Radio';

import styles from './RadioGroup.scss';
const cx = classNames.bind(styles);

export interface RadioGroupProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  /**
   * Unique identifier for the group of radio inputs
   */
  id: string;

  /**
   * Label for the group of radio inputs
   * If not passed, be sure to set `aria-labelledby`
   */
  label?: string;

  /**
   * Name attribute for the radio inputs
   */
  name: RadioProps['name'];

  /**
   * Array of props for each Radio component
   */
  options: Pick<RadioProps, 'id' | 'name' | 'label' | 'value'>[];

  /**
   * The value of the checked radio input
   */
  checked?: HTMLInputElement['value'];

  /**
   * Button style for the Radio components
   */
  button?: RadioProps['button'];

  /**
   * Size of the Radio components
   */
  size?: RadioProps['size'];

  /**
   * Change handler for the radio group
   */
  onChange: (value: HTMLInputElement['value']) => void;
}

/**
 * A RadioGroup is a container for Radio components that share the same name attribute.
 */
const RadioGroup: React.FC<RadioGroupProps> = ({
  id,
  label,
  name,
  button,
  size,
  options = [],
  checked,
  className,
  onChange,
  ...others
}) => {
  const rootClasses = cx(
    {
      RadioGroup: true,
    },
    className
  );

  /**
   * Warning messages
   */
  const ariaLabelledBy = others['aria-labelledby'];
  useEffect(() => {
    if (!label && !ariaLabelledBy) {
      console.warn(
        'RadioGroup: neither "label" nor "aria-labelledby" property is provided. Please provide one.'
      );
    }
  }, [label, ariaLabelledBy]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange(e.target.value);
    }
  };

  return (
    <div
      id={`${id}-radiogroup`}
      role="radiogroup"
      aria-labelledby={label ? `${id}-label` : undefined}
      className={rootClasses}
      {...others}
    >
      {label ? <p id={`${id}-label`}>{label}</p> : null}

      {options.map((option) => (
        <Radio
          key={option.id}
          name={name}
          button={button}
          size={size}
          checked={option.value === checked}
          onChange={handleOnChange}
          {...option}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
