import { Quantity } from '@mobble/shared/src';
import { DateRange, formatDate } from '@mobble/shared/src/core/Date';
import {
  MassVolumeUnits,
  makeQuantityOfMassVolume,
} from '@mobble/shared/src/core/Quantity';
import { roundNumber } from '@mobble/shared/src/core/Number';
import { Color, colorToHex } from '@mobble/colors/src';
import { InventoryItemUsage } from '@mobble/models/src/model/InventoryItem';
import { InventoryCategory } from '@mobble/models/src';

const getArrayOfDatesBetweenTwoDays = (startDate: Date, endDate: Date) => {
  const dates = [];
  while (startDate <= endDate) {
    dates.push(new Date(startDate));
    startDate.setDate(startDate.getDate() + 1);
  }
  return dates;
};

const key = 'inventoryItemUsage';

export const convertInventoryItemUsageData = (
  inventoryItemId: string,
  inventoryItemUsage: InventoryItemUsage,
  range: DateRange,
  unit: MassVolumeUnits,
  type: InventoryCategory
) => {
  if (!inventoryItemId || !inventoryItemUsage?.data) {
    return {
      data: [],
      maxY: 0,
    };
  }

  const days = getArrayOfDatesBetweenTwoDays(
    new Date(range.start),
    new Date(range.end)
  );

  const data = days
    ?.reduce((acc, date) => {
      const dateFormatted = formatDate(date, 'YYYY-MM-DD');

      const valuesOfDate = inventoryItemUsage?.data
        .filter((curr) => formatDate(curr.date, 'YYYY-MM-DD') === dateFormatted)
        .reduce((highest, current) => {
          const value = Quantity.convertTo(unit)(
            makeQuantityOfMassVolume(current.unit, current.value)
          ).value;

          if (value > highest) {
            return value;
          }

          return highest;
        }, 0);

      const previousValue = acc[acc.length - 1]?.y || 0;

      const yValue = valuesOfDate ? valuesOfDate : previousValue;

      return [
        ...acc,
        {
          x: dateFormatted,
          y: yValue,
        },
      ];
    }, [])
    .map((curr) => ({
      x: curr.x,
      y: roundNumber(curr.y),
    }));

  const color = colorToHex(
    type === InventoryCategory.Chemicals ? Color.Blue : Color.Orange
  );

  const usage = [
    {
      id: key,
      color,
      data,
    },
  ];

  const maxY = Math.max(...data.map((curr) => curr.y), 0);

  return {
    data: usage,
    maxY,
  };
};
