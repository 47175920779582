import React from 'react';
import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import { useSettings } from '@mobble/store/src/hooks/settings';
import { Polygon } from '@mobble/models/src/model/MapGeometry';
import {
  mapAssetTypeToGeometryFeatureType,
  type MapAssetType,
} from '@mobble/models/src/model/MapAsset';
import {
  QuantityOfArea,
  type Quantities,
  type QuantityOfDistance,
} from '@mobble/shared/src/core/Quantity';
import { MapDraw } from '@src/components';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { makeQuantityI18nItem } from '@src/stories/Components/Locale/LocaleQuantity';
import {
  useMapPluginsContext,
  useMapPluginsPointsContext,
  useMapPluginsPointsDispatchContext,
} from '../Context';
import {
  ToolbarButtons,
  type ToolbarButtonProps,
} from '../Shared/ToolbarButtons';
import { MapInfoTablePoints } from '../Shared/MapInfoTablePoints';
import { MAP_ITEM_PADDOCK_BOUNDARY } from '../Items/MapItemType';

import styles from './creator.scss';
import { Icon } from '@src/stories/Components/UI/Icon';

export const mapAssetTypeToMode = (type: MapAssetType | string) => {
  switch (mapAssetTypeToGeometryFeatureType(type as MapAssetType)) {
    default:
    case 'Point':
      return 'point';
    case 'LineString':
      return 'path';
  }
};

const Toolbar: React.FC = () => {
  const pointStorage = useMapPluginsPointsContext();
  const pointStorageDispatch = useMapPluginsPointsDispatchContext();
  const { draw } = useMapPluginsContext();

  if (!pointStorage.enabled) {
    return null;
  }

  const buttonsBase =
    pointStorage.options?.mode === 'area'
      ? [
          {
            icon: pointStorage.options?.snap ? 'magnet' : 'magnet-off',
            hint: { key: 'map.tools.snap.hint' },
            onClick: () => {
              pointStorageDispatch.setOptions({
                snap: !(pointStorage.options?.snap ?? false),
              });
            },
          },
        ]
      : [];

  const buttons =
    pointStorage && pointStorage.points?.length > 0
      ? [
          ...buttonsBase,
          {
            icon: 'trash',
            hint: { key: 'map.tools.measure_clear.hint' },
            fill: Color.Red,
            color: Color.White,
            onClick: () => {
              draw.deleteAll();
              pointStorageDispatch.clearPoints();
            },
          },
          ...(pointStorage.options.single
            ? []
            : [
                {
                  icon: 'delete',
                  hint: { key: 'map.tools.measure_delete.hint' },
                  fill: Color.Orange,
                  color: Color.White,
                  onClick: () => {
                    const selectedIds = draw.getSelectedPoints();
                    if (selectedIds.features.length) {
                      draw.trash();
                    }
                  },
                },
              ]),
        ]
      : [...buttonsBase];

  return <ToolbarButtons buttons={buttons as (null | ToolbarButtonProps)[]} />;
};

const Effect: React.FC = () => {
  const { translate } = useI18n();
  const { settings } = useSettings();
  const pointStorage = useMapPluginsPointsContext();
  const pointStorageDispatch = useMapPluginsPointsDispatchContext();
  const { map, draw, mapProperties } = useMapPluginsContext();

  const printArea = (area: QuantityOfArea): string => {
    const key = makeQuantityI18nItem(area, settings.areaUnit);
    return translate(key) ?? `${area.value} ${area.unit}}`;
  };

  const printDistance = (distance: QuantityOfDistance): string => {
    const key = makeQuantityI18nItem(distance, settings.distanceUnit);
    return translate(key) ?? `${distance.value} ${distance.unit}}`;
  };

  const printQuantity = (q: Quantities): string => {
    if (q.type === 'area') {
      return printArea(q);
    } else if (q.type === 'distance') {
      return printDistance(q);
    }
    return '';
  };

  const itemsToSnapTo = React.useMemo(() => {
    return mapProperties.items
      .map((item) =>
        item.type === MAP_ITEM_PADDOCK_BOUNDARY ? item.polygon : null
      )
      .filter(Boolean) as Polygon[];
  }, [mapProperties.items]);

  // Draw type based on options
  let type: 'area' | 'path' | 'point' = 'point';
  if (pointStorage.options?.mode === 'area') {
    type = 'area';
  } else if (pointStorage.options?.single === false) {
    type = 'path';
  }

  if (!map || !draw) {
    return null;
  }

  return (
    <MapDraw
      mapRef={map}
      drawRef={draw}
      type={type}
      showMeasurements
      getMeasurementLabel={printQuantity}
      editable={pointStorage.enabled}
      points={pointStorage.points}
      snap={pointStorage.options.snap}
      itemsToSnapTo={itemsToSnapTo}
      onChange={(points) => {
        pointStorageDispatch.updatePoints(points);
      }}
    />
  );
};

const Content: React.FC = () => {
  const { formatMessage } = useI18n();
  const pointStorage = useMapPluginsPointsContext();
  if (!pointStorage.enabled) {
    return null;
  }

  return (
    <>
      <MapInfoTablePoints
        points={pointStorage?.points}
        type={pointStorage.options?.mode ?? 'area'}
      />

      {pointStorage.options?.mode === 'area' && (
        <Box className={styles.instructions}>
          <Icon name="info" className={styles.icon} />
          <Text tagName="p">
            {formatMessage({
              description:
                'Instructions for completing the drawing of a polygon',
              defaultMessage: 'Double click to complete the drawing',
            })}
          </Text>
        </Box>
      )}
    </>
  );
};

export default {
  Toolbar,
  Effect,
  Content,
};
