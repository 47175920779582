import React from 'react';
import { type RainGaugeReading } from '@mobble/models/src/model/RainGaugeReading';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { QuantityOfLength } from '@mobble/shared/src/core/Quantity';

export interface RainGaugeReadingEditFormProps {
  rainGaugeReading: RainGaugeReading;
  initialValues?: Partial<RainGaugeReadingEditFormValues>;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (formValues: RainGaugeReadingEditFormValues) => void;
}

export interface RainGaugeReadingEditFormValues {
  value: QuantityOfLength;
  date: Date;
}

export const RainGaugeReadingEditForm: React.FC<
  RainGaugeReadingEditFormProps
> = ({ rainGaugeReading, initialValues, loading, onCancel, onSubmit }) => {
  const form: FormBuilderProps<RainGaugeReadingEditFormValues> = {
    flex: true,
    i18nRootKey: 'rain-gauges.rain-gauge.edit.form',
    fields: [
      {
        name: 'value',
        type: 'quantity-length',
        required: true,
        initialValue: initialValues?.value,
      },
      {
        name: 'date',
        type: 'date',
        required: true,
        initialValue: String(rainGaugeReading.date),
      },
    ],
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
