import { Color, colorToHex } from '@mobble/colors';
import React from 'react';

import Feather from './svgs/feather';
import Mobble from './svgs/mobble';
import MobbleAnimals from './svgs/mobble-animals';
import MobbleIcons from './svgs/mobble-icon';
import MobbleLogo from './svgs/mobble-logo';

export const iconNames = [
  'activity',
  'alert-triangle',
  'animal-cattle',
  'animal-goat',
  'animal-mixed',
  'animal-sheep',
  'area',
  'arrow-down',
  'arrow-left',
  'arrow-right-circle',
  'arrow-right',
  'arrow-up-circle',
  'arrow-up',
  'average',
  'bar-chart',
  'calendar',
  'chat',
  'check-circle',
  'check-fat',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevrons-up',
  'circle',
  'clipboard',
  'close-circle',
  'close',
  'cloud-off',
  'cloud-rain',
  'credit-card',
  'crosshair',
  'date-empty',
  'date',
  'delete',
  'distance',
  'dollar-sign',
  'download',
  'edit',
  'error',
  'eye-off',
  'eye',
  'facebook',
  'farm',
  'file-text',
  'filter',
  'grid',
  'hamburger',
  'help-circle',
  'info',
  'instagram',
  'layers',
  'line-string',
  'link',
  'magnet-off',
  'magnet',
  'mail',
  'map-pin',
  'map',
  'maximise',
  'message',
  'minus-circle',
  'minus',
  'mob-action',
  'mob',
  'moon',
  'navigation',
  'offline',
  'online',
  'package',
  'paddock-action',
  'paddock',
  'path',
  'plus-circle',
  'plus-square',
  'plus',
  'point',
  'polygon',
  'print',
  'refresh',
  'ruler',
  'search',
  'settings',
  'share',
  'sign-out',
  'sort-asc',
  'split',
  'merge',
  'sun',
  'trash',
  'twitter',
  'undo',
  'user',
  'zap',
] as const;

export type IconName = (typeof iconNames)[number];

export type IconProps<T extends Record<string, any>> = {
  name: IconName;
  color?: string | Color;
} & T;

export function Icon<T extends Record<string, any>>({
  name,
  color,
  ...props
}: IconProps<T>) {
  return getNamedSvg({ name, color, props });
}

export function getNamedSvg<T extends Record<string, any>>({
  name,
  color,
  props,
}: {
  name: IconName;
  color?: string | Color;
  props?: T;
}): React.ReactElement {
  const strColor = color ? colorToHex(color) : 'currentColor';

  switch (name) {
    case 'activity':
      return <Feather.Activity stroke={strColor} {...props} />;
    case 'alert-triangle':
      return <Feather.AlertTriangle stroke={strColor} {...props} />;
    case 'animal-cattle':
      return <MobbleAnimals.Cattle {...props} />;
    case 'animal-goat':
      return <MobbleAnimals.Goat {...props} />;
    case 'animal-mixed':
      return <MobbleAnimals.Mixed {...props} />;
    case 'animal-sheep':
      return <MobbleAnimals.Sheep {...props} />;
    case 'area':
      return <Mobble.Area fill={strColor} {...props} />;
    case 'arrow-down':
      return <Feather.ArrowDown stroke={strColor} {...props} />;
    case 'arrow-left':
      return <Feather.ArrowLeft stroke={strColor} {...props} />;
    case 'arrow-right-circle':
      return <Feather.ArrowRightCircle stroke={strColor} {...props} />;
    case 'arrow-right':
      return <Feather.ArrowRight stroke={strColor} {...props} />;
    case 'arrow-up':
      return <Feather.ArrowUp stroke={strColor} {...props} />;
    case 'arrow-up-circle':
      return <Feather.ArrowUpCircle stroke={strColor} {...props} />;
    case 'average':
      return <Mobble.Average fill={strColor} {...props} />;
    case 'bar-chart':
      return <Feather.BarChart2 stroke={strColor} {...props} />;
    case 'calendar':
      return <Feather.Calendar stroke={strColor} {...props} />;
    case 'chat':
      return <Feather.MessageCircle stroke={strColor} {...props} />;
    case 'check-circle':
      return <Feather.CheckCircle stroke={strColor} {...props} />;
    case 'check-fat':
      return <Mobble.CheckFat stroke={strColor} {...props} />;
    case 'check':
      return <Feather.Check stroke={strColor} {...props} />;
    case 'chevron-down':
      return <Feather.ChevronDown stroke={strColor} {...props} />;
    case 'chevron-left':
      return <Feather.ChevronLeft stroke={strColor} {...props} />;
    case 'chevron-right':
      return <Feather.ChevronRight stroke={strColor} {...props} />;
    case 'chevron-up':
      return <Feather.ChevronUp stroke={strColor} {...props} />;
    case 'chevrons-up':
      return <Feather.ChevronsUp stroke={strColor} {...props} />;
    case 'circle':
      return <Feather.Circle stroke={strColor} {...props} />;
    case 'clipboard':
      return <Feather.Clipboard stroke={strColor} {...props} />;
    case 'close':
      return <Feather.X stroke={strColor} {...props} />;
    case 'close-circle':
      return <Feather.XCircle stroke={strColor} {...props} />;
    case 'cloud-off':
      return <Feather.CloudOff stroke={strColor} {...props} />;
    case 'cloud-rain':
      return <Feather.CloudRain stroke={strColor} {...props} />;
    case 'credit-card':
      return <Feather.CreditCard stroke={strColor} {...props} />;
    case 'crosshair':
      return <Feather.Crosshair stroke={strColor} {...props} />;
    case 'date':
      return <Feather.Calendar stroke={strColor} {...props} />;
    case 'delete':
      return <Feather.Delete stroke={strColor} {...props} />;
    case 'distance':
      return <Mobble.Distance stroke={strColor} {...props} />;
    case 'dollar-sign':
      return <Feather.DollarSign stroke={strColor} {...props} />;
    case 'download':
      return <Feather.DownloadCloud stroke={strColor} {...props} />;
    case 'edit':
      return <Feather.Edit2 stroke={strColor} {...props} />;
    case 'error':
      return <Feather.AlertTriangle stroke={strColor} {...props} />;
    case 'eye-off':
      return <Feather.EyeOff stroke={strColor} {...props} />;
    case 'eye':
      return <Feather.Eye stroke={strColor} {...props} />;
    case 'facebook':
      return <Feather.Facebook stroke={strColor} {...props} />;
    case 'farm':
      return <Feather.Layers stroke={strColor} {...props} />;
    case 'file-text':
      return <Feather.FileText stroke={strColor} {...props} />;
    case 'filter':
      return <Feather.Filter stroke={strColor} {...props} />;
    case 'grid':
      return <Feather.Grid stroke={strColor} {...props} />;
    case 'hamburger':
      return <Feather.Menu stroke={strColor} {...props} />;
    case 'help-circle':
      return <Feather.HelpCircle stroke={strColor} {...props} />;
    case 'info':
      return <Feather.Info stroke={strColor} {...props} />;
    case 'instagram':
      return <Feather.Instagram stroke={strColor} {...props} />;
    case 'layers':
      return <Feather.Layers stroke={strColor} {...props} />;
    case 'line-string':
      return <Mobble.LineString stroke={strColor} {...props} />;
    case 'link':
      return <Feather.Link2 stroke={strColor} {...props} />;
    case 'magnet-off':
      return <Mobble.MagnetOff stroke={strColor} {...props} />;
    case 'magnet':
      return <Mobble.Magnet stroke={strColor} {...props} />;
    case 'mail':
      return <Feather.Mail stroke={strColor} {...props} />;
    case 'map':
      return <Feather.Map stroke={strColor} {...props} />;
    case 'map-pin':
      return <Feather.MapPin stroke={strColor} {...props} />;
    case 'maximise':
      return <Feather.Maximize2 stroke={strColor} {...props} />;
    case 'merge':
      return <Mobble.Merge stroke={strColor} {...props} />;
    case 'message':
      return <Feather.Message stroke={strColor} {...props} />;
    case 'minus-circle':
      return <Feather.MinusCircle stroke={strColor} {...props} />;
    case 'minus':
      return <Feather.Minus stroke={strColor} {...props} />;
    case 'mob-action':
      return <Feather.CheckCircle stroke={strColor} {...props} />;
    case 'mob':
      return <Feather.Circle stroke={strColor} {...props} />;
    case 'moon':
      return <Feather.Moon stroke={strColor} {...props} />;
    case 'navigation':
      return <Feather.Navigation stroke={strColor} {...props} />;
    case 'online':
      return <Feather.Wifi stroke={strColor} {...props} />;
    case 'offline':
      return <Feather.WifiOff stroke={strColor} {...props} />;
    case 'package':
      return <Feather.Package stroke={strColor} {...props} />;
    case 'paddock-action':
      return <Feather.CheckSquare stroke={strColor} {...props} />;
    case 'paddock':
      return <Mobble.Paddock stroke={strColor} {...props} />;
    case 'path':
      return <Mobble.Path stroke={strColor} {...props} />;
    case 'plus-circle':
      return <Feather.PlusCircle stroke={strColor} {...props} />;
    case 'plus-square':
      return <Feather.PlusSquare stroke={strColor} {...props} />;
    case 'plus':
      return <Feather.Plus stroke={strColor} {...props} />;
    case 'polygon':
      return <Mobble.Polygon fill={strColor} {...props} />;
    case 'point':
      return <Mobble.Point stroke={strColor} {...props} />;
    case 'print':
      return <Feather.Printer stroke={strColor} {...props} />;
    case 'refresh':
      return <Feather.RefreshCw stroke={strColor} {...props} />;
    case 'ruler':
      return <Mobble.Ruler fill={strColor} {...props} />;
    case 'search':
      return <Feather.Search stroke={strColor} {...props} />;
    case 'settings':
      return <Feather.Settings stroke={strColor} {...props} />;
    case 'share':
      return <Feather.Share stroke={strColor} {...props} />;
    case 'sign-out':
      return <Feather.LogOut stroke={strColor} {...props} />;
    case 'sort-asc':
      return <Mobble.SortAsc stroke={strColor} {...props} />;
    case 'split':
      return <Mobble.Split stroke={strColor} {...props} />;
    case 'sun':
      return <Feather.Sun stroke={strColor} {...props} />;
    case 'trash':
      return <Feather.Trash stroke={strColor} {...props} />;
    case 'twitter':
      return <Feather.Twitter stroke={strColor} {...props} />;
    case 'user':
      return <Feather.User stroke={strColor} {...props} />;
    case 'undo':
      return <Feather.RotateCcw stroke={strColor} {...props} />;
    case 'zap':
      return <Feather.Zap stroke={strColor} {...props} />;
  }
}

export type LogoProps = {
  white?: boolean;
} & Record<string, any>;

export const Logo: React.FC<LogoProps> = ({ white, ...props }) => {
  return white ? (
    <MobbleLogo.LogoWhite {...props} />
  ) : (
    <MobbleLogo.Logo {...props} />
  );
};

export const MobbleIcon: React.FC<LogoProps> = ({ white, ...props }) => {
  return <MobbleIcons.Icon {...props} />;
};
