import React, { type ReactElement } from 'react';
import { type Paddock, getPaddock } from '@mobble/models/src/model/Paddock';
import {
  type ConfiguredPropertyType,
  type ConfiguredPropertyTypeGroupCustom,
} from '@mobble/models/src/model/Property';
import { formatDate } from '@mobble/shared/src/core/Date';
import {
  type SortOption,
  type SortSetting,
} from '@mobble/shared/src/core/Sort';
import { MobCreateWizardMobForm } from './MobCreateWizard/MobCreateWizardMobForm';
import { MobCreateWizardTypeOfAddition } from './MobCreateWizard/MobCreateWizardTypeOfAddition';
import { MobCreateWizardNaturalIncrease } from './MobCreateWizard/MobCreateWizardNaturalIncrease';
import { MobCreateWizardSummary } from './MobCreateWizard/MobCreateWizardSummary';
import { MobCreateWizardPurchase } from './MobCreateWizard/MobCreateWizardPurchase';
import {
  FormWizard,
  type FormWizardFormValues,
  type FormWizardSteps,
  type FormWizardFormState,
} from '../Form/FormWizard';
import { type ListSelectOption } from '@src/components/ListSelect';
import {
  stockingRateToDSE,
  StockingUnit,
} from '@mobble/models/src/model/Settings';

export interface MobCreateWizardProps {
  paddockOptions: ListSelectOption[];
  paddockSortOptions: SortOption[];
  sortPaddockOptions: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  paddocks: Paddock[];
  propertyTypes: ConfiguredPropertyType[];
  initialValues: Partial<FormWizardFormValues<StepNames>>;
  error?: string;
  loading?: boolean;
  onSubmit: (formValues: FormWizardFormValues<StepNames>) => Promise<void>;
  onCreateCustomField: (
    group: ConfiguredPropertyTypeGroupCustom,
    parentId: string,
    label: string
  ) => Promise<void>;
  stockingUnit: StockingUnit;
}

type StepNames =
  | 'type_of_addition'
  | 'mob_form'
  | 'setup_form'
  | 'natural_increase_form'
  | 'purchase_form';

export const MobCreateWizard: React.FC<MobCreateWizardProps> = ({
  paddocks,
  paddockOptions,
  paddockSortOptions,
  sortPaddockOptions,
  propertyTypes,
  initialValues,
  error,
  loading,
  onSubmit,
  onCreateCustomField,
  stockingUnit,
}) => {
  const date = formatDate(new Date(), 'YYYY-MM-DD');

  const parsedInitialValues = {
    mob_form: { ...initialValues.mob_form, stocking_rate: 1 },
    natural_increase_form: { ...initialValues.natural_increase_form, date },
    purchase_form: { ...initialValues.purchase_form, date },
  };

  const formToNewMob = (
    mob_form: FormWizardFormValues<StepNames>['mob_form']
  ) => {
    return {
      id: 'virtual_mob',
      propertyId: 'virtual_property',
      type: mob_form?.type,
      breed: mob_form?.breed,
      gender: mob_form?.gender,
      size: mob_form?.size ? Math.floor(mob_form?.size) : 0,
      DSE: stockingRateToDSE(stockingUnit)(mob_form?.stocking_rate),
      ages: mob_form?.ages,
      classes: mob_form?.classes,
      safeDate: undefined,
    };
  };

  const formToPaddock = (
    mob_form: FormWizardFormValues<StepNames>['mob_form']
  ) => {
    return getPaddock(paddocks)(mob_form?.paddock);
  };

  const wrapWithSummary = (
    formState: FormWizardFormState<StepNames>,
    component?: ReactElement
  ) => {
    return (
      <MobCreateWizardSummary
        newMob={formToNewMob(formState.allValues.mob_form)}
        paddock={formToPaddock(formState.allValues.mob_form)}
        propertyTypes={propertyTypes}
      >
        {component}
      </MobCreateWizardSummary>
    );
  };

  const steps: FormWizardSteps<StepNames> = {
    type_of_addition: {
      progress: [1, 3],
      component: ({ values, setValidity, setValues, setStep }) => (
        <MobCreateWizardTypeOfAddition
          formValues={values}
          onChange={(updateValues) => {
            setValidity(true);
            setValues(updateValues);
            setStep('mob_form');
          }}
        />
      ),
      nextStep: ({ valid }) => {
        return valid ? 'mob_form' : null;
      },
    },
    mob_form: {
      progress: [2, 3],
      component: ({ values, setValidity, setValues }) => (
        <MobCreateWizardMobForm
          paddockOptions={paddockOptions}
          paddockSortOptions={paddockSortOptions}
          sortPaddockOptions={sortPaddockOptions}
          propertyTypes={propertyTypes}
          formValues={values}
          onChange={(updateValues, errors) => {
            setValidity(Object.keys(errors).length === 0);
            setValues({ ...updateValues, size: Math.floor(updateValues.size) });
          }}
          onCreateCustomField={onCreateCustomField}
        />
      ),
      previousStep: () => 'type_of_addition',
      nextStep: ({ valid, allValues }) => {
        if (valid) {
          switch (allValues.type_of_addition.addition_type) {
            case 'setup':
              return 'setup_form';
            case 'natural_increase':
              return 'natural_increase_form';
            case 'purchase':
              return 'purchase_form';
          }
        }
        return null;
      },
    },
    setup_form: {
      progress: [3, 3],
      alwaysValid: true,
      component: (formState) => wrapWithSummary(formState),
      previousStep: () => 'mob_form',
    },
    natural_increase_form: {
      progress: [3, 3],
      alwaysValid: true,
      component: (formState) =>
        wrapWithSummary(
          formState,
          <MobCreateWizardNaturalIncrease
            formValues={formState.values}
            onChange={(values, errors) => {
              formState.setValidity(Object.keys(errors).length === 0);
              formState.setValues(values);
            }}
          />
        ),
      previousStep: () => 'mob_form',
    },
    purchase_form: {
      progress: [3, 3],
      component: (formState) =>
        wrapWithSummary(
          formState,
          <MobCreateWizardPurchase
            mobSize={formState.allValues.mob_form?.size}
            formValues={formState.values}
            onChange={(values, errors) => {
              formState.setValidity(Object.keys(errors).length === 0);
              formState.setValues(values);
            }}
          />
        ),
      previousStep: () => 'mob_form',
    },
  };

  return (
    <FormWizard
      steps={steps}
      initialValues={parsedInitialValues}
      error={error}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
};
