import { Middleware } from '@reduxjs/toolkit';
import {
  createQueueAction,
  getActionTypeWithoutSuffix,
} from '@mobble/models/src/model/QueueAction';

import { State } from '../index';
import * as Reducers from '../reducers';
import { replayableThunks } from './replayableThunks';

export const offlineMiddleware: Middleware =
  (storeAPI) => (next) => (action) => {
    const storeState = storeAPI.getState() as State;
    const isOnline = storeState.network.connected;
    const propertyId = storeState.properties.selectedProperty;

    if (!isOnline && action.type.endsWith(`/rejected`)) {
      const canBeQueued = replayableThunks.some((thunk) =>
        action.type.startsWith(thunk.typePrefix)
      );

      if (!canBeQueued) {
        return next({
          ...action,
          type: `${action.type}/offline`,
        });
      }

      const queueAction = createQueueAction(
        getActionTypeWithoutSuffix(action.type),
        propertyId,
        action.meta.arg
      );

      return next(Reducers.ActionQueue.addToQueue(queueAction));
    }
    return next(action);
  };
