import React from 'react';
import { RainGauge } from '@mobble/models/src/model/RainGauge';
import { Settings } from '@mobble/models/src/model/Settings';
import {
  RawDate,
  formatDate,
  formatTime,
  fromRawDate,
} from '@mobble/shared/src/core/Date';
import {
  TableCellValue,
  TableColumn,
} from '@src/stories/Components/Layout/Table';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Text } from '@src/stories/Components/UI/Text';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';

export const makeRainGaugesTableColumns = ({
  settings,
}: {
  settings: Settings;
}): TableColumn<TableCellValue, RainGauge>[] => {
  return [
    {
      key: 'name',
      label: { key: 'rain-gauge.info.table.row.name.label' },
      toValue: (rainGauge) => rainGauge.name,
      render: (_, rainGauge) => (
        <Clickable
          href={toPath(ROUTE_NAME.RAIN_GAUGE_DETAIL, {
            rainGaugeId: rainGauge.id,
          })}
        >
          <Text underline variant="small">
            {rainGauge.name}
          </Text>
        </Clickable>
      ),
    },
    {
      key: 'last_reading',
      label: { key: 'rain-gauge.info.table.row.last_reading.label' },
      toValue: (rainGauge) =>
        rainGauge.lastReading
          ? fromRawDate(rainGauge.lastReading.date).toDate().getTime()
          : null,
      valueToString: (value) => {
        if (!value) {
          return '';
        }
        const date = formatDate(fromRawDate(value as RawDate), settings.dateFormat);
        const time = formatTime(fromRawDate(value as RawDate), settings.timeFormat);
        return `${date} ${time}`;
      },
    },
    {
      key: 'mtd',
      label: { key: 'rain-gauge.info.table.row.mtd.label' },
      averages: true,
      toValue: (rainGauge) => rainGauge.mtd,
    },
    {
      key: 'ytd',
      label: { key: 'rain-gauge.info.table.row.ytd.label' },
      averages: true,
      toValue: (rainGauge) => rainGauge.ytd,
    },
  ];
};
