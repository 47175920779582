import React from 'react';
import { Color } from '@mobble/theme';
import { IconName } from '@mobble/icon';

import { Icon } from '@src/components';

import { RootStackParamList } from '@src/screens/config/types';
import { Text } from '@src/stories/Components/UI/Text';
import { Box } from '../Layout/Box';
import { Clickable } from './Clickable';

import styles from './cardedNavigationListItem.scss';

export interface CardedNavigationListItemProps {
  title: string;
  subtitle: string;
  href?: keyof RootStackParamList | (() => void);
  icon: IconName;
  disabled?: boolean;
  isLastElement?: boolean;
}

export const CardedNavigationListItem: React.FC<
  CardedNavigationListItemProps
> = (item) => (
  <Clickable href={item.href} className={styles.cardNavigationListGridItem}>
    <Box
      className={[
        styles.card,
        !item.isLastElement ? styles.cardBorder : null,
        item.disabled ? styles.cardDisabled : null,
      ]}
    >
      <Box className={styles.cardTitle}>
        <Icon
          name={item.icon}
          color={
            item.disabled ? Color.NeutralsCoreDisabled : Color.PrimaryDefault
          }
        />
        <Text i18n={item.title} tagName="h2" bold />
      </Box>

      <Text
        i18n={item.subtitle}
        tagName="p"
        bold
        className={styles.cardSubtitle}
      />
    </Box>
  </Clickable>
);
