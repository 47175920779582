import { useEffect } from 'react';
import {
  EntitySliceFactoryProxyEntityResponse,
  EntitySliceFactoryProxyEntitiesResponse,
} from '@mobble/store/src/lib/entitySliceFactory';

export const useEntitiesRefresher = (
  entities: Partial<
    | EntitySliceFactoryProxyEntityResponse<any>
    | EntitySliceFactoryProxyEntitiesResponse<any>
  >[],
  propertyId?: string
) => {
  const refresher = (force?: boolean) => {
    return Promise.all(entities.map((entity) => entity.refresh(force)));
  };

  const refresh = () => refresher(true);
  const refreshIfExpired = () => refresher(false);

  useEffect(() => {
    if (propertyId) {
      refreshIfExpired();
    }
  }, [propertyId]);

  return {
    refresh,
    refreshIfExpired,
  };
};
