import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface NetInfoState {
  type: string;
  isConnected?: null | boolean;
  isInternetReachable?: null | boolean;
}

export interface NetworkState {
  connected: boolean;
  connectionType: string;
  lastOnline?: number;
  offlineMode: boolean;
}

export const defaultState: NetworkState = {
  connected: true,
  connectionType: 'unknown',
  lastOnline: undefined,
  offlineMode: false,
};

const slice = createSlice({
  name: 'network',
  initialState: defaultState,
  reducers: {
    updateNetworkInformation: (
      state: NetworkState,
      action: PayloadAction<NetInfoState>
    ) => {
      if (action.payload.type === 'unknown') {
        return state;
      }

      const connected =
        (action.payload.isConnected === null ||
          action.payload.isConnected === true) &&
        (action.payload.isInternetReachable === null ||
          action.payload.isInternetReachable === true);
      state.connected = connected;
      state.lastOnline = connected ? new Date().getTime() : state.lastOnline;
      state.connectionType = action.payload.type;
    },
    setOfflineMode: (state: NetworkState, action: PayloadAction<boolean>) => {
      state.offlineMode = action.payload;
    },
  },
});

export const { updateNetworkInformation, setOfflineMode } = slice.actions;

export const { reducer } = slice;
