import React from 'react';
import * as Yup from 'yup';
import { useI18n } from '@mobble/i18n';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import {
  Point,
  pointsToFeaturePolygon,
  polygonToArea,
  polygonToPoints,
} from '@mobble/models/src/model/MapGeometry';
import { PaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import { useSettings } from '@mobble/store/src/hooks/settings';
import {
  convertTo,
  type QuantityOfArea,
} from '@mobble/shared/src/core/Quantity';

import { ColorSwatch } from '@src/components';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface PaddockEditFormProps {
  paddock: Paddock;
  paddockGeometry?: PaddockGeometry;
  paddockTypes: { value: string; color: string }[];
  error?: string;
  loading?: boolean;

  onChange?: FormBuilderProps['onChange'];
  onCancel: () => void;
  onSubmit: (formValues: PaddockEditFormValues) => void;
  onCreateCustomField: (label: string) => Promise<void>;
}

export interface PaddockEditFormValues {
  name: string;
  paddock_type: Paddock['type'];
  grazeable_area: QuantityOfArea;
  total_area: QuantityOfArea;
  points?: Point[];
}

export const PaddockEditForm: React.FC<PaddockEditFormProps> = ({
  paddock,
  paddockGeometry,
  paddockTypes,
  error,
  loading,
  onChange,
  onCancel,
  onSubmit,
  onCreateCustomField,
}) => {
  const { translate, formatMessage } = useI18n();
  const settings = useSettings();

  const paddockTypeOptions = paddockTypes.map(({ value, color }) => ({
    label: value,
    value,
    labelExtra: <ColorSwatch color={color} />,
  }));
  const handleCreateCustomField = (value: string): Promise<void> =>
    onCreateCustomField(value);

  const form: FormBuilderProps<PaddockEditFormValues> = {
    flex: true,
    i18nRootKey: 'paddocks.paddock.edit.form',
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
        initialValue: paddock.name,
      },
      {
        name: 'paddock_type',
        type: 'select',
        options: paddockTypeOptions,
        required: true,
        initialValue: paddock.type,
        addNew: {
          onSubmit: handleCreateCustomField,
          placeholder: formatMessage({
            defaultMessage: 'Add new paddock type',
            description:
              'paddocks.paddock.create.form.paddock_form.paddockTypes.add_new.placeholder.label',
          }),
        },
      },
      {
        name: 'points',
        type: 'map-creator',
        required: false,
        initialValue: paddockGeometry?.polygon
          ? polygonToPoints(paddockGeometry.polygon)
          : null,
        mapCreator: {
          options: {
            single: false,
            mode: 'area',
          },
        },
        onChange: (values) => {
          const polygon = values.points
            ? pointsToFeaturePolygon(values.points)
            : null;

          if (!polygon) {
            return values;
          }

          const total_area = convertTo(
            settings.settings.areaUnit,
            2
          )(polygonToArea(polygon.geometry)) as QuantityOfArea;

          return {
            ...values,
            total_area,
          };
        },
      },
      {
        name: 'total_area',
        type: 'quantity-area',
        disabled: () => true,
        initialValue: paddockGeometry?.polygon?.coordinates
          ? (convertTo(
              settings.settings.areaUnit,
              2
            )(polygonToArea(paddockGeometry?.polygon)) as QuantityOfArea)
          : undefined,
      },
      {
        name: 'grazeable_area',
        type: 'quantity-area',
        initialValue: paddock.properties.size,
        show: (values: PaddockEditFormValues) => values.paddock_type !== 'Yard',
        validation: Yup.object({
          value: Yup.number(),
          unit: Yup.string(),
        }).when('paddock_type', {
          is: 'Yard',
          then: (schema) => schema.optional(),
          otherwise: (schema) =>
            schema.required(
              translate({
                key: 'generic.form.validation.required',
                params: {
                  FIELD: translate({
                    key: 'paddocks.paddock.edit.form.grazeable_area.label',
                  }),
                },
              })
            ),
        }),
      },
    ],
    error,
    loading,
    onChange,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
