import 'core-js/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

import './reset.scss';
import './index.scss';

const main = document.querySelector('main');

createRoot(main).render(<App />);
