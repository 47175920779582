import React from 'react';
import mobbleService from '@mobble/service';
import { AgWorldProperty } from '@mobble/service/src/ext/mobble-apps';
import { useProperties } from '@mobble/store/src/hooks/properties';

export const useGetAgWorldPaddocks = () => {
  const properties = useProperties();

  const [propertyPaddocks, setPropertyPaddocks] = React.useState<
    AgWorldProperty[]
  >([]);

  React.useEffect(() => {
    if (properties.selected?.organisationId)
      mobbleService.api.mobbleApps
        .getAgWorldPaddocks({
          organisationId: properties.selected?.organisationId,
          propertyId: properties.selected?.id,
        })
        .then(({ entities }) => {
          const propertiesWithFilteredGeometries = entities.reduce(
            (acc: AgWorldProperty[], property) => {
              const paddocks = property.paddocks.filter(
                (p) => p?.geometry?.polygon
              );

              if (paddocks.length) {
                return [
                  ...acc,
                  {
                    ...property,
                    paddocks,
                  },
                ];
              }

              return acc;
            },
            []
          );

          setPropertyPaddocks(propertiesWithFilteredGeometries);
        });
  }, []);

  return { propertyPaddocks };
};
