import { getTitle } from '@mobble/shared/src/core/HTML';
import { RequestErrors } from '@mobble/shared/src/core/Error';
import { getQueryOrMutationName } from './getQueryOrMutationName';
import promiseQueue from './PromiseQueue';

export interface FetcherConfiguration {
  uri: string;
  restUri: string;
  getIsOnline: () => Promise<boolean>;
  getAuthToken: () => Promise<string>;
  getUserId: () => Promise<string>;
  getClientVersion: () => string;
  getRequestId: () => Promise<string>;
  onResponse?: (ev: {
    uri: string;
    method: string;
    operationName: string;
    variables: Record<string, any>;
    status: number;
  }) => void;
}

const X_REQUEST_ID_KEY = 'x-request-id';
const X_CLIENT_VERSION_KEY = 'x-client-version';
const X_USER_ID_KEY = 'x-user-id';

const errorsFromJson = (data?: any): null | string => {
  try {
    if (!data?.errors?.length) {
      return null;
    }

    const errors = data?.errors.map((a: any) => {
      let str = '';
      if (a?.extensions?.code) {
        str = `${a.extensions.code}`;
      }
      if (a?.extensions?.message) {
        str = `${str} ${a.message}`;
      }
      return str;
    });

    if (errors.length === 0) {
      return null;
    }

    return errors.join(', ');
  } catch (e) {
    return null;
  }
};

export class FetcherError extends Error {
  code?: number;
  date: Date;

  constructor(
    error: { message: string; code?: number } = { message: 'Unknown Error' }
  ) {
    super(error.message);
    this.name = 'FetcherError';
    this.date = new Date();
    this.code = error.code;
  }
}

export interface FetcherInterface {
  configure: (config: FetcherConfiguration) => void;
  fetch: <Response = any>(args: FetchArgs) => Promise<Response>;
  graphql: <Response = any>(args: FetchGraphqlArgs) => Promise<Response>;
}

export class Fetcher implements FetcherInterface {
  private static instance: Fetcher;
  //
  private queue = promiseQueue;
  private config: FetcherConfiguration | null = null;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new Fetcher();
    }
    return this.instance;
  }

  public configure = (config: FetcherConfiguration) => {
    this.config = config;
  };

  private getXHeaders = async () => {
    if (!this.config) {
      return [];
    }
    try {
      const [userId, clientVersion, requestId] = await Promise.all([
        this.config.getUserId(),
        this.config.getClientVersion(),
        this.config.getRequestId(),
      ]);

      return {
        [X_REQUEST_ID_KEY]: requestId,
        [X_CLIENT_VERSION_KEY]: clientVersion,
        [X_USER_ID_KEY]: userId,
      };
    } catch (_) {
      return [];
    }
  };

  public graphql = async <Response = any>(
    args: FetchGraphqlArgs
  ): Promise<Response> => {
    if (!this.config) {
      throw new Error(
        'Fetcher not configured.\n Call Fetcher.configure() first.'
      );
    }
    if (!(await this.config.getIsOnline())) {
      return Promise.reject(new Error(RequestErrors.NetworkError));
    }

    const { query, variables, token } = args;
    const uri = args.uri ?? this.config?.uri ?? '';
    const tokenToUse =
      token === false ? false : token ?? (await this.config.getAuthToken());

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(await this.getXHeaders()),
      ...(tokenToUse ? { Authorization: `Bearer ${tokenToUse}` } : {}),
    };

    const body = JSON.stringify({
      query,
      variables,
    });

    const fetchOptions: RequestInit = {
      method: 'POST',
      headers,
      body,
    };

    const fn = async () => {
      const response = await fetch(uri, fetchOptions)
        .then(async (res) => {
          const operationName = getQueryOrMutationName(query);

          this.config?.onResponse &&
            this.config.onResponse({
              uri,
              method: 'POST',
              operationName,
              variables,
              status: res.status,
            });

          if (res.ok) {
            return res;
          }

          let errorMessage = '';
          try {
            const json = await res.json();
            errorMessage = errorsFromJson(json) ?? 'Unknown Error';
          } catch (e) {
            errorMessage = `${
              res.statusText || getTitle((res as any)?._bodyText)
            } ${e}`;
          }
          throw new Error(errorMessage);
        })
        .then((res) => res.json())
        .then((res) => {
          const hasErrors = res.errors?.length;

          if (hasErrors) {
            const errorMessage = errorsFromJson(res) ?? 'Unknown Error';
            throw new Error(errorMessage);
          }
          return res;
        })
        .then((res) => {
          return res;
        });

      return response;
    };

    return this.queue.enqueue(fn) as Promise<Response>;
  };

  public fetch = async <Response = any>(args: FetchArgs): Promise<Response> => {
    if (!this.config) {
      throw new Error(
        'Fetcher not configured.\n Call Fetcher.configure() first.'
      );
    }
    if (!(await this.config.getIsOnline())) {
      return Promise.reject(new Error(RequestErrors.NetworkError));
    }

    const { variables, token, method } = args;
    const uri = args.uri ?? this.config?.uri ?? '';
    const tokenToUse =
      token === false ? false : token ?? (await this.config.getAuthToken());

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(await this.getXHeaders()),
      ...(tokenToUse ? { Authorization: `Bearer ${tokenToUse}` } : {}),
    };

    const hasBody = method === 'POST' || method === 'PATCH' || method === 'PUT';
    const body = hasBody ? JSON.stringify(variables) : undefined;

    const fetchOptions: RequestInit = {
      method,
      headers,
      ...(body ? { body } : {}),
    };

    let fullUri = uri.startsWith('/') ? `${this.config.restUri}${uri}` : uri;
    if (method === 'GET' && variables) {
      const params = new URLSearchParams(variables);
      fullUri += `?${params.toString()}`;
    }

    const fn = async () => {
      const response = await fetch(fullUri, fetchOptions)
        .then(async (res) => {
          this.config?.onResponse &&
            this.config.onResponse({
              uri: res.url,
              method: method,
              operationName: uri,
              variables,
              status: res.status,
            });

          if (res.ok) {
            return res;
          }

          try {
            const json = await res.json();
            if (json.error) {
              throw new FetcherError(json.error);
            } else {
              throw new FetcherError(
                json.errors || {
                  message: 'Unknown Error',
                }
              );
            }
          } catch (e) {
            throw new FetcherError(e);
          }
        })
        .then((res) => res.json());

      return response;
    };

    return this.queue.enqueue(fn) as Promise<Response>;
  };
}

export interface FetchGraphqlArgs {
  uri?: string;
  query: string;
  variables?: any;
  token?: false | string;
}

export interface FetchArgs {
  uri: string;
  method: 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE';
  variables?: any;
  token?: false | string;
}

const fetcher = Fetcher.getInstance();

export default fetcher;
