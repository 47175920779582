import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/paddocks';

export const usePaddocks = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddocks);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const usePaddock = (paddockId: string, propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddocks);

  return proxyUseEntity({
    entityId: paddockId,
    parentId: propertyId,
    dispatch,
    state,
  });
};
