import React from 'react';

import { useI18n } from '@mobble/i18n';
import {
  type Point,
  pointIsInPolygon,
} from '@mobble/models/src/model/MapGeometry';
import {
  sortAnyContainingPaddocks,
  sortOptionsNameDistanceFromMe,
} from '@mobble/models/src/model/Paddock';
import { type Task } from '@mobble/models/src/model/Task';
import { fromRawDate } from '@mobble/shared/src/core/Date';
import { base64decode } from '@mobble/shared/src/core/String';
import { useExtStatus, usePaddockGeometries } from '@mobble/store/src/hooks';

import { useFormError } from '@src/hooks/useFormError';
import { useMyLocation } from '@src/hooks/useMyLocation';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { usePaddockCardFactory } from '@src/hooks/usePaddockCardFactory';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { type ScreenRendererProps } from '@src/screens/config/types';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  TaskCreateForm,
  type TaskCreateFormValues,
} from '@src/stories/Views/Task/TaskCreateForm';

import { useTaskScreen, useTaskScreenHeader } from './useTaskScreen';

export const TaskCreateHeader: React.FC<ScreenRendererProps> = () => {
  const props = useTaskScreenHeader();

  return <ScreenHeader modal {...props} />;
};

export const TaskCreate: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const myLocation = useMyLocation();
  const goBack = useNavigateBack();

  const { Prelude, task, paddocks, properties } = useTaskScreen();

  const paddockGeometries = usePaddockGeometries(properties.selected?.id);
  const makePaddockCard = usePaddockCardFactory({
    propertyId: properties.selected?.id,
    location: myLocation.location,
  });
  const extStatus = useExtStatus('tasks', 'create', properties.selected?.id);
  const formLoading = extStatus?.loading;
  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Task',
      description: 'screen.title.task_detail',
    }),
    active: Boolean(extStatus?.error),
  });

  const findPaddockIdForPoint = (point: Point) => {
    return paddockGeometries.entities.find((pg) =>
      pointIsInPolygon(point, pg.polygon)
    )?.paddockId;
  };

  const fromRoute = (() => {
    if (props.route.params?.point) {
      const point = JSON.parse(base64decode(props.route.params.point));
      if (point) {
        return {
          paddockId: findPaddockIdForPoint(point),
          location: point,
        };
      }
      return {
        paddockId: props.route.params.paddockId,
        // location: point,
      };
    } else if (props.route.params.paddockId) {
      return { paddockId: props.route.params.paddockId };
    }
    return {};
  })();

  const paddockSortMeta = {
    origin: myLocation.location || undefined,
    paddockGeometries: paddockGeometries.entities,
  };

  const sortPaddockOptions = sortAnyContainingPaddocks((o) => o.entity)(
    paddockSortMeta
  );

  // TODO: move to selector
  const paddockOptions = paddocks.entities.map((p) => ({
    label: p.name,
    value: p.id,
    entity: p,
    component: makePaddockCard(p),
  }));

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: TaskCreateFormValues) => {
    if (!properties.selected) {
      return Promise.reject();
    }

    const point = formValues.location || null;
    const paddockId = point
      ? findPaddockIdForPoint(point)
      : formValues.paddock_id;

    const newTask: Omit<Task, 'id'> = {
      propertyId: properties.selected.id,
      title: formValues.title,
      description: formValues.description,
      createdAt: new Date(),
      status: 'pending',
      dueDate: formValues.due_date
        ? fromRawDate(formValues.due_date)
        : undefined,
      paddockId,
      location: point,
      assignedToUsers: (formValues.assigned_to || []).map((id) => ({
        id,
        email: id,
        name: id,
      })),
    };

    task
      .create(newTask)
      .then((task) => {
        // TODO: replace with Toast/Alert
        console.log(`Task '${task.title}' successfully created`);
        console.log(task);
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <TaskCreateForm
      initialValues={{
        location: fromRoute?.location,
        paddock_id: fromRoute?.paddockId,
      }}
      paddockOptions={paddockOptions}
      paddockSortOptions={sortOptionsNameDistanceFromMe}
      sortPaddockOptions={sortPaddockOptions}
      users={properties.selected.users}
      findPaddockIdForPoint={findPaddockIdForPoint}
      error={formError}
      loading={formLoading}
      onCancel={goBack}
      onTouched={() => {}}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_TASK_CREATE,
  header: TaskCreateHeader,
  component: TaskCreate,
  modal: true,
};
