import {
  type HistoricEvent,
  mergeAllHistoricEvents,
} from '@mobble/models/src/model/HistoricEvent';
import mobbleService from '@mobble/service';
import {
  ExtFilterParams,
  type ExtStatusPerActionPerParent,
} from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { type FilterPerProperty } from '../lib/filter';

export interface HistoricEventsState {
  extStatus: ExtStatusPerActionPerParent;
  entities: HistoricEvent[];
  filter: FilterPerProperty;
  sort: {};
}

export const defaultState: HistoricEventsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const matchesExtFilter =
  (extFilter: ExtFilterParams) => (entity: HistoricEvent) => {
    if (extFilter?.paddockId) {
      return (
        entity.related.paddockId?.includes(String(extFilter.paddockId)) ?? false
      );
    }
    if (extFilter?.mobId) {
      return entity.related.mobId?.includes(String(extFilter.mobId)) ?? false;
    }
    return true;
  };

const merger = (args: {
  current: HistoricEvent[];
  incoming: HistoricEvent[];
  parentId: string;
  extFilter?: ExtFilterParams;
  cursor?: string;
}): HistoricEvent[] => {
  const combined = [...args.current, ...args.incoming];
  const merged = mergeAllHistoricEvents(combined);

  if (!args.cursor) {
    if (args.extFilter) {
      return [
        ...args.current.filter(
          (a) => !matchesExtFilter(args.extFilter as ExtFilterParams)(a)
        ),
        ...args.incoming,
      ];
    } else {
      return args.incoming;
    }
  }

  return merged;
};

const entitySlice = entitySliceFactory<HistoricEventsState>({
  name: 'historicEvents',
  initialState: defaultState,
  toParentId: ({ propertyId }: HistoricEvent) => propertyId,
  matchesExtFilter,
  merger,
  onGetAll: mobbleService.api.historicEvents.get,
  onCreate: () => Promise.reject(),
  onUpdate: () => Promise.reject(),
  onDelete: () => Promise.reject(),
});

export const {
  thunkGetAll,
  //
  proxyUseFilter,
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;

export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
