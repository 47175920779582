import React from 'react';
import { defineMessages } from 'react-intl';
import { useMessages } from '@mobble/i18n';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useProperties, useHistoricEvents } from '@mobble/store/src/hooks';
import {
  type HistoricEvent,
  filterHistoricEvents,
  eventTypes,
  eventTypeToColor,
} from '@mobble/models/src/model/HistoricEvent';
import { formatDateAndTime, fromRawDate } from '@mobble/shared/src/core/Date';

import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';

import { EntitiesViewer } from '@src/stories/Views/Entities/EntitiesViewer';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { HistoricEventCard } from '@src/stories/Views/HistoricEvent/HistoricEventCard';

import * as ROUTE_NAME from '../config/routeNames';

export interface HistoricEventsProps {
  title?: string;
  className?: string;
  extFilter?: any;
  asList?: boolean;
}

const messages = defineMessages({
  'historic_events.filter.group.event_type.label': {
    defaultMessage: 'Event type',
    description: 'historic_events.filter.group.event_type.label',
  },
  'historic_events.event.event_type.CasualtyAddition': {
    defaultMessage: 'Casualty Addition',
    description: 'historic_events.event.event_type.CasualtyAddition',
  },
  'historic_events.event.event_type.CasualtyDeletion': {
    defaultMessage: 'Casualty Deletion',
    description: 'historic_events.event.event_type.CasualtyDeletion',
  },
  'historic_events.event.event_type.CasualtyEdit': {
    defaultMessage: 'Casualty Edit',
    description: 'historic_events.event.event_type.CasualtyEdit',
  },
  'historic_events.event.event_type.ChemicalAddition': {
    defaultMessage: 'Chemical Addition',
    description: 'historic_events.event.event_type.ChemicalAddition',
  },
  'historic_events.event.event_type.ChemicalBatchAddition': {
    defaultMessage: 'Chemical Batch Addition',
    description: 'historic_events.event.event_type.ChemicalBatchAddition',
  },
  'historic_events.event.event_type.ChemicalDeletion': {
    defaultMessage: 'Chemical Deletion',
    description: 'historic_events.event.event_type.ChemicalDeletion',
  },
  'historic_events.event.event_type.ChemicalEdit': {
    defaultMessage: 'Chemical Edit',
    description: 'historic_events.event.event_type.ChemicalEdit',
  },
  'historic_events.event.event_type.ChemicalBatchEdit': {
    defaultMessage: 'Chemical Batch Edit',
    description: 'historic_events.event.event_type.ChemicalBatchEdit',
  },
  'historic_events.event.event_type.FeedAddition': {
    defaultMessage: 'Product Addition',
    description: 'historic_events.event.event_type.FeedAddition',
  },
  'historic_events.event.event_type.FeedDeletion': {
    defaultMessage: 'Product Deletion',
    description: 'historic_events.event.event_type.FeedDeletion',
  },
  'historic_events.event.event_type.FeedEdit': {
    defaultMessage: 'Product Edit',
    description: 'historic_events.event.event_type.FeedEdit',
  },
  'historic_events.event.event_type.FeedFinished': {
    defaultMessage: 'Product Finished',
    description: 'historic_events.event.event_type.FeedFinished',
  },
  'historic_events.event.event_type.MapAssetAddition': {
    defaultMessage: 'Map Asset Addition',
    description: 'historic_events.event.event_type.MapAssetAddition',
  },
  'historic_events.event.event_type.MapAssetEdit': {
    defaultMessage: 'Map Asset Edit',
    description: 'historic_events.event.event_type.MapAssetEdit',
  },
  'historic_events.event.event_type.MobActionAddition': {
    defaultMessage: 'Mob Action Addition',
    description: 'historic_events.event.event_type.MobActionAddition',
  },
  'historic_events.event.event_type.MobActionDeletion': {
    defaultMessage: 'Mob Action Deletion',
    description: 'historic_events.event.event_type.MobActionDeletion',
  },
  'historic_events.event.event_type.MobActionEdit': {
    defaultMessage: 'Mob Action Edit',
    description: 'historic_events.event.event_type.MobActionEdit',
  },
  'historic_events.event.event_type.MobAddition': {
    defaultMessage: 'Mob Addition',
    description: 'historic_events.event.event_type.MobAddition',
  },
  'historic_events.event.event_type.MobArchiving': {
    defaultMessage: 'Mob Archiving',
    description: 'historic_events.event.event_type.MobArchiving',
  },
  'historic_events.event.event_type.MobDeletion': {
    defaultMessage: 'Mob Deletion',
    description: 'historic_events.event.event_type.MobDeletion',
  },
  'historic_events.event.event_type.MobEdit': {
    defaultMessage: 'Mob Edit',
    description: 'historic_events.event.event_type.MobEdit',
  },
  'historic_events.event.event_type.MobMerge': {
    defaultMessage: 'Mob Merge',
    description: 'historic_events.event.event_type.MobMerge',
  },
  'historic_events.event.event_type.MobMove': {
    defaultMessage: 'Mob Move',
    description: 'historic_events.event.event_type.MobMove',
  },
  'historic_events.event.event_type.MobMoveToProp': {
    defaultMessage: 'Mob Move To Property',
    description: 'historic_events.event.event_type.MobMoveToProp',
  },
  'historic_events.event.event_type.MobSplit': {
    defaultMessage: 'Mob Split',
    description: 'historic_events.event.event_type.MobSplit',
  },
  'historic_events.event.event_type.NaturalIncreaseAddition': {
    defaultMessage: 'Natural Increase Addition',
    description: 'historic_events.event.event_type.NaturalIncreaseAddition',
  },
  'historic_events.event.event_type.PaddockActionAddition': {
    defaultMessage: 'Paddock Action Addition',
    description: 'historic_events.event.event_type.PaddockActionAddition',
  },
  'historic_events.event.event_type.PaddockActionDeletion': {
    defaultMessage: 'Paddock Action Deletion',
    description: 'historic_events.event.event_type.PaddockActionDeletion',
  },
  'historic_events.event.event_type.PaddockActionEdit': {
    defaultMessage: 'Paddock Action Edit',
    description: 'historic_events.event.event_type.PaddockActionEdit',
  },
  'historic_events.event.event_type.PaddockAddition': {
    defaultMessage: 'Paddock Addition',
    description: 'historic_events.event.event_type.PaddockAddition',
  },
  'historic_events.event.event_type.PaddockDeletion': {
    defaultMessage: 'Paddock Deletion',
    description: 'historic_events.event.event_type.PaddockDeletion',
  },
  'historic_events.event.event_type.PaddockEdit': {
    defaultMessage: 'Paddock Edit',
    description: 'historic_events.event.event_type.PaddockEdit',
  },
  'historic_events.event.event_type.PaddockOpenGroupCreate': {
    defaultMessage: 'Open Gates',
    description: 'historic_events.event.event_type.PaddockOpenGroupCreate',
  },
  'historic_events.event.event_type.PropertyAddition': {
    defaultMessage: 'Property Addition',
    description: 'historic_events.event.event_type.PropertyAddition',
  },
  'historic_events.event.event_type.PurchaseAddition': {
    defaultMessage: 'Purchase Addition',
    description: 'historic_events.event.event_type.PurchaseAddition',
  },
  'historic_events.event.event_type.RainGaugeAddition': {
    defaultMessage: 'Rain Gauge Addition',
    description: 'historic_events.event.event_type.RainGaugeAddition',
  },
  'historic_events.event.event_type.RainGaugeDeletion': {
    defaultMessage: 'Rain Gauge Deletion',
    description: 'historic_events.event.event_type.RainGaugeDeletion',
  },
  'historic_events.event.event_type.RainGaugeEdit': {
    defaultMessage: 'Rain Gauge Edit',
    description: 'historic_events.event.event_type.RainGaugeEdit',
  },
  'historic_events.event.event_type.RainGaugeReadingAddition': {
    defaultMessage: 'Rain Gauge Reading Addition',
    description: 'historic_events.event.event_type.RainGaugeReadingAddition',
  },
  'historic_events.event.event_type.RainGaugeReadingDeletion': {
    defaultMessage: 'Rain Gauge Reading Deletion',
    description: 'historic_events.event.event_type.RainGaugeReadingDeletion',
  },
  'historic_events.event.event_type.RainGaugeReadingEdit': {
    defaultMessage: 'Rain Gauge Reading Edit',
    description: 'historic_events.event.event_type.RainGaugeReadingEdit',
  },
  'historic_events.event.event_type.SaleAddition': {
    defaultMessage: 'Sale Addition',
    description: 'historic_events.event.event_type.SaleAddition',
  },
  'historic_events.event.event_type.SaleDeletion': {
    defaultMessage: 'Sale Deletion',
    description: 'historic_events.event.event_type.SaleDeletion',
  },
  'historic_events.event.event_type.SaleEdit': {
    defaultMessage: 'Sale Edit',
    description: 'historic_events.event.event_type.SaleEdit',
  },
  'historic_events.event.event_type.TaskAddition': {
    defaultMessage: 'Task Addition',
    description: 'historic_events.event.event_type.TaskAddition',
  },
  'historic_events.event.event_type.TaskDeletion': {
    defaultMessage: 'Task Deletion',
    description: 'historic_events.event.event_type.TaskDeletion',
  },
  'historic_events.event.event_type.TaskEdit': {
    defaultMessage: 'Task Edit',
    description: 'historic_events.event.event_type.TaskEdit',
  },
  'historic_events.event.event_type.FeedBatchAddition': {
    id: 'historic_events.event.event_type.FeedBatchAddition',
    defaultMessage: 'Product Batch Addition',
  },
  'historic_events.event.event_type.FeedBatchEdit': {
    id: 'historic_events.event.event_type.FeedBatchEdit',
    defaultMessage: 'Product Batch Edit',
  },
  'generic.refresh': {
    defaultMessage: 'Refresh',
    description: 'generic.refresh',
  },
});

export const HistoricEvents: React.FC<HistoricEventsProps> = ({
  title,
  className,
  extFilter,
  asList,
}) => {
  const linkTo = useLinking();
  const strings = useMessages(messages);
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const historicEvents = useHistoricEvents(propertyId, extFilter);
  const { refresh } = useEntitiesRefresher([historicEvents], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, historicEvents.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const filterItems = () => [
    {
      type: 'select-multiple',
      group: 'event_type',
      title: strings['historic_events.filter.group.event_type.label'],
      data: eventTypes.map((value) => ({
        value,
        label: strings[`historic_events.event.event_type.${value}`],
        color: eventTypeToColor(value),
      })),
    },
  ];

  return (
    <EntitiesViewer
      headerButtons={[
        {
          loading: historicEvents.refreshing || historicEvents.loading,
          label: strings['generic.refresh'],
          onClick: refresh,
        },
      ]}
      title={title}
      className={className}
      asList={asList}
      provider={historicEvents}
      applyFilter={filterHistoricEvents}
      filterItems={filterItems}
      tableColumns={[
        {
          key: 'time',
          label: { key: 'time' },
          totals: false,
          toValue: (historicEvent) =>
            fromRawDate(historicEvent.date).toDate().getTime(),
          valueToString: (_, historicEvent) =>
            formatDateAndTime(fromRawDate(historicEvent.date)),
        },
        {
          key: 'event_type',
          label: { key: 'event_type' },
          totals: false,
          toValue: (historicEvent) => historicEvent.eventType,
        },
        {
          key: 'created_by',
          label: { key: 'created_by' },
          totals: false,
          toValue: (historicEvent) => historicEvent.createdBy.name,
        },
        {
          key: 'description',
          label: { key: 'description' },
          totals: false,
          toValue: (historicEvent) => historicEvent.description,
        },
      ]}
      renderEntityCard={(item) => {
        const routeAndProps = getEventRouteAndProps(item);
        const path = routeAndProps
          ? toPath(routeAndProps?.route as any, routeAndProps?.props)
          : undefined;
        return (
          <HistoricEventCard
            historicEvent={item}
            href={path}
            onClick={
              routeAndProps
                ? () => {
                    linkTo(routeAndProps.route as any, routeAndProps.props);
                  }
                : undefined
            }
          />
        );
      }}
    />
  );
};

const getEventRouteAndProps = (
  event: HistoricEvent
): null | {
  route: string;
  props: { [key: string]: string } | undefined;
} => {
  if (!event.linksTo?.id) {
    return null;
  }

  switch (event.eventType) {
    case 'CasualtyAddition':
    case 'CasualtyEdit':
      return {
        route: ROUTE_NAME.CASUALTY_DETAIL,
        props: { casualtyId: event.linksTo?.id },
      };

    case 'ChemicalAddition':
    case 'ChemicalEdit':
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo?.id,
        },
      };

    case 'ChemicalBatchAddition':
    case 'ChemicalBatchEdit': {
      if (!event.related.inventoryId || !event.linksTo.parentId) {
        return null;
      }
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo.parentId,
          inventoryItemBatchId: event.linksTo.id,
        },
      };
    }

    case 'FeedAddition':
    case 'FeedEdit':
    case 'FeedFinished':
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo?.id,
        },
      };

    case 'FeedBatchAddition':
    case 'FeedBatchEdit': {
      if (!event.related.inventoryId || !event.linksTo.parentId) {
        return null;
      }
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo.parentId,
          inventoryItemBatchId: event.linksTo.id,
        },
      };
    }

    case 'MapAssetAddition':
    case 'MapAssetEdit':
      return {
        route: ROUTE_NAME.MAP_ASSET_DETAIL,
        props: { mapAssetId: event.linksTo?.id },
      };

    case 'MobActionAddition':
    case 'MobActionEdit':
      return {
        route: ROUTE_NAME.MOB_ACTION_DETAIL,
        props: { mobActionId: event.linksTo?.id },
      };

    case 'MobAddition':
    case 'MobEdit':
      return {
        route: ROUTE_NAME.MOB_DETAIL,
        props: { mobId: event.linksTo?.id },
      };

    case 'PaddockActionAddition':
    case 'PaddockActionEdit':
      return {
        route: ROUTE_NAME.PADDOCK_ACTION_DETAIL,
        props: { paddockActionId: event.linksTo?.id },
      };

    case 'PaddockAddition':
    case 'PaddockEdit':
      return {
        route: ROUTE_NAME.PADDOCK_DETAIL_INFO,
        props: { paddockId: event.linksTo?.id },
      };
    case 'PropertyAddition':
      return null;

    case 'RainGaugeAddition':
    case 'RainGaugeEdit':
    case 'RainGaugeReadingAddition':
    case 'RainGaugeReadingEdit':
      return {
        route: ROUTE_NAME.RAIN_GAUGE_DETAIL,
        props: { rainGaugeId: event.linksTo?.id },
      };

    case 'SaleAddition':
    case 'SaleEdit':
      return {
        route: ROUTE_NAME.SALE_DETAIL,
        props: { saleId: event.linksTo?.id },
      };

    case 'NaturalIncreaseAddition':
      return {
        route: ROUTE_NAME.NATURAL_INCREASE_DETAIL,
        props: { naturalIncreaseId: event.linksTo?.id },
      };

    case 'PurchaseAddition':
      return {
        route: ROUTE_NAME.PURCHASE_DETAIL,
        props: { purchaseId: event.linksTo?.id },
      };

    case 'TaskAddition':
    case 'TaskEdit':
      return {
        route: ROUTE_NAME.TASK_DETAIL,
        props: { taskId: event.linksTo?.id },
      };

    default:
      if (event.linksTo) {
        console.warn('Event type not handled: ', event.eventType);
      }
      return null;
  }
};
