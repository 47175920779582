import {
  ConfiguredPropertyTypeGroup,
  type ConfiguredPropertyType,
} from '@mobble/models/src/model/Property';
import {
  decodeGender,
  decodeClass,
  decodeMobActionType,
  decodePaddockType,
  decodePaddockActionType,
  decodeTag,
  decodeBreed,
} from './properties';
import { type ApiPrelude } from '../types';

export const create =
  (prelude: ApiPrelude) =>
  (propertyId: string, input: Partial<ConfiguredPropertyType>) => {
    switch (input.group) {
      case ConfiguredPropertyTypeGroup.paddockType:
        return createPaddockType(prelude)({
          propertyId,
          title: input.label ?? '',
          color: input.color,
        });
      case ConfiguredPropertyTypeGroup.breed:
        return createLivestockBreed(prelude)({
          propertyId,
          livestockTypeId: input.parentId ?? '',
          title: input.label ?? '',
        });
      case ConfiguredPropertyTypeGroup.gender:
        return createLivestockGender(prelude)({
          propertyId,
          livestockTypeId: input.parentId ?? '',
          title: input.label ?? '',
        });
      case ConfiguredPropertyTypeGroup.class:
        return createLivestockClass(prelude)({
          propertyId,
          livestockTypeId: input.parentId ?? '',
          title: input.label ?? '',
        });
      case ConfiguredPropertyTypeGroup.paddockAction:
        return createPaddockActionType(prelude)({
          propertyId,
          title: input.label ?? '',
        });
      case ConfiguredPropertyTypeGroup.mobAction:
        return createMobActionType(prelude)({
          propertyId,
          title: input.label ?? '',
        });
      default:
        throw new Error(
          `There is no mutation available for group ${input.group}`
        );
    }
  };

const ADD_PADDOCK_TYPE = `
  mutation AddPaddockType($input: AddPaddockTypeInput!) {
    addPaddockType(input: $input) {
      paddockType {
        id
        title
        color
      }
    }
  }
`;

interface AddPaddockTypeInput {
  propertyId: string;
  title: string;
  color?: string | null;
}

const createPaddockType =
  (prelude: ApiPrelude) =>
  async (input: AddPaddockTypeInput): Promise<ConfiguredPropertyType> => {
    const response = await prelude.graphql({
      query: ADD_PADDOCK_TYPE,
      variables: {
        input,
      },
    });

    return decodePaddockType(response.data.addPaddockType.paddockType);
  };

const ADD_LIVESTOCK_BREED = `
  mutation AddLivestockBreed($input: AddLivestockBreedInput!) {
    addLivestockBreed(input: $input) {
      livestockBreed {
        id
        title
      }
    }
  }
`;

interface AddLivestockBreedInput {
  propertyId: string;
  livestockTypeId: string;
  title: string;
}

const createLivestockBreed =
  (prelude: ApiPrelude) =>
  async (input: AddLivestockBreedInput): Promise<ConfiguredPropertyType> => {
    const response = await prelude.graphql({
      query: ADD_LIVESTOCK_BREED,
      variables: {
        input,
      },
    });

    return decodeBreed(input.livestockTypeId)(
      response.data.addLivestockBreed.livestockBreed
    );
  };

const ADD_LIVESTOCK_GENDER = `
  mutation AddLivestockGender($input: AddLivestockGenderInput!) {
    addLivestockGender(input: $input) {
      livestockGender {
        id
        title
      }
    }
  }
`;

interface AddLivestockGenderInput {
  propertyId: string;
  livestockTypeId: string;
  title: string;
}

const createLivestockGender =
  (prelude: ApiPrelude) =>
  async (input: AddLivestockGenderInput): Promise<ConfiguredPropertyType> => {
    const response = await prelude.graphql({
      query: ADD_LIVESTOCK_GENDER,
      variables: {
        input,
      },
    });

    return decodeGender(input.livestockTypeId)(
      response.data.addLivestockGender.livestockGender
    );
  };

const ADD_LIVESTOCK_CLASS = `
  mutation AddLivestockClass($input: AddLivestockClassInput!) {
    addLivestockClass(input: $input) {
      livestockClass {
        id
        title
      }
    }
  }
`;

interface AddLivestockClassInput {
  propertyId: string;
  livestockTypeId: string;
  title: string;
}

const createLivestockClass =
  (prelude: ApiPrelude) =>
  async (input: AddLivestockClassInput): Promise<ConfiguredPropertyType> => {
    const response = await prelude.graphql({
      query: ADD_LIVESTOCK_CLASS,
      variables: {
        input,
      },
    });

    return decodeClass(input.livestockTypeId)(
      response.data.addLivestockClass.livestockClass
    );
  };

const ADD_MOB_ACTION_TYPE = `
  mutation AddMobActionType($input: AddMobActionTypeInput!) {
    addMobActionType(input: $input) {
      mobActionType {
        id
        title
      }
    }
  }
`;

interface AddMobActionTypeInput {
  propertyId: string;
  title: string;
}

const createMobActionType =
  (prelude: ApiPrelude) =>
  async (input: AddMobActionTypeInput): Promise<ConfiguredPropertyType> => {
    const response = await prelude.graphql({
      query: ADD_MOB_ACTION_TYPE,
      variables: {
        input,
      },
    });

    return decodeMobActionType(response.data.addMobActionType.mobActionType);
  };

const ADD_PADDOCK_ACTION_TYPE = `
  mutation AddPaddockActionType($input: AddPaddockActionTypeInput!) {
    addPaddockActionType(input: $input) {
      paddockActionType {
        id
        title
      }
    }
  }
`;

interface AddPaddockActionTypeInput {
  propertyId: string;
  title: string;
}

const createPaddockActionType =
  (prelude: ApiPrelude) =>
  async (input: AddPaddockActionTypeInput): Promise<ConfiguredPropertyType> => {
    const response = await prelude.graphql({
      query: ADD_PADDOCK_ACTION_TYPE,
      variables: {
        input,
      },
    });

    return decodePaddockActionType(
      response.data.addPaddockActionType.paddockActionType
    );
  };

//////////

export const update =
  (prelude: ApiPrelude) =>
  (propertyId: string, input: ConfiguredPropertyType) => {
    switch (input.group) {
      case ConfiguredPropertyTypeGroup.paddockType:
        return updatePaddockType(prelude)({
          propertyId,
          id: input.id,
          title: input.label ?? '',
          color: input.color,
        });
      case ConfiguredPropertyTypeGroup.tag:
        return updateLivestockTag(prelude)({
          id: input.id,
          livestockTypeId: input.parentId ?? '',
          propertyId,
          title: input.label ?? '',
          color: input.color,
        });
      default:
        throw new Error(
          `There is no edit mutation available for group ${input.group}`
        );
    }
  };

const EDIT_PADDOCK_TYPE = `
  mutation EditPaddockType($input: EditPaddockTypeInput!) {
    editPaddockType(input: $input) {
      paddockType {
        id
        title
        color
      }
    }
  }
`;

interface EditPaddockTypeInput {
  propertyId: string;
  id: string;
  title: string;
  color?: string | null;
}

const updatePaddockType =
  (prelude: ApiPrelude) =>
  async (input: EditPaddockTypeInput): Promise<ConfiguredPropertyType> => {
    const response = await prelude.graphql({
      query: EDIT_PADDOCK_TYPE,
      variables: {
        input,
      },
    });

    return decodePaddockType(response.data.editPaddockType.paddockType);
  };

const EDIT_LIVESTOCK_TAG = `
  mutation EditLivestockTag($input: EditLivestockTagInput!) {
    editLivestockTag(input: $input) {
      livestockTag {
        id
        title
        color
      }
    }
  }
`;

interface EditLivestockTagInput {
  propertyId: string;
  id: string;
  color?: string | null;
  livestockTypeId: string;
  title?: string | null;
}

const updateLivestockTag =
  (prelude: ApiPrelude) =>
  async (input: EditLivestockTagInput): Promise<ConfiguredPropertyType> => {
    const response = await prelude.graphql({
      query: EDIT_LIVESTOCK_TAG,
      variables: {
        input,
      },
    });

    return decodeTag(input.livestockTypeId)(
      response.data.editLivestockTag.livestockTag
    );
  };

//////
export const remove =
  (prelude: ApiPrelude) =>
  (propertyId: string, input: ConfiguredPropertyType): Promise<true> => {
    switch (input.group) {
      case ConfiguredPropertyTypeGroup.paddockType:
        return deletePaddockType(prelude)({ propertyId, id: input.id });
      case ConfiguredPropertyTypeGroup.breed:
        return deleteLivestockBreed(prelude)({ propertyId, id: input.id });
      case ConfiguredPropertyTypeGroup.gender:
        return deleteLivestockGender(prelude)({ propertyId, id: input.id });
      case ConfiguredPropertyTypeGroup.class:
        return deleteLivestockClass(prelude)({ propertyId, id: input.id });
      case ConfiguredPropertyTypeGroup.paddockAction:
        return deletePaddockActionType(prelude)({ propertyId, id: input.id });
      case ConfiguredPropertyTypeGroup.mobAction:
        return deleteMobActionType(prelude)({ propertyId, id: input.id });

      default:
        throw new Error(
          `There is no delete mutation available for group ${input.group}`
        );
    }
  };

const DELETE_PADDOCK_TYPE = `
  mutation DeletePaddockType($input: DeletePaddockTypeInput!) {
    deletePaddockType(input: $input) {
      paddockType {
        id
        title
      }
    }
  }
`;

interface DeletePaddockTypeInput {
  propertyId: string;
  id: string;
}

const deletePaddockType =
  (prelude: ApiPrelude) =>
  async (input: DeletePaddockTypeInput): Promise<true> => {
    await prelude.graphql({
      query: DELETE_PADDOCK_TYPE,
      variables: {
        input,
      },
    });

    return true;
  };

const DELETE_LIVESTOCK_BREED = `
  mutation DeleteLivestockBreed($input: DeleteLivestockBreedInput!) {
    deleteLivestockBreed(input: $input) {
      livestockBreed {
        id
        title
      }
    }
  }
`;

interface DeleteLivestockBreedInput {
  propertyId: string;
  id: string;
}

const deleteLivestockBreed =
  (prelude: ApiPrelude) =>
  async (input: DeleteLivestockBreedInput): Promise<true> => {
    await prelude.graphql({
      query: DELETE_LIVESTOCK_BREED,
      variables: {
        input,
      },
    });

    return true;
  };

const DELETE_LIVESTOCK_GENDER = `
  mutation DeleteLivestockGender($input: DeleteLivestockGenderInput!) {
    deleteLivestockGender(input: $input) {
      livestockGender {
        id
        title
      }
    }
  }
`;

interface DeleteLivestockGenderInput {
  propertyId: string;
  id: string;
}

const deleteLivestockGender =
  (prelude: ApiPrelude) =>
  async (input: DeleteLivestockGenderInput): Promise<true> => {
    await prelude.graphql({
      query: DELETE_LIVESTOCK_GENDER,
      variables: {
        input,
      },
    });

    return true;
  };

const DELETE_LIVESTOCK_CLASS = `
  mutation DeleteLivestockClass($input: DeleteLivestockClassInput!) {
    deleteLivestockClass(input: $input) {
      livestockClass {
        id
        title
      }
    }
  }
`;

interface DeleteLivestockClassInput {
  propertyId: string;
  id: string;
}

const deleteLivestockClass =
  (prelude: ApiPrelude) =>
  async (input: DeleteLivestockClassInput): Promise<true> => {
    await prelude.graphql({
      query: DELETE_LIVESTOCK_CLASS,
      variables: {
        input,
      },
    });

    return true;
  };

const DELETE_PADDOCK_ACTION_TYPE = `
  mutation DeletePaddockActionType($input: DeletePaddockActionTypeInput!) {
    deletePaddockActionType(input: $input) {
      paddockActionType {
        id
        title
      }
    }
  }
`;

interface DeletePaddockActionTypeInput {
  propertyId: string;
  id: string;
}

const deletePaddockActionType =
  (prelude: ApiPrelude) =>
  async (input: DeletePaddockActionTypeInput): Promise<true> => {
    await prelude.graphql({
      query: DELETE_PADDOCK_ACTION_TYPE,
      variables: {
        input,
      },
    });

    return true;
  };

const DELETE_MOB_ACTION_TYPE = `
  mutation DeleteMobActionType($input: DeleteMobActionTypeInput!) {
    deleteMobActionType(input: $input) {
      mobActionType {
        id
        title
      }
    }
  }
`;

interface DeleteMobActionTypeInput {
  propertyId: string;
  id: string;
}

const deleteMobActionType =
  (prelude: ApiPrelude) =>
  async (input: DeleteMobActionTypeInput): Promise<true> => {
    await prelude.graphql({
      query: DELETE_MOB_ACTION_TYPE,
      variables: {
        input,
      },
    });

    return true;
  };
