import { ALL_USER_ROLES, UserRole } from '@mobble/models/src/model/User';

import * as R from './routeNames';

export type AccessMatrix = Record<string, UserRole[]>;

const o = UserRole.Owner;
const a = UserRole.Admin;
const u = UserRole.User;
const v_o = UserRole.ViewOnly;
const mc_fa = UserRole.FarmAdvisor;
const mc_st = UserRole.StockAgent;
const mc_ac = UserRole.Accountant;
const mc_co = UserRole.Contractor;
const mc_au = UserRole.Auditor;

const allUsers = ALL_USER_ROLES;
const coreUsers = [o, a, u];

export const ACCESS_MATRIX: AccessMatrix = {
  [R.ACTION_QUEUE_LIST]: [...coreUsers, v_o, mc_fa, mc_st],
  [R.AUTH_FORGOT_PASSWORD]: [...coreUsers, v_o, mc_fa],
  [R.AUTH_SIGN_IN]: [...allUsers],
  [R.CASUALTY_DETAIL]: [...coreUsers, v_o, mc_fa, mc_ac, mc_au],
  [R.CASUALTY_EDIT]: [...coreUsers],
  [R.CHATS_LIST]: [...coreUsers, v_o, mc_fa, mc_st],
  [R.CUSTOM_MAP_LAYER_DETAIL]: [...coreUsers, v_o, mc_fa, mc_co],
  [R.CUSTOM_MAP_LAYER_EDIT]: [...coreUsers],
  [R.DASHBOARD]: [...coreUsers, v_o, mc_fa],
  [R.IMPORT_AGWORLD_PADDOCKS]: [...coreUsers, v_o, mc_fa, mc_co],
  [R.INVENTORIES_LIST]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.INVENTORY_EDIT]: [...coreUsers],
  [R.INVENTORY_ITEM_DETAIL]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.INVENTORY_ITEM_EDIT]: [...coreUsers],
  [R.INVENTORY_ITEMS]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.INVENTORY_ITEM_BATCH_DETAIL]: [...coreUsers, v_o, mc_fa],
  [R.INVENTORY_ITEM_BATCH_EDIT]: [...coreUsers],
  [R.MODAL_INVENTORY_ITEM_BATCH_CREATE]: [...coreUsers],
  [R.MAP_ASSET_DETAIL]: [...coreUsers, v_o, mc_fa, mc_co],
  [R.MAP_ASSET_EDIT]: [...coreUsers],
  [R.MAP_ASSETS_LIST]: [...coreUsers, v_o, mc_fa, mc_co],
  [R.MAP_OVERVIEW]: [...coreUsers, v_o, mc_fa, mc_co, mc_au],
  [R.MOB_ACTION_DETAIL]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.MOB_ACTION_EDIT]: [...coreUsers],
  [R.MOB_DETAIL_EDIT]: [...coreUsers, mc_st],
  [R.MOB_DETAIL_HISTORY]: [...coreUsers, v_o, mc_fa, mc_st, mc_au],
  [R.MOB_DETAIL_INFO]: [...coreUsers, v_o, mc_fa, mc_st, mc_au],
  [R.MOB_DETAIL]: [...coreUsers, v_o, mc_fa, mc_st, mc_au],
  [R.MOBBLE_CONNECT_USER_EDIT]: [o, a],
  [R.MOBBLE_CONNECT_USER_INVITE]: [o, a],
  [R.MOBBLE_CONNECT_USER]: [o, a],
  [R.MOBBLE_CONNECT_USERS]: [o, a],
  [R.MOBS_LIST]: [...coreUsers, v_o, mc_fa, mc_st, mc_au],
  [R.MODAL_CASUALTY_CREATE]: [...coreUsers],
  [R.MODAL_INVENTORY_ITEM_CREATE]: [...coreUsers],
  [R.MODAL_MAP_ASSET_CREATE]: [...coreUsers, v_o, mc_fa, mc_st],
  [R.MODAL_MOB_ACTION_CREATE]: [...coreUsers],
  [R.MODAL_MOB_CREATE]: [...coreUsers, mc_st],
  [R.MODAL_MOB_MERGE]: [...coreUsers],
  [R.MODAL_MOB_MOVE]: [...coreUsers],
  [R.MODAL_PADDOCK_ACTION_CREATE]: [...coreUsers],
  [R.MODAL_PADDOCK_CREATE]: [...coreUsers],
  [R.MODAL_PADDOCK_GROUP_CREATE]: [...coreUsers],
  [R.MODAL_PADDOCK_GROUP_EDIT]: [...coreUsers],
  [R.MODAL_PROPERTY_CREATE]: [o],
  [R.MODAL_RAIN_GAUGE_CREATE]: [...coreUsers],
  [R.MODAL_RAIN_GAUGE_READING_CREATE]: [...coreUsers],
  [R.MODAL_RAIN_GAUGE_READING_EDIT]: [...coreUsers],
  [R.MODAL_SALE_CREATE]: [o, a, mc_st],
  [R.MODAL_SETTINGS_PROPERTY_CLASSES_TYPES_CREATE]: [...coreUsers],
  [R.MODAL_SETTINGS_PROPERTY_USERS_INVITE_USER]: [a, o],
  [R.MODAL_TASK_CREATE]: [...coreUsers, v_o, mc_fa],
  [R.NATURAL_INCREASE_DETAIL]: [...coreUsers, v_o, mc_fa, mc_ac, mc_au],
  [R.NATURAL_INCREASE_EDIT]: [...coreUsers],
  [R.NO_PROPERTY_ACCESS]: [...coreUsers, v_o, mc_fa],
  [R.PADDOCK_ACTION_DETAIL]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.PADDOCK_ACTION_EDIT]: [...coreUsers],
  [R.PADDOCK_DETAIL_EDIT]: [...coreUsers],
  [R.PADDOCK_DETAIL_HISTORY]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.PADDOCK_DETAIL_INFO]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.PADDOCK_DETAIL_MOBS]: [...coreUsers, v_o, mc_fa, mc_st, mc_au],
  [R.PADDOCK_DETAIL_TASKS]: [...coreUsers, v_o, mc_fa, mc_co, mc_au],
  [R.PADDOCK_DETAIL]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.PADDOCKS_LIST]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.PURCHASE_DETAIL]: [o, a, mc_ac, mc_st, mc_au],
  [R.PURCHASE_EDIT]: [o, a],
  [R.RAIN_GAUGE_DETAIL_EDIT]: [...coreUsers],
  [R.RAIN_GAUGE_DETAIL_HISTORY]: [...coreUsers, v_o, mc_fa],
  [R.RAIN_GAUGE_DETAIL_INFO]: [...coreUsers, v_o, mc_fa],
  [R.RAIN_GAUGE_DETAIL]: [...coreUsers, v_o, mc_fa],
  [R.RAIN_GAUGE_READING]: [...coreUsers, v_o, mc_fa],
  [R.RAIN_GAUGES_LIST]: [...coreUsers, v_o, mc_fa],
  [R.REFER_FARMER]: [...allUsers],
  [R.REPORTS]: [o, a, mc_ac, mc_fa, mc_st],
  [R.ROOT_DRAWER_TABS_APP]: [...allUsers],
  [R.ROOT_DRAWER_TABS]: [...allUsers],
  [R.ROOT_DRAWER]: [...allUsers],
  [R.SALE_DETAIL]: [o, a, mc_ac, mc_st, mc_au],
  [R.SALE_EDIT]: [o, a, mc_st],
  [R.SETTINGS_BILLING]: [o],
  [R.SETTINGS_LIST]: [...allUsers],
  [R.SETTINGS_LOGGER]: [...coreUsers, v_o, mc_fa],
  [R.SETTINGS_MOBBLE_APPS]: [...coreUsers, v_o, mc_fa],
  [R.SETTINGS_OFFLINE]: [...coreUsers, v_o, mc_fa],
  [R.SETTINGS_PROPERTY_CUSTOM_MAP_LAYERS]: [...coreUsers, v_o, mc_fa, mc_ac, mc_st, mc_co, mc_au],
  [R.SETTINGS_PROPERTY_CLASSES_TYPES_EDIT]: [...coreUsers],
  [R.SETTINGS_PROPERTY_CLASSES_TYPES_GROUP]: [...coreUsers, v_o, mc_fa],
  [R.SETTINGS_PROPERTY_CLASSES_TYPES]: [...coreUsers],
  [R.SETTINGS_PROPERTY_INFORMATION]: [...coreUsers],
  [R.SETTINGS_PROPERTY_USERS_USER_EDIT]: [o, a],
  [R.SETTINGS_PROPERTY_USERS]: [o, a],
  [R.SETTINGS_UNITS]: [...allUsers],
  [R.SETTINGS_USER]: [...allUsers],
  [R.SETUP_DISPLAY_NAME]: [...allUsers],
  [R.SETUP_PROPERTY]: [...coreUsers, v_o, mc_fa, mc_st],
  [R.SUMMARIES_LIST]: [...coreUsers, v_o, mc_fa, mc_ac, mc_st, mc_co, mc_au],
  [R.SUMMARY_CASUALTIES]: [...coreUsers, v_o, mc_fa, mc_ac, mc_au],
  [R.SUMMARY_EVENTS_LIST]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.SUMMARY_LIVESTOCK_NUMBERS]: [...coreUsers, v_o, mc_fa, mc_st, mc_ac, mc_au],
  [R.SUMMARY_MOB_ACTIONS_LIST]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.SUMMARY_NATURAL_INCREASES_LIST]: [...coreUsers, v_o, mc_fa, mc_ac, mc_au],
  [R.SUMMARY_PADDOCK_ACTIONS_LIST]: [...coreUsers, v_o, mc_fa, mc_au],
  [R.SUMMARY_PURCHASES]: [o, a, mc_ac, mc_st, mc_au],
  [R.SUMMARY_SALES_LIST]: [o, a, mc_ac, mc_st, mc_au],
  [R.TASK_DETAIL]: [...coreUsers, v_o, mc_fa, mc_co, mc_au],
  [R.TASK_EDIT]: [...coreUsers, v_o, mc_fa, mc_co],
  [R.TASKS_LIST_MY_COMPLETED]: [...coreUsers, v_o, mc_fa, mc_co],
  [R.TASKS_LIST_MY_TASKS]: [...coreUsers, v_o, mc_fa, mc_st],
  [R.TASKS_LIST_PENDING]: [...coreUsers, v_o, mc_fa, mc_st],
  [R.TASKS_LIST]: [...coreUsers, v_o, mc_fa, mc_co, mc_au],
};
