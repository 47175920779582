import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { mapDetails } from '@mobble/models/src/model/MapDetail';
import { MapStyle } from '@mobble/models/src/model/MapStyle';
import { Settings, StockingUnit } from '@mobble/models/src/model/Settings';

export type SettingsState = Settings;

export const defaultState: SettingsState = {
  areaUnit: 'ha',
  volumeUnit: 'L',
  massUnit: 'kg',
  lengthUnit: 'mm',
  distanceUnit: 'km',
  massVolumeUnit: 'kg',
  dateFormat: 'DD/MM/YYYY',
  timeFormat: 'HH:mm',
  currencySymbol: '$',
  stockingUnit: StockingUnit.DSE,
  mapStyle: MapStyle.Satellite,
  mapDetails: [...mapDetails],
  mapMobsFilter: [],
  mapCustomLayersFilter: [],
};

const slice = createSlice({
  name: 'settings',
  initialState: defaultState,
  reducers: {
    flush: () => defaultState,
    updateSettings: (
      state: SettingsState,
      action: PayloadAction<Partial<SettingsState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateSettings, flush } = slice.actions;

export const { reducer } = slice;
