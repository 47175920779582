import React from 'react';
import isEqual from 'lodash/isEqual';
import { useI18n } from '@mobble/i18n';
import { compareDates, formatDate } from '@mobble/shared/src/core/Date';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { type Task } from '@mobble/models/src/model/Task';
import { Color, colorToHex } from '@mobble/colors';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Card } from '@src/stories/Components/Layout/Card';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Badge } from '@src/stories/Components/UI/Badge';
import { useSetting } from '@mobble/store/src/hooks/settings';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { type QuantityOfDistance } from '@mobble/shared/src/core/Quantity';
import { Icon } from '@src/stories/Components/UI/Icon';
import { LocaleQuantity } from '@src/stories/Components/Locale/LocaleQuantity';

interface TaskCardProps {
  task: Task;
  paddock?: Paddock;
  distance?: QuantityOfDistance;
  onClick: (task: Task) => void;
}

export const TaskCardComponent: React.FC<TaskCardProps> = ({
  task,
  paddock,
  distance,
  onClick,
}) => {
  const { formatMessage } = useI18n();
  const dateFormat = useSetting('dateFormat') as string;
  const mainColor = (() => {
    switch (task.status) {
      default:
        return Color.AlmostWhite;
      case 'pending':
        if (compareDates(task.dueDate, Date.now()) === -1) {
          return Color.WashedRed;
        } else {
          return Color.AlmostWhite;
        }
      case 'done':
        return Color.LightGrey;
    }
  })();

  return (
    <Clickable
      fullWidth
      href={() => {
        onClick(task);
      }}
    >
      <Card title={task.title} color={colorToHex(mainColor)}>
        {distance ? (
          <Box flex spacing={{ bottom: 1 }}>
            <HStack>
              <Spacer flex />
              <Icon size="small" name="distance" color={Color.DarkGrey} />
              <Spacer x={1} />
              <LocaleQuantity
                color={Color.DarkGrey}
                variant="small"
                quantity={distance}
              />
            </HStack>
          </Box>
        ) : null}

        {paddock ? (
          <Box spacing={{ bottom: 1 }}>
            <Text
              i18n={formatMessage(
                {
                  defaultMessage: 'Paddock: {PADDOCK}',
                  description: 'tasks.list.task.card.paddock',
                },
                {
                  PADDOCK: paddock.name,
                }
              )}
            />
          </Box>
        ) : null}

        {task.assignedToUsers && task.assignedToUsers.length > 0 ? (
          <Box spacing={{ bottom: 1 }}>
            <Text
              i18n={formatMessage(
                {
                  defaultMessage: 'Assigned to: {NAMES}',
                  description: 'tasks.list.task.card.assigned_to',
                },
                {
                  NAMES: task.assignedToUsers.map((u) => u.name).join(', '),
                }
              )}
            />
          </Box>
        ) : null}

        {task.description ? (
          <Box spacing={{ bottom: 1 }}>
            <Text color={Color.Grey}>{task.description}</Text>
          </Box>
        ) : null}

        <Box spacing={{ top: 2 }}>
          <HStack>
            {task.dueDate ? (
              <Badge
                boxy
                icon="date"
                color={mainColor}
                label={formatDate(task.dueDate, dateFormat)}
              />
            ) : (
              <Badge
                boxy
                icon="date"
                color={Color.AlmostWhite}
                label={formatMessage({
                  defaultMessage: 'on a rainy day',
                  description: 'tasks.list.task.card.no.due_date.note',
                })}
              />
            )}
          </HStack>
        </Box>
      </Card>
    </Clickable>
  );
};

export const TaskCard = React.memo(TaskCardComponent, (a, b) => {
  return isEqual(a.task, b.task);
});
