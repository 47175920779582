export enum UserActionType {
  Inventories_InventoryItem_Create = 'inventories-inventory_item-create',

  MapAsset_Create = 'map_asset-create',

  Map_MapAsset_Create = 'map-map_asset-create',
  Map_Task_Create = 'map-task-create',
  Map_Paddock_Create = 'map-paddock-create',
  Map_Mob_Create = 'map-mob-create',

  Mobs_Create = 'mobs-create',
  Mobs_Edit = 'mobs-edit',
  Mobs_Move = 'mobs-move',
  Mobs_View = 'mobs-view',
  Mobs_Casualty_Create = 'mobs-casualty-create',
  Mobs_Sale_Create = 'mobs-sale-create',
  Mobs_MobAction_Create = 'mobs-mob_action-create',

  MobActions_Create = 'mob_actions-create',

  OpenGates_Create = 'open_gates-create',
  OpenGates_Edit = 'open_gates-edit',

  Paddocks_Create = 'paddocks-create',
  Paddocks_Task_Create = 'paddocks-tasks-create',
  Paddocks_Mob_Create = 'paddocks-mob-create',
  Paddocks_View = 'paddocks-view',
  Paddocks_Mobs_View = 'paddocks-mobs-view',
  Paddocks_Map_View = 'paddocks-map-view',
  Paddocks_PaddockAction_Create = 'paddocks-paddock-action-create',
  Paddocks_OpenGates_Create = 'paddocks-open-gates-create',
  Paddocks_OpenGates_Edit = 'paddocks-open-gates-edit',

  PaddockAction_Create = 'paddock_action-create',

  RainGauges_Create = 'rain_gauges-create',
  RainGauges_RainGaugeReading_Create = 'rain_gauges-rain_gauge_reading-create',

  RainGauges_RainGaugeReading_Edit = 'rain_gauges-rain_gauge_reading-edit',

  Recommendations_Create = 'recommendations-create',

  Tasks_Create = 'tasks-create',

  Settings_Property_PaddockType_Create = 'settings-property-paddock_type-create',
  Settings_Property_Breed_Create = 'settings-property-breed-create',
  Settings_Property_Gender_Create = 'settings-property-gender-create',
  Settings_Property_Class_Create = 'settings-property-class-create',
  Settings_Property_Tag_Create = 'settings-property-tag-create',
  Settings_Property_PaddockAction_Create = 'settings-property-paddock_action-create',
  Settings_Property_MobAction_Create = 'settings-property-mob_action-create',

  SettingsUserInvite = 'settings-users-invite',

  InventoryItem_Batch_Create = 'inventory_item-batch-create',

  InventoryItem_Info = 'inventory_item-info',
  InventoryItem_Edit = 'inventory_item-edit',
}

export interface UserActionTypeMeta {
  type: UserActionType;
  icon: string;
  titleKey: string;
  descriptionKey: string;
}

export const metaForUserActionType = (
  userActionType: UserActionType
): UserActionTypeMeta => {
  return {
    type: userActionType,
    icon: iconForUserActionType(userActionType),
    titleKey: `user_action_type.${userActionType}.title`,
    descriptionKey: `user_action_type.${userActionType}.description`,
  };
};

const iconForUserActionType = (userActionType: UserActionType): string => {
  switch (userActionType) {
    case UserActionType.Mobs_Casualty_Create:
      return 'crosshair';

    case UserActionType.Inventories_InventoryItem_Create:
    case UserActionType.Map_Mob_Create:
    case UserActionType.Map_Paddock_Create:
    case UserActionType.Paddocks_Create:
    case UserActionType.Paddocks_Mob_Create:
    case UserActionType.Paddocks_Task_Create:
    case UserActionType.RainGauges_Create:
    case UserActionType.RainGauges_RainGaugeReading_Create:
    case UserActionType.RainGauges_RainGaugeReading_Edit:
    case UserActionType.Recommendations_Create:
    case UserActionType.Settings_Property_PaddockType_Create:
    case UserActionType.Settings_Property_Breed_Create:
    case UserActionType.Settings_Property_Gender_Create:
    case UserActionType.Settings_Property_Class_Create:
    case UserActionType.Settings_Property_Tag_Create:
    case UserActionType.Settings_Property_PaddockAction_Create:
    case UserActionType.Settings_Property_MobAction_Create:
    case UserActionType.Tasks_Create:
    case UserActionType.Mobs_MobAction_Create:
    case UserActionType.Mobs_Create:
    case UserActionType.InventoryItem_Batch_Create:
      return 'plus-circle';

    case UserActionType.PaddockAction_Create:
    case UserActionType.Paddocks_PaddockAction_Create:
      return 'paddock-action';

    case UserActionType.MobActions_Create:
      return 'mob-action';

    case UserActionType.MapAsset_Create:
    case UserActionType.Map_MapAsset_Create:
      return 'map-pin';

    case UserActionType.Paddocks_Map_View:
      return 'map';

    case UserActionType.Paddocks_Mobs_View:
      return 'mob';

    case UserActionType.Mobs_View:
    case UserActionType.InventoryItem_Info:
      return 'info';

    case UserActionType.Mobs_Edit:
    case UserActionType.InventoryItem_Edit:
      return 'edit';

    case UserActionType.Mobs_Move:
      return 'arrow-right-circle';

    case UserActionType.Paddocks_OpenGates_Create:
    case UserActionType.Paddocks_OpenGates_Edit:
    case UserActionType.OpenGates_Create:
    case UserActionType.OpenGates_Edit:
      return 'share';

    case UserActionType.Paddocks_View:
      return 'paddock';

    case UserActionType.Mobs_Sale_Create:
      return 'dollar-sign';

    case UserActionType.SettingsUserInvite:
      return 'mail';

    case UserActionType.Map_Task_Create:
      return 'clipboard';
  }
};
