import React, { useState } from 'react';
import { useI18n } from '@mobble/i18n';
import {
  Mob,
  canMerge,
  findMob,
  findMobs,
  getAges,
  getClasses,
  getMaxDSE,
  getTotalHead,
  toMobDisplayName,
} from '@mobble/models/src/model/Mob';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { type ConfiguredPropertyTypeGroupCustom } from '@mobble/models/src/model/Property';
import { base64decode } from '@mobble/shared/src/core/String';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useMobs,
  usePaddock,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useMoveMobs } from '@src/hooks/useMoveMobs'; // TODO: move to store hooks

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import {
  PaddockMergeMobsForm,
  type PaddockMergeMobsFormValues,
} from '@src/stories/Views/Paddock/PaddockMergeMobsForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';
import { useFormError } from '@src/hooks/useFormError';

export const PaddockMergeMobsHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { paddockId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const paddock = usePaddock(paddockId);

  return (
    <ScreenHeader
      title={formatMessage(
        {
          defaultMessage: 'Merge mobs on {PADDOCK_NAME}',
          description: 'screen.title.paddock_merge_mobs',
        },
        {
          PADDOCK_NAME: paddock?.entity?.name,
        }
      )}
      onGoBack={goBack}
    />
  );
};

export const PaddockMergeMobs: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const mobIds = props.route.params?.mobIds
    ? JSON.parse(base64decode(props.route.params.mobIds))
    : [];
  const goBack = useNavigateBack();
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddock = usePaddock(paddockId);
  const { mergeMobs } = useMoveMobs();
  const paddocks = usePaddocks(paddock.entity?.propertyId);
  const mobs = useMobs(paddock.entity?.propertyId);
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const formErrorMessage = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Mob',
      description: 'screen.title.mob_detail',
    }),
  });

  const [principalMob, setPrincipalMob] = useState<Mob | null>(
    findMob(mobs.entities)(mobIds[0]) ?? null
  );
  const [selectedMobs, setSelectedMobs] = useState<string[]>(
    mobIds.filter((a) => paddock.entity?.mobs.includes(a))
  );

  useEntitiesRefresher([paddocks, mobs], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      paddock.prelude,
      paddocks.prelude,
      mobs.prelude,
    ],
    required: [properties.selected],
    notFound: [paddock.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const mobOptions = (paddock.entity as Paddock).mobs
    .map((mobId) => {
      const mob = findMob(mobs.entities)(mobId);
      if (!mob) {
        return null;
      }
      return {
        label: toMobDisplayName(mob),
        labelExtra: `${mob.size}`,
        value: mobId,
        disabled: selectedMobs.length > 0 && !canMerge(mob)(principalMob),
      };
    })
    .filter(Boolean);

  const onCreateCustomField = (
    group: ConfiguredPropertyTypeGroupCustom,
    parentId: string,
    label: string
  ) => {
    if (!properties.selected) {
      return Promise.reject();
    }
    return properties.addPropertyType({
      group,
      parentId,
      label,
    });
  };

  const handleSelectMobs = (selectedMobIds: string[]) => {
    if (selectedMobIds.length === 0) {
      setPrincipalMob(null);
    } else {
      const mob = findMob(mobs.entities)(selectedMobIds[0]) as Mob;
      setPrincipalMob(mob);
    }
    setSelectedMobs(selectedMobIds);
  };

  const handleCancel = () => {
    goBack();
  };

  const handleSubmit = (formValues: PaddockMergeMobsFormValues) => {
    if (
      formLoading ||
      paddocks.entities.length === 0 ||
      !paddock.entity ||
      !mobs
    ) {
      return;
    }

    setFormError('');
    setFormLoading(true);

    mergeMobs({
      meta: {
        paddockNames: paddock.entity.name,
        mobsNames: findMobs(mobs.entities)(formValues.mobs).map((m) =>
          toMobDisplayName(m, true)
        ),
      },
      propertyId: paddock.entity.propertyId,
      paddockId: paddock.entity.id,
      mobIds: formValues.mobs,
      classes: formValues.classes,
      ages: formValues.ages,
      DSE: formValues.dse,
      date: formValues.date,
      paddockName: paddock?.entity?.name,
      breed: principalMob?.breed,
      gender: principalMob?.gender,
    })
      .then(() => {
        // TODO: replace with Toast/Alert
        console.log(`Mobs successfully merged`);
        goBack();
      })
      .catch((err) => {
        console.log(err);
        setFormError(formErrorMessage);
        setFormLoading(false);
      });
  };

  return (
    <PaddockMergeMobsForm
      principalMob={principalMob}
      mobOptions={mobOptions}
      mobs={mobs.entities}
      initialValues={{
        mobs: selectedMobs,
        classes: getClasses(findMobs(mobs.entities)(selectedMobs)),
        ages: getAges(findMobs(mobs.entities)(selectedMobs)),
        dse: getMaxDSE(findMobs(mobs.entities)(selectedMobs)),
      }}
      combinedSize={getTotalHead(findMobs(mobs.entities)(selectedMobs))}
      paddock={paddock.entity}
      onSelectMobs={handleSelectMobs}
      onCreateCustomField={onCreateCustomField}
      propertyTypes={properties.selected?.types ?? []}
      error={formError}
      loading={formLoading}
      onCancel={handleCancel}
      onTouched={() => {}}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_MOB_MERGE,
  header: PaddockMergeMobsHeader,
  component: PaddockMergeMobs,
};
