import React, { Fragment } from 'react';
import { defineMessages } from 'react-intl';
import {
  makeQuantityFormatMessageItem,
  useI18n,
  useMessages,
} from '@mobble/i18n';
import { compareDates, formatDate } from '@mobble/shared/src/core/Date';
import { type InfoRowProps, InfoRow } from '../../Components/UI/InfoRow';
import { useSetting } from '@mobble/store/src/hooks/settings';
import { RainGauge, totalForYear } from '@mobble/models/src/model/RainGauge';
import {
  RainGaugeReading,
  toLastReading,
} from '@mobble/models/src/model/RainGaugeReading';
import { Quantity } from '@mobble/shared/src';

export interface RainGaugeInfoTableProps {
  rainGaugeReadings: RainGaugeReading[];
  rainGauge: RainGauge;
  year: number;
}

const messages = defineMessages({
  'custom-year': {
    description: 'rain-gauge.info.table.row.custom-year.label',
    defaultMessage: 'Total ({RAIN_GAUGE_YEAR})',
  },
  'last-reading-date': {
    description: 'rain-gauge.info.table.row.last-reading-date.label',
    defaultMessage: 'Last reading date',
  },
  'last-reading': {
    description: 'rain-gauge.info.table.row.last-reading.label',
    defaultMessage: 'Last reading',
  },
  mtd: {
    description: 'rain-gauge.info.table.row.mtd.label',
    defaultMessage: 'Month to date',
  },
  name: {
    description: 'rain-gauge.info.table.row.name.label',
    defaultMessage: 'Name',
  },
  ytd: {
    description: 'rain-gauge.info.table.row.ytd.label',
    defaultMessage: 'Year to date',
  },
});

export const RainGaugeInfoTable: React.FC<RainGaugeInfoTableProps> = ({
  rainGaugeReadings,
  rainGauge,
  year,
}) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages, { RAIN_GAUGE_YEAR: year });
  const dateFormat = useSetting('dateFormat') as string;
  const lengthUnit = useSetting('lengthUnit') as Quantity.LengthUnits;
  const currentYear = new Date().getFullYear();
  const isCurrentYear = currentYear === year;
  const lastReadingOnEntity = rainGauge.lastReading;
  const lastReadingOnRainGaugeReadings = toLastReading(rainGaugeReadings);

  const lastReading = (() => {
    if (
      compareDates(
        lastReadingOnEntity?.date,
        lastReadingOnRainGaugeReadings?.date
      )
    ) {
      return lastReadingOnRainGaugeReadings;
    }
    return lastReadingOnEntity;
  })();

  const rows: (InfoRowProps | InfoRowProps[])[] = [
    {
      label: strings.name,
      value: rainGauge.name,
    },

    isCurrentYear
      ? [
          {
            label: strings.ytd,
            value: formatMessage(
              ...makeQuantityFormatMessageItem(rainGauge.ytd, lengthUnit)
            ),
          },
          {
            label: strings.mtd,
            value: formatMessage(
              ...makeQuantityFormatMessageItem(rainGauge.mtd, lengthUnit)
            ),
          },
        ]
      : {
          label: strings['custom-year'],
          value: formatMessage(
            ...makeQuantityFormatMessageItem(
              totalForYear(rainGauge)(year),
              lengthUnit
            )
          ),
        },

    ...(lastReading
      ? [
          [
            {
              label: strings['last-reading-date'],
              value: formatDate(lastReading?.date, dateFormat),
            },
            {
              label: strings['last-reading'],
              value: formatMessage(
                ...makeQuantityFormatMessageItem(lastReading?.value, lengthUnit)
              ),
            },
          ],
        ]
      : []),
  ];

  return (
    <>
      {rows.map((row, i) =>
        Array.isArray(row) ? (
          <Fragment key={i}>
            {row.map((r) => (
              <InfoRow {...r} key={r.label} />
            ))}
          </Fragment>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </>
  );
};
