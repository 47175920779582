import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import { FilterItem } from '@mobble/models/src/model/Filter';
import {
  filterTasks,
  sortTasks,
  type Task,
  type TaskList,
} from '@mobble/models/src/model/Task';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { useTasks } from '@mobble/store/src/hooks/tasks';

import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { Box } from '@src/stories/Components/Layout/Box';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { TabBar } from '@src/stories/Views/Misc/TabBar';
import { makeTasksTableColumns } from '@src/stories/Views/Task/List/tasksTableColumns';
import { TaskCard } from '@src/stories/Views/Task/TaskCard';

export const TasksHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const { hasAccessToRoute } = useAccessHelper();
  const displayActivity = hasAccessToRoute(ROUTE_NAME.SUMMARY_EVENTS_LIST);

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Tasks',
        description: 'screen.title.tasks',
      })}
      actions={
        displayActivity
          ? [
              {
                label: formatMessage({
                  defaultMessage: 'Event log',
                  description: 'screen.title.summary_events',
                }),
                icon: 'activity',
                href: toPath(ROUTE_NAME.SUMMARY_EVENTS_LIST),
              },
            ]
          : []
      }
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Tasks: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const { hash } = useLocation();

  const user = useGetUser();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const tasks = useTasks(propertyId);
  const paddocks = usePaddocks(propertyId);

  const { refresh } = useEntitiesRefresher([tasks, paddocks], propertyId);

  const [activeTab, setActiveTab] = useState<TaskList>({
    type: (hash.slice(1) as any) || 'pending',
  });

  const tabs = [
    {
      label: formatMessage({
        defaultMessage: 'Incomplete',
        description: 'screen.title.tasks_incomplete',
      }),
      value: { type: 'pending' },
    },
    {
      label: formatMessage({
        defaultMessage: 'My tasks',
        description: 'screen.title.tasks_my_tasks',
      }),
      value: { type: 'my_tasks', userId: user.id },
    },
    {
      label: formatMessage({
        defaultMessage: 'Completed',
        description: 'screen.title.tasks_completed',
      }),
      value: { type: 'completed' },
    },
  ].map((t) => ({
    ...t,
    isActive: t.value.type === activeTab.type,
    onClick: (tab) => {
      setActiveTab(tab);
      window.location.hash = `#${tab.type}`;
    },
  }));

  const applyFilter = (p, filter: FilterItem[]) => {
    return filterTasks(paddocks.entities)(activeTab)(tasks.entities, filter);
  };

  const handleClick = (task: Task) => {
    linkTo(ROUTE_NAME.TASK_DETAIL, { taskId: task.id });
  };

  const tableColumns = makeTasksTableColumns({ paddocks: paddocks.entities });

  const viewerProps: EntitiesViewerProps<Task> = {
    provider: tasks,
    hideSortAndFilter: true,
    applyFilter,
    applySort: sortTasks,
    onRefresh: refresh,
    renderEntityCard: (task) => {
      return (
        <Box spacing={1}>
          <TaskCard onClick={handleClick} task={task} />
        </Box>
      );
    },
    tableColumns,
    onEmpty: formatMessage({
      defaultMessage: 'Add a task below',
      description: 'tasks.list.empty.add',
    }),
    onClickTableRow: handleClick,
  };

  return (
    <>
      <TabBar backgroundColor={Color.WashedRed} tabs={tabs} />
      <EntitiesViewer {...viewerProps} />
    </>
  );
};

export default {
  name: ROUTE_NAME.TASKS_LIST,
  header: TasksHeader,
  component: Tasks,
};
