import { useEffect, useState } from 'react';
import { IconName } from '@mobble/icon';
import { I18nItem } from '@mobble/i18n';
import { UserRole, advisorRoles } from '@mobble/models/src/model/User';
import {
  useGetUser,
  useMobs,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';
import { useGetMobbleApps } from '@src/screens/Settings/hooks/useGetMobbleApps';
import * as ROUTE_NAME from '@src/screens/config/routeNames';

import AppleIcon from '@assets/dashboard-icons/apple.svg';
import GooglePlayIcon from '@assets/dashboard-icons/google-play.svg';
import AgWorldIcon from '@assets/dashboard-icons/agworld.svg';

export enum Suggestions {
  apps = 'apps',
  paddocks = 'paddocks',
  mobs = 'mobs',
  users = 'users',
  partners = 'partners',
  integrations = 'integrations',
}

interface ButtonType {
  label: I18nItem;
  href: string;
  icon?: IconName;
  image?: any;
}

export interface DashboardSuggestion {
  id: Suggestions;
  buttons: ButtonType[];
}

const DASHBOARD_SUGGESTIONS = 'dashboard-suggestions';
const UPLOAD_MAP_FILE_URL =
  'https://www.mobble.io/help/how-to-upload-maps-to-mobble';
const CREATE_PADDOCK_LINK = ROUTE_NAME.MAP_OVERVIEW + '#add-paddock';

const dashboardSuggestions: DashboardSuggestion[] = [
  {
    id: Suggestions.apps,
    buttons: [
      {
        label: {
          key: 'dashboard.tile.suggestions.apps.card.button.download-ios',
        },
        href: 'https://apps.apple.com/au/app/mobble-farm-management/id1505306095',
        image: AppleIcon,
      },
      {
        label: {
          key: 'dashboard.tile.suggestions.apps.card.button.download-android',
        },
        href: 'https://play.google.com/store/apps/details?id=io.mobble.app.production&pcampaignid=web_share',
        image: GooglePlayIcon,
      },
    ],
  },
  {
    id: Suggestions.paddocks,
    buttons: [
      {
        label: {
          key: 'dashboard.tile.suggestions.paddocks.card.button.send-map-file',
        },
        href: UPLOAD_MAP_FILE_URL,
        icon: 'share',
      },
      {
        label: {
          key: 'dashboard.tile.suggestions.paddocks.card.button.import-agworld-paddocks',
        },
        href: ROUTE_NAME.SETTINGS_MOBBLE_APPS,
        image: AgWorldIcon,
      },
      {
        label: {
          key: 'dashboard.tile.suggestions.paddocks.card.button.draw-paddocks',
        },
        href: CREATE_PADDOCK_LINK,
        icon: 'edit',
      },
    ],
  },
  {
    id: Suggestions.mobs,
    buttons: [
      {
        label: { key: 'dashboard.tile.suggestions.mobs.card.button.add-mob' },
        href: ROUTE_NAME.MODAL_MOB_CREATE,
        icon: 'circle',
      },
    ],
  },
  {
    id: Suggestions.users,
    buttons: [
      {
        label: {
          key: 'dashboard.tile.suggestions.users.card.button.add-users',
        },
        href: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_USERS_INVITE_USER,
        icon: 'plus-circle',
      },
    ],
  },
  {
    id: Suggestions.partners,
    buttons: [
      {
        label: {
          key: 'dashboard.tile.suggestions.partners.card.button.invite-partners',
        },
        href: ROUTE_NAME.SETTINGS_LIST,
        icon: 'eye',
      },
    ],
  },
  {
    id: Suggestions.integrations,
    buttons: [
      {
        label: {
          key: 'dashboard.tile.suggestions.integrations.card.button.connect-mobble',
        },
        href: ROUTE_NAME.SETTINGS_MOBBLE_APPS,
        icon: 'grid',
      },
    ],
  },
];

const useLocalStorageSuggestions = () => {
  const [suggestions, setSuggestions] = useState<
    | {
        [id in Suggestions]: 'active' | 'dismissed';
      }
    | null
  >(null);

  useEffect(() => {
    const suggestions = localStorage.getItem(DASHBOARD_SUGGESTIONS);
    if (suggestions) {
      setSuggestions(JSON.parse(suggestions));
      return;
    }
    setSuggestions({
      [Suggestions.apps]: 'active',
      [Suggestions.paddocks]: 'active',
      [Suggestions.mobs]: 'active',
      [Suggestions.users]: 'active',
      [Suggestions.partners]: 'active',
      [Suggestions.integrations]: 'active',
    });
  }, []);

  const removeSuggestion = (suggestionId: Suggestions) => {
    const newSuggestions = { ...suggestions, [suggestionId]: 'dismissed' };
    setSuggestions(newSuggestions);
    localStorage.setItem(DASHBOARD_SUGGESTIONS, JSON.stringify(newSuggestions));
  };

  return { suggestions, removeSuggestion };
};

const rolesCanAddItems = [UserRole.Owner, UserRole.Admin, UserRole.User];
const roleCanInvite = [UserRole.Owner, UserRole.Admin];
const roleCanAccessIntegrations = [UserRole.Owner];

const useCheckIfRecommendationIsRelevant = () => {
  const properties = useProperties();
  const user = useGetUser();
  const paddocks = usePaddocks(properties.selected.id);
  const mobs = useMobs(properties.selected.id);
  const { apps } = useGetMobbleApps();
  const hasEnabledApps = apps?.some((app) => app.enabled);
  const hasMobbleConnectUser = properties.selected.users.some((u) =>
    advisorRoles.includes(u.role)
  );
  const hasUsers = properties.selected.users.length > 1;
  const localStorageSuggestions = useLocalStorageSuggestions();

  const isRelevant = (suggestion: DashboardSuggestion) => {
    switch (suggestion.id) {
      case Suggestions.apps:
        return localStorageSuggestions.suggestions?.apps !== 'dismissed';
      case Suggestions.paddocks:
        if (
          paddocks?.entities.length > 1 ||
          !rolesCanAddItems.includes(user.role)
        ) {
          return false;
        }
        return localStorageSuggestions.suggestions?.paddocks !== 'dismissed';
      case Suggestions.mobs:
        if (mobs?.entities.length || !rolesCanAddItems.includes(user.role)) {
          return false;
        }
        return localStorageSuggestions.suggestions?.mobs !== 'dismissed';
      case Suggestions.users:
        if (hasUsers || !roleCanInvite.includes(user.role)) {
          return false;
        }
        return localStorageSuggestions.suggestions?.users !== 'dismissed';
      case Suggestions.partners:
        if (hasMobbleConnectUser || !roleCanInvite.includes(user.role)) {
          return false;
        }
        return localStorageSuggestions.suggestions?.partners !== 'dismissed';
      case Suggestions.integrations:
        if (hasEnabledApps || !roleCanAccessIntegrations.includes(user.role)) {
          return false;
        }
        return (
          localStorageSuggestions.suggestions?.integrations !== 'dismissed'
        );
      default:
        return false;
    }
  };

  return {
    isRelevant,
    removeSuggestion: localStorageSuggestions.removeSuggestion,
  };
};
export interface DashboardSuggestionDetails {
  suggestions: DashboardSuggestion[];
  suggestionCount: number;
  totalSuggestionCount: number;
  removeSuggestion: (suggestionId: Suggestions) => void;
}

export const useDashboardSuggestions = (): DashboardSuggestionDetails => {
  const { isRelevant, removeSuggestion } = useCheckIfRecommendationIsRelevant();
  const suggestions = dashboardSuggestions.filter(isRelevant);

  return {
    suggestions,
    suggestionCount: suggestions.length,
    totalSuggestionCount: dashboardSuggestions.length,
    removeSuggestion,
  };
};
