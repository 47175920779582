import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { type InventoryItem } from '@mobble/models/src/model/InventoryItem';
import mobbleService from '@mobble/service';
import {
  type ExtStatusPerActionPerParent,
  type ExtStatus,
  updateStatusForXAndAction,
} from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { FilterPerProperty } from '../lib/filter';

export interface InventoryItemsState {
  extStatus: ExtStatusPerActionPerParent;
  entities: InventoryItem[];
  filter: FilterPerProperty;
  sort: {};
}

export const defaultState: InventoryItemsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<InventoryItemsState>({
  name: 'inventoryItems',
  initialState: defaultState,
  toParentId: ({ inventoryId }: InventoryItem) => inventoryId,
  onGetAll: mobbleService.api.inventoryItems.get,
  onFind: mobbleService.api.inventoryItems.find,
  onCreate: mobbleService.api.inventoryItems.create,
  onUpdate: mobbleService.api.inventoryItems.update,
  onDelete: mobbleService.api.inventoryItems.remove,
  reducers: {
    updateExtStatus: (
      state,
      action: PayloadAction<{ parentId: string; extStatus: ExtStatus }>
    ) => ({
      ...state,
      extStatus: updateStatusForXAndAction(state.extStatus)(
        action.payload.parentId
      )(action.payload.extStatus),
    }),
  },
});

export const {
  thunkGetAll,
  thunkFind,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;
export const { updateExtStatus } = entitySlice.slice.actions as any;

export default entitySlice.slice.reducer;
