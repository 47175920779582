import { toISO8601 } from '@mobble/shared/src/core/Date';
import { type RainGaugeReading } from '@mobble/models/src/model/RainGaugeReading';
import {
  convertLength,
  makeQuantityOfLength,
} from '@mobble/shared/src/core/Quantity';
import { type ApiPrelude, type ExtCursor } from '../types';

const decodeRainGaugeReadings =
  (propertyId: string, rainGaugeId: string) =>
  (raw: any[]): RainGaugeReading[] => {
    return raw.map(decodeRainGaugeReading(propertyId, rainGaugeId));
  };

const decodeRainGaugeReading =
  (propertyId: string, rainGaugeId: string) =>
  (raw: any): RainGaugeReading => {
    return {
      id: raw?.id ?? '',
      propertyId,
      rainGaugeId,
      createdByID: raw?.createdByID ?? null,
      date: raw?.date,
      value: makeQuantityOfLength('mm', raw?.value?.value ?? 0),
    };
  };

const GET_RAIN_GAUGE_READINGS = `
  query GetRainGaugeReadings($propertyId: ID!, $gaugeId: ID!, $after: String) {
    rainGaugeReadingsByGauge(
      propertyId: $propertyId
      gaugeId: $gaugeId
      after: $after
    ) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        date
        value {
          unit
          value
        }
        createdByID
      }
    }
  }`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    cursor,
  }: {
    parentId: string; // propertyId_gaugeId
    cursor?: string;
  }): Promise<{ entities: RainGaugeReading[]; extCursor: ExtCursor }> => {
    const [propertyId, gaugeId] = parentId.split('_');
    const variables = {
      propertyId,
      gaugeId,
      after: cursor,
    };

    const response = await prelude.graphql({
      query: GET_RAIN_GAUGE_READINGS,
      variables,
    });
    const entities = decodeRainGaugeReadings(
      propertyId,
      gaugeId
    )(response.data.rainGaugeReadingsByGauge.nodes);

    const pageInfo = response?.data?.rainGaugeReadingsByGauge?.pageInfo;

    const extCursor = {
      total:
        response?.data?.rainGaugeReadingsByGauge?.totalCount ?? entities.length,
      cursorNext: pageInfo?.hasNextPage && pageInfo?.endCursor,
    };

    return {
      entities,
      extCursor,
    };
  };

const CREATE_RAIN_GAUGE_READING = `
  mutation AddRainGaugeReading($input: AddRainGaugeReadingInput!) {
    addRainGaugeReading(input: $input) {
      rainGaugeReading {
        id
        date
        value {
          unit
          value
        }
        createdByID
      }
    }
  }
`;

interface AddRainGaugeReadingInput {
  propertyId: string;
  gaugeId: string;
  date: string;
  value: {
    value: number;
    unit: string;
  };
}

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<RainGaugeReading, 'id'> }) => {
    const input: AddRainGaugeReadingInput = {
      propertyId: entity.propertyId,
      gaugeId: entity.rainGaugeId,
      date: toISO8601(entity.date),
      value: {
        value: convertLength('mm')(entity.value).value,
        unit: 'mm',
      },
    };

    const response = await prelude.graphql({
      query: CREATE_RAIN_GAUGE_READING,
      variables: { input },
    });

    const rainGauge = decodeRainGaugeReading(
      entity.propertyId,
      entity.rainGaugeId
    )(response.data.addRainGaugeReading.rainGaugeReading);

    return rainGauge;
  };

const EDIT_RAIN_GAUGE_READING = `
  mutation EditRainGaugeReading($input: EditRainGaugeReadingInput!) {
    editRainGaugeReading(input: $input) {
      rainGaugeReading {
        id
        date
        value {
          unit
          value
        }
        createdByID
      }
    }
  }
`;

interface EditRainGaugeReadingInput {
  id: string;
  propertyId: string;
  gaugeId: string;
  value: {
    value: number;
    unit: string;
  };
  date: any;
}

export const update =
  (prelude: ApiPrelude) =>
  async ({ updatedEntity }: { updatedEntity: RainGaugeReading }) => {
    const input: EditRainGaugeReadingInput = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      gaugeId: updatedEntity.rainGaugeId,
      date: toISO8601(updatedEntity.date),
      value: {
        value: convertLength('mm')(updatedEntity.value).value,
        unit: 'mm',
      },
    };

    const response = await prelude.graphql({
      query: EDIT_RAIN_GAUGE_READING,
      variables: { input },
    });

    const rainGauge = decodeRainGaugeReading(
      updatedEntity.propertyId,
      updatedEntity.rainGaugeId
    )(response.data.editRainGaugeReading.rainGaugeReading);

    return rainGauge;
  };

const DELETE_RAIN_GAUGE_READING = `
  mutation DeleteRainGaugeReading($input: DeleteRainGaugeReadingInput!) {
    deleteRainGaugeReading(input: $input) {
      rainGaugeReading {
        id
      }
    }
  }
`;

interface DeleteRainGaugeReadingInput {
  propertyId: string;
  id: string;
}

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: RainGaugeReading }): Promise<void> => {
    const input: DeleteRainGaugeReadingInput = {
      propertyId: entity.propertyId,
      id: entity.id,
    };

    await prelude.graphql({
      query: DELETE_RAIN_GAUGE_READING,
      variables: { input },
    });
    return;
  };
