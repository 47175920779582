import { useEffect } from 'react';
import { useI18n } from '@mobble/i18n';
import {
  useExtStatus,
  useProperties,
  useRainGauge,
} from '@mobble/store/src/hooks';
import { useEntitiesRefresher } from './useEntitiesRefresher';

export type RainGaugeEditView = {
  rainGaugeId: string;
  EntitySliceFactoryPrelude: any;
  onDeleteSuccess?: () => void;
  onSubmitSuccess: () => void;
};

export interface RainGaugeEditFormValues {
  name: string;
}

export const useRainGaugeEditView = (props: RainGaugeEditView) => {
  const {
    rainGaugeId,
    EntitySliceFactoryPrelude,
    onDeleteSuccess,
    onSubmitSuccess,
  } = props;
  const { translate } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const rainGauge = useRainGauge(rainGaugeId);
  const extStatus = useExtStatus('rainGauges', 'update', rainGaugeId);
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus('rainGauges', 'delete', rainGaugeId);
  const deleteLoading = extStatusDelete?.loading;
  const formError = extStatus?.error
    ? translate({
        key: 'generic.form.error',
        params: {
          '%ENTITY': translate({
            key: 'rain-gauges.rain-gauge.create.form.title.label',
          }),
        },
      })
    : '';

  useEntitiesRefresher([rainGauge], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, rainGauge.prelude],
    required: [properties.selected],
    notFound: [rainGauge.entity],
  });

  const handleDelete = () => {
    rainGauge
      .remove()
      .then(() => {
        typeof onDeleteSuccess === 'function' && onDeleteSuccess();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (formValues: RainGaugeEditFormValues) => {
    rainGauge
      .update({
        ...rainGauge.entity,
        name: formValues.name,
      })
      .then(() => {
        onSubmitSuccess();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    Prelude,
    rainGauge,
    formLoading,
    deleteLoading,
    formError,
    handleDelete,
    handleSubmit,
  };
};
