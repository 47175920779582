import React from 'react';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import {
  getMobsOnPaddockAndSamePaddockGroup,
  getPaddockGroupsForPaddock,
} from '@mobble/models/src/model/PaddockGroup';
import {
  ScenarioType,
  getUserActionsForScenario,
} from '@mobble/models/src/model/Intentions/Scenarios';
import { metaForUserActionType } from '@mobble/models/src/model/Intentions/UserActions';
import { type IconName } from '@mobble/icon';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { usePaddockGroups } from '@mobble/store/src/hooks/paddockGroups';
import { usePaddockGeometry } from '@mobble/store/src/hooks/paddockGeometries';
import {
  Actions,
  type ActionsOption,
} from '@src/stories/Components/UX/Actions';
import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { userActionToRoute } from '@src/screens/config/userActionToRoute';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { toPath } from '@src/interfaces/Routing';
import { base64encode } from '@mobble/shared/src/core/String';

export interface ActionSheetOnPaddockSelectProps {
  paddock: Paddock;
  onClose: () => void;
}

export const ActionSheetOnPaddockSelect: React.FC<
  ActionSheetOnPaddockSelectProps
> = ({ paddock, onClose }) => {
  const { hasAccessToRoute } = useAccessHelper();
  const paddocks = usePaddocks(paddock.propertyId);
  const paddockGeometry = usePaddockGeometry(paddock.id);
  const paddockGroups = usePaddockGroups(paddock.propertyId);
  const paddockGrouped = paddock
    ? getPaddockGroupsForPaddock(paddockGroups.entities)(paddock.id)[0]
    : undefined;

  const mobIds = getMobsOnPaddockAndSamePaddockGroup(
    paddockGroups.entities,
    paddocks.entities
  )(paddock.id);

  const mobIdParams = base64encode(JSON.stringify(mobIds));

  const paddockParams: Record<string, string | number> = {
    paddockId: paddock?.id,
    // mode: 'overview',
    goto: `paddock--${paddock.id}`,
    // id: paddock.id,
    // point: [],
    mobIds: mobIdParams,
    paddockGroupId: paddockGrouped?.id,
  };

  const userActions = getUserActionsForScenario(ScenarioType.PaddockSelect, {
    hasMobs: Boolean(mobIds.length),
    hasGeometry: Boolean(paddockGeometry.entity),
    hasOpenGate: Boolean(paddockGrouped?.id),
  });
  const options: ActionsOption[] = userActions.map((ua) => {
    const meta = metaForUserActionType(ua);
    const { route, params } = userActionToRoute(ua, paddockParams);

    return {
      icon: meta.icon as IconName,
      label: { key: meta.titleKey },
      description: { key: meta.descriptionKey },
      disabled: !hasAccessToRoute(route),
      href: toPath(route, params),
    };
  });

  return (
    <ModalFlyUp title={paddock?.name} isOpen={true} onClose={onClose}>
      <Actions options={options} onClose={onClose} />
    </ModalFlyUp>
  );
};
