import React from 'react';
import { type IntlFormatters } from 'react-intl';

import { getLivestockAgeColor } from '@mobble/models/src/model/Mob';
import { type Paddock, paddockForMob } from '@mobble/models/src/model/Paddock';
import { type ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { formatDate, fromRawDate, RawDate } from '@mobble/shared/src/core/Date';
import { toInteger } from '@mobble/shared/src/core/Number';

import { BadgeList, Text } from '@src/components';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';
import { Clickable } from '@src/stories/Components/UX/Clickable';

import styles from './mobsTableColumns.scss';

export const makeMobsTableColumns = ({
  propertyTypes,
  paddocks,
  stockingUnit,
  formatMessage,
}: {
  propertyTypes: ConfiguredPropertyType[];
  paddocks: Paddock[];
  stockingUnit: StockingUnit;
  formatMessage: IntlFormatters['formatMessage'];
}): TableColumn[] => {
  const findPaddockForMob = paddockForMob(paddocks);

  const convertStockingRate = (stockingRate: number) =>
    formatStockingRate(stockingRate, stockingUnit);
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));

  return [
    {
      key: 'type',
      label: formatMessage({
        defaultMessage: 'Type',
        description: 'mobs.table.heading.column.type',
      }),
      totals: false,
      enabled: false,
      toValue: (mob) => mob.type,
    },
    {
      key: 'head',
      label: formatMessage({
        defaultMessage: 'Head',
        description: 'mobs.table.heading.column.head',
      }),
      totals: true,
      toValue: (mob) => mob.size,
      render: (_, mob) => {
        return (
          <>
            <Text className={styles.size}>
              {mob.size}

              {mob.safeDate ? (
                <div className={styles.unSafeDot}>
                  <div className={styles.tooltip}>
                    <Text align="center">
                      {formatMessage({
                        defaultMessage: 'Safe Date',
                        description: 'mobs.table.heading.column.safe_date',
                      })}
                      : {formatDate(mob.safeDate)}
                    </Text>
                  </div>
                </div>
              ) : null}
            </Text>
          </>
        );
      },
    },
    {
      key: 'breed',
      label: formatMessage({
        defaultMessage: 'Breed',
        description: 'mobs.table.heading.column.breed',
      }),
      totals: false,
      toValue: (mob) => mob.breed,
    },
    {
      key: 'gender',
      label: formatMessage({
        defaultMessage: 'Gender',
        description: 'mobs.table.heading.column.gender',
      }),
      totals: false,
      toValue: (mob) => mob.gender,
    },
    {
      key: 'stocking_rate',
      label: formatMessage(
        {
          defaultMessage: '{STOCKING_UNIT}/head',
          description: 'mobs.table.heading.column.stocking_rate',
        },
        {
          STOCKING_UNIT: stockingUnitLabel,
        }
      ),
      totals: false,
      toValue: (mob) => convertStockingRate(mob.DSE),
    },
    {
      key: 'total_stocking_rate',
      label: formatMessage(
        {
          defaultMessage: 'Total {STOCKING_UNIT}',
          description: 'mobs.table.heading.column.total_stocking_rate',
        },
        {
          STOCKING_UNIT: stockingUnitLabel,
        }
      ),
      totals: true,
      toValue: (mob) => toInteger(mob.size * convertStockingRate(mob.DSE)),
    },
    {
      key: 'ages',
      label: formatMessage({
        defaultMessage: 'Ages',
        description: 'mobs.table.heading.column.ages',
      }),
      totals: false,
      toValue: (mob) => mob.ages.join(', '),
      render: (_, mob) => {
        return (
          <BadgeList
            badges={mob.ages.map((age) => ({
              label: `${age}`,
              color: getLivestockAgeColor(propertyTypes, mob.type)(age),
            }))}
            badgesToShow={3}
          />
        );
      },
    },
    {
      key: 'classes',
      label: formatMessage({
        defaultMessage: 'Classes',
        description: 'mobs.table.heading.column.classes',
      }),
      totals: false,
      toValue: (mob) => mob.classes.join(', '),
      render: (_, mob) => {
        return (
          <BadgeList
            defaultColor="var(--neutrals-bg-default)"
            badges={mob.classes.map((label) => ({
              label,
            }))}
            badgesToShow={3}
          />
        );
      },
    },
    {
      key: 'paddock',
      label: formatMessage({
        defaultMessage: 'Paddock',
        description: 'mobs.table.heading.column.paddock',
      }),
      totals: false,
      toValue: (mob) => findPaddockForMob(mob)?.name,
      render: (_, mob) => {
        const paddock = findPaddockForMob(mob);

        if (paddock) {
          return (
            <Clickable
              href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
                paddockId: paddock.id,
              })}
            >
              <Text underline>{paddock.name}</Text>
            </Clickable>
          );
        }

        return null;
      },
    },
    {
      key: 'safeDate',
      label: formatMessage({
        defaultMessage: 'Safe date',
        description: 'mobs.table.heading.column.safeDate',
      }),
      enabled: false,
      toValue: (mob) =>
        mob.safeDate ? fromRawDate(mob.safeDate).toDate().getTime() : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
  ];
};
