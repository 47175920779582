import { useEffect, useState } from 'react';
import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities } from '../reducers/chats';
import { PreludeStatus } from '../lib/EntitySliceFactoryPrelude';

export const useChats = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.chats);
  const [loaded, setLoaded] = useState(false);

  const chats = proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });

  useEffect(() => {
    if (chats.lastChanged > 0 && !loaded) {
      setLoaded(true);
    }
  }, [chats.lastChanged]);

  return {
    ...chats,
    prelude: {
      ...chats.prelude,
      status: loaded ? PreludeStatus.Ready : chats.prelude.status,
    },
  };
};
