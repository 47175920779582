import React from 'react';
import mobbleService from '@mobble/service';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { MobbleApp } from '@mobble/models/src/model/MobbleApps';

export const useGetMobbleApps = () => {
  const properties = useProperties();
  const [apps, setApps] = React.useState<MobbleApp[]>([]);
  React.useEffect(() => {
    if (properties.selected) {
      refetch();
    }
  }, []);

  const refetch = () => {
    mobbleService.api.mobbleApps
      .get({ organisationId: properties.selected?.organisationId })
      .then((res) => {
        setApps(res.entities);
      });
  };

  return { apps, refetch };
};
