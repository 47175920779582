import { type TaskGeometry } from '@mobble/models/src/model/TaskGeometry';
import { type ApiPrelude } from '../types';

const GET_MAP_TASKS = `
  query GetMapTasks($propertyId: ID!) {
    mapTasks(propertyId: $propertyId) {
      nodes {
        taskId
        geometry
      }
    }
  }
`;

const decodeTaskGeometry =
  (propertyId: string) =>
  (raw: any): TaskGeometry => {
    return {
      id: raw?.taskId,
      propertyId,
      taskId: raw?.taskId,
      point: raw?.geometry,
    };
  };

const decodeTaskGeometries =
  (propertyId: string) =>
  (raw: any[]): TaskGeometry[] =>
    raw.map(decodeTaskGeometry(propertyId));

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{ entities: TaskGeometry[] }> => {
    const response = await prelude.graphql({
      query: GET_MAP_TASKS,
      variables: {
        propertyId: parentId,
      },
    });
    const entities = decodeTaskGeometries(parentId)(
      response?.data?.mapTasks?.nodes ?? []
    );

    return { entities };
  };
