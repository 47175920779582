import React from 'react';
import { type Property } from '@mobble/models/src/model/Property';
import {
  FormBuilder,
  type FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';

export interface SettingsPropertyInformationFormProps {
  property: Property;
  loading?: boolean;
  onSubmit: (formValues: SettingsPropertyInformationFormValues) => void;
  onCancel: () => void;
}

export interface SettingsPropertyInformationFormValues {
  name: string;
  pic?: string;
}

export const SettingsPropertyInformationForm: React.FC<
  SettingsPropertyInformationFormProps
> = ({ property, loading, onSubmit, onCancel }) => {
  const form: FormBuilderProps<SettingsPropertyInformationFormValues> = {
    flex: true,
    i18nRootKey: 'settings.property_information.form',
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
        initialValue: property.name,
      },
      {
        name: 'pic',
        type: 'text',
        initialValue: property.pic,
      },
    ],
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
