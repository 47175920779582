import React, { useEffect } from 'react';

import { useI18n } from '@mobble/i18n';
import { useChats } from '@mobble/store/src/hooks/chats';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { ChatScreen } from '@src/stories/Views/Chat/ChatScreen';

import { ScreenRendererProps } from '@src/screens/config/types';
import * as ROUTE_NAME from '@src/screens/config/routeNames';

export const ChatHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.chats',
        defaultMessage: 'Property Chat',
      })}
      onToggleDrawer={() => {
        props.drawer.toggle();
      }}
    />
  );
};

export const Chat: React.FC<ScreenRendererProps> = () => {
  const properties = useProperties();
  const user = useGetUser();
  const chats = useChats(properties.selected?.id);

  useEffect(() => {
    const interval = setInterval(() => {
      chats.refresh();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, chats.prelude],
    required: [properties.selected],
  });

  if (prelude) {
    return prelude;
  }

  const handleSubmit = async (body: string) => {
    if (!properties.selected || !user) {
      return;
    }

    await chats.create({
      body,
      propertyId: properties.selected.id,
      user,
      date: new Date(),
    });
  };

  return (
    <ChatScreen
      chats={chats.entities}
      loading={chats.loading}
      user={user}
      hasNextPage={chats.hasNextPage}
      loadMore={chats.loadMore}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.CHATS_LIST,
  header: ChatHeader,
  component: Chat,
};
