import { type Property } from './Property';
import { type PaddockGroup } from './PaddockGroup';
import { type Polygon } from './MapGeometry';
import { type BoundingBox, polygonsToBoundingBox } from './MapGeometry';

export interface PaddockGroupGeometry {
  id: string;
  propertyId: Property['id'];
  paddockGroupId: PaddockGroup['id'];
  polygon: Polygon;
}

export const findPaddockGroupGeometry =
  (paddockGroupGeometries: PaddockGroupGeometry[]) =>
  (paddockGroupId: PaddockGroup['id']): PaddockGroupGeometry | undefined =>
    paddockGroupGeometries.find(
      (p) =>
        p.paddockGroupId === paddockGroupId && p.polygon?.coordinates?.length
    );

export const findPaddockBoundingBox =
  (paddockGroupGeometries: PaddockGroupGeometry[]) =>
  (paddockGroupId: PaddockGroup['id']): undefined | BoundingBox => {
    const paddockGroupGeometry = findPaddockGroupGeometry(
      paddockGroupGeometries
    )(paddockGroupId);
    if (!paddockGroupGeometry) {
      return undefined;
    }

    return polygonsToBoundingBox([paddockGroupGeometry.polygon]);
  };
