import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Preference, isEqual } from '@mobble/models/src/model/Preference';

export interface PreferencesState {
  entities: Preference[];
}

export const defaultState: PreferencesState = {
  entities: [],
};

const slice = createSlice({
  name: 'preferences',
  initialState: defaultState,
  reducers: {
    flush: () => defaultState,
    updatePreference: (
      state: PreferencesState,
      action: PayloadAction<Preference>
    ) => {
      return {
        ...state,
        entities: [
          ...state.entities.filter((a) => !isEqual(action.payload)(a)),
          action.payload,
        ],
      };
    },
    removePreference: (
      state: PreferencesState,
      action: PayloadAction<Preference>
    ) => {
      return {
        ...state,
        entities: [
          ...state.entities.filter((a) => !isEqual(action.payload)(a)),
        ],
      };
    },
  },
});

export const { updatePreference, removePreference, flush } = slice.actions;

export const { reducer } = slice;
