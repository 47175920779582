import React from 'react';
import {
  type Point,
  type BoundingBox,
} from '@mobble/models/src/model/MapGeometry';
import { findPaddock } from '@mobble/models/src/model/Paddock';
import { usePaddockGeometries } from '@mobble/store/src/hooks/paddockGeometries';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { findPaddockGeometryByPoint } from '@mobble/models/src/model/PaddockGeometry';
import { MAP_DETAILS, MapDetail } from '@mobble/models/src/model/MapDetail';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useMobs } from '@mobble/store/src/hooks/mobs';
import { useSettings } from '@mobble/store/src/hooks/settings';
import { MapAssetType } from '@mobble/models/src/model/MapAsset';
import { Box } from '@src/stories/Components/Layout/Box';
import { MapPortal, type MapPortalContent } from './MapOverView/MapPortal';
import { Map } from '@src/stories/Map';
import { mapPluginTypesToArray } from '@src/stories/Map/Plugins';
import { MapEvent } from '@src/stories/Map/Events';
import { mapAssetTypeToMode } from '@src/stories/Map/Plugins/Creator';
import { MapPluginsListenersDispatchRef } from '@src/stories/Map/Context';
import { OnClickMapItemEvent } from '@src/hooks/useMapProperties';
import { type MapOverviewState } from './MapOverView/SharedTypes';
import * as AddTask from './MapOverView/Modes/AddTask';
import * as AddAsset from './MapOverView/Modes/AddAsset';
import * as AddPaddock from './MapOverView/Modes/AddPaddock';
import styles from './mapOverview.scss';

export type MapOverviewMode =
  | 'standard'
  | 'add-task'
  | 'add-asset'
  | 'add-paddock';

export type NewMapItem = NewMapItemTask | NewMapItemAsset | NewMapPaddock;

export interface NewMapItemAsset {
  type: 'asset';
  mapAssetType: MapAssetType;
  points: Point[];
}

export interface NewMapItemTask {
  type: 'task';
  point: Point;
}

export interface NewMapPaddock {
  type: 'paddock';
  points: Point[];
}

export interface MapOverviewProps {
  mode?: MapOverviewMode;
  centerOn?: BoundingBox;
  onResetMode: () => void;
  onClickMapItem: (ev: OnClickMapItemEvent) => void;
  onAddMapItem: (newMapItem: NewMapItem) => void;
}

interface SharedState {
  selectedMapAssetType: MapAssetType;
  mapPortalContent: MapPortalContent;
  overwrittenMapDetails: null | MapDetail[];
}

export const MapOverview: React.FC<MapOverviewProps> = ({
  mode = 'standard',
  centerOn,
  onClickMapItem,
  onResetMode,
  onAddMapItem,
}) => {
  const [state, setState] = React.useState<SharedState>({
    selectedMapAssetType: MapAssetType.Bore,
    mapPortalContent: null,
    overwrittenMapDetails: null,
  });
  const updateState = (newState: Partial<SharedState>) =>
    setState((s) => ({ ...s, ...newState }));

  const { settings } = useSettings();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddockGeometries = usePaddockGeometries(propertyId);
  const paddocks = usePaddocks(propertyId);
  const mobs = useMobs(propertyId);
  const stateRef = React.useRef<MapPluginsListenersDispatchRef>(null);

  const localOnClickMapItem = (ev: OnClickMapItemEvent) => {
    updateState({
      mapPortalContent: {
        ...ev,
        onClick: (altEv) => {
          onClickMapItem(altEv ?? ev);
        },
      },
    });
  };

  const allMapOverviewStates: MapOverviewState[] = [
    // standard
    {
      mode: 'standard',
      mapPlugins: 'standard',
      pointsState: { enabled: false },
    },
    // add-task
    {
      mode: 'add-task',
      mapPlugins: [...mapPluginTypesToArray('standard-restricted'), 'creator'],
      header: AddTask.Header,
      footer: AddTask.Footer,
      pointsState: {
        enabled: true,
        options: {
          single: true,
        },
      },
      onSubmit: () => {
        const { points } = stateRef.current.getState();
        if (points[0]) {
          onAddMapItem({ type: 'task', point: points[0] });
        }
      },
    },
    // add-asset
    {
      mode: 'add-asset',
      mapPlugins: [...mapPluginTypesToArray('standard-restricted'), 'creator'],
      header: AddAsset.Header,
      footer: AddAsset.Footer,
      pointsState: {
        enabled: true,
        points: [],
        options: {
          single: mapAssetTypeToMode(state.selectedMapAssetType) === 'point',
        },
      },
      onSubmit: () => {
        const { points } = stateRef.current.getState();
        if (points) {
          onAddMapItem({
            type: 'asset',
            mapAssetType: state.selectedMapAssetType,
            points,
          });
        }
      },
    },
    // add-paddock
    {
      mode: 'add-paddock',
      mapPlugins: [...mapPluginTypesToArray('standard-restricted'), 'creator'],
      header: AddPaddock.Header,
      footer: AddPaddock.Footer,
      pointsState: {
        enabled: true,
        points: [],
        options: {
          mode: 'area',
          single: false,
          snap: true,
        },
      },
      onSubmit: () => {
        const { points } = stateRef.current.getState();
        if (points) {
          onAddMapItem({
            type: 'paddock',
            points,
          });
        }
      },
    },
  ];

  const overviewState = React.useMemo(() => {
    return (
      allMapOverviewStates.find((a) => a.mode === mode) ??
      allMapOverviewStates[0]
    );
  }, [mode, state.selectedMapAssetType]);

  const onEvent = (event: MapEvent) => {
    if (event.type === 'movement') {
      if (event.final && event.id) {
        const paddockGeometry = findPaddockGeometryByPoint(
          paddockGeometries.entities
        )(event.b);
        const fromPaddock = paddockGeometry
          ? findPaddock(paddocks.entities)(event.id)
          : null;
        const toPaddock = paddockGeometry
          ? findPaddock(paddocks.entities)(paddockGeometry.paddockId)
          : null;

        if (fromPaddock && toPaddock) {
          updateState({
            mapPortalContent: {
              type: 'move-mob',
              fromPaddock,
              toPaddock,
              onClick: () => {},
            },
          });
        }
      }
    }
  };

  const onCancel = () => {
    onResetMode();
  };

  const onSubmit = () => {
    overviewState.onSubmit?.();
  };

  const Header = overviewState.header ? overviewState.header : null;
  const Footer = overviewState.footer ? overviewState.footer : null;

  const mapStateComponentProps = {
    state,
    stateRef,
    updateState,
    onCancel,
    onSubmit,
  };

  const map = React.useMemo(() => {
    return (
      <Map
        pointsState={overviewState.pointsState}
        stateRef={stateRef}
        name={centerOn ? undefined : 'map.overview'}
        types={overviewState.mapPlugins}
        onClickMapItem={localOnClickMapItem}
        onEvent={onEvent}
        overrideMapProperties={{
          boundingBox: centerOn,
          mapDetails: state.overwrittenMapDetails ?? undefined,
        }}
      />
    );
  }, [stateRef, centerOn, overviewState]);

  return (
    <div className={styles.mapOverviewWrapper}>
      <MapPortal
        content={state.mapPortalContent}
        onClose={() => {
          updateState({ mapPortalContent: null });
        }}
        onRefresh={() => {
          updateState({ mapPortalContent: null });
          updateState({
            overwrittenMapDetails: [
              ...settings.mapDetails.filter((a) => a !== MAP_DETAILS.MOBS),
            ],
          });
          Promise.all([mobs.refresh(), paddocks.refresh()]).then(() => {
            updateState({
              overwrittenMapDetails: null,
            });
          });
        }}
      />

      {Header && <Header {...mapStateComponentProps} />}

      <Box
        className={[
          styles.mapWrapper,
          Header || Footer ? styles.withHeaderOrFooter : '',
        ]}
      >
        {map}
      </Box>

      {Footer && <Footer {...mapStateComponentProps} />}
    </div>
  );
};
