import React from 'react';
import { formatTime, RawDate } from '@mobble/shared/src/core/Date';
import { useSetting } from '@mobble/store/src/hooks/settings';
import { Text, type TextProps } from '../UI/Text';

export type LocaleTimeProps = TextProps & {
  date: RawDate;
};

export const LocaleTime: React.FC<LocaleTimeProps> = ({ date, ...rest }) => {
  const timeFormat = useSetting('timeFormat') as string;
  return <Text {...rest}>{formatTime(date, timeFormat)}</Text>;
};
