import { type Property } from './Property';
import {
  type Paddock,
  findPaddock,
  getMobTotalHead,
  calcTotalDSEOfPaddock,
  getTotalArea,
} from './Paddock';
import { type Mob } from './Mob';

export interface PaddockGroup {
  id: string;
  propertyId: Property['id'];
  paddockIds: Paddock['id'][];
}

export const getPaddockGroupsForPaddock =
  (paddockGroups: PaddockGroup[]) =>
  (paddockId: Paddock['id']): PaddockGroup[] =>
    paddockGroups.filter((pg) => pg.paddockIds.includes(paddockId));

export const findPaddockGroupForPaddock =
  (paddockGroups: PaddockGroup[]) =>
  (paddockId: Paddock['id']): undefined | PaddockGroup =>
    paddockGroups.find((pg) => pg.paddockIds.includes(paddockId));

export const getPaddocksInSamePaddockGroupAsPaddock =
  (paddockGroups: PaddockGroup[], paddocks: Paddock[]) =>
  (paddockId: Paddock['id']): Paddock[] => {
    const paddockGroupsWithPaddock =
      getPaddockGroupsForPaddock(paddockGroups)(paddockId);
    const paddocksIdsOnPaddockGroups = [
      ...paddockGroupsWithPaddock.reduce<Set<string>>((acc, pg) => {
        pg.paddockIds.forEach((pid) => acc.add(pid));
        return acc;
      }, new Set()),
    ];

    return paddocksIdsOnPaddockGroups
      .map(findPaddock(paddocks))
      .filter(Boolean) as Paddock[];
  };

export const getMobsOnPaddockAndSamePaddockGroup =
  (paddockGroups: PaddockGroup[], paddocks: Paddock[]) =>
  (paddockId: string): Mob['id'][] => {
    const paddocksInGroup = getPaddocksInSamePaddockGroupAsPaddock(
      paddockGroups,
      paddocks
    )(paddockId);

    if (paddocksInGroup.length > 0) {
      return paddocksInGroup.reduce<Mob['id'][]>(
        (acc, cur) => [...acc, ...cur.mobs],
        []
      );
    }
    return findPaddock(paddocks)(paddockId)?.mobs ?? [];
  };

export const getOpenGatesParams = (
  paddocksInSamePaddockGroup: Paddock[],
  mobs: Mob[]
) => {
  const mobTotal = getMobTotalHead(mobs);
  const DSETotal = calcTotalDSEOfPaddock(mobs);

  const totalLivestock = paddocksInSamePaddockGroup.reduce(
    (total, pdk) => total + mobTotal(pdk),
    0
  );
  const totalDSE = paddocksInSamePaddockGroup.reduce(
    (total, pdk) => total + DSETotal(pdk),
    0
  );
  const totalArea = getTotalArea(paddocksInSamePaddockGroup);

  return {
    totalLivestock,
    totalDSE,
    totalArea,
  };
};
