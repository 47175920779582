import React from 'react';
import { useI18n } from '@mobble/i18n';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useProperties,
  useRainGauges,
  useSettings,
} from '@mobble/store/src/hooks';
import {
  RainGauge,
  filterRainGauges,
} from '@mobble/models/src/model/RainGauge';

import { useLinking } from '@src/hooks/useLinking';

import { Box } from '@src/stories/Components/Layout/Box';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { makeRainGaugesTableColumns } from '@src/stories/Views/RainGauge/List/rainGaugeTableColumns';
import { RainGaugeCard } from '@src/stories/Views/RainGauge/RainGaugeCard';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

import styles from './rainGauges.scss';

export const RainGaugesHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Rain gauges',
        description: 'screen.title.rain-gauges',
      })}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const RainGauges: React.FC = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const { settings } = useSettings();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const rainGauges = useRainGauges(propertyId);

  useEntitiesRefresher([rainGauges], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, rainGauges.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleClickRainGauge = (rainGauge: RainGauge) => {
    linkTo(ROUTE_NAME.RAIN_GAUGE_DETAIL, { rainGaugeId: rainGauge.id });
  };

  const tableColumns = makeRainGaugesTableColumns({ settings });

  const viewerProps: EntitiesViewerProps<RainGauge> = {
    provider: rainGauges,
    tableColumns,
    onEmpty: formatMessage({
      defaultMessage: 'Add a new rain gauge below',
      description: 'rain-gauges.list.empty.add',
    }),
    applyFilter: filterRainGauges,
    onClickTableRow: handleClickRainGauge,
    renderEntityCard(entity) {
      return (
        <Box spacing={1}>
          <RainGaugeCard rainGauge={entity} onClick={handleClickRainGauge} />
        </Box>
      );
    },
  };

  return (
    <>
      <EntitiesViewer {...viewerProps} />

      <Box className={styles.rainGaugesGraphs}>
        {rainGauges.entities.map((rainGauge) => (
          <RainGaugeCard
            key={rainGauge.id}
            chart
            rainGauge={rainGauge}
            onClick={handleClickRainGauge}
          />
        ))}
      </Box>
    </>
  );
};

export default {
  name: ROUTE_NAME.RAIN_GAUGES_LIST,
  header: RainGaugesHeader,
  component: RainGauges,
};
