import { Color } from '@mobble/colors';
import { type Property } from './Property';
import { InventoryItem, InventoryItemStatus } from './InventoryItem';

export interface Inventory {
  id: InventoryID;
  propertyId: Property['id'];
  name: string;
  type: InventoryTypes;
  category: InventoryCategory;
  shared?: InventoryPropertyReference;
  sharing: InventoryPropertySharingReference[];
}

export interface InventoryPropertyReference {
  propertyId: Property['id'];
  propertyName: string;
}

export interface InventoryPropertySharingReference {
  propertyId: Property['id'];
  propertyName: string;
  selected: boolean;
}

export enum InventoryCategory {
  Feed = 'inventory-category_feed',
  Chemicals = 'inventory-category_chemicals',
}

export type InventoryTypes = 'herbicidePesticide' | 'animalHealth' | 'feed';

export enum INVENTORY_TYPE {
  HERBICIDE_PESTICIDE = 'herbicidePesticide',
  ANIMAL_HEALTH = 'animalHealth',
  FEED = 'feed',
}

export const inventoryNameToCategory = (name: string): InventoryCategory => {
  switch (name) {
    case 'feed':
      return InventoryCategory.Feed;
    case 'herbicidePesticide':
    case 'animalHealth':
      return InventoryCategory.Chemicals;
    default:
      return InventoryCategory.Chemicals;
  }
};

export const inventoryCategoryToColor = (
  category: InventoryCategory
): Color => {
  switch (category) {
    case InventoryCategory.Feed:
      return Color.Orange;
    case InventoryCategory.Chemicals:
      return Color.Blue;
    default:
      return Color.Blue;
  }
};

export type InventoryID = string;

export interface InventoryIdParts {
  propertyId: string;
  type: string;
  sharedPropertyId?: string;
}

export const makeInventoryId = (parts: InventoryIdParts): InventoryID => {
  const json = JSON.stringify(parts);
  return btoa(json);
};

export const parseInventoryId = (id: InventoryID): InventoryIdParts => {
  const json = atob(id);
  return JSON.parse(json);
};

export const sortActiveProducts =
  (inventoryItems: InventoryItem[]) => (inventoryId: string) =>
    inventoryItems.filter(
      (inventoryItem) =>
        inventoryItem.inventoryId === inventoryId &&
        inventoryItem.status === InventoryItemStatus.Active
    );
