import React from 'react';

import { Color } from '@mobble/colors';
import { getLivestockAgeColor } from '@mobble/models/src/model/Mob';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';
import { useSettings } from '@mobble/store/src/hooks/settings';

import { BadgeList } from '@src/components';
import { Box } from '@src/stories/Components/Layout/Box';
import { VStack } from '@src/stories/Components/Layout/Stack';
import { Table, type TableProps } from '@src/stories/Components/Layout/Table';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Text } from '@src/stories/Components/UI/Text';

import { type RootItem } from './usePropertyReportForRange';

export interface LivestockSalesReportProps {
  propertyTypes: ConfiguredPropertyType[];
  reportData: RootItem[];
}

export const LivestockSalesReport: React.FC<LivestockSalesReportProps> = ({
  propertyTypes,
  reportData,
}) => {
  const { settings } = useSettings();

  if (!reportData) {
    return (
      <VStack alignment="center">
        <Box spacing={4}>
          <Spinner color={Color.Black} />
        </Box>
      </VStack>
    );
  }

  const renderClasses = (arr: (string | number)[]) => {
    const badges = arr.map((a) => ({
      label: String(a),
    }));
    return (
      <BadgeList defaultColor={'var(--neutrals-bg-default)'} badges={badges} />
    );
  };

  const renderAges = (type: string, arr: (string | number)[]) => {
    const badges = arr.map((a) => ({
      label: String(a),
      color: getLivestockAgeColor(propertyTypes, type)(Number(a)),
    }));
    return <BadgeList badges={badges} />;
  };

  const tableData = toTableExtendedData(reportData);

  const tableProps: TableProps<RootItem> = {
    title: { key: 'reports.livestock_sales.table.title' },
    showTitle: true,
    columns: [
      {
        key: 'type',
        toValue: (item) => item.type,
      },
      {
        key: 'breed',
        toValue: (item) => item.breed,
      },
      {
        key: 'gender',
        toValue: (item) => item.gender,
      },
      {
        key: 'sold',
        toValue: (item) => item.sales.sold,
        totals: true,
      },
      {
        key: 'average_per_head',
        toValue: (item) => item.sales.averagePerHead,
        totals: () => {
          const totalReceived = tableData.reduce(
            (a, b) => a + b.sales.total,
            0
          );
          const totalSold = tableData.reduce((a, b) => a + b.sales.sold, 0);
          return totalReceived / totalSold;
        },
        valueToString: (value) =>
          formatMonetary(value, settings.currencySymbol),
      },
      {
        key: 'total',
        toValue: (item) => item.sales.total,
        totals: true,
        valueToString: (value) =>
          formatMonetary(value, settings.currencySymbol),
      },
    ].map((c) => ({
      ...c,
      label: {
        key: `reports.livestock_sales.table.heading.colum.${c.key}`,
      },
    })),
    data: tableData,
    renderColSpan: (item, depth) => {
      if (item._title) {
        if (Array.isArray(item._title)) {
          if (depth === 1) {
            return renderClasses(item._title);
          } else if (depth === 2) {
            return renderAges(item.type, item._title);
          } else {
            return <Text variant="body">{item._title.join(', ')}</Text>;
          }
        }
      }
      return <Text variant="body">{item._title}</Text>;
    },
  };

  return <Table {...tableProps} />;
};

const toTableExtendedData = (data: RootItem[]) => {
  return data
    .map((rootItem) => {
      return {
        ...rootItem,
        _children: [
          ...Object.entries(rootItem.byClasses).map(([key, val]) => ({
            ...val,
            _title: JSON.parse(key),
            _span: 3,
            _children: [
              ...Object.entries(val.byAges).map(([key, val]) => ({
                ...val,
                _title: JSON.parse(key),
                _span: 3,
              })),
            ],
          })),
        ],
      };
    })
    .reduce((acc, item) => {
      if (item.sales.sold === 0) {
        return acc;
      }

      return [
        ...acc,
        {
          ...item,
          _children: item._children.filter((item) => item.sales.sold),
        },
      ];
    }, []);
};
