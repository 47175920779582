import React, { useState } from 'react';
import { Color } from '@mobble/theme';
import { useI18n } from '@mobble/i18n';
import { MOBBLE_COLORS } from '@mobble/models/src/model/Property';

import { ColorSwatch } from '@src/components';

import { Box } from '../Layout/Box';
import { Spacer } from '../Layout/Spacer';
import { HStack } from '../Layout/Stack';
import { Icon } from '../UI/Icon';
import { Text } from '../UI/Text';
import { Clickable } from './Clickable';
import { ModalFlyUp } from './ModalFlyUp';

import styles from './inputColor.scss';

export interface InputColorProps {
  colors?: string[];
  placeholder?: string;
  value?: string;
  onExpandChange?: (expanded: boolean) => void;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

export const InputColor: React.FC<InputColorProps> = ({
  colors,
  placeholder,
  value,
  onExpandChange,
  onChange,
  onBlur,
}) => {
  const { formatMessage } = useI18n();
  const [stateExpanded, setStateExpanded] = useState(false);

  const onToggleExpanded = () => {
    setStateExpanded(!stateExpanded);
    if (onExpandChange) {
      onExpandChange(!stateExpanded);
    }
    if (onBlur && !stateExpanded) {
      onBlur();
    }
  };

  const options = (colors ?? MOBBLE_COLORS).map((color) => ({
    label: color,
    color,
    selected: color.toUpperCase() === value?.toUpperCase(),
  }));

  const handleClose = () => {
    setStateExpanded(false);
  };

  return (
    <>
      <Box flex className={styles.inputWrapper}>
        <Clickable href={onToggleExpanded} flex spacing={2}>
          <Box flex>
            <HStack>
              <ColorSwatch color={value} circle />
              <Spacer x={1} />
              <Text
                color={Color.Black}
                i18n={
                  !value
                    ? placeholder ||
                      formatMessage({
                        defaultMessage: 'Select colour',
                        description: 'color_selector.placeholder',
                      })
                    : null
                }
              >
                {value}
              </Text>
            </HStack>
          </Box>
          <Icon
            size="small"
            name={stateExpanded ? 'chevron-up' : 'chevron-down'}
          />
        </Clickable>
      </Box>
      <ModalFlyUp
        isOpen={stateExpanded}
        title={formatMessage({
          defaultMessage: 'Select Colour',
          description: 'color_selector.modal.title',
        })}
        onClose={handleClose}
      >
        <section>
          {options.map((option) => (
            <Clickable
              key={option.color}
              href={() => {
                onChange(option.color);
                setStateExpanded(false);
              }}
              spacing={1}
            >
              <ColorSwatch color={option.color} />
            </Clickable>
          ))}
        </section>
      </ModalFlyUp>
    </>
  );
};
