import { type Sale } from '@mobble/models/src/model/Sale';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { FilterPerProperty } from '../lib/filter';
import mobbleService from '@mobble/service';
import { thunkGetAll as thunkGetAllPaddocks } from './paddocks';
import { thunkGetAll as thunkGetAllMobs } from './mobs';

export interface SaleState {
  entities: Sale[];
  extStatus: ExtStatusPerActionPerParent;
  filter: FilterPerProperty;
  sort: {};
}

export const defaultState: SaleState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const toParentId = ({ mob }: Sale) => {
  return mob?.propertyId;
};

const entitySlice = entitySliceFactory<SaleState, Sale>({
  name: 'sales',
  initialState: defaultState,
  toParentId,
  onGetAll: mobbleService.api.sales.get,
  onCreate: mobbleService.api.sales.create,
  onUpdate: mobbleService.api.sales.update,
  onDelete: mobbleService.api.sales.remove,
  afterMutation: async ({ entity }, dispatch) => {
    dispatch(thunkGetAllPaddocks({ parentId: toParentId(entity) }));
    dispatch(thunkGetAllMobs({ parentId: toParentId(entity) }));
  },
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
