import React from 'react';
import { Box } from '@src/stories/Components/Layout/Box';
import { SaleInfoTable } from '@src/stories/Views/Sale/SaleInfoTable';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import { ScreenRendererProps } from '../config/types';
import * as ROUTE_NAME from '../config/routeNames';

import { useSaleScreenHeader, useSaleScreen } from './useSaleScreen';
import styles from './saleDetail.scss';

export const SalesDetailHeader: React.FC<ScreenRendererProps> = ({ route }) => {
  const { saleId } = route.params;
  const props = useSaleScreenHeader(saleId);

  return <ScreenHeader {...props} />;
};

export const SalesDetail: React.FC<ScreenRendererProps> = ({ route }) => {
  const { saleId } = route.params;
  const { Prelude, sale, paddock, properties } = useSaleScreen(saleId);

  if (Prelude) {
    return Prelude;
  }

  return (
    <Box className={styles.saleInfoTabTable}>
      <SaleInfoTable
        sale={sale.entity}
        paddock={paddock.entity}
        propertyTypes={properties.selected?.types}
      />
    </Box>
  );
};

export default {
  name: ROUTE_NAME.SALE_DETAIL,
  header: SalesDetailHeader,
  component: SalesDetail,
};
