import Fuse from 'fuse.js';
import {
  convertArea,
  makeQuantityOfArea,
  sum,
  type QuantityOfArea,
} from '@mobble/shared/src/core/Quantity';
import { roundNumber } from '@mobble/shared/src/core/Number';
import { daysAgo, dateIsInThePast } from '@mobble/shared/src/core/Date';
import { findMob, type Mob } from './Mob';
import { ConfiguredPropertyType, type Property } from './Property';
import { type FilterItem, filterMatches, groupFilter } from './Filter';
import { SortDirection, type SortSetting, type SortOption } from './Sort';
import {
  distanceFromPointToPolygon,
  type Point,
  type Polygon,
} from './MapGeometry';
import { findPaddockGeometry, PaddockGeometry } from './PaddockGeometry';

export interface Paddock {
  id: string;
  propertyId: Property['id'];
  name: string;
  type: string;
  mobs: Mob['id'][];
  properties: PaddockProperties;
  status?: 'active' | 'deleted';

  // for create/update
  geometry?: {
    polygon: null | Polygon;
  };
}

export interface PaddockProperties {
  safeDate?: string;
  averageAreaStockingRate?: number;
  lastGrazingHistory?: {
    type: GrazingHistoryType;
    date: string;
  };
  size?: QuantityOfArea;
}

export enum GrazingHistoryType {
  paddockEmpty = 'paddockEmpty',
  paddockGrazing = 'paddockGrazing',
}

export const paddockIsYard = (paddock: Paddock): boolean =>
  paddock.type === 'Yard';

export const lastGrazingHistoryTypeToI18nKey = (type: string) => {
  switch (type) {
    default:
    case GrazingHistoryType.paddockEmpty:
      return 'rested';
    case GrazingHistoryType.paddockGrazing:
      return 'grazed';
  }
};

export const calcTotalDSEOfPaddock =
  (mobs: Mob[]) =>
  (paddock: Paddock): number =>
    Number(
      roundNumber(
        paddock.mobs.reduce((total, mobId) => {
          const mob = findMob(mobs)(mobId);
          if (!mob) {
            return total;
          }
          return total + mob.size * mob.DSE;
        }, 0)
      )
    );

export const getMobTotalHead =
  (allMobs: Mob[]) =>
  (paddock: Paddock): number =>
    mobsOnPaddock(allMobs)(paddock).reduce((a, mob) => a + mob.size, 0);

export const getTotalArea = (paddocks: Paddock[]): QuantityOfArea => {
  return (
    sum(
      paddocks.map((p) => p.properties.size).filter(Boolean) as QuantityOfArea[]
    ) ?? makeQuantityOfArea('ha', 0)
  );
};

export const mobsOnPaddock =
  (allMobs: Mob[]) =>
  (paddock: Paddock): Mob[] =>
    paddock.mobs.map(findMob(allMobs)).filter(Boolean) as Mob[];

export const findPaddock =
  (paddocks: Paddock[]) =>
  (id: string): Paddock | undefined =>
    paddocks.find((p) => p.id === id);

export const getPaddock =
  (paddocks: Paddock[]) =>
  (id: string): Paddock => {
    const paddock = paddocks.find((p) => p.id === id);
    if (!paddock) {
      throw new Error('Paddock not found');
    }
    return paddock;
  };

export const findPaddocks = (paddocks: Paddock[]) => (paddockIds: string[]) =>
  paddocks.filter((p) => paddockIds.includes(p.id));

export const paddockForMob =
  (paddocks: Paddock[]) =>
  (mob: Mob | string): undefined | Paddock => {
    const mobId = typeof mob === 'string' ? mob : mob.id;
    return paddocks.find((p) => p.mobs.includes(mobId));
  };

export const filterPaddocks =
  (allMobs: Mob[]) =>
  (paddocks: Paddock[], filter?: FilterItem[]): Paddock[] => {
    if (!filter || filter.length === 0) {
      return paddocks;
    }
    const grouped = [...groupFilter(filter)];

    const searchQuery = filter.find((a) => a.group === 'search')?.filter;

    const preFilteredPaddocks =
      searchQuery && searchQuery.type === 'search'
        ? searchPaddocks(allMobs)(paddocks, searchQuery.value)
        : paddocks;

    return preFilteredPaddocks.filter((paddock) =>
      grouped.every(([_, filters]) =>
        filters.some(paddocksFilterItemMatchesPaddock(allMobs)(paddock))
      )
    );
  };

export const searchPaddocks =
  (allMobs: Mob[]) =>
  (paddocks: Paddock[], searchQuery: string, threshold?: number): Paddock[] => {
    const fuse = new Fuse(
      paddocks.map((p) => ({
        ...p,
        _mobsOnPaddock: mobsOnPaddock(allMobs)(p),
      })),
      {
        keys: [
          { name: 'name', weight: 6 },
          { name: 'type', weight: 1 },
          { name: '_mobsOnPaddock.type', weight: 3 },
          { name: '_mobsOnPaddock.breed', weight: 2 },
          { name: '_mobsOnPaddock.gender', weight: 1 },
          { name: '_mobsOnPaddock.classes', weight: 2 },
        ],
        threshold: threshold ?? 0.3,
        shouldSort: true,
      }
    );

    const result = fuse.search(searchQuery).map((a) => a.item);

    if (result.length === 0 && !threshold) {
      return searchPaddocks(allMobs)(paddocks, searchQuery, 0.5);
    }

    return result;
  };

export const paddocksFilterItemMatchesPaddock =
  (allMobs: Mob[]) => (paddock: Paddock) => (filterItem: FilterItem) => {
    const mobs = mobsOnPaddock(allMobs)(paddock);
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'paddock_type':
        return matches(paddock.type);
      case 'livestock_type':
        return mobs.some((m) => matches(m.type));
      case 'breed':
        return mobs.some((m) => matches(m.breed));
      case 'gender':
        return mobs.some((m) => matches(m.gender));
      case 'age':
        return mobs.some((m) => m.ages.some(matches));
      case 'class':
        return mobs.some((m) => m.classes.some(matches));
      case 'paddock_size':
        return (
          paddock.properties?.size?.value &&
          matches(paddock.properties.size.value)
        );
      case 'search':
        return true;
      default:
        return true;
    }
  };

export const availableSortOptions: SortOption[] = [
  {
    name: 'name_yards_asc',
    settings: [{ direction: SortDirection.Ascending, column: 'name' }],
  },
  {
    name: 'name_desc',
    settings: [{ direction: SortDirection.Descending, column: 'name' }],
  },
  {
    name: 'distance',
    settings: [{ direction: SortDirection.Ascending, column: 'distance' }],
  },
  {
    name: 'size_asc',
    settings: [{ direction: SortDirection.Ascending, column: 'size' }],
  },
  {
    name: 'size_desc',
    settings: [{ direction: SortDirection.Descending, column: 'size' }],
  },
  {
    name: 'head_asc',
    settings: [{ direction: SortDirection.Ascending, column: 'head' }],
  },
  {
    name: 'head_desc',
    settings: [{ direction: SortDirection.Descending, column: 'head' }],
  },
  {
    name: 'days_grazed_asc',
    settings: [{ direction: SortDirection.Ascending, column: 'days_grazed' }],
  },
  {
    name: 'days_grazed_desc',
    settings: [{ direction: SortDirection.Descending, column: 'days_grazed' }],
  },
];

export const sortOptionsNameDistanceFromPaddock: SortOption[] = [
  {
    name: 'name_yards_asc',
    settings: [{ direction: SortDirection.Ascending, column: 'name' }],
  },
  {
    name: 'name_desc',
    settings: [{ direction: SortDirection.Descending, column: 'name' }],
  },
  {
    name: 'distance_from_paddock',
    settings: [{ direction: SortDirection.Ascending, column: 'distance' }],
  },
];

export const sortOptionsNameDistanceFromMe: SortOption[] = [
  {
    name: 'name_yards_asc',
    settings: [{ direction: SortDirection.Ascending, column: 'name' }],
  },
  {
    name: 'name_desc',
    settings: [{ direction: SortDirection.Descending, column: 'name' }],
  },
  {
    name: 'distance',
    settings: [{ direction: SortDirection.Ascending, column: 'distance' }],
  },
];

export interface PaddockSortMeta {
  paddockGeometries?: PaddockGeometry[];
  mobs?: Mob[];
  origin?: Point;
}

export const sortAscendingBy =
  (meta?: PaddockSortMeta) => (column: string) => (a: Paddock, b: Paddock) => {
    switch (column) {
      case 'name':
        return a.name.localeCompare(b.name);
      case 'distance': {
        const distanceToPaddockId = (paddock: Paddock) => {
          const paddockPolygon = meta?.paddockGeometries
            ? findPaddockGeometry(meta.paddockGeometries)(paddock.id)?.polygon
            : null;
          const distance =
            meta?.origin &&
            paddockPolygon &&
            distanceFromPointToPolygon(meta.origin, paddockPolygon)?.value;
          return distance;
        };

        const dA = distanceToPaddockId(a);
        const dB = distanceToPaddockId(b);
        if (dA && dB) {
          return dA - dB;
        } else if (dA) {
          return -1;
        } else if (dB) {
          return 1;
        }
        return 0;
      }
      case 'size': {
        const sA = a.properties?.size
          ? convertArea('ha')(a.properties?.size).value
          : 0;
        const sB = b.properties?.size
          ? convertArea('ha')(b.properties?.size).value
          : 0;
        return sA - sB;
      }

      case 'head': {
        const mA = (meta?.mobs && getMobTotalHead(meta.mobs)(a)) || 0;
        const mB = (meta?.mobs && getMobTotalHead(meta.mobs)(b)) || 0;

        return mA - mB;
      }

      case 'days_grazed': {
        const daysA =
          a.properties.lastGrazingHistory?.type ===
          GrazingHistoryType.paddockGrazing
            ? 1
            : a.properties.lastGrazingHistory?.type ===
              GrazingHistoryType.paddockEmpty
            ? 0
            : -1;
        const daysB = b.properties.lastGrazingHistory?.date
          ? b.properties.lastGrazingHistory?.type ===
            GrazingHistoryType.paddockEmpty
            ? -daysAgo(b.properties.lastGrazingHistory.date)
            : daysAgo(b.properties.lastGrazingHistory.date)
          : -1;
        return daysA - daysB;
      }
    }
  };

export const sortPaddocks =
  (meta?: PaddockSortMeta) =>
  (paddocks: Paddock[], sortSettings: SortSetting[]): Paddock[] =>
    sortAnyContainingPaddocks((p) => p)(meta)(paddocks, sortSettings);

export const sortAnyContainingPaddocks =
  <A = any>(toPaddock: (a: A) => Paddock) =>
  (meta?: PaddockSortMeta) =>
  (items: A[], sortSettings: SortSetting[]): A[] => {
    return items.sort((a, b) => {
      const paddockA = toPaddock(a);
      const paddockB = toPaddock(b);

      // Pin yards to the top when sorting by name
      if (sortSettings[0]?.column === 'name') {
        if (paddockA.type === 'Yard' && paddockB.type !== 'Yard') {
          return -1;
        } else if (paddockA.type !== 'Yard' && paddockB.type === 'Yard') {
          return 1;
        }
      }

      return sortSettings.reduce<number>((result, sortSetting) => {
        if (result === 0) {
          const asc =
            sortAscendingBy(meta)(sortSetting.column)(paddockA, paddockB) ??
            result;
          if (sortSetting.direction === SortDirection.Descending) {
            return asc < 0 ? 1 : asc > 0 ? -1 : 0;
          }
          return asc;
        }
        return result;
      }, 0);
    });
  };

export const getPaddockColor =
  (propertyTypes: ConfiguredPropertyType[]) => (paddock: Paddock) =>
    propertyTypes.find((a) => a.type === paddock.type)?.color;

export const isSafe = (paddock: Paddock) => {
  return (
    !paddock.properties.safeDate || dateIsInThePast(paddock.properties.safeDate)
  );
};
