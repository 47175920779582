import React from 'react';
import { Color } from '@mobble/colors';
import { Box } from '../Layout/Box';
import { spacingToAbsolute } from '../Layout/Spacing';
import {
  NavigationListItem,
  NavigationListItemProps,
} from './NavigationListItem';

export interface NavigationListProps {
  items: NavigationListItemProps[];
}

export interface WithTitle<ItemT = any> {
  title: string;
  right?: string;
  data: ItemT[];
}

export const NavigationList: React.FC<NavigationListProps> = ({ items }) => (
  <Box
    background={Color.AlmostWhite}
    spacing={0.5}
    style={{ borderRadius: spacingToAbsolute(1.5) }}
  >
    {items.map((item, index) => (
      <React.Fragment key={index}>
        <NavigationListItem
          label={item.label}
          description={item.description}
          first={index === 0}
          last={items.length - 1 === index}
          href={item.href}
          disabled={item.disabled}
          color={item.color}
        />
        {items.length - 1 !== index && <Box height={0.5} />}
      </React.Fragment>
    ))}
  </Box>
);
