import { Property } from '@mobble/models/src/model/Property';
import { Purchase } from '@mobble/models/src/model/Purchase';
import { type ApiPrelude, type ExtCursor } from '../types';
import { decodeMob } from './mobs';

const GET_PURCHASES = `
  query GetPurchases($propertyId: ID!) {
    purchases(propertyId: $propertyId) {
      totalCount
      nodes {
        id
        date
        pricePerHeadCents
        number
        status
        notes
        type
        DSE
        breed
        gender
        classes
        ages
        seller {
          id
          name
        }
        paddock {
          id
        }
      }
    }
  }
`;

const decodePurchases =
  (propertyId: string) =>
  (raw: any[]): Purchase[] => {
    return raw.map(decodePurchase(propertyId));
  };

const decodePurchase =
  (propertyId: string) =>
  (raw: any): Purchase => {
    const purchase: Purchase = {
      id: raw?.id ?? '',
      propertyId,
      type: raw?.type,
      date: raw?.date ?? '',

      mob: decodeMob(propertyId)(raw),

      paddock: raw?.paddock?.id,

      pricePerHeadCents: raw?.pricePerHeadCents,
      notes: raw?.notes,

      sellerId: raw?.seller?.id ?? '',
      sellerName: raw?.seller?.name ?? '',

      status: raw?.status,
    };

    return purchase;
  };

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    cursor,
  }: {
    parentId: string;
    cursor?: string;
  }): Promise<{ entities: Purchase[]; extCursor: ExtCursor }> => {
    const variables = {
      propertyId: parentId,
      after: cursor,
      pageSize: 9999,
    };

    const response = await prelude.graphql({
      query: GET_PURCHASES,
      variables,
    });

    const entities = decodePurchases(parentId)(response.data.purchases.nodes);

    const pageInfo = response?.data?.casualties?.pageInfo;
    const extCursor = {
      total: response?.data?.casualties?.totalCount ?? entities.length,
      nextCursor: pageInfo?.hasNextPage && pageInfo.endCursor,
    };

    return {
      entities,
      extCursor,
    };
  };

const EDIT_PURCHASE = `
  mutation EditPurchase($input: EditPurchaseInput!) {
    editPurchase(input: $input) {
      purchase {
        id
        date
        pricePerHeadCents
        number
        status
        notes
        type
        DSE
        breed
        gender
        classes
        ages
        seller {
          id
          name
        }
        paddock {
          id
        }
      }
    }
  }
`;

interface EditCasualtyActionInput {
  id: string;
  propertyId: string;
  purchaseDate: any;
  pricePerHeadCents: number;
  notes?: string | null;
  seller?: string | null;
  number: number;
  DSE?: number;
  paddockId?: string | null;
  type: string;
  breed: string;
  gender: string;
  classes: string[];
  ages: number[];
}

export const update =
  (prelude: ApiPrelude) =>
  async ({ updatedEntity }: { updatedEntity: Purchase }): Promise<Purchase> => {
    const input: EditCasualtyActionInput = {
      propertyId: updatedEntity.propertyId,
      id: updatedEntity.id,
      number: updatedEntity.mob.size,
      purchaseDate: updatedEntity.date,
      pricePerHeadCents: Math.floor(updatedEntity.pricePerHeadCents),
      seller: updatedEntity.sellerName,
      notes: updatedEntity.notes,
      type: updatedEntity.type,
      DSE: Number(updatedEntity.mob.DSE),
      breed: updatedEntity.mob.breed,
      gender: updatedEntity.mob.gender,
      classes: updatedEntity.mob.classes,
      ages: updatedEntity.mob.ages,
    };

    const response = await prelude.graphql({
      query: EDIT_PURCHASE,
      variables: { input },
    });

    const purchase = decodePurchase(updatedEntity.propertyId)(
      response?.data?.editPurchase?.purchase
    );

    return purchase;
  };

const DELETE_PURCHASE = `
mutation DeletePurchase($input: DeletePurchaseInput!) {
    deletePurchase(input: $input) {
      purchase {
        id
      }
    }
  }
`;

interface ApiDeletePurchaseInput {
  id: string;
  propertyId: Property['id'];
}

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Purchase }): Promise<void> => {
    const input: ApiDeletePurchaseInput = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({ query: DELETE_PURCHASE, variables: { input } });

    return;
  };
