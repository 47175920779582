import React from 'react';
import { useProperties } from '@mobble/store/src/hooks/properties';
import mobbleService from '@mobble/service';
import { useGetPostAuthenticationActions } from './useGetAuthenticationActions';
import { useAuthenticateStateAndCode } from './useAuthenticateStateAndCode';
export const useAPICallback = ({
  state,
  code,
}: {
  state: string;
  code: string;
}) => {
  const properties = useProperties();
  const authenticateStateAndCode = useAuthenticateStateAndCode();
  const postAuthenticationAction = useGetPostAuthenticationActions();

  React.useEffect(() => {
    if (code && state) {
      authenticateStateAndCode(code, state).then((r) => {
        const context = r.entity.attributes.find(
          (i) => i.key === 'context'
        )?.value;

        mobbleService.api.mobbleApps
          .enableMobbleApp({
            organisationId: properties.selected?.organisationId,
            integrationId: 'agworld',
          })
          .then(() => {
            postAuthenticationAction(context);
          });
      });
    }
  }, [code, state]);
};
