import React from 'react';
import { useI18n } from '@mobble/i18n';
import { fromRawDate, getMonths } from '@mobble/shared/src/core/Date';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { type Casualty } from '@mobble/models/src/model/Casualty';
import { ChartWrapper } from '@src/stories/Components/Charts/ChartWrapper';
import { Bar } from '@src/stories/Components/Charts/Bar';

export interface ChartMonthlyCasualtiesByMobProps {
  casualties: Casualty[];
}

export const ChartMonthlyCasualtiesByMob: React.FC<
  ChartMonthlyCasualtiesByMobProps
> = ({ casualties }) => {
  const { formatMessage } = useI18n();
  const curYear = new Date().getFullYear();
  const [year, setYear] = React.useState<number>(curYear);

  const casualtyYears = [
    ...new Set(casualties.map((c) => fromRawDate(c.date).year())),
  ];
  const minYear = Math.min(...casualtyYears) ?? curYear;
  const yearOptions = new Array(curYear - minYear + 1)
    .fill(null)
    .map((_, i) => {
      const value = minYear + i;
      return {
        value,
        label: String(value),
        selected: value === year,
      };
    });

  const months = getMonths({ short: true });

  const data = months.map((m, i) => {
    const casualtiesThisMonth = casualties.filter((a) => {
      const date = fromRawDate(a.date);
      return date.year() === year && date.month() === i;
    });

    return {
      index: m,
      values: casualtiesThisMonth.map((c) => ({
        label: toMobDisplayName(c.mob),
        value: c.number,
      })),
    };
  });

  return (
    <ChartWrapper
      title={formatMessage({
        defaultMessage: 'Monthly casualties by mob',
        description: 'Monthly Casualties Chart by Mob Title',
      })}
      options={[
        {
          options: yearOptions,
          onChange: (value) => setYear(Number(value)),
        },
      ]}
    >
      <Bar data={data} />
    </ChartWrapper>
  );
};
