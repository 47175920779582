import { Mob } from '@mobble/models/src/model/Mob';
import { sortNumberDescending } from '@mobble/shared/src/core/Number';
import { type Property } from '@mobble/models/src/model/Property';
import { type HistoricEvent } from '@mobble/models/src/model/HistoricEvent';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { type ApiPrelude, type ExtCursor } from '../types';
// import { makeResponseApiGetHistoricEventsForMob } from './historic-events';

const decodeMobs =
  (propertyId: string) =>
  (raw: any[]): Mob[] => {
    return raw.map(decodeMob(propertyId));
  };

export const decodeMob =
  (propertyId: string) =>
  (raw: any): Mob => {
    return {
      id: raw?.id ?? '',
      propertyId,
      breed: raw?.breed ?? '',
      type: raw?.type ?? '',
      gender: raw?.gender ?? '',
      size: raw?.number ?? 0,
      DSE: raw?.DSE ?? 0,
      ages: (raw?.ages ?? []).sort(sortNumberDescending),
      classes: (raw?.classes ?? []).filter((a) => Boolean(a)),
      safeDate: raw?.safeDate ?? '',
      snapshot: raw?.paddockName
        ? { paddockName: raw.paddockName, paddockId: raw.paddockUUID }
        : undefined,
    };
  };

const GET_MOBS = `
  query GetMobs($propertyId: ID!) {
    mobs(propertyId: $propertyId) {
      nodes {
        id
        breed
        gender
        type
        DSE
        number
        classes
        ages
        deleted
        sold
        safeDate
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{
    entities: Mob[];
    historicEvents: {
      mobId: string;
      entities: HistoricEvent[];
      extCursor: ExtCursor;
    }[];
  }> => {
    const response = await prelude.graphql({
      query: GET_MOBS,
      variables: { propertyId: parentId },
    });
    const entities = decodeMobs(parentId)(response?.data?.mobs?.nodes ?? []);

    // const historicEvents = (response?.data?.paddocks?.nodes ?? []).map(
    //   (node: any) => {
    //     return {
    //       mobId: node.id,
    //       ...makeResponseApiGetHistoricEventsForMob(
    //         parentId,
    //         node.id
    //       )(node.events),
    //     };
    //   }
    // );
    const historicEvents: any[] = [];

    return { entities, historicEvents };
  };

const CREATE_MOB = `
  mutation AddMob($input: AddMobInput!) {
    addMob(input: $input) {
      mob {
        id
        breed
        gender
        type
        DSE
        number
        classes
        ages
        deleted
        sold
        safeDate
        paddock {
          id
        }
      }
    }
  }
`;

interface AddMobPurchaseInput {
  purchaseDate: any;
  pricePerHeadCents: number;
  notes?: string | null;
  seller?: string | null;
}

interface AddMobNaturalIncreaseInput {
  notes?: string | null;
  naturalIncreaseDate: any;
}

interface ApiCreateMobInput {
  type: string;
  DSE: number;
  breed: string;
  gender: string;
  number: number;
  classes: (string | null)[];
  ages: number[];
  paddockId: string;
  propertyId: string;
  purchase?: AddMobPurchaseInput | null;
  naturalIncrease?: AddMobNaturalIncreaseInput | null;
}

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<Mob, 'id'> }): Promise<Mob> => {
    const input: ApiCreateMobInput = {
      type: entity.type,
      DSE: Number(entity.DSE),
      breed: entity.breed,
      gender: entity.gender,
      number: entity.size,
      classes: entity.classes,
      ages: entity.ages,
      paddockId: entity.paddockId ?? '',
      propertyId: entity.propertyId,
      purchase: entity.purchaseInformation
        ? {
            purchaseDate: toISO8601(entity.purchaseInformation.purchaseDate),
            pricePerHeadCents: Math.floor(
              entity.purchaseInformation.pricePerHeadCents
            ),
            notes: entity.purchaseInformation.notes || null,
            seller: entity.purchaseInformation.seller || null,
          }
        : null,
      naturalIncrease: entity.naturalIncreaseInformation
        ? {
            naturalIncreaseDate: toISO8601(
              entity.naturalIncreaseInformation.naturalIncreaseDate
            ),
            notes: entity.naturalIncreaseInformation.notes || null,
          }
        : null,
    };

    const response = await prelude.graphql({
      query: CREATE_MOB,
      variables: { input },
    });
    const result = response?.data?.addMob?.mob;

    const mob = decodeMob(entity.propertyId)(result);

    return mob;
  };

interface ApiUpdateMobInput {
  id: string;
  type: string;
  breed: string;
  gender: string;
  ages: number[];
  classes: string[];
  propertyId: Property['id'];
  number: number;
  DSE: number;
}

const UPDATE_MOB = `
  mutation EditMob($input: EditMobInput!) {
    editMob(input: $input) {
      mob {
        id
        breed
        gender
        type
        DSE
        number
        classes
        ages
        deleted
        sold
        safeDate
      }
    }
  }
`;

export const update =
  (prelude: ApiPrelude) =>
  async ({ updatedEntity }: { updatedEntity: Mob }): Promise<Mob> => {
    const input: ApiUpdateMobInput = {
      id: updatedEntity.id,
      type: updatedEntity.type,
      breed: updatedEntity.breed,
      gender: updatedEntity.gender,
      ages: updatedEntity.ages,
      classes: updatedEntity.classes,
      propertyId: updatedEntity.propertyId,
      number: updatedEntity.size,
      DSE: Number(updatedEntity.DSE),
    };

    const response = await prelude.graphql({
      query: UPDATE_MOB,
      variables: { input },
    });
    const result = response?.data?.editMob?.mob;

    const mob = decodeMob(updatedEntity.propertyId)(result);

    return mob;
  };

const DELETE_MOB = `
  mutation DeleteMob($input: DeleteMobInput!) {
    deleteMob(input: $input) {
      mob {
        id
      }
    }
  }
`;

interface ApiDeleteMobInput {
  id: string;
  propertyId: Property['id'];
}

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Mob }): Promise<void> => {
    const input: ApiDeleteMobInput = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({ query: DELETE_MOB, variables: { input } });
    return;
  };
