import { Sale } from '@mobble/models/src/model/Sale';
import { Property } from '@mobble/models/src/model/Property';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { decodeMob } from './mobs';
import { decodePaddock } from './paddocks';
import { type ApiPrelude, type ExtCursor } from '../types';

export const decodeSales =
  (propertyId: string) =>
  (raw: any[]): Sale[] => {
    return raw.map(decodeSale(propertyId));
  };

export const decodeSale =
  (propertyId: string) =>
  (raw: any): Sale => {
    return {
      id: raw?.id,
      propertyId,
      pricePerHeadCents: raw?.pricePerHeadCents,
      number: raw?.number,
      date: raw?.date,
      deleted: raw?.deleted,
      status: raw?.status,
      notes: raw?.notes,
      soldToId: raw.soldTo.id ?? '',
      soldToName: raw.soldTo.name ?? '',
      createdBy: {
        id: raw?.createdBy?.id ?? '',
        name: raw?.createdBy?.displayName ?? '',
        email: raw?.createdBy?.email ?? '',
      },
      mob: decodeMob(propertyId)(raw?.mob),
      paddock: decodePaddock(raw?.mob.paddock),
    };
  };

const GET_MOB_ACTIONS = `
  query GetSales($propertyId: ID!) {
    sales(propertyId: $propertyId) {
      totalCount
      nodes {
        id
        pricePerHeadCents
        number
        date
        deleted
        status
        notes
        soldTo {
            id
            name
        }
        createdBy {
            id
            displayName
            email
        }
        mob {
          id
          breed
          gender
          type
          DSE
          number
          classes
          ages
          deleted
          sold
          safeDate
          paddock {
              id
              name
              status
          }
        }
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    cursor,
  }: {
    parentId: string;
    cursor?: string;
  }): Promise<{ entities: Sale[]; extCursor: ExtCursor }> => {
    const variables = {
      propertyId: parentId,
      after: cursor,
      pageSize: 9999,
    };

    const response = await prelude.graphql({
      query: GET_MOB_ACTIONS,
      variables,
    });

    const entities = decodeSales(parentId)(response.data.sales.nodes);

    const pageInfo = response?.data?.casualties?.pageInfo;
    const extCursor = {
      total: response?.data?.casualties?.totalCount ?? entities.length,
      nextCursor: pageInfo?.hasNextPage && pageInfo.endCursor,
    };

    return {
      entities,
      extCursor,
    };
  };

const CREATE_SALE = `
  mutation AddSale($input: AddSaleInput!) {
    addSale(input: $input) {
      sale {
        id
        pricePerHeadCents
        number
        date
        deleted
        status
        notes
        soldTo {
          id
          name
        }
        createdBy {
          id
          displayName
          email
        }
        mob {
          id
          breed
          gender
          type
          DSE
          number
          classes
          ages
          deleted
          sold
          safeDate
          paddock {
            id
            name
          }
        }
      }
    }
  }
`;

interface CreateSaleActionInput {
  propertyId: string;
  date: any;
  pricePerHeadCents?: number;
  soldTo: string;
  mobId: string;
  number: number;
  notes: string;
}

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<Sale, 'id'> }): Promise<Sale> => {
    const input: CreateSaleActionInput = {
      propertyId: entity.propertyId,
      date: toISO8601(entity.date),
      pricePerHeadCents: Math.floor(entity.pricePerHeadCents),
      soldTo: entity.soldToName,
      mobId: entity.mob.id,
      number: entity.number,
      notes: entity.notes,
    };

    const response = await prelude.graphql({
      query: CREATE_SALE,
      variables: { input },
    });

    const sale = decodeSale(entity.propertyId)(response.data.addSale.sale);

    return sale;
  };

const EDIT_SALE = `
  mutation EditSale($input: EditSaleInput!) {
    editSale(input: $input) {
      sale {
        id
        pricePerHeadCents
        number
        date
        deleted
        status
        notes
        soldTo {
          id
          name
        }
        createdBy {
          id
          displayName
          email
        }
        mob {
          id
          breed
          gender
          type
          DSE
          number
          classes
          ages
          deleted
          sold
          safeDate
          paddock {
            id
            name
          }
        }
      }
    }
  }
`;

interface EditSaleActionInput {
  id: string;
  propertyId: string;
  date: any | null;
  pricePerHeadCents?: number | null;
  soldTo: string | null;
  mob: string | null;
  number: number | null;
  notes: string | null;
}

export const update =
  (prelude: ApiPrelude) =>
  async ({ updatedEntity }: { updatedEntity: Sale }): Promise<Sale> => {
    const input: EditSaleActionInput = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      date: toISO8601(updatedEntity.date),
      pricePerHeadCents: Math.floor(Number(updatedEntity.pricePerHeadCents)),
      soldTo: updatedEntity.soldToName,
      mob: updatedEntity.mob.id,
      number: Number(updatedEntity.number),
      notes: updatedEntity.notes,
    };

    const response = await prelude.graphql({
      query: EDIT_SALE,
      variables: { input },
    });

    const sale = decodeSale(updatedEntity.propertyId)(
      response.data.editSale.sale
    );

    return sale;
  };

const DELETE_SALE = `
  mutation DeleteSale($input: DeleteSaleInput!) {
    deleteSale(input: $input) {
      sale {
        id
      }
    }
  }
`;

interface ApiDeleteSaleInput {
  id: string;
  propertyId: Property['id'];
}

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Sale }): Promise<void> => {
    const input: ApiDeleteSaleInput = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({ query: DELETE_SALE, variables: { input } });
    return;
  };
