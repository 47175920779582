import {
  type Inventory,
  makeInventoryId,
  inventoryNameToCategory,
  InventoryPropertyReference,
} from '@mobble/models/src/model/Inventory';
import { type ApiPrelude } from '../types';

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{ entities: Inventory[] }> => {
    const localEntities = await apiGetLocalInventories(prelude)(parentId);
    const sharedEntities = await apiGetSharedInventories(prelude)(parentId);

    const allPropertyIds = new Set(
      [...localEntities, ...sharedEntities].map((i) => i.propertyId)
    );

    const shareablePropertiesFromIds = await Promise.all(
      [...allPropertyIds].map(async (id) => ({
        id,
        shareable: await apiGetShareableProperties(prelude)(id),
      }))
    );

    const entities = await Promise.all(
      [...localEntities, ...sharedEntities].map(async (inventory) => {
        const invPropertyId = inventory.propertyId;

        let sharedWith = [];
        try {
          sharedWith = await getInventoryProperties(prelude)({
            propertyId: invPropertyId,
            type: inventory.type,
          });
        } catch (error) {
          // TODO: expose a nice error on the UI
          console.log(
            'Error getting sharedWith for propertyId: ',
            invPropertyId
          );
          console.log(error);
          return {
            ...inventory,
            sharing: [],
          };
        }

        const shareableWith =
          shareablePropertiesFromIds.find((a) => a)?.shareable ?? [];

        const sharing = shareableWith.map((shareable) => ({
          ...shareable,
          selected:
            sharedWith.find((a) => a.propertyId === shareable.propertyId) !==
            undefined,
        }));

        return {
          ...inventory,
          sharing,
        };
      })
    );

    return {
      entities,
    };
  };

const GET_INVENTORIES = `
  query GetInventoryDataSummary($propertyId: ID!) {
    animalHealth: chemicalsCategorized(
      propertyId: $propertyId
      category: "animalHealth"
    ) {
      totalCount
      activeProducts
    }
    herbicidePesticide: chemicalsCategorized(
      propertyId: $propertyId
      category: "herbicidePesticide"
    ) {
      totalCount
      activeProducts
    }
    feed: feeds(propertyId: $propertyId) {
      totalCount
      activeProducts
    }
  }
`;

const inventoryTypeToName = (type: string): string => {
  switch (type) {
    case 'feed':
      return 'Feed, Grain & Storage';
    case 'herbicidePesticide':
      return 'Herbicides, Pesticides & Fertilisers';
    case 'animalHealth':
      return 'Animal Health';
    default:
      return type;
  }
};

export const decodeInventories =
  (propertyId: string) =>
  (data: Record<string, any>): Inventory[] => {
    const names = Object.keys(data);

    return names.map((name) =>
      decodeInventory(propertyId)({ ...data[name], name })
    );
  };

export const decodeInventory =
  (propertyId: string) =>
  (data?: any): Inventory => {
    const type = data?.name;
    return {
      propertyId,
      id: makeInventoryId({ propertyId, type }),
      type,
      // TODO: strings should not be defined here
      name: inventoryTypeToName(data?.name ?? ''),
      category: inventoryNameToCategory(data?.name ?? ''),

      sharing: [],
    };
  };

export const apiGetLocalInventories =
  (prelude: ApiPrelude) =>
  async (propertyId: string): Promise<Inventory[]> => {
    const response = await prelude.graphql({
      query: GET_INVENTORIES,
      variables: { propertyId },
    });
    return decodeInventories(propertyId)(response?.data || {});
  };

//

const GET_SHARED_INVENTORIES = `
  query GetSharedInventories($propertyId: ID!) {
    sharedInventories(propertyId: $propertyId) {
      nodes {
        inventoryName
        propertyId
        propertyName
        type
      }
    }
  }
`;

export const decodeSharedInventories =
  (propertyId: string) =>
  (data: any[]): Inventory[] =>
    data.map(decodeSharedInventory(propertyId));

export const decodeSharedInventory =
  (propertyId: string) =>
  (data?: any): Inventory => ({
    propertyId,
    id: makeInventoryId({
      propertyId,
      type: data?.type,
      sharedPropertyId: data?.propertyId,
    }),
    type: data?.type,
    name: data?.inventoryName ?? '',
    category: inventoryNameToCategory(data?.type ?? ''),
    shared: {
      propertyName: data?.propertyName ?? '',
      propertyId: data?.propertyId ?? '',
    },
    sharing: [],
  });

export const apiGetSharedInventories =
  (prelude: ApiPrelude) =>
  async (propertyId: string): Promise<Inventory[]> => {
    const response = await prelude.graphql({
      query: GET_SHARED_INVENTORIES,
      variables: {
        propertyId,
      },
    });
    return decodeSharedInventories(propertyId)(
      response?.data?.sharedInventories?.nodes || []
    );
  };

//

const GET_SHAREABLE_PROPERTIES = `
  query GetSharableProperties($id: ID!) {
    sharablePropertiesWithinOrg(id: $id) {
      nodes {
        id
        name
      }
    }
  }
`;

const decodeShareableProperties = (data: any[]): InventoryPropertyReference[] =>
  data.map(decodeShareableProperty);

const decodeShareableProperty = (data?: any): InventoryPropertyReference => ({
  propertyId: data?.id ?? '',
  propertyName: data?.name ?? '',
});

export const apiGetShareableProperties =
  (prelude: ApiPrelude) =>
  async (propertyId: string): Promise<InventoryPropertyReference[]> => {
    const response = await prelude.graphql({
      query: GET_SHAREABLE_PROPERTIES,
      variables: {
        id: propertyId,
      },
    });

    const sharableProperties = decodeShareableProperties(
      response?.data?.sharablePropertiesWithinOrg?.nodes || []
    );

    return sharableProperties;
  };

//
const decodeInventoryProperties = (data: any[]): InventoryPropertyReference[] =>
  data.map(decodeInventoryProperty);

const decodeInventoryProperty = (data?: any): InventoryPropertyReference => ({
  propertyId: data?.propertyId ?? '',
  propertyName: data?.propertyName ?? '',
});

export const GET_INVENTORY_PROPERTIES = `
  query GetInventoryProperties($propertyId: ID!, $type: String!) {
    inventoryProperties(propertyId: $propertyId, type: $type) {
      totalCount
      nodes {
        propertyId
        propertyName
        type
      }
    }
  }
`;
export const getInventoryProperties =
  (prelude: ApiPrelude) =>
  async ({
    propertyId,
    type,
  }: {
    propertyId: string;
    type: string;
  }): Promise<InventoryPropertyReference[]> => {
    const response = await prelude.graphql({
      query: GET_INVENTORY_PROPERTIES,
      variables: {
        propertyId,
        type,
      },
    });

    return decodeInventoryProperties(
      response?.data?.inventoryProperties?.nodes
    );
  };

// Update
export const update =
  (prelude: ApiPrelude) =>
  async ({
    updatedEntity,
  }: {
    updatedEntity: Inventory;
  }): Promise<Inventory> => {
    updatedEntity.sharing.forEach(async (sharing) => {
      const input = {
        propertyId: updatedEntity.propertyId,
        type: updatedEntity.type,
        sharablePropertyId: sharing.propertyId,
      };
      if (sharing.selected) {
        await apiShareInventory(prelude)(input);
      } else {
        await apiRemoveShareInventory(prelude)(input);
      }
    });

    return updatedEntity;
  };

interface AddShareInventoryInput {
  propertyId: string;
  sharablePropertyId: string;
  type: string;
}

const SHARE_INVENTORY = `
  mutation ShareInventory($input: ShareInventoryInput!) {
    shareInventory(input: $input) {
      sharedInventory {
        propertyId
        propertyName
        type
        inventoryName
      }
    }
  }
`;

interface RemoveShareInventoryInput {
  propertyId: string;
  sharablePropertyId: string;
  type: string;
}

const UNSHARE_INVENTORY = `
  mutation UnshareInventory($input: UnShareInventoryInput!) {
    unShareInventory(input: $input) {
      sharedInventory {
        propertyId
        propertyName
        type
        inventoryName
      }
    }
  }
`;

const apiShareInventory =
  (prelude: ApiPrelude) => async (input: AddShareInventoryInput) => {
    const response = await prelude.graphql({
      query: SHARE_INVENTORY,
      variables: { input },
    });
    return response;
  };

const apiRemoveShareInventory =
  (prelude: ApiPrelude) => async (input: RemoveShareInventoryInput) => {
    const response = await prelude.graphql({
      query: UNSHARE_INVENTORY,
      variables: { input },
    });
    return response;
  };
