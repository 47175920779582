import {
  proxyUseEntities,
  proxyUseEntity,
} from '../reducers/paddockGroupedStockingRate';
import { useRootSelector, useRootDispatch } from './root';

export const usePaddockGroupedStockingRates = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddockGroupedStockingRate);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const usePaddockGroupedStockingRate = (paddockId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddockGroupedStockingRate);

  return proxyUseEntity({
    entityId: paddockId ?? '',
    dispatch,
    state,
  });
};
