import React from 'react';
import { Box, BoxProps } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import styles from './inputError.scss';

interface InputErrorProps extends BoxProps {
  error?: false | string | string[] | Record<string, string>;
  size?: 'small' | 'normal';
}

const flatten = (value: any): string[] => {
  const flattenedValues: string[] = [];

  if (typeof value === 'string') {
    flattenedValues.push(value);
  } else if (Array.isArray(value)) {
    flattenedValues.push(...(value.map(flatten).concat() as any));
  } else if (typeof value === 'object') {
    for (const val of Object.values(value)) {
      flattenedValues.push(...flatten(val));
    }
  }

  return flattenedValues;
};

export const InputError: React.FC<InputErrorProps> = ({
  error,
  size = 'normal',
  ...delegated
}) => {
  return (
    <Box role="alert" className={styles.inputError} {...delegated}>
      {error && (
        <Text variant={size === 'normal' ? 'body' : 'small'} tagName="p">
          {flatten(error).join(', ')}
        </Text>
      )}
    </Box>
  );
};
