import { makeQuantityFormatMessageItem, useI18n } from '@mobble/i18n/src';
import { InventoryCategory, InventoryItemBatch } from '@mobble/models/src';

export const useGetBatchName = (batch?: InventoryItemBatch) => {
  const { formatMessage } = useI18n();

  if (batch) {
    const quantity = formatMessage(
      ...makeQuantityFormatMessageItem(batch.quantity)
    );
    if (batch.category === InventoryCategory.Chemicals) {
      return batch.name;
    }
    if (batch.category === InventoryCategory.Feed) {
      return `${batch.supplierName} (${quantity})`;
    }
  }

  return formatMessage({
    description: 'Untitled batch name fallback text',
    defaultMessage: 'Untitled batch',
  });
};
