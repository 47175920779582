import { toContrastingTextColor } from '@mobble/shared/src/core/ColorUtils';

export enum Color {
  AlmostWhite = 'almost-white',
  Black = 'black',
  Blue = 'blue',
  DarkGrey = 'dark-grey',
  Green = 'green',
  Grey = 'grey',
  GreyGreen = 'grey-green',
  LightBlue = 'light-blue',
  LightGreen = 'light-green',
  LightGrey = 'light-grey',
  Lilac = 'lilac',
  Orange = 'orange',
  Purple = 'purple',
  Red = 'red',
  WashedRed = 'washed-red',
  White = 'white',
  WhiteBlue = 'white-blue',
  Yellow = 'yellow',
}

export const colorToHex = (color: string | Color, alpha?: number): string => {
  const hex = (() => {
    if (color.startsWith('#') || color.startsWith('var')) {
      return color;
    }
    switch (color) {
      case Color.AlmostWhite:
        return '#F1F1F1';
      case Color.Black:
        return '#121212';
      case Color.Blue:
        return '#2C74F9';
      case Color.LightBlue:
        return '#3450E3';
      case Color.WhiteBlue:
        return '#2dc7ee';
      case Color.DarkGrey:
        return '#606060';
      case Color.LightGreen:
        return '#81C784';
      case Color.Green:
        return '#009966';
      case Color.GreyGreen:
        return '#9fb8b0';
      case Color.Grey:
        return '#8E8E93';
      case Color.LightGrey:
        return '#D8D8D8';
      case Color.WashedRed:
        return '#EE5C5C';
      case Color.Red:
        return '#F4333C';
      case Color.White:
        return '#FFFFFF';
      case Color.Lilac:
        return '#9575CD';
      case Color.Purple:
        return '#5E35B1';
      case Color.Orange:
        return '#FFA000';
      case Color.Yellow:
        return '#ffd000';
      default:
        return '#FFFFFF';
    }
  })();

  if (alpha) {
    return `${hex}${Math.round(alpha * 255).toString(16)}`;
  }
  return hex;
};

export const contrastingTextColor = (color: string | Color): Color => {
  return toContrastingTextColor(colorToHex(color), Color.White, Color.Black);
};
