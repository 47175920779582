import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPlural } from '@formatjs/intl-pluralrules/should-polyfill';
import { loadDateLocale } from '@mobble/shared/src/core/Date';

export const i18nLanguages = [
  {
    name: 'English (Australia)',
    code: 'en-au',
  },
  {
    name: 'Spanish',
    code: 'es',
  },
  {
    name: 'Spanish (Argentina)',
    code: 'es-ar',
  },
  {
    name: 'Spanish (Chile)',
    code: 'es-cl',
  },
  {
    name: 'Spanish (Uruguay)',
    code: 'es-uy',
  },
] as const;

export type I18nLocale = (typeof i18nLanguages)[number]['code'];

export type I18nLocaleOption = {
  code: I18nLocale;
  name: string;
  rtl?: boolean;
};

export const i18nLocales: I18nLocale[] = i18nLanguages.map((l) => l.code);

export const loadLocaleData = async (locale: I18nLocale) => {
  loadDateLocale(locale);
  await loadPolyfill(locale);

  switch (locale) {
    case 'es':
      return import('../../locale/es.json');
    case 'es-ar':
      return import('../../locale/es-ar.json');
    case 'es-cl':
      return import('../../locale/es-cl.json');
    case 'es-uy':
      return import('../../locale/es-uy.json');
    default:
      return import('../../locale/en-au.json');
  }
};

async function loadPolyfill(locale: string) {
  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }

  const unsupportedLocale = shouldPolyfillPlural(locale);
  if (unsupportedLocale) {
    await import('@formatjs/intl-pluralrules/polyfill');

    switch (unsupportedLocale) {
      case 'es':
        await import('@formatjs/intl-pluralrules/locale-data/es');
        break;
      default:
        await import('@formatjs/intl-pluralrules/locale-data/en');
        break;
    }
  }
  return true;
}
