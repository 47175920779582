import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import {
  queueActionIsNotSimilar,
  type QueueAction,
} from '@mobble/models/src/model/QueueAction';
import { replayableThunks } from '../config/replayableThunks';
import { RequestErrors } from '@mobble/shared/src/core/Error';

export interface QueueState {
  actions: QueueAction[];
  lastRejectedReason?: string;
}

export const defaultState: QueueState = {
  actions: [],
  lastRejectedReason: undefined,
};

const slice = createSlice({
  name: 'actionQueue',
  initialState: defaultState,
  reducers: {
    flush: () => {
      return defaultState;
    },

    addToQueue: (state, action: PayloadAction<QueueAction>) => {
      state.actions = state.actions.filter(
        queueActionIsNotSimilar(action.payload)
      );

      state.actions.push(action.payload);
    },

    removeFromQueue: (state, action: PayloadAction<string>) => {
      state.actions = state.actions.filter((a) => a.id !== action.payload);
    },
  },
  extraReducers(builder) {
    replayableThunks.map((thunk) => {
      builder.addCase(thunk.pending, (state, action) => {
        const queueActionId = action.meta.arg.queueActionId;
        const queueActionIndex = state.actions.findIndex(
          (a) => a.id === queueActionId
        );
        if (queueActionIndex !== -1) {
          state.actions[queueActionIndex].status = 'pending';
        }
      });
      builder.addCase(thunk.fulfilled, (state, action) => {
        const queueActionId = action.meta.arg.queueActionId;
        const queueActionIndex = state.actions.findIndex(
          (a) => a.id === queueActionId
        );
        if (queueActionIndex !== -1) {
          state.actions[queueActionIndex].status = 'fulfilled';
        }
      });
      builder.addCase(thunk.rejected, (state, action) => {
        const errorType = (action.error as any)?.message?.toLowerCase();

        const queueActionId = action.meta.arg.queueActionId;
        const queueActionIndex = state.actions.findIndex(
          (a) => a.id === queueActionId
        );
        if (queueActionIndex !== -1) {
          state.actions[queueActionIndex].status = 'error';
          state.actions[queueActionIndex].error = errorType;
        }

        switch (errorType) {
          case RequestErrors.NetworkError:
            state.lastRejectedReason = RequestErrors.NetworkError;
            break;
          case RequestErrors.Forbidden:
            state.lastRejectedReason = RequestErrors.SubscriptionExpired;
            break;
          case RequestErrors.RequestFailed:
            state.lastRejectedReason = RequestErrors.RequestFailed;
            break;
          default:
            console.log('Unhandled error type:', errorType);
            console.log(action.error);
            break;
        }
      });
    });
  },
});

export const { flush, addToQueue, removeFromQueue } = slice.actions;
export const { reducer } = slice;
