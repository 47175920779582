import React from 'react';
import { useI18n } from '@mobble/i18n';
import { NaturalIncrease } from '@mobble/models/src/model/NaturalIncrease';
import { useExtStatus } from '@mobble/store/src/hooks/root';

import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';

import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import {
  NaturalIncreaseEditForm,
  NaturalIncreaseEditFormValues,
} from '@src/stories/Views/NaturalIncrease/NaturalIncreaseEditForm';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

import {
  useNaturalIncreaseScreen,
  useNaturalIncreasesScreenHeader,
} from './useNaturalIncreasesScreen';
import { useFormError } from '@src/hooks/useFormError';

export const NaturalIncreaseEditHeader: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const { naturalIncreaseId } = route.params;
  const props = useNaturalIncreasesScreenHeader(naturalIncreaseId, true);

  return <ScreenHeader {...props} />;
};

export const NaturalIncreaseEdit: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const { naturalIncreaseId } = route.params;

  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const goBack = useNavigateBack();

  const { prelude, paddocks, properties, naturalIncrease } =
    useNaturalIncreaseScreen(naturalIncreaseId);

  const extStatus = useExtStatus(
    'naturalIncreases',
    'update',
    naturalIncreaseId
  );
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus(
    'naturalIncreases',
    'delete',
    naturalIncreaseId
  );
  const deleteLoading = extStatusDelete?.loading;
  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Natural increase',
      description: 'screen.title.natural_increase_detail',
    }),
    active: Boolean(extStatus?.error || extStatusDelete?.error),
  });

  if (prelude) {
    return prelude;
  }

  const handleSubmit = (formValues: NaturalIncreaseEditFormValues) => {
    if (!naturalIncrease.entity || !properties.selected) {
      return;
    }

    const updatedNaturalIncrease: NaturalIncrease = {
      ...naturalIncrease.entity,
      mob: {
        ...naturalIncrease.entity.mob,
        size: Number(formValues.number),
      },
      date: formValues.date,
      notes: formValues.note,
    };

    naturalIncrease
      .update(updatedNaturalIncrease)
      .then(() => {
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    naturalIncrease
      .remove()
      .then(() => {
        linkTo(ROUTE_NAME.SUMMARY_NATURAL_INCREASES_LIST);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <NaturalIncreaseEditForm
        paddocks={paddocks.entities}
        naturalIncrease={naturalIncrease.entity}
        propertyTypes={properties.selected?.types}
        error={formError}
        loading={formLoading}
        onCancel={goBack}
        onSubmit={handleSubmit}
      />
      <DeleteForm
        i18nRootKey="naturalIncreases.naturalIncrease.delete"
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.NATURAL_INCREASE_EDIT,
  header: NaturalIncreaseEditHeader,
  component: NaturalIncreaseEdit,
};
