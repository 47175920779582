import React from 'react';
import { useI18n } from '@mobble/i18n';
import { Property } from '@mobble/models/src/model/Property';
import { checkUserCanEditRole, UserRole } from '@mobble/models/src/model/User';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { useLinking } from '@src/hooks/useLinking';

import { useProperties } from '@mobble/store/src/hooks/properties';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { SettingsPropertyUserInfoTable } from '@src/stories/Views/Settings/SettingsPropertyUserInfoTable';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { ScreenRendererProps } from '@src/screens/config/types';
import { SettingsPropertyUserOperations } from '@src/stories/Views/Settings/SettingsPropertyUserOperations';
import { toPath } from '@src/interfaces/Routing';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';

export const SettingsPropertyUserUserEditHeader: React.FC<
  ScreenRendererProps
> = ({ route }) => {
  const { userId } = route.params as any;
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const linkTo = useLinking();

  const users = properties.selected.users;
  const user = properties.selected?.users.find((a) => a.id === userId);

  const quickLinks = users
    .map((p) => ({
      title: p.name,
      href: toPath(ROUTE_NAME.SETTINGS_PROPERTY_USERS_USER_EDIT, {
        userId: p.id,
      }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <ScreenHeader
      title={formatMessage(
        {
          description: 'screen.title.settings_property_users_user_edit',
          defaultMessage: 'User "{NAME}"',
        },
        {
          NAME: user?.name ?? '',
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.settings_property_users',
            defaultMessage: 'Users',
          }),
          href: ROUTE_NAME.SETTINGS_PROPERTY_USERS,
        },
      ]}
      quickLinks={quickLinks}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_PROPERTY_USERS);
      }}
    />
  );
};

export const SettingsPropertyUserUserEdit: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const { userId } = route.params;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const linkTo = useLinking();
  const me = useGetUser();
  const [loading, setLoading] = React.useState(false);

  useEntitiesRefresher([properties], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected, me],
  });

  if (Prelude) {
    return Prelude;
  }

  const property = properties.selected as Property;
  const user = property.users.find((a) => a.id === userId);

  const userCanEditRole =
    me?.role && me?.role !== UserRole.User && user?.role
      ? checkUserCanEditRole(me.role)(user.role)
      : null;

  if (!user) {
    linkTo(ROUTE_NAME.SETTINGS_PROPERTY_USERS);
    return null;
  }

  const handleChangeRole = (role: UserRole) => {
    setLoading(true);
    properties
      .editUser({
        propertyIds: [property.id],
        organisationId: property.organisationId,
        email: user.email,
        role,
      })
      .then(() => {
        setLoading(false);
      });
  };

  const handleRemove = () => {
    setLoading(true);
    properties
      .deleteUser({
        propertyIds: [property.id],
        organisationId: property.organisationId,
        email: user.email,
      })
      .then(() => {
        setLoading(false);
        linkTo(ROUTE_NAME.SETTINGS_PROPERTY_USERS);
      });
  };

  return (
    <>
      <SettingsPropertyUserInfoTable user={user} />
      {userCanEditRole && (
        <SettingsPropertyUserOperations
          loading={loading}
          user={user}
          onRemove={handleRemove}
          onChangeRole={handleChangeRole}
        />
      )}
    </>
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_PROPERTY_USERS_USER_EDIT,
  header: SettingsPropertyUserUserEditHeader,
  component: SettingsPropertyUserUserEdit,
};
