import { useRootSelector } from './root';

export const useNetwork = () => useRootSelector((state) => state.network);

export const useNetworkIsOnline = () => {
  const network = useNetwork();
  if (network.offlineMode) {
    return false;
  }
  return network.connected;
};
