export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  trialing = 'trialing',
  unpaid = 'unpaid',
}

export interface MobbleSubscription {
  id: string;
  status: SubscriptionStatus;
  subscriptionName: string;
  priceID: string;
  productID: string;
  maxUsers: number;
  maxProperties: number;
  billingAmount: number;
  billingInterval: string;
  currentPeriodEnd: string;
  currentPeriodStart: string;
  endedAt: string;
  cancelAt: string;
}

export interface StripeProduct {
  id: string;
  name: string;
  description: string;
  prices: StripePrice[];
}

export interface StripePrice {
  id: string;
  unitAmount: number;
  interval: string;
  maxUsers: number;
  maxProperties: number;
  canPurchase: boolean;
}

export const getProductValues = (product: StripeProduct) => ({
  name: product.name,
  price: product.prices[0].unitAmount / 100,
  interval: product.prices[0].interval,
  canPurchase: product.prices[0].canPurchase,
  description: product.description,
});

export const findActiveSubscription = (subscriptions: MobbleSubscription[]) =>
  subscriptions.find(
    (sub) =>
      sub.status === SubscriptionStatus.active ||
      sub.status === SubscriptionStatus.trialing
  );

export const findLatestSubscription = (subscriptions: MobbleSubscription[]) =>
  subscriptions.reduce((acc, curr) => {
    if (new Date(curr.currentPeriodEnd) > new Date(acc.currentPeriodEnd)) {
      return curr;
    }
    return acc;
  });
