import { type ProductApplication } from '@mobble/models/src/model/ProductApplication';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { type FilterPerProperty } from '../lib/filter';

export interface ProductApplicationsState {
  extStatus: ExtStatusPerActionPerParent;
  entities: ProductApplication[];
  filter: FilterPerProperty;
  sort: {};
}

export const defaultState: ProductApplicationsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<ProductApplicationsState>({
  name: 'productApplications',
  initialState: defaultState,
  toParentId: ({ propertyId, productId, inventoryType }: ProductApplication) =>
    `${propertyId}_${productId}_${inventoryType}`,
  onGetAll: mobbleService.api.productApplications.get,
  onCreate: () => Promise.reject(),
  onUpdate: () => Promise.reject(),
  onDelete: () => Promise.reject(),
});

export const {
  thunkGetAll,
  //
  proxyUseFilter,
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;

export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
