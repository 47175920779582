import {
  findPreference,
  findPreferenceValue,
  type Preference,
  type PreferenceValue,
} from '@mobble/models/src/model/Preference';
import { useRootDispatch, useRootSelector } from './root';
import { removePreference, updatePreference } from '../reducers/preferences';

export interface UsePreferencesResponse {
  preferences: Preference[];
  update: (preference: Preference) => void;
  find: (key: string) => PreferenceValue;
}

export const usePreferences = (propertyId?: string): UsePreferencesResponse => {
  const dispatch = useRootDispatch();
  const entities = useRootSelector((s) => s.preferences.entities);
  const preferences = propertyId
    ? entities.filter((e) => !e.propertyId || e.propertyId === propertyId)
    : entities;

  const update = (p: Preference) => {
    dispatch(updatePreference(p));
  };

  const find = (key: string) =>
    findPreferenceValue(preferences)(key, propertyId);

  return {
    preferences,
    update,
    find,
  };
};

export interface UsePreferenceResponse {
  preference: undefined | Preference;
  value: PreferenceValue;
  update: (value: PreferenceValue) => void;
  remove: () => void;
}

export const usePreference = (
  key: string,
  propertyId?: string
): UsePreferenceResponse => {
  const preferences = usePreferences(propertyId).preferences;
  const dispatch = useRootDispatch();
  const preference = findPreference(preferences)(key, propertyId);
  const value = preference?.value ?? null;

  const update = (value: PreferenceValue) => {
    dispatch(
      updatePreference({
        propertyId,
        key,
        value,
      })
    );
  };

  const remove = () => {
    preference && dispatch(removePreference(preference));
  };

  return {
    preference,
    value,
    update,
    remove,
  };
};
