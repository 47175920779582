import omit from 'lodash/omit';
import {
  makeQuantityOfArea,
  areaUnits,
  type QuantityOfArea,
  convertArea,
} from '@mobble/shared/src/core/Quantity';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { type LatLng } from '@mobble/models/src/model/MapGeometry';
import { type HistoricEvent } from '@mobble/models/src/model/HistoricEvent';
import { type ApiPrelude, type ExtCursor } from '../types';

// import { makeResponseApiGetHistoricEventsForPaddock } from './historic-events';

// const GET_PADDOCKS_ORIG = `
//   query GetPaddocks($propertyId: ID!) {
//     paddocks(propertyId: $propertyId) {
//       nodes {
//         id
//         name
//         type
//         status
//         propertyId
//         averageAreaStockingRate
//         safeDate

//         lastGrazingHistory {
//           type
//           date
//         }

//         size {
//           unit
//           value
//         }

//         mobs {
//           nodes {
//             id
//           }
//         }

//         events {
//           totalCount
//           pageInfo {
//             hasNextPage
//             endCursor
//           }
//           nodes {
//             id
//             eventType
//             date
//             title
//             description
//             createdBy {
//               id
//               displayName
//               email
//             }
//             linksTo {
//               type
//               id
//             }
//           }
//         }
//       }
//     }
//   }
// `;

const GET_PADDOCKS = `
  query GetPaddocks($propertyId: ID!) {
    paddocks(propertyId: $propertyId) {
      nodes {
        id
        name
        type
        status
        propertyId
        averageAreaStockingRate
        safeDate

        lastGrazingHistory {
          type
          date
        }

        size {
          unit
          value
        }

        mobs {
          nodes {
            id
          }
        }
      }
    }
  }
`;

export const decodePaddocks = (raw: any[]): Paddock[] => {
  return raw.map(decodePaddock);
};

export const decodePaddock = (raw: any): Paddock => {
  return {
    id: raw?.id ?? '',
    propertyId: raw?.propertyId ?? '',
    name: raw?.name ?? '',
    type: raw?.type ?? '',
    status: raw?.status ?? '',
    mobs: (raw?.mobs?.nodes ?? []).map((m: any) => m?.id).filter(Boolean),

    properties: {
      safeDate: raw?.safeDate ?? undefined,
      averageAreaStockingRate: raw?.averageAreaStockingRate,
      lastGrazingHistory:
        raw?.lastGrazingHistory?.type && raw?.lastGrazingHistory?.date
          ? {
              type: raw.lastGrazingHistory.type,
              date: raw.lastGrazingHistory.date,
            }
          : undefined,

      size: decodeAreaSize(raw?.size),
    },
  };
};

const decodeAreaSize = (raw: any): undefined | QuantityOfArea => {
  const rawUnit = raw?.unit?.toLowerCase();
  const rawValue = raw?.value;

  if (typeof rawUnit !== 'undefined' && typeof rawValue !== 'undefined') {
    const value = parseFloat(rawValue) ?? 0;
    if (areaUnits.includes(rawUnit)) {
      return makeQuantityOfArea(rawUnit, value);
    }
    return makeQuantityOfArea('ha', value);
  }
};

const decodeShape = (raw: any): undefined | LatLng[] => {
  try {
    return raw[0]
      ?.map((coord: any) => {
        return {
          latitude: coord[1],
          longitude: coord[0],
        };
      })
      .filter(Boolean);
  } catch (e) {
    return undefined;
  }
};

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{
    entities: Paddock[];
    historicEvents: {
      paddockId: string;
      entities: HistoricEvent[];
      extCursor: ExtCursor;
    }[];
  }> => {
    const response = await prelude.graphql({
      query: GET_PADDOCKS,
      variables: { propertyId: parentId },
    });
    const entities = decodePaddocks(response?.data?.paddocks?.nodes ?? []);

    // const historicEvents = (response?.data?.paddocks?.nodes ?? []).map(
    //   (node: any) => {
    //     return {
    //       paddockId: node.id,
    //       ...makeResponseApiGetHistoricEventsForPaddock(
    //         parentId,
    //         node.id
    //       )(node.events),
    //     };
    //   }
    // );
    const historicEvents: any[] = [];

    return { entities, historicEvents };
  };

const GET_PADDOCK = `
  query GetPaddock($propertyId: ID!, $id: ID!) {
    paddock(propertyId: $propertyId, id: $id) {
      id
      name
      type
      status
      propertyId
      averageAreaStockingRate
      safeDate

      lastGrazingHistory {
        type
        date
      }

      size {
        unit
        value
      }

      mobs {
        nodes {
          id
        }
      }
    }
  }
`;

export const find =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    id,
  }: {
    parentId: string;
    id: Paddock['id'];
  }): Promise<Paddock> => {
    const response = await prelude.graphql({
      query: GET_PADDOCK,
      variables: { propertyId: parentId, id },
    });

    const paddock = decodePaddock(response?.data?.paddock || []);

    return paddock;
  };

export const CREATE_PADDOCK = `
  mutation AddPaddock($input: AddPaddockInput!) {
    addPaddock(input: $input) {
      paddock {
        id
        name
        type
        status
        propertyId
        averageAreaStockingRate
        safeDate

        lastGrazingHistory {
          type
          date
        }

        size {
          unit
          value
        }
      }
    }
  }
`;

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<Paddock, 'id'> }): Promise<Paddock> => {
    const input = {
      propertyId: entity.propertyId,
      name: entity.name,
      type: entity.type,
      size: entity.properties.size
        ? omit(convertArea('ha')(entity.properties.size), 'type')
        : null,
      geometry: entity.geometry?.polygon,
    };

    const response = await prelude.graphql({
      query: CREATE_PADDOCK,
      variables: { input },
    });

    const result = response?.data?.addPaddock?.paddock;
    const paddock = decodePaddock(result);

    return paddock;
  };

const UPDATE_PADDOCK = `
  mutation EditPaddock($input: EditPaddockInput!) {
    editPaddock(input: $input) {
      paddock {
        id
        name
        type
        status
        propertyId
        averageAreaStockingRate
        safeDate

        lastGrazingHistory {
          type
          date
        }

        size {
          unit
          value
        }
      }
    }
  }
`;

export const update =
  (prelude: ApiPrelude) =>
  async ({ updatedEntity }: { updatedEntity: Paddock }): Promise<Paddock> => {
    const input = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      name: updatedEntity.name,
      type: updatedEntity.type,
      size: updatedEntity.properties.size
        ? omit(convertArea('ha')(updatedEntity.properties.size), 'type')
        : null,
      geometry: updatedEntity.geometry?.polygon,
    };

    const response = await prelude.graphql({
      query: UPDATE_PADDOCK,
      variables: { input },
    });
    const result = response?.data?.editPaddock?.paddock;

    const paddock = decodePaddock(result);

    return paddock;
  };

const DELETE_PADDOCK = `
  mutation DeletePaddock($input: DeletePaddockInput!) {
    deletePaddock(input: $input) {
      paddock {
        id
      }
    }
  }
`;

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Paddock }): Promise<void> => {
    const input = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({ query: DELETE_PADDOCK, variables: { input } });
    return;
  };
