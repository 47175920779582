import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/mobs';

export const useMobs = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.mobs);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const useMob = (mobId: string, propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.mobs);

  return proxyUseEntity({
    entityId: mobId,
    parentId: propertyId,
    dispatch,
    state,
  });
};
