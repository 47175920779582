import React from 'react';
import classNames from 'classnames/bind';

import { Color } from '@mobble/theme';

import { PolymorphicComponent } from '@src/@types/polymorphic';

import styles from './Text.scss';
const cx = classNames.bind(styles);

const DEFAULT_TAG = 'span' as const;

export type TextVariants =
  | 'ultra-tiny'
  | 'tiny'
  | 'small'
  | 'body'
  | 'larger'
  | 'screen-title'
  | 'card-title'
  | 'jumbo'
  | 'mono'
  | 'mono-tiny';

export interface TextProps {
  /**
   * Sets the text style
   */
  variant?: TextVariants;

  /**
   * Sets `text-align` CSS property
   */
  align?: 'left' | 'center' | 'right';

  /**
   * Sets `font-weight` CSS property
   */
  bold?: boolean;

  /**
   * Sets `color` CSS property
   */
  color?: Color | string;

  /**
   * Sets `box-shadow` CSS property
   */
  shadow?: boolean;

  /**
   * Sets `text-decoration` CSS property
   */
  underline?: boolean;

  /**
   * Sets `white-space: pre-wrap` CSS property
   */
  wrap?: boolean;
}

/**
 * All text content should be wrapped in a `<Text />` component.
 *
 * A `tagName` should be supplied to the component. This will determine the HTML element that is rendered.
 *
 * All HTML attributes are supported and will be passed through to the rendered element.
 */
const Text: PolymorphicComponent<TextProps, typeof DEFAULT_TAG> = ({
  align = 'left',
  bold,
  children,
  color,
  shadow,
  tagName: TagName = DEFAULT_TAG,
  underline,
  variant = 'body',
  wrap,
  className,
  style,
  ...others
}) => {
  const rootClasses = cx(
    {
      Text: true,
      [`align-${align}`]: align,
      [`variant-${variant}`]: variant,
      bold: bold,
      shadow: shadow,
      underline: underline,
      wrap: wrap,
    },
    className
  );

  const styleProp = {
    color,
    ...style,
  };

  return (
    <TagName className={rootClasses} style={styleProp} {...others}>
      {children}
    </TagName>
  );
};

export default Text;
