import React, { ComponentPropsWithoutRef } from 'react';
import { Icon, IconName } from '@mobble/icon';
import classNames from 'classnames/bind';
import styles from './Alert.scss';
const cx = classNames.bind(styles);

export type AlertVariant = 'info' | 'warning' | 'error' | 'success';
export type AlertSize = 'large' | 'normal';

export interface AlertProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * Set the visibility of the alert, defaults to `true`
   */
  open?: boolean;

  /**
   * The type of the alert, defaults to `info`
   */
  variant?: AlertVariant;

  /**
   * The title of the alert
   */
  title?: string;

  /**
   * The message of the alert
   */
  message?: string;
}

const getIcon = (variant: AlertVariant): IconName => {
  switch (variant) {
    case 'success':
      return 'check-circle';
    case 'warning':
      return 'alert-triangle';
    case 'error':
      return 'error';
    case 'info':
    default:
      return 'info';
  }
};

/**
 * Alerts are used to display important messages inline.
 */
const Alert: React.FC<AlertProps> = ({
  open = true,
  variant = 'info',
  title,
  message,
  children,
  className,
  ...others
}) => {
  const rootClasses = cx(
    {
      Alert: true,
      [variant]: true,
    },
    className
  );
  const icon = getIcon(variant);

  const alertAttrs: {
    role: string;
    'aria-live': React.AriaAttributes['aria-live'];
  } = {
    role: 'alert',
    ...(variant === 'info' || variant === 'success'
      ? { 'aria-live': 'polite' }
      : { 'aria-live': 'assertive' }),
  };

  return (
    <div className={rootClasses} {...alertAttrs} {...others}>
      {open && (
        <div className={styles.wrapper}>
          <Icon name={icon} />

          {!children ? (
            <div className={styles.contentWrapper}>
              <h1>{title}</h1>
              <p>{message}</p>
            </div>
          ) : (
            <div>{children}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Alert;
