import mapboxgl from 'mapbox-gl';

export const safeAddSource =
  (map?: mapboxgl.Map) => (id: string, source: mapboxgl.AnySourceData) => {
    try {
      if (!map?.getSource(id)) {
        map?.addSource(id, source);
      }
    } catch (_) {}
  };

export const safeRemoveSource = (map?: mapboxgl.Map) => (id: string) => {
  try {
    if (map?.getSource(id)) {
      map?.removeSource(id);
    }
  } catch (_) {}
};

export const safeAddLayer =
  (map?: mapboxgl.Map) => (layer: mapboxgl.AnyLayer, beforeId?: string) => {
    try {
      if (!layer.id || !map?.getLayer(layer.id)) {
        map?.addLayer(layer, beforeId);
      }
    } catch (_) {}
  };

export const safeRemoveLayer = (map?: mapboxgl.Map) => (id: string) => {
  if (!map) {
    return;
  }
  try {
    if (map?.getLayer(id)) {
      map?.removeLayer(id);
    }
  } catch (_) {}
};
