import React from 'react';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { CustomMapLayer } from '@mobble/models/src';

export interface CustomMapLayerEditFormProps {
  customMapLayer: CustomMapLayer;
  loading?: boolean;
  error?: string;
  onCancel: () => void;
  onSubmit: (formValues: CustomMapLayerEditFormValues) => void;
}

export interface CustomMapLayerEditFormValues {
  name: string;
  description?: string;
  color?: string;
}

export const CustomMapLayerEditForm: React.FC<CustomMapLayerEditFormProps> = ({
  customMapLayer,
  loading,
  error,
  onCancel,
  onSubmit,
}) => {
  const form: FormBuilderProps<CustomMapLayerEditFormValues> = {
    flex: true,
    i18nRootKey: 'custom-map-layer.edit.form',
    fields: [
      {
        name: 'name',
        type: 'text',
        initialValue: customMapLayer.name,
        required: true,
      },
      {
        name: 'description',
        type: 'textarea',
        initialValue: customMapLayer.description,
      },
      {
        name: 'color',
        type: 'color',
        initialValue: customMapLayer?.properties?.color,
      },
    ],
    loading,
    error,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
