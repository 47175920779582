import { type Inventory } from '@mobble/models/src/model/Inventory';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { thunkGetAll as inventoryItemsThunkGetAll } from './inventoryItems';

export interface InventorysState {
  extStatus: ExtStatusPerActionPerParent;
  entities: Inventory[];
  filter: {};
  sort: {};
}

export const defaultState: InventorysState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<InventorysState>({
  name: 'inventories',
  initialState: defaultState,
  toParentId: ({ propertyId }: Inventory) => propertyId,
  onGetAll: mobbleService.api.inventories.get,
  onCreate: () => Promise.reject(),
  onUpdate: mobbleService.api.inventories.update,
  onDelete: () => Promise.reject(),
  afterQuery: async ({ entities }, dispatch) => {
    entities.forEach((a) => {
      dispatch(inventoryItemsThunkGetAll({ parentId: a.id }));
    });
  },
});

export const {
  thunkGetAll,
  thunkUpdate,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
