import { fromRecord, type I18nDictionary } from '../src/lib/i18n-dictionary';

/**
 * Fixed strings are now represented as `defaultMessages.ts`
 * This file should not be changed and can be removed once all strings are migrated
 */

const keysAndTranslations = {
  'action_queue.item.delete.confirm.cancel': 'Cancel',
  'action_queue.item.delete.confirm.confirm': 'Discard',
  'action_queue.item.delete.confirm.description': 'This will discard this action.',
  'action_queue.item.delete.confirm.title': 'Are you sure?',
  'action_queue.list.card.accept.label': 'Accept',
  'action_queue.list.card.discard.label': 'Discard',
  'action_queue.list.empty': 'No offline requests.',
  'appstartup.action_queue.modal.cancel': 'Not now',
  'appstartup.action_queue.modal.confirm': 'OK',
  'appstartup.action_queue.modal.description': 'you can now review and update pending offline requests',
  'appstartup.action_queue.modal.title': "You're back online",
  'auth.forgot_password.alert.success.confirm': 'OK',
  'auth.forgot_password.alert.success.description': 'Please check your email for further instructions.',
  'auth.forgot_password.alert.success.title': 'Password reset email sent',
  'auth.forgot_password.form.cancel.label': 'Back to Sign In',
  'auth.forgot_password.form.email.label': 'Email address',
  'auth.forgot_password.form.email.placeholder': 'Enter your account email address',
  'auth.forgot_password.form.submit.label': 'Reset password',
  'auth.invite.description': 'Fill in your name and password to continue to the property.',
  'auth.invite.form.error': 'An error occurred:\n%ERROR',
  'auth.invite.form.firstName.error': 'Insert your first name',
  'auth.invite.form.firstName.label': 'First name',
  'auth.invite.form.firstName.placeholder': 'Enter first name',
  'auth.invite.form.lastName.error': 'Insert your last name',
  'auth.invite.form.lastName.label': 'Last name',
  'auth.invite.form.lastName.placeholder': 'Enter last name',
  'auth.invite.form.password.error': 'Insert your password',
  'auth.invite.form.password.label': 'Password',
  'auth.invite.form.password.placeholder': 'Enter password',
  'auth.invite.form.submit': 'Continue',
  'auth.invite.form.termsAgreed.error': 'Must Accept Terms and Conditions',
  'auth.invite.form.termsAgreed.message1': 'I have read and agree with the ',
  'auth.invite.form.termsAgreed.message2': 'Mobble Customer Agreement',
  'auth.invite.form.termsAgreed.message3': ' and ',
  'auth.invite.form.termsAgreed.message4': 'Mobble Privacy Policy',
  'auth.invite.title': "You've been invited to Mobble!",
  'auth.sign_in.form.email.label': 'Email address',
  'auth.sign_in.form.email.placeholder': 'your.name@domain.com',
  'auth.sign_in.form.error': 'Your email or password is incorrect.',
  'auth.sign_in.form.password.label': 'Password',
  'auth.sign_in.form.password.placeholder': '********',
  'auth.sign_in.form.submit.label': 'Sign In',
  'auth.sign_in.help.already_have_account.label': 'Already have an account?',
  'auth.sign_in.help.forgot_password.label': 'Forgot password?',
  'auth.sign_in.help.more_information.label': ' Mobble help center',
  'auth.sign_in.help.sign_in.label': 'Sign In',
  'auth.sign_up.form.address.error': 'Insert your address',
  "auth.sign_up.form.address.label": "Business Address",
  "auth.sign_up.form.address.placeholder": "Enter your address",
  'auth.sign_up.form.country.error': 'Select your country',
  "auth.sign_up.form.country.label": "Country",
  "auth.sign_up.form.country.placeholder": "Select your country",
  'auth.sign_up.form.email.error': 'Insert your Email',
  "auth.sign_up.form.email.label": "Email",
  "auth.sign_up.form.email.placeholder": "Enter your email",
  'auth.sign_up.form.error': 'An error occurred:\n%ERROR',
  'auth.sign_up.form.farmRole.error': 'Select your farm role',
  "auth.sign_up.form.farmRole.label": 'Farm role',
  "auth.sign_up.form.farmRole.placeholder": 'Select your farm role',
  'auth.sign_up.form.firstName.error': 'Insert your first name',
  "auth.sign_up.form.firstName.label": "First name",
  "auth.sign_up.form.firstName.placeholder": "Enter your first name",
  'auth.sign_up.form.lastName.error': 'Insert your last name',
  "auth.sign_up.form.lastName.label": "Last name",
  "auth.sign_up.form.lastName.placeholder": "Enter your last name",
  'auth.sign_up.form.password.error': 'Insert your password',
  "auth.sign_up.form.password.label": "Password",
  "auth.sign_up.form.password.placeholder": "Enter your password",
  'auth.sign_up.form.phone.error': 'Insert your phone number',
  "auth.sign_up.form.phone.label": "Phone number",
  "auth.sign_up.form.phone.placeholder": "Enter your phone number",
  'auth.sign_up.form.submit': 'Start Free Trial',
  "auth.sign_up.form.submit.label": 'Sign Up',
  'auth.sign_up.form.termsAgreed.error': 'Must Accept Terms and Conditions',
  'auth.sign_up.form.termsAgreed.message1': 'I have read and agree with the ',
  'auth.sign_up.form.termsAgreed.message2': 'Mobble Customer Agreement',
  'auth.sign_up.form.termsAgreed.message3': ' and ',
  'auth.sign_up.form.termsAgreed.message4': 'Mobble Privacy Policy',
  'auth.sign_up.form.title': 'Start a 21 day free trial',
  'auth.sign_up.help.dont_have_account.label': "Don't have an account?",
  'auth.sign_up.help.sign_in.label': 'Sign Up',
  'backdating_notification.alert.confirm': 'Ok',
  'backdating_notification.alert.description': "Backdated Mob Moves don't reflect in the days grazed or rested counters, these counters will start from today. We encourage you to move Mobs on the day for better records 👍",
  'backdating_notification.alert.title': 'Days Grazed or Rested will start from today',
  'billing.cancelled-subscription.card.manage-subscription': 'Manage Subscription',
  'billing.cancelled-subscription.card.subscription-end-date': 'Cancelled at: %DATE',
  'billing.cancelled-subscription.card.subscription-name': 'Subscription: %VALUE',
  'billing.cancelled-subscription.card.title': 'Subscription Cancelled',
  'billing.contact-support.card.description': 'If you have any questions or need help with your subscription, please contact us.',
  'billing.contact-support.card.title': 'Contact Mobble Customer Support',
  'billing.current-subscription.card.current-period-end': 'Current Period End: **%DATE**',
  'billing.current-subscription.card.current-period-start': 'Current Period Start: **%DATE**',
  'billing.current-subscription.card.manage-subscription': 'Manage subscription',
  'billing.current-subscription.card.max-user-count': 'Max user count: %VALUE',
  'billing.current-subscription.card.price': '$%VALUE',
  'billing.current-subscription.card.users-in-use': 'Users in Use: %CURRENT_USER_COUNT / %MAX_USER_COUNT',
  'billing.pricing-option.card.max-user-count': 'Max User Count: %VALUE',
  'billing.pricing-option.card.price': '$%VALUE / %INTERVAL',
  'billing.pricing-option.card.purchase': 'Purchase',
  'billing.pricing-optionsToEndpoint.card.cannot-purchase': 'Over user limit',
  'billing.trial-ended.card.description': '  Mobble offers pricing plans based on your farm size, and number of users in mobble, to find out more about pricing, please contact us.',
  'billing.trial-ended.card.tag-title': 'Your 21-day trial of Mobble has finished, to continue using mobble, please purchase a mobble subscription that suits your needs.',
  'billing.trial-ended.card.title': 'Trial Ended',
  'billing.trial-subscription.card.days-until-end': '%VALUE day(s) remaining',
  'billing.trial-subscription.card.end-date': 'Ends on: **%DATE**',
  'billing.trial-subscription.card.price': 'Free',
  'billing.trial-subscription.card.title': '21-day Mobble trial',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.chats_list.label': 'Chat',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.inventories.label': 'Inventory',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.map_overview.label': 'Map',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.mobs_list.label': 'Mobs',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.paddocks_list.label': 'Paddocks',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.reports.label': 'Reports',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.summary.casualties_list.label': 'Casualties',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.summary.natural_increases_list.label': 'NI',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.summary.purchases_list.label': 'Purchases',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.summary.sales_list.label': 'Sales',
  'bottom_main_tab_bar.route.root.drawer.tabs.app.tasks_list.label': 'Tasks',
  'casualties.casualty.delete.confirm.cancel': 'Cancel',
  'casualties.casualty.delete.confirm.confirm': 'Delete',
  'casualties.casualty.delete.confirm.description': 'This will delete the casualty from your records.',
  'casualties.casualty.delete.confirm.title': 'Are you sure?',
  'casualties.casualty.delete.form.delete.label': 'Delete casualty',
  'casualties.filter.group.breed.label': 'Breed',
  'casualties.filter.group.gender.label': 'Gender',
  'casualties.filter.group.number.label': 'Number',
  'casualties.filter.group.type.date_range.label': 'Date range',
  'casualties.filter.group.type.label': 'Casualty type',
  'casualties.summary_table.amount_of_casualties.label': ' Amount of casualties',
  'casualties.summary_table.amount_of_records.label': 'Amount of records',
  'casualties.table.empty': 'There are no casualties to display',
  'casualties.table.heading.column.date': 'Date',
  'casualties.table.heading.column.head': 'Head',
  'casualties.table.heading.column.method_of_disposal': 'Method of disposal',
  'casualties.table.heading.column.mob': 'Mob',
  'casualties.table.heading.column.paddock': 'Paddock',
  'casualties.table.heading.column.reason': 'Reason',
  'casualty.create.form.cancel.label': 'Cancel',
  'casualty.create.form.date.label': 'Date',
  'casualty.create.form.methodOfDisposal.label': 'Method of disposal',
  'casualty.create.form.methodOfDisposal.placeholder': 'Method (optional)',
  'casualty.create.form.mob.label': 'Mob',
  'casualty.create.form.mob.placeholder': 'Select a Mob',
  'casualty.create.form.number.label': 'Head',
  'casualty.create.form.number.placeholder': 'Amount',
  'casualty.create.form.reason.label': 'Reason for casualty',
  'casualty.create.form.reason.placeholder': 'Reason (optional)',
  'casualty.create.form.submit.label': 'Record casualty',
  'casualty.create.form.title.label': 'Casualty',
  'casualty.edit.form.cancel.label': 'Cancel',
  'casualty.edit.form.date.label': 'Date',
  'casualty.edit.form.methodOfDisposal.label': 'Method of disposal',
  'casualty.edit.form.methodOfDisposal.placeholder': 'Method (optional)',
  'casualty.edit.form.number.label': 'Head',
  'casualty.edit.form.number.placeholder': 'Amount',
  'casualty.edit.form.reason.label': 'Reason for Casualty',
  'casualty.edit.form.reason.placeholder': 'Reason (optional)',
  'casualty.edit.form.submit.label': 'Update',
  'casualty.info.table.row.created_by.label': 'Created by',
  'casualty.info.table.row.date.label': 'Date',
  'casualty.info.table.row.disposal.label': 'Method of Disposal',
  'casualty.info.table.row.mob.label': 'Mobs',
  'casualty.info.table.row.paddock.label': 'Paddock',
  'casualty.info.table.row.reason.label': 'Reason',
  'chart.historic-livestock.average': 'average',
  'chart.historic-livestock.option.areadse': 'DSE/Ha',
  'chart.historic-livestock.option.dse': 'DSE',
  'chart.historic-livestock.option.head': 'Totals',
  'chart.historic-livestock.title': 'Historic livestock over time',
  'chart.historic-livestock.total': 'total',
  'chart.livestock-totals.legend.other': 'Other',
  'chart.livestock-totals.option.all': 'All',
  'chart.livestock-totals.option.dse': 'DSE',
  'chart.livestock-totals.option.size': 'Number',
  'chart.livestock-totals.title': 'Livestock totals',
  'codepush.skip_status.label': 'Skip checking for update',
  'codepush.skip.label': 'Skip update',
  'codepush.status.CHECKING_FOR_UPDATE': 'Checking for update.',
  'codepush.status.DOWNLOADING': 'Downloading update\n%PERCENTAGE%',
  'codepush.status.ERROR': 'An error occurred while checking for update.',
  'codepush.status.IDLE': 'Checking for update.',
  'codepush.status.INSTALLED': 'Update installed.',
  'codepush.status.INSTALLING': 'Installing update.',
  'codepush.status.READY': 'Mobble is up to date.',
  'color_selector.modal.title': 'Select Color',
  'color_selector.placeholder': 'Select color',
  'dashboard.activity_log.title': 'Activity log',
  'dashboard.tile.active-tasks.title': 'Active Tasks',
  'dashboard.tile.suggestions.apps.card.button.download-android': 'Download On Android',
  'dashboard.tile.suggestions.apps.card.button.download-ios': 'Download On IOS',
  'dashboard.tile.suggestions.apps.card.description': 'Install the Mobble app on your mobile for use out in the paddock',
  'dashboard.tile.suggestions.apps.card.title': 'Download Mobble App',
  'dashboard.tile.suggestions.integrations.card.button.connect-mobble': 'Connect Mobble',
  'dashboard.tile.suggestions.integrations.card.description': 'Connect Mobble to other apps',
  'dashboard.tile.suggestions.integrations.card.title': 'Activate integrations',
  'dashboard.tile.suggestions.mobs.card.button.add-mob': 'Add Mobs',
  'dashboard.tile.suggestions.mobs.card.description': 'Add your first mob via the green plus button on the maps page',
  'dashboard.tile.suggestions.mobs.card.title': 'Add Mobs',
  'dashboard.tile.suggestions.paddocks.card.button.draw-paddocks': 'Draw Paddock',
  'dashboard.tile.suggestions.paddocks.card.button.import-agworld-paddocks': 'Import AgWorld Paddocks',
  'dashboard.tile.suggestions.paddocks.card.button.send-map-file': 'Send Map File',
  'dashboard.tile.suggestions.paddocks.card.description': 'Add or upload your paddocks to Mobble',
  'dashboard.tile.suggestions.paddocks.card.title': 'Add Paddocks',
  'dashboard.tile.suggestions.partners.card.button.invite-partners': 'Invite Partners',
  'dashboard.tile.suggestions.partners.card.description': 'Give limited access to your trusted partners such as your Farm Advisor, Livestock Agent, Contractors, and Accountant',
  'dashboard.tile.suggestions.partners.card.title': 'Invite your trusted partners',
  'dashboard.tile.suggestions.title': 'Setting Up Property',
  'dashboard.tile.suggestions.users.card.button.add-users': 'Add Users',
  'dashboard.tile.suggestions.users.card.description': 'Invite your farming team to Mobble',
  'dashboard.tile.suggestions.users.card.title': 'Invite the team',
  'dashboard.tile.total-area.title': 'Total **%UNIT**',
  'dashboard.tile.total-dse-area.title': 'Total DSE/**%UNIT**',
  'dashboard.tile.total-dse.title': 'Total DSE',
  'date_range.all': 'All',
  'date_range.form.end.label': 'End date',
  'date_range.form.end.placeholder': 'select date',
  'date_range.form.start.label': 'Start date',
  'date_range.form.start.placeholder': 'select date',
  'date_range.form.submit.label': 'Select range',
  'date_range.from': 'From %FROM',
  'date_range.from_to': 'From %FROM until %TO',
  'date_range.modal.title': 'Select a date range',
  'date_range.options.custom.label': 'custom',
  'date_range.options.last-3-months.label': 'last 3 months',
  'date_range.options.last-4-weeks.label': 'last 4 weeks',
  'date_range.options.last-7-days.label': 'last 7 days',
  'date_range.options.last-12-months.label': 'last 12 months',
  'date_range.options.month-to-date.label': 'month to date',
  'date_range.options.quarter-to-date.label': 'quarter to date',
  'date_range.options.today.label': 'today',
  'date_range.options.year-to-date.label': 'year to date',
  'date_range.selection.label': '**%FROM** — **%TO**',
  'date_range.to': 'Until %TO',
  'entities.entities_filter.footer.button.clear.label': 'Clear filter',
  'entities.entities_filter.footer.button.close.label': 'Close',
  'entities.entities_filter.title': 'Filter options',
  'entities.entities_list.count.all': 'Showing **%COUNT** of %TOTAL',
  'entities.entities_list.count.filtered': 'Filtered **%COUNT** of %TOTAL',
  'entities.entities_list.filter.button.clear.label': 'Clear',
  'entities.entities_list.filter.button.filter.label': 'Filter',
  'entities.entities_list.filter.search.placeholder': 'Search',
  'entities.entities_list.sort.button.label': 'Sort',
  'entities.entities_sort.options.distance_from_paddock.label': 'Distance from paddock',
  'entities.entities_sort.options.distance.label': 'Distance from me',
  'entities.entities_sort.options.head_asc.label': 'Head (least)',
  'entities.entities_sort.options.head_desc.label': 'Head (most)',
  'entities.entities_sort.options.name_asc.label': 'Name (A-Z)',
  'entities.entities_sort.options.name_desc.label': 'Name (Z-A)',
  'entities.entities_sort.options.name_yards_asc.label': 'Name (A-Z) (Yards first)',
  'entities.entities_sort.options.paddock_name_asc.label': 'Paddock name (A-Z)',
  'entities.entities_sort.options.paddock_name_desc.label': 'Paddock name (Z-A)',
  'entities.entities_sort.options.size_asc.label': 'Size (smallest)',
  'entities.entities_sort.options.size_desc.label': 'Size (largest)',
  'entities.entities_sort.options.type.label': 'Type',
  'entities.entities_sort.title': 'Sorting options',
  'generic.action.cancel': 'Cancel',
  'generic.button.refresh.label': 'Refresh',
  'generic.components.ux.inline_select.filter.placeholder': 'Filter available options',
  'generic.edit': 'Edit',
  'generic.form.error': 'There was a problem saving your %ENTITY. Please ensure all required fields are completed, if the problem persists please contact Mobble support at <a href="mailto:hello@mobble.io?subject=Bug Report">hello@mobble.io</a>',
  'generic.form.input.date.modal.cancel': 'Cancel',
  'generic.form.input.date.modal.confirm': 'Confirm',
  'generic.form.validation.max': '%FIELD must be less than %MAX.',
  'generic.form.validation.min': '%FIELD must be more than %MIN.',
  'generic.form.validation.number': '%FIELD must be a number.',
  'generic.form.validation.required': '%FIELD is a required field.',
  'generic.form.wizard.footer.next.label': 'Next',
  'generic.form.wizard.footer.previous.label': 'Previous',
  'generic.form.wizard.footer.submit.label': 'Save',
  'generic.info.table.average': 'Avg: %VALUE',
  'generic.input.date_range.or_select_year.label': 'Or select a year',
  'generic.input.inline_select.add_new.button.label': 'Add new',
  'generic.input.inline_select.add_new.placeholder.label': 'Custom name',
  'generic.input.inline_select.add_new.select_all.button.label': 'Select all',
  'generic.input.inline_select.add_new.select_filtered.button.label': 'Select all (%COUNT)',
  'generic.input.inline_select.add_new.select_none.button.label': 'Select none',
  'generic.input.input_location.select_on_map.button.label': 'Select on map',
  'generic.input.input_location.select_on_map.modal.cancel.label': 'Cancel',
  'generic.input.input_location.select_on_map.modal.select.label': 'Select',
  'generic.input.input_location.select_on_map.modal.title': 'Select on map',
  'generic.loading.chats': 'Loading chat.',
  'generic.loading.historic_events': 'Loading events.',
  'generic.loading.inventories': 'Loading inventories.',
  'generic.loading.inventory': 'Loading inventory.',
  'generic.loading.inventory_items': 'Loading inventory items.',
  'generic.loading.loading': 'Loading.',
  'generic.loading.mob': 'Loading mob.',
  'generic.loading.mobs': 'Loading mobs.',
  'generic.loading.paddock': 'Loading paddock.',
  'generic.loading.paddock_action': 'Loading paddock action.',
  'generic.loading.paddocks': 'Loading paddocks.',
  'generic.loading.paddocks_geometry': 'Loading paddocks geometry.',
  'generic.loading.properties': 'Loading properties.',
  'generic.loading.rain-gauge': 'Loading Rain Gauge.',
  'generic.loading.state': 'Resuming were you left off.',
  'generic.loading.task': 'Loading task.',
  'generic.loading.tasks': 'Loading tasks.',
  'generic.quantity.unit.acre.long': 'Acres',
  'generic.quantity.unit.acre.short': 'Acres',
  'generic.quantity.unit.acre.value': '%VALUE Acres',
  'generic.quantity.unit.are.long': 'Ares',
  'generic.quantity.unit.are.short': 'Ares',
  'generic.quantity.unit.are.value': '%VALUE Ares',
  'generic.quantity.unit.cm.long': 'Centimeters',
  'generic.quantity.unit.cm.short': 'centimeters',
  'generic.quantity.unit.cm.value': '%VALUE cm',
  'generic.quantity.unit.cm2.long': 'Square Centimeters',
  'generic.quantity.unit.cm2.short': 'cm2',
  'generic.quantity.unit.cm2.value': '%VALUE cm2',
  'generic.quantity.unit.g.long': 'Grams',
  'generic.quantity.unit.g.short': 'grams',
  'generic.quantity.unit.g.value': '%VALUE g',
  'generic.quantity.unit.ha.long': 'Hectares',
  'generic.quantity.unit.ha.short': 'Ha',
  'generic.quantity.unit.ha.value': '%VALUE Ha',
  'generic.quantity.unit.in.long': 'Inches',
  'generic.quantity.unit.in.short': 'inches',
  'generic.quantity.unit.in.value': '%VALUE in',
  'generic.quantity.unit.kg.long': 'Kilograms',
  'generic.quantity.unit.kg.short': 'kilograms',
  'generic.quantity.unit.kg.value': '%VALUE kg',
  'generic.quantity.unit.km.long': 'Kilometers',
  'generic.quantity.unit.km.short': 'kilometers',
  'generic.quantity.unit.km.value': '%VALUE km',
  'generic.quantity.unit.km2.long': 'Square Kilometers',
  'generic.quantity.unit.km2.short': 'km2',
  'generic.quantity.unit.km2.value': '%VALUE km2',
  'generic.quantity.unit.L.long': 'Litres',
  'generic.quantity.unit.L.short': 'litres',
  'generic.quantity.unit.L.value': '%VALUE L',
  'generic.quantity.unit.m.long': 'Meters',
  'generic.quantity.unit.m.short': 'meters',
  'generic.quantity.unit.m.value': '%VALUE m',
  'generic.quantity.unit.m2.long': 'Square Meters',
  'generic.quantity.unit.m2.short': 'm2',
  'generic.quantity.unit.m2.value': '%VALUE m2',
  'generic.quantity.unit.mg.long': 'Milligrams',
  'generic.quantity.unit.mg.short': 'milligrams',
  'generic.quantity.unit.mg.value': '%VALUE mg',
  'generic.quantity.unit.mi.long': 'Miles',
  'generic.quantity.unit.mi.short': 'miles',
  'generic.quantity.unit.mi.value': '%VALUE mi',
  'generic.quantity.unit.ml.long': 'Millilitres',
  'generic.quantity.unit.ml.short': 'millilitres',
  'generic.quantity.unit.ml.value': '%VALUE ml',
  'generic.quantity.unit.mm.long': 'Millimeters',
  'generic.quantity.unit.mm.short': 'millimeters',
  'generic.quantity.unit.mm.value': '%VALUE mm',
  'generic.quantity.unit.select': 'Select unit',
  'generic.quantity.unit.square_miles.long': 'Square Miles',
  'generic.quantity.unit.square_miles.short': 'Sq mi',
  'generic.quantity.unit.square_miles.value': '%VALUE Sq mi',
  'generic.quantity.unit.square_yards.long': 'Square Yards',
  'generic.quantity.unit.square_yards.short': 'Sq yd',
  'generic.quantity.unit.square_yards.value': '%VALUE Sq yd',
  'generic.quantity.unit.t.long': 'Tonnes',
  'generic.quantity.unit.t.short': 'tonnes',
  'generic.quantity.unit.t.value': '%VALUE t',
  'generic.quantity.unit.Units.long': 'Units',
  'generic.quantity.unit.Units.short': 'units',
  'generic.quantity.unit.Units.value': '%VALUE units',
  'generic.quantity.unit.yd.long': 'Yards',
  'generic.quantity.unit.yd.short': 'yards',
  'generic.quantity.unit.yd.value': '%VALUE yd',
  'generic.refresh': 'Refresh',
  'generic.uploading.chats': 'Sending message',
  'historic_events.event.event_type.CasualtyAddition': 'Casualty Addition',
  'historic_events.event.event_type.CasualtyDeletion': 'Casualty Deletion',
  'historic_events.event.event_type.CasualtyEdit': 'Casualty Edit',
  'historic_events.event.event_type.ChemicalAddition': 'Chemical Addition',
  'historic_events.event.event_type.ChemicalDeletion': 'Chemical Deletion',
  'historic_events.event.event_type.ChemicalEdit': 'Chemical Edit',
  'historic_events.event.event_type.CommentAddition': 'Comment Addition',
  'historic_events.event.event_type.FeedAddition': 'Product Addition',
  'historic_events.event.event_type.FeedDeletion': 'Product Deletion',
  'historic_events.event.event_type.FeedEdit': 'Product Edit',
  'historic_events.event.event_type.FeedFinished': 'Product Finished',
  'historic_events.event.event_type.MapAssetAddition': 'Map Asset Addition',
  'historic_events.event.event_type.MapAssetEdit': 'Map Asset Edit',
  'historic_events.event.event_type.MobActionAddition': 'Mob Action Addition',
  'historic_events.event.event_type.MobActionDeletion': 'Mob Action Deletion',
  'historic_events.event.event_type.MobActionEdit': 'Mob Action Edit',
  'historic_events.event.event_type.MobAddition': 'Mob Addition',
  'historic_events.event.event_type.MobArchiving': 'Mob Archiving',
  'historic_events.event.event_type.MobDeletion': 'Mob Deletion',
  'historic_events.event.event_type.MobEdit': 'Mob Edit',
  'historic_events.event.event_type.MobMerge': 'Mob Merge',
  'historic_events.event.event_type.MobMove': 'Mob Move',
  'historic_events.event.event_type.MobMoveToProp': 'Mob Move To Property',
  'historic_events.event.event_type.MobSplit': 'Mob Split',
  'historic_events.event.event_type.NaturalIncreaseAddition': 'Natural Increase Addition',
  'historic_events.event.event_type.PaddockActionAddition': 'Paddock Action Addition',
  'historic_events.event.event_type.PaddockActionDeletion': 'Paddock Action Deletion',
  'historic_events.event.event_type.PaddockActionEdit': 'Paddock Action Edit',
  'historic_events.event.event_type.PaddockAddition': 'Paddock Addition',
  'historic_events.event.event_type.PaddockDeletion': 'Paddock Deletion',
  'historic_events.event.event_type.PaddockEdit': 'Paddock Edit',
  'historic_events.event.event_type.PaddockOpenGroupCreate': 'Open Gates',
  'historic_events.event.event_type.PropertyAddition': 'Property Addition',
  'historic_events.event.event_type.PurchaseAddition': 'Purchase Addition',
  'historic_events.event.event_type.RainGaugeAddition': 'Rain Gauge Addition',
  'historic_events.event.event_type.RainGaugeDeletion': 'Rain Gauge Deletion',
  'historic_events.event.event_type.RainGaugeEdit': 'Rain Gauge Edit',
  'historic_events.event.event_type.RainGaugeReadingAddition': 'Rain Gauge Reading Addition',
  'historic_events.event.event_type.RainGaugeReadingDeletion': 'Rain Gauge Reading Deletion',
  'historic_events.event.event_type.RainGaugeReadingEdit': 'Rain Gauge Reading Edit',
  'historic_events.event.event_type.SaleAddition': 'Sale Addition',
  'historic_events.event.event_type.SaleDeletion': 'Sale Deletion',
  'historic_events.event.event_type.SaleEdit': 'Sale Edit',
  'historic_events.event.event_type.TaskAddition': 'Task Addition',
  'historic_events.event.event_type.TaskDeletion': 'Task Deletion',
  'historic_events.event.event_type.TaskEdit': 'Task Edit',
  'historic_events.filter.actions.apply_filter': 'Apply filter',
  'historic_events.filter.actions.clear_filter': 'Clear filter',
  'historic_events.filter.group.event_type.label': 'Event type',
  'historic_events.list.empty.filter.line1': 'There are currently no events matching your filter.',
  'historic_events.list.empty.filter.line2': 'Try to alter you filter or clear it to show all events.',
  'historic_events.list.filter.clear': 'Clear',
  'historic_events.list.filter.open': 'Filters',
  'historic_events.list.filter.search.placeholder': 'Search events',
  'historic_events.list.result.filtered': 'Showing **%COUNT** of **%TOTAL** events',
  'historic_events.list.result.total': '**%TOTAL** events',
  'historic_events.list.result.unfiltered': 'Loaded **%COUNT** events',
  'input_inventory_item.button.add_product.label': 'Add Product',
  'input_inventory_item.inventories.title': 'Select inventory',
  'input_inventory_item.inventory_items.search.placeholder': 'Search for a product',
  'input_inventory_item.mob-action.form.esi.label': 'ESI',
  'input_inventory_item.mob-action.form.esi.placeholder': 'Enter amount in days',
  'input_inventory_item.mob-action.form.quantity.label': 'Quantity/Head',
  'input_inventory_item.mob-action.form.quantity.placeholder': 'Enter a quantity',
  'input_inventory_item.mob-action.form.whp.label': 'WHP',
  'input_inventory_item.mob-action.form.whp.placeholder': 'Enter amount in days',
  'input_inventory_item.paddock-action.form.esi.label': 'ESI',
  'input_inventory_item.paddock-action.form.esi.placeholder': 'Enter amount in days',
  'input_inventory_item.paddock-action.form.quantity.label': 'Quantity/Ha',
  'input_inventory_item.paddock-action.form.quantity.placeholder': 'Enter a quantity',
  'input_inventory_item.paddock-action.form.whp.label': 'WHP',
  'input_inventory_item.paddock-action.form.whp.placeholder': 'Enter amount in days',
  'inventories.inventory.edit.form.cancel.label': 'Cancel',
  'inventories.inventory.edit.form.name.label': 'Name',
  'inventories.inventory.edit.form.property.label': 'Property',
  'inventories.inventory.edit.form.sharingIds.label': 'Properties with access',
  'inventories.inventory.edit.form.sharingIds.placeholder': 'Select properties',
  'inventories.inventory.edit.form.submit.label': 'Save changes',
  'inventories.list.inventory_item.card.active_products.label': 'Active products: **%VALUE**',
  'inventories.list.inventory_item.card.batchId.label': 'Batch ID',
  'inventories.list.inventory_item.card.dateReceived.label': 'Date received',
  'inventories.list.inventory_item.card.expiryDate.label': 'Expiry date',
  'inventories.list.inventory_item.card.quantity.label': 'Amount at arrival',
  'inventories.list.inventory_item.card.supplierName.label': 'Supplier',
  'inventory_item.delete.button.label': 'Delete',
  'inventory_item.delete.confirm.cancel': 'Cancel',
  'inventory_item.delete.confirm.confirm': 'Delete',
  'inventory_item.delete.confirm.description': 'This will delete "%NAME" from your inventory.',
  'inventory_item.delete.confirm.title': 'Are you sure?',
  'inventory_item.info.table.row.amountAtArrival.label': 'Amount at arrival',
  'inventory_item.info.table.row.batchId.label': 'Batch ID',
  'inventory_item.info.table.row.createdBy.label': 'Created by',
  'inventory_item.info.table.row.dateOfManufacture.label': 'Date of manufacture',
  'inventory_item.info.table.row.dateReceived.label': 'Date received',
  'inventory_item.info.table.row.esi.label': 'ESI',
  'inventory_item.info.table.row.expiryDate.label': 'Expiry date',
  'inventory_item.info.table.row.inventory.label': 'Inventory',
  'inventory_item.info.table.row.name.label': 'Name',
  'inventory_item.info.table.row.notes.label': 'Notes',
  'inventory_item.info.table.row.pricePerUnit.label': 'Price per unit',
  'inventory_item.info.table.row.supplierName.label': 'Supplier',
  'inventory_item.info.table.row.whp.label': 'WHP',
  'inventory_item.info.table.row.woolWhp.label': 'Wool WHP',
  'inventory_items.inventory_item.chemicals.create.form.batchId.label': 'Batch ID',
  'inventory_items.inventory_item.chemicals.create.form.batchId.placeholder': '003465',
  'inventory_items.inventory_item.chemicals.create.form.cancel.label': 'Cancel',
  'inventory_items.inventory_item.chemicals.create.form.dateOfManufacture.label': 'Date of manufacture',
  'inventory_items.inventory_item.chemicals.create.form.dateOfManufacture.placeholder': 'Select date',
  'inventory_items.inventory_item.chemicals.create.form.dateReceived.label': 'Date received',
  'inventory_items.inventory_item.chemicals.create.form.dateReceived.placeholder': 'Select date',
  'inventory_items.inventory_item.chemicals.create.form.esi.label': 'ESI',
  'inventory_items.inventory_item.chemicals.create.form.esi.placeholder': 'Number (optional)',
  'inventory_items.inventory_item.chemicals.create.form.expiryDate.label': 'Expiry date',
  'inventory_items.inventory_item.chemicals.create.form.expiryDate.placeholder': 'Select date',
  'inventory_items.inventory_item.chemicals.create.form.name.label': 'Name',
  'inventory_items.inventory_item.chemicals.create.form.name.placeholder': 'Chemical name',
  'inventory_items.inventory_item.chemicals.create.form.notes.label': 'Notes',
  'inventory_items.inventory_item.chemicals.create.form.notes.placeholder': 'Any additional notes',
  'inventory_items.inventory_item.chemicals.create.form.quantity.label': 'Amount at arrival',
  'inventory_items.inventory_item.chemicals.create.form.quantity.placeholder': 'Amount',
  'inventory_items.inventory_item.chemicals.create.form.submit.label': 'Add to inventory',
  'inventory_items.inventory_item.chemicals.create.form.whp.label': 'WHP',
  'inventory_items.inventory_item.chemicals.create.form.whp.placeholder': 'Number of days',
  'inventory_items.inventory_item.chemicals.create.form.woolWhp.label': 'Wool WHP',
  'inventory_items.inventory_item.chemicals.create.form.woolWhp.placeholder': 'Number of days (optional)',
  'inventory_items.inventory_item.chemicals.edit.form.batchId.label': 'Batch ID',
  'inventory_items.inventory_item.chemicals.edit.form.batchId.placeholder': '003465',
  'inventory_items.inventory_item.chemicals.edit.form.cancel.label': 'Cancel',
  'inventory_items.inventory_item.chemicals.edit.form.dateOfManufacture.label': 'Date of manufacture',
  'inventory_items.inventory_item.chemicals.edit.form.dateOfManufacture.placeholder': 'Select date',
  'inventory_items.inventory_item.chemicals.edit.form.dateReceived.label': 'Date received',
  'inventory_items.inventory_item.chemicals.edit.form.dateReceived.placeholder': 'Select date',
  'inventory_items.inventory_item.chemicals.edit.form.esi.label': 'ESI',
  'inventory_items.inventory_item.chemicals.edit.form.esi.placeholder': 'Number (optional)',
  'inventory_items.inventory_item.chemicals.edit.form.expiryDate.label': 'Expiry date',
  'inventory_items.inventory_item.chemicals.edit.form.expiryDate.placeholder': 'Select date',
  'inventory_items.inventory_item.chemicals.edit.form.name.label': 'Name',
  'inventory_items.inventory_item.chemicals.edit.form.name.placeholder': 'Chemical name',
  'inventory_items.inventory_item.chemicals.edit.form.notes.label': 'Notes',
  'inventory_items.inventory_item.chemicals.edit.form.notes.placeholder': 'Any additional notes',
  'inventory_items.inventory_item.chemicals.edit.form.quantity.label': 'Amount at arrival',
  'inventory_items.inventory_item.chemicals.edit.form.quantity.placeholder': 'Amount',
  'inventory_items.inventory_item.chemicals.edit.form.submit.label': 'Save changes',
  'inventory_items.inventory_item.chemicals.edit.form.whp.label': 'WHP',
  'inventory_items.inventory_item.chemicals.edit.form.whp.placeholder': 'Number of days',
  'inventory_items.inventory_item.chemicals.edit.form.woolWhp.label': 'Wool WHP',
  'inventory_items.inventory_item.chemicals.edit.form.woolWhp.placeholder': 'Number of days (optional)',
  'inventory_items.inventory_item.feed.create.form.cancel.label': 'Cancel',
  'inventory_items.inventory_item.feed.create.form.dateReceived.label': 'Date received',
  'inventory_items.inventory_item.feed.create.form.dateReceived.placeholder': 'Select date',
  'inventory_items.inventory_item.feed.create.form.name.label': 'Name',
  'inventory_items.inventory_item.feed.create.form.name.placeholder': 'Product name',
  'inventory_items.inventory_item.feed.create.form.notes.label': 'Notes',
  'inventory_items.inventory_item.feed.create.form.notes.placeholder': 'Any additional notes',
  'inventory_items.inventory_item.feed.create.form.pricePerUnit.label': 'Price per unit',
  'inventory_items.inventory_item.feed.create.form.pricePerUnit.placeholder': '0',
  'inventory_items.inventory_item.feed.create.form.quantity.label': 'Amount at arrival',
  'inventory_items.inventory_item.feed.create.form.quantity.placeholder': 'Amount',
  'inventory_items.inventory_item.feed.create.form.submit.label': 'Add to inventory',
  'inventory_items.inventory_item.feed.create.form.supplierName.label': 'Supplier name',
  'inventory_items.inventory_item.feed.create.form.supplierName.placeholder': 'Name',
  'inventory_items.inventory_item.feed.create.form.whp.label': 'WHP',
  'inventory_items.inventory_item.feed.create.form.whp.placeholder': 'Number',
  'inventory_items.inventory_item.feed.edit.form.cancel.label': 'Cancel',
  'inventory_items.inventory_item.feed.edit.form.dateReceived.label': 'Date received',
  'inventory_items.inventory_item.feed.edit.form.dateReceived.placeholder': 'Select date',
  'inventory_items.inventory_item.feed.edit.form.name.label': 'Name',
  'inventory_items.inventory_item.feed.edit.form.name.placeholder': 'Product name',
  'inventory_items.inventory_item.feed.edit.form.notes.label': 'Notes',
  'inventory_items.inventory_item.feed.edit.form.notes.placeholder': 'Any additional notes',
  'inventory_items.inventory_item.feed.edit.form.pricePerUnit.label': 'Price per unit',
  'inventory_items.inventory_item.feed.edit.form.pricePerUnit.placeholder': '0',
  'inventory_items.inventory_item.feed.edit.form.quantity.label': 'Amount at arrival',
  'inventory_items.inventory_item.feed.edit.form.quantity.placeholder': 'Amount',
  'inventory_items.inventory_item.feed.edit.form.submit.label': 'Save changes',
  'inventory_items.inventory_item.feed.edit.form.supplierName.label': 'Supplier name',
  'inventory_items.inventory_item.feed.edit.form.supplierName.placeholder': '',
  'inventory_items.inventory_item.status.change_to_active': 'Mark as active',
  'inventory_items.inventory_item.status.change_to_finished': 'Mark as finished',
  'inventory_items.list.empty.add': 'Add a new inventory item below',
  'inventory_items.list.filter.search.placeholder': 'Search inventory',
  'inventory_items.list.filter.tabs.active.label': 'Current',
  'inventory_items.list.filter.tabs.finished.label': 'Finished',
  'inventory_items.list.section.local.label': 'Local inventories',
  'inventory_items.list.section.shared.label': 'Shared inventories',
  'inventory_items.table.heading.column.batch_id': 'Batch ID',
  'inventory_items.table.heading.column.date_of_manufacture': 'Date of manufacture',
  'inventory_items.table.heading.column.date_received': 'Date received',
  'inventory_items.table.heading.column.esi': 'ESI',
  'inventory_items.table.heading.column.expiry_date': 'Expiry date',
  'inventory_items.table.heading.column.name': 'Name',
  'inventory_items.table.heading.column.notes': 'Notes',
  'inventory_items.table.heading.column.price_per_unit_cents': 'Price per unit',
  'inventory_items.table.heading.column.quantity': 'Quantity',
  'inventory_items.table.heading.column.supplier_name': 'Supplier name',
  'inventory_items.table.heading.column.whp': 'WHP',
  'inventory_items.table.heading.column.woolWhp': 'Wool WHP',
  'main_drawer.menu.action_queue.label': 'Offline requests',
  'main_drawer.menu.chat.label': 'Chat',
  'main_drawer.menu.dashboard.label': 'Dashboard',
  'main_drawer.menu.inventories.label': 'Inventories',
  'main_drawer.menu.invite.label': 'Refer a Farmer',
  'main_drawer.menu.map.label': 'Map',
  'main_drawer.menu.mobs.label': 'Mobs',
  'main_drawer.menu.paddocks.label': 'Paddocks',
  'main_drawer.menu.rain_gauges.label': 'Rain gauges',
  'main_drawer.menu.reports.label': 'Reports',
  'main_drawer.menu.settings.label': 'Settings',
  'main_drawer.menu.summaries.label': 'Summaries',
  'main_drawer.menu.tasks.label': 'Tasks',
  'main_drawer.menu.user_settings.label': 'User settings',
  'map_asset.info.table.empty': 'There are no Map Assets to display',
  'map_asset.info.table.row.description.label': 'Description',
  'map_asset.info.table.row.length.label': 'Length',
  'map_asset.info.table.row.name.label': 'Name',
  'map_asset.info.table.row.type.label': 'Type of asset',
  'map_asset.mode.line_string': 'Line',
  'map_asset.mode.point': 'Point',
  'map_assets.filter.sections.type.title': 'Map asset type',
  'map_assets.list.result.total': '**%TOTAL** assets',
  'map_assets.map_asset.create.form.cancel.label': 'Cancel',
  'map_assets.map_asset.create.form.description.label': 'Description',
  'map_assets.map_asset.create.form.description.placeholder': '(Optional)',
  'map_assets.map_asset.create.form.name.label': 'Name',
  'map_assets.map_asset.create.form.name.placeholder': 'Name your asset',
  'map_assets.map_asset.create.form.points.label': 'Geometry',
  'map_assets.map_asset.create.form.submit.label': 'Add asset',
  'map_assets.map_asset.create.form.title.label': 'Map asset',
  'map_assets.map_asset.create.form.type.label': 'Type of asset',
  'map_assets.map_asset.create.form.type.placeholder': 'Select type',
  'map_assets.map_asset.delete.confirm.cancel': 'Cancel',
  'map_assets.map_asset.delete.confirm.confirm': 'Delete asset',
  'map_assets.map_asset.delete.confirm.description': 'Are you sure you want to delete this asset?',
  'map_assets.map_asset.delete.confirm.title': 'Delete Map Asset',
  'map_assets.map_asset.delete.form.delete.label': 'Delete asset',
  'map_assets.map_asset.edit.form.cancel.label': 'Cancel',
  'map_assets.map_asset.edit.form.description.label': 'Description',
  'map_assets.map_asset.edit.form.description.placeholder': '(Optional)',
  'map_assets.map_asset.edit.form.name.label': 'Name',
  'map_assets.map_asset.edit.form.name.placeholder': 'Name your asset',
  'map_assets.map_asset.edit.form.points.label': 'Geometry',
  'map_assets.map_asset.edit.form.submit.label': 'Update asset',
  'map_assets.map_asset.edit.form.type.label': 'Type of asset',
  'map_assets.map_asset.edit.form.type.placeholder': 'Select type',
  'map_assets.table.heading.column.description': 'Description',
  'map_assets.table.heading.column.name': 'Name',
  'map_assets.table.heading.column.type': 'Type',
  'map_assets.type.bore': 'Bore',
  'map_assets.type.dam': 'Dam',
  'map_assets.type.electric-fence': 'Electric fence',
  'map_assets.type.electric-fence-unit': 'Electric fence unit',
  'map_assets.type.feeder': 'Feeder',
  'map_assets.type.gate': 'Gate',
  'map_assets.type.hazard': 'Hazard',
  'map_assets.type.other-line': 'Other line',
  'map_assets.type.other-point': 'Other point',
  'map_assets.type.pipeline': 'Pipeline',
  'map_assets.type.road': 'Road',
  'map_assets.type.shed': 'Shed',
  'map_assets.type.silo': 'Silo',
  'map_assets.type.tank': 'Tank',
  'map_assets.type.water-trough': 'Water trough',
  'map_detail.map_assets__bore.label': 'Bore',
  'map_detail.map_assets__dam.label': 'Dam',
  'map_detail.map_assets__electric-fence-unit.label': 'Electric fence unit',
  'map_detail.map_assets__electric-fence.label': 'Electric fence',
  'map_detail.map_assets__feeder.label': 'Feeder',
  'map_detail.map_assets__gate.label': 'Gate',
  'map_detail.map_assets__hazard.label': 'Hazard',
  'map_detail.map_assets__other-line.label': 'Other line',
  'map_detail.map_assets__other-point.label': 'Other point',
  'map_detail.map_assets__pipeline.label': 'Pipeline',
  'map_detail.map_assets__road.label': 'Road',
  'map_detail.map_assets__shed.label': 'Shed',
  'map_detail.map_assets__silo.label': 'Silo',
  'map_detail.map_assets__tank.label': 'Tank',
  'map_detail.map_assets__water-trough.label': 'Water trough',
  'map_detail.mobs.label': 'Mobs',
  'map_detail.paddock_boundaries.label': 'Paddock boundaries',
  'map_detail.paddock_groups.label': 'Open gates',
  'map_detail.paddock_labels.label': 'Paddock names',
  'map_detail.tasks__others.label': 'Others',
  'map_detail.tasks__user.label': 'Yours',
  'map.actions.action.add_measurement.description': 'Place points on the map to measure distance and area.',
  'map.actions.action.add_measurement.label': 'Make measurement',
  'map.actions.action.add_task.description': 'Add a new task or reminder anywhere on the map.',
  'map.actions.action.add_task.label': 'Add task',
  'map.map_details.geometry-edit.alert.cancel': 'No',
  'map.map_details.geometry-edit.alert.confirm': 'Yes',
  'map.map_details.geometry-edit.alert.description': 'Would you like to update the grazeable area of the paddock based on the new geometry?',
  'map.map_details.geometry-edit.alert.title': 'Update grazeable area',
  'map.map_details.label': 'Map details',
  'map.map_details.mobs.label': 'Mobs',
  'map.map_details.paddock_boundaries.label': 'Paddock boundaries',
  'map.map_details.paddock_groups.label': 'Open gates',
  'map.map_details.paddock_labels.label': 'Paddock labels',
  'map.map_details.tasks.label': 'Tasks',
  'map.map_options_modal.details.heading': 'Paddocks & Mobs',
  'map.map_options_modal.details.select.placeholder': 'Select details to display on map',
  'map.map_options_modal.details.select.selection.multiple': '%COUNT of %TOTAL selected',
  'map.map_options_modal.details.select.selection.single': '%COUNT of %TOTAL selected',
  'map.map_options_modal.map_assets.heading': 'Map Assets',
  'map.map_options_modal.map_assets.select.placeholder': 'Select assets to display on map',
  'map.map_options_modal.map_assets.select.selection.multiple': '%COUNT of %TOTAL selected',
  'map.map_options_modal.map_assets.select.selection.single': '%COUNT of %TOTAL selected',
  'map.map_options_modal.tasks.heading': 'Tasks',
  'map.map_options_modal.tasks.select.placeholder': 'Select tasks to display on map',
  'map.map_options_modal.tasks.select.selection.multiple': '%COUNT of %TOTAL selected',
  'map.map_options_modal.tasks.select.selection.single': '%COUNT of %TOTAL selected',
  'map.map_options_modal.title': 'Map options',
  'map.map_style.label': 'Map type',
  'map.map_style.light.label': 'Light',
  'map.map_style.outdoor.label': 'Terrain',
  'map.map_style.satellite.label': 'Satellite',
  'map.map_style.street.label': 'Streets',
  'map.measure.area.label': 'Area:\t\t',
  'map.measure.length.label': 'Length:\t',
  'map.measure.perimeter.label': 'Perimeter:\t',
  'map.overview.add_asset.footer.cancel.label': 'Cancel',
  'map.overview.add_asset.footer.submit.label': 'Add asset',
  'map.overview.add_asset.header.map_asset_type.placeholder': 'Select asset type:',
  'map.overview.add_asset.header.map_asset_type.selection.single': 'Selected: **%VALUE**',
  'map.overview.add_asset.instructions': 'Select a point on the map to set Asset location.',
  'map.overview.add_paddock.footer.cancel.label': 'Cancel',
  'map.overview.add_paddock.footer.submit.label': 'Add Paddock',
  'map.overview.add_paddock.instructions': 'Select points on the map to draw the Paddock boundary.',
  'map.overview.add_task.footer.cancel.label': 'Cancel',
  'map.overview.add_task.footer.submit.label': 'Add task',
  'map.overview.add_task.instructions': 'Select a point on the map to set Task location.',
  'map.tools.bounds.hint': 'Scale map to fit all items',
  'map.tools.measure_clear.hint': 'Clear all placed points',
  'map.tools.measure_close.hint': 'Stop measuring',
  'map.tools.measure_delete.hint': 'Delete the highlighted point',
  'map.tools.measure_type.hint': 'Switch between path and area measurements',
  'map.tools.measure.hint': 'Measure a path or line on the map',
  'map.tools.pan.hint': 'Toggle between a 3D (panned) view and a (top down) 2D view',
  'map.tools.snap.hint': 'Snap to boundary',
  'map.tools.style_detail.hint': 'Map options, change map style and what\'s showing',
  'map.tools.user_loading.hint': 'Go to your current position (loading...)',
  'map.tools.user.hint': 'Go to your current position',
  'mob_action.create.form.action_type.add.button.label': 'Add',
  'mob_action.create.form.action_type.add.placeholder.label': 'Add a new action type.',
  'mob_action.create.form.action_type.label': 'Action type',
  'mob_action.create.form.action_type.placeholder': 'Select action type',
  'mob_action.create.form.cancel.label': 'Cancel',
  'mob_action.create.form.count.label': 'Number affected (optional)',
  'mob_action.create.form.count.placeholder': 'Affected count',
  'mob_action.create.form.date.label': 'Date',
  'mob_action.create.form.inventory_items.label': 'Product applications',
  'mob_action.create.form.mobIds.label': 'Mobs',
  'mob_action.create.form.mobIds.placeholder': 'Mobs',
  'mob_action.create.form.note.label': 'Notes',
  'mob_action.create.form.note.placeholder': 'Action details/notes',
  'mob_action.create.form.quantity.label': 'Quantity',
  'mob_action.create.form.quantity.placeholder': 'Quantity',
  'mob_action.create.form.submit.label': 'Apply action',
  'mob_action.create.form.whp.label': 'WHP',
  'mob_action.create.form.whp.placeholder': 'Enter amount in days',
  'mob_action.edit.form.action_type.label': 'Action type',
  'mob_action.edit.form.action_type.placeholder': 'Select action type',
  'mob_action.edit.form.cancel.label': 'Cancel',
  'mob_action.edit.form.count.label': 'Number affected (optional)',
  'mob_action.edit.form.count.placeholder': 'Affected count',
  'mob_action.edit.form.date.label': 'Date',
  'mob_action.edit.form.inventory_items.label': 'Product applications',
  'mob_action.edit.form.mobIds.label': 'Mobs',
  'mob_action.edit.form.mobIds.placeholder': 'Mobs',
  'mob_action.edit.form.note.label': 'Notes',
  'mob_action.edit.form.note.placeholder': 'Action details/notes',
  'mob_action.edit.form.quantity.label': 'Quantity',
  'mob_action.edit.form.quantity.placeholder': 'Quantity',
  'mob_action.edit.form.submit.label': 'Save changes',
  'mob_action.edit.form.whp.label': 'WHP',
  'mob_action.edit.form.whp.placeholder': 'Enter amount in days',
  'mob_action.info.table.row.action_type.label': 'Action type',
  'mob_action.info.table.row.applied_inventory_items.label': 'Product applications',
  'mob_action.info.table.row.count.label': 'Affected',
  'mob_action.info.table.row.created_by.label': 'Recorded by',
  'mob_action.info.table.row.date.label': 'Date',
  'mob_action.info.table.row.description.label': 'Description',
  'mob_action.info.table.row.esi.label': 'ESI',
  'mob_action.info.table.row.mobs.label': 'Mobs',
  'mob_action.info.table.row.note.label': 'Notes',
  'mob_action.info.table.row.quantity.label': 'Quantity/Head used',
  'mob_action.info.table.row.type.label': 'Type',
  'mob_action.info.table.row.whp.label': 'WHP',
  'mob_actions.filter.empty.add': 'There are no Mob Actions to show',
  'mob_actions.filter.group.type.label': 'Event type',
  'mob_actions.table.empty': 'There are no Mob Actions to display',
  'mob_actions.table.heading.column.applied_to': 'Applied to',
  'mob_actions.table.heading.column.classes': 'Classes',
  'mob_actions.table.heading.column.created_by': 'Recorded by',
  'mob_actions.table.heading.column.date': 'Date',
  'mob_actions.table.heading.column.paddocks': 'Paddocks',
  'mob_actions.table.heading.column.products': 'Products',
  'mob_actions.table.heading.column.type': 'Type',
  'mob_detail.casualties.title': 'Casualties',
  'mob.actions.action.add_mob': 'Add Mob',
  'mob.casualty.create.form.number.error.label': 'Head must be a whole number',
  'mob.casualty.edit.form.number.error.label': 'Head must be a whole number',
  'mob.info.table.row.ages.label': 'Ages',
  'mob.info.table.row.breed.label': 'Breed',
  'mob.info.table.row.dse.label': 'DSE',
  'mob.info.table.row.gender.label': 'Gender',
  'mob.info.table.row.name.label': 'Name',
  'mob.info.table.row.number.label': 'Head',
  'mob.info.table.row.paddock.label': 'Paddock',
  'mob.info.table.row.safedate.label': 'Safe Date',
  'mob.info.table.row.type.label': 'Type',
  'mobActions.mobAction.delete.confirm.cancel': 'Cancel',
  'mobActions.mobAction.delete.confirm.confirm': 'Delete Action',
  'mobActions.mobAction.delete.confirm.description': 'This will delete the Mob Action from your records.',
  'mobActions.mobAction.delete.confirm.title': 'Are you sure?',
  'mobActions.mobAction.delete.form.delete.label': 'Delete Mob Action',
  'mobble-apps.app.agworld.description': 'Connect Mobble to your Agworld account to help manage your cropping and livestock operation. This integration links information between Mobble and Agworld - making access to mob locations and livestock information in your Agworld app quick and easy. Look forward to better decisions and time saved with improved data visibility.',
  'mobble-apps.app.agworld.disableText': 'Disconnect',
  'mobble-apps.app.agworld.enableText': 'Connect',
  'mobble-apps.app.agworld.title': 'AgWorld',
  'mobble-apps.app.delaypay.description': 'Buy Livestock, Stockfeed & Machinery now and pay later. Mobble and DelayPay are working together to give you a discounted rate by using your Mobble livestock data as security to access funding. When using Mobble, DelayPay can approve funding up to $250,000 within 24 hours.',
  'mobble-apps.app.delaypay.enableText': 'Enquire',
  'mobble-apps.app.delaypay.title': 'DelayPay',
  'mobble-apps.app.generic.learnMore': 'Learn More',
  'mobble-apps.app.import-agworld-paddocks.description': 'Save time setting up Mobble and import your paddocks from Agworld to Mobble. This integration works via associating paddock names, it will replace paddock geometries in Mobble that have the same names as Agworld, or add new paddocks if no name is found.',
  'mobble-apps.app.import-agworld-paddocks.enableText': 'Import Paddocks',
  'mobble-apps.app.import-agworld-paddocks.title': 'Import Agworld Paddocks',
  'mobs_can_be_merged.alert.cancel': "Close",
  'mobs_can_be_merged.alert.confirm': 'View mobs',
  'mobs_can_be_merged.alert.message': "One or more of the mobs you have moved can be merged in '%PADDOCK'",
  'mobs_can_be_merged.alert.title': 'Mobs can be merged',
  'mobs.filter.actions.apply_filter': 'Apply filter',
  'mobs.filter.actions.clear_filter': 'Clear filter',
  'mobs.filter.sections.age.from.placeholder': 'From',
  'mobs.filter.sections.age.title': 'Livestock ages',
  'mobs.filter.sections.age.to.placeholder': 'To',
  'mobs.filter.sections.breed.title': 'Breed',
  'mobs.filter.sections.class.title': 'Class',
  'mobs.filter.sections.gender.title': 'Gender',
  'mobs.filter.sections.livestock_type.title': 'Livestock type',
  'mobs.filter.sections.range.from.placeholder': 'From',
  'mobs.filter.sections.range.to.placeholder': 'To',
  'mobs.filter.summary.age.from': '**age** from %FROM',
  'mobs.filter.summary.age.from_to': '**age** from %FROM to %TO',
  'mobs.filter.summary.age.to': '**age** to %TO',
  'mobs.filter.summary.body': 'Filtering by %VALUE',
  'mobs.filter.summary.breed': '**breed:** %VALUE',
  'mobs.filter.summary.classes': 'Classes: %VALUE',
  'mobs.filter.summary.conjunction': ' and ',
  'mobs.filter.summary.gender': '**gender:** %VALUE',
  'mobs.filter.summary.livestock_type': '**livestock type:** %VALUE',
  'mobs.filter.summary.or': ' or ',
  'mobs.filter.summary.search': '**search query:** %VALUE',
  'mobs.list.empty.add': 'Add a mob below',
  'mobs.list.empty.filter.line1': 'There are currently no mobs matching your filter.',
  'mobs.list.empty.filter.line2': 'Try to alter you filter or clear it to show all mobs.',
  'mobs.list.empty.line1': 'Mobs you create will be listed here.',
  'mobs.list.empty.paddock': 'There are currently no mobs registered in this paddock.',
  'mobs.list.loading.mobs': 'Loading mobs...',
  'mobs.list.loading.paddocks': 'Loading paddocks...',
  'mobs.list.mob.actions.action.edit': 'Edit mob',
  'mobs.list.mob.actions.title': 'Available Options',
  'mobs.list.mob.card.ages': 'Ages:',
  'mobs.list.mob.card.classes': 'Classes:',
  'mobs.list.mob.card.deleted': '[deleted]',
  'mobs.list.mob.card.DSE': 'DSE: %COUNT',
  'mobs.list.mob.filter.clear': 'Clear',
  'mobs.list.mob.filter.open': 'Filters',
  'mobs.list.mob.filter.search.placeholder': 'Search mobs',
  'mobs.list.result.filtered': 'Showing **%COUNT** of **%TOTAL** mobs',
  'mobs.list.result.paddock_group.header': 'Mobs from paddocks with open gate',
  'mobs.list.result.total_head': '**%TOTAL** head',
  'mobs.list.result.unfiltered': 'Showing **%COUNT** mobs',
  'mobs.mob.create.form.addition_type.label': 'Type of mob addition',
  'mobs.mob.create.form.addition_type.option.natural_increase.label': 'Natural increase (birth)',
  'mobs.mob.create.form.addition_type.option.purchase.label': 'Purchase',
  'mobs.mob.create.form.addition_type.option.setup.label': 'Set up/other',
  'mobs.mob.create.form.cancel.label': 'Cancel',
  'mobs.mob.create.form.mob_form.ages.label': 'Ages',
  'mobs.mob.create.form.mob_form.ages.placeholder': 'Select age(s)',
  'mobs.mob.create.form.mob_form.breed.add_new.button.label': 'Add',
  'mobs.mob.create.form.mob_form.breed.add_new.placeholder.label': 'Add new breed type',
  'mobs.mob.create.form.mob_form.breed.label': 'Breed',
  'mobs.mob.create.form.mob_form.breed.placeholder': 'Select livestock breed',
  'mobs.mob.create.form.mob_form.classes.add_new.button.label': 'Add',
  'mobs.mob.create.form.mob_form.classes.add_new.placeholder.label': 'Add new class type',
  'mobs.mob.create.form.mob_form.classes.label': 'Classes (optional)',
  'mobs.mob.create.form.mob_form.classes.placeholder': 'Select classes',
  'mobs.mob.create.form.mob_form.dse.label': 'DSE',
  'mobs.mob.create.form.mob_form.dse.placeholder': 'Enter amount',
  'mobs.mob.create.form.mob_form.gender.add_new.button.label': 'Add',
  'mobs.mob.create.form.mob_form.gender.add_new.placeholder.label': 'Add new gender type',
  'mobs.mob.create.form.mob_form.gender.label': 'Gender',
  'mobs.mob.create.form.mob_form.gender.placeholder': 'Select gender',
  'mobs.mob.create.form.mob_form.paddock.label': 'Paddock',
  'mobs.mob.create.form.mob_form.paddock.placeholder': 'Select paddock',
  'mobs.mob.create.form.mob_form.size.error.label': 'Head must be a whole number',
  'mobs.mob.create.form.mob_form.size.label': 'Head',
  'mobs.mob.create.form.mob_form.size.placeholder': 'Enter amount',
  'mobs.mob.create.form.mob_form.type.label': 'Livestock type',
  'mobs.mob.create.form.mob_form.type.placeholder': 'Select livestock type',
  'mobs.mob.create.form.natural_increase_form.date.label': 'Increase date',
  'mobs.mob.create.form.natural_increase_form.date.placeholder': 'Select a date',
  'mobs.mob.create.form.natural_increase_form.notes.label': 'Notes (optional)',
  'mobs.mob.create.form.natural_increase_form.notes.placeholder': 'Additional notes',
  'mobs.mob.create.form.next.label': 'Next',
  'mobs.mob.create.form.previous.label': 'Previous',
  'mobs.mob.create.form.purchase_form.date.label': 'Purchase date',
  'mobs.mob.create.form.purchase_form.date.placeholder': 'Select a date',
  'mobs.mob.create.form.purchase_form.notes.label': 'Notes (optional)',
  'mobs.mob.create.form.purchase_form.notes.placeholder': 'Additional notes',
  'mobs.mob.create.form.purchase_form.price_per_head.label': 'Price per head',
  'mobs.mob.create.form.purchase_form.price_per_head.placeholder': 'Enter an amount',
  'mobs.mob.create.form.purchase_form.total_price.label': 'Total price',
  'mobs.mob.create.form.purchase_form.total_price.placeholder': 'Enter an amount',
  'mobs.mob.create.form.purchase_form.vendor.label': 'Vendor (optional)',
  'mobs.mob.create.form.purchase_form.vendor.placeholder': 'Enter a vendor reference',
  'mobs.mob.create.form.submit.label': 'Create Mob',
  'mobs.mob.create.form.title.label': 'Mob',
  'mobs.mob.delete.confirm.cancel': 'Cancel',
  'mobs.mob.delete.confirm.confirm': 'Delete',
  'mobs.mob.delete.confirm.description': 'This will delete the mob.',
  'mobs.mob.delete.confirm.title': 'Are you sure?',
  'mobs.mob.delete.form.delete.label': 'Delete mob',
  'mobs.mob.edit.form.mob_form.ages.label': 'Ages',
  'mobs.mob.edit.form.mob_form.ages.placeholder': 'Select age(s)',
  'mobs.mob.edit.form.mob_form.breed.add_new.button.label': 'Add',
  'mobs.mob.edit.form.mob_form.breed.add_new.placeholder.label': 'New breed type',
  'mobs.mob.edit.form.mob_form.breed.label': 'Breed',
  'mobs.mob.edit.form.mob_form.breed.placeholder': 'Select livestock breed',
  'mobs.mob.edit.form.mob_form.cancel.label': 'Cancel',
  'mobs.mob.edit.form.mob_form.classes.add_new.button.label': 'Add',
  'mobs.mob.edit.form.mob_form.classes.add_new.placeholder.label': 'New class type',
  'mobs.mob.edit.form.mob_form.classes.label': 'Classes (optional)',
  'mobs.mob.edit.form.mob_form.classes.placeholder': 'Select classes',
  'mobs.mob.edit.form.mob_form.DSE.label': 'DSE',
  'mobs.mob.edit.form.mob_form.DSE.placeholder': 'Enter amount',
  'mobs.mob.edit.form.mob_form.gender.add_new.button.label': 'Add',
  'mobs.mob.edit.form.mob_form.gender.add_new.placeholder.label': 'New gender type',
  'mobs.mob.edit.form.mob_form.gender.label': 'Gender',
  'mobs.mob.edit.form.mob_form.gender.placeholder': 'Select gender',
  'mobs.mob.edit.form.mob_form.paddock.label': 'Paddock',
  'mobs.mob.edit.form.mob_form.paddock.placeholder': 'Select paddock',
  'mobs.mob.edit.form.mob_form.size.error.label': 'Head must be a whole number',
  'mobs.mob.edit.form.mob_form.size.label': 'Head',
  'mobs.mob.edit.form.mob_form.size.placeholder': 'Enter amount',
  'mobs.mob.edit.form.mob_form.submit.label': 'Update Mob',
  'mobs.mob.edit.form.mob_form.type.label': 'Livestock type',
  'mobs.mob.edit.form.mob_form.type.placeholder': 'Select livestock type',
  'mobs.sale.create.form.number.error.label': 'Head must be a whole number',
  'mobs.sale.edit.form.number.error.label': 'Head must be a whole number',
  'mobs.table.empty': 'There are no mobs to display',
  'mobs.table.heading.column.ages': 'Ages',
  'mobs.table.heading.column.breed': 'Breed',
  'mobs.table.heading.column.classes': 'Classes',
  'mobs.table.heading.column.dse': 'DSE',
  'mobs.table.heading.column.gender': 'Gender',
  'mobs.table.heading.column.head': 'Head',
  'mobs.table.heading.column.paddock': 'Paddock',
  'mobs.table.heading.column.safe_date': 'Safe Date',
  'mobs.table.heading.column.total_dse': 'Total DSE',
  'mobs.table.heading.column.type': 'Type',
  'modal.user_access.denied.description': 'If you would like to access this page, please contact the owner or administrator of the property to request a change in your access permissions.',
  'modal.user_access.denied.dismiss': 'Close',
  'modal.user_access.denied.title': 'Unable to view page with "%VALUE" permissions',
  'modal.user_access.owner_only.description': 'Only the property owner can access this.',
  'modal.user_access.owner_only.dismiss': 'Close',
  'modal.user_access.owner_only.title': 'Access denied',
  'natural_increases.create.form.title.label': 'Natural Increase',
  'natural_increases.filter.group.year.label': 'Year',
  'natural_increases.summary_table.amount_of_head.label': 'Total natural increase',
  'natural_increases.summary_table.amount_of_records.label': 'Amount of records',
  'natural_increases.table.empty': 'There are no Natural Increases to display',
  'natural_increases.table.heading.column.date': 'Date',
  'natural_increases.table.heading.column.head': 'Head',
  'natural_increases.table.heading.column.mob': 'Mob',
  'natural_increases.table.heading.column.notes': 'Notes',
  'natural_increases.table.heading.column.paddock': "Paddock",
  'naturalIncrease.edit.form.cancel.label': 'Cancel',
  'naturalIncrease.edit.form.date.label': 'Date',
  'naturalIncrease.edit.form.note.label': 'Notes',
  'naturalIncrease.edit.form.note.placeholder': 'Notes',
  'naturalIncrease.edit.form.number.label': 'Number',
  'naturalIncrease.edit.form.number.placeholder': 'Number',
  'naturalIncrease.edit.form.submit.label': 'Update',
  'naturalIncrease.info.table.row.date.label': 'Date',
  'naturalIncrease.info.table.row.mob.label': 'Mob',
  'naturalIncrease.info.table.row.notes.label': 'Notes',
  'naturalIncrease.info.table.row.number.label': 'Number',
  'naturalIncrease.info.table.row.paddock.label': 'Paddock',
  'naturalIncreases.filter.group.breed.label': 'Breed',
  'naturalIncreases.filter.group.gender.label': 'Gender',
  'naturalIncreases.filter.group.number.label': 'Number',
  'naturalIncreases.filter.group.type.date_range.label': 'Date range',
  'naturalIncreases.naturalIncrease.delete.confirm.cancel': 'Cancel',
  'naturalIncreases.naturalIncrease.delete.confirm.confirm': 'Delete',
  'naturalIncreases.naturalIncrease.delete.confirm.description': 'This will delete the Natural Increase from your records.',
  'naturalIncreases.naturalIncrease.delete.confirm.title': 'Are you sure?',
  'naturalIncreases.naturalIncrease.delete.form.delete.label': 'Delete Natural Increase',
  'naturalIncreases.summary_table.amount_of_natural_increases.label': 'Amount of Records',
  'naturalIncreases.summary_table.head_headNatural_increase.label': 'Amount of Natural Increase',
  'no_property_access.screen.description': 'If you want to use the app, either ask the subscription owner to invite you to a property or start a Mobble trial.',
  'no_property_access.screen.title': 'You are not currently part of any property',
  'offline.no_data.screen.button': 'Reconnect to Mobble',
  'offline.no_data.screen.description': 'Currently Offline',
  'paddock_action.create.form.action_type.add.button.label': 'Add',
  'paddock_action.create.form.action_type.add.placeholder.label': 'Add a new action type',
  'paddock_action.create.form.action_type.label': 'Action type',
  'paddock_action.create.form.action_type.placeholder': 'Select action type',
  'paddock_action.create.form.cancel.label': 'Cancel',
  'paddock_action.create.form.date.label': 'Date',
  'paddock_action.create.form.date.placeholder': 'Select a date',
  'paddock_action.create.form.inventory_items.label': 'Product applications',
  'paddock_action.create.form.inventory_items.placeholder': 'Select products used',
  'paddock_action.create.form.note.label': 'Notes',
  'paddock_action.create.form.note.placeholder': 'Action details/notes',
  'paddock_action.create.form.paddock.label': 'Paddocks',
  'paddock_action.create.form.paddock.placeholder': 'Select paddock(s) to apply action to',
  'paddock_action.create.form.quantity.label': 'Quantity/Ha',
  'paddock_action.create.form.submit.label': 'Apply action',
  'paddock_action.create.form.title.label': 'Paddock action',
  'paddock_action.create.form.whp.label': 'WHP',
  'paddock_action.edit.form.action_type.add.button.label': 'Add',
  'paddock_action.edit.form.action_type.add.placeholder.label': 'Select an action type',
  'paddock_action.edit.form.action_type.label': 'Action type',
  'paddock_action.edit.form.action_type.placeholder': 'Select action type',
  'paddock_action.edit.form.cancel.label': 'Cancel',
  'paddock_action.edit.form.date.label': 'Date',
  'paddock_action.edit.form.date.placeholder': 'Select a date',
  'paddock_action.edit.form.inventory_items.label': 'Product applications',
  'paddock_action.edit.form.inventory_items.placeholder': 'Select products used',
  'paddock_action.edit.form.note.label': 'Notes',
  'paddock_action.edit.form.note.placeholder': 'Action details/notes',
  'paddock_action.edit.form.paddock.label': 'Paddocks',
  'paddock_action.edit.form.paddock.placeholder': 'Select paddock(s) to apply action to',
  'paddock_action.edit.form.quantity.label': 'Quantity/Ha',
  'paddock_action.edit.form.submit.label': 'Update action',
  'paddock_action.edit.form.whp.label': 'WHP',
  'paddock_action.info.table.row.applied_inventory_items.label': 'Product applications',
  'paddock_action.info.table.row.created_by.label': 'Recorded by',
  'paddock_action.info.table.row.date.label': 'Date',
  'paddock_action.info.table.row.description.label': 'Description',
  'paddock_action.info.table.row.esi.label': 'ESI',
  'paddock_action.info.table.row.note.label': 'Note',
  'paddock_action.info.table.row.paddocks.label': 'Applied to paddocks',
  'paddock_action.info.table.row.quantity.label': 'Quantity/Ha used',
  'paddock_action.info.table.row.type.label': 'Action type',
  'paddock_action.info.table.row.whp.label': 'WHP',
  'paddock_action.info.table.row.woolWhp.label': 'Wool WHP',
  'paddock_actions.filter.group.type.label': 'Paddock action type',
  'paddock_actions.table.empty': 'There are no paddock actions to display',
  'paddock_actions.table.heading.column.applied_to': 'Applied to',
  'paddock_actions.table.heading.column.created_by': 'Recorded by',
  'paddock_actions.table.heading.column.date': 'Date',
  'paddock_actions.table.heading.column.notes': 'Notes',
  'paddock_actions.table.heading.column.products': 'Products',
  'paddock_actions.table.heading.column.type': 'Type',
  'paddock-import.form.continue.button': 'Continue to Paddocks',
  'paddock-import.form.failed.label': 'Failed to import %NUMBER paddocks',
  'paddock-import.form.finished.label': 'Finished importing paddocks',
  'paddock-import.form.import.button': 'Import Paddocks',
  'paddock-import.form.title': 'Import AgWorld Paddocks',
  'paddock.info.table.row.average_dse_per_ha.label': 'Average DSE/ha',
  'paddock.info.table.row.average_dse_per_ha.note': '(last 12 months)',
  'paddock.info.table.row.days_grazed.label': 'Days grazed',
  'paddock.info.table.row.days_rested.label': 'Days rested',
  'paddock.info.table.row.dse_per_ha.label': 'DSE/Ha',
  'paddock.info.table.row.grazeable_area.label': 'Grazeable area',
  'paddock.info.table.row.number_of_head.label': 'Number of head',
  'paddock.info.table.row.paddock_group.label': 'Opened with',
  'paddock.info.table.row.paddock_type.label': 'Paddock type',
  'paddock.info.table.row.paddock_type.note.yard': 'A yard is a special paddock type that can be used for the bulk processing of livestock such as, scanning, weaning, merging, reclassing and more.',
  'paddock.info.table.row.total_area.label': 'Total area',
  'paddock.info.table.row.total_dse.label': 'Total DSE',
  'paddock.info.table.row.whp.is_safe': 'Safe',
  'paddock.info.table.row.whp.label': 'WHP',
  'paddock.tasks.list.empty': 'No tasks on paddock.',
  'paddockActions.paddockAction.delete.confirm.cancel': 'Cancel',
  'paddockActions.paddockAction.delete.confirm.confirm': 'Delete Action',
  'paddockActions.paddockAction.delete.confirm.description': 'This will delete the Paddock Action from your records.',
  'paddockActions.paddockAction.delete.confirm.title': 'Are you sure?',
  'paddockActions.paddockAction.delete.form.delete.label': 'Delete Paddock Action',
  'paddocks.actions.action.add_mob': 'Add Mob',
  'paddocks.actions.action.filter_paddocks': 'Filter paddocks',
  'paddocks.actions.action.show_filter_result_on_map.description': 'Show the paddocks currently filtered on the map.',
  'paddocks.actions.action.show_filter_result_on_map.label': 'Show filter result on map',
  'paddocks.filter.actions.apply_filter': 'Apply filter',
  'paddocks.filter.actions.clear_filter': 'Clear filter',
  'paddocks.filter.sections.age.from.placeholder': 'From',
  'paddocks.filter.sections.age.title': 'Livestock ages',
  'paddocks.filter.sections.age.to.placeholder': 'To',
  'paddocks.filter.sections.breed.title': 'Breed',
  'paddocks.filter.sections.class.title': 'Class',
  'paddocks.filter.sections.gender.title': 'Gender',
  'paddocks.filter.sections.livestock_type.title': 'Livestock type',
  'paddocks.filter.sections.paddock_type.title': 'Paddock type',
  'paddocks.filter.sections.range.from.placeholder': 'From',
  'paddocks.filter.sections.range.to.placeholder': 'To',
  'paddocks.filter.summary.age.from': '**age** from %FROM',
  'paddocks.filter.summary.age.from_to': '**age** from %FROM to %TO',
  'paddocks.filter.summary.age.to': '**age** to %TO',
  'paddocks.filter.summary.body': 'Filtering by %VALUE',
  'paddocks.filter.summary.breed': '**breed:** %VALUE',
  'paddocks.filter.summary.classes': 'Classes: %VALUE',
  'paddocks.filter.summary.conjunction': ' and ',
  'paddocks.filter.summary.gender': '**gender:** %VALUE',
  'paddocks.filter.summary.livestock_type': '**livestock type:** %VALUE',
  'paddocks.filter.summary.or': ' or ',
  'paddocks.filter.summary.paddock_size.from': '**paddock size** from %FROMha',
  'paddocks.filter.summary.paddock_size.from_to': '**paddock size** from %FROMha to %TOha',
  'paddocks.filter.summary.paddock_size.to': '**paddock size** to %TOha',
  'paddocks.filter.summary.paddock_type': '**paddock type:** %VALUE',
  'paddocks.filter.summary.search': '**search query:** %VALUE',
  'paddocks.list.empty.add': 'Add a paddock below',
  'paddocks.list.empty.filter.line1': 'There are currently no paddocks matching your filter.',
  'paddocks.list.empty.filter.line2': 'Try to alter you filter or clear it to show all paddocks.',
  'paddocks.list.empty.line1': 'Paddocks you create will be listed here.',
  'paddocks.list.loading.mobs': 'Loading mobs...',
  'paddocks.list.loading.paddocks': 'Loading paddocks...',
  'paddocks.list.paddock.actions.action.add_mob.description': 'Add a mob on this paddock.',
  'paddocks.list.paddock.actions.action.add_mob.label': 'Add mob',
  'paddocks.list.paddock.actions.action.add_paddock_action.description': 'Record an action performed on this paddock.',
  'paddocks.list.paddock.actions.action.add_paddock_action.label': 'Add paddock action',
  'paddocks.list.paddock.actions.action.move_mobs.description': 'Move mobs to another paddock.',
  'paddocks.list.paddock.actions.action.move_mobs.label': 'Move or Split',
  'paddocks.list.paddock.actions.action.paddock_action.description': 'Record an action applied to selected paddock.',
  'paddocks.list.paddock.actions.action.paddock_action.label': 'Add paddock action',
  'paddocks.list.paddock.card.ages_summary': 'Ages:\t%SUMMARY',
  'paddocks.list.paddock.card.badge.average_dse_ha': '%COUNT Av. DSE/ha',
  'paddocks.list.paddock.card.badge.dse_ha': '%COUNT DSE/ha',
  'paddocks.list.paddock.card.badge.heads': '%COUNT Head',
  'paddocks.list.paddock.card.badge.last_grazing_history.grazed': '%COUNT days grazed',
  'paddocks.list.paddock.card.badge.last_grazing_history.rested': '%COUNT days rested',
  'paddocks.list.paddock.card.badge.paddock_size': '%COUNT Ha',
  'paddocks.list.paddock.card.classes_summary': 'Classes:\t%SUMMARY',
  'paddocks.list.paddock.card.open_with': 'Open with %PADDOCK_NAMES',
  'paddocks.list.paddock.card.stat.average_dse_ha': 'Av. DSE/ha',
  'paddocks.list.paddock.card.stat.dse_ha': 'DSE/ha',
  'paddocks.list.paddock.card.stat.last_grazing_history.days': '%COUNT days',
  'paddocks.list.paddock.card.stat.last_grazing_history.grazed': 'grazed',
  'paddocks.list.paddock.card.stat.last_grazing_history.rested': 'rested',
  'paddocks.list.paddock.card.stat.mobs.heads': 'heads',
  'paddocks.list.paddock.filter.clear': 'Clear',
  'paddocks.list.paddock.filter.open': 'Filters',
  'paddocks.list.paddock.filter.search.placeholder': 'Search paddocks',
  'paddocks.list.result.filtered': 'Showing **%COUNT** of **%TOTAL** paddocks',
  'paddocks.list.result.total_ha': '**%TOTAL** ha',
  'paddocks.list.result.unfiltered': 'Showing **%COUNT** paddocks',
  'paddocks.list.section.non_yard_paddocks.title': 'Non Yard Paddocks',
  'paddocks.list.section.yard_paddocks.title': 'Yard Paddocks',
  'paddocks.paddock_group.create.form.cancel.label': 'Cancel',
  'paddocks.paddock_group.create.form.paddocks.label': 'Paddocks',
  'paddocks.paddock_group.create.form.paddocks.placeholder': 'Select paddocks',
  'paddocks.paddock_group.create.form.paddocks.validation.message': 'Must select at least two Paddocks to connect.',
  'paddocks.paddock_group.create.form.submit.label': 'Open Gates',
  'paddocks.paddock_group.delete.confirm.cancel': 'Cancel',
  'paddocks.paddock_group.delete.confirm.confirm': 'Ok',
  'paddocks.paddock_group.delete.confirm.description': 'This will close gates between paddocks.',
  'paddocks.paddock_group.delete.confirm.title': 'Are you sure?',
  'paddocks.paddock_group.delete.form.delete.label': 'Close gates between all paddocks',
  'paddocks.paddock_group.edit.form.cancel.label': 'Cancel',
  'paddocks.paddock_group.edit.form.paddocks.label': 'Paddocks',
  'paddocks.paddock_group.edit.form.paddocks.placeholder': 'Paddocks',
  'paddocks.paddock_group.edit.form.submit.label': 'Update',
  'paddocks.paddock.create.form.cancel.label': 'Cancel',
  'paddocks.paddock.create.form.grazeable_area.label': 'Grazeable area',
  'paddocks.paddock.create.form.grazeable_area.placeholder': 'Enter area',
  'paddocks.paddock.create.form.name.label': 'Paddock name',
  'paddocks.paddock.create.form.name.placeholder': 'Enter a paddock name',
  'paddocks.paddock.create.form.paddock_form.paddockTypes.add_new.button.label': 'Add',
  'paddocks.paddock.create.form.paddock_form.paddockTypes.add_new.placeholder.label': 'Add new paddock type',
  'paddocks.paddock.create.form.paddock_type.label': 'Paddock type',
  'paddocks.paddock.create.form.paddock_type.placeholder': 'Select a paddock type',
  'paddocks.paddock.create.form.points.label': 'Paddock boundaries',
  'paddocks.paddock.create.form.submit.label': 'Add new paddock',
  'paddocks.paddock.create.form.title.label': 'Paddock',
  'paddocks.paddock.delete.confirm.cancel': 'Cancel',
  'paddocks.paddock.delete.confirm.confirm': 'Delete',
  'paddocks.paddock.delete.confirm.description': 'This will delete the paddock.',
  'paddocks.paddock.delete.confirm.title': 'Are you sure?',
  'paddocks.paddock.delete.form.can_not_delete': "You can't delete a paddock that contains mobs. Please move the mobs to another paddock first.",
  'paddocks.paddock.delete.form.delete.label': 'Delete paddock',
  'paddocks.paddock.edit.button.add_geometry.label': 'Add paddock boundaries',
  'paddocks.paddock.edit.button.edit_geometry.label': 'Edit paddock boundaries',
  'paddocks.paddock.edit.form.cancel.label': 'Cancel',
  'paddocks.paddock.edit.form.grazeable_area.label': 'Grazeable area',
  'paddocks.paddock.edit.form.grazeable_area.placeholder': 'Enter area',
  'paddocks.paddock.edit.form.name.label': 'Paddock name',
  'paddocks.paddock.edit.form.name.placeholder': 'Enter a paddock name',
  'paddocks.paddock.edit.form.paddock_form.paddockTypes.add_new.button.label': 'Add',
  'paddocks.paddock.edit.form.paddock_form.paddockTypes.add_new.placeholder.label': 'Add new paddock type',
  'paddocks.paddock.edit.form.paddock_type.label': 'Paddock type',
  'paddocks.paddock.edit.form.paddock_type.placeholder': 'Select a paddock type',
  'paddocks.paddock.edit.form.points.label': 'Paddock boundaries',
  'paddocks.paddock.edit.form.submit.label': 'Update paddock',
  'paddocks.paddock.merge_mobs.form.ages.label': 'Combined ages',
  'paddocks.paddock.merge_mobs.form.ages.placeholder': 'Select ages',
  'paddocks.paddock.merge_mobs.form.cancel.label': 'Cancel',
  'paddocks.paddock.merge_mobs.form.classes.label': 'Combined classes',
  'paddocks.paddock.merge_mobs.form.classes.placeholder': 'Select classes',
  'paddocks.paddock.merge_mobs.form.date.label': 'Date',
  'paddocks.paddock.merge_mobs.form.date.placeholder': 'Select a date',
  'paddocks.paddock.merge_mobs.form.dse.label': 'Combined DSE',
  'paddocks.paddock.merge_mobs.form.dse.placeholder': 'Enter amount',
  'paddocks.paddock.merge_mobs.form.mobs.label': 'Mobs to merge',
  'paddocks.paddock.merge_mobs.form.mobs.placeholder': 'Select mobs',
  'paddocks.paddock.merge_mobs.form.mobs.selection.multiple': '%COUNT Mobs selected',
  'paddocks.paddock.merge_mobs.form.mobs.selection.single': '1 Mob selected',
  'paddocks.paddock.merge_mobs.form.new_mob_preview.label': 'New mob preview',
  'paddocks.paddock.merge_mobs.form.submit.label': 'Merge mobs',
  'paddocks.paddock.merge_mobs.progress.label': 'Merging mobs',
  'paddocks.paddock.mobs.entities_list.count.title.multiple': '**%COUNT** mobs in paddock',
  'paddocks.paddock.mobs.entities_list.count.title.single': '**1** mob in paddock',
  'paddocks.paddock.mobs.selected_mobs_menu.actions.apply_action.label': 'Apply action',
  'paddocks.paddock.mobs.selected_mobs_menu.actions.merge_mob.label': 'Merge mob',
  'paddocks.paddock.mobs.selected_mobs_menu.actions.move_mob.label': 'Move mob',
  'paddocks.paddock.move_mob.form.advanced.label': 'Advanced mob movement/split options',
  'paddocks.paddock.move_mob.form.cancel.label': 'Cancel',
  'paddocks.paddock.move_mob.form.date.label': 'Date',
  'paddocks.paddock.move_mob.form.date.placeholder': 'Select a date',
  'paddocks.paddock.move_mob.form.mob_split.button_split.label': 'Split',
  'paddocks.paddock.move_mob.form.mob_split.cancel_split.label': 'Cancel',
  'paddocks.paddock.move_mob.form.mob_split.new.title': 'Moving mob',
  'paddocks.paddock.move_mob.form.mob_split.remaining.title': 'Remaining mob',
  'paddocks.paddock.move_mob.form.mob_split.title': 'Splitting mob',
  'paddocks.paddock.move_mob.form.mobs.label': 'Mobs to move',
  'paddocks.paddock.move_mob.form.mobs.placeholder': 'Select mobs',
  'paddocks.paddock.move_mob.form.mobs.selection.multiple': '%COUNT Mobs selected',
  'paddocks.paddock.move_mob.form.mobs.selection.single': '1 Mob selected',
  'paddocks.paddock.move_mob.form.paddock.label': 'Move to paddock',
  'paddocks.paddock.move_mob.form.paddock.placeholder': 'Select paddock',
  'paddocks.paddock.move_mob.form.paddockNameFrom.label': 'Move from paddock',
  'paddocks.paddock.move_mob.form.property.label': 'Move to property',
  'paddocks.paddock.move_mob.form.property.placeholder': 'Select property',
  'paddocks.paddock.move_mob.form.quick.title': 'Move mob',
  'paddocks.paddock.move_mob.form.submit.label': 'Move mob',
  'paddocks.paddock.move_mob.mob_split.form.ages.label': 'Ages',
  'paddocks.paddock.move_mob.mob_split.form.ages.placeholder': 'Select ages',
  'paddocks.paddock.move_mob.mob_split.form.classes.label': 'Classes',
  'paddocks.paddock.move_mob.mob_split.form.classes.placeholder': 'Select classes',
  'paddocks.paddock.move_mob.mob_split.form.gender.label': 'Gender',
  'paddocks.paddock.move_mob.mob_split.form.gender.placeholder': 'Select gender',
  'paddocks.paddock.move_mob.mob_split.form.size.label': 'Head',
  'paddocks.paddock.move_mob.mob_split.form.size.placeholder': 'Enter amount',
  'paddocks.table.heading.column.actions': 'Actions',
  'paddocks.table.heading.column.amount_of_mobs': 'Mobs',
  'paddocks.table.heading.column.average_dse_ha': 'Average DSE/Ha',
  'paddocks.table.heading.column.days_grazed': 'Days grazed/rested',
  'paddocks.table.heading.column.dse_ha': 'DSE/Ha',
  'paddocks.table.heading.column.grazeable_area': 'Grazeable area',
  'paddocks.table.heading.column.name': 'Paddock name',
  'paddocks.table.heading.column.opened_with': 'Opened with',
  'paddocks.table.heading.column.total_area': 'Total area',
  'paddocks.table.heading.column.total_dse': 'Total DSE',
  'paddocks.table.heading.column.total_head': 'Head',
  'paddocks.table.heading.column.type': 'Type',
  'prelude.error.actionQueue': 'Error gathering offline requests.',
  'prelude.error.auth': 'Error gathering authentication information.',
  'prelude.error.casualties': 'Error gathering casualties.',
  'prelude.error.chats': 'Error gathering messages.',
  'prelude.error.FORBIDDEN': 'You do not have permission to access this resource.',
  'prelude.error.historicEvents': 'Error gathering events.',
  'prelude.error.inventories': 'Error gathering inventories.',
  'prelude.error.inventoryItems': 'Error gathering inventory items.',
  'prelude.error.mobActions': 'Error gathering mob action.',
  'prelude.error.mobs': 'Error gathering mobs.',
  'prelude.error.not_found.description': 'The requested item was not found.',
  'prelude.error.not_found.title': 'Item not found.',
  'prelude.error.paddockActions': 'Error gathering paddock action.',
  'prelude.error.paddockGeometry': 'Error gathering paddocks geometry.',
  'prelude.error.paddockGroupedStockingRate': 'Error gathering paddock stocking rates',
  'prelude.error.paddockGroupGeometry': 'Error gathering open gates geometry.',
  'prelude.error.paddockGroups': 'Error gathering paddocks.',
  'prelude.error.paddocks': 'Error gathering paddocks.',
  'prelude.error.properties': 'Error gathering properties.',
  'prelude.error.purchases': 'Error gathering purchases.',
  'prelude.error.rainGaugeReadings': 'Error gathering rain gauge readings.',
  'prelude.error.rainGauges': 'Error gathering rain gauges.',
  'prelude.error.sales': 'Error gathering sales.',
  'prelude.error.settings': 'Error gathering settings.',
  'prelude.error.tasks': 'Error gathering tasks.',
  'prelude.loading.actionQueue': 'Loading offline requests.',
  'prelude.loading.auth': 'Loading authentication.',
  'prelude.loading.casualties': 'Loading casualties.',
  'prelude.loading.chats': 'Loading messages.',
  'prelude.loading.historicEvents': 'Loading events.',
  'prelude.loading.inventories': 'Loading inventories.',
  'prelude.loading.inventoryItems': 'Loading inventory items.',
  'prelude.loading.maps': 'Loading maps.',
  'prelude.loading.mobActions': 'Loading mob action.',
  'prelude.loading.mobs': 'Loading mobs.',
  'prelude.loading.naturalIncreases': 'Loading natural increase.',
  'prelude.loading.paddockActions': 'Loading paddock action.',
  'prelude.loading.paddockGeometry': 'Loading paddocks geometry.',
  'prelude.loading.paddockGroupedStockingRate': 'Loading stocking ratees',
  'prelude.loading.paddockGroupGeometry': 'Loading open gates geometry.',
  'prelude.loading.paddockGroups': 'Loading paddocks.',
  'prelude.loading.paddocks': 'Loading paddocks.',
  'prelude.loading.properties': 'Loading properties.',
  'prelude.loading.purchases': 'Loading purchases.',
  'prelude.loading.rainGaugeReadings': 'Loading rain gauge readings.',
  'prelude.loading.rainGauges': 'Loading rain gauges.',
  'prelude.loading.required': 'Loading remaining required items.',
  'prelude.loading.sales': 'Loading sales.',
  'prelude.loading.settings': 'Loading settings.',
  'prelude.loading.tasks': 'Loading tasks.',
  'prevent_going_back.alert.cancel': "Don't leave",
  'prevent_going_back.alert.confirm': 'Discard',
  'prevent_going_back.alert.message': 'You have unsaved changes. Are you sure to discard them and leave the screen?',
  'prevent_going_back.alert.title': 'Discard changes?',
  'properties_selector.modal.title': 'Select properties',
  'properties.create.error': 'Something went wrong.\nThe subscription or trial period may have expired.',
  'properties.create.form.cancel.label': 'Cancel',
  'properties.create.form.name.label': 'Property name',
  'properties.create.form.name.placeholder': 'Enter a name for your property',
  'properties.create.form.pic.label': 'Property identification number',
  'properties.create.form.pic.placeholder': 'Property identification number (optional)',
  'properties.create.form.submit.label': 'Create property',
  'properties.delete.cancel': 'Cancel',
  'properties.delete.confirm.cancel': 'Cancel',
  'properties.delete.confirm.confirm': 'Confirm',
  'properties.delete.confirm.description': 'This will delete the property.',
  'properties.delete.confirm.title': 'Are you sure?',
  'properties.delete.form.delete.label': 'Delete',
  'property_selector.modal.title': 'Select property',
  'purchase.edit.form.cancel.label': 'Cancel',
  'purchase.edit.form.date.label': 'Date',
  'purchase.edit.form.notes.label': 'Notes',
  'purchase.edit.form.notes.placeholder': 'Notes',
  'purchase.edit.form.number.label': 'Head',
  'purchase.edit.form.number.placeholder': 'Amount of mobs',
  'purchase.edit.form.price_per_head.label': 'Price per head',
  'purchase.edit.form.price_per_head.placeholder': '$0',
  'purchase.edit.form.seller.label': 'Seller (optional)',
  'purchase.edit.form.seller.placeholder': 'Seller',
  'purchase.edit.form.submit.label': 'Update',
  'purchase.edit.form.total_price.label': 'Total price',
  'purchase.edit.form.total_price.placeholder': '$0',
  'purchase.info.table.row.date.label': 'Date',
  'purchase.info.table.row.mob.label': 'Mob',
  'purchase.info.table.row.notes.label': 'Notes',
  'purchase.info.table.row.number.label': 'Head',
  'purchase.info.table.row.paddock.label': 'Paddock',
  'purchase.info.table.row.price_per_head.label': 'Price per head',
  'purchase.info.table.row.purchased_from.label': 'Purchased from',
  'purchase.info.table.row.total_spend.label': 'Total spend',
  'purchases.filter.group.breed.label': 'Breed',
  'purchases.filter.group.gender.label': 'Gender',
  'purchases.filter.group.number.label': 'Number',
  'purchases.filter.group.type.date_range.label': 'Date range',
  'purchases.filter.group.year.label': 'Year',
  'purchases.purchase.delete.confirm.cancel': 'Cancel',
  'purchases.purchase.delete.confirm.confirm': 'Delete',
  'purchases.purchase.delete.confirm.description': 'This will delete the purchase.',
  'purchases.purchase.delete.confirm.title': 'Are you sure?',
  'purchases.purchase.delete.form.delete.label': 'Delete Purchase',
  'purchases.summary_table.amount_of_purchases.label': 'Amount of purchases',
  'purchases.summary_table.average_cost_per_head.label': 'Average cost per head',
  'purchases.summary_table.head_purchased.label': 'Head purchased',
  'purchases.summary_table.total_cost.label': 'Total cost',
  'purchases.table.empty': 'There are no purchases to display',
  'purchases.table.heading.column.classes': 'Classes',
  'purchases.table.heading.column.date': 'Date',
  'purchases.table.heading.column.head': 'Head',
  'purchases.table.heading.column.mob': 'Mob',
  'purchases.table.heading.column.notes': 'Notes',
  'purchases.table.heading.column.paddock': "Paddock",
  'purchases.table.heading.column.price_per_head': "Price Per Head",
  'purchases.table.heading.column.price_per_head.average': 'Avg: %VALUE',
  'purchases.table.heading.column.total': "Total",
  'purchases.table.heading.column.vendor': "Vendor",
  'rain-gauge.info.table.row.custom-year.label': 'Total (%RAIN_GAUGE_YEAR)',
  'rain-gauge.info.table.row.last_reading_date.label': 'Last reading date',
  'rain-gauge.info.table.row.last_reading.label': 'Last reading',
  'rain-gauge.info.table.row.mtd.label': 'Month to date',
  'rain-gauge.info.table.row.name.label': 'Name',
  'rain-gauge.info.table.row.ytd.label': 'Year to date',
  'rain-gauge.reading.table.row.amount.label': 'Reading',
  'rain-gauge.reading.table.row.date.label': 'Date',
  'rain-gauge.reading.table.row.user.label': 'Recorded by',
  'rain-gauges.list.empty.add': 'Add a new rain gauge below',
  'rain-gauges.list.rain-gauge.card.last_reading': '**Last reading:** %DATE',
  'rain-gauges.rain-gauge-reading.delete.confirm.cancel': 'Cancel',
  'rain-gauges.rain-gauge-reading.delete.confirm.confirm': 'Delete',
  'rain-gauges.rain-gauge-reading.delete.confirm.description': 'This will delete the rain gauge reading.',
  'rain-gauges.rain-gauge-reading.delete.confirm.title': 'Are you sure?',
  'rain-gauges.rain-gauge-reading.delete.form.delete.label': 'Delete rain gauge reading',
  'rain-gauges.rain-gauge.create.form.cancel.label': 'Cancel',
  'rain-gauges.rain-gauge.create.form.name.label': 'Name',
  'rain-gauges.rain-gauge.create.form.name.placeholder': 'Rain gauge name',
  'rain-gauges.rain-gauge.create.form.submit.label': 'Create',
  'rain-gauges.rain-gauge.create.form.title.label': 'Rain guage',
  'rain-gauges.rain-gauge.delete.confirm.cancel': 'Cancel',
  'rain-gauges.rain-gauge.delete.confirm.confirm': 'Delete',
  'rain-gauges.rain-gauge.delete.confirm.description': 'This will delete the rain gauge.',
  'rain-gauges.rain-gauge.delete.confirm.title': 'Are you sure?',
  'rain-gauges.rain-gauge.delete.form.delete.label': 'Delete rain gauge',
  'rain-gauges.rain-gauge.edit.form.cancel.label': 'Cancel',
  'rain-gauges.rain-gauge.edit.form.date.label': 'Date',
  'rain-gauges.rain-gauge.edit.form.name.label': 'Rain gauge name',
  'rain-gauges.rain-gauge.edit.form.submit.label': 'Update',
  'rain-gauges.rain-gauge.edit.form.value.label': 'Reading',
  'rain-gauges.rain-gauge.edit.form.value.placeholder': '0',
  'rain-gauges.rain-gauge.reading.create.form.cancel.label': 'Cancel',
  'rain-gauges.rain-gauge.reading.create.form.date.label': 'Date',
  'rain-gauges.rain-gauge.reading.create.form.rainGauge.label': 'Rain gauge',
  'rain-gauges.rain-gauge.reading.create.form.rainGauge.placeholder': 'Select a rain gauge',
  'rain-gauges.rain-gauge.reading.create.form.submit.label': 'Create',
  'rain-gauges.rain-gauge.reading.create.form.value.label': 'Reading',
  'rain-gauges.rain-gauge.reading.create.form.value.placeholder': '0',
  'rain-gauges.rain-gauge.reading.edit.form.cancel.label': 'Cancel',
  'rain-gauges.rain-gauge.reading.edit.form.submit.label': 'Create',
  'rain-gauges.rain-gauge.year_selector.modal.title': 'Select year',
  'refer-farmer.options.accountant.label': 'Accountant',
  'refer-farmer.options.acquaintance.label': 'Acquaintance',
  'refer-farmer.options.farm-advisor.label': 'Farm Advisor',
  'refer-farmer.options.stock-agent.label': 'Stock Agent',
  'refer-farmer.refer-farmer-form.form.cancel.label': 'Cancel',
  'refer-farmer.refer-farmer-form.form.email.label': "Farmer's Email",
  'refer-farmer.refer-farmer-form.form.email.placeholder': "Email",
  'refer-farmer.refer-farmer-form.form.farmName.label': 'Farm Name',
  'refer-farmer.refer-farmer-form.form.farmName.placeholder': 'Farm Name',
  'refer-farmer.refer-farmer-form.form.firstName.label': "Farmer's First Name",
  'refer-farmer.refer-farmer-form.form.firstName.placeholder': "First Name",
  'refer-farmer.refer-farmer-form.form.lastName.label': "Farmer's Last Name",
  'refer-farmer.refer-farmer-form.form.lastName.placeholder': "Last Name",
  'refer-farmer.refer-farmer-form.form.message.label': 'Invite Message to Farmer',
  'refer-farmer.refer-farmer-form.form.message.placeholder': 'Invite Message',
  'refer-farmer.refer-farmer-form.form.relationship.label': 'Your Relationship',
  'refer-farmer.refer-farmer-form.form.relationship.placeholder': 'Your Relationship',
  'refer-farmer.refer-farmer-form.form.role.label': "Farmer's Role",
  'refer-farmer.refer-farmer-form.form.role.placeholder': "Role",
  'refer-farmer.refer-farmer-form.form.submit.label': 'Send Invite',
  'refer-farmer.refer-farmer-form.submit.success.button': 'Go to Dashboard',
  'refer-farmer.refer-farmer-form.submit.success.heading': 'Thank you for referring Mobble!',
  'refer-farmer.refer-farmer-form.submit.success.message': 'Your referral email is now on it\'s way to %FIRST.NAME.',
  'replay.action.casualties.create.description': 'Register new casualty;\nMob:\t\t%MOB.BREED %MOB.GENDER',
  'replay.action.casualties.create.title': 'Register new casualty',
  'replay.action.casualties.delete.description': 'Delete casualty;\nMob:\t\t%MOB.BREED %MOB.GENDER',
  'replay.action.casualties.delete.title': 'Delete casualty',
  'replay.action.casualties.update.description': 'Edit casualty;\nMob:\t\t%MOB.BREED %MOB.GENDER',
  'replay.action.casualties.update.title': 'Edit casualty',
  'replay.action.chats.create_chat.description': 'Add a new chat message with the following text\nmessage: "**%MESSAGE**"',
  'replay.action.chats.create_chat.title': 'Send Message',
  'replay.action.chats.create.description': 'message:\n**%BODY**',
  'replay.action.chats.create.title': 'Add chat message',
  'replay.action.inventories.update.description': 'Update inventory\nname: %NAME',
  'replay.action.inventories.update.title': 'Update inventory',
  'replay.action.inventory_items.create.description': 'Create new inventory item\nname: %NAME',
  'replay.action.inventory_items.create.title': 'Create new inventory item',
  'replay.action.inventory_items.delete.description': 'Delete inventory item\nname: %NAME',
  'replay.action.inventory_items.delete.title': 'Delete inventory item',
  'replay.action.inventory_items.update.description': 'Update inventory item settings\nname: %NAME',
  'replay.action.inventory_items.update.title': 'Update inventory item',
  'replay.action.map_assets.create.description': 'Add a new map asset with the following details;\nname: **%NAME**\nasset type: **%MAP.TYPE**',
  'replay.action.map_assets.create.title': 'Create new map asset',
  'replay.action.map_assets.delete.description': 'Delete map asset **%NAME**',
  'replay.action.map_assets.delete.title': 'Delete map asset',
  'replay.action.map_assets.update.description': 'Update map asset **%NAME**',
  'replay.action.map_assets.update.title': 'Update map asset',
  'replay.action.mob_actions.create.description': 'Apply a mob action\nAction:\t**%TYPE**\nMobs:\t**%MOBNAMES**',
  'replay.action.mob_actions.create.title': 'Apply a mob action',
  'replay.action.mob_actions.delete.description': 'Delete a mob action',
  'replay.action.mob_actions.delete.title': 'Delete a mob action',
  'replay.action.mob_actions.edit.description': 'Edit a mob action',
  'replay.action.mob_actions.edit.title': 'Edit a mob action',
  'replay.action.mobs.create_mob.description': 'Add a new mob with the following description;\ntype: **%TYPE** \nbreed: **%BREED** \ngender: **%GENDER** \nages: **%AGES** \nhead: **%NUMBER** \npaddock: **%PADDOCKNAME**',
  'replay.action.mobs.create_mob.title': 'Create new mob',
  'replay.action.mobs.create.description': 'Add a new mob with the following details;\ntype: **%TYPE**\nbreed: **%BREED**\ngender: **%GENDER**\nnumber: **%SIZE**\nDSE: **%DSE**\npaddock: **%META.PADDOCKNAME**',
  'replay.action.mobs.create.title': 'Add new mob',
  'replay.action.mobs.update_mob.description': 'Update a mob with the following description;\ntype: **%TYPE** \nbreed: **%BREED** \ngender: **%GENDER** \nages: **%AGES** \nhead: **%SIZE**',
  'replay.action.mobs.update_mob.title': 'Update mob',
  'replay.action.mobs.update.description': 'Edit a mob with the following details;\nbreed: **%BREED**\ngender: **%GENDER**',
  'replay.action.mobs.update.title': 'Edit mob',
  'replay.action.natural_increases.delete.description': 'Delete natural increase;\nMob:\t\t%MOB.BREED %MOB.GENDER',
  'replay.action.natural_increases.delete.title': 'Delete natural increase',
  'replay.action.natural_increases.update.description': 'Edit natural increase;\nMob:\t\t%MOB.BREED %MOB.GENDER',
  'replay.action.natural_increases.update.title': 'Edit natural increase',
  'replay.action.paddock_actions.create.description': 'Apply an action to paddock\naction: **%TYPE**\npaddock: **%PADDOCKS.NAME**\ninventory used: **%APPLIEDINVENTORYITEMS.NAME**',
  'replay.action.paddock_actions.create.title': 'Apply an action to paddock',
  'replay.action.paddock_actions.delete.description': 'Delete an action applied to paddock\naction: **%TYPE**\npaddock: **%PADDOCKS.NAME**',
  'replay.action.paddock_actions.delete.title': 'Delete an action applied to paddock',
  'replay.action.paddock_actions.update.description': 'Update an action applied to paddock\naction: **%TYPE**\npaddock: **%PADDOCKS.NAME**\ninventory used: **%APPLIEDINVENTORYITEMS.NAME**',
  'replay.action.paddock_actions.update.title': 'Update action applied to paddock',
  'replay.action.paddock_groups.create.description': 'Open gates between two or more paddocks',
  'replay.action.paddock_groups.create.title': 'Open gates',
  'replay.action.paddock_groups.delete.description': 'Closes all the gates between a paddock group',
  'replay.action.paddock_groups.delete.title': 'Delete open gates',
  'replay.action.paddock_groups.update.description': 'Update gates open between paddocks',
  'replay.action.paddock_groups.update.title': 'Edit open gates',
  'replay.action.paddocks.create.description': 'Add a new paddock with the following details;\nname: **%NAME**\ntype: **%TYPE**',
  'replay.action.paddocks.create.title': 'Add new paddock',
  'replay.action.paddocks.delete.description': 'Delete paddock **%NAME**',
  'replay.action.paddocks.delete.title': 'Delete paddock',
  'replay.action.paddocks.merge_mobs.description': 'Merge mobs;\nPaddock:\t\t%PADDOCKNAME\nMobs:\t\t%BREED %GENDER',
  'replay.action.paddocks.merge_mobs.title': 'Merge mobs',
  'replay.action.paddocks.move_mobs.description': 'Move mobs from **%PADDOCKFROM.NAME** to **%PADDOCKTO.NAME**\nMoving:\n%META.MOBMOVENAMES\nSplitting:\n%META.MOBSPLITNAMES',
  'replay.action.paddocks.move_mobs.title': 'Move mobs to paddock',
  'replay.action.paddocks.update.description': 'Edit a paddock with the following details;\nname: **%NAME**\ntype: **%TYPE**',
  'replay.action.paddocks.update.title': 'Edit paddock',
  'replay.action.properties.add_custom_configured_property_type.description': 'Add a custom field with the following description: \ngroup: **%GROUP** \nlabel: **%LABEL**',
  'replay.action.properties.add_custom_configured_property_type.title': 'Add a custom field',
  'replay.action.properties.add_property_type.description': 'Add custom type with the value;\ngroup: **%GROUP**\nlabel: **%LABEL**\ncolour: **%COLOR**',
  'replay.action.properties.add_property_type.title': 'Add custom type',
  'replay.action.properties.delete_property_type.description': 'Delete custom type with the value;\ngroup: **%GROUP**\nlabel: **%LABEL**\ncolour: **%COLOR**',
  'replay.action.properties.delete_property_type.title': 'Delete custom type',
  'replay.action.properties.update_property_type.description': 'Edit custom type with the value;\ngroup: **%GROUP**\nlabel: **%LABEL**\ncolour: **%COLOR**',
  'replay.action.properties.update_property_type.title': 'Edit custom type',
  'replay.action.purchases.delete.description': 'Delete purchase;\nMob:\t\t%MOB.BREED %MOB.GENDER',
  'replay.action.purchases.delete.title': 'Delete purchase',
  'replay.action.purchases.update.description': 'Edit purchase;\nMob:\t\t%MOB.BREED %MOB.GENDER',
  'replay.action.purchases.update.title': 'Edit purchase',
  'replay.action.rain_gauge_readings.create.description': 'Create new rain gauge reading',
  'replay.action.rain_gauge_readings.create.title': 'Create new rain gauge reading',
  'replay.action.rain_gauge_readings.delete.description': 'Delete rain gauge reading',
  'replay.action.rain_gauge_readings.delete.title': 'Delete rain gauge reading',
  'replay.action.rain_gauge_readings.update.description': 'Update rain gauge reading settings',
  'replay.action.rain_gauge_readings.update.title': 'Update rain gauge reading',
  'replay.action.rain_gauges.create.description': 'Create new rain gauge\nname: %NAME',
  'replay.action.rain_gauges.create.title': 'Create new rain gauge',
  'replay.action.rain_gauges.delete.description': 'Delete rain gauge\nname: %NAME',
  'replay.action.rain_gauges.delete.title': 'Delete rain gauge ',
  'replay.action.rain_gauges.update.description': 'Update rain gauge\nname: %NAME',
  'replay.action.rain_gauges.update.title': 'Update rain gauge',
  'replay.action.sales.create.description': 'Register new sale;\nMob:\t\t%MOB.BREED %MOB.GENDER',
  'replay.action.sales.create.title': 'Register new sale',
  'replay.action.sales.delete.description': 'Delete sale;\nMob:\t\t%MOB.BREED %MOB.GENDER',
  'replay.action.sales.delete.title': 'Delete sale',
  'replay.action.sales.update.description': 'Edit sale;\nMob:\t\t%MOB.BREED %MOB.GENDER',
  'replay.action.sales.update.title': 'Edit sale',
  'replay.action.tasks.create.description': 'Add a new task with the following details;\ntitle: **%TITLE**',
  'replay.action.tasks.create.title': 'Add new task',
  'replay.action.tasks.update.description': 'Edit a task with the following details;\ntitle: **%TITLE**',
  'replay.action.tasks.update.title': 'Edit task',
  'reports.livestock_reconciliation.table.heading.colum.breed': 'Breed',
  'reports.livestock_reconciliation.table.heading.colum.casualties': 'Casualties',
  'reports.livestock_reconciliation.table.heading.colum.closing_head': 'Closing head',
  'reports.livestock_reconciliation.table.heading.colum.diff_head': 'Difference',
  'reports.livestock_reconciliation.table.heading.colum.gender': 'Gender',
  'reports.livestock_reconciliation.table.heading.colum.natural_increase': 'Natural increase',
  'reports.livestock_reconciliation.table.heading.colum.other': 'Other',
  'reports.livestock_reconciliation.table.heading.colum.purchased': 'Purchased',
  'reports.livestock_reconciliation.table.heading.colum.sold': 'Sold',
  'reports.livestock_reconciliation.table.heading.colum.starting_head': 'Starting head',
  'reports.livestock_reconciliation.table.heading.colum.type': 'Type',
  'reports.livestock_reconciliation.table.item.empty': 'un-classed',
  'reports.livestock_reconciliation.table.title': 'Livestock reconciliation report',
  'reports.livestock_sales.table.heading.colum.average_per_head': 'Average per head',
  'reports.livestock_sales.table.heading.colum.breed': 'Breed',
  'reports.livestock_sales.table.heading.colum.gender': 'Gender',
  'reports.livestock_sales.table.heading.colum.sold': 'Head sold',
  'reports.livestock_sales.table.heading.colum.total': 'Total received',
  'reports.livestock_sales.table.heading.colum.type': 'Type',
  'reports.livestock_sales.table.title': 'Livestock sales report',
  'reports.no_properties_selected.label': 'No properties selected',
  'reports.properties_selected.count.label': '**%VALUE properties** selected',
  'reports.totals.chart.casualties.title': 'Casualties totals',
  'reports.totals.chart.closingHead.title': 'Livestock totals',
  'reports.totals.chart.naturalIncrease.title': 'Natural Increases totals',
  'reports.totals.chart.purchased.title': 'Purchases totals',
  'reports.totals.chart.sold.title': 'Sales totals',
  'root.drawer.chats_list': 'Mobble Chat',
  'root.modal.rain-gauge.reading_create': 'Record a Reading',
  'root.modal.rain-gauge.reading_edit': 'Edit a Reading',
  'sale.edit.form.cancel.label': 'Cancel',
  'sale.edit.form.date.label': 'Date',
  'sale.edit.form.notes.label': 'Notes',
  'sale.edit.form.notes.placeholder': 'Any additional notes',
  'sale.edit.form.number.label': 'Head',
  'sale.edit.form.number.placeholder': 'Number',
  'sale.edit.form.price_per_head.label': 'Price per head',
  'sale.edit.form.price_per_head.placeholder': '$0',
  'sale.edit.form.sold_to.label': 'Sold to (optional)',
  'sale.edit.form.sold_to.placeholder': 'Name',
  'sale.edit.form.submit.label': 'Submit',
  'sale.edit.form.total_price.label': 'Total price',
  'sale.edit.form.total_price.placeholder': '$0',
  'sale.info.table.row.date.label': 'Date',
  'sale.info.table.row.mob.label': 'Mob',
  'sale.info.table.row.notes.label': 'Notes',
  'sale.info.table.row.number.label': 'Head',
  'sale.info.table.row.price_per_head.label': 'Price per head',
  'sale.info.table.row.received.label': 'Total received',
  'sale.info.table.row.sold_to.label': 'Sold to',
  'sales.casualty.delete.confirm.cancel': 'Cancel',
  'sales.casualty.delete.confirm.confirm': 'Delete',
  'sales.casualty.delete.confirm.description': 'This will delete the sale from your records.',
  'sales.casualty.delete.confirm.title': 'Are you sure?',
  'sales.casualty.delete.form.delete.label': 'Delete sale',
  'sales.create.form.cancel.label': 'Cancel',
  'sales.create.form.date.label': 'Date',
  'sales.create.form.date.placeholder': 'Date',
  'sales.create.form.mob.label': 'Mob',
  'sales.create.form.mob.placeholder': 'Select a mob',
  'sales.create.form.notes.label': 'Notes',
  'sales.create.form.notes.placeholder': 'Any additional notes',
  'sales.create.form.number.label': 'Head',
  'sales.create.form.number.placeholder': 'Amount',
  'sales.create.form.price_per_head.label': 'Price per Head',
  'sales.create.form.price_per_head.placeholder': '$0',
  'sales.create.form.sold_to.label': 'Sold to',
  'sales.create.form.sold_to.placeholder': 'Name (optional)',
  'sales.create.form.submit.label': 'Submit',
  'sales.create.form.total_price.label': 'Total price',
  'sales.create.form.total_price.placeholder': '$0',
  'sales.filter.group.breed.label': 'Breed',
  'sales.filter.group.gender.label': 'Gender',
  'sales.filter.group.type.breed.label': 'Breed',
  'sales.filter.group.type.date_range.label': 'Date range',
  'sales.filter.group.type.gender.label': 'Gender',
  'sales.filter.group.type.number.label': 'Number',
  'sales.sale.delete.confirm.cancel': 'Cancel',
  'sales.sale.delete.confirm.confirm': 'Delete',
  'sales.sale.delete.confirm.description': 'This will delete the sale from your records.',
  'sales.sale.delete.confirm.title': 'Are you sure?',
  'sales.sale.delete.form.delete.label': 'Delete Sale',
  'sales.summary_table.amount_of_sales.label': 'Amount of sales',
  'sales.summary_table.average_price_per_head.label': 'Average price per head',
  'sales.summary_table.head_sold.label': 'Head sold',
  'sales.summary_table.total_received.label': 'Total received',
  'sales.table.empty': 'There are no sales to display',
  'sales.table.heading.column.classes': 'Classes',
  'sales.table.heading.column.date': 'Date',
  'sales.table.heading.column.head': 'Head',
  'sales.table.heading.column.mob': 'Mob',
  'sales.table.heading.column.notes': 'Notes',
  'sales.table.heading.column.paddock': "Paddock",
  'sales.table.heading.column.price_per_head': "Price Per Head",
  'sales.table.heading.column.price_per_head.average': 'Avg: %VALUE',
  'sales.table.heading.column.received': "Received",
  'sales.table.heading.column.sold_to': "Sold to",
  'scenario_type.inventory_items-overview.title': 'Inventory items',
  'scenario_type.map_assets-overview.title': 'Map assets',
  'scenario_type.map-overview.title': 'Map',
  'scenario_type.mob-detail.title': 'Mob',
  'scenario_type.mobs-overview.title': 'Mobs',
  'scenario_type.paddock-detail.title': 'Paddock',
  'scenario_type.paddocks-overview.title': 'Paddocks',
  'scenario_type.rain_gauge-detail.title': 'Rain gauge',
  'scenario_type.rain_gauges-overview.title': 'Rain gauges',
  'scenario_type.settings-property-breeds.title': 'Breeds',
  'scenario_type.settings-property-classes.title': 'Classes',
  'scenario_type.settings-property-genders.title': 'Genders',
  'scenario_type.settings-property-mob_actions.title': 'Mob actions',
  'scenario_type.settings-property-paddock_actions.title': 'Paddock actions',
  'scenario_type.settings-property-paddock_types.title': 'Paddock types',
  'scenario_type.settings-property-tags.title': 'Tags',
  'scenario_type.settings-property-users-overview.title': 'Users',
  'scenario_type.tasks-overview.title': 'Tasks',
  'screen.title.action_queue': 'Offline requests ',
  'screen.title.billing': 'Billing',
  'screen.title.casualty': 'Record a casualty',
  'screen.title.casualty_detail': 'Casualty',
  'screen.title.casualty_edit': 'Edit casualty',
  'screen.title.chats': 'Mobble Chat',
  'screen.title.dashboard': 'Dashboard',
  'screen.title.edit_x': 'Edit “%TITLE”',
  'screen.title.historic_events_filter': 'Filter events',
  'screen.title.inventories': 'Inventories',
  'screen.title.inventory_edit': 'Edit inventory',
  'screen.title.inventory_item_create': 'Add a new inventory item “%TITLE”',
  'screen.title.inventory_item_edit': 'Edit inventory item',
  'screen.title.inventory_item.inventory-category_chemicals.create': 'Add a new chemical',
  'screen.title.inventory_item.inventory-category_feed.create': 'Add a new product',
  'screen.title.inventory-items': 'Inventory items',
  'screen.title.map': 'Map',
  'screen.title.map_asset_create': 'Add new map asset',
  'screen.title.map_asset_detail': 'Map Asset details',
  'screen.title.map_asset_edit': 'Edit %MAP_ASSET_NAME',
  'screen.title.map_assets': 'Map Assets',
  'screen.title.mob_action': 'Mob action',
  'screen.title.mob_action_edit': 'Edit mob action',
  'screen.title.mob_create': 'Add a new mob',
  'screen.title.mob_detail': 'Mob details',
  'screen.title.mob_detail_history': 'History',
  'screen.title.mob_detail_info': 'Info',
  'screen.title.mob_edit': 'Edit "%MOB_NAME"',
  'screen.title.mobble_apps': 'Mobble Apps',
  'screen.title.mobble_connect': 'Mobble Connect',
  'screen.title.mobs': 'Mobs',
  'screen.title.mobs_filter': 'Filter mobs',
  'screen.title.natural_increase': 'Natural Increase',
  'screen.title.natural_increase_edit': 'Edit Natural Increase',
  'screen.title.paddock_action_create': 'Apply paddock action',
  'screen.title.paddock_action_detail': 'Paddock action',
  'screen.title.paddock_action_edit': 'Edit paddock action',
  'screen.title.paddock_create': 'Add new paddock',
  'screen.title.paddock_create_map': 'Add Paddock',
  'screen.title.paddock_detail': 'Paddock details',
  'screen.title.paddock_detail_history': 'History',
  'screen.title.paddock_detail_info': 'Info',
  'screen.title.paddock_detail_mobs': 'Mobs',
  'screen.title.paddock_detail_tasks': 'Tasks',
  'screen.title.paddock_edit': 'Edit "%PADDOCK_NAME"',
  'screen.title.paddock_group.create': 'Open Gates',
  'screen.title.paddock_group.edit': 'Edit Gates Opened',
  'screen.title.paddock_merge_mobs': 'Merge mobs on %PADDOCK_NAME',
  'screen.title.paddock_move_mobs': 'Move mobs from %PADDOCK_NAME',
  'screen.title.paddocks': 'Paddocks',
  'screen.title.paddocks_filter': 'Filter paddocks',
  'screen.title.property_create': 'Create new property',
  'screen.title.purchase_detail': 'Purchase',
  'screen.title.purchase_edit': 'Edit Purchase',
  'screen.title.rain-gauge_create': 'Add a new rain gauge',
  'screen.title.rain-gauge_detail': 'Rain gauge',
  'screen.title.rain-gauge_detail_history': 'History',
  'screen.title.rain-gauge_detail_info': 'Info',
  'screen.title.rain-gauge_edit': 'Edit %RAIN_GAUGE_NAME',
  'screen.title.rain-gauge_reading': 'Rain gauge reading',
  'screen.title.rain-gauges': 'Rain gauges',
  'screen.title.refer-farmer': 'Refer a Farmer',
  'screen.title.reports': 'Reports',
  'screen.title.sale': 'Sell Mob',
  'screen.title.sale_detail': 'Sale',
  'screen.title.sale_edit': 'Edit Sale',
  'screen.title.settings': 'Settings',
  'screen.title.settings_clear_data': 'Clear locally stored data',
  'screen.title.settings_help': 'Help',
  'screen.title.settings_log': 'System log',
  'screen.title.settings_offline': 'Offline settings',
  'screen.title.settings_property_classes_types': 'Classes, types & tags',
  'screen.title.settings_property_classes_types_group': 'Custom fields',
  'screen.title.settings_property_classes_types_group.breed': 'Breeds',
  'screen.title.settings_property_classes_types_group.class': 'Classes',
  'screen.title.settings_property_classes_types_group.gender': 'Genders',
  'screen.title.settings_property_classes_types_group.livestockType': 'Livestock Types',
  'screen.title.settings_property_classes_types_group.mobAction': 'Mob actions',
  'screen.title.settings_property_classes_types_group.paddockAction': 'Paddock actions',
  'screen.title.settings_property_classes_types_group.paddockType': 'Paddock types',
  'screen.title.settings_property_classes_types_group.tag': 'Tags',
  'screen.title.settings_property_information': 'Property information',
  'screen.title.settings_property_users': 'Users',
  'screen.title.settings_property_users_invite_user': 'Invite user',
  'screen.title.settings_property_users_user_edit': 'User "%NAME"',
  'screen.title.settings_units': 'Units and formatting',
  'screen.title.settings_user': 'Profile',
  'screen.title.summaries': 'Summaries',
  'screen.title.summary_casualties': 'Casualties',
  'screen.title.summary_events': 'Event log',
  'screen.title.summary_livestock_numbers': 'Livestock numbers',
  'screen.title.summary_map_assets': 'Map Assets',
  'screen.title.summary_mob_actions': 'Mob actions',
  'screen.title.summary_natural_increases': 'Natural Increases',
  'screen.title.summary_paddock_actions': 'Paddock actions',
  'screen.title.summary_purchases': 'Purchases',
  'screen.title.summary_sales': 'Sales',
  'screen.title.task_create': 'Add a new task',
  'screen.title.task_detail': 'Task details',
  'screen.title.task_edit': 'Edit "%TASK_TITLE"',
  'screen.title.tasks': 'Tasks',
  'screen.title.tasks_completed': 'Completed',
  'screen.title.tasks_incomplete': 'Incomplete',
  'screen.title.tasks_my_tasks': 'My tasks',
  'screen.title.user_settings': 'User settings',
  'settings.information.version': 'Version: %VERSION',
  'settings.mobble_connect.message.description-1': 'Mobble Connect, our third-party access feature, empowers farmers to securely share their data with essential partners: Farm Advisors, Livestock Agents, Accountants, and Contractors.',
  'settings.mobble_connect.message.description-2': 'We champion open communication. Our goal? To enhance collaboration between farmers and their trusted service providers, paving the way for heightened productivity and connection.',
  'settings.mobble_connect.message.description-3': 'Remember: Your data remains YOURS. Share it selectively with those you trust the most. Third parties are granted access only to relevant data and can only undertake authorized actions. Dive deeper below to understand the full capabilities of third-party access. Explore Mobble Connect now and elevate your farming collaboration!',
  'settings.mobble_connect.message.description1': 'Mobble Connect, our third-party access feature, empowers farmers to securely share their data with essential partners: Farm Advisors, Livestock Agents, Accountants, and Contractors.',
  'settings.mobble_connect.message.description2': 'We champion open communication. Our goal? To enhance collaboration between farmers and their trusted service providers, paving the way for heightened productivity and connection.',
  'settings.mobble_connect.message.description3': 'Remember: Your data remains YOURS. Share it selectively with those you trust the most. Third parties are granted access only to relevant data and can only undertake authorized actions. Dive deeper below to understand the full capabilities of third-party access. Explore Mobble Connect now and elevate your farming collaboration!',
  'settings.mobble_connect.message.title': 'What is Mobble Connect?',
  'settings.offline.clear.button.label': 'Clear data',
  'settings.offline.clear.content.blurb': 'Clear all local stored data. This will remove data stored on your device only. Data will be re-downloaded while using the application or when you next sync with the cloud.',
  'settings.offline.sync.button.label': 'Sync with cloud',
  'settings.offline.sync.content.blurb': 'To ensure all your properties are up-to-date, we recommend that you sync your properties with the cloud before heading offline.\n\nThis way, you can be confident that you have all the latest and most important data on your device when you need it.',
  'settings.offline.sync.content.currently_stored': 'Currently stored **%COUNT** items.',
  'settings.offline.sync.content.title': 'Using Mobble offline',
  'settings.property_information.form.cancel.label': 'Cancel',
  'settings.property_information.form.name.label': 'Name',
  'settings.property_information.form.name.placeholder': 'Enter a name for this property',
  'settings.property_information.form.pic.label': 'Property identification number',
  'settings.property_information.form.pic.placeholder': 'Enter a unique ID for this property',
  'settings.property_information.form.submit.label': 'Save changes',
  'settings.property.classes_types.toc.actions_and_treatments': 'Actions & treatments',
  'settings.property.classes_types.toc.breed': 'Breeds',
  'settings.property.classes_types.toc.breeds': 'Breeds',
  'settings.property.classes_types.toc.cattle_breed': 'Cattle breeds',
  'settings.property.classes_types.toc.cattle_breeds': 'Cattle breeds',
  'settings.property.classes_types.toc.cattle_class': 'Cattle classes',
  'settings.property.classes_types.toc.cattle_classes': 'Cattle classes',
  'settings.property.classes_types.toc.cattle_gender': 'Cattle genders',
  'settings.property.classes_types.toc.cattle_genders': 'Cattle genders',
  'settings.property.classes_types.toc.cattle_tag': 'Cattle tags',
  'settings.property.classes_types.toc.cattle_tags': 'Cattle tags',
  'settings.property.classes_types.toc.class': 'Classes',
  'settings.property.classes_types.toc.classes': 'Classes',
  'settings.property.classes_types.toc.gender': 'Genders',
  'settings.property.classes_types.toc.genders': 'Genders',
  'settings.property.classes_types.toc.goats_breed': 'Goat breeds',
  'settings.property.classes_types.toc.goats_breeds': 'Goat breeds',
  'settings.property.classes_types.toc.goats_class': 'Goat classes',
  'settings.property.classes_types.toc.goats_classes': 'Goat classes',
  'settings.property.classes_types.toc.goats_gender': 'Goat genders',
  'settings.property.classes_types.toc.goats_genders': 'Goat genders',
  'settings.property.classes_types.toc.goats_tag': 'Goat tags',
  'settings.property.classes_types.toc.goats_tags': 'Goat tags',
  'settings.property.classes_types.toc.livestock_types': 'Livestock types',
  'settings.property.classes_types.toc.mob_actions': 'Mob actions',
  'settings.property.classes_types.toc.other_breed': 'Other breeds',
  'settings.property.classes_types.toc.other_breeds': 'Other breeds',
  'settings.property.classes_types.toc.other_class': 'Other classes',
  'settings.property.classes_types.toc.other_classes': 'Other classes',
  'settings.property.classes_types.toc.other_gender': 'Other genders',
  'settings.property.classes_types.toc.other_genders': 'Other genders',
  'settings.property.classes_types.toc.other_tag': 'Other tags',
  'settings.property.classes_types.toc.other_tags': 'Other tags',
  'settings.property.classes_types.toc.paddock_actions': 'Paddock actions',
  'settings.property.classes_types.toc.paddock_types': 'Paddock types',
  'settings.property.classes_types.toc.sheep_breed': 'Sheep breeds',
  'settings.property.classes_types.toc.sheep_breeds': 'Sheep breeds',
  'settings.property.classes_types.toc.sheep_class': 'Sheep classes',
  'settings.property.classes_types.toc.sheep_classes': 'Sheep classes',
  'settings.property.classes_types.toc.sheep_gender': 'Sheep genders',
  'settings.property.classes_types.toc.sheep_genders': 'Sheep genders',
  'settings.property.classes_types.toc.sheep_tag': 'Sheep tags',
  'settings.property.classes_types.toc.sheep_tags': 'Sheep tags',
  'settings.property.classes_types.toc.tag': 'Tags (tag colours)',
  'settings.property.classes_types.toc.tags': 'Tags (tag colours)',
  'settings.property.classes_types.toc.types': 'Types',
  'settings.property.mobble_connect.accountant.capabilities': `• Access to sales, purchases, livestock totals, natural increases, casualties and mobs
• Can't make edits
• Can use Mobble Chat
`,
  'settings.property.mobble_connect.capabilities': '%VALUE capabilities',
  'settings.property.mobble_connect.edit_user.form.cancel.label': 'Cancel',
  'settings.property.mobble_connect.edit_user.form.propertyIds.label': 'Remove from the following Properties',
  'settings.property.mobble_connect.edit_user.form.propertyIds.placeholder': 'Select Properties',
  'settings.property.mobble_connect.edit_user.form.submit.label': 'Remove ',
  'settings.property.mobble_connect.edit_user.form.user.label': 'User',
  'settings.property.mobble_connect.edit_user.title': 'Edit %ROLE',
  'settings.property.mobble_connect.farm_advisor.capabilities': `• Access to entire property
• Can't make edits (outside of tasks)
• Can add tasks/recommendations for the farm
• Can use Mobble Chat
`,
  'settings.property.mobble_connect.invite': 'Invite %VALUE',
  'settings.property.mobble_connect.invite_user.form.cancel.label': 'Cancel',
  'settings.property.mobble_connect.invite_user.form.email.label': 'Invite Email',
  'settings.property.mobble_connect.invite_user.form.email.placeholder': 'Input Email',
  'settings.property.mobble_connect.invite_user.form.infoMessage.label': 'User is Already Part of property',
  'settings.property.mobble_connect.invite_user.form.propertyIds.label': 'Properties',
  'settings.property.mobble_connect.invite_user.form.propertyIds.placeholder': 'Select Property',
  'settings.property.mobble_connect.invite_user.form.submit.label': 'Submit',
  'settings.property.mobble_connect.invite_user.title': 'Invite %ROLE',
  'settings.property.mobble_connect.map_view.capabilities': `• Access to map only
• Can't make edits
• Can view tasks assigned to them
• Can use Mobble Chat

`,
  'settings.property.mobble_connect.stock_agent.capabilities': `• Access to sales, purchases, livestock totals and mobs
• Can add/edit sales and purchases
• Can't make edits (outside of sales and purchases)
• Can use Mobble Chat
`,
  'settings.property.mobble_connect.title': 'Mobble Connect',
  'settings.property.types.confirm.cancel': 'Cancel',
  'settings.property.types.confirm.confirm': 'Delete',
  'settings.property.types.confirm.description': 'This will delete the selected type and make it unable to be selected in the future. Historic data will remain.',
  'settings.property.types.confirm.title': 'Delete',
  'settings.property.types.edit.form.cancel.label': 'Cancel',
  'settings.property.types.edit.form.color.label': 'Color',
  'settings.property.types.edit.form.color.placeholder': 'Select a color',
  'settings.property.types.edit.form.label.label': 'Label',
  'settings.property.types.edit.form.submit.label': 'Save changes',
  'settings.property.types.form.delete.label': 'Delete',
  'settings.property.types.new.form.cancel.label': 'Cancel',
  'settings.property.types.new.form.color.label': 'Color',
  'settings.property.types.new.form.color.placeholder': 'Select a color',
  'settings.property.types.new.form.label.label': 'Label',
  'settings.property.types.new.form.label.placeholder': 'Enter a new label text',
  'settings.property.types.new.form.submit.label': 'Add new',
  'settings.property.users.invite_user.form.cancel.label': 'Cancel',
  'settings.property.users.invite_user.form.email.label': 'Email',
  'settings.property.users.invite_user.form.email.placeholder': 'Enter the email address of the user you want to invite',
  'settings.property.users.invite_user.form.propertyIds.label': 'Add to the following Properties',
  'settings.property.users.invite_user.form.propertyIds.placeholder': 'Select Properties',
  'settings.property.users.invite_user.form.role.label': 'User Role',
  'settings.property.users.invite_user.form.role.placeholder': 'Select User Role',
  'settings.property.users.invite_user.form.submit.label': 'Send invite',
  'settings.property.users.invite_user.go_back.button': 'Go Back',
  'settings.property.users.invite_user.user_count_currently_invited': 'There are currently **%CURRENT_COUNT** users registered on this property, your subscription allows for **%MAX_COUNT**.',
  'settings.property.users.invite_user.user_count_remaining_to_invite': 'You can invite **%REMAINING** more users to this property.',
  'settings.property.users.invite_user.user_limit_reached.description': 'You have reached the limit of users that can be added to this organisation.\n\nTo add a user, please either remove an existing user or upgrade your subscription.\n\nPlease refer to our website for more information.',
  'settings.property.users.invite_user.user_limit_reached.title': "User limit has been reached",
  'settings.property.users.user.delete.button.label': 'Remove user',
  'settings.property.users.user.delete.confirm.cancel': 'Cancel',
  'settings.property.users.user.delete.confirm.confirm': 'Delete',
  'settings.property.users.user.delete.confirm.description': 'This will delete the user.',
  'settings.property.users.user.delete.confirm.title': 'Are you sure?',
  'settings.property.users.user.delete.demote_user.user.button.label': 'Demote to User',
  'settings.property.users.user.delete.demote_user.viewOnly.button.label': 'Demote to View Only',
  'settings.property.users.user.delete.promote_user.admin.button.label': 'Promote to Admin',
  'settings.property.users.user.delete.promote_user.user.button.label': 'Promote to User',
  'settings.property.users.user.info.email.label': 'Email',
  'settings.property.users.user.info.name.label': 'Name',
  'settings.property.users.user.info.role.label': 'Role',
  'settings.property.users.user.userRole.label': 'Edit User Role',
  'settings.property.users.user.userRole.placeholder': 'User roles',
  'settings.sign_out.are_you_sure.cancel': 'Cancel',
  'settings.sign_out.are_you_sure.confirm': 'Sign out',
  'settings.sign_out.are_you_sure.description': 'Are you sure you want to sign out?',
  'settings.sign_out.are_you_sure.title': 'Sign out',
  'settings.sign_out.button.label': 'Sign out',
  'settings.toc.about': 'About',
  'settings.toc.about.update_available': 'Update available, tap to download',
  'settings.toc.about.version': 'Version: %VERSION',
  'settings.toc.accountant': 'Accountant',
  'settings.toc.app': 'App',
  'settings.toc.farm_advisor': 'Farm Advisor',
  'settings.toc.map_view': 'Contractor',
  'settings.toc.mobble_connect': 'Mobble Connect',
  'settings.toc.organisation': 'Organisation',
  'settings.toc.property': 'Property',
  'settings.toc.stock_agent': 'Stock Agent',
  'settings.toc.user': 'User',
  'setup.displayName.form.cancel.label': 'Log out',
  'setup.displayName.form.displayName.label': 'Your display name',
  'setup.displayName.form.displayName.placeholder': 'display name',
  'setup.displayName.form.message': 'Set up your display name',
  'setup.displayName.form.submit.label': 'Set Display Name',
  'setup.displayName.form.title': 'Almost there 🎉',
  'setup.no_property_access.description': 'You are not currently part of any property or subscription, if you want to use the app, either ask the subscription owner to invite you to a property or start a Mobble trial.',
  'setup.no_property_access.logout': 'Log out',
  'setup.no_property_access.title': 'Unable to find any properties',
  'setup.property.error': 'Something went wrong.\nThe subscription or trial period may have expired.',
  'setup.property.form.cancel.label': 'Sign out',
  'setup.property.form.name.label': 'Property name',
  'setup.property.form.name.placeholder': 'Enter a name for your property',
  'setup.property.form.pic.label': 'Property identification number',
  'setup.property.form.pic.placeholder': 'Property identification number (optional)',
  'setup.property.form.submit.label': 'Create property',
  'setup.property.welcome.blurb': 'Let’s get started by creating your first property.',
  'setup.property.welcome.title': 'Welcome to Mobble',
  'subscription.insufficient.screen.blurb': 'Only family and above tier subscriptions have access to the map feature.',
  'subscription.insufficient.screen.title': 'Map feature not available',
  'summaries.natural_increase.edit.form.number.error.label': 'Head must be a whole number',
  'summaries.purchase.edit.form.number.error.label': 'Head must be a whole number',
  'summary.card.price_per_head': 'Price per head: %VALUE',
  'summary.card.seller': 'Seller: %VALUE',
  'summary.card.sold_to': 'Sold to: %VALUE',
  'summary.card.total_received': 'Total received: %VALUE',
  'summary.livestock_numbers.column.dse.label': 'DSE',
  'summary.livestock_numbers.column.head.label': 'Head',
  'summary.purchases.card.price_per_head': 'Price per head: %VALUE',
  'summary.purchases.card.seller': 'Seller: %VALUE',
  'summary.purchases.card.total_spend': 'Total spend: %VALUE',
  'task.assigned_to.none': 'Anyone',
  'task.due_date.none': 'On a rainy day',
  'task.info.actions.add_map_location.label': 'Add map location',
  'task.info.actions.edit_map_location.label': 'Edit map location',
  'task.info.actions.mark_as_complete.label': 'Mark as complete',
  'task.info.actions.mark_as_incomplete.label': 'Mark as incomplete',
  'task.info.table.row.assigned_by.label': 'Assigned by',
  'task.info.table.row.assigned_to.label': 'Assigned to',
  'task.info.table.row.created_at.label': 'Created at',
  'task.info.table.row.description.label': 'Description',
  'task.info.table.row.due_date.label': 'Due date',
  'task.info.table.row.paddock.label': 'Paddock',
  'task.info.table.row.status.label': 'Status',
  'task.info.table.row.title.label': 'Title',
  'task.status.deleted': 'Deleted',
  'task.status.done': 'Completed',
  'task.status.pending': 'Incomplete',
  'tasks.list.empty': 'Tasks you create will be listed here.',
  'tasks.list.empty.add': 'Add a task below',
  'tasks.list.empty.filter.line1': 'There are currently no tasks matching your selection.',
  'tasks.list.empty.paddock': 'There are currently no pending tasks for this paddock.',
  'tasks.list.filter.clear': 'Clear',
  'tasks.list.filter.open': 'Filters',
  'tasks.list.filter.search.placeholder': 'Search tasks',
  'tasks.list.task.card.assigned_to': 'Assigned to: %NAMES',
  'tasks.list.task.card.paddock': 'Paddock: %PADDOCK',
  'tasks.table.empty': 'There are no tasks to display',
  'tasks.table.heading.column.assigned_by': 'Assigned by',
  'tasks.table.heading.column.assigned_to': 'Assigned to',
  'tasks.table.heading.column.description': 'Description',
  'tasks.table.heading.column.due_date': 'Due date',
  'tasks.table.heading.column.paddock': 'Paddock',
  'tasks.table.heading.column.status': 'Status',
  'tasks.table.heading.column.title': 'Title',
  'tasks.task.create.form.assigned_to.label': 'Assigned to',
  'tasks.task.create.form.assigned_to.placeholder': 'Select users to assign this task to',
  'tasks.task.create.form.cancel.label': 'Cancel',
  'tasks.task.create.form.description.label': 'Task description',
  'tasks.task.create.form.description.placeholder': 'Optionally add additional information about the task',
  'tasks.task.create.form.due_date.label': 'Due date',
  'tasks.task.create.form.due_date.placeholder': 'Select a due date',
  'tasks.task.create.form.location.label': 'Task location',
  'tasks.task.create.form.paddock_id.label': 'Paddock',
  'tasks.task.create.form.paddock_id.placeholder': 'Select paddock (optional)',
  'tasks.task.create.form.submit.label': 'Add new task',
  'tasks.task.create.form.title.label': 'Task',
  'tasks.task.create.form.title.placeholder': 'Enter a task title',
  'tasks.task.delete.confirm.cancel': 'Cancel',
  'tasks.task.delete.confirm.confirm': 'Delete',
  'tasks.task.delete.confirm.description': 'This will delete the task.',
  'tasks.task.delete.confirm.title': 'Are you sure?',
  'tasks.task.delete.form.delete.label': 'Delete task',
  'tasks.task.edit.form.assigned_to.label': 'Assigned to',
  'tasks.task.edit.form.assigned_to.placeholder': 'Select users to assign this task to',
  'tasks.task.edit.form.cancel.label': 'Cancel',
  'tasks.task.edit.form.description.label': 'Task description',
  'tasks.task.edit.form.description.placeholder': 'Optionally add additional information about the task',
  'tasks.task.edit.form.due_date.label': 'Due date',
  'tasks.task.edit.form.due_date.placeholder': 'Select a due date',
  'tasks.task.edit.form.location.label': 'Task location',
  'tasks.task.edit.form.paddock_id.label': 'Paddock',
  'tasks.task.edit.form.paddock_id.placeholder': 'Select paddock (optional)',
  'tasks.task.edit.form.submit.label': 'Save changes',
  'tasks.task.edit.form.title.label': 'Task',
  'tasks.task.edit.form.title.placeholder': 'Enter a task title',
  'toast.action_queue.button.close.label': 'Close',
  'toast.action_queue.button.review.label': 'Review',
  'toast.action_queue.description': 'Your action has been saved in the "Offline Requests" where you can review and replay any pending actions.',
  'toast.action_queue.title': 'You are offline',
  'toast.failed_request.button.close.label': 'Close',
  'toast.failed_request.description': 'An error occurred while storing your last request. The error has been shared with our team. Please try again later or contact support if the problem persists.',
  'toast.failed_request.title': 'Something went wrong',
  'toast.subscription_expired.button.close.label': 'Close',
  'toast.subscription_expired.description': 'Subscription for this property may have expired, check with property owner.',
  'toast.subscription_expired.title': 'Subscription expired',
  'user_action_type.inventories-inventory_item-create.description': 'Add new item to the inventory',
  'user_action_type.inventories-inventory_item-create.title': 'Add inventory item',
  'user_action_type.map_asset-create.description': 'Add a new asset anywhere on the map',
  'user_action_type.map_asset-create.title': 'Add map asset',
  'user_action_type.map-map_asset-create.description': 'Add a new asset anywhere on the map',
  'user_action_type.map-map_asset-create.title': 'Add map asset',
  'user_action_type.map-mob-create.description': 'Add a new mob to any paddock',
  'user_action_type.map-mob-create.title': 'Add mob',
  'user_action_type.map-paddock-create.description': 'Add a new paddock to your property',
  'user_action_type.map-paddock-create.title': 'Add paddock',
  'user_action_type.map-task-create.description': 'Add a new task or reminder',
  'user_action_type.map-task-create.title': 'Add task',
  'user_action_type.mob_actions-create.description': 'Record an action applied to any mob',
  'user_action_type.mob_actions-create.title': 'Add mob action',
  'user_action_type.mobs-casualty-create.description': 'Record casualties on the selected mob',
  'user_action_type.mobs-casualty-create.title': 'Report casualty',
  'user_action_type.mobs-create.description': 'Add a new mob to any paddock',
  'user_action_type.mobs-create.title': 'Add mob',
  'user_action_type.mobs-edit.description': 'Edit the details of the mob',
  'user_action_type.mobs-edit.title': 'Edit mob',
  'user_action_type.mobs-mob_action-create.description': 'Record an action applied to the selected mob',
  'user_action_type.mobs-mob_action-create.title': 'Add mob action',
  'user_action_type.mobs-move.description': 'Move or split the current mob',
  'user_action_type.mobs-move.title': 'Move mob',
  'user_action_type.mobs-sale-create.description': 'Record a mob sale',
  'user_action_type.mobs-sale-create.title': 'Sell mob',
  'user_action_type.mobs-view.description': 'View detailed mob information',
  'user_action_type.mobs-view.title': 'View mob',
  'user_action_type.open_gates-create.description': 'Open gates between two or more paddocks',
  'user_action_type.open_gates-create.title': 'Open gates',
  'user_action_type.open_gates-edit.description': 'Open or close gates between paddocks',
  'user_action_type.open_gates-edit.title': 'Manage open gates',
  'user_action_type.paddock_action-create.description': 'Record an action performed on any paddock',
  'user_action_type.paddock_action-create.title': 'Add paddock action',
  'user_action_type.paddocks-create.description': 'Add a new paddock to your property',
  'user_action_type.paddocks-create.title': 'Add paddock',
  'user_action_type.paddocks-map-view.description': 'Show this paddock on the map.',
  'user_action_type.paddocks-map-view.title': 'View on map',
  'user_action_type.paddocks-mob-create.description': 'Add a new mob to this paddock',
  'user_action_type.paddocks-mob-create.title': 'Add mob',
  'user_action_type.paddocks-mobs-view.description': 'View detailed mob information',
  'user_action_type.paddocks-mobs-view.title': 'View mobs',
  'user_action_type.paddocks-open-gates-create.description': 'Open gates between two or more paddocks',
  'user_action_type.paddocks-open-gates-create.title': 'Open gates',
  'user_action_type.paddocks-open-gates-edit.description': 'Manage open gates between paddocks',
  'user_action_type.paddocks-open-gates-edit.title': 'Manage Open gates',
  'user_action_type.paddocks-paddock-action-create.description': 'Record an action performed on this paddock',
  'user_action_type.paddocks-paddock-action-create.title': 'Add paddock action',
  'user_action_type.paddocks-tasks-create.description': 'Add a new task to this paddock',
  'user_action_type.paddocks-tasks-create.title': 'Add task',
  'user_action_type.paddocks-view.description': 'Browse paddock details, event history, tasks & mobs',
  'user_action_type.paddocks-view.title': 'Paddock info',
  'user_action_type.rain_gauges-create.description': 'Add a new rain gauge to your property',
  'user_action_type.rain_gauges-create.title': 'Add rain gauge',
  'user_action_type.rain_gauges-rain_gauge_reading-create.description': 'Add a new rain gauge reading',
  'user_action_type.rain_gauges-rain_gauge_reading-create.title': 'Add reading',
  'user_action_type.rain_gauges-rain_gauge_reading-edit.description': 'Edit the details of the rain gauge reading',
  'user_action_type.rain_gauges-rain_gauge_reading-edit.title': 'Edit rain gauge reading',
  'user_action_type.recommendations-create.description': 'Add a new task or recommendation',
  'user_action_type.recommendations-create.title': 'Add task/recommendation',
  'user_action_type.settings-property-breed-create.description': 'Add a new breed to your mobs',
  'user_action_type.settings-property-breed-create.title': 'Add breed',
  'user_action_type.settings-property-class-create.description': 'Add a new class to your mobs',
  'user_action_type.settings-property-class-create.title': 'Add class',
  'user_action_type.settings-property-gender-create.description': 'Add a new gender to your mobs',
  'user_action_type.settings-property-gender-create.title': 'Add gender',
  'user_action_type.settings-property-mob_action-create.description': 'Add a new mob action',
  'user_action_type.settings-property-mob_action-create.title': 'Add mob action',
  'user_action_type.settings-property-paddock_action-create.description': 'Add a new paddock action',
  'user_action_type.settings-property-paddock_action-create.title': 'Add paddock action',
  'user_action_type.settings-property-paddock_type-create.description': 'Add a new paddock type',
  'user_action_type.settings-property-paddock_type-create.title': 'Add paddock type',
  'user_action_type.settings-property-tag-create.description': 'Add a new tag to your mobs',
  'user_action_type.settings-property-tag-create.title': 'Add tag',
  'user_action_type.settings-users-invite.description': 'Invite a new user to your property',
  'user_action_type.settings-users-invite.title': 'Invite user',
  'user_action_type.tasks-create.description': 'Add a new task or reminder',
  'user_action_type.tasks-create.title': 'Add task',
  'user_role.accountant': 'Accountant',
  'user_role.admin': 'Admin',
  "user_role.contractor": 'Contractor',
  'user_role.farm_advisor': 'Farm Advisor',
  'user_role.map_view': 'Contractor',
  'user_role.owner': 'Owner',
  'user_role.stock_agent': 'Stock Agent',
  'user_role.user': 'User',
  'user_role.view_only': 'View Only',
  'user.user_settings.appearance.form.locale.label': 'Language',
  'user.user_settings.appearance.form.areaUnit.label': 'Default area unit',
  'user.user_settings.appearance.form.areaUnit.placeholder': 'Select an area unit',
  'user.user_settings.appearance.form.cancel.label': 'Cancel',
  'user.user_settings.appearance.form.currencySymbol.label': 'Currency symbol',
  'user.user_settings.appearance.form.currencySymbol.placeholder': 'E.g. $',
  'user.user_settings.appearance.form.dateFormat.label': 'Date format',
  'user.user_settings.appearance.form.dateFormat.placeholder': 'Select a date format',
  'user.user_settings.appearance.form.distanceUnit.label': 'Default distance unit',
  'user.user_settings.appearance.form.distanceUnit.placeholder': 'Select a distance unit',
  'user.user_settings.appearance.form.lengthUnit.label': 'Default length unit',
  'user.user_settings.appearance.form.lengthUnit.placeholder': 'Select a length unit',
  'user.user_settings.appearance.form.submit.label': 'Save changes',
  'user.user_settings.appearance.form.timeFormat.label': 'Time format',
  'user.user_settings.appearance.form.timeFormat.placeholder': 'Select a time format',
  'user.user_settings.profile.form.cancel.label': 'Cancel',
  'user.user_settings.profile.form.name.label': 'Display name',
  'user.user_settings.profile.form.name.placeholder': 'Enter display name',
  'user.user_settings.profile.form.submit.label': 'Save changes',
  'map.map_options_modal.download.heading': 'Download',
  'map.map_options_modal.download.button.label': 'Download Property KML File'
}
const translations: I18nDictionary = fromRecord(keysAndTranslations);

export default translations;
