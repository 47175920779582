import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/mobActions';

export const useMobActions = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.mobActions);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const useMobAction = (mobActionId: string, propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.mobActions);

  return proxyUseEntity({
    entityId: mobActionId,
    parentId: propertyId,
    dispatch,
    state,
  });
};
