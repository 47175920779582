import React from 'react';
import { Button } from '../../Components/UX/Button';
import { Box } from '../../Components/Layout/Box';
import { Divider } from '../../Components/Layout/Divider';
import { Spacer } from '../../Components/Layout/Spacer';
import { InventoryItemStatus } from '@mobble/models/src/model/InventoryItem';
import { InventoryItemBatch } from '@mobble/models/src/model/InventoryItemBatch';

export interface InventoryItemBatchStatusFormFormProps {
  inventoryItemBatch: InventoryItemBatch;
  loading?: boolean;
  disabled?: boolean;
  onSubmit: (inventoryItemBatch: InventoryItemBatch) => void;
}

export const InventoryItemBatchStatusForm: React.FC<
  InventoryItemBatchStatusFormFormProps
> = ({ inventoryItemBatch, loading, disabled, onSubmit }) => {
  const onChangeStatus = () => {
    onSubmit({
      ...inventoryItemBatch,
      status:
        inventoryItemBatch.status === InventoryItemStatus.Active
          ? InventoryItemStatus.Finished
          : InventoryItemStatus.Active,
    });
  };

  const key =
    inventoryItemBatch.status === InventoryItemStatus.Active
      ? 'change_to_finished'
      : 'change_to_active';

  return (
    <Box>
      <Divider bold />
      <Spacer y={4} />
      <Button
        flex
        outline
        disabled={disabled}
        loading={loading}
        label={{ key: `inventory_items.inventory_item.status.${key}` }}
        intent="destructive"
        onClick={onChangeStatus}
      />
    </Box>
  );
};
