export const ANALYTICS_EVENTS = {
  TRIAL_SIGNUP_FORM_COMPLETED: 'Trial Signup Form Completed',
  INVITE_SIGNUP_FORM_COMPLETED: 'Invite Signup Form Completed',
};

export const TRIAL_SIGNUP = 'Trial Signup';
export const INVITE_SIGNUP = 'Invite Signup';

export const GTM_SIGN_UP = {
  EVENT: 'formSubmissionSuccess',
  FORM_ID: 'signupForm',
};
