import { dateIsInThePast } from '@mobble/shared/src/core/Date';
import { Task } from '@mobble/models/src/model/Task';
import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/tasks';
import { useTaskGeometry } from './taskGeometries';
import { useProperties } from './properties';

export const useTasks = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.tasks);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const useTask = (taskId: string, propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.tasks);
  const properties = useProperties();
  const parentId = propertyId ?? properties.selected?.id ?? '';

  const taskGeometry = useTaskGeometry(taskId, parentId);

  const transformEntity = (task: Task): Task => {
    if (!task.location) {
      return {
        ...task,
        location: taskGeometry.entity?.point,
      };
    }

    return task;
  };

  return proxyUseEntity({
    entityId: taskId,
    parentId: parentId,
    dispatch,
    state,
    transformEntity,
  });
};

export const usePendingOverdueTasksCount = (propertyId?: string) => {
  const tasks = useTasks(propertyId);
  return tasks.entities.filter(
    (t) => t.dueDate && t.status === 'pending' && dateIsInThePast(t.dueDate)
  ).length;
};
