import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  ConfiguredPropertyTypeGroup,
  getConfiguredPropertyTypes,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';
import * as ROUTE_NAME from '../config/routeNames';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenRendererProps } from '../config/types';
import {
  SettingsPropertyTypesEditForm,
  SettingsPropertyTypesEditFormValues,
} from '@src/stories/Views/Settings/SettingsPropertyTypeEditForm';
import { toPath } from '@src/interfaces/Routing';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { base64decode, base64encode } from '@mobble/shared/src/core/String';
import { useLinking } from '@src/hooks/useLinking';

export const SettingsPropertyClassesTypesEditHeader: React.FC<
  ScreenRendererProps
> = ({ route }) => {
  const { classTypes } = route.params;
  const { formatMessage } = useI18n();
  const { group, livestockType, valueType } = JSON.parse(
    base64decode(classTypes)
  );
  const linkTo = useLinking();

  const typeLowerCase = livestockType ? livestockType.toLowerCase() : null;
  const groupKey = typeLowerCase
    ? `settings.property.classes_types.toc.${typeLowerCase}_${
        group === 'class' ? 'classes' : group + 's'
      }`
    : `screen.title.settings_property_classes_types_group.${group}`;

  return (
    <ScreenHeader
      title={valueType}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.settings_property_classes_types',
            defaultMessage: 'Classes, types & tags',
          }),
          href: ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES,
        },
        {
          title: {
            key: groupKey,
          },
          href: toPath(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_GROUP, {
            classTypes: base64encode(JSON.stringify({ group, livestockType })),
          }),
        },
      ]}
      onGoBack={() =>
        linkTo(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_GROUP, {
          classTypes: base64encode(JSON.stringify({ group, livestockType })),
        })
      }
    />
  );
};

export const SettingsPropertyClassesTypesEdit: React.FC<
  ScreenRendererProps
> = ({ route }) => {
  const goBack = useNavigateBack();
  const { classTypes } = route.params;
  const { group, livestockType, valueType } = JSON.parse(
    base64decode(classTypes)
  );

  const properties = useProperties();
  const [loading, setLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (prelude) {
    return prelude;
  }

  const groupsWithColor = [
    ConfiguredPropertyTypeGroup.paddockType,
    ConfiguredPropertyTypeGroup.tag,
  ];

  const property = properties.selected;
  const parentId = getLivestockParentIdForValue(livestockType, property.types);
  const items = getConfiguredPropertyTypes(property, group, parentId);

  const itemType = items.find((item) => item.type === valueType);

  const hasTagColors = group === ConfiguredPropertyTypeGroup.tag;
  const showDeleteButton = !hasTagColors;

  const onSubmit = (formValues: SettingsPropertyTypesEditFormValues) => {
    setLoading(true);
    properties
      .updatePropertyType({
        ...itemType,
        ...formValues,
      })
      .then(() => {
        setLoading(false);
        return;
      });
  };

  const onDelete = () => {
    setDeleteLoading(true);
    properties.removePropertyType(itemType).then(() => {
      setDeleteLoading(false);
      goBack();
    });
  };

  if (!itemType) {
    return null;
  }

  return (
    <>
      <SettingsPropertyTypesEditForm
        showColor={groupsWithColor.includes(group)}
        hasTagColors={hasTagColors}
        type={itemType}
        loading={loading}
        onSubmit={onSubmit}
        onCancel={goBack}
      />

      {showDeleteButton && (
        <DeleteForm
          onDelete={onDelete}
          i18nRootKey="settings.property.types"
          loading={deleteLoading}
        />
      )}
    </>
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_EDIT,
  header: SettingsPropertyClassesTypesEditHeader,
  component: SettingsPropertyClassesTypesEdit,
};
