import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  RawDate,
  formatDateAndTime,
  fromRawDate,
} from '@mobble/shared/src/core/Date';
import {
  TableCellValue,
  TableColumn,
} from '@src/stories/Components/Layout/Table';
import { type Task } from '@mobble/models/src/model/Task';
import { findPaddock, type Paddock } from '@mobble/models/src/model/Paddock';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { toPath } from '@src/interfaces/Routing';
import { Text } from '@src/stories/Components/UI/Text';
import * as ROUTE_NAME from '@src/screens/config/routeNames';

export const makeTasksTableColumns = ({
  paddocks,
}: {
  paddocks: Paddock[];
}): TableColumn<TableCellValue, Task>[] => {
  const { translate } = useI18n();

  // Prevents table row click event from triggering
  const handleClick = (event: any) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'task',
      label: { key: 'task.info.table.row.title.label' },
      toValue: (task) => task.title,
      render: (_, task) => (
        <Clickable
          href={toPath(ROUTE_NAME.TASK_DETAIL, { taskId: task.id })}
          onClick={handleClick}
        >
          <Text underline variant="small">
            {task.title}
          </Text>
        </Clickable>
      ),
    },
    {
      key: 'description',
      label: { key: 'task.info.table.row.description.label' },
      toValue: (task) => task.description,
    },
    {
      key: 'due_date',
      label: { key: 'task.info.table.row.due_date.label' },
      toValue: (task) =>
        task.dueDate ? fromRawDate(task.dueDate).toDate().getTime() : null,
      valueToString: (value) =>
        value ? formatDateAndTime(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'status',
      label: { key: 'task.info.table.row.status.label' },
      toValue: (task) => translate({ key: `task.status.${task.status}` }),
    },
    {
      key: 'assigned_to',
      label: { key: 'tasks.table.heading.column.assigned_to' },
      toValue: (task) =>
        (task.assignedToUsers ?? []).map((u) => u.name).join(', '),
    },
    {
      key: 'assigned_by',
      label: { key: 'tasks.table.heading.column.assigned_by' },
      toValue: (task) => task.assignedByUser?.name,
    },
    {
      key: 'location',
      label: { key: 'tasks.task.create.form.location.label' },
      toValue: (task) => {
        if (task.paddockId) {
          return findPaddock(paddocks)(task.paddockId)?.name;
        }
        return null;
      },
      toCsvValue: (task) => {
        if (task.paddockId) {
          return findPaddock(paddocks)(task.paddockId)?.name || '';
        }
        return '';
      },
      render: (_, task) => {
        if (task.paddockId) {
          const paddock = findPaddock(paddocks)(task.paddockId);

          if (paddock) {
            return (
              <Clickable
                href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
                  paddockId: task.paddockId,
                })}
                onClick={handleClick}
              >
                <Text underline variant="small">
                  {paddock.name}
                </Text>
              </Clickable>
            );
          }
        }

        return null;
      },
    },
  ];
};
