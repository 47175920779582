import React from 'react';
import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import { useSettings } from '@mobble/store/src/hooks/settings';
import {
  QuantityOfArea,
  type Quantities,
  type QuantityOfDistance,
} from '@mobble/shared/src/core/Quantity';
import { MapDraw } from '@src/components';
import { makeQuantityI18nItem } from '@src/stories/Components/Locale/LocaleQuantity';
import {
  useMapPluginsContext,
  useMapPluginsPointsContext,
  useMapPluginsPointsDispatchContext,
} from '../Context';
import {
  ToolbarButtons,
  type ToolbarButtonProps,
} from '../Shared/ToolbarButtons';
import { MapInfoTablePoints } from '../Shared/MapInfoTablePoints';

const Toolbar: React.FC = () => {
  const pointStorage = useMapPluginsPointsContext();
  const pointStorageDispatch = useMapPluginsPointsDispatchContext();
  const { draw } = useMapPluginsContext();

  const isMeasuring = pointStorage.enabled;

  const buttons = isMeasuring
    ? [
        {
          icon: 'close',
          fill: Color.Red,
          color: Color.White,
          hint: { key: 'map.tools.measure_close.hint' },
          onClick: () => {
            pointStorageDispatch.enable(false);
          },
        },
        pointStorage.options?.mode === 'area'
          ? {
              icon: 'polygon',
              hint: { key: 'map.tools.measure_type.hint' },
              fill: Color.LightBlue,
              color: Color.White,
              onClick: () => {
                pointStorageDispatch.setOptions({
                  ...pointStorage.options,
                  mode: 'path',
                });
              },
            }
          : {
              icon: 'path',
              hint: { key: 'map.tools.measure_type.hint' },
              fill: Color.LightBlue,
              color: Color.White,
              onClick: () => {
                pointStorageDispatch.setOptions({
                  ...pointStorage.options,
                  mode: 'area',
                });
              },
            },
        ...(pointStorage.points.length > 0
          ? [
              {
                icon: 'trash',
                hint: { key: 'map.tools.measure_clear.hint' },
                fill: Color.Red,
                color: Color.White,
                onClick: () => {
                  draw.deleteAll();
                  pointStorageDispatch.clearPoints();
                },
              },
              {
                icon: 'delete',
                hint: { key: 'map.tools.measure_delete.hint' },
                fill: Color.Orange,
                color: Color.White,
                onClick: () => {
                  const selectedIds = draw.getSelectedPoints();
                  if (selectedIds.features.length) {
                    draw.trash();
                  }
                },
              },
            ]
          : []),
      ]
    : [
        {
          icon: 'ruler',
          hint: { key: 'map.tools.measure.hint' },
          onClick: () => {
            pointStorageDispatch.enable(true, { mode: 'path' });
          },
        },
      ];

  return <ToolbarButtons buttons={buttons as (null | ToolbarButtonProps)[]} />;
};

const Effect: React.FC = () => {
  const { translate } = useI18n();
  const { settings } = useSettings();
  const pointStorage = useMapPluginsPointsContext();
  const pointStorageDispatch = useMapPluginsPointsDispatchContext();
  const { map, draw } = useMapPluginsContext();

  const printArea = (area: QuantityOfArea): string => {
    const key = makeQuantityI18nItem(area, settings.areaUnit);
    return translate(key) ?? `${area.value} ${area.unit}}`;
  };

  const printDistance = (distance: QuantityOfDistance): string => {
    const key = makeQuantityI18nItem(distance, settings.distanceUnit);
    return translate(key) ?? `${distance.value} ${distance.unit}}`;
  };

  const printQuantity = (q: Quantities): string => {
    if (q.type === 'area') {
      return printArea(q);
    } else if (q.type === 'distance') {
      return printDistance(q);
    }
    return '';
  };

  const isMeasuring = pointStorage.enabled;
  if (!isMeasuring) {
    return null;
  }

  const type =
    (pointStorage.options.mode ?? 'area') === 'area' ? 'area' : 'path';

  return (
    <MapDraw
      mapRef={map}
      drawRef={draw}
      type={type}
      showMeasurements
      getMeasurementLabel={printQuantity}
      points={pointStorage.points}
      onChange={(points) => {
        pointStorageDispatch.updatePoints(points);
      }}
    />
  );
};

const Content: React.FC = () => {
  const pointStorage = useMapPluginsPointsContext();
  const isMeasuring = pointStorage.enabled;

  if (!isMeasuring) {
    return null;
  }
  return (
    <MapInfoTablePoints
      points={pointStorage?.points}
      type={pointStorage.options?.mode ?? 'area'}
    />
  );
};

export default {
  Toolbar,
  Effect,
  Content,
};
