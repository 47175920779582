import isEqual from 'lodash/isEqual';
import { type Property } from '@mobble/models/src/model/Property';
import { FilterItem } from '@mobble/models/src/model/Filter';

export type FilterPerProperty = Record<Property['id'], FilterItem[]>;

export interface UpdateActionSet {
  type: 'set';
  targetId: string;
  filterItem: FilterItem;
}

export interface UpdateActionToggle {
  type: 'toggle';
  targetId: string;
  filterItem: FilterItem;
}

export interface UpdateActionClear {
  type: 'clear';
  targetId: string;
}

export type UpdateAction =
  | UpdateActionSet
  | UpdateActionToggle
  | UpdateActionClear;

export const makeUpdateAction = (
  type: 'toggle' | 'set' | 'clear',
  targetId: string,
  filterItem?: FilterItem
): UpdateAction => {
  if (
    !filterItem ||
    (filterItem.filter.type === 'dateRange' && filterItem.filter.value.every((v) => !v))
  ) {
    return { type: 'clear', targetId };
  }
  return { type, targetId, filterItem };
};

export const reduce = (state: any, action: UpdateAction): FilterPerProperty => {
  state = JSON.parse(JSON.stringify(state));
  if (typeof state === 'undefined') {
    state = {};
  }
  if (typeof state[action.targetId] === 'undefined') {
    state[action.targetId] = [];
  }
  switch (action.type) {
    case 'clear': {
      state[action.targetId] = [];
      return state;
    }

    case 'toggle': {
      const equalsItem = (b: FilterItem) => isEqual(action.filterItem, b);
      const doesNotEqualItem = (b: FilterItem) => !equalsItem(b);

      const exists = Boolean(state[action.targetId].find(equalsItem));

      if (exists) {
        return {
          ...state,
          [action.targetId]: state[action.targetId].filter(doesNotEqualItem),
        };
      }

      return {
        ...state,
        [action.targetId]: [...state[action.targetId], action.filterItem],
      };
    }

    case 'set': {
      const equalsItem = (b: FilterItem) => action.filterItem.group === b.group;
      const doesNotEqualItem = (b: FilterItem) => !equalsItem(b);

      state[action.targetId] = state[action.targetId].filter(doesNotEqualItem);

      if (action.filterItem.filter.value !== '') {
        state[action.targetId] = [...state[action.targetId], action.filterItem];
      }
      return state;
    }
  }
};
