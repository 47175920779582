import React from 'react';
import { Box } from './Box';
import styles from './container.scss';

export type ContainerProps = {
  width?: 'small';
  children?: React.ReactNode;
  border?: boolean;
};

export const Container: React.FC<ContainerProps> = ({
  width = 'small',
  children,
  border,
}) => {
  const classNames = [
    styles.container,
    styles[`width-${width}`],
    border && styles.border,
  ]
    .filter(Boolean)
    .join(' ');

  return <Box className={classNames}>{children}</Box>;
};
