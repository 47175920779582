import React, { createContext, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import { type MapChildren } from '../types';

export interface MapContextProps {
  map: mapboxgl.Map;
}

export const MapContext = createContext<MapContextProps>({} as never);

export interface MapContextProviderProps {
  map: mapboxgl.Map;
  children?: MapChildren;
}

export const useMap = () => {
  const ctx = useContext(MapContext);
  return ctx.map;
};

export const MapContextProvider: React.FC<MapContextProviderProps> = (
  props
) => {
  return (
    <MapContext.Provider value={{ map: props.map }}>
      {props.children}
    </MapContext.Provider>
  );
};
