import React from 'react';
import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { ConfiguredPropertyType, Paddock, Purchase } from '@mobble/models';
import { formatDate } from '@mobble/shared/src/core/Date';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';
import { useSettings } from '@mobble/store/src/hooks';

import { MobCard } from '@src/components';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import { InfoRow, type InfoRowProps } from '@src/stories/Components/UI/InfoRow';

export interface PurchaseInfoTableProps {
  purchase: Purchase;
  propertyTypes: ConfiguredPropertyType[];
  paddock?: Paddock;
}

const messages = defineMessages({
  date: {
    description: 'purchase.info.table.row.date.label',
    defaultMessage: 'Date',
  },
  mob: {
    description: 'purchase.info.table.row.mob.label',
    defaultMessage: 'Mob',
  },
  notes: {
    description: 'purchase.info.table.row.notes.label',
    defaultMessage: 'Notes',
  },
  number: {
    description: 'purchase.info.table.row.number.label',
    defaultMessage: 'Head',
  },
  paddock: {
    description: 'purchase.info.table.row.paddock.label',
    defaultMessage: 'Paddock',
  },
  'price-per-head': {
    description: 'purchase.info.table.row.price-per-head.label',
    defaultMessage: 'Price per head',
  },
  'purchased-from': {
    description: 'purchase.info.table.row.purchased-from.label',
    defaultMessage: 'Purchased from',
  },
  'total-spend': {
    description: 'purchase.info.table.row.total-spend.label',
    defaultMessage: 'Total spend',
  },
});

export const PurchaseInfoTable: React.FC<PurchaseInfoTableProps> = ({
  purchase,
  propertyTypes,
  paddock,
}) => {
  const strings = useMessages(messages);
  const { settings } = useSettings();
  const { currencySymbol, dateFormat } = settings;

  const rows: (InfoRowProps | InfoRowProps[])[] = [
    {
      label: strings.mob,
      value: () => (
        <Box fill>
          <MobCard
            propertyTypes={propertyTypes}
            paddockName={paddock?.name}
            mob={purchase.mob}
          />
        </Box>
      ),
    },
    {
      label: strings.date,
      value: formatDate(purchase.date, dateFormat),
    },
    {
      label: strings.number,
      value: purchase.mob.size,
    },
    {
      label: strings['price-per-head'],
      value: formatMonetary(purchase.pricePerHeadCents, currencySymbol),
    },
    {
      label: strings['total-spend'],
      value: formatMonetary(
        purchase.pricePerHeadCents * purchase.mob.size,
        currencySymbol
      ),
    },
    paddock?.id
      ? {
          label: strings.paddock,
          value: paddock.name,
          href: toPath(ROUTE_NAME.PADDOCK_DETAIL, { paddockId: paddock.id }),
        }
      : null,
    {
      label: strings['purchased-from'],
      value: purchase.sellerName || '-',
    },
    {
      label: strings.notes,
      value: purchase.notes || '-',
    },
  ].filter(Boolean);

  return (
    <>
      {rows.map((row) =>
        Array.isArray(row) ? (
          <>
            {row.map((r) => (
              <InfoRow {...r} key={r.label as string} />
            ))}
          </>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </>
  );
};
