import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/paddockGeometry';

export const usePaddockGeometries = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddockGeometry);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const usePaddockGeometry = (paddockGeometryId: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddockGeometry);

  return proxyUseEntity({
    entityId: paddockGeometryId,
    dispatch,
    state,
  });
};
