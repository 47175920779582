import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { FilterPerProperty } from '../lib/filter';
import { type Casualty } from '@mobble/models/src/model/Casualty';
import { thunkGetAll as thunkGetAllPaddocks } from './paddocks';
import { thunkGetAll as thunkGetAllMobs } from './mobs';
import mobbleService from '@mobble/service';

export interface CasualtiesState {
  entities: Casualty[];
  extStatus: ExtStatusPerActionPerParent;
  filter: FilterPerProperty;
  sort: {};
}

export const defaultState: CasualtiesState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const toParentId = (casualty: Casualty) => casualty.propertyId;

const entitySlice = entitySliceFactory<CasualtiesState, Casualty>({
  name: 'casualties',
  initialState: defaultState,
  toParentId,
  onGetAll: mobbleService.api.casualties.get,
  onCreate: mobbleService.api.casualties.create,
  onUpdate: mobbleService.api.casualties.update,
  onDelete: mobbleService.api.casualties.remove,
  afterMutation: async ({ entity }, dispatch, type) => {
    if (type === 'update') {
      return;
    }
    dispatch(thunkGetAllPaddocks({ parentId: toParentId(entity) }));
    dispatch(thunkGetAllMobs({ parentId: toParentId(entity) }));
  },
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
