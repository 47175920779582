import React from 'react';
import { useI18n } from '@mobble/i18n';
import { dateIsInThePast, formatDate } from '@mobble/shared/src/core/Date';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import {
  findMob,
  getMobDisplayName,
  getMobSize,
  type Mob,
} from '@mobble/models/src/model/Mob';
import {
  getMobsOnPaddockAndSamePaddockGroup,
  type PaddockGroup,
} from '@mobble/models/src/model/PaddockGroup';
import {
  getLivestockParentIdForValue,
  type ConfiguredPropertyTypeGroupCustom,
  type ConfiguredPropertyType,
} from '@mobble/models/src/model/Property';
import {
  type SortOption,
  type SortSetting,
} from '@mobble/shared/src/core/Sort';

import { useDialog } from '@src/hooks/useDialog';
import { type ListSelectOption } from '@src/components/ListSelect';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { MobsSplit } from './PaddockMoveMobForm/MobsSplit';

import styles from './PaddockMoveMobForm/styles.scss';

export interface PaddockMoveMobFormProps {
  paddockOptions: ListSelectOption[];
  paddockSortOptions: SortOption[];
  sortPaddockOptionsFunction: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  propertyTypes: ConfiguredPropertyType[];
  propertyOptions: ListSelectOption[];
  paddock: Paddock;
  paddocks: Paddock[];
  mobs: Mob[];
  paddockGroups: PaddockGroup[];
  error?: string;
  loading?: boolean;
  selectedMobs?: string[];
  selectedProperty: string;
  selectedToPaddockId?: string;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: PaddockMoveMobFormValues) => void;
  onChangeProperty: (propertyId: string) => void;
  onCreateCustomField: (
    group: ConfiguredPropertyTypeGroupCustom,
    livestockTypeId: string,
    label: string
  ) => Promise<void>;
}

export interface PaddockMoveMobFormValues {
  mobs: string[];
  mobsSplit: string;
  paddockNameFrom: string;
  property: string;
  paddock: string;
  date: string;
}

export const PaddockMoveMobForm: React.FC<PaddockMoveMobFormProps> = ({
  sortPaddockOptionsFunction,
  propertyTypes,
  paddockOptions,
  paddockSortOptions,
  propertyOptions,
  paddock,
  paddocks,
  mobs,
  paddockGroups,
  selectedMobs,
  selectedProperty,
  selectedToPaddockId,
  error,
  loading,
  onCancel,
  onSubmit,
  onTouched,
  onCreateCustomField,
  onChangeProperty,
}) => {
  const { alert, close } = useDialog();
  const { translate } = useI18n();
  const selectableMobs = getMobsOnPaddockAndSamePaddockGroup(
    paddockGroups,
    paddocks
  )(paddock.id);

  const mobOptions = selectableMobs.map((mobId) => ({
    label: getMobDisplayName(mobs)(mobId) || 'unknown',
    labelExtra: getMobSize(mobs)(mobId),
    value: mobId,
  }));

  const checkUserHasMoreThanOneProperty = () => propertyOptions.length > 1;

  const handleCreateCustomField =
    (mob: Mob) =>
    (type: ConfiguredPropertyTypeGroupCustom) =>
    (value: string): Promise<void> => {
      const liveStockTypeId = getLivestockParentIdForValue(
        mob.type,
        propertyTypes
      );
      if (!liveStockTypeId) {
        return Promise.reject();
      }

      return onCreateCustomField(type, liveStockTypeId, value);
    };

  const form: FormBuilderProps<PaddockMoveMobFormValues> = {
    flex: true,
    i18nRootKey: 'paddocks.paddock.move_mob.form',
    fields: [
      {
        name: 'paddockNameFrom',
        type: 'display',
        initialValue: paddock.name,
      },
      {
        name: 'mobs',
        type: 'select-multiple',
        options: mobOptions,
        initialValue: selectedMobs
          ? [...selectedMobs]
          : [...mobOptions.map((a) => a.value)],
        required: true,
      },
      {
        name: 'mobsSplit',
        type: 'custom',
        initialValue: '{}',
        component: ({ values, onSetValue }) => (
          <MobsSplit
            paddocks={paddocks}
            onCreateCustomField={handleCreateCustomField}
            propertyTypes={propertyTypes}
            value={JSON.parse(values.mobsSplit || '{}')}
            selectedMobs={
              values.mobs.map(findMob(mobs)).filter(Boolean) as Mob[]
            }
            onChange={(value) => onSetValue(JSON.stringify(value))}
          />
        ),
        containerComponent: ({ input }) => (
          <div className={styles.mobSplitWrapperContainer}>{input}</div>
        ),
      },
      {
        name: 'property',
        type: 'select',
        options: propertyOptions,

        required: true,
        initialValue: selectedProperty,
        onChange: (values) => {
          onChangeProperty(String(values.property));
          return values;
        },
        show: checkUserHasMoreThanOneProperty,
      },
      {
        name: 'paddock',
        type: 'select',
        options: paddockOptions,
        sortOptions: paddockSortOptions,
        sortFunction: sortPaddockOptionsFunction,
        required: true,
        initialValue: selectedToPaddockId,
      },
      {
        name: 'date',
        type: 'date',
        required: true,
        initialValue: new Date().toISOString(),
        onChange: (values) => {
          if (
            values.date &&
            dateIsInThePast(values.date) &&
            formatDate(values.date) !== formatDate(new Date())
          ) {
            alert(
              translate({
                key: 'backdating_notification.alert.title',
              }) ?? '',
              translate({
                key: 'backdating_notification.alert.description',
              }) ?? '',
              [
                {
                  label:
                    translate({
                      key: 'backdating_notification.alert.confirm',
                    }) ?? '',
                  onClick: close,
                },
              ]
            );
          }
          return values;
        },
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
