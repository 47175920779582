import React from 'react';
import { type IntlFormatters } from 'react-intl';

import {
  getLivestockAgeColor,
  toMobDisplayName,
} from '@mobble/models/src/model/Mob';
import { findPaddock, Paddock } from '@mobble/models/src/model/Paddock';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { formatDate, fromRawDate, RawDate } from '@mobble/shared/src/core/Date';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';

import { Badge, Text } from '@src/components';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import { Clickable } from '@src/stories/Components/UX/Clickable';

import styles from './purchasesTableColumns.scss';

export const makePurchasesTableColumns = ({
  paddocks,
  currencySymbol,
  propertyTypes,
  formatMessage,
}: {
  paddocks: Paddock[];
  currencySymbol: string;
  propertyTypes: ConfiguredPropertyType[];
  formatMessage: IntlFormatters['formatMessage'];
}): TableColumn[] => {
  const getPaddock = findPaddock(paddocks);
  const handleClick = (event: any) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'date',
      label: formatMessage({
        description: 'purchases.table.heading.column.date',
        defaultMessage: 'Date',
      }),
      totals: false,
      toValue: (purchase) =>
        purchase.date ? fromRawDate(purchase.date).toDate().getTime() : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'head',
      label: formatMessage({
        description: 'purchases.table.heading.column.head',
        defaultMessage: 'Head',
      }),
      totals: true,
      toValue: (purchase) => purchase.mob.size,
      render: (_, purchase) => <Text>{purchase.mob.size}</Text>,
    },
    {
      key: 'type',
      label: formatMessage({
        description: 'mobs.table.heading.column.type',
        defaultMessage: 'Type',
      }),
      totals: false,
      toValue: (purchase) => purchase.mob.type,
    },
    {
      key: 'mob',
      label: formatMessage({
        description: 'purchases.table.heading.column.mob',
        defaultMessage: 'Mob',
      }),
      totals: false,
      toValue: (purchase) => toMobDisplayName(purchase.mob),
      render: (_, purchase) => {
        return <Text>{toMobDisplayName(purchase.mob)}</Text>;
      },
    },
    {
      key: 'ages',
      label: formatMessage({
        description: 'mobs.table.heading.column.ages',
        defaultMessage: 'Ages',
      }),
      totals: false,
      toValue: (casualty) => casualty.mob.ages.join(', '),
      render: (_, purchase) => {
        return (
          <ul className={styles.agesList}>
            {purchase.mob.ages.map((age) => (
              <li key={age}>
                <Badge
                  textVariant="small"
                  label={String(age)}
                  color={getLivestockAgeColor(
                    propertyTypes,
                    purchase.mob.type
                  )(age)}
                />
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      key: 'classes',
      label: formatMessage({
        description: 'purchases.table.heading.column.classes',
        defaultMessage: 'Classes',
      }),
      totals: false,
      toValue: (purchase) => purchase.mob.classes.join(', '),
    },
    {
      key: 'paddock',
      label: formatMessage({
        description: 'purchases.table.heading.column.paddock',
        defaultMessage: 'Paddock',
      }),
      totals: false,
      toValue: (purchase) => {
        return purchase;
      },
      toCsvValue: (purchase) => `"${getPaddock(purchase.paddock)?.name || ''}"`,
      render: (_, purchase) => (
        <Clickable
          href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
            paddockId: purchase.paddock,
          })}
          onClick={handleClick}
        >
          <Text underline>{getPaddock(purchase.paddock)?.name}</Text>
        </Clickable>
      ),
    },
    {
      key: 'price_per_head',
      label: formatMessage({
        description: 'purchases.table.heading.column.price_per_head',
        defaultMessage: 'Price Per Head',
      }),
      totals: (prices: number[], _, rows) => {
        const totalReceived = rows.reduce(
          (acc: number, purchase) =>
            acc + purchase.pricePerHeadCents * purchase.mob.size,
          0
        );
        const totalNumber = rows.reduce(
          (acc: number, purchase) => acc + purchase.mob.size,
          0
        );
        const avgCostPerHead = formatMonetary(
          totalReceived / totalNumber,
          currencySymbol
        );

        return formatMessage(
          {
            defaultMessage: 'Avg: {VALUE}',
            description:
              'purchases.table.heading.column.price_per_head.average',
          },
          {
            VALUE: avgCostPerHead,
          }
        );
      },
      toValue: (purchase) => purchase.pricePerHeadCents / 100,
      render: (_, purchase) => (
        <Text>
          {formatMonetary(purchase.pricePerHeadCents, currencySymbol)}
        </Text>
      ),
    },
    {
      key: 'received',
      label: formatMessage({
        description: 'purchases.table.heading.column.total',
        defaultMessage: 'Total',
      }),
      totals: (_, total: number) => {
        return formatMonetary(total * 100, currencySymbol);
      },
      toValue: (purchase) =>
        (purchase.pricePerHeadCents / 100) * purchase.mob.size,
      render: (_, purchase) => (
        <Text>
          {formatMonetary(
            purchase.pricePerHeadCents * purchase.mob.size,
            currencySymbol
          )}
        </Text>
      ),
    },
    {
      key: 'sold_to',
      label: formatMessage({
        description: 'purchases.table.heading.column.vendor',
        defaultMessage: 'Vendor',
      }),
      totals: false,
      toValue: (purchase) => purchase.sellerName,
    },
    {
      key: 'notes',
      label: formatMessage({
        description: 'purchases.table.heading.column.notes',
        defaultMessage: 'Notes',
      }),
      totals: false,
      toValue: (purchase) => purchase.notes || '',
    },
  ];
};
