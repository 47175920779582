import { UserRole, type User } from '@mobble/models/src/model/User';
import { type AuthState, updateUser } from '../reducers/auth';
import { usePreference } from './preferences';
import { useProperties } from './properties';
import { useRootSelector, useRootDispatch } from './root';

export const useAuth = (): AuthState => useRootSelector((s) => s.auth);

export const useAuthIsLoggedIn = () => useAuth().user !== null;

export const useGetUser = () => {
  const properties = useProperties();
  const debugUserRole = usePreference(
    'debug_user_role',
    properties?.selected?.id
  ); // for debug purposes

  const user = useAuth().user;

  if (!user) {
    return undefined;
  }

  return {
    ...user,
    role:
      (debugUserRole.value as UserRole) ??
      properties?.selected?.currentUserRole,
  };
};

export const useUpdateUser = () => {
  const dispatch = useRootDispatch();
  return {
    updateUser: (user: User) => dispatch(updateUser(user)),
  };
};
