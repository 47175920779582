import React from 'react';
import { type Mob } from '@mobble/models/src/model/Mob';
import { paddockForMob, type Paddock } from '@mobble/models/src/model/Paddock';
import {
  type ConfiguredPropertyType,
  type ConfiguredPropertyTypeGroupCustom,
} from '@mobble/models/src/model/Property';
import { Box } from '../../../Components/Layout/Box';
import { Divider } from '../../../Components/Layout/Divider';
import { MobSplit, type MobSplitFormValues } from './MobSplit';

export interface MobsSplitProps {
  propertyTypes: ConfiguredPropertyType[];
  selectedMobs: Mob[];
  paddocks: Paddock[];
  value: MobsSplitFormValues;
  onChange: (value: MobsSplitFormValues) => void;
  onCreateCustomField: (
    mob: Mob
  ) => (
    type: ConfiguredPropertyTypeGroupCustom
  ) => (value: string) => Promise<void>;
}

export interface MobsSplitFormValues {
  [key: string]: null | MobSplitFormValues;
}

export const MobsSplit: React.FC<MobsSplitProps> = ({
  propertyTypes,
  selectedMobs,
  paddocks,
  value,
  onChange,
  onCreateCustomField,
}) => {
  return (
    <Box>
      {selectedMobs.map((mob, index) => (
        <Box key={mob.id}>
          <MobSplit
            paddock={paddockForMob(paddocks)(mob.id)}
            value={value[mob.id]}
            propertyTypes={propertyTypes}
            mob={mob}
            onCreateCustomField={onCreateCustomField(mob)}
            onChange={(val) => {
              onChange({
                ...value,
                [mob.id]: val,
              });
            }}
          />
          {index < selectedMobs.length - 1 && <Divider />}
        </Box>
      ))}
    </Box>
  );
};
