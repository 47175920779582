import { useI18n } from '@mobble/i18n/src';

export const useFormError = ({
  entityName,
  active = true,
}: {
  entityName: string;
  active?: boolean;
}) => {
  const { formatMessage } = useI18n();
  return active
    ? formatMessage(
        {
          description: 'Generic error message when saving an entity fails',
          defaultMessage:
            'There was a problem saving your {ENTITY}. Please ensure all required fields are completed, if the problem persists please contact Mobble support at hello@mobble.io',
        },
        {
          ENTITY: entityName,
        }
      )
    : '';
};
