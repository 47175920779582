import React from 'react';
import mapboxgl from 'mapbox-gl';
import { useMap } from './lib/React/Context';
import {
  safeAddLayer,
  safeAddSource,
  safeRemoveLayer,
  safeRemoveSource,
} from './lib/helpers';

export interface CircleProps {
  id: string;
  data: GeoJSON.Point;

  circlePaint: mapboxgl.CirclePaint;

  onClick?: (
    id: string,
    ev: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[];
    } & mapboxgl.EventData
  ) => void;
}

export const Circle = (props: CircleProps) => {
  const id = (ns) => `circle-${ns}-${props.id}`;
  const map = useMap();

  const onClick = (ev) => {
    if (props.onClick) {
      props.onClick(props.id, ev);
    }
  };

  const onMouseEnter = () => {
    if (props.onClick) {
      map.getCanvas().style.cursor = 'pointer';
    }
  };

  const onMouseLeave = () => {
    if (props.onClick) {
      map.getCanvas().style.cursor = '';
    }
  };

  React.useEffect(() => {
    const cleanUp = () => {
      safeRemoveLayer(map)(id('layer'));
      safeRemoveSource(map)(id('source'));

      map.off('click', id('layer'), onClick);
      map.off('mouseenter', id('layer'), onMouseEnter);
      map.off('mouseleave', id('layer'), onMouseLeave);
    };
    safeAddSource(map)(id('source'), {
      type: 'geojson',
      data: {
        type: 'Point',
        coordinates: props.data.coordinates,
      },
    });

    safeAddLayer(map)({
      type: 'circle',
      id: id('layer'),
      source: id('source'),
      paint: props.circlePaint,
    });

    map.on('click', id('layer'), onClick);
    map.on('mouseenter', id('layer'), onMouseEnter);
    map.on('mouseleave', id('layer'), onMouseLeave);

    return () => {
      cleanUp();
    };
  }, []);

  return null;
};
