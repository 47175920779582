import { useRootSelector, useRootDispatch } from './root';
import {
  proxyUseEntities,
  proxyUseEntity,
} from '../reducers/paddockGroupGeometry';

export const usePaddockGroupGeometries = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddockGroupGeometry);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const usePaddockGroupGeometry = (paddockGroupGeometryId: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddockGroupGeometry);

  return proxyUseEntity({
    entityId: paddockGroupGeometryId,
    dispatch,
    state,
  });
};
