import { createSelector } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { type State } from '../index';

export type RootState = State;
export type RootDispatch = any;

export const useRootDispatch: () => RootDispatch = useDispatch<RootDispatch>;
export const useRootSelector: TypedUseSelectorHook<State> = useSelector;

export const useExtStatus = (
  storeName: keyof State,
  actionName: 'create' | 'delete' | 'update' | 'moveMobs',
  id: string
) => {
  const selectExtStatus = createSelector(
    (state) => state[storeName]?.extStatus[id],
    (extStatus) => extStatus?.[`${String(storeName)}/${actionName}`]
  );

  return useRootSelector(selectExtStatus);
};
