import { User, UserRole } from './User';

export const createUser = (override?: Partial<User>): User => ({
  ...USER_EXAMPLE,
  id: Math.random().toString(),
  ...override,
});

export const USER_EXAMPLE: User = {
  id: 'user-1',
  name: 'Test User',
  email: 'user@example.com',
  role: UserRole.User,
};

export const USER_ADMIN: User = {
  id: 'user-2',
  name: 'Admin User',
  email: 'admin@mobble.io',
  role: UserRole.Admin,
};

export const USER_OWNER: User = {
  id: 'user-3',
  name: 'Owner User',
  email: 'owner@mobble.io',
  role: UserRole.Owner,
};

export const USER_STOCK_AGENT: User = {
  id: 'user-4',
  name: 'Stock Agent User',
  email: 'stockagent@mobble.io',
  role: UserRole.StockAgent,
};

export const USERS: User[] = [
  USER_EXAMPLE,
  USER_ADMIN,
  USER_OWNER,
  USER_STOCK_AGENT,
];
