import { type PaddockGroup } from '@mobble/models/src/model/PaddockGroup';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { thunkGetAll as thunkGetAllPaddockGroupGeometries } from './paddockGroupGeometry';
import { thunkGetAll as thunkGetAllPaddockGroupedStockingRate } from './paddockGroupedStockingRate';
import { thunkGetAll as thunkGetAllPaddocks } from './paddocks';

export interface PaddockGroupsState {
  extStatus: ExtStatusPerActionPerParent;
  entities: PaddockGroup[];
  filter: {};
  sort: {};
}

export const defaultState: PaddockGroupsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<PaddockGroupsState>({
  name: 'paddockGroups',
  initialState: defaultState,
  toParentId: (group) => group.propertyId,
  onGetAll: mobbleService.api.paddockGroups.get,
  onCreate: mobbleService.api.paddockGroups.create,
  onUpdate: mobbleService.api.paddockGroups.update,
  onDelete: mobbleService.api.paddockGroups.delete,
  afterMutation: async ({ entity }, dispatch) => {
    const parentId = entity.propertyId;
    dispatch(thunkGetAll({ parentId }));
    dispatch(thunkGetAllPaddockGroupGeometries({ parentId }));
    dispatch(thunkGetAllPaddocks({ parentId }));
    dispatch(
      thunkGetAllPaddockGroupedStockingRate({
        parentId,
      })
    );
  },
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
