import React from 'react';
import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import { Text } from '@src/stories/Components/UI/Text';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Logo } from '@src/stories/Components/UI/Logo';
import { Box } from '@src/stories/Components/Layout/Box';
import styles from './loadingScreen.scss';

export interface LoadingScreenProps {
  label?: string;
  fullScreen?: boolean;
  background?: Color;
}

export const LoadingScreen: React.FC<LoadingScreenProps> = ({
  label,
  fullScreen,
  background,
}) => {
  const { formatMessage } = useI18n();

  const labelText =
    label ??
    formatMessage({
      defaultMessage: 'Loading',
      description: 'generic.loading.loading',
    });

  return (
    <Box
      aria-busy="true"
      aria-live="polite"
      role="status"
      background={background || (fullScreen ? Color.Green : undefined)}
      className={styles.loadingScreen}
    >
      {fullScreen && (
        <Box className={styles.logo}>
          <Logo white />
        </Box>
      )}
      <Text
        variant="small"
        align="center"
        color={fullScreen ? Color.White : Color.DarkGrey}
        i18n={labelText}
        tagName="p"
      />
      <Spinner
        aria-label={labelText}
        role="presentation"
        color={fullScreen ? Color.White : Color.DarkGrey}
        className={styles.spinner}
      />
    </Box>
  );
};
