import React, { useEffect, useState } from 'react';
import {
  type IntlFormatters,
  type ResolvedIntlConfig,
  useIntl,
  IntlProvider,
} from 'react-intl';
import {
  type Translate,
  translate as translateLegacy,
  I18nItem,
} from '../lib/i18n-item';
import {
  type I18nLocale,
  loadLocaleData,
  i18nLocales,
} from '../lib/i18n-locale';
import { keyExists } from '../lib/i18n-dictionary';
import translations from '../../translations/legacy';

const FALLBACK_LOCALE: I18nLocale = 'en-au';

export interface I18nContextProps {
  formatMessage: IntlFormatters['formatMessage'];
  translate: Translate;
  keyExists?: (key: string) => boolean;
}

export const I18nContext = React.createContext<I18nContextProps>({
  formatMessage: () => undefined,
  translate: () => undefined,
  keyExists: () => false,
});

export interface I18nProviderProps {
  userLocale?: string;
  defaultRichTextElements: ResolvedIntlConfig['defaultRichTextElements'];
  children: React.ReactNode;
  onReady?: () => void;
}

// loop over params keys and remove leading % from key
// required for legacy translate function
const transformParams = (
  params: Record<string, any> = {},
  pluralise?: number
): {} => {
  let newParams = Object.keys(params).reduce((acc, key) => {
    const newKey = key.replace(/^%/, '');
    acc[newKey] = params[key];
    return acc;
  }, {});

  if (pluralise) {
    newParams['count'] = pluralise;
  }

  return newParams;
};

export const I18nProvider: React.FC<I18nProviderProps> = ({
  userLocale,
  defaultRichTextElements,
  children,
  onReady,
}) => {
  const locale = userLocale || FALLBACK_LOCALE;
  const [messages, setMessages] = useState<Record<string, string>>(null);

  // Load new locale data when locale changes
  useEffect(() => {
    const asyncLoad = async (requestedLocale: I18nLocale) => {
      const m = await loadLocaleData(requestedLocale as I18nLocale);
      setMessages(m.default);
    };

    asyncLoad(
      i18nLocales.includes(locale as I18nLocale)
        ? (locale as I18nLocale)
        : FALLBACK_LOCALE
    );
  }, [locale]);

  useEffect(() => {
    if (messages && typeof onReady === 'function') {
      onReady();
    }
  }, [messages, onReady]);

  // Don't render until locale data is ready
  if (!messages) {
    return null;
  }

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={FALLBACK_LOCALE}
      messages={messages}
      defaultRichTextElements={defaultRichTextElements}
      onError={(err) => {
        switch (err.code) {
          case 'MISSING_TRANSLATION':
            console.warn('[i18n]: Missing translation', err.message);
            return;
          case 'MISSING_DATA':
            console.warn('[i18n]: Missing data', err.message);
            return;
          default:
            console.error(err);
        }
      }}
      // ignore warnings
      onWarn={() => {}}
    >
      {children}
    </IntlProvider>
  );
};

export const useI18n = (): I18nContextProps => {
  const { formatMessage, locale } = useIntl();

  const context = React.useContext(I18nContext);
  if (context === undefined) {
    throw new Error('[i18n]: useI18n must be used within a I18nProvider');
  }

  return {
    // react-intl formatMessage
    formatMessage,

    // legacy translation function
    translate: (item: I18nItem) => {
      if (!item.key) {
        console.warn(
          '[i18n]: No key provided for translation',
          JSON.stringify(item)
        );
        return item.defaultTranslation || '';
      }

      // attempt to use `react-intl`
      const intlString = formatMessage(
        {
          id: item.key,
          defaultMessage: [],
        },
        transformParams(item.params, item.pluralise)
      );

      // fallback to legacy translation
      if (!intlString) {
        console.warn(
          '[i18n]: Using legacy translation for',
          JSON.stringify(item),
          JSON.stringify(transformParams(item.params, item.pluralise))
        );

        const legacyString = translateLegacy(translations)({
          ...item,
          params: item.params,
        });

        // return key if no translation found
        if (legacyString === item.key) {
          console.warn(
            '[i18n]: No translation found for',
            JSON.stringify(item)
          );
          return item.key;
        }

        return legacyString;
      }

      return intlString;
    },

    // currently used for form builder
    keyExists: keyExists(translations[locale]),
  };
};
