export interface EntitySliceFactoryProxyPrelude {
  name: string;
  // loading: boolean;
  // failed: boolean;
  status: PreludeStatus;
  lastError?: string;
  onRetry?: () => Promise<void>;
}

export enum PreludeStatus {
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}
