import React from 'react';
import { type I18nContextProps } from '@mobble/i18n';
import {
  getLivestockAgeColor,
  toMobDisplayName,
} from '@mobble/models/src/model/Mob';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';
import { Paddock, findPaddock } from '@mobble/models/src/model/Paddock';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { Sale } from '@mobble/models/src/model/Sale';
import { RawDate, formatDate, fromRawDate } from '@mobble/shared/src/core/Date';

import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';

import { TableColumn } from '@src/stories/Components/Layout/Table';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Text } from '@src/stories/Components/UI/Text';
import { Box } from '@src/stories/Components/Layout/Box';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Badge } from '@src/stories/Components/UI/Badge';

export const makeSalesTableColumns = ({
  paddocks,
  currencySymbol,
  propertyTypes,
  formatMessage,
}: {
  paddocks: Paddock[];
  currencySymbol: string;
  propertyTypes: ConfiguredPropertyType[];
  formatMessage: I18nContextProps['formatMessage'];
}): TableColumn[] => {
  const getPaddock = findPaddock(paddocks);

  const handleClick = (event) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'date',
      label: formatMessage({ defaultMessage: 'Date' }),
      totals: false,
      toValue: (sale: Sale) =>
        sale.date ? fromRawDate(sale.date).toDate().getTime() : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'head',
      label: formatMessage({ defaultMessage: 'Head' }),
      totals: true,
      toValue: (sale: Sale) => sale.number,
      render: (_, sale) => (
        <Text underline variant="small">
          {sale.number}
        </Text>
      ),
    },
    {
      key: 'type',
      label: formatMessage({ defaultMessage: 'Type' }),
      totals: false,
      toValue: (sale: Sale) => sale.mob.type,
    },
    {
      key: 'mob',
      label: formatMessage({ defaultMessage: 'Mob' }),
      totals: false,
      toValue: (sale) => toMobDisplayName(sale.mob),
      render: (_, sale: Sale) => {
        return <Text variant="small">{toMobDisplayName(sale.mob)}</Text>;
      },
    },
    {
      key: 'ages',
      label: formatMessage({ defaultMessage: 'Ages' }),
      totals: false,
      toValue: (casualty) => casualty.mob.ages.join(', '),
      render: (_, sale: Sale) => {
        return (
          <div style={{ marginTop: '-8px', marginBottom: '-8px' }}>
            <HStack wrap>
              {sale.mob.ages.map((age) => (
                <Box
                  key={age}
                  spacing={{ top: 0, right: 0.5, bottom: 0.25, left: 0 }}
                >
                  <Badge
                    textVariant="small"
                    key={age}
                    label={String(age)}
                    color={getLivestockAgeColor(
                      propertyTypes,
                      sale.mob.type
                    )(age)}
                  />
                </Box>
              ))}
            </HStack>
          </div>
        );
      },
    },
    {
      key: 'classes',
      label: formatMessage({ defaultMessage: 'Classes' }),
      totals: false,
      toValue: (sale: Sale) => sale.mob.classes.join(', '),
    },
    {
      key: 'paddock',
      label: formatMessage({ defaultMessage: 'Paddock' }),
      totals: false,
      toValue: (sale) => {
        return sale;
      },
      toCsvValue: (sale: Sale) =>
        `"${getPaddock(sale.paddock?.id)?.name || ''}"`,
      render: (_, sale: Sale) => (
        <Clickable
          href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
            paddockId: sale.paddock?.id,
          })}
          onClick={handleClick}
        >
          <Text underline variant="small">
            {sale.paddock?.name}
          </Text>
        </Clickable>
      ),
    },
    {
      key: 'price_per_head',
      label: formatMessage({ defaultMessage: 'Price Per Head' }),
      totals: (prices: number[], _, rows) => {
        const totalReceived = rows.reduce(
          (acc: number, sale) => acc + sale.pricePerHeadCents * sale.number,
          0
        );
        const totalNumber = rows.reduce(
          (acc: number, sale) => acc + sale.number,
          0
        );
        const avgCostPerHead = formatMonetary(
          totalReceived / totalNumber,
          currencySymbol
        );

        return formatMessage(
          {
            defaultMessage: 'Avg: {value}',
          },
          {
            value: avgCostPerHead,
          }
        );
      },
      toValue: (sale) => sale.pricePerHeadCents / 100,
      render: (_, sale: Sale) => (
        <Text variant="small">
          {formatMonetary(sale.pricePerHeadCents, currencySymbol)}
        </Text>
      ),
    },
    {
      key: 'received',
      label: formatMessage({ defaultMessage: 'Received' }),
      totals: (_, total: number) => {
        return formatMonetary(total * 100, currencySymbol);
      },
      toValue: (sale) => (sale.pricePerHeadCents / 100) * sale.mob.size,
      render: (_, sale) => (
        <Text variant="small">
          {formatMonetary(
            sale.pricePerHeadCents * sale.mob.size,
            currencySymbol
          )}
        </Text>
      ),
    },
    {
      key: 'sold_to',
      label: formatMessage({ defaultMessage: 'Sold to' }),
      totals: false,
      toValue: (sale: Sale) => sale.soldToName,
    },
    {
      key: 'notes',
      label: formatMessage({ defaultMessage: 'Notes' }),
      totals: false,
      toValue: (sale: Sale) => sale.notes,
    },
  ];
};
