import { createContext, useContext } from 'react';

import { defaultState } from '@mobble/store/src/reducers/settings';

export const SettingsContext = createContext(defaultState);

export const useSettings = () => {
  return useContext(SettingsContext);
};

export const useSetting = (key) => {
  const settings = useSettings();
  return settings[key];
};
