import React from 'react';

import { Color } from '@mobble/colors';
import { Text } from '@src/stories/Components/UI/Text';
import { VStack } from '@src/stories/Components/Layout/Stack';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { Logo } from '@src/stories/Components/UI/Logo';
import { Box } from '@src/stories/Components/Layout/Box';
import { Button } from '@src/stories/Components/UX/Button';

import styles from './OfflineNoDataScreen.scss';

export interface OfflineNoDataScreenProps {
  onRefresh: () => void;
}

export const OfflineNoDataScreen: React.FC<OfflineNoDataScreenProps> = ({
  onRefresh,
}) => {
  return (
    <Box className={styles.offlineNoDataScreen} flex background={Color.Green}>
      <VStack flex alignment="center">
        <Spacer flex />
        <VStack alignment="center">
          <Logo white />
          <Spacer y={4} />
          <Text
            variant="small"
            align="center"
            color={Color.White}
            i18n={{ key: 'offline.no_data.screen.description' }}
          />
          <Spacer y={6} />
          <Button
            label={{ key: 'offline.no_data.screen.button' }}
            intent="primary"
            outline
            onClick={onRefresh}
          />
        </VStack>
        <Spacer flex />
      </VStack>
    </Box>
  );
};
