import React from 'react';
import { useMap } from './lib/React/Context';
import {
  safeAddLayer,
  safeAddSource,
  safeRemoveLayer,
  safeRemoveSource,
} from './lib/helpers';
import mapboxgl from 'mapbox-gl';

export interface LineProps {
  id: string;
  data: GeoJSON.LineString;

  linePaint: mapboxgl.LinePaint;
  lineLayout?: mapboxgl.LineLayout;

  symbolLayout?: mapboxgl.SymbolLayout;
  symbolPaint?: mapboxgl.SymbolPaint;

  onClick?: (
    id: string,
    ev: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[];
    } & mapboxgl.EventData
  ) => void;
}

export const Line = (props: LineProps) => {
  const id = (ns) => `line-${ns}-${props.id}`;
  const map = useMap();

  const onClick = (ev) => {
    if (props.onClick) {
      props.onClick(props.id, ev);
    }
  };

  const onMouseEnter = () => {
    if (props.onClick) {
      map.getCanvas().style.cursor = 'pointer';
    }
  };

  const onMouseLeave = () => {
    if (props.onClick) {
      map.getCanvas().style.cursor = '';
    }
  };

  React.useEffect(() => {
    const cleanUp = () => {
      safeRemoveLayer(map)(id('line'));
      if (props.symbolLayout || props.symbolPaint) {
        safeRemoveLayer(map)(id('label'));
      }
      safeRemoveSource(map)(id('source'));

      if (props.onClick) {
        map.off('click', id('line'), onClick);
      }
      map.off('mouseenter', id('line'), onMouseEnter);
      map.off('mouseleave', id('line'), onMouseLeave);
    };
    safeAddSource(map)(id('source'), {
      type: 'geojson',
      data: {
        type: 'LineString',
        coordinates: props.data.coordinates,
      },
    });

    if (props.linePaint) {
      safeAddLayer(map)({
        type: 'line',
        id: id('line'),
        source: id('source'),
        paint: props.linePaint,
      });

      map.on('click', id('line'), onClick);
      map.on('mouseenter', id('line'), onMouseEnter);
      map.on('mouseleave', id('line'), onMouseLeave);
    }

    if (props.symbolLayout || props.symbolPaint) {
      safeAddLayer(map)({
        type: 'symbol',
        id: id('label'),
        source: id('source'),
        layout: props.symbolLayout,
        paint: props.symbolPaint,
      });
    }

    return () => {
      cleanUp();
    };
  }, []);

  return null;
};
