import React from 'react';
import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import {
  ConfiguredPropertyType,
  NaturalIncrease,
  Paddock,
} from '@mobble/models';
import { formatDate } from '@mobble/shared/src/core/Date';
import { useSetting } from '@mobble/store/src/hooks';

import { MobCard } from '@src/components';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import { InfoRow, type InfoRowProps } from '@src/stories/Components/UI/InfoRow';

import styles from './naturalIncreaseInfoTable.scss';

export interface NaturalIncreaseInfoTableProps {
  naturalIncrease: NaturalIncrease;
  paddock?: Paddock;
  propertyTypes: ConfiguredPropertyType[];
}

const messages = defineMessages({
  date: {
    description: 'naturalIncrease.info.table.row.date.label',
    defaultMessage: 'Date',
  },
  mob: {
    description: 'naturalIncrease.info.table.row.mob.label',
    defaultMessage: 'Mob',
  },
  notes: {
    description: 'naturalIncrease.info.table.row.notes.label',
    defaultMessage: 'Notes',
  },
  number: {
    description: 'naturalIncrease.info.table.row.number.label',
    defaultMessage: 'Number',
  },
  paddock: {
    description: 'naturalIncrease.info.table.row.paddock.label',
    defaultMessage: 'Paddock',
  },
});

export const NaturalIncreaseInfoTable: React.FC<
  NaturalIncreaseInfoTableProps
> = ({ naturalIncrease, paddock, propertyTypes }) => {
  const strings = useMessages(messages);
  const dateFormat = useSetting('dateFormat') as string;

  const rows: (InfoRowProps | InfoRowProps[])[] = [
    {
      label: strings.mob,
      value: () => (
        <Box fill>
          <MobCard
            propertyTypes={propertyTypes}
            paddockName={paddock?.name}
            mob={naturalIncrease.mob}
          />
        </Box>
      ),
    },
    {
      label: strings.date,
      value: formatDate(naturalIncrease.date, dateFormat),
    },

    {
      label: strings.number,
      value: naturalIncrease.mob.size,
    },
    naturalIncrease.paddock?.id
      ? {
          label: strings.paddock,
          value: paddock.name,
          href: toPath(ROUTE_NAME.PADDOCK_DETAIL, {
            paddockId: naturalIncrease.paddock.id,
          }),
        }
      : null,
    {
      label: strings.notes,
      value: naturalIncrease.notes || '',
    },
  ].filter(Boolean);

  return (
    <Box className={styles.naturalIncreaseInfoTable}>
      {rows.map((row) =>
        Array.isArray(row) ? (
          <>
            {row.map((r) => (
              <InfoRow {...r} key={r.label as string} />
            ))}
          </>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </Box>
  );
};
