import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/customMapLayers';

export const useCustomMapLayers = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.customMapLayers);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const useCustomMapLayer = (layerId: string, propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.customMapLayers);

  return proxyUseEntity({
    entityId: layerId,
    parentId: propertyId,
    dispatch,
    state,
  });
};
