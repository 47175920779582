import { I18nItem } from '@mobble/i18n';
import { type IconName } from '@mobble/icon';
import { UserRole } from '@mobble/models/src/model/User';
import * as ROUTE_NAME from './routeNames';

export interface BottomTabBarMenuItemConfig {
  label: I18nItem;
  route: string;
  icon: IconName;
  activeOnRoutes: string[];
}

const MAP_ROUTE = '/map'
const REPORTS_ROUTE = '/reports'

const MENU_ITEMS: Record<string, BottomTabBarMenuItemConfig> = {
  menuItemPaddocks: {
    label: {
      key: 'bottom_main_tab_bar.route.root.drawer.tabs.app.paddocks_list.label',
    },
    route: ROUTE_NAME.PADDOCKS_LIST,
    icon: 'paddock',
    activeOnRoutes: [
      'initial',
      ROUTE_NAME.PADDOCKS_LIST,
      ROUTE_NAME.PADDOCK_DETAIL,
      ROUTE_NAME.PADDOCK_DETAIL_EDIT,
    ],
  },

  menuItemMobs: {
    label: {
      key: 'bottom_main_tab_bar.route.root.drawer.tabs.app.mobs_list.label',
    },
    route: ROUTE_NAME.MOBS_LIST,
    icon: 'mob',
    activeOnRoutes: [
      ROUTE_NAME.MOBS_LIST,
      ROUTE_NAME.MOB_DETAIL,
      ROUTE_NAME.MOB_DETAIL_EDIT,
    ],
  },

  menuItemMap: {
    label: {
      key: 'bottom_main_tab_bar.route.root.drawer.tabs.app.map_overview.label',
    },
    route: ROUTE_NAME.MAP_OVERVIEW,
    icon: 'map',
    activeOnRoutes: [ROUTE_NAME.MAP_OVERVIEW, MAP_ROUTE],
  },

  menuItemTasks: {
    label: {
      key: 'bottom_main_tab_bar.route.root.drawer.tabs.app.tasks_list.label',
    },
    route: ROUTE_NAME.TASKS_LIST,
    icon: 'clipboard',
    activeOnRoutes: [
      ROUTE_NAME.TASKS_LIST,
      ROUTE_NAME.TASK_DETAIL,
      ROUTE_NAME.TASK_EDIT,
    ],
  },

  menuItemSales: {
    label: {
      key: `bottom_main_tab_bar.route.root.drawer.tabs.app.summary.sales_list.label`,
    },
    route: ROUTE_NAME.SUMMARY_SALES_LIST,
    icon: 'dollar-sign',
    activeOnRoutes: [
      ROUTE_NAME.SUMMARY_SALES_LIST,
      ROUTE_NAME.SALE_DETAIL,
      ROUTE_NAME.SALE_EDIT,
    ],
  },

  menuItemPurchases: {
    label: {
      key: `bottom_main_tab_bar.route.root.drawer.tabs.app.summary.purchases_list.label`,
    },
    route: ROUTE_NAME.SUMMARY_PURCHASES,
    icon: 'credit-card',
    activeOnRoutes: [
      ROUTE_NAME.SUMMARY_PURCHASES,
      ROUTE_NAME.PURCHASE_DETAIL,
      ROUTE_NAME.PURCHASE_EDIT,
    ],
  },

  menuItemNaturalIncreases: {
    label: {
      key: `bottom_main_tab_bar.route.root.drawer.tabs.app.summary.natural_increases_list.label`,
    },
    route: ROUTE_NAME.SUMMARY_NATURAL_INCREASES_LIST,
    icon: 'arrow-up-circle',
    activeOnRoutes: [
      ROUTE_NAME.SUMMARY_NATURAL_INCREASES_LIST,
      ROUTE_NAME.NATURAL_INCREASE_DETAIL,
      ROUTE_NAME.NATURAL_INCREASE_EDIT,
    ],
  },

  menuItemNaturalCasualties: {
    label: {
      key: `bottom_main_tab_bar.route.root.drawer.tabs.app.summary.casualties_list.label`,
    },
    route: ROUTE_NAME.SUMMARY_CASUALTIES,
    icon: 'crosshair',
    activeOnRoutes: [
      ROUTE_NAME.SUMMARY_CASUALTIES,
      ROUTE_NAME.CASUALTY_DETAIL,
      ROUTE_NAME.CASUALTY_EDIT,
    ],
  },

  menuItemReports: {
    label: {
      key: `bottom_main_tab_bar.route.root.drawer.tabs.app.reports.label`,
    },
    route: ROUTE_NAME.REPORTS,
    icon: 'file-text',
    activeOnRoutes: [ROUTE_NAME.REPORTS, REPORTS_ROUTE],
  },
};

export const MENU_ITEMS_FOR_ROLE: Record<string, BottomTabBarMenuItemConfig[]> = {
  default: [
    MENU_ITEMS.menuItemPaddocks,
    MENU_ITEMS.menuItemMobs,
    MENU_ITEMS.menuItemMap,
    MENU_ITEMS.menuItemTasks,
  ],

  [UserRole.Accountant]: [
    MENU_ITEMS.menuItemSales,
    MENU_ITEMS.menuItemPurchases,
    MENU_ITEMS.menuItemNaturalIncreases,
    MENU_ITEMS.menuItemNaturalCasualties,
  ],

  [UserRole.StockAgent]: [
    MENU_ITEMS.menuItemMobs,
    MENU_ITEMS.menuItemSales,
    MENU_ITEMS.menuItemPurchases,
    MENU_ITEMS.menuItemReports,
  ],
  [UserRole.Contractor]: [MENU_ITEMS.menuItemMap, MENU_ITEMS.menuItemTasks],
};

export const ACTION_BUTTON_ENABLED_FOR_USER_ROLES = [
  UserRole.User,
  UserRole.Admin,
  UserRole.Owner,
  UserRole.FarmAdvisor,
  UserRole.StockAgent,
];
