import React, { useState } from 'react';

import { useI18n } from '@mobble/i18n';
import { type FetcherError } from '@mobble/service/src/lib/Fetcher';

import { getCookie } from '@src/helpers/getCookie';
import { AuthWrapper } from '@src/stories/Views/Auth/AuthWrapper';
import { useAuthentication } from '@mobble/shared/src/hooks/useAuthentication';
import { useGetInvitationMeta } from '@src/hooks/useInvations';
import { Container } from '@src/stories/Components/Layout/Container';
import * as ROUTE_NAME from '../config/routeNames';
import { AuthHelp } from '@src/stories/Views/Auth/AuthHelp';
import {
  SignUpForm,
  SignUpFormValues,
} from '@src/stories/Views/Auth/SignUpForm';
import {
  countries,
  useGetCountryFromQueryParams,
} from './hooks/useGetCountryFromQueryParams';
import { useTitle } from '@src/hooks/useTitle';
import {
  ANALYTICS_EVENTS,
  TRIAL_SIGNUP,
  GTM_SIGN_UP,
} from '@src/constants/analytics';
import { COOKIES } from '@src/constants/cookies';

const STRIPE_TRIAL_KEY = process.env.REACT_APP_STRIPE_TRIAL_KEY;
const STRIPE_TRIAL_KEY_NZ = process.env.REACT_APP_STRIPE_TRIAL_KEY_NZ;

const handleAnalytics = (values: SignUpFormValues) => {
  if (window.analytics) {
    window.analytics.track(ANALYTICS_EVENTS.TRIAL_SIGNUP_FORM_COMPLETED, {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      country: values.country,
      address: values.address,
      farm_role: values.farmRole,
      initial_source: getCookie(COOKIES.INITIAL_SOURCE),
      signup_source: getCookie(COOKIES.SIGNUP_SOURCE),
      internalTraffic: getCookie(COOKIES.INTERNAL_TRAFFIC),
      lead_source: TRIAL_SIGNUP,
    });
    window.analytics.identify(values.email, {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      country: values.country,
      address: values.address,
      farm_role: values.farmRole,
      initial_source: getCookie(COOKIES.INITIAL_SOURCE),
      signup_source: getCookie(COOKIES.SIGNUP_SOURCE),
      internalTraffic: getCookie(COOKIES.INTERNAL_TRAFFIC),
      lead_source: TRIAL_SIGNUP,
    });
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      event: GTM_SIGN_UP.EVENT,
      formId: GTM_SIGN_UP.FORM_ID,
    });
  }
};

export const SignUp: React.FC = () => {
  const { formatMessage } = useI18n();
  const [stateLoading, setStateLoading] = useState(false);
  const [error, setError] = useState<FetcherError>(null);
  const { signUpForTrial } = useAuthentication();

  useTitle(
    formatMessage({
      defaultMessage: 'Sign Up',
      description: 'Sign Up page title',
    })
  );

  const country = useGetCountryFromQueryParams();
  const timeZone =
    Intl.DateTimeFormat().resolvedOptions().timeZone || 'Australia/Sydney';

  const invitationMeta = useGetInvitationMeta();

  const countryValue = countries?.find(
    (val) => val.value === country?.value
  )?.value;

  const initialValues: Partial<SignUpFormValues> = {
    email: invitationMeta?.email || '',
    password: '',
    firstName: invitationMeta?.farmersFirstName || '',
    lastName: invitationMeta?.farmersLastName || '',
    farmRole: invitationMeta?.farmersRole || '',
    country: countryValue || undefined,
    phone: country ? '+' + country.phone : '',
    address: '',
    termsAgreed: false,
  };

  const handleSubmit = (values: SignUpFormValues) => {
    setError(null);
    setStateLoading(true);
    signUpForTrial({
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      farmRole: values.farmRole,
      country: values.country,
      phone: values.phone,
      address: values.address,
      tz: timeZone,

      trialKey:
        values.country === 'nz' ? STRIPE_TRIAL_KEY_NZ : STRIPE_TRIAL_KEY,
    })
      .then(() => {
        setStateLoading(false);
        handleAnalytics(values);
      })
      .catch((e) => {
        setStateLoading(false);
        setError(e);
      });
  };

  return (
    <AuthWrapper>
      <Container width="small">
        <SignUpForm
          error={error}
          loading={stateLoading}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      </Container>
      <AuthHelp signIn={ROUTE_NAME.AUTH_SIGN_IN} />
    </AuthWrapper>
  );
};

export default {
  name: ROUTE_NAME.AUTH_SIGN_UP,
  component: SignUp,
};
