import React, { useEffect, useState } from 'react';
import { Map } from 'mapbox-gl';
import { type Point } from '@mobble/models/src/model/MapGeometry';
import { Marker } from '@src/stories/Mapbox/Marker';
import { useMapPluginsContext } from '../Context';
import { ToolbarButton } from '../Shared/ToolbarButtons';
import { geolocationPositionToPoint } from '../Shared/helpers';

import styles from './user.scss';

let lastKnownPosition: null | GeolocationPosition = null;

export const getCurrentPosition = (): Promise<GeolocationPosition> => {
  if (lastKnownPosition) {
    return Promise.resolve(lastKnownPosition);
  }
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (err) => {
        reject(err);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
      }
    );
  });
};

export const cameraPanToUserLocation = async (
  map: Map | null
): Promise<void> => {
  return getCurrentPosition().then((geolocationPosition) => {
    if (map) {
      const point = geolocationPositionToPoint(geolocationPosition);
      map.easeTo({
        center: point.coordinates as [number, number],
        zoom: 15,
      });
    }
  });
};

const Toolbar: React.FC = () => {
  const { map } = useMapPluginsContext();
  const [loading, setLoading] = useState(false);

  const onGoToUserLocation = () => {
    setLoading(true);

    cameraPanToUserLocation(map)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ToolbarButton
      icon="crosshair"
      hint={{
        key: loading ? 'map.tools.user_loading.hint' : 'map.tools.user.hint',
      }}
      loading={loading}
      onClick={onGoToUserLocation}
    />
  );
};

const Effect = () => {
  const [userLocation, setUserLocation] = useState<Point | null>(null);
  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (geolocationPosition) => {
        lastKnownPosition = geolocationPosition;
        setUserLocation(geolocationPositionToPoint(geolocationPosition));
      },
      () => {
        setUserLocation(null);
      },
      { enableHighAccuracy: true }
    );
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);
  return userLocation ? (
    <Marker id="user_location" data={userLocation}>
      <div className={styles.positionDot} />
    </Marker>
  ) : null;
};

export default {
  Toolbar,
  Effect,
};
