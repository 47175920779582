export enum SortDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export interface SortOption {
  name: string;
  settings: SortSetting[];
}

export interface SortSetting {
  column: string;
  direction: SortDirection;
}
