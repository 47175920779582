import React from 'react';
import { type FormikErrors } from 'formik';
import { roundNumberTo } from '@mobble/shared/src/core/Number';
import { FormBuilder, type FormBuilderProps } from '../../Form/FormBuilder';

export interface MobCreateWizardPurchaseProps {
  formValues?: MobCreateWizardPurchaseFormValues;
  mobSize: number;
  onChange: (
    values: MobCreateWizardPurchaseFormValues,
    errors: FormikErrors<MobCreateWizardPurchaseFormValues>
  ) => void;
}

export interface MobCreateWizardPurchaseFormValues {
  price_per_head: number | string;
  total_price: number | string;
  date: string;
  vendor?: string;
  notes?: string;
}

export const MobCreateWizardPurchase: React.FC<
  MobCreateWizardPurchaseProps
> = ({ formValues, mobSize, onChange }) => {
  const formProps: FormBuilderProps<MobCreateWizardPurchaseFormValues> = {
    i18nRootKey: 'mobs.mob.create.form.purchase_form',
    footer: false,
    fields: [
      {
        name: 'price_per_head',
        type: 'float',
        initialValue: formValues?.price_per_head,
        required: true,
        onChange: (values) => {
          return {
            ...values,
            total_price: values.price_per_head
              ? roundNumberTo(2)((Number(values.price_per_head) || 0) * mobSize)
              : '',
          };
        },
      },
      {
        name: 'total_price',
        type: 'float',
        initialValue: formValues?.total_price,
        required: true,
        onChange: (values) => {
          return {
            ...values,
            price_per_head: values.total_price
              ? Number(roundNumberTo(2)(Number(values.total_price) / mobSize))
              : 0,
          };
        },
      },
      {
        name: 'date',
        type: 'date',
        initialValue: formValues?.date,
        required: true,
      },
      {
        name: 'vendor',
        type: 'text',
      },
      {
        name: 'notes',
        type: 'textarea',
      },
    ],
    onSubmit: () => {},
    onChange: (values, errors) => {
      onChange(values, errors);
    },
  };

  return <FormBuilder {...formProps} />;
};
