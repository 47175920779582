import React from 'react';
import { useI18n } from '@mobble/i18n';
import * as ROUTE_NAME from '../config/routeNames';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { useLinking } from '../../hooks/useLinking';
import { usePaddockActions } from '@mobble/store/src/hooks/paddockActions';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenRendererProps } from '../config/types';
import { PaddockActionsViewer } from '@src/stories/Views/PaddockAction/List/PaddockActionsViewer';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';

export const SummaryPaddockActionsHeader: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Paddock actions',
        description: 'screen.title.summary_paddock_actions',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Summaries',
            description: 'screen.title.summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SUMMARIES_LIST);
      }}
    />
  );
};

export const SummaryPaddockActions: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddockActions = usePaddockActions(propertyId);

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, paddockActions.prelude],
    required: [properties.selected],
  });

  useEntitiesRefresher([paddockActions], propertyId);

  if (prelude) {
    return prelude;
  }

  return (
    <PaddockActionsViewer
      title={formatMessage({
        defaultMessage: 'Paddock actions',
        description: 'screen.title.summary_paddock_actions',
      })}
      provider={paddockActions}
    />
  );
};

export default {
  name: ROUTE_NAME.SUMMARY_PADDOCK_ACTIONS_LIST,
  header: SummaryPaddockActionsHeader,
  component: SummaryPaddockActions,
};
