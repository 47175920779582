import React from 'react';
import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { Casualty, ConfiguredPropertyType, Paddock } from '@mobble/models';
import { formatDate } from '@mobble/shared/src/core/Date';
import { useSetting } from '@mobble/store/src/hooks';

import { MobCard } from '@src/components';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import { InfoRow, type InfoRowProps } from '@src/stories/Components/UI/InfoRow';

import styles from './casualtyInfoTable.scss';

export interface CasualtyInfoTableProps {
  casualty: Casualty;
  paddock: Paddock;
  propertyTypes: ConfiguredPropertyType[];
}

const messages = defineMessages({
  created_by: {
    description: 'casualty.info.table.row.created_by.label',
    defaultMessage: 'Created by',
  },
  date: {
    description: 'casualty.info.table.row.date.label',
    defaultMessage: 'Date',
  },
  disposal: {
    description: 'casualty.info.table.row.disposal.label',
    defaultMessage: 'Method of Disposal',
  },
  mob: {
    description: 'casualty.info.table.row.mob.label',
    defaultMessage: 'Mobs',
  },
  paddock: {
    description: 'casualty.info.table.row.paddock.label',
    defaultMessage: 'Paddock',
  },
  reason: {
    description: 'casualty.info.table.row.reason.label',
    defaultMessage: 'Reason',
  },
});

export const CasualtyInfoTable: React.FC<CasualtyInfoTableProps> = ({
  casualty,
  paddock,
  propertyTypes,
}) => {
  const dateFormat = useSetting('dateFormat') as string;
  const strings = useMessages(messages);

  const mob = {
    ...casualty.mob,
    size: casualty.number,
  };

  const rows: (InfoRowProps | InfoRowProps[])[] = [
    {
      label: strings.mob,
      value: () => (
        <Box fill>
          <MobCard
            propertyTypes={propertyTypes}
            paddockName={paddock?.name}
            mob={mob}
          />
        </Box>
      ),
    },
    casualty?.paddock
      ? {
          label: strings.paddock,
          value: paddock.name,
          href: toPath(ROUTE_NAME.PADDOCK_DETAIL, {
            paddockId: casualty.paddock,
          }),
        }
      : null,
    {
      label: strings.created_by,
      value: casualty.createdBy.name,
    },
    {
      label: strings.date,
      value: casualty.date ? formatDate(casualty.date, dateFormat) : ' - ',
    },
    {
      label: strings.reason,
      value: casualty.reason || ' - ',
    },
    {
      label: strings.disposal,
      value: casualty.methodOfDisposal || ' - ',
    },
  ].filter(Boolean);

  return (
    <Box className={styles.casualtyInfoTable}>
      {rows.map((row) =>
        Array.isArray(row) ? (
          <>
            {row.map((r) => (
              <InfoRow {...r} key={r.label as string} />
            ))}
          </>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </Box>
  );
};
