import { PaddockGroup } from '@mobble/models/src/model/PaddockGroup';
import { type ApiPrelude } from '../types';

const decodePaddockGroup =
  (propertyId: string) =>
  (paddockGroup: any): PaddockGroup => ({
    id: paddockGroup.groupId || '',
    paddockIds: paddockGroup.paddockIds || [],
    propertyId,
  });

const decodePaddockGroups =
  (propertyId: string) =>
  (paddockGroups: any): PaddockGroup[] =>
    paddockGroups.map(decodePaddockGroup(propertyId));

const GET_PADDOCK_GROUPS = `
  query paddockGroups($propertyId: ID!) {
    paddockGroups(propertyId: $propertyId) {
      nodes {
        groupId
        paddockIds
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{ entities: PaddockGroup[] }> => {
    const response = await prelude.graphql({
      query: GET_PADDOCK_GROUPS,
      variables: {
        propertyId: parentId,
      },
    });

    const entities = decodePaddockGroups(parentId)(
      response.data.paddockGroups.nodes
    );

    return { entities };
  };

const ADD_PADDOCK_GROUP = `
  mutation CreatePaddockGroup($propertyId: ID!, $paddockIds: [ID!]!) {
    createPaddockGroup(
      input: { 
        propertyId: $propertyId
        paddockIds: $paddockIds 
      }
    ) {
      groupId
      paddockIds
    }
  }
`;

export const create =
  (prelude: ApiPrelude) =>
  async ({
    entity,
  }: {
    entity: Omit<PaddockGroup, 'id'>;
  }): Promise<PaddockGroup> => {
    const variables = {
      propertyId: entity.propertyId,
      paddockIds: entity.paddockIds,
    };

    const response = await prelude.graphql({
      query: ADD_PADDOCK_GROUP,
      variables,
    });

    const entities = decodePaddockGroup(entity.propertyId)(
      response.data.createPaddockGroup
    );

    return entities;
  };

export const UNGROUP_PADDOCK_GROUP = `
  mutation ungroupPaddockGroups($propertyId: ID!, $paddockIds: [ID!]!) {
    ungroupPaddocks(
      input: {
        propertyId: $propertyId
        paddockIds: $paddockIds
      }
    ) {
      nodes{
        groupId
        paddockIds
      }
    } 
  }
`;

export const ungroup =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: PaddockGroup }): Promise<PaddockGroup> => {
    const variables = {
      propertyId: entity.propertyId,
      paddockIds: entity.paddockIds,
    };

    const response = await prelude.graphql({
      query: UNGROUP_PADDOCK_GROUP,
      variables,
    });

    const entities = decodePaddockGroups(entity.propertyId)(
      response.data.ungroupPaddocks.nodes
    )[0];

    return entities;
  };

// Update
export const update =
  (prelude: ApiPrelude) =>
  async ({
    updatedEntity,
    originalEntity,
  }: {
    updatedEntity: PaddockGroup;
    originalEntity?: PaddockGroup;
  }): Promise<PaddockGroup> => {
    const addPaddocks = updatedEntity.paddockIds.filter(
      (paddockId) =>
        !originalEntity?.paddockIds.find((pId) => pId === paddockId)
    );
    const removedPaddocks =
      originalEntity?.paddockIds.filter(
        (pId) => !updatedEntity.paddockIds.includes(pId)
      ) || [];

    const addInput = addPaddocks.length
      ? {
          entity: {
            propertyId: updatedEntity.propertyId,
            paddockIds: updatedEntity.paddockIds,
          },
        }
      : null;
    const removeInput = removedPaddocks.length
      ? {
          entity: {
            ...updatedEntity,
            propertyId: updatedEntity.propertyId,
            paddockIds: removedPaddocks,
          },
        }
      : null;

    const createPaddockGroup = addInput
      ? await create(prelude)(addInput)
      : null;

    const removePaddockGroup = removeInput
      ? await ungroup(prelude)(removeInput)
      : null;

    return (removePaddockGroup || createPaddockGroup) as PaddockGroup;
  };

const DELETE_PADDOCK_GROUPS = `
  mutation DeletePaddockGroup($propertyId: ID!, $id: ID!) {
    deletePaddockGroup(
      input: {
        propertyId: $propertyId
        id: $id
      } 
    ) {
      __typename
    }
  }
`;

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: PaddockGroup }) => {
    await prelude.graphql({
      query: DELETE_PADDOCK_GROUPS,
      variables: {
        propertyId: entity.propertyId,
        id: entity.id,
      },
    });

    return;
  };
