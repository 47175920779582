import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { useMap } from '../React/Context';
import { safeAddSource, safeRemoveSource } from '../helpers';

export type RasterOptions = mapboxgl.RasterSource;

export interface RasterProps {
  id: string;
  options: Omit<RasterOptions, 'id' | 'type'>;
}

export const Raster: React.FC<RasterProps> = (props) => {
  const map = useMap();

  useEffect(() => {
    const cleanUp = () => {
      safeRemoveSource(map)(props.id);
    };

    safeAddSource(map)(props.id, {
      type: 'raster',
      ...props.options,
    });

    return () => {
      cleanUp();
    };
  }, [JSON.stringify(props)]);

  return null;
};
