import React from 'react';
import { type RainGaugeReading } from '@mobble/models/src/model/RainGaugeReading';
import { type User, findUser } from '@mobble/models/src/model/User';
import { Color } from '@mobble/colors';
import { Text } from '../../Components/UI/Text';
import { TimelineEvent } from '../../Components/UI/TimelineEvent';
import { Spacer } from '../../Components/Layout/Spacer';
import { HStack } from '../../Components/Layout/Stack';
import { LocaleQuantity } from '../../Components/Locale/LocaleQuantity';
import { Avatar } from '../../Components/UI/Avatar';
import { Clickable } from '@src/stories/Components/UX/Clickable';

interface RainGaugeReadingCardProps {
  rainGaugeReading: RainGaugeReading;
  users: User[];
  last?: boolean;
  onClick: (historicEvent: RainGaugeReading) => void;
}

export const RainGaugeReadingCard: React.FC<RainGaugeReadingCardProps> = ({
  users,
  rainGaugeReading,
  onClick,
  last,
}) => {
  const user = findUser(users)(String(rainGaugeReading.createdByID));
  return (
    <Clickable
      href={() => {
        onClick(rainGaugeReading);
      }}
      fullWidth
    >
      <TimelineEvent
        title="Rain Reading"
        date={String(rainGaugeReading.date)}
        last={last}
        color={Color.Blue}
      >
        <div style={{ width: '100%', flex: 1 }}>
          <LocaleQuantity quantity={rainGaugeReading.value} />
          <Spacer y={1} />
          {user && (
            <HStack alignment="center">
              <Avatar size="tiny" name={user.name} />
              <Spacer x={0.5} />
              <Text color={Color.Grey} variant="tiny">
                {user.name}
              </Text>
            </HStack>
          )}
        </div>
      </TimelineEvent>
    </Clickable>
  );
};
