import React from 'react';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '../config/types';
import { findPaddock } from '@mobble/models/src/model/Paddock';
import { useLinking } from '@src/hooks/useLinking';
import { ImportAgWorldPaddocksForm } from '@src/stories/Views/Callback/ImportAgWorldPaddocks';
import { useGetAgWorldPaddocks } from './hooks/useGetAgworldPaddocks';
import { useBulkImportPaddocks } from './hooks/useBulkImportPaddocks';

export const ImportAgWorldPaddocks: React.FC<ScreenRendererProps> = () => {
  const [selectedPaddockIds, setSelectedPaddockIds] = React.useState<string[]>(
    []
  );
  const [paddockImportSuccess, setPaddockImportSuccess] =
    React.useState<boolean>(false);
  const { propertyPaddocks } = useGetAgWorldPaddocks();
  const { isSubmitting, loadingProgress, addPaddocks, paddockImportErrors } =
    useBulkImportPaddocks();
  const linkTo = useLinking();

  const allAgWorldPaddocks = propertyPaddocks
    .map((paddock) => paddock.paddocks.map((paddock) => paddock))
    .flat(1);

  const isSubmittable =
    selectedPaddockIds.length > 0 && !isSubmitting && !paddockImportSuccess;

  React.useEffect(() => {
    const paddockIds = propertyPaddocks
      .map((paddock) => paddock.paddocks.map((paddock) => paddock.id))
      .flat(1);
    setSelectedPaddockIds(paddockIds);
  }, [propertyPaddocks]);

  const getPaddockFromId = findPaddock(allAgWorldPaddocks);
  const findPropertyByName = (propertyName) =>
    propertyPaddocks.find((property) => property.name === propertyName);

  const isPaddockSelected = (paddockId: string) =>
    selectedPaddockIds.includes(paddockId);

  const selectPaddock = (paddockId: string) => {
    if (selectedPaddockIds.includes(paddockId)) {
      setSelectedPaddockIds(
        selectedPaddockIds.filter((id) => id !== paddockId)
      );
    } else {
      setSelectedPaddockIds([...selectedPaddockIds, paddockId]);
    }
  };

  const areAllPaddocksOfPropertySelected = (propertyName: string) => {
    const paddocksOfProperty = findPropertyByName(propertyName)?.paddocks;

    if (!paddocksOfProperty) {
      return false;
    }
    return paddocksOfProperty.every((paddock) =>
      selectedPaddockIds.includes(paddock.id)
    );
  };

  const selectAllPaddocksOfProperty = (propertyName: string) => {
    const paddocksOfProperty = findPropertyByName(propertyName)?.paddocks;

    if (!paddocksOfProperty) {
      return false;
    }
    if (areAllPaddocksOfPropertySelected(propertyName)) {
      setSelectedPaddockIds(
        selectedPaddockIds.filter(
          (paddockId) =>
            !paddocksOfProperty.find((paddock) => paddock.id === paddockId)
        )
      );
    } else {
      setSelectedPaddockIds([
        ...selectedPaddockIds,
        ...paddocksOfProperty.map((paddock) => paddock.id),
      ]);
    }
  };

  const onSubmit = async () => {
    if (!isSubmittable) return;

    const agWorldPaddocks = selectedPaddockIds.map(getPaddockFromId);

    addPaddocks(agWorldPaddocks).then(() => {
      setPaddockImportSuccess(true);
    });
  };

  const onClickContinue = () => {
    linkTo(ROUTE_NAME.PADDOCKS_LIST);
  };

  return (
    <ImportAgWorldPaddocksForm
      propertyPaddocks={propertyPaddocks}
      isSubmittable={isSubmittable}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      paddockImportSuccess={paddockImportSuccess}
      paddockImportErrors={paddockImportErrors}
      loadingProgress={Number(loadingProgress)}
      onClickContinue={onClickContinue}
      selectAllPaddocksOfProperty={selectAllPaddocksOfProperty}
      areAllPaddocksOfPropertySelected={areAllPaddocksOfPropertySelected}
      selectPaddock={selectPaddock}
      isPaddockSelected={isPaddockSelected}
    />
  );
};

export default {
  name: ROUTE_NAME.IMPORT_AGWORLD_PADDOCKS,
  component: ImportAgWorldPaddocks,
};
