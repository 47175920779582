import React, {
  forwardRef,
  InputHTMLAttributes,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames/bind';

import { useI18n } from '@mobble/i18n';

import Button from '../Button';
import Icon, { IconName } from '../Icon';

import styles from './Input.scss';

const cx = classNames.bind(styles);

export type InputSize = 'small' | 'medium';

export type InputTypes =
  | 'text'
  | 'textarea'
  | 'email'
  | 'number'
  | 'float'
  | 'password'
  | 'date';

type PickedInputAttributes = Partial<
  Pick<
    InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    | 'autoComplete'
    | 'autoFocus'
    | 'disabled'
    | 'inputMode'
    | 'name'
    | 'min'
    | 'max'
    | 'maxLength'
    | 'pattern'
    | 'placeholder'
    | 'step'
    | 'pattern'
    | 'className'
    | 'onKeyUp'
    | 'onKeyDown'
    | 'style'
    | 'value'
  >
>;

export interface InputProps extends PickedInputAttributes {
  /**
   * Used to bind input and label
   */
  id: string;

  /**
   * Type of the input, defaults to `text`
   */
  type?: InputTypes;

  /**
   * Value of the input
   */
  value?: string | number | null;

  /**
   * Icon to display before the input
   */
  icon?: IconName;

  /**
   * Display a clear button that clears the input value
   */
  showClear?: boolean;

  /**
   * Size of the checkbox, defaults to `medium`
   */
  size?: InputSize;

  /**
   * Render a custom element after the input
   */
  children?: React.ReactNode;

  /**
   * Returns the value as a string
   */
  onChange: (value: string) => void;

  /**
   * Returns `validationMessage` when `validity.valid` is `false
   */
  onInvalid?: (message: string) => void;
}

const typeToInputType = (type: InputTypes, isSecure?: boolean) => {
  switch (type) {
    case 'email':
      return 'email';
    case 'password':
      return isSecure ? 'password' : 'text';
    case 'date':
      return 'date';
    default:
      return 'text';
  }
};

const typeToInputMode = (type: InputTypes) => {
  switch (type) {
    case 'email':
      return 'email';
    case 'number':
      return 'numeric';
    case 'float':
      return 'decimal';
    default:
      return 'text';
  }
};

const typeToInputPattern = (type: InputTypes) => {
  switch (type) {
    case 'email':
      return '.+@.+';
    case 'number':
      return '[0-9]*';
    case 'float':
      return '[0-9]*.[0-9]*';
    default:
      return undefined;
  }
};

/**
 * Input components renders a text input field, additional props are added to the input element.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      value,
      icon,
      showClear,
      children,
      size = 'medium',
      onChange,
      onInvalid,
      className,
      style,
      ...others
    },
    forwardRef
  ) => {
    const { formatMessage } = useI18n();
    const ref = useRef<HTMLInputElement>();
    useImperativeHandle(forwardRef, () => ref.current);

    const [isSecure, setIsSecure] = useState(type === 'password');
    const inputType = typeToInputType(type, isSecure);
    const inputMode = typeToInputMode(type);
    const inputPattern = typeToInputPattern(type);

    const normalisedValue =
      typeof value === 'number' ? String(value) : value || '';

    const rootClasses = cx(
      {
        Input: true,
        [size]: true,
        [type]: true,
      },
      className
    );

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      onChange(event.target.value);

      if (!event.target.validity.valid && typeof onInvalid === 'function') {
        onInvalid(event.target.validationMessage);
      }
    };

    const icons = [
      showClear
        ? {
            label: formatMessage({
              defaultMessage: 'Clear',
              description: 'Input clear button label',
            }),
            name: 'close',
            onClick: () => {
              onChange('');
            },
            hidden: !normalisedValue || normalisedValue.trim().length === 0,
          }
        : null,
      type === 'password'
        ? {
            label: formatMessage({
              defaultMessage: 'Toggle password visibility',
              description: 'Input password reveal button label',
            }),
            name: isSecure ? 'eye' : 'eye-off',
            onClick: () => {
              setIsSecure(!isSecure);
            },
          }
        : null,
      icon ? { name: icon } : null,
    ]
      .filter(Boolean)
      .map((a, index) => {
        const iconEl = (
          <Icon
            key={`icon-${index}`}
            name={a.name as IconName}
            size="small"
            style={{
              opacity: a.hidden ? 0 : 1,
            }}
          />
        );

        if (a.onClick) {
          return (
            <Button
              key={`button-${index}`}
              aria-label={a.label}
              aria-hidden={a.hidden}
              type="button"
              size="small"
              intent="neutral"
              tabIndex={a.hidden ? -1 : 0}
              onClick={a.onClick}
              style={{
                opacity: a.hidden ? 0 : 1,
              }}
            >
              {iconEl}
            </Button>
          );
        }

        return iconEl;
      });

    return (
      <div tabIndex={-1} className={rootClasses} style={style}>
        {type === 'textarea' ? (
          <textarea value={value} onChange={handleChange} {...others} />
        ) : (
          <input
            type={inputType}
            inputMode={inputMode}
            pattern={inputPattern}
            value={normalisedValue}
            onChange={handleChange}
            {...others}
          />
        )}

        {children}

        {icons?.length > 0 && <div className={styles.icons}>{icons}</div>}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
