import { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';

import Button, { ButtonProps } from '../../Button';

import styles from './Footer.scss';
const cx = classNames.bind(styles);

type PickedProps = 'children' | 'className' | 'style';

export interface DialogFooterProps
  extends Pick<ComponentPropsWithoutRef<'footer'>, PickedProps> {
  closeLabel?: string;
  submitLabel?: string;
  submitIntent?: ButtonProps['intent'];
  isLoading?: boolean;
  showBorder?: boolean;
  onCloseClick?: ButtonProps['onClick'];
  onSubmitClick?: ButtonProps['onClick'];
}

/**
 * DialogFooter displays close and submit buttons
 * To be used in a Dialog component
 */
const DialogFooter = ({
  closeLabel,
  submitLabel,
  submitIntent = 'primary',
  isLoading,
  showBorder,
  children,
  onCloseClick,
  onSubmitClick,
  className,
  ...others
}: DialogFooterProps) => {
  const rootClasses = cx(
    {
      Footer: true,
      border: showBorder,
    },
    className
  );

  return (
    <footer className={rootClasses} {...others}>
      {children}

      {closeLabel && typeof onCloseClick === 'function' && (
        <Button intent="secondary" outline onClick={onCloseClick}>
          {closeLabel}
        </Button>
      )}

      {submitLabel && typeof onSubmitClick === 'function' && (
        <Button
          intent={submitIntent}
          loading={isLoading}
          onClick={onSubmitClick}
        >
          {submitLabel}
        </Button>
      )}
    </footer>
  );
};

export default DialogFooter;
