import {
  makeQuantityOfMassVolume,
  type QuantityOfMassVolume,
} from '@mobble/shared/src/core/Quantity';
import { makeInventoryId, InventoryCategory } from './Inventory';
import {
  type InventoryItem,
  type InventoryItemStatus,
  type InventoryItemFeed,
  type InventoryItemChemical,
} from './InventoryItem';

interface BaseAppliedInventoryItem {
  id: string;
  category: InventoryCategory;
  status?: InventoryItemStatus;
  batchId: string;
  inventoryItemId: InventoryItem['id'];
  quantity_total?: QuantityOfMassVolume;
  quantity: QuantityOfMassVolume;
}

export interface AppliedInventoryItemFeed extends BaseAppliedInventoryItem {
  category: InventoryCategory.Feed;
  supplierName: string;
  inventoryItemId: InventoryItemFeed['id'];
  feed?: Partial<InventoryItemFeed>;
}

export interface AppliedInventoryItemChemical extends BaseAppliedInventoryItem {
  category: InventoryCategory.Chemicals;
  name: string;
  oldBatchId?: string;
  inventoryItemId: InventoryItemChemical['id'];
  whp: number;
  esi: number;
  chemical?: Partial<InventoryItemChemical>;
}

export type AppliedInventoryItem =
  | AppliedInventoryItemFeed
  | AppliedInventoryItemChemical;

export const makeAppliedInventoryItem = ({
  inventoryItem,
  batchId,
  supplierName,
  quantity,
  whp,
  esi,
}: {
  inventoryItem: InventoryItem;
  batchId?: string;
  supplierName?: string;
  quantity?: QuantityOfMassVolume;
  whp?: number;
  esi?: number;
}): AppliedInventoryItem => {
  const base = {
    id: `tmp-${inventoryItem.id}-${Math.random()}`,
    category: inventoryItem.category,
    inventoryItemId: inventoryItem.id,
    batchId,
    quantity:
      quantity ??
      makeQuantityOfMassVolume(inventoryItem.quantity.unit ?? 'kg', 0),
  };

  if (inventoryItem.category === InventoryCategory.Feed) {
    return {
      ...base,
      supplierName: supplierName ?? '',
    } as AppliedInventoryItemFeed;
  }

  return {
    ...base,
    whp: whp ?? inventoryItem.whp,
    esi: esi ?? inventoryItem.esi,
  } as AppliedInventoryItemChemical;
};

export const decodeAppliedInventoryItemChemicals = (
  raw: any[]
): AppliedInventoryItemChemical[] =>
  raw.map(decodeAppliedInventoryItemChemical);

export const decodeAppliedInventoryItemChemical = (
  raw: any
): AppliedInventoryItemChemical => ({
  id: raw?.id ?? '',
  category: InventoryCategory.Chemicals,
  name: raw?.chemical?.productName ?? '',
  batchId: raw?.batch?.id ?? '',
  oldBatchId: raw?.chemical?.batchId ?? '',
  inventoryItemId: raw?.chemical?.id ?? '',
  quantity: decodeQuantityOfMassVolume(raw?.quantity),
  whp: raw?.whp ?? 0,
  esi: raw?.esi ?? 0,

  chemical: {
    id: raw?.chemical?.id,
    inventoryId: makeInventoryId({
      propertyId: raw?.chemical?.propertyId,
      type: raw?.chemical?.category,
    }),
    name: raw?.chemical?.productName,
    category: raw?.chemical?.category,
    status: raw?.chemical?.status ?? '',
    lastUsed: raw?.chemical?.lastUsed,
    quantity: makeQuantityOfMassVolume(
      raw?.chemical?.quantity?.unit,
      raw?.chemical?.quantity?.value
    ),
  },
});

export const decodeAppliedInventoryItemFeeds = (
  raw: any[]
): AppliedInventoryItemFeed[] => raw.map(decodeAppliedInventoryItemFeed);

export const decodeAppliedInventoryItemFeed = (
  raw: any
): AppliedInventoryItemFeed => ({
  id: raw?.id ?? '',
  category: InventoryCategory.Feed,
  status: raw?.feed?.status ?? '',
  batchId: raw?.batch?.id ?? '',
  supplierName: raw?.feed?.supplierName ?? '',
  inventoryItemId: raw?.feed?.id ?? '',
  quantity: decodeQuantityOfMassVolume(raw?.quantity),

  feed: {
    id: raw?.feed?.id,
    inventoryId: makeInventoryId({
      propertyId: raw?.feed?.propertyId,
      type: 'feed',
    }),
    name: raw?.feed?.name,
    status: raw?.feed?.status ?? '',
    lastUsed: raw?.feed?.lastUsed,
    quantity: makeQuantityOfMassVolume(
      raw?.feed?.amountReceived?.unit,
      raw?.feed?.amountReceived?.value
    ),
  },
});

export const decodeQuantityOfMassVolume = (raw: any): QuantityOfMassVolume =>
  makeQuantityOfMassVolume(raw?.unit ?? 'kg', raw?.value ?? 0);
