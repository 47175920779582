import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  checkUserCanEditRole,
  UserRole,
  filterUsersOfUserRoles,
  userRoles,
} from '@mobble/models/src/model/User';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import { Box } from '@src/stories/Components/Layout/Box';
import {
  SettingsPropertyInviteUserForm,
  SettingsPropertyInviteUserFormValues,
} from '@src/stories/Views/Settings/SettingsPropertyInviteUserForm';
import { SettingsPropertyUsersInviteLimitReached } from '@src/stories/Views/Settings/SettingsPropertyUserInviteLimitReached';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';

export const SettingsPropertyUsersInviteUserHeader: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.settings_property_users_invite_user',
        defaultMessage: 'Invite user',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.settings_property_users',
            defaultMessage: 'Users',
          }),
          href: ROUTE_NAME.SETTINGS_PROPERTY_USERS,
        },
      ]}
      onClose={() => {
        linkTo(ROUTE_NAME.SETTINGS_PROPERTY_USERS);
      }}
    />
  );
};

export const SettingsPropertyUsersInviteUser: React.FC<
  ScreenRendererProps
> = () => {
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const linkTo = useLinking();
  const { formatMessage } = useI18n();
  const [loading, setLoading] = React.useState(false);
  const { role } = useAccessHelper();

  const { refresh } = useEntitiesRefresher([properties], propertyId);

  React.useEffect(() => {
    refresh();
  }, []);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected, properties.entities, role],
  });

  if (Prelude) {
    return Prelude;
  }

  const property = properties.selected;
  const { users, organisation } = property;
  const propertyUsers = filterUsersOfUserRoles(users);

  const totalUsers =
    organisation?.maxUsers - organisation?.remainingUsers ??
    propertyUsers.length;
  const maxUsers = organisation?.maxUsers ?? 100;

  const checkMaxUsersHaveBeenReached =
    property.organisation?.maxUsers <= propertyUsers.length;

  const initialValues = {
    email: '',
    role: UserRole.User,
    propertyIds: [property.id],
  };

  const roleOptions = userRoles
    .filter((a) => checkUserCanEditRole(role)(a))
    .map((value) => ({
      value,
      label:
        formatMessage({ id: `user_role.${value}`, defaultMessage: [] }) ??
        value,
    }));

  const propertiesOfOrganisation = properties.entities.filter(
    (property) =>
      property.organisationId === properties.selected?.organisationId
  );
  const propertiesWithoutMaxUsersReached = propertiesOfOrganisation.filter(
    (property) => property.organisation?.maxUsers > propertyUsers.length
  );
  const propertyOptions = propertiesWithoutMaxUsersReached.map((property) => ({
    value: property.id,
    label: property.name,
  }));

  const handleCancel = () => {
    linkTo(ROUTE_NAME.SETTINGS_PROPERTY_USERS);
  };

  const handleSubmit = (formValues: SettingsPropertyInviteUserFormValues) => {
    setLoading(true);
    properties
      .addUser({
        propertyIds: formValues.propertyIds,
        organisationId: property.organisationId,
        email: formValues.email,
        role: formValues.role,
      })
      .then(() => {
        setLoading(false);
        linkTo(ROUTE_NAME.SETTINGS_PROPERTY_USERS);
      });
  };

  if (checkMaxUsersHaveBeenReached) {
    const i18nUserCountCurrentlyInvited = formatMessage(
      {
        description: 'settings.property.users.invite_user.user_limit_reached',
        defaultMessage:
          'There are currently <b>{totalUsers}</b> users registered on this organisation, your subscription allows for <b>{maxUsers}</b>.',
      },
      {
        totalUsers,
        maxUsers,
      }
    );

    return (
      <SettingsPropertyUsersInviteLimitReached
        i18nUserCountCurrentlyInvited={i18nUserCountCurrentlyInvited}
        goBack={handleCancel}
      />
    );
  }

  return (
    <Box>
      <SettingsPropertyInviteUserForm
        loading={loading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        roleOptions={roleOptions}
        propertyOptions={propertyOptions}
        initialValues={initialValues}
      />
    </Box>
  );
};

export default {
  name: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_USERS_INVITE_USER,
  header: SettingsPropertyUsersInviteUserHeader,
  component: SettingsPropertyUsersInviteUser,
};
