import Fuse from 'fuse.js';
import { FilterItem, filterMatches, groupFilter } from './Filter';
import { Mob } from './Mob';
import { Paddock } from './Paddock';
import { Property } from './Property';

export interface NaturalIncrease {
  id: string;
  propertyId: Property['id'];
  status: 'active' | 'deleted';
  date: any;
  notes: string | null;
  paddock: {
    name: string;
    id: Paddock['id'];
  };
  mob: Mob;
}

export const filterNaturalIncreases = (
  naturalIncrease: NaturalIncrease[],
  filter?: FilterItem[]
): NaturalIncrease[] => {
  if (!filter || filter.length === 0) {
    return naturalIncrease;
  }
  const grouped = [...groupFilter(filter)];

  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredNaturalIncrease =
    searchQuery && searchQuery.type === 'search'
      ? searchNaturalIncreases(naturalIncrease, searchQuery.value)
      : naturalIncrease;

  return preFilteredNaturalIncrease.filter((ni) =>
    grouped.every(([_, filters]) =>
      filters.some(filterItemMatchesNaturalIncreases(ni))
    )
  );
};

export const searchNaturalIncreases = (
  naturalIncreases: NaturalIncrease[],
  searchQuery: string
): NaturalIncrease[] => {
  const fuse = new Fuse(naturalIncreases, {
    keys: [
      { name: 'mob.breed', weight: 5 },
      { name: 'mob.gender', weight: 3 },
      { name: 'mob.number', weight: 3 },
    ],
    threshold: 0.5,
    shouldSort: true,
  });

  return fuse.search(searchQuery).map((a) => a.item);
};

export const filterItemMatchesNaturalIncreases =
  (naturalIncrease: NaturalIncrease) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'breed':
        return matches(naturalIncrease.mob.breed);
      case 'gender':
        return matches(naturalIncrease.mob.gender);
      case 'number':
        return matches(naturalIncrease.mob.size);
      case 'dateRange':
        return matches(naturalIncrease.date);
      case 'search':
        return true;
      default:
        return true;
    }
  };
