import React from 'react';
import * as Yup from 'yup';
import { useI18n } from '@mobble/i18n';
import {
  convertTo,
  type QuantityOfArea,
} from '@mobble/shared/src/core/Quantity';
import { type Paddock } from '@mobble/models/src/model/Paddock';

import { ColorSwatch } from '@src/components';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import {
  Point,
  pointsToFeaturePolygon,
  polygonToArea,
} from '@mobble/models/src/model/MapGeometry';
import { useSettings } from '@mobble/store/src/hooks/settings';

export interface PaddockCreateFormProps {
  paddockTypes: { value: string; color: string }[];
  error?: string;
  loading?: boolean;
  initialValues?: Partial<PaddockCreateFormValues>;
  onCancel: () => void;
  onSubmit: (formValues: PaddockCreateFormValues) => void;
  onCreateCustomField: (label: string) => Promise<void>;
}

export interface PaddockCreateFormValues {
  name: string;
  paddock_type: Paddock['type'];
  points?: Point[];
  grazeable_area: QuantityOfArea;
}

export const PaddockCreateForm: React.FC<PaddockCreateFormProps> = ({
  paddockTypes,
  error,
  loading,
  initialValues,
  onCancel,
  onSubmit,
  onCreateCustomField,
}) => {
  const { translate, formatMessage } = useI18n();
  const settings = useSettings();
  const paddockTypeOptions = paddockTypes.map(({ value, color }) => ({
    label: value,
    value,
    labelExtra: <ColorSwatch color={color} />,
  }));
  const handleCreateCustomField = (value: string): Promise<void> =>
    onCreateCustomField(value);

  const form: FormBuilderProps<PaddockCreateFormValues> = {
    flex: true,
    i18nRootKey: 'paddocks.paddock.create.form',
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
        initialValue: initialValues?.name,
      },
      {
        name: 'paddock_type',
        type: 'select',
        options: paddockTypeOptions,
        required: true,
        initialValue: initialValues?.paddock_type,
        addNew: {
          onSubmit: handleCreateCustomField,
          placeholder: formatMessage({
            defaultMessage: 'Add new paddock type',
            description:
              'paddocks.paddock.create.form.paddock_form.paddockTypes.add_new.placeholder.label',
          }),
        },
      },
      {
        name: 'points',
        type: 'map-creator',
        required: false,
        initialValue: initialValues?.points,
        mapCreator: {
          options: {
            single: false,
            mode: 'area',
          },
        },
        onChange: (values) => {
          const polygon = values.points
            ? pointsToFeaturePolygon(values.points)
            : null;

          if (!polygon) {
            return values;
          }

          const total_area = convertTo(
            settings.settings.areaUnit,
            2
          )(polygonToArea(polygon.geometry)) as QuantityOfArea;

          return {
            ...values,
            total_area,
            // Update grazeble area if not set
            grazeable_area: values.grazeable_area || total_area,
          };
        },
      },
      {
        name: 'total_area',
        type: 'quantity-area',
        disabled: () => true,
        initialValue: initialValues.grazeable_area,
      },
      {
        name: 'grazeable_area',
        type: 'quantity-area',
        initialValue: initialValues.grazeable_area,
        show: (values: PaddockCreateFormValues) =>
          values.paddock_type !== 'Yard',
        validation: Yup.object({
          value: Yup.number(),
          unit: Yup.string(),
        }).when('paddock_type', {
          is: 'Yard',
          then: (schema) => schema.optional(),
          otherwise: (schema) =>
            schema.required(
              translate({
                key: 'generic.form.validation.required',
                params: {
                  FIELD: translate({
                    key: 'paddocks.paddock.edit.form.grazeable_area.label',
                  }),
                },
              })
            ),
        }),
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
