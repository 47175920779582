import { useEffect } from 'react';
import { useMap } from './lib/React/Context';
import {
  safeAddLayer,
  safeAddSource,
  safeRemoveLayer,
  safeRemoveSource,
} from './lib/helpers';

export interface PolygonProps {
  id: string;
  data: GeoJSON.Polygon;

  linePaint?: mapboxgl.LinePaint;
  lineLayout?: mapboxgl.LineLayout;
  fillPaint?: mapboxgl.FillPaint;

  onClick?: (
    id: string,
    ev: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[];
    } & mapboxgl.EventData
  ) => void;
}

export const Polygon = (props: PolygonProps) => {
  const id = (ns: string) => `polygon-${ns}-${props.id}`;
  const map = useMap();

  const handleClick = (ev: mapboxgl.MapMouseEvent) => {
    if (props.onClick) {
      props.onClick(props.id, ev);
    }
  };

  const handleMouseEnter = () => {
    if (props.onClick) {
      map.getCanvas().style.cursor = 'pointer';
    }
  };

  const handleMouseLeave = () => {
    if (props.onClick) {
      map.getCanvas().style.cursor = '';
    }
  };

  const cleanUp = () => {
    safeRemoveLayer(map)(id('fill'));
    safeRemoveLayer(map)(id('line'));
    safeRemoveSource(map)(id('source'));

    if (props.fillPaint) {
      map.off('click', id('fill'), handleClick);
      map.off('mouseenter', id('fill'), handleMouseEnter);
      map.off('mouseleave', id('fill'), handleMouseLeave);
    }

    if (props.linePaint) {
      map.off('click', id('line'), handleClick);
    }
  };

  useEffect(() => {
    safeAddSource(map)(id('source'), {
      type: 'geojson',
      data: {
        type: 'Polygon',
        coordinates: props.data.coordinates,
      },
    });

    if (props.fillPaint) {
      safeAddLayer(map)({
        type: 'fill',
        id: id('fill'),
        source: id('source'),
        paint: props.fillPaint,
      });

      map.on('click', id('fill'), handleClick);
      map.on('mouseenter', id('fill'), handleMouseEnter);
      map.on('mouseleave', id('fill'), handleMouseLeave);
    }

    if (props.linePaint) {
      safeAddLayer(map)({
        type: 'line',
        id: id('line'),
        source: id('source'),
        paint: props.linePaint,
      });

      map.on('click', id('line'), handleClick);
    }

    return () => {
      cleanUp();
    };
  }, []);

  return null;
};
