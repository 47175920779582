import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  type BoundingBox,
  defaultBoundingBox,
} from '@mobble/models/src/model/MapGeometry';
import { CustomMapLayer, FilterItem } from '@mobble/models';
import { MapDetails } from '@mobble/models/src/model/MapDetail';
import { MapStyle } from '@mobble/models/src/model/MapStyle';
import { type Task } from '@mobble/models/src/model/Task';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { type MapAsset } from '@mobble/models/src/model/MapAsset';
import { filterMobs, type Mob } from '@mobble/models/src/model/Mob';
import {
  useSettings,
  usePaddockGroups,
  useProperties,
  usePaddocks,
  usePaddockGeometries,
  usePaddockGroupGeometries,
  useMobs,
  useTaskGeometries,
  useTasks,
  useMapAssets,
  useCustomMapLayers,
} from '@mobble/store/src/hooks';

import {
  makeMapItemCustomFeature,
  makeMapItemMapAsset,
  makeMapItemMobs,
  makeMapItemPaddockBoundary,
  makeMapItemPaddockGroup,
  makeMapItemPaddockLabel,
  makeMapItemTask,
  mapItemsToBoundaryBox,
} from '../stories/Map/Items/helper';
import {
  mapItemIsIncludedInMapDetails,
  type MapItem,
} from '../stories/Map/Items/MapItemType';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { filterCustomMapLayers } from '@mobble/models/src/model/CustomMapLayers';

export type OnClickMapItemEvent =
  | {
      type: 'task';
      task: Task;
    }
  | {
      type: 'paddock';
      paddock: Paddock;
    }
  | {
      type: 'mobs';
      paddock: Paddock;
    }
  | {
      type: 'mob';
      mob: Mob;
      paddock: Paddock;
    }
  | {
      type: 'map-asset';
      mapAsset: MapAsset;
    }
  | {
      type: 'custom-map-layer';
      customMapLayer: CustomMapLayer;
    };

export interface UseMapPropertiesProps {
  onClickMapItem?: (ev: OnClickMapItemEvent) => void;
  override?: Partial<MapProperties>;
  filterMap?: (mapItem: MapItem) => false | MapItem;
}

export interface MapProperties {
  override?: Partial<MapProperties>;
  propertyId?: string;

  items: MapItem[];
  additionalMapItems: MapItem[];
  boundingBox: BoundingBox;
  mapStyle: MapStyle;
  mapDetails: MapDetails;
  mobsFilter: FilterItem[];
  customMapLayersFilter: FilterItem[];

  updateMapStyle: (mapStyle: MapStyle) => void;
  updateMapDetails: (mapDetails: MapDetails) => void;
  updateMobsFilter: (filter: FilterItem[]) => void;
  updateCustomMapLayersFilter: (filter: FilterItem[]) => void;
}

export const useMapProperties = ({
  onClickMapItem,
  override,
  filterMap,
}: UseMapPropertiesProps): MapProperties => {
  const properties = useProperties();
  const user = useGetUser();
  const { settings, update } = useSettings();
  const { translate } = useI18n();
  const propertyId = properties.selected?.id;
  const mobs = useMobs(propertyId);
  const tasks = useTasks(propertyId);
  const mapAssets = useMapAssets(propertyId);
  const taskGeometries = useTaskGeometries(propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const paddockGeometries = usePaddockGeometries(propertyId);
  const paddockGroupGeometries = usePaddockGroupGeometries(propertyId);
  const customMapLayers = useCustomMapLayers(propertyId);

  const factoryMapItemPaddockBoundary = makeMapItemPaddockBoundary({
    propertyTypes: properties.selected?.types ?? [],
    paddockGeometries: paddockGeometries.entities,
  });
  const factoryMapItemPaddockLabel = makeMapItemPaddockLabel({
    paddockGeometries: paddockGeometries.entities,
    translate,
  });

  const mobsFilter = override?.mobsFilter || settings.mapMobsFilter;
  const filteredMobs = filterMobs(paddocks.entities)(mobs.entities, mobsFilter);

  const customMapLayersFilter =
    override?.customMapLayersFilter || settings.mapCustomLayersFilter;
  const filteredCustomMapLayers = customMapLayersFilter?.length
    ? filterCustomMapLayers(customMapLayers.entities, customMapLayersFilter)
    : [];

  const factoryMapItemMobs = makeMapItemMobs({
    propertyTypes: properties.selected?.types ?? [],
    paddockGeometries: paddockGeometries.entities,
    mobs: filteredMobs,
  });

  const factoryMapItemCustomFeature = makeMapItemCustomFeature({
    customMapLayers: filteredCustomMapLayers,
  });

  const factoryMapItemPaddockGroup = makeMapItemPaddockGroup({
    paddockGroupGeometries: paddockGroupGeometries.entities,
  });
  const factoryMapItemTask = makeMapItemTask({
    userId: String(user?.id),
    taskGeometries: taskGeometries.entities,
  });
  const factoryMapItemMapAsset = makeMapItemMapAsset();

  const additionalMapItems = override?.additionalMapItems ?? [];
  const mapDetailsInUse = override?.mapDetails ?? settings.mapDetails;
  const items: MapItem[] = React.useMemo(() => {
    return (
      override?.items ?? [
        // MapItemPaddockBoundary, MapItemPaddockLabel,  MapItemMob
        ...paddocks.entities.reduce<MapItem[]>((acc, paddock) => {
          const onClick = (ev: OnClickMapItemEvent) =>
            onClickMapItem
              ? () => {
                  onClickMapItem(ev);
                }
              : undefined;

          return [
            ...acc,
            ...([
              factoryMapItemPaddockBoundary({
                paddock,
                onClick: onClick({ type: 'paddock', paddock }),
              }),
              factoryMapItemPaddockLabel({ paddock }),
              factoryMapItemMobs({
                paddock,
                onClick: onClick({ type: 'mobs', paddock }),
              }),
            ].filter(Boolean) as MapItem[]),
          ];
        }, []),

        // MapItemPaddockGroup
        ...paddockGroups.entities.reduce<MapItem[]>((acc, paddockGroup) => {
          return [
            ...acc,
            ...([factoryMapItemPaddockGroup({ paddockGroup })].filter(
              Boolean
            ) as MapItem[]),
          ];
        }, []),

        // MapItemTask
        ...tasks.entities.reduce<MapItem[]>((acc, task) => {
          const onClick = onClickMapItem
            ? () => {
                onClickMapItem({ type: 'task', task });
              }
            : undefined;

          return [
            ...acc,
            ...([factoryMapItemTask({ task, onClick })].filter(
              Boolean
            ) as MapItem[]),
          ];
        }, []),

        // MapItemMapAsset
        ...mapAssets.entities.reduce<MapItem[]>((acc, mapAsset) => {
          const onClick = onClickMapItem
            ? () => {
                onClickMapItem({ type: 'map-asset', mapAsset });
              }
            : undefined;

          return [
            ...acc,
            ...([factoryMapItemMapAsset({ mapAsset, onClick })].filter(
              Boolean
            ) as MapItem[]),
          ];
        }, []),
        // MapItemCustomFeature
        ...customMapLayers?.entities.reduce<MapItem[]>(
          (acc, customMapLayer) => {
            const onClick = onClickMapItem
              ? () => {
                  onClickMapItem({
                    type: 'custom-map-layer',
                    customMapLayer,
                  });
                }
              : undefined;

            return [
              ...acc,
              ...customMapLayer.features.reduce<MapItem[]>(
                (acc, customMapFeature) =>
                  [
                    ...acc,
                    factoryMapItemCustomFeature({
                      customMapFeature,
                      customMapLayer,
                      onClick,
                    }),
                  ].filter(Boolean),
                []
              ),
            ];
          },
          []
        ),
      ]
    );
  }, [
    override,
    paddocks.entities,
    paddockGroups.entities,
    mobs.entities,
    tasks.entities,
    mapAssets.entities,
    customMapLayers.entities,
  ]);

  const f = mapItemIsIncludedInMapDetails(mapDetailsInUse);

  const filteredItems = filterMap
    ? (items.map(filterMap).filter(Boolean) as MapItem[]).filter(f)
    : items.filter(f);

  const boundingBox =
    override?.boundingBox ??
    mapItemsToBoundaryBox(filteredItems) ??
    defaultBoundingBox;

  const result: MapProperties = {
    propertyId,
    items: filteredItems,
    additionalMapItems,
    boundingBox,
    mapStyle: override?.mapStyle ?? settings.mapStyle,
    mapDetails: mapDetailsInUse,
    mobsFilter: override?.mobsFilter || settings.mapMobsFilter,
    customMapLayersFilter:
      override?.customMapLayersFilter || settings.mapCustomLayersFilter,
    updateMapStyle: (updatedMapStyle: MapStyle) => {
      update({ mapStyle: updatedMapStyle });
    },
    updateMapDetails: (updatedMapDetails: MapDetails) => {
      update({ mapDetails: updatedMapDetails });
    },
    updateMobsFilter: (filter: FilterItem[]) => {
      update({ mapMobsFilter: filter });
    },
    updateCustomMapLayersFilter: (filter: FilterItem[]) => {
      update({ mapCustomLayersFilter: filter });
    },
    override,
  };

  return result;
};
