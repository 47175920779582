import React from 'react';
import { Color, colorToHex } from '@mobble/colors';
import { type I18nItem } from '@mobble/i18n';

import { reactIntersperse } from '@src/interfaces/React';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Text } from '@src/stories/Components/UI/Text';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { Icon, type IconName } from '@src/stories/Components/UI/Icon';

import styles from './toolbarButton.scss';

export interface ToolbarButtonProps {
  icon?: IconName;
  onClick: () => void;
  fill?: Color;
  color?: Color;
  loading?: boolean;
  active?: boolean;
  hint?: I18nItem | string;
  children?: React.ReactNode;
}

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  icon,
  onClick,
  fill,
  color,
  loading,
  active,
  hint,
  children,
}) => {
  const button = (
    <button
      className={styles.button}
      onClick={onClick}
      style={{ backgroundColor: fill ? colorToHex(fill) : null }}
    >
      {loading ? (
        <Spinner color={Color.Black} />
      ) : icon ? (
        <Icon color={color} name={icon} />
      ) : (
        children
      )}

      {active && <span className={styles.activeIndicator} />}
    </button>
  );

  return hint ? (
    <div className={styles.toolbarButtonWrapper}>
      {button}
      <div className={styles.tooltip}>
        <Text align="center" i18n={hint} />
      </div>
    </div>
  ) : (
    button
  );
};

export interface ToolbarButtonsProps {
  buttons: (ToolbarButtonProps | null)[];
}

export const ToolbarButtons: React.FC<ToolbarButtonsProps> = ({ buttons }) => {
  return (
    <>
      {reactIntersperse(
        <Spacer y={1} />,
        buttons
          .filter(Boolean)
          .map((button, index) => (
            <ToolbarButton key={index} {...(button as ToolbarButtonProps)} />
          ))
      )}
    </>
  );
};
