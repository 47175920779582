import React from 'react';
import { defineMessages } from 'react-intl';
import { useI18n, useMessages } from '@mobble/i18n';
import {
  ConfiguredPropertyTypeGroup,
  getConfiguredPropertyTypes,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';
import * as ROUTE_NAME from '../config/routeNames';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { useLinking } from '../../hooks/useLinking';
import { NavigationList } from '../../stories/Components/UX/NavigationList';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenRendererProps } from '../config/types';
import { toPath } from '@src/interfaces/Routing';
import { base64decode, base64encode } from '@mobble/shared/src/core/String';

const messages = defineMessages({
  breed: {
    description: 'screen.title.settings_property_classes_types_group.breed',
    defaultMessage: 'Breeds',
  },
  class: {
    description: 'screen.title.settings_property_classes_types_group.class',
    defaultMessage: 'Classes',
  },
  gender: {
    description: 'screen.title.settings_property_classes_types_group.gender',
    defaultMessage: 'Genders',
  },
  livestockType: {
    description:
      'screen.title.settings_property_classes_types_group.livestockType',
    defaultMessage: 'Livestock Types',
  },
  mobAction: {
    description: 'screen.title.settings_property_classes_types_group.mobAction',
    defaultMessage: 'Mob actions',
  },
  paddockAction: {
    description:
      'screen.title.settings_property_classes_types_group.paddockAction',
    defaultMessage: 'Paddock actions',
  },
  paddockType: {
    description:
      'screen.title.settings_property_classes_types_group.paddockType',
    defaultMessage: 'Paddock types',
  },
  tag: {
    description: 'screen.title.settings_property_classes_types_group.tag',
    defaultMessage: 'Tags',
  },

  'toc.actions_and_treatments': {
    description: 'settings.property.classes_types.toc.actions_and_treatments',
    defaultMessage: 'Actions & treatments',
  },
  'toc.breed': {
    description: 'settings.property.classes_types.toc.breed',
    defaultMessage: 'Breeds',
  },
  'toc.breeds': {
    description: 'settings.property.classes_types.toc.breeds',
    defaultMessage: 'Breeds',
  },
  'toc.cattle_breed': {
    description: 'settings.property.classes_types.toc.cattle_breed',
    defaultMessage: 'Cattle breeds',
  },
  'toc.cattle_breeds': {
    description: 'settings.property.classes_types.toc.cattle_breeds',
    defaultMessage: 'Cattle breeds',
  },
  'toc.cattle_class': {
    description: 'settings.property.classes_types.toc.cattle_class',
    defaultMessage: 'Cattle classes',
  },
  'toc.cattle_classes': {
    description: 'settings.property.classes_types.toc.cattle_classes',
    defaultMessage: 'Cattle classes',
  },
  'toc.cattle_gender': {
    description: 'settings.property.classes_types.toc.cattle_gender',
    defaultMessage: 'Cattle genders',
  },
  'toc.cattle_genders': {
    description: 'settings.property.classes_types.toc.cattle_genders',
    defaultMessage: 'Cattle genders',
  },
  'toc.cattle_tag': {
    description: 'settings.property.classes_types.toc.cattle_tag',
    defaultMessage: 'Cattle tags',
  },
  'toc.cattle_tags': {
    description: 'settings.property.classes_types.toc.cattle_tags',
    defaultMessage: 'Cattle tags',
  },
  'toc.class': {
    description: 'settings.property.classes_types.toc.class',
    defaultMessage: 'Classes',
  },
  'toc.classes': {
    description: 'settings.property.classes_types.toc.classes',
    defaultMessage: 'Classes',
  },
  'toc.gender': {
    description: 'settings.property.classes_types.toc.gender',
    defaultMessage: 'Genders',
  },
  'toc.genders': {
    description: 'settings.property.classes_types.toc.genders',
    defaultMessage: 'Genders',
  },
  'toc.goats_breed': {
    description: 'settings.property.classes_types.toc.goats_breed',
    defaultMessage: 'Goat breeds',
  },
  'toc.goats_breeds': {
    description: 'settings.property.classes_types.toc.goats_breeds',
    defaultMessage: 'Goat breeds',
  },
  'toc.goats_class': {
    description: 'settings.property.classes_types.toc.goats_class',
    defaultMessage: 'Goat classes',
  },
  'toc.goats_classes': {
    description: 'settings.property.classes_types.toc.goats_classes',
    defaultMessage: 'Goat classes',
  },
  'toc.goats_gender': {
    description: 'settings.property.classes_types.toc.goats_gender',
    defaultMessage: 'Goat genders',
  },
  'toc.goats_genders': {
    description: 'settings.property.classes_types.toc.goats_genders',
    defaultMessage: 'Goat genders',
  },
  'toc.goats_tag': {
    description: 'settings.property.classes_types.toc.goats_tag',
    defaultMessage: 'Goat tags',
  },
  'toc.goats_tags': {
    description: 'settings.property.classes_types.toc.goats_tags',
    defaultMessage: 'Goat tags',
  },
  'toc.livestock_types': {
    description: 'settings.property.classes_types.toc.livestock_types',
    defaultMessage: 'Livestock types',
  },
  'toc.mob_actions': {
    description: 'settings.property.classes_types.toc.mob_actions',
    defaultMessage: 'Mob actions',
  },
  'toc.other_breed': {
    description: 'settings.property.classes_types.toc.other_breed',
    defaultMessage: 'Other breeds',
  },
  'toc.other_breeds': {
    description: 'settings.property.classes_types.toc.other_breeds',
    defaultMessage: 'Other breeds',
  },
  'toc.other_class': {
    description: 'settings.property.classes_types.toc.other_class',
    defaultMessage: 'Other classes',
  },
  'toc.other_classes': {
    description: 'settings.property.classes_types.toc.other_classes',
    defaultMessage: 'Other classes',
  },
  'toc.other_gender': {
    description: 'settings.property.classes_types.toc.other_gender',
    defaultMessage: 'Other genders',
  },
  'toc.other_genders': {
    description: 'settings.property.classes_types.toc.other_genders',
    defaultMessage: 'Other genders',
  },
  'toc.other_tag': {
    description: 'settings.property.classes_types.toc.other_tag',
    defaultMessage: 'Other tags',
  },
  'toc.other_tags': {
    description: 'settings.property.classes_types.toc.other_tags',
    defaultMessage: 'Other tags',
  },
  'toc.paddock_actions': {
    description: 'settings.property.classes_types.toc.paddock_actions',
    defaultMessage: 'Paddock actions',
  },
  'toc.paddock_types': {
    description: 'settings.property.classes_types.toc.paddock_types',
    defaultMessage: 'Paddock types',
  },
  'toc.sheep_breed': {
    description: 'settings.property.classes_types.toc.sheep_breed',
    defaultMessage: 'Sheep breeds',
  },
  'toc.sheep_breeds': {
    description: 'settings.property.classes_types.toc.sheep_breeds',
    defaultMessage: 'Sheep breeds',
  },
  'toc.sheep_class': {
    description: 'settings.property.classes_types.toc.sheep_class',
    defaultMessage: 'Sheep classes',
  },
  'toc.sheep_classes': {
    description: 'settings.property.classes_types.toc.sheep_classes',
    defaultMessage: 'Sheep classes',
  },
  'toc.sheep_gender': {
    description: 'settings.property.classes_types.toc.sheep_gender',
    defaultMessage: 'Sheep genders',
  },
  'toc.sheep_genders': {
    description: 'settings.property.classes_types.toc.sheep_genders',
    defaultMessage: 'Sheep genders',
  },
  'toc.sheep_tag': {
    description: 'settings.property.classes_types.toc.sheep_tag',
    defaultMessage: 'Sheep tags',
  },
  'toc.sheep_tags': {
    description: 'settings.property.classes_types.toc.sheep_tags',
    defaultMessage: 'Sheep tags',
  },
  'toc.tag': {
    description: 'settings.property.classes_types.toc.tag',
    defaultMessage: 'Tags (tag colours)',
  },
  'toc.tags': {
    description: 'settings.property.classes_types.toc.tags',
    defaultMessage: 'Tags (tag colours)',
  },
  'toc.types': {
    description: 'settings.property.classes_types.toc.types',
    defaultMessage: 'Types',
  },
});

export const SettingsPropertyClassesTypesGroupHeader: React.FC<
  ScreenRendererProps
> = ({ route }) => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const strings = useMessages(messages);
  const { classTypes } = route.params as any;
  const { group, livestockType } = JSON.parse(base64decode(classTypes));

  const typeLowerCase = livestockType ? livestockType.toLowerCase() : null;

  const groupKey = typeLowerCase
    ? `toc.${typeLowerCase}_${group === 'class' ? 'classe' : group}s`
    : group;

  return (
    <ScreenHeader
      title={strings[groupKey]}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.settings_property_classes_types',
            defaultMessage: 'Classes, types & tags',
          }),
          href: ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES);
      }}
    />
  );
};

export const SettingsPropertyClassesTypesGroup: React.FC<
  ScreenRendererProps
> = ({ route }) => {
  const { classTypes } = route.params;
  const { group, livestockType } = JSON.parse(base64decode(classTypes));

  const noEditGroups = [ConfiguredPropertyTypeGroup.livestockType];

  const properties = useProperties();

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (prelude) {
    return prelude;
  }

  const property = properties.selected;
  const parentId = getLivestockParentIdForValue(livestockType, property.types);

  const items = getConfiguredPropertyTypes(property, group, parentId);

  if (!items.length) {
    return null;
  }

  if (noEditGroups.includes(group)) {
    return (
      <NavigationList
        items={items.map((type) => ({
          label: type.label,
        }))}
      />
    );
  }

  return (
    <NavigationList
      items={items.map((item) => ({
        label: item.label,
        href: toPath(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_EDIT, {
          classTypes: base64encode(
            JSON.stringify({
              group,
              livestockType,
              valueType: item.type,
            })
          ),
        }),
        color: item.color,
      }))}
    />
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_GROUP,
  header: SettingsPropertyClassesTypesGroupHeader,
  component: SettingsPropertyClassesTypesGroup,
};
