import React from 'react';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface SetUpDisplayNameFormProps {
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (formValues: SetUpDisplayNameFormValues) => void;
  onTouched?: (dirty: boolean) => void;
}

export interface SetUpDisplayNameFormValues {
  displayName: string;
}

export const SetUpDisplayNameForm: React.FC<SetUpDisplayNameFormProps> = ({
  loading,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const form: FormBuilderProps<any> = {
    flex: true,
    i18nRootKey: 'setup.displayName.form',
    fields: [
      {
        name: 'displayName',
        type: 'text',
        required: true,
      },
    ],
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
