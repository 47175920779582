import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/inventoryItems';

export const useInventoryItems = (inventoryId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.inventoryItems);

  return proxyUseEntities({
    parentId: inventoryId,
    dispatch,
    state,
  });
};

export const useInventoryItem = (
  inventoryItemId: string,
  inventoryId?: string
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.inventoryItems);

  return proxyUseEntity({
    entityId: inventoryItemId,
    parentId: inventoryId,
    dispatch,
    state,
  });
};
