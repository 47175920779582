import { NaturalIncrease } from '@mobble/models/src/model/NaturalIncrease';
import { Property } from '@mobble/models/src/model/Property';
import { sortNumberDescending } from '@mobble/shared/src/core/Number';
import { type ApiPrelude, type ExtCursor } from '../types';

const decodeNaturalIncreases =
  (propertyId: string) =>
  (raw: any[]): NaturalIncrease[] => {
    return raw.map(decodeNaturalIncrease(propertyId));
  };

const decodeNaturalIncrease =
  (propertyId: string) =>
  (raw: any): NaturalIncrease => {
    return {
      id: raw?.id ?? '',
      propertyId,
      status: raw?.status ?? '',
      date: raw?.date ?? '',
      notes: raw?.notes ?? '',
      paddock: {
        id: raw?.paddock?.id ?? '',
        name: raw?.paddock?.name ?? '',
      },
      mob: {
        id: raw?.mobId,
        propertyId,
        breed: raw?.breed ?? '',
        type: raw?.type ?? '',
        gender: raw?.gender ?? '',
        size: raw?.number ?? 0,
        DSE: raw?.DSE ?? 0,
        ages: (raw?.ages ?? []).sort(sortNumberDescending),
        classes: raw?.classes ?? [],
        safeDate: raw.safeDate ?? '',
      },
    };
  };

const GET_NATURAL_INCREASES = `
  query GetNaturalIncreases($propertyId: ID!) {
    naturalIncreases(propertyId: $propertyId) {
      totalCount
      nodes {
        id
        status
        date
        notes
        mobId
        number
        type
        DSE
        breed
        gender
        classes
        ages
        paddock {
          id
          name
        }
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    cursor,
  }: {
    parentId: string;
    cursor?: string;
  }): Promise<{ entities: NaturalIncrease[]; extCursor: ExtCursor }> => {
    const variables = {
      propertyId: parentId,
      after: cursor,
      pageSize: 9999,
    };

    const response = await prelude.graphql({
      query: GET_NATURAL_INCREASES,
      variables,
    });

    const entities = decodeNaturalIncreases(parentId)(
      response.data.naturalIncreases.nodes
    );

    const pageInfo = response?.data?.casualties?.pageInfo;
    const extCursor = {
      total: response?.data?.casualties?.totalCount ?? entities.length,
      nextCursor: pageInfo?.hasNextPage && pageInfo.endCursor,
    };

    return {
      entities,
      extCursor,
    };
  };

const EDIT_NATURAL_INCREASE = `
  mutation EditNaturalIncrease($input: EditNaturalIncreaseInput!) {
    editNaturalIncrease(input: $input) {
      naturalIncrease {
        id
        status
        date
        notes
        mobId
        number
        type
        DSE
        breed
        gender
        classes
        ages
        paddock {
          name
          id
        }
      }
    }
  }
`;

interface EditNaturalIncreaseActionInput {
  id: string;
  propertyId: string;
  naturalIncreaseDate: any;
  notes?: string | null;
  number: number;
  DSE: number;
  paddockId?: string | null;
  type: string;
  breed: string;
  gender: string;
  classes: string[];
  ages: number[];
}

export const update =
  (prelude: ApiPrelude) =>
  async ({
    updatedEntity,
  }: {
    updatedEntity: NaturalIncrease;
  }): Promise<NaturalIncrease> => {
    const input: EditNaturalIncreaseActionInput = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      naturalIncreaseDate: updatedEntity.date,
      notes: updatedEntity.notes,
      number: updatedEntity.mob.size,
      DSE: Number(updatedEntity.mob.DSE),
      paddockId: updatedEntity.paddock.id,
      type: updatedEntity.mob.type,
      breed: updatedEntity.mob.breed,
      gender: updatedEntity.mob.gender,
      classes: updatedEntity.mob.classes,
      ages: updatedEntity.mob.ages,
    };

    const response = await prelude.graphql({
      query: EDIT_NATURAL_INCREASE,
      variables: { input },
    });

    const naturalIncrease = decodeNaturalIncrease(updatedEntity.propertyId)(
      response.data.editNaturalIncrease.naturalIncrease
    );

    return naturalIncrease;
  };

const DELETE_NATURAL_INCREASE = `
  mutation DeleteNaturalIncrease($input: DeleteNaturalIncreaseInput!) {
    deleteNaturalIncrease(input: $input) {
      naturalIncrease {
        id
      }
    }
  }
`;

interface ApiDeleteNaturalIncreaseInput {
  id: string;
  propertyId: Property['id'];
}

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: NaturalIncrease }): Promise<void> => {
    const input: ApiDeleteNaturalIncreaseInput = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({
      query: DELETE_NATURAL_INCREASE,
      variables: { input },
    });
    return;
  };
