import React from 'react';
import { type IntlFormatters } from 'react-intl';

import {
  findMob,
  getLivestockAgeColor,
  type Mob,
  toMobDisplayName,
} from '@mobble/models/src/model/Mob';
import { findPaddock, Paddock } from '@mobble/models/src/model/Paddock';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { formatDate, fromRawDate, RawDate } from '@mobble/shared/src/core/Date';

import { Badge, Text } from '@src/components';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import { Clickable } from '@src/stories/Components/UX/Clickable';

import styles from './casualtiesTableColumns.scss';

export const makeCasualtiesTableColumns = ({
  paddocks,
  mobs,
  propertyTypes,
  formatMessage,
}: {
  mobs: Mob[];
  paddocks: Paddock[];
  propertyTypes: ConfiguredPropertyType[];
  formatMessage: IntlFormatters['formatMessage'];
}): TableColumn[] => {
  const handleClick = (event: any) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'date',
      label: formatMessage({
        defaultMessage: 'Date',
        description: 'casualties.table.heading.column.date',
      }),
      totals: false,
      toValue: (casualty) =>
        casualty.date ? fromRawDate(casualty.date).toDate().getTime() : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'head',
      label: formatMessage({
        defaultMessage: 'Head',
        description: 'casualties.table.heading.column.head',
      }),
      totals: true,
      toValue: (casualty) => casualty.number,
      render: (_, casualty) => <Text>{casualty.number}</Text>,
    },
    {
      key: 'type',
      label: formatMessage({
        defaultMessage: 'Type',
        description: 'mobs.table.heading.column.type',
      }),
      totals: false,
      toValue: (casualty) => casualty.mob.type,
    },
    {
      key: 'mob',
      label: formatMessage({
        description: 'casualties.table.heading.column.mob',
        defaultMessage: 'Mob',
      }),
      totals: false,
      toValue: (casualty) => toMobDisplayName(casualty.mob),
      render: (_, casualty) => {
        if (findMob(mobs)(casualty.mob.id)) {
          return (
            <Clickable
              href={toPath(ROUTE_NAME.MOB_DETAIL, { mobId: casualty.mob.id })}
            >
              <Text underline>{toMobDisplayName(casualty.mob)}</Text>
            </Clickable>
          );
        }
        return <Text>{toMobDisplayName(casualty.mob)}</Text>;
      },
    },
    {
      key: 'ages',
      label: formatMessage({
        defaultMessage: 'Ages',
        description: 'mobs.table.heading.column.ages',
      }),
      totals: false,
      toValue: (casualty) => casualty.mob.ages.join(', '),
      render: (_, casualty) => {
        return (
          <ul className={styles.agesList}>
            {casualty.mob.ages.map((age) => (
              <li key={age}>
                <Badge
                  textVariant="small"
                  label={String(age)}
                  color={getLivestockAgeColor(
                    propertyTypes,
                    casualty.mob.type
                  )(age)}
                />
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      key: 'classes',
      label: formatMessage({
        defaultMessage: 'Classes',
        description: 'mobs.table.heading.column.classes',
      }),
      totals: false,
      toValue: (casualty) => casualty.mob.classes.join(', '),
    },
    {
      key: 'paddock',
      label: formatMessage({
        defaultMessage: 'Paddock',
        description: 'casualties.table.heading.column.paddock',
      }),
      totals: false,
      toValue: (casualty) => {
        return findPaddock(paddocks)(casualty.paddock)?.name ?? '[deleted]';
      },
      render: (value, casualty) => (
        <Clickable
          href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
            paddockId: casualty.paddock,
          })}
          onClick={handleClick}
        >
          <Text underline>{String(value)}</Text>
        </Clickable>
      ),
    },
    {
      key: 'reason',
      label: formatMessage({
        defaultMessage: 'Reason',
        description: 'casualties.table.heading.column.reason',
      }),
      totals: false,
      toValue: (casualty) => casualty.reason,
    },
    {
      key: 'methodOfDisposal',
      label: formatMessage({
        defaultMessage: 'Method of disposal',
        description: 'casualties.table.heading.column.method_of_disposal',
      }),
      totals: false,
      toValue: (casualty) => casualty.methodOfDisposal,
    },
  ];
};
