import React from 'react';

export type StyleProps = React.CSSProperties;

export type Styles = (undefined | null | StyleProps | Styles)[] | StyleProps;

export type StyleSheet = Record<string, Styles>;

export const filterStyleProps = (styleProps: StyleProps): StyleProps =>
  Object.entries(styleProps).reduce((acc, [k, v]) => {
    if (v !== undefined && v !== null) {
      return { ...acc, [k]: v };
    }
    return acc;
  }, {});

export const concatStyles = (styles: Styles): StyleProps =>
  Array.isArray(styles)
    ? (styles.reduce((acc, a) => {
        if (typeof a === 'undefined' || a === null) {
          return acc;
        } else if (Array.isArray(a)) {
          return { ...acc, ...concatStyles(a) };
        }
        return { ...acc, ...filterStyleProps(a) };
      }, {}) as StyleProps)
    : styles;
