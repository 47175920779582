import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';

import { Color } from '@mobble/theme';

import Icon, { type IconName } from '../Icon/Icon';
import Text, { TextVariants } from '../Text/Text';

import styles from './Badge.scss';
const cx = classNames.bind(styles);

export interface BadgeProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * Text to display
   */
  label: string;

  /**
   * Background color of the badge
   * Text will be black or white depending on the contrast
   */
  color?: string | Color;

  /**
   * The icon to display before the label
   */
  icon?: IconName;

  /**
   * Text variant
   */
  textVariant?: TextVariants;
}

/**
 * Badge displays a coloured box with text and optional icon
 */
const Badge: React.FC<BadgeProps> = ({
  label,
  color = 'var(--primary-default)',
  icon,
  textVariant = 'small',
  className,
  style,
  ...others
}) => {
  const hasClickHandler = typeof others.onClick === 'function';
  const rootClasses = cx(
    {
      Badge: true,
      clickable: hasClickHandler,
      hasIcon: !!icon,
    },
    className
  );

  const mergedStyle = {
    ...style,
    '--badge-color': color,
  };

  return (
    <div className={rootClasses} style={mergedStyle} {...others}>
      {icon && <Icon color={null} name={icon} size="small" />}
      <Text variant={textVariant}>{label}</Text>
    </div>
  );
};

export default Badge;
