import { Purchase } from '@mobble/models/src/model/Purchase';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { FilterPerProperty } from '../lib/filter';

export interface PurchasesState {
  entities: Purchase[];
  extStatus: ExtStatusPerActionPerParent;
  filter: FilterPerProperty;
  sort: {};
}

export const defaultState: PurchasesState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<PurchasesState>({
  name: 'purchases',
  initialState: defaultState,
  toParentId: ({ propertyId }: Purchase) => propertyId,
  onGetAll: mobbleService.api.purchases.get,
  onCreate: () => Promise.reject(),
  onUpdate: mobbleService.api.purchases.update,
  onDelete: mobbleService.api.purchases.remove,
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
