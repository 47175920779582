import Fuse from 'fuse.js';
import { type FilterItem, filterMatches, groupFilter } from './Filter';
import { type Mob } from './Mob';
import { type Property } from './Property';
import { type User } from './User';

export interface Casualty {
  id: string;
  propertyId: Property['id'];
  number: number; // @TODO rename to amount or size
  methodOfDisposal: string | null;
  reason: string | null;
  date: any; // @TODO type to RawDate
  deleted: any | null; // @TODO type to boolean..
  createdBy: User;
  mob: Mob;
  paddock: string; // @TODO rename to paddockId
}

export const filterCasualties = (
  casualties: Casualty[],
  filter?: FilterItem[]
): Casualty[] => {
  if (!filter || filter.length === 0) {
    return casualties;
  }
  const grouped = [...groupFilter(filter)];

  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredCasualty =
    searchQuery && searchQuery.type === 'search'
      ? searchCasualties(casualties, searchQuery.value)
      : casualties;

  return preFilteredCasualty.filter((casualty) =>
    grouped.every(([_, filters]) =>
      filters.some(filterItemMatchesCasualties(casualty))
    )
  );
};

export const searchCasualties = (
  casualty: Casualty[],
  searchQuery: string
): Casualty[] => {
  const fuse = new Fuse(casualty, {
    keys: [
      { name: 'mob.breed', weight: 5 },
      { name: 'mob.gender', weight: 3 },
      { name: 'number', weight: 3 },
    ],
    threshold: 0.5,
    shouldSort: true,
  });
  return fuse.search(searchQuery || '').map((a) => a.item);
};

export const filterItemMatchesCasualties =
  (casualty: Casualty) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'dateRange':
        return matches(casualty.date);
      case 'breed':
        return matches(casualty.mob.breed);
      case 'gender':
        return matches(casualty.mob.gender);
      case 'number':
        return matches(casualty.number);
      case 'search':
        return true;
      default:
        return true;
    }
  };

export const findCasualtiesForMob =
  (casualties: Casualty[]) => (mobId: string) =>
    casualties.filter((casualty) => casualty.mob.id === mobId);

export const findCasualtiesForPaddock =
  (casualties: Casualty[]) => (paddockId: string) =>
    casualties.filter((casualty) => casualty.paddock === paddockId);
