import React from 'react';
import { useNavigate } from 'react-router';

import { useI18n } from '@mobble/i18n';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useProperties,
  usePaddocks,
  useSales,
  useMobs,
} from '@mobble/store/src/hooks';

import { toPath } from '@src/interfaces/Routing';
import { useLinking } from '@src/hooks/useLinking';
import { Sale, filterSales } from '@mobble/models/src/model/Sale';
import { useSalesFilterItems } from '@src/hooks/useSalesFilterItems';

import { makeSalesTableColumns } from '@src/stories/Views/Sale/List/salesTableColumns';
import { useSetting } from '@mobble/store/src/hooks/settings';
import { MemoisedSaleCard } from '@src/stories/Views/Sale/SaleCard';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { Box } from '@src/stories/Components/Layout/Box';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const SummarySalesHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Sales',
        description: 'screen.title.summary_sales',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Summaries',
            description: 'screen.title.summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SUMMARIES_LIST);
      }}
    />
  );
};

export const SummarySales: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const currencySymbol = useSetting('currencySymbol') as string;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const sales = useSales(propertyId);
  const paddocks = usePaddocks(propertyId);
  const mobs = useMobs(propertyId);
  const filterItems = useSalesFilterItems();
  const navigate = useNavigate();

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      mobs.prelude,
      sales.prelude,
      paddocks.prelude,
    ],
    required: [properties.selected],
  });

  const { refresh } = useEntitiesRefresher([mobs, paddocks, sales], propertyId);

  if (Prelude) {
    return Prelude;
  }

  const handleClick = (sale: Sale) => {
    navigate(
      toPath(ROUTE_NAME.SALE_DETAIL, {
        saleId: sale.id,
      })
    );
  };

  const viewerProps: EntitiesViewerProps<Sale> = {
    provider: sales,
    onEmpty: formatMessage({
      defaultMessage: 'There are no sales to display',
      description: 'sales.table.empty',
    }),
    onRefresh: refresh,
    applyFilter: filterSales,
    filterItems,
    onClickTableRow: handleClick,
    renderEntityCard: (sale) => {
      return (
        <Box spacing={1}>
          <MemoisedSaleCard sale={sale} onClick={handleClick} />
        </Box>
      );
    },
    tableColumns: makeSalesTableColumns({
      paddocks: paddocks.entities,
      currencySymbol,
      propertyTypes: properties.selected.types,
      formatMessage,
    }),
  };

  return (
    <EntitiesViewer
      title={formatMessage({
        defaultMessage: 'Sales',
        description: 'screen.title.summary_sales',
      })}
      {...viewerProps}
    />
  );
};

export default {
  name: ROUTE_NAME.SUMMARY_SALES_LIST,
  header: SummarySalesHeader,
  component: SummarySales,
};
