import React from 'react';
import { useI18n } from '@mobble/i18n';
import { ConfiguredPropertyTypeGroup } from '@mobble/models/src/model/Property';
import * as ROUTE_NAME from '../config/routeNames';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { NavigationList } from '../../stories/Components/UX/NavigationList';
import { ScreenRendererProps } from '../config/types';
import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Color } from '@mobble/colors';
import { base64encode } from '@mobble/shared/src/core/String';

const toClassTypesPath = (classTypes: {
  group: ConfiguredPropertyTypeGroup;
  livestockType?: 'Sheep' | 'Cattle' | 'Goats' | 'Other';
}) =>
  toPath(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_GROUP, {
    classTypes: base64encode(JSON.stringify(classTypes)),
  });

export const SettingsPropertyClassesTypesHeader: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_LIST);
      }}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      title={{
        key: 'screen.title.settings_property_classes_types',
      }}
    />
  );
};

export const SettingsPropertyClassesTypes: React.FC<
  ScreenRendererProps
> = () => {
  const items = [
    {
      title: { key: 'settings.property.classes_types.toc.types' },
      data: [
        {
          label: { key: 'settings.property.classes_types.toc.paddock_types' },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.paddockType,
          }),
        },
        {
          label: { key: 'settings.property.classes_types.toc.livestock_types' },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.livestockType,
          }),
        },
      ],
    },
    {
      title: { key: 'settings.property.classes_types.toc.breeds' },
      data: [
        {
          label: {
            key: 'settings.property.classes_types.toc.sheep_breeds',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.breed,
            livestockType: 'Sheep',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.cattle_breeds',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.breed,
            livestockType: 'Cattle',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.goats_breeds',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.breed,
            livestockType: 'Goats',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.other_breeds',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.breed,
            livestockType: 'Other',
          }),
        },
      ],
    },
    {
      title: { key: 'settings.property.classes_types.toc.genders' },
      data: [
        {
          label: {
            key: 'settings.property.classes_types.toc.sheep_genders',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.gender,
            livestockType: 'Sheep',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.cattle_genders',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.gender,
            livestockType: 'Cattle',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.goats_genders',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.gender,
            livestockType: 'Goats',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.other_genders',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.gender,
            livestockType: 'Other',
          }),
        },
      ],
    },
    {
      title: { key: 'settings.property.classes_types.toc.classes' },
      data: [
        {
          label: {
            key: 'settings.property.classes_types.toc.sheep_classes',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.class,
            livestockType: 'Sheep',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.cattle_classes',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.class,
            livestockType: 'Cattle',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.goats_classes',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.class,
            livestockType: 'Goats',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.other_classes',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.class,
            livestockType: 'Other',
          }),
        },
      ],
    },
    {
      title: { key: 'settings.property.classes_types.toc.tags' },
      data: [
        {
          label: {
            key: 'settings.property.classes_types.toc.sheep_tags',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.tag,
            livestockType: 'Sheep',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.cattle_tags',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.tag,
            livestockType: 'Cattle',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.goats_tags',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.tag,
            livestockType: 'Goats',
          }),
        },
        {
          label: {
            key: 'settings.property.classes_types.toc.other_tags',
          },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.tag,
            livestockType: 'Other',
          }),
        },
      ],
    },
    {
      title: {
        key: 'settings.property.classes_types.toc.actions_and_treatments',
      },
      data: [
        {
          label: { key: 'settings.property.classes_types.toc.paddock_actions' },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.paddockAction,
          }),
        },
        {
          label: { key: 'settings.property.classes_types.toc.mob_actions' },
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.mobAction,
          }),
        },
      ],
    },
  ];

  return (
    <Box>
      {items.map((item) => (
        <React.Fragment key={JSON.stringify(item)}>
          <Box spacing={2}>
            <Text bold i18n={item.title} color={Color.Grey} />
          </Box>
          <NavigationList items={item.data} />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES,
  header: SettingsPropertyClassesTypesHeader,
  component: SettingsPropertyClassesTypes,
};
