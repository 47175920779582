import React, { useState } from 'react';

import { useI18n } from '@mobble/i18n/src';

import {
  ANALYTICS_EVENTS,
  GTM_SIGN_UP,
  INVITE_SIGNUP,
} from '@src/constants/analytics';
import { useTitle } from '@src/hooks/useTitle';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { AuthHelp } from '@src/stories/Views/Auth/AuthHelp';
import { AuthWrapper } from '@src/stories/Views/Auth/AuthWrapper';
import {
  InviteForm,
  InviteFormValues,
} from '@src/stories/Views/Auth/InviteForm';
import { LoadingScreen } from '@src/stories/Views/Misc/LoadingScreen';

import {
  useAcceptInvite,
  useGetInvitationEmailFromToken,
  useGetInviteTokenFromQueryParam,
} from './hooks/inviteHooks';

const handleAnalytics = (email: string) => {
  if (window.analytics) {
    window.analytics.track(ANALYTICS_EVENTS.INVITE_SIGNUP_FORM_COMPLETED, {
      email,
      lead_source: INVITE_SIGNUP,
    });
    window.analytics.identify(email, {
      email,
      lead_source: INVITE_SIGNUP,
    });
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      event: GTM_SIGN_UP.EVENT,
      formId: GTM_SIGN_UP.FORM_ID,
    });
  }
};

export const InvitationSetUp: React.FC = () => {
  const { formatMessage } = useI18n();
  const token = useGetInviteTokenFromQueryParam();
  const email = useGetInvitationEmailFromToken(token);
  const onAcceptInvite = useAcceptInvite();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  useTitle(
    formatMessage({
      defaultMessage: 'Accept Invitation',
      description: 'Invite Sign Up page title',
    })
  );

  const handleSubmit = ({
    firstName,
    lastName,
    password,
  }: InviteFormValues) => {
    setLoading(true);
    setError(null);

    onAcceptInvite({
      email,
      password,
      firstName,
      lastName,
    })
      .then(() => {
        setLoading(false);
        handleAnalytics(email);
      })
      .catch((e) => {
        setLoading(false);
        setError(e?.message);
      });
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    password: '',
    termsAgreed: false,
  };

  if (!email) {
    return <LoadingScreen fullScreen />;
  }

  return (
    <AuthWrapper>
      <InviteForm
        error={error}
        loading={loading}
        initialValues={initialValues}
        email={email}
        onSubmit={handleSubmit}
      />
      <AuthHelp signUp={ROUTE_NAME.AUTH_SIGN_UP} />
    </AuthWrapper>
  );
};

export default {
  name: ROUTE_NAME.AUTH_INVITE,
  component: InvitationSetUp,
};
