import React, { HTMLAttributes, MutableRefObject } from 'react';
import { type I18nItem } from '@mobble/i18n';
import { Color } from '@mobble/colors';
import { Spacer } from '../Layout/Spacer';
import { Icon, type IconSize, type IconName } from '../UI/Icon';
import { HStack } from '../Layout/Stack';
import { Text, type TextVariants } from '../UI/Text';
import { NavLink } from 'react-router-dom';
import {
  type Cardinal,
  classNamePadding,
  type Spacing,
} from '../Layout/Spacing';
import styles from './clickable.scss';

type PickedAttributes = Partial<
  Pick<
    HTMLAttributes<HTMLAnchorElement | HTMLButtonElement>,
    'aria-label' | 'onFocus' | 'tabIndex' | 'id' | 'role'
  >
>;

interface ClickableProps extends PickedAttributes {
  bold?: boolean;
  children?:
    | React.ReactNode
    | ((props?: { isActive: boolean }) => React.ReactNode);
  color?: Color;
  flex?: boolean;
  icon?: IconName;
  iconSize?: IconSize;
  label?: I18nItem | string;
  href?: string | ((ev: any) => void);
  onClick?: (ev: any) => void;
  variant?: TextVariants;
  disabled?: boolean;
  className?: string;
  spacing?: Spacing | Cardinal<Spacing>;
  fullWidth?: boolean;
}

export const Clickable = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ClickableProps
>(
  (
    {
      bold,
      children,
      color,
      flex,
      icon,
      iconSize = 'small',
      label,
      onClick,
      href = onClick,
      variant,
      disabled,
      className,
      spacing,
      fullWidth,
      ...others
    },
    ref
  ) => {
    const classNames = [
      styles.clickable,
      flex ? styles.flex : null,
      spacing ? classNamePadding(spacing) : styles.noSpacing,
      fullWidth ? styles.fullWidth : null,
      className,
    ]
      .filter(Boolean)
      .join(' ');

    const renderedChildren =
      typeof children === 'function' ? children() : children;

    const content = (args?: { isActive: boolean }) =>
      icon || label ? (
        <HStack alignment="center">
          {icon && (
            <>
              <Icon name={icon} color={color} size={iconSize} />
              <Spacer x={1} />
            </>
          )}
          {label && (
            <Text bold={bold} variant={variant} color={color} i18n={label} />
          )}
          {renderedChildren}
        </HStack>
      ) : (
        renderedChildren
      );

    const externalLink = typeof href === 'string' && href.startsWith('http');

    if (!href) {
      return <>{content()}</>;
    } else if (typeof href === 'function') {
      return (
        <button
          {...others}
          ref={ref as MutableRefObject<HTMLButtonElement>}
          type="button"
          className={classNames}
          disabled={disabled}
          onClick={href}
        >
          {content()}
        </button>
      );
    } else if (externalLink) {
      return (
        <a
          {...others}
          ref={ref as MutableRefObject<HTMLAnchorElement>}
          className={classNames}
          href={disabled ? undefined : href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {content()}
        </a>
      );
    }

    return (
      <NavLink
        {...others}
        ref={ref as MutableRefObject<HTMLAnchorElement>}
        className={classNames}
        to={disabled ? undefined : href}
        state={{ from: location.pathname }}
        onClick={onClick}
      >
        {({ isActive }) => content({ isActive })}
      </NavLink>
    );
  }
);

Clickable.displayName = 'Clickable';
