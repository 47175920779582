import { type PaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';

export interface PaddockGeometryState {
  extStatus: ExtStatusPerActionPerParent;
  entities: PaddockGeometry[];
  filter: {};
  sort: {};
}

export const defaultState: PaddockGeometryState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<PaddockGeometryState>({
  name: 'paddockGeometry',
  initialState: defaultState,
  toParentId: ({ propertyId }: PaddockGeometry) => propertyId,
  onGetAll: mobbleService.api.paddockGeometries.get,
  onCreate: mobbleService.api.paddockGeometries.create,
  onUpdate: mobbleService.api.paddockGeometries.update,
  onDelete: mobbleService.api.paddockGeometries.remove,
  afterMutation: async ({ entity }, dispatch) => {
    dispatch(thunkGetAll({ parentId: entity.propertyId }));
  },
});

export const { thunkGetAll, proxyUseEntities, proxyUseEntity } = entitySlice;
export const { flush } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
