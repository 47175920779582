import { type ApiPrelude } from '../types';
import { addDays, fromRawDate } from '@mobble/shared/src/core/Date';

const GET_PROPERTY_STOCKING_RATES = `
  query GetPropertyStockingRates($propertyId: ID!, $start: Date!, $end: Date!) {
    propertyStockingRates(propertyId: $propertyId, start: $start, end: $end) {
      start
      end
      data {
        day
        rollingAverageDSE
        rollingAreaAverageDSE
        totalHeadSheep
        totalDSESheep
        areaDSESheep
        totalHeadCattle
        totalDSECattle
        areaDSECattle
        totalHeadGoats
        totalDSEGoats
        areaDSEGoats
        totalHeadOther
        totalDSEOther
        areaDSEOther
        __typename
      }
      __typename
    }
  }
`;

const decodePropertyStockingRate =
  (propertyId: string) =>
  (raw: any): PropertyStockingRate => {
    const date = fromRawDate(raw.day);

    return {
      propertyId,

      dateFormatted: date.format('YYYY-MM-DD'),
      dateRaw: raw.day,

      byLivestockType: Object.entries(raw).reduce((acc, [key, value]) => {
        if (key.startsWith('totalHead')) {
          const [_, livestockType] = key.split('totalHead');
          acc[livestockType.toLowerCase()] = {
            head: value,
            DSE: raw[`totalDSE${livestockType}`],
            areaDSE: raw[`areaDSE${livestockType}`],
          };
        }

        return acc;
      }, {}),

      rollingAverage: {
        DSE: raw.rollingAverageDSE,
        areaDSE: raw.rollingAreaAverageDSE,
      },
    };
  };

const decodePropertyStockingRates =
  (propertyId: string) =>
  (raw: any[]): PropertyStockingRate[] =>
    raw.map(decodePropertyStockingRate(propertyId));

export const get =
  (prelude: ApiPrelude) =>
  async ({
    propertyId,
    range,
  }: {
    propertyId: string;
    range: {
      start: Date;
      end: Date;
    };
  }): Promise<PropertyStockingRate[]> => {
    const variables = {
      propertyId,
      start: addDays(range.start, 1).toISOString(),
      end: addDays(range.end, 1).toISOString(),
    };

    const response = await prelude.graphql({
      query: GET_PROPERTY_STOCKING_RATES,
      variables,
    });

    return decodePropertyStockingRates(propertyId)(
      response?.data?.propertyStockingRates?.data ?? []
    );
  };

export interface PropertyStockingRate {
  propertyId: string;

  dateFormatted: string;
  dateRaw: string;

  byLivestockType: {
    [key: string]: PropertyStockingRateByLivestockValues;
  };

  rollingAverage: Omit<PropertyStockingRateByLivestockValues, 'head'>;
}

export interface PropertyStockingRateByLivestockValues {
  head: number;
  DSE: number;
  areaDSE?: number;
}
