export const roundNumberTo =
  (digits: number) =>
  (num: number): string | number => {
    if (typeof num !== 'number') {
      return 0;
    }

    if (digits <= 0) {
      return Math.round(num);
    }

    num = num * Math.pow(10, digits); // num = 3141.69
    num = Math.round(num) / Math.pow(10, digits); // num = 3141 / 100 = 31.41

    if (num.toFixed(digits) !== String(num)) {
      return String(num);
      // if (String(num).endsWith(`.${Array(digits).fill('0').join('')}`)) {
      //   return '3';
      // }
      // return num.toFixed(digits);
    }
    return num;
  };

export const roundNumber = roundNumberTo(2);
export const toInteger = (num: number) => Math.round(num);

export const sortNumberDescending = (a: number, b: number) => (a > b ? -1 : 1);

export const interpolateSlope =
  (a: number, b: number, c: number, d: number) => (x: number) => {
    if (x <= a) {
      return b;
    } else if (x >= c) {
      return d;
    }

    const slope = (d - b) / (c - a);
    const yIntercept = b - slope * a;
    return slope * x + yIntercept;
  };

export const formatNumber = (num: number): string => {
  return new Intl.NumberFormat().format(num);
};

export const isNumber = (num: number | string) => {
  return !isNaN(Number(num));
};
