import { useEffect } from 'react';
import { InventoryItem, InventoryItemBatch } from '@mobble/models';
import {
  proxyUseEntities,
  proxyUseEntity,
  thunkGetAll as thunkGetAllPropertyInventoryItemBatches,
} from '../reducers/inventoryItemBatches';
import { useInventoryItems } from './inventoryItems';
import { useRootDispatch, useRootSelector } from './root';

export const useInventoryItemBatches = (
  inventoryItemId: InventoryItem['id'],
  propertyId: InventoryItem['propertyId']
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.inventoryItemBatches);
  const propertiesState = useRootSelector((s) => s.properties);

  const inventoryItems = useInventoryItems();
  const allEntities = inventoryItems.allEntitiesAvailable;
  const chemicalInventories = allEntities?.filter(
    (i) =>
      (i.propertyId === propertyId || i?.shared?.propertyId === propertyId) &&
      i.status === 'active'
  );
  const organisation = propertiesState.entities.find(
    (property) => property.id === propertyId
  )?.organisation;

  const proxied = proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
    extFilter: {
      chemicalIds: chemicalInventories.map((i) => i.id),
    },
  });

  useEffect(() => {
    propertiesState.entities
      .filter((property) => property?.organisation?.id === organisation?.id)
      .forEach((property) => {
        dispatch(
          thunkGetAllPropertyInventoryItemBatches({ parentId: property.id })
        );
      });
  }, []);

  // auto-refresh if chemicalInventories changes
  // required for cases where batches are requested before chemicalInventories are loaded
  useEffect(() => {
    if (propertyId && chemicalInventories.length > 0) {
      proxied.refresh();
    }
  }, [propertyId, chemicalInventories.length]);

  return {
    ...proxied,
    entities:
      inventoryItemId !== '_'
        ? (proxied.allEntitiesAvailable.filter(
            (i) => i.inventoryItemId === inventoryItemId
          ) as InventoryItemBatch[])
        : proxied.entities,
  };
};

export const useInventoryItemBatch = (
  inventoryItemBatchId: InventoryItemBatch['id'],
  inventoryItemId: InventoryItem['id'],
  propertyId: InventoryItem['propertyId']
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.inventoryItemBatches);

  return proxyUseEntity({
    entityId: inventoryItemBatchId,
    parentId: propertyId,
    dispatch,
    state,
    extFilter: {
      chemicalIds: [inventoryItemId],
    },
  });
};
