import React from 'react';
import { defineMessages } from 'react-intl';
import { useMessages } from '@mobble/i18n';
import { useDialog } from '@src/hooks/useDialog';
import Button, { ButtonProps } from '@src/components/Button';
import { Box } from '@src/stories/Components/Layout/Box';
import styles from './deleteForm.scss';

export interface DeleteFormProps {
  i18nRootKey: string;
  name?: string;
  disabled?: boolean;
  loading: boolean;
  icon?: ButtonProps['icon'];
  intent?: ButtonProps['intent'];
  outline?: ButtonProps['outline'];
  children?: React.ReactNode;
  onDelete: () => void;
}

const messages = defineMessages({
  'casualties.casualty.delete.confirm.cancel': {
    description: 'casualties.casualty.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'casualties.casualty.delete.confirm.confirm': {
    description: 'casualties.casualty.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'casualties.casualty.delete.confirm.description': {
    description: 'casualties.casualty.delete.confirm.description',
    defaultMessage: 'This will delete the casualty from your records.',
  },
  'casualties.casualty.delete.confirm.title': {
    description: 'casualties.casualty.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'casualties.casualty.delete.form.delete.label': {
    description: 'casualties.casualty.delete.form.delete.label',
    defaultMessage: 'Delete casualty',
  },
  'paddocks.paddock.delete.confirm.cancel': {
    description: 'paddocks.paddock.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'paddocks.paddock.delete.confirm.confirm': {
    description: 'paddocks.paddock.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'paddocks.paddock.delete.confirm.description': {
    description: 'paddocks.paddock.delete.confirm.description',
    defaultMessage: 'This will delete the paddock.',
  },
  'paddocks.paddock.delete.confirm.title': {
    description: 'paddocks.paddock.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'paddocks.paddock.delete.form.delete.label': {
    description: 'paddocks.paddock.delete.form.delete.label',
    defaultMessage: 'Delete paddock',
  },
  'paddocks.paddock_group.delete.confirm.cancel': {
    description: 'paddocks.paddock_group.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'paddocks.paddock_group.delete.confirm.confirm': {
    description: 'paddocks.paddock_group.delete.confirm.confirm',
    defaultMessage: 'Close Gates',
  },
  'paddocks.paddock_group.delete.confirm.description': {
    description: 'paddocks.paddock_group.delete.confirm.description',
    defaultMessage: 'This will close gates between the selected paddocks.',
  },
  'paddocks.paddock_group.delete.confirm.title': {
    description: 'paddocks.paddock_group.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'paddocks.paddock_group.delete.form.delete.label': {
    description: 'paddocks.paddock_group.delete.form.delete.label',
    defaultMessage: 'Close gates between all paddocks',
  },
  'paddockActions.paddockAction.delete.confirm.cancel': {
    description: 'paddockActions.paddockAction.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'paddockActions.paddockAction.delete.confirm.confirm': {
    description: 'paddockActions.paddockAction.delete.confirm.confirm',
    defaultMessage: 'Delete Action',
  },
  'paddockActions.paddockAction.delete.confirm.description': {
    description: 'paddockActions.paddockAction.delete.confirm.description',
    defaultMessage: 'This will delete the Paddock Action from your records.',
  },
  'paddockActions.paddockAction.delete.confirm.title': {
    description: 'paddockActions.paddockAction.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'paddockActions.paddockAction.delete.form.delete.label': {
    description: 'paddockActions.paddockAction.delete.form.delete.label',
    defaultMessage: 'Delete Paddock Action',
  },
  'mobs.mob.delete.confirm.cancel': {
    description: 'mobs.mob.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'mobs.mob.delete.confirm.confirm': {
    description: 'mobs.mob.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'mobs.mob.delete.confirm.description': {
    description: 'mobs.mob.delete.confirm.description',
    defaultMessage: 'This will delete the mob.',
  },
  'mobs.mob.delete.confirm.title': {
    description: 'mobs.mob.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'mobs.mob.delete.form.delete.label': {
    description: 'mobs.mob.delete.form.delete.label',
    defaultMessage: 'Delete mob',
  },
  'tasks.task.delete.confirm.cancel': {
    description: 'tasks.task.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'tasks.task.delete.confirm.confirm': {
    description: 'tasks.task.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'tasks.task.delete.confirm.description': {
    description: 'tasks.task.delete.confirm.description',
    defaultMessage: 'This will delete the task.',
  },
  'tasks.task.delete.confirm.title': {
    description: 'tasks.task.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'tasks.task.delete.form.delete.label': {
    description: 'tasks.task.delete.form.delete.label',
    defaultMessage: 'Delete task',
  },
  'inventories.inventory-item-batch.delete.confirm.cancel': {
    description: 'inventories.inventory-item-batch.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'inventories.inventory-item-batch.delete.confirm.confirm': {
    description: 'inventories.inventory-item-batch.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'inventories.inventory-item-batch.delete.confirm.description': {
    description: 'inventories.inventory-item-batch.delete.confirm.description',
    defaultMessage: 'This will delete the batch.',
  },
  'inventories.inventory-item-batch.delete.confirm.title': {
    description: 'inventories.inventory-item-batch.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'inventories.inventory-item-batch.delete.form.delete.label': {
    description: 'inventories.inventory-item-batch.delete.form.delete.label',
    defaultMessage: 'Delete batch',
  },
  'inventory_item.delete.form.delete.label': {
    description: 'inventory_item.delete.form.delete.label',
    defaultMessage: 'Delete',
  },
  'inventory_item.delete.confirm.cancel': {
    description: 'inventory_item.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'inventory_item.delete.confirm.confirm': {
    description: 'inventory_item.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'inventory_item.delete.confirm.description': {
    description: 'inventory_item.delete.confirm.description',
    defaultMessage: 'This will delete "{NAME}" from your inventory.',
  },
  'inventory_item.delete.confirm.title': {
    description: 'inventory_item.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'rain-gauges.rain-gauge.delete.confirm.cancel': {
    description: 'rain-gauges.rain-gauge.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'rain-gauges.rain-gauge.delete.confirm.confirm': {
    description: 'rain-gauges.rain-gauge.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'rain-gauges.rain-gauge.delete.confirm.description': {
    description: 'rain-gauges.rain-gauge.delete.confirm.description',
    defaultMessage: 'This will delete the rain gauge.',
  },
  'rain-gauges.rain-gauge.delete.confirm.title': {
    description: 'rain-gauges.rain-gauge.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'rain-gauges.rain-gauge.delete.form.delete.label': {
    description: 'rain-gauges.rain-gauge.delete.form.delete.label',
    defaultMessage: 'Delete rain gauge',
  },
  'rain-gauges.rain-gauge-reading.delete.confirm.cancel': {
    description: 'rain-gauges.rain-gauge-reading.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'rain-gauges.rain-gauge-reading.delete.confirm.confirm': {
    description: 'rain-gauges.rain-gauge-reading.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'rain-gauges.rain-gauge-reading.delete.confirm.description': {
    description: 'rain-gauges.rain-gauge-reading.delete.confirm.description',
    defaultMessage: 'This will delete the rain gauge reading.',
  },
  'rain-gauges.rain-gauge-reading.delete.confirm.title': {
    description: 'rain-gauges.rain-gauge-reading.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'rain-gauges.rain-gauge-reading.delete.form.delete.label': {
    description: 'rain-gauges.rain-gauge-reading.delete.form.delete.label',
    defaultMessage: 'Delete rain gauge reading',
  },
  'mobActions.mobAction.delete.confirm.cancel': {
    description: 'mobActions.mobAction.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'mobActions.mobAction.delete.confirm.confirm': {
    description: 'mobActions.mobAction.delete.confirm.confirm',
    defaultMessage: 'Delete Action',
  },
  'mobActions.mobAction.delete.confirm.description': {
    description: 'mobActions.mobAction.delete.confirm.description',
    defaultMessage: 'This will delete the Mob Action from your records.',
  },
  'mobActions.mobAction.delete.confirm.title': {
    description: 'mobActions.mobAction.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'mobActions.mobAction.delete.form.delete.label': {
    description: 'mobActions.mobAction.delete.form.delete.label',
    defaultMessage: 'Delete Mob Action',
  },
  'sales.sale.delete.confirm.cancel': {
    description: 'sales.sale.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'sales.sale.delete.confirm.confirm': {
    description: 'sales.sale.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'sales.sale.delete.confirm.description': {
    description: 'sales.sale.delete.confirm.description',
    defaultMessage: 'This will delete the sale from your records.',
  },
  'sales.sale.delete.confirm.title': {
    description: 'sales.sale.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'sales.sale.delete.form.delete.label': {
    description: 'sales.sale.delete.form.delete.label',
    defaultMessage: 'Delete Sale',
  },
  'purchases.purchase.delete.confirm.cancel': {
    description: 'purchases.purchase.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'purchases.purchase.delete.confirm.confirm': {
    description: 'purchases.purchase.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'purchases.purchase.delete.confirm.description': {
    description: 'purchases.purchase.delete.confirm.description',
    defaultMessage: 'This will delete the purchase.',
  },
  'purchases.purchase.delete.confirm.title': {
    description: 'purchases.purchase.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'purchases.purchase.delete.form.delete.label': {
    description: 'purchases.purchase.delete.form.delete.label',
    defaultMessage: 'Delete Purchase',
  },
  'naturalIncreases.naturalIncrease.delete.confirm.cancel': {
    description: 'naturalIncreases.naturalIncrease.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'naturalIncreases.naturalIncrease.delete.confirm.confirm': {
    description: 'naturalIncreases.naturalIncrease.delete.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'naturalIncreases.naturalIncrease.delete.confirm.description': {
    description: 'naturalIncreases.naturalIncrease.delete.confirm.description',
    defaultMessage: 'This will delete the Natural Increase from your records.',
  },
  'naturalIncreases.naturalIncrease.delete.confirm.title': {
    description: 'naturalIncreases.naturalIncrease.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'naturalIncreases.naturalIncrease.delete.form.delete.label': {
    description: 'naturalIncreases.naturalIncrease.delete.form.delete.label',
    defaultMessage: 'Delete Natural Increase',
  },
  'map_assets.map_asset.delete.confirm.cancel': {
    description: 'map_assets.map_asset.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'map_assets.map_asset.delete.confirm.confirm': {
    description: 'map_assets.map_asset.delete.confirm.confirm',
    defaultMessage: 'Delete asset',
  },
  'map_assets.map_asset.delete.confirm.description': {
    description: 'map_assets.map_asset.delete.confirm.description',
    defaultMessage: 'Are you sure you want to delete this asset?',
  },
  'map_assets.map_asset.delete.confirm.title': {
    description: 'map_assets.map_asset.delete.confirm.title',
    defaultMessage: 'Delete Map Asset',
  },
  'map_assets.map_asset.delete.form.delete.label': {
    description: 'map_assets.map_asset.delete.form.delete.label',
    defaultMessage: 'Delete asset',
  },

  'settings.property.types.confirm.description': {
    description: 'settings.property.types.confirm.description',
    defaultMessage:
      'This will delete the selected type and make it unable to be selected in the future. Historic data will remain.',
  },
  'settings.property.types.confirm.title': {
    description: 'settings.property.types.confirm.title',
    defaultMessage: 'Delete',
  },
  'settings.property.types.confirm.cancel': {
    description: 'settings.property.types.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'settings.property.types.confirm.confirm': {
    description: 'settings.property.types.confirm.confirm',
    defaultMessage: 'Delete',
  },
  'settings.property.types.form.delete.label': {
    description: 'settings.property.types.form.delete.label',
    defaultMessage: 'Delete',
  },
  'properties.delete.cancel': {
    description: 'properties.delete.cancel',
    defaultMessage: 'Cancel',
  },
  'properties.delete.confirm.cancel': {
    description: 'properties.delete.confirm.cancel',
    defaultMessage: 'Cancel',
  },
  'properties.delete.confirm.confirm': {
    description: 'properties.delete.confirm.confirm',
    defaultMessage: 'Confirm',
  },
  'properties.delete.confirm.description': {
    description: 'properties.delete.confirm.description',
    defaultMessage: 'This will delete the property.',
  },
  'properties.delete.confirm.title': {
    description: 'properties.delete.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  'properties.delete.form.delete.label': {
    description: 'properties.delete.form.delete.label',
    defaultMessage: 'Delete',
  },
});

export const DeleteForm = ({
  i18nRootKey,
  name,
  disabled,
  loading,
  icon = 'trash',
  intent = 'destructive',
  outline = false,
  children,
  onDelete,
}: DeleteFormProps) => {
  const strings = useMessages(messages, {
    NAME: name,
  });
  const { alert, close } = useDialog();

  const handleDeleteClick = () => {
    alert(
      strings[`${i18nRootKey}.confirm.title`],
      strings[`${i18nRootKey}.confirm.description`],
      [
        {
          label: strings[`${i18nRootKey}.confirm.cancel`],
          outline: true,
          intent: 'secondary',
          onClick: () => {
            close();
          },
        },
        {
          label: strings[`${i18nRootKey}.confirm.confirm`],
          type: 'submit',
          intent: intent !== 'destructive' ? 'primary' : 'destructive',
          onClick: () => {
            onDelete();
            close();
          },
        },
      ]
    );
  };

  return (
    <Box className={styles.deleteForm}>
      <Button
        id="delete-button"
        label={strings[`${i18nRootKey}.form.delete.label`]}
        disabled={disabled}
        loading={loading}
        icon={icon}
        intent={intent}
        outline={outline}
        flex
        onClick={handleDeleteClick}
      />

      {children}
    </Box>
  );
};
