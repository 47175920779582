import { toISO8601 } from '@mobble/shared/src/core/Date';
import {
  decodeAppliedInventoryItemChemicals,
  decodeAppliedInventoryItemFeeds,
  type AppliedInventoryItemChemical,
  type AppliedInventoryItemFeed,
} from '@mobble/models/src/model/AppliedInventoryItem';
import { InventoryCategory } from '@mobble/models/src/model/Inventory';
import { type PaddockAction } from '@mobble/models/src/model/PaddockAction';

import { type ApiPrelude, type ExtCursor } from '../types';

const decodePaddockActions =
  (propertyId: string) =>
  (raw: any[]): PaddockAction[] => {
    return raw.map(decodePaddockAction(propertyId));
  };

const decodePaddockAction =
  (propertyId: string) =>
  (raw: any): PaddockAction => {
    return {
      id: raw?.id ?? '',
      propertyId,
      date: raw?.date ?? '',
      createdBy: {
        id: raw?.createdBy?.id ?? '',
        name: raw?.createdBy?.displayName ?? '',
        email: raw?.createdBy?.email ?? '',
      },
      type: raw?.type ?? '',
      title: raw?.title ?? '',
      description: raw?.description ?? '',
      note: raw?.note,
      paddocks: raw?.paddocks?.map((a: any) => ({
        name: a?.name ?? '',
        paddockId: a?.id ?? '',
      })),
      appliedInventoryItems: [
        ...decodeAppliedInventoryItemChemicals(raw?.chemicalApplications ?? []),
        ...decodeAppliedInventoryItemFeeds(raw?.feedApplications ?? []),
      ],
    };
  };

const GET_PADDOCK_ACTIONS = `
  query GetPaddockActions(
    $propertyId: ID!
    $after: String
    $filterBy: ActionFilters
    $pageSize: Int
  ) {
    paddockActions(
      propertyId: $propertyId
      after: $after
      filterBy: $filterBy
      page_size: $pageSize
    ) {
      totalCount
      nodes {
        id
        type
        date
        title
        description
        note
        createdBy {
          id
          displayName
          email
        }
        paddocks {
          id
          name
        }
        chemicalApplications {
          id
          esi
          whp
          quantity {
            unit
            value
          }
          batch {
            id
          }
          chemical {
            id
            batchId
            productName
            propertyId
            category
            status
            lastUsed
            quantity {
              unit
              value
            }
          }
        }
        feedApplications {
          id
          quantity {
            unit
            value
          }
          batch {
            id
          }
          feed {
            id
            name
            propertyId
            status
            lastUsed
            amountReceived {
              value
              unit
            }
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    cursor,
  }: {
    parentId: string;
    cursor?: string;
  }): Promise<{ entities: PaddockAction[]; extCursor: ExtCursor }> => {
    const variables = {
      propertyId: parentId,
      after: cursor,
      pageSize: 9999,
    };

    const response = await prelude.graphql({
      query: GET_PADDOCK_ACTIONS,
      variables,
    });
    const entities = decodePaddockActions(parentId)(
      response.data.paddockActions.nodes
    );

    const pageInfo = response?.data?.paddockActions?.pageInfo;
    const extCursor = {
      total: response?.data?.paddockActions?.totalCount ?? entities.length,
      nextCursor: pageInfo?.hasNextPage && pageInfo.endCursor,
    };

    return {
      entities,
      extCursor,
    };
  };

// Get

const GET_PADDOCK_ACTION = `
  query GetPaddockActions($propertyId: ID!, $id: ID!) {
     paddockAction(propertyId: $propertyId, id: $id) {
      id
      type
      date
      title
      description
      note
      createdBy {
        id
        displayName
        email
      }
      paddocks {
        id
        name
      }
      chemicalApplications {
        id
        esi
        whp
        quantity {
          unit
          value
        }
        batch {
          id
        }
        chemical {
          id
          batchId
          productName
          propertyId
          category
          status
          lastUsed
          quantity {
            unit
            value
          }
        }
      }
      feedApplications {
        id
        quantity {
          unit
          value
        }
        batch {
          id
        }
        feed {
          id
          name
          propertyId
          status
          lastUsed
          amountReceived {
            value
            unit
          }
        }
      }
    }
  }
`;

export const find =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    id,
  }: {
    parentId: string;
    id: string;
  }): Promise<PaddockAction> => {
    const variables = {
      propertyId: parentId,
      id,
    };

    const response = await prelude.graphql({
      query: GET_PADDOCK_ACTION,
      variables,
    });

    const entity = decodePaddockAction(parentId)(response.data.paddockAction);

    return entity;
  };

// Create

interface AddPaddockActionInput {
  propertyId: string;
  date: any;
  type: string;
  paddockIds: string[];
  chemicalApplications: AddChemicalApplicationInput[];
  feedApplications?: AddFeedApplicationInput[] | null;
  note: string;
}

interface AddChemicalApplicationInput {
  chemical: string;
  quantity: ValueWithUnitInput;
  esi?: number | null;
  whp?: number | null;
}

interface ValueWithUnitInput {
  unit: string;
  value: number;
}

interface AddFeedApplicationInput {
  feed: string;
  quantity: ValueWithUnitInput;
}

const CREATE_PADDOCK_ACTION = `
  mutation AddPaddockAction($input: AddPaddockActionInput!) {
    addPaddockAction(input: $input) {
      paddockAction {
        id
        type
        date
        title
        description
        note

        createdBy {
          id
          displayName
          email
        }

        paddocks {
          id
          name
        }

        chemicalApplications {
          id

          esi
          whp

          quantity {
            unit
            value
          }

          batch {
            id
          }

          chemical {
            id
            batchId
            productName
          }
        }

        feedApplications {
          id

          quantity {
            unit
            value
          }

          batch {
            id
          }

          feed {
            id
            name
          }
        }
      }
    }
  }
`;

export const create =
  (prelude: ApiPrelude) =>
  async ({
    entity,
  }: {
    entity: Omit<PaddockAction, 'id'>;
  }): Promise<PaddockAction> => {
    const input: AddPaddockActionInput = {
      propertyId: entity.propertyId,
      date: toISO8601(entity.date),
      type: entity.type,
      note: entity.note ?? '',
      paddockIds: entity.paddocks.map((p) => p.paddockId),
      chemicalApplications: (
        entity.appliedInventoryItems.filter(
          (a) => a.category === InventoryCategory.Chemicals
        ) as AppliedInventoryItemChemical[]
      ).map((a) => ({
        chemical: a.inventoryItemId,
        batch: a.batchId,
        quantity: {
          unit: a.quantity.unit,
          value: a.quantity.value,
        },
        esi: 0,
        whp: Number(a.whp),
      })),
      feedApplications: (
        entity.appliedInventoryItems.filter(
          (a) => a.category === InventoryCategory.Feed
        ) as AppliedInventoryItemFeed[]
      ).map((a) => ({
        feed: a.inventoryItemId,
        batch: a.batchId,
        quantity: {
          unit: a.quantity.unit,
          value: a.quantity.value,
        },
      })),
    };

    const response = await prelude.graphql({
      query: CREATE_PADDOCK_ACTION,
      variables: { input },
    });

    const paddockAction = decodePaddockAction(entity.propertyId)(
      response?.data?.addPaddockAction?.paddockAction
    );

    return paddockAction;
  };

// Update

interface EditPaddockActionInput {
  id: string;
  propertyId: string;
  date: any;
  type: string;
  paddockIds?: string[] | null;
  chemicalApplications: EditChemicalApplicationInput[];
  feedApplications?: EditFeedApplicationInput[] | null;
  note: string;
}

interface EditChemicalApplicationInput {
  chemical: string;
  quantity: ValueWithUnitInput;
  esi?: number | null;
  whp?: number | null;
}

interface EditFeedApplicationInput {
  feed: string;
  quantity: ValueWithUnitInput;
}

const EDIT_PADDOCK_ACTION = `
  mutation EditPaddockAction($input: EditPaddockActionInput!) {
    editPaddockAction(input: $input) {
      paddockAction {
        id
        type
        date
        title
        description
        note

        createdBy {
          id
          displayName
          email
        }

        paddocks {
          id
          name
        }

        chemicalApplications {
          id

          esi
          whp

          quantity {
            unit
            value
          }

          batch {
            id
          }

          chemical {
            id
            batchId
            productName
          }
        }

        feedApplications {
          id

          quantity {
            unit
            value
          }

          batch {
            id
          }

          feed {
            id
            name
          }
        }
      }
    }
  }
`;

export const update =
  (prelude: ApiPrelude) =>
  async ({
    updatedEntity,
  }: {
    updatedEntity: PaddockAction;
  }): Promise<PaddockAction> => {
    const input: EditPaddockActionInput = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      date: toISO8601(updatedEntity.date),
      type: updatedEntity.type,
      note: updatedEntity.note ?? '',
      paddockIds: updatedEntity.paddocks.map((p) => p.paddockId),
      chemicalApplications: (
        updatedEntity.appliedInventoryItems.filter(
          (a) => a.category === InventoryCategory.Chemicals
        ) as AppliedInventoryItemChemical[]
      ).map((a) => ({
        chemical: a.inventoryItemId,
        batch: a.batchId,
        quantity: {
          unit: a.quantity.unit,
          value: a.quantity.value,
        },
        esi: 0,
        whp: a.whp,
      })),
      feedApplications: (
        updatedEntity.appliedInventoryItems.filter(
          (a) => a.category === InventoryCategory.Feed
        ) as AppliedInventoryItemFeed[]
      ).map((a) => ({
        feed: a.inventoryItemId,
        batch: a.batchId,
        quantity: {
          unit: a.quantity.unit,
          value: a.quantity.value,
        },
      })),
    };

    const response = await prelude.graphql({
      query: EDIT_PADDOCK_ACTION,
      variables: { input },
    });
    const paddockAction = decodePaddockAction(updatedEntity.propertyId)(
      response?.data?.editPaddockAction?.paddockAction
    );

    return paddockAction;
  };

// Delete

interface DeletePaddockActionInput {
  propertyId: string;
  id: string;
}

const DELETE_PADDOCK_ACTION = `
  mutation DeletePaddockAction($input: DeletePaddockActionInput!) {
    deletePaddockAction(input: $input) {
      paddockAction {
        id
      }
    }
  }
`;

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: PaddockAction }): Promise<void> => {
    const input: DeletePaddockActionInput = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({
      query: DELETE_PADDOCK_ACTION,
      variables: { input },
    });
    return;
  };
