import { availableSortOptions, type Mob } from '@mobble/models/src/model/Mob';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { type FilterPerProperty } from '../lib/filter';
import { thunkGetAll as thunkGetAllHistoricEvents } from './historicEvents';
import { SortPerProperty } from '../lib/sort';
import { thunkGetAll as thunkGetAllNaturalIncreases } from './naturalIncreases';
import { thunkGetAll as thunkGetAllPaddocks } from './paddocks';
import { thunkGetAll as thunkGetAllPaddockGroupedStockingRate } from './paddockGroupedStockingRate';

export interface MobsState {
  extStatus: ExtStatusPerActionPerParent;
  entities: Mob[];
  filter: FilterPerProperty;
  sort: SortPerProperty;
}

export const defaultState: MobsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<MobsState, Mob>({
  name: 'mobs',
  initialState: defaultState,
  defaultSort: availableSortOptions[0]?.settings,
  toParentId: ({ propertyId }: Mob) => propertyId,
  onGetAll: (args, dispatch) =>
    mobbleService.api.mobs.get(args).then(({ entities, historicEvents }) => {
      historicEvents.forEach((payload) => {
        dispatch(
          thunkGetAllHistoricEvents.fulfilled(payload, '', {
            parentId: args.parentId,
            extFilter: { mobId: payload.mobId },
          })
        );
      });
      return { entities };
    }),
  onCreate: mobbleService.api.mobs.create,
  onUpdate: mobbleService.api.mobs.update,
  onDelete: mobbleService.api.mobs.remove,
  afterMutation: ({ entity }, dispatch) => {
    dispatch(thunkGetAllPaddocks({ parentId: entity.propertyId }));
    dispatch(
      thunkGetAllPaddockGroupedStockingRate({
        parentId: entity.propertyId,
      })
    );
    dispatch(thunkGetAllNaturalIncreases({ parentId: entity.propertyId }));

    return Promise.resolve();
  },
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
