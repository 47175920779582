import React from 'react';
import { useI18n } from '@mobble/i18n';
import { sortAnyContainingPaddocks } from '@mobble/models/src/model/Paddock';
import { findPaddockCenterPoint } from '@mobble/models/src/model/PaddockGeometry';
import { usePaddock, usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useExtStatus } from '@mobble/store/src/hooks/root';
import { usePaddockGroups } from '@mobble/store/src/hooks/paddockGroups';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { usePaddockGeometries } from '@mobble/store/src/hooks/paddockGeometries';
import { ScreenHeader } from '../../../stories/Views/Misc/ScreenHeader';
import * as ROUTE_NAME from '../../config/routeNames';
import {
  PaddockGroupCreateForm,
  PaddockGroupCreateFormValues,
} from '../../../stories/Views/Paddock/PaddockGroup/PaddockGroupCreateForm';
import { EntitySliceFactoryPrelude } from '../../../stories/Views/Misc/EntitySliceFactoryPrelude';
import { usePaddockCardFactory } from '../../../hooks/usePaddockCardFactory';
import { ScreenRendererProps } from '@src/screens/config/types';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useFormError } from '@src/hooks/useFormError';

export const PaddockGroupCreateHeader: React.FC<ScreenRendererProps> = () => {
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      title={{
        key: 'screen.title.paddock_group.create',
      }}
      breadcrumbs={[
        {
          title: { key: 'screen.title.paddocks' },
          href: ROUTE_NAME.PADDOCKS_LIST,
        },
      ]}
      onClose={goBack}
    />
  );
};

export const PaddockGroupCreate: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const { paddockId } = route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const paddock = usePaddock(paddockId);
  const paddocks = usePaddocks(properties.selected?.id);
  const paddockGroups = usePaddockGroups(properties.selected?.id);
  const paddockGeometries = usePaddockGeometries(properties.selected?.id);
  const paddockOrigin = findPaddockCenterPoint(paddockGeometries.entities)(
    paddockId
  );
  const extStatus = useExtStatus(
    'paddockGroups',
    'create',
    properties.selected?.id
  );
  const formLoading = extStatus?.loading;
  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Paddock group',
      description: 'screen.title.paddock_group_detail',
    }),
    active: Boolean(extStatus?.error),
  });

  const makePaddockCard = usePaddockCardFactory({
    propertyId: properties.selected?.id,
    location: paddockOrigin,
  });

  const paddockSortMeta = {
    origin: paddockOrigin,
    paddockGeometries: paddockGeometries.entities,
    mobs: [],
  };

  const sortPaddockOptions = sortAnyContainingPaddocks((o) => o.entity)(
    paddockSortMeta
  );

  const paddockOptions = paddocks.entities.map((p) => ({
    label: p.name,
    value: p.id,
    entity: p,
    component: makePaddockCard(p),
  }));

  const prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      paddocks.prelude,
      paddock.prelude,
      paddockGroups.prelude,
      paddockGeometries.prelude,
    ],
    required: [properties.selected],
  });

  if (prelude) {
    return prelude;
  }

  const handleSubmit = (formValues: PaddockGroupCreateFormValues) => {
    paddockGroups
      .create({
        paddockIds: formValues.paddocks,
        propertyId: properties?.selected?.id || '',
      })
      .then((paddockGroup) => {
        // TODO: replace with Toast/Alert
        console.log(`Paddock group successfully created`);
        console.log(paddockGroup);
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PaddockGroupCreateForm
      paddockOptions={paddockOptions}
      sortPaddockOptions={sortPaddockOptions}
      paddock={paddock.entity}
      error={formError}
      loading={formLoading}
      onCancel={goBack}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_PADDOCK_GROUP_CREATE,
  header: PaddockGroupCreateHeader,
  component: PaddockGroupCreate,
};
