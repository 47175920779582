import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { I18nProvider } from '@mobble/i18n';
import logger from '@mobble/shared/src/core/Logger';
import { useAuthStateListener } from '@mobble/shared/src/hooks/useAuthStateListener';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { useSettings } from '@mobble/store/src/hooks/settings';

import './service';

import './service';

import { DialogProvider } from './context/dialog';
import { SettingsContext } from './context/settings';
import { useNetworkInformation } from './hooks/useNetworkInformation';
import { useVersion } from './hooks/useVersion';
import { Main } from './screens/Main';
import { LoadingScreen } from './stories/Views/Misc/LoadingScreen';
import { PrinterProvider } from './stories/Views/Misc/Printer';
import { ScrollRestore } from './stories/Views/Misc/ScrollRestore';
import { persistor, store } from './store';

const StoredApp = () => {
  useNetworkInformation();
  useAuthStateListener({
    purge: persistor.purge,
  });
  const version = useVersion();
  logger.info('App version:', JSON.stringify(version));

  return <Main />;
};

const SettingsProvider = ({ children }) => {
  const { settings } = useSettings();

  return (
    <SettingsContext.Provider value={{ ...settings }}>
      {children}
    </SettingsContext.Provider>
  );
};

const IntlApp = () => {
  const user = useGetUser();

  return (
    <I18nProvider
      userLocale={user?.locale || navigator.language}
      defaultRichTextElements={{
        b: (chunks) => <span style={{ fontWeight: 'bold' }}>{chunks}</span>,
      }}
    >
      <DialogProvider>
        <SettingsProvider>
          <PersistGate
            persistor={persistor}
            loading={<LoadingScreen fullScreen />}
          >
            <PrinterProvider>
              <ScrollRestore>
                <StoredApp />
              </ScrollRestore>
            </PrinterProvider>
          </PersistGate>
        </SettingsProvider>
      </DialogProvider>
    </I18nProvider>
  );
};

export const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <IntlApp />
      </Provider>
    </BrowserRouter>
  );
};
