import React from 'react';
import { type I18nItem } from '@mobble/i18n';
import { Box } from '../../Components/Layout/Box';
import { Color } from '@mobble/colors';
import { HStack, VStack } from '../../Components/Layout/Stack';
import { Text } from '../../Components/UI/Text';
import { Icon, type IconName } from '../../Components/UI/Icon';
import { Spacer } from '../../Components/Layout/Spacer';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import styles from './bottomMenuTabs.scss';

export type BottomMenuTabsProps = {
  items: BottomMenuTabsItem[];
  actionButton: React.ReactNode;
  onNavigate: (path: string, params?: Record<string, string | number>) => void;
};

export interface BottomMenuTabsItem {
  label: I18nItem;
  routeName: string;
  icon: IconName;
  isActive?: boolean;
}

export const BottomMenuTabs: React.FC<BottomMenuTabsProps> = ({
  items,
  actionButton,
  onNavigate,
}) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const itemsNumber = items.length === 2 ? 2 : actionButton ? 5 : 4;

  const indicatorWidth = width / itemsNumber;
  const index = items.findIndex((a) => a.isActive);

  React.useEffect(() => {
    const setWindowWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', setWindowWidth);

    return () => {
      window.removeEventListener('resize', setWindowWidth);
    };
  }, []);

  const TabBarNavigationItem: React.FC<{ item: BottomMenuTabsItem }> = ({
    item,
  }) => {
    const color = item?.isActive ? Color.Green : Color.DarkGrey;
    const onClick = () => {
      onNavigate(item.routeName);
    };

    return (
      <Box flex key={item.routeName}>
        <Clickable href={onClick} className={styles.itemHitArea}>
          <VStack alignment="center">
            <Spacer y={2} />
            <Icon size="normal" name={item.icon} color={color} />
            <Spacer y={1} />
            <Text bold variant="tiny" color={color} i18n={item.label} />
          </VStack>
        </Clickable>
      </Box>
    );
  };

  const activeIndicatorStyle = {
    width: indicatorWidth,
    transform: actionButton
    ? `translateX(${indicatorWidth * (index >= 2 ? index + 1 : index)}px)`
    : `translateX(${indicatorWidth * index}px)`,
  };

  return (
    <Box className={styles.container}>
      <Box
        className={styles.animatedIndicator}
        style={activeIndicatorStyle}
      />
      <Box className={styles.inner}>
        <HStack>
          {items.length === 2 ? (
            <>
              <TabBarNavigationItem item={items[0]} />
              {actionButton ? <Box flex>{actionButton}</Box> : null}
              <TabBarNavigationItem item={items[1]} />
            </>
          ) : (
            <>
              <TabBarNavigationItem item={items[0]} />
              <TabBarNavigationItem item={items[1]} />
              {actionButton ? <Box flex>{actionButton}</Box> : null}
              <TabBarNavigationItem item={items[2]} />
              <TabBarNavigationItem item={items[3]} />
            </>
          )}
        </HStack>
      </Box>
    </Box>
  );
};
