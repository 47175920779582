import Activity from './activity.svg';
import Airplay from './airplay.svg';
import AlertCircle from './alert-circle.svg';
import AlertOctagon from './alert-octagon.svg';
import AlertTriangle from './alert-triangle.svg';
import AlignCenter from './align-center.svg';
import AlignJustify from './align-justify.svg';
import AlignLeft from './align-left.svg';
import AlignRight from './align-right.svg';
import Anchor from './anchor.svg';
import Aperture from './aperture.svg';
import Archive from './archive.svg';
import ArrowDownCircle from './arrow-down-circle.svg';
import ArrowDownLeft from './arrow-down-left.svg';
import ArrowDownRight from './arrow-down-right.svg';
import ArrowDown from './arrow-down.svg';
import ArrowLeftCircle from './arrow-left-circle.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRightCircle from './arrow-right-circle.svg';
import ArrowRight from './arrow-right.svg';
import ArrowUpCircle from './arrow-up-circle.svg';
import ArrowUpLeft from './arrow-up-left.svg';
import ArrowUpRight from './arrow-up-right.svg';
import ArrowUp from './arrow-up.svg';
import AtSign from './at-sign.svg';
import Award from './award.svg';
import BarChart2 from './bar-chart-2.svg';
import BarChart from './bar-chart.svg';
import BatteryCharging from './battery-charging.svg';
import Battery from './battery.svg';
import BellOff from './bell-off.svg';
import Bell from './bell.svg';
import Bluetooth from './bluetooth.svg';
import Bold from './bold.svg';
import BookOpen from './book-open.svg';
import Book from './book.svg';
import Bookmark from './bookmark.svg';
import Box from './box.svg';
import Briefcase from './briefcase.svg';
import Calendar from './calendar.svg';
import CameraOff from './camera-off.svg';
import Camera from './camera.svg';
import Cast from './cast.svg';
import CheckCircle from './check-circle.svg';
import CheckSquare from './check-square.svg';
import Check from './check.svg';
import ChevronDown from './chevron-down.svg';
import ChevronLeft from './chevron-left.svg';
import ChevronRight from './chevron-right.svg';
import ChevronUp from './chevron-up.svg';
import ChevronsDown from './chevrons-down.svg';
import ChevronsLeft from './chevrons-left.svg';
import ChevronsRight from './chevrons-right.svg';
import ChevronsUp from './chevrons-up.svg';
import Chrome from './chrome.svg';
import Circle from './circle.svg';
import Clipboard from './clipboard.svg';
import Clock from './clock.svg';
import CloudDrizzle from './cloud-drizzle.svg';
import CloudLightning from './cloud-lightning.svg';
import CloudOff from './cloud-off.svg';
import CloudRain from './cloud-rain.svg';
import CloudSnow from './cloud-snow.svg';
import Cloud from './cloud.svg';
import Code from './code.svg';
import Codepen from './codepen.svg';
import Codesandbox from './codesandbox.svg';
import Coffee from './coffee.svg';
import Columns from './columns.svg';
import Command from './command.svg';
import Compass from './compass.svg';
import Copy from './copy.svg';
import CornerDownLeft from './corner-down-left.svg';
import CornerDownRight from './corner-down-right.svg';
import CornerLeftDown from './corner-left-down.svg';
import CornerLeftUp from './corner-left-up.svg';
import CornerRightDown from './corner-right-down.svg';
import CornerRightUp from './corner-right-up.svg';
import CornerUpLeft from './corner-up-left.svg';
import CornerUpRight from './corner-up-right.svg';
import Cpu from './cpu.svg';
import CreditCard from './credit-card.svg';
import Crop from './crop.svg';
import Crosshair from './crosshair.svg';
import Database from './database.svg';
import Delete from './delete.svg';
import Disc from './disc.svg';
import DivideCircle from './divide-circle.svg';
import DivideSquare from './divide-square.svg';
import Divide from './divide.svg';
import DollarSign from './dollar-sign.svg';
import DownloadCloud from './download-cloud.svg';
import Download from './download.svg';
import Dribbble from './dribbble.svg';
import Droplet from './droplet.svg';
import Edit2 from './edit-2.svg';
import Edit3 from './edit-3.svg';
import Edit from './edit.svg';
import ExternalLink from './external-link.svg';
import EyeOff from './eye-off.svg';
import Eye from './eye.svg';
import Facebook from './facebook.svg';
import FastForward from './fast-forward.svg';
import Feather from './feather.svg';
import Figma from './figma.svg';
import FileMinus from './file-minus.svg';
import FilePlus from './file-plus.svg';
import FileText from './file-text.svg';
import File from './file.svg';
import Film from './film.svg';
import Filter from './filter.svg';
import Flag from './flag.svg';
import FolderMinus from './folder-minus.svg';
import FolderPlus from './folder-plus.svg';
import Folder from './folder.svg';
import Framer from './framer.svg';
import Frown from './frown.svg';
import Gift from './gift.svg';
import GitBranch from './git-branch.svg';
import GitCommit from './git-commit.svg';
import GitMerge from './git-merge.svg';
import GitPullRequest from './git-pull-request.svg';
import Github from './github.svg';
import Gitlab from './gitlab.svg';
import Globe from './globe.svg';
import Grid from './grid.svg';
import HardDrive from './hard-drive.svg';
import Hash from './hash.svg';
import Headphones from './headphones.svg';
import Heart from './heart.svg';
import HelpCircle from './help-circle.svg';
import Hexagon from './hexagon.svg';
import Home from './home.svg';
import Image from './image.svg';
import Inbox from './inbox.svg';
import Info from './info.svg';
import Instagram from './instagram.svg';
import Italic from './italic.svg';
import Key from './key.svg';
import Layers from './layers.svg';
import Layout from './layout.svg';
import LifeBuoy from './life-buoy.svg';
import Link2 from './link-2.svg';
import Link from './link.svg';
import Linkedin from './linkedin.svg';
import List from './list.svg';
import Loader from './loader.svg';
import Lock from './lock.svg';
import LogIn from './log-in.svg';
import LogOut from './log-out.svg';
import Mail from './mail.svg';
import MapPin from './map-pin.svg';
import Map from './map.svg';
import Maximize2 from './maximize-2.svg';
import Maximize from './maximize.svg';
import Meh from './meh.svg';
import Menu from './menu.svg';
import Message from './message.svg';
import MessageCircle from './message-circle.svg';
import MessageSquare from './message-square.svg';
import MicOff from './mic-off.svg';
import Mic from './mic.svg';
import Minimize2 from './minimize-2.svg';
import Minimize from './minimize.svg';
import MinusCircle from './minus-circle.svg';
import MinusSquare from './minus-square.svg';
import Minus from './minus.svg';
import Monitor from './monitor.svg';
import Moon from './moon.svg';
import MoreHorizontal from './more-horizontal.svg';
import MoreVertical from './more-vertical.svg';
import MousePointer from './mouse-pointer.svg';
import Move from './move.svg';
import Music from './music.svg';
import Navigation2 from './navigation-2.svg';
import Navigation from './navigation.svg';
import Octagon from './octagon.svg';
import Package from './package.svg';
import Paperclip from './paperclip.svg';
import PauseCircle from './pause-circle.svg';
import Pause from './pause.svg';
import PenTool from './pen-tool.svg';
import Percent from './percent.svg';
import PhoneCall from './phone-call.svg';
import PhoneForwarded from './phone-forwarded.svg';
import PhoneIncoming from './phone-incoming.svg';
import PhoneMissed from './phone-missed.svg';
import PhoneOff from './phone-off.svg';
import PhoneOutgoing from './phone-outgoing.svg';
import Phone from './phone.svg';
import PieChart from './pie-chart.svg';
import PlayCircle from './play-circle.svg';
import Play from './play.svg';
import PlusCircle from './plus-circle.svg';
import PlusSquare from './plus-square.svg';
import Plus from './plus.svg';
import Pocket from './pocket.svg';
import Power from './power.svg';
import Printer from './printer.svg';
import Radio from './radio.svg';
import RefreshCcw from './refresh-ccw.svg';
import RefreshCw from './refresh-cw.svg';
import Repeat from './repeat.svg';
import Rewind from './rewind.svg';
import RotateCcw from './rotate-ccw.svg';
import RotateCw from './rotate-cw.svg';
import Rss from './rss.svg';
import Save from './save.svg';
import Scissors from './scissors.svg';
import Search from './search.svg';
import Send from './send.svg';
import Server from './server.svg';
import Settings from './settings.svg';
import Share2 from './share-2.svg';
import Share from './share.svg';
import ShieldOff from './shield-off.svg';
import Shield from './shield.svg';
import ShoppingBag from './shopping-bag.svg';
import ShoppingCart from './shopping-cart.svg';
import Shuffle from './shuffle.svg';
import Sidebar from './sidebar.svg';
import SkipBack from './skip-back.svg';
import SkipForward from './skip-forward.svg';
import Slack from './slack.svg';
import Slash from './slash.svg';
import Sliders from './sliders.svg';
import Smartphone from './smartphone.svg';
import Smile from './smile.svg';
import Speaker from './speaker.svg';
import Square from './square.svg';
import Star from './star.svg';
import StopCircle from './stop-circle.svg';
import Sun from './sun.svg';
import Sunrise from './sunrise.svg';
import Sunset from './sunset.svg';
import Table from './table.svg';
import Tablet from './tablet.svg';
import Tag from './tag.svg';
import Target from './target.svg';
import Terminal from './terminal.svg';
import Thermometer from './thermometer.svg';
import ThumbsDown from './thumbs-down.svg';
import ThumbsUp from './thumbs-up.svg';
import ToggleLeft from './toggle-left.svg';
import ToggleRight from './toggle-right.svg';
import Tool from './tool.svg';
import Trash2 from './trash-2.svg';
import Trash from './trash.svg';
import Trello from './trello.svg';
import TrendingDown from './trending-down.svg';
import TrendingUp from './trending-up.svg';
import Triangle from './triangle.svg';
import Truck from './truck.svg';
import Tv from './tv.svg';
import Twitch from './twitch.svg';
import Twitter from './twitter.svg';
import Type from './type.svg';
import Umbrella from './umbrella.svg';
import Underline from './underline.svg';
import Unlock from './unlock.svg';
import UploadCloud from './upload-cloud.svg';
import Upload from './upload.svg';
import UserCheck from './user-check.svg';
import UserMinus from './user-minus.svg';
import UserPlus from './user-plus.svg';
import UserX from './user-x.svg';
import User from './user.svg';
import Users from './users.svg';
import VideoOff from './video-off.svg';
import Video from './video.svg';
import Voicemail from './voicemail.svg';
import Volume1 from './volume-1.svg';
import Volume2 from './volume-2.svg';
import VolumeX from './volume-x.svg';
import Volume from './volume.svg';
import Watch from './watch.svg';
import WifiOff from './wifi-off.svg';
import Wifi from './wifi.svg';
import Wind from './wind.svg';
import XCircle from './x-circle.svg';
import XOctagon from './x-octagon.svg';
import XSquare from './x-square.svg';
import X from './x.svg';
import Youtube from './youtube.svg';
import ZapOff from './zap-off.svg';
import Zap from './zap.svg';
import ZoomIn from './zoom-in.svg';
import ZoomOut from './zoom-out.svg';

export default {
  Activity,
  Airplay,
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Anchor,
  Aperture,
  Archive,
  ArrowDownCircle,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowDown,
  ArrowLeftCircle,
  ArrowLeft,
  ArrowRightCircle,
  ArrowRight,
  ArrowUpCircle,
  ArrowUpLeft,
  ArrowUpRight,
  ArrowUp,
  AtSign,
  Award,
  BarChart2,
  BarChart,
  BatteryCharging,
  Battery,
  BellOff,
  Bell,
  Bluetooth,
  Bold,
  BookOpen,
  Book,
  Bookmark,
  Box,
  Briefcase,
  Calendar,
  CameraOff,
  Camera,
  Cast,
  CheckCircle,
  CheckSquare,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsDown,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUp,
  Chrome,
  Circle,
  Clipboard,
  Clock,
  CloudDrizzle,
  CloudLightning,
  CloudOff,
  CloudRain,
  CloudSnow,
  Cloud,
  Code,
  Codepen,
  Codesandbox,
  Coffee,
  Columns,
  Command,
  Compass,
  Copy,
  CornerDownLeft,
  CornerDownRight,
  CornerLeftDown,
  CornerLeftUp,
  CornerRightDown,
  CornerRightUp,
  CornerUpLeft,
  CornerUpRight,
  Cpu,
  CreditCard,
  Crop,
  Crosshair,
  Database,
  Delete,
  Disc,
  DivideCircle,
  DivideSquare,
  Divide,
  DollarSign,
  DownloadCloud,
  Download,
  Dribbble,
  Droplet,
  Edit2,
  Edit3,
  Edit,
  ExternalLink,
  EyeOff,
  Eye,
  Facebook,
  FastForward,
  Feather,
  Figma,
  FileMinus,
  FilePlus,
  FileText,
  File,
  Film,
  Filter,
  Flag,
  FolderMinus,
  FolderPlus,
  Folder,
  Framer,
  Frown,
  Gift,
  GitBranch,
  GitCommit,
  GitMerge,
  GitPullRequest,
  Github,
  Gitlab,
  Globe,
  Grid,
  HardDrive,
  Hash,
  Headphones,
  Heart,
  HelpCircle,
  Hexagon,
  Home,
  Image,
  Inbox,
  Info,
  Instagram,
  Italic,
  Key,
  Layers,
  Layout,
  LifeBuoy,
  Link2,
  Link,
  Linkedin,
  List,
  Loader,
  Lock,
  LogIn,
  LogOut,
  Mail,
  MapPin,
  Map,
  Maximize2,
  Maximize,
  Meh,
  Menu,
  Message,
  MessageCircle,
  MessageSquare,
  MicOff,
  Mic,
  Minimize2,
  Minimize,
  MinusCircle,
  MinusSquare,
  Minus,
  Monitor,
  Moon,
  MoreHorizontal,
  MoreVertical,
  MousePointer,
  Move,
  Music,
  Navigation2,
  Navigation,
  Octagon,
  Package,
  Paperclip,
  PauseCircle,
  Pause,
  PenTool,
  Percent,
  PhoneCall,
  PhoneForwarded,
  PhoneIncoming,
  PhoneMissed,
  PhoneOff,
  PhoneOutgoing,
  Phone,
  PieChart,
  PlayCircle,
  Play,
  PlusCircle,
  PlusSquare,
  Plus,
  Pocket,
  Power,
  Printer,
  Radio,
  RefreshCcw,
  RefreshCw,
  Repeat,
  Rewind,
  RotateCcw,
  RotateCw,
  Rss,
  Save,
  Scissors,
  Search,
  Send,
  Server,
  Settings,
  Share2,
  Share,
  ShieldOff,
  Shield,
  ShoppingBag,
  ShoppingCart,
  Shuffle,
  Sidebar,
  SkipBack,
  SkipForward,
  Slack,
  Slash,
  Sliders,
  Smartphone,
  Smile,
  Speaker,
  Square,
  Star,
  StopCircle,
  Sun,
  Sunrise,
  Sunset,
  Table,
  Tablet,
  Tag,
  Target,
  Terminal,
  Thermometer,
  ThumbsDown,
  ThumbsUp,
  ToggleLeft,
  ToggleRight,
  Tool,
  Trash2,
  Trash,
  Trello,
  TrendingDown,
  TrendingUp,
  Triangle,
  Truck,
  Tv,
  Twitch,
  Twitter,
  Type,
  Umbrella,
  Underline,
  Unlock,
  UploadCloud,
  Upload,
  UserCheck,
  UserMinus,
  UserPlus,
  UserX,
  User,
  Users,
  VideoOff,
  Video,
  Voicemail,
  Volume1,
  Volume2,
  VolumeX,
  Volume,
  Watch,
  WifiOff,
  Wifi,
  Wind,
  XCircle,
  XOctagon,
  XSquare,
  X,
  Youtube,
  ZapOff,
  Zap,
  ZoomIn,
  ZoomOut,
};
