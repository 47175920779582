import { generatePath, matchPath } from 'react-router-dom';

export const toPath = (
  route: string,
  params?: Record<string, string | number>
) => {
  // eslint-disable-next-line prefer-const
  let [path, hash] = route.split('#');
  try {
    // match with self to get a set of default params
    const m = matchPath(path, path);
    const baseParams = Object.keys(m.params).reduce(
      (acc, key) => ({ ...acc, [key]: '_' }),
      {}
    );

    path = generatePath(path, { ...baseParams, ...params });
    path = path.replace(/(\/_+)+$/, '');
  } catch (e) {
    console.log(e);
  }

  if (hash || params?.mode) {
    path += `#${hash || params?.mode}`;
  }

  return path;
};
