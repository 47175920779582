import { Color } from '@mobble/theme';

export const getDrawStyles = () => {
  return [
    // ACTIVE (being drawn)
    // line stroke - line
    {
      id: 'gl-draw-line',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': Color.LightBlue,
        'line-dasharray': [0.2, 2],
        'line-width': 2,
      },
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
      id: 'gl-draw-polygon-stroke',
      type: 'line',
      filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': Color.LightBlue,
        'line-dasharray': [0.2, 2],
        'line-width': 2,
      },
    },
    // polygon fill - area_fill
    {
      id: 'gl-draw-polygon-fill',
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      paint: {
        'fill-color': Color.LightBlue,
        'fill-opacity': 0.2,
      },
    },
    // polygon mid points
    {
      id: 'gl-draw-polygon-midpoint',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
      paint: {
        'circle-radius': 3,
        'circle-color': Color.Orange,
      },
    },
    // vertex point halos (corners)
    {
      id: 'gl-draw-polygon-and-line-vertex-halo',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 8,
        'circle-color': Color.Blue,
      },
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-halo-active',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['==', 'active', 'true'],
      ],
      paint: {
        'circle-radius': 8,
        'circle-color': Color.Orange,
      },
    },
    // vertex points (corners)
    {
      id: 'gl-draw-polygon-and-line-vertex',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 5,
        'circle-color': Color.White,
      },
    },

    // single point halo
    {
      id: 'gl-draw-single-point-halo',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['!=', 'meta', 'midpoint']],
      paint: {
        'circle-radius': 8,
        'circle-color': Color.Blue,
      },
    },
    // single point halo active
    {
      id: 'gl-draw-single-point-halo-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'active', 'true'],
        ['!=', 'meta', 'midpoint'],
      ],
      paint: {
        'circle-radius': 8,
        'circle-color': Color.Orange,
      },
    },
    // single point
    {
      id: 'gl-draw-single-point',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['!=', 'meta', 'midpoint']],
      paint: {
        'circle-radius': 5,
        'circle-color': Color.White,
      },
    },

    // INACTIVE (static, already drawn)
    // line stroke
    {
      id: 'gl-draw-line-static',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': Color.White,
        'line-width': 3,
      },
    },
    // polygon fill
    {
      id: 'gl-draw-polygon-fill-static',
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
      paint: {
        'fill-color': Color.Black,
        'fill-outline-color': Color.Black,
        'fill-opacity': 0.1,
      },
    },
    // polygon outline
    {
      id: 'gl-draw-polygon-stroke-static',
      type: 'line',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': Color.White,
        'line-width': 3,
      },
    },
  ];
};
