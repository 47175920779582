import React from 'react';
import { formatDate } from '@mobble/shared/src/core/Date';
import { roundNumber } from '@mobble/shared/src/core/Number';
import { type PropertyStockingRate } from '@mobble/service/src/ext/property-stocking-rates';
import { Mode } from './LivestockHistoricGraph';
import { useI18n } from '@mobble/i18n/src';
import { chartLivestockColors } from '@src/stories/Components/Charts/config';
import { PaddockStockingRate } from '@mobble/models/src/model/PaddockStockingRate';
import { useSetting } from '@mobble/store/src/hooks';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';

const SORT_ORDER_LIVESTOCK_TYPE = ['sheep', 'cattle', 'goats', 'other'];

export const useConvertStockingRatesData = ({
  year = 0,
  stockingRates,
  mode,
}: {
  year?: number;
  stockingRates: PropertyStockingRate[] | PaddockStockingRate[];
  mode: Mode;
}) => {
  const { data, dataAverage, dataAverageByYmd, maxY } = React.useMemo(() => {
    const dataAverage = [];

    const stockingRatesForYear =
      year === 0
        ? stockingRates
        : stockingRates.filter((a) => a.dateFormatted.startsWith(`${year}`));

    const stockingRatesForYearByDate = stockingRatesForYear.reduce<
      Record<string, PropertyStockingRate>
    >((acc, a) => {
      if (!acc[a.dateFormatted]) {
        acc[a.dateFormatted] = a;
      } else {
        acc[a.dateFormatted] = mergePropertyStockingRates(
          acc[a.dateFormatted],
          a
        );
      }
      return acc;
    }, {});

    let maxY = 0;
    const livestockTypes =
      stockingRates.length > 0
        ? livestockTypesOnPropertyStockingRate(stockingRates[0])
        : [];

    const dataObj = Object.entries(stockingRatesForYearByDate).reduce(
      (acc, [ymd, sr]) => {
        let total = 0;
        livestockTypes.forEach((lt) => {
          acc[lt] = acc[lt] ?? [];
          const y = Number(roundNumber(sr.byLivestockType[lt][mode]));
          total += y;

          acc[lt].push({ x: ymd, y });
        });

        maxY = Math.max(maxY, total);

        if (sr.rollingAverage[mode]) {
          const y = Number(roundNumber(sr.rollingAverage[mode]));
          maxY = Math.max(maxY, y);

          dataAverage.push({
            ymd,
            x: new Date(ymd),
            y: y,
          });
        }

        return acc;
      },
      {}
    );

    const data = Object.entries(dataObj)
      .map(([k, v], i) => ({
        id: `${k}`,
        color: chartLivestockColors[i % chartLivestockColors.length],
        data: v as {
          x: string;
          y: number;
        }[],
      }))
      .sort((a, b) => {
        const aIndex = SORT_ORDER_LIVESTOCK_TYPE.indexOf(a.id);
        const bIndex = SORT_ORDER_LIVESTOCK_TYPE.indexOf(b.id);
        return bIndex - aIndex;
      })
      .filter((a: any) => a?.data?.reduce((acc, b) => acc + b.y, 0) > 0);

    const dataAverageByYmd = dataAverage.reduce(
      (acc, da) => ({
        ...acc,
        [formatDate(da.ymd, 'DD-MM-YYYY')]: da,
      }),
      {}
    );

    return { data, dataAverage, dataAverageByYmd, maxY };
  }, [stockingRates, year, mode]);

  return { data, dataAverage, dataAverageByYmd, maxY };
};

const livestockTypesOnPropertyStockingRate = (
  paddockStockingRate: PropertyStockingRate
): string[] => {
  return Object.keys(paddockStockingRate.byLivestockType);
};

const mergePropertyStockingRates = (
  a: PropertyStockingRate,
  b: PropertyStockingRate
): PropertyStockingRate => {
  const livestockTypes = [
    ...new Set([
      ...livestockTypesOnPropertyStockingRate(a),
      ...livestockTypesOnPropertyStockingRate(b),
    ]),
  ];

  return {
    ...a,
    byLivestockType: livestockTypes.reduce(
      (acc, lt) => ({
        ...acc,
        [lt]: {
          head:
            (a.byLivestockType[lt]?.head ?? 0) +
            (b.byLivestockType[lt]?.head ?? 0),
          DSE:
            (a.byLivestockType[lt]?.DSE ?? 0) +
            (b.byLivestockType[lt]?.DSE ?? 0),
          areaDSE:
            (a.byLivestockType[lt]?.areaDSE ?? 0) +
            (b.byLivestockType[lt]?.areaDSE ?? 0),
        },
      }),
      {}
    ),

    rollingAverage: {
      DSE: (a.rollingAverage.DSE ?? 0) + (b.rollingAverage.DSE ?? 0),
      areaDSE:
        (a.rollingAverage.areaDSE ?? 0) + (b.rollingAverage.areaDSE ?? 0),
    },
  };
};

//
//
//

const curYear = new Date().getFullYear();

export const useYearSelector = (years: number[]) => {
  const { translate } = useI18n();

  const [year, setYear] = React.useState<number>(curYear);

  const yearOptions = years.map((value) => {
    return {
      value,
      label: String(value),
      selected: value === year,
    };
  });
  yearOptions.unshift({
    label: translate({ key: 'chart.livestock-totals.option.all' }),
    value: 0,
    selected: year === 0,
  });

  return { year, setYear, yearOptions };
};

export const useConvertStockingRatesDataUnits =
  (stockingRateData: any) => (mode: any) => {
    const stockingUnit = useSetting('stockingUnit') as StockingUnit;
    const convertStockingRate = (stockingRate: number) =>
      formatStockingRate(stockingRate, stockingUnit);

    const isDSEModes = ['stocking_rate', 'area_stocking_rate'].includes(mode);

    const data = isDSEModes
      ? stockingRateData.data.map((i) => {
          return {
            ...i,
            data: i?.data?.map((d) => {
              return {
                ...d,
                y: convertStockingRate(d.y),
              };
            }),
          };
        })
      : stockingRateData.data;

    const dataAverage = isDSEModes
      ? stockingRateData?.dataAverage?.map((i) => {
          return {
            ...i,
            y: convertStockingRate(i.y),
          };
        })
      : stockingRateData.dataAverage;

    const dataArverateByYmdArray = Object.entries(
      stockingRateData?.dataAverageByYmd
    ).map(([_, value]) => {
      const { x, y, ymd } = value as any;

      return {
        x,
        y: convertStockingRate(y),
        ymd,
      };
    });
    const dataAverageByYmd = dataArverateByYmdArray
      ? dataArverateByYmdArray.reduce(
          (acc, da) =>
            da
              ? {
                  ...acc,
                  [da.ymd]: da,
                }
              : {},
          {}
        )
      : {};

    const maxY = isDSEModes
      ? convertStockingRate(stockingRateData.maxY)
      : stockingRateData.maxY;

    return { data, dataAverage, dataAverageByYmd, maxY };
  };
