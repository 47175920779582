import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  type Inventory,
  inventoryCategoryToColor,
} from '@mobble/models/src/model/Inventory';
import { Card } from '@src/stories/Components/Layout/Card';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';

interface InventoryCardProps {
  inventory: Inventory;
  activeProductsCount: number;
  onClick: (inventory: Inventory) => void;
}

export const InventoryCard: React.FC<InventoryCardProps> = ({
  inventory,
  activeProductsCount,
  onClick,
}) => {
  const { formatMessage } = useI18n();

  return (
    <Clickable fullWidth href={() => onClick(inventory)}>
      <Card
        title={inventory.name}
        color={inventoryCategoryToColor(inventory.category)}
      >
        <Spacer y={0.5} />
        <Text
          i18n={formatMessage(
            {
              description:
                'inventories.list.inventory_item.card.active_products.label',
              defaultMessage: 'Active products: <b>{VALUE}</b>',
            },
            {
              VALUE: String(activeProductsCount),
            }
          )}
        />
        <Spacer y={1.5} />
        {inventory.shared && (
          <HStack>
            <Icon size="small" name="farm" />
            <Spacer x={1} />
            <Text variant="small">{inventory.shared.propertyName}</Text>
          </HStack>
        )}
      </Card>
    </Clickable>
  );
};
