import React from 'react';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { type QuantityOfMassVolume } from '@mobble/shared/src/core/Quantity';
import {
  InventoryCategory,
  INVENTORY_TYPE,
} from '@mobble/models/src/model/Inventory';
import {
  InventoryItem,
  InventoryItemFeed,
  InventoryItemChemical,
} from '@mobble/models/src/model/InventoryItem';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface InventoryItemEditFormProps<T extends InventoryItem> {
  inventoryItem: T;
  inventoryType?:
    | INVENTORY_TYPE.ANIMAL_HEALTH
    | INVENTORY_TYPE.HERBICIDE_PESTICIDE;
  error?: string;
  loading?: boolean;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (inventoryItem: T) => void;
}

export const InventoryItemEditForm: React.FC<
  InventoryItemEditFormProps<InventoryItem>
> = ({ inventoryItem, ...props }) => {
  switch (inventoryItem.category) {
    case InventoryCategory.Chemicals:
      return (
        <InventoryItemChemicalsEditForm
          inventoryItem={inventoryItem}
          {...props}
        />
      );
    case InventoryCategory.Feed:
      return (
        <InventoryItemFeedEditForm inventoryItem={inventoryItem} {...props} />
      );
  }
};

export interface InventoryItemChemicalsEditFormValues {
  name: string;
  batchId: string;
  quantity: QuantityOfMassVolume;
  whp: number;
  esi?: number;
  woolWhp?: number;
  dateReceived: string;
  expiryDate: string;
  dateOfManufacture: string;
  notes: string;
}

const InventoryItemChemicalsEditForm: React.FC<
  InventoryItemEditFormProps<InventoryItemChemical>
> = ({
  inventoryItem,
  inventoryType,
  error,
  loading,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const handleSubmit = (formValues: InventoryItemChemicalsEditFormValues) => {
    onSubmit({
      ...inventoryItem,
      name: formValues.name,
      notes: formValues.notes,
      quantity: formValues.quantity,
      whp: formValues.whp,
      esi: formValues.esi ?? 0,
      woolWhp: formValues.woolWhp ?? 0,
      dateReceived: formValues.dateReceived,
    });
  };

  const form: FormBuilderProps<InventoryItemChemicalsEditFormValues> = {
    i18nRootKey: 'inventory_items.inventory_item.chemicals.edit.form',
    flex: true,
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
        initialValue: inventoryItem.name,
      },
      {
        name: 'quantity',
        type: 'quantity-mass-volume',
        initialValue: inventoryItem.quantity,
        required: true,
      },
      {
        name: 'whp',
        type: 'number',
        initialValue: inventoryItem.whp,
        min: 0,
      },
      {
        name: 'esi',
        type: 'number',
        initialValue: inventoryItem.esi,
        min: 0,
        show: () => inventoryType === INVENTORY_TYPE.ANIMAL_HEALTH,
      },
      {
        name: 'woolWhp',
        type: 'number',
        initialValue: inventoryItem.woolWhp,
        min: 0,
        show: () => inventoryType === INVENTORY_TYPE.ANIMAL_HEALTH,
      },
      { name: 'notes', type: 'textarea', initialValue: inventoryItem.notes },
    ],
    error,
    loading,
    onSubmit: handleSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};

export interface InventoryItemFeedEditFormValues {
  name: string;
  quantity: QuantityOfMassVolume;
  notes: string;
}

const InventoryItemFeedEditForm: React.FC<
  InventoryItemEditFormProps<InventoryItemFeed>
> = ({
  inventoryItem,
  //
  error,
  loading,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const handleSubmit = (formValues: InventoryItemFeedEditFormValues) => {
    onSubmit({
      ...inventoryItem,
      name: formValues.name,
      notes: formValues.notes,
      quantity: formValues.quantity,
    });
  };

  const form: FormBuilderProps<InventoryItemFeedEditFormValues> = {
    i18nRootKey: 'inventory_items.inventory_item.feed.edit.form',
    flex: true,
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
        initialValue: inventoryItem.name,
      },
      {
        name: 'quantity',
        type: 'quantity-mass-volume',
        initialValue: inventoryItem.quantity,
      },
      { name: 'notes', type: 'textarea', initialValue: inventoryItem.notes },
    ],
    error,
    loading,
    onSubmit: handleSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
