import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import classNames from 'classnames/bind';

import { Icon } from '@mobble/icon';

import styles from './Checkbox.scss';
const cx = classNames.bind(styles);

type TPickedProps =
  | 'aria-label'
  | 'autoFocus'
  | 'checked'
  | 'className'
  | 'disabled'
  | 'name'
  | 'onBlur'
  | 'onClick'
  | 'onChange'
  | 'onFocus'
  | 'onKeyDown'
  | 'onKeyUp'
  | 'required'
  | 'style'
  | 'tabIndex'
  | 'value';

export interface CheckboxProps
  extends Pick<ComponentPropsWithoutRef<'input'>, TPickedProps> {
  /**
   * Used to bind input and label
   */
  id: string;

  /**
   * 	Checkbox label -- alternative set `aria-label`
   */
  label?: string | React.ReactNode;

  /**
   * Display a circular checkbox
   */
  radio?: boolean;

  /**
   * Indeterminate state of checkbox, overwrites checked
   */
  indeterminate?: boolean;

  /**
   * Show error state
   */
  error?: boolean;

  /**
   * Size of the checkbox, defaults to medium
   */
  size?: 'small' | 'medium' | 'large';
}

/**
 * Checkbox is a component that allows users to select one or more options from a set.
 * It can be used to turn an option on or off.
 * It can also be used to select multiple options from a set.
 * The default state of a checkbox is unchecked.
 * When a user selects a checkbox, the state changes to checked.
 */
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      label,
      radio,
      checked,
      indeterminate,
      error,
      size = 'medium',
      className,
      style,
      ...others
    },
    ref
  ) => {
    const inputEl = useRef<HTMLInputElement>(null);
    // Expose input ref to parent component
    useImperativeHandle(ref, () => inputEl?.current as HTMLInputElement);

    const isChecked = checked || indeterminate || false;
    const rootClasses = cx(
      {
        Checkbox: true,
        error,
        radio,
        [size]: true,
      },
      className
    );

    // Set indeterminate state of checkbox
    useEffect(() => {
      if (inputEl?.current && typeof indeterminate === 'boolean') {
        inputEl.current.indeterminate = indeterminate;
      }
    }, [inputEl, indeterminate]);

    /**
     * Warning messages
     */
    const ariaLabel = others['aria-label'];
    useEffect(() => {
      if (!id) {
        console.warn('Checkbox: "id" property is missing. Please provide one.');
      }

      if (!label && !ariaLabel) {
        console.warn(
          'Checkbox: neither "label" nor "aria-label" property is provided. Please provide one.'
        );
      }
    }, [id, label, ariaLabel]);

    return (
      <div className={rootClasses} style={style}>
        <label>
          <input
            id={id}
            type="checkbox"
            ref={inputEl}
            aria-invalid={error}
            checked={isChecked}
            {...others}
          />
          <Icon name={indeterminate ? 'minus' : 'check-fat'} />
          {typeof label === 'string' ? (
            <span className={styles.labelText}>{label}</span>
          ) : (
            label
          )}
        </label>
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
