import { useCallback } from 'react';
import {
  type ConfiguredPropertyType,
  type Property,
} from '@mobble/models/src/model/Property';
import { UserRole } from '@mobble/models/src/model/User';
import {
  PreludeStatus,
  type EntitySliceFactoryProxyPrelude,
} from '../lib/EntitySliceFactoryPrelude';
import { Status, checkEntityExpiration, getForAction } from '../lib/ExtStatus';
import {
  ThunkChangeUserInput,
  ThunkDeleteUserInput,
  select,
  thunkAddPropertyType,
  thunkAddUser,
  thunkCreate,
  thunkDelete,
  thunkDeleteUser,
  thunkEditUser,
  thunkGetAll,
  thunkGetOrganisation,
  thunkGetUsers,
  thunkRemovePropertyType,
  thunkUpdate,
  thunkUpdatePropertyType,
} from '../reducers/properties';
import { useAuthIsLoggedIn } from './auth';
import { useRootDispatch, useRootSelector } from './root';

const PROPERTIES_MAX_AGE = 60 * 2; // minutes in seconds

// Roles with access to organisation data
const ORG_ROLES = [UserRole.Owner, UserRole.Admin];

// Roles with access to other organisation user data
const ORG_USER_ROLES = [
  UserRole.Owner,
  UserRole.Admin,
  UserRole.User,
  UserRole.ViewOnly,
  UserRole.FarmAdvisor,
];

export interface UsePropertiesResult {
  entities: Property[];
  selected?: Property;
  loading: boolean;
  prelude: EntitySliceFactoryProxyPrelude;
  refresh: (force?: boolean) => Promise<void>;
  selectProperty: (propertyId: string) => void;
  create: (input: any) => Promise<Property>;
  update: (input: any) => Promise<void>;
  remove: (input: any) => Promise<void>;
  addUser: (input: ThunkChangeUserInput) => Promise<void>;
  editUser: (input: ThunkChangeUserInput) => Promise<void>;
  deleteUser: (input: ThunkDeleteUserInput) => Promise<void>;
  addPropertyType: (input: Partial<ConfiguredPropertyType>) => Promise<void>;
  updatePropertyType: (input: ConfiguredPropertyType) => Promise<void>;
  removePropertyType: (input: ConfiguredPropertyType) => Promise<void>;
}

export const useProperties = (): UsePropertiesResult => {
  const isLoggedIn = useAuthIsLoggedIn();
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.properties);
  const entities = state.entities;
  const selected = entities.find((p) => p.id === state.selectedProperty);
  const userRole = selected?.currentUserRole;

  const extStatus = getForAction(state.extStatus)(thunkGetAll.typePrefix);
  const refreshing =
    extStatus?.status === Status.Loading && entities.length > 0;
  const loading =
    isLoggedIn &&
    (!extStatus || (extStatus.status === Status.Loading && !refreshing));
  const lastError = extStatus?.error;

  const refresh = useCallback(
    (force: boolean = true) => {
      // Refresh is forced by UI action (e.g. pull to refresh)
      // or if previous fetch data isn't available (e.g. page reload)
      // See `src/config/persist.ts` to see `extStatus` filtered
      const forceRefresh = force || !extStatus;

      // Update the data if it's expired - this avoids querying too often
      // Ideally we'd have a way of knowing if updated data is available
      const { hasExpired } = checkEntityExpiration(
        PROPERTIES_MAX_AGE,
        extStatus
      );

      if (forceRefresh || hasExpired) {
        return dispatch(thunkGetAll()).then(() => {
          if (selected?.id && ORG_ROLES.includes(userRole)) {
            dispatch(thunkGetOrganisation(selected.id));
          }

          if (selected?.organisationId && ORG_USER_ROLES.includes(userRole)) {
            dispatch(thunkGetUsers(selected.organisationId));
          }
        });
      }

      return Promise.resolve();
    },
    [selected?.id, JSON.stringify(extStatus), userRole]
  );

  const selectProperty = (propertyId: Property['id']) => {
    dispatch(select(propertyId));
  };

  const addPropertyType = (input: Partial<ConfiguredPropertyType>) => {
    const propertyId = selected?.id;
    if (!propertyId) {
      return Promise.reject();
    }
    return dispatch(thunkAddPropertyType({ ...input, propertyId })).then(
      () => {}
    );
  };

  const updatePropertyType = (input: ConfiguredPropertyType) => {
    const propertyId = selected?.id;
    if (!propertyId) {
      return Promise.reject();
    }
    return dispatch(thunkUpdatePropertyType({ ...input, propertyId })).then(
      () => {}
    );
  };

  const removePropertyType = (input: ConfiguredPropertyType) => {
    const propertyId = selected?.id;
    if (!propertyId) {
      return Promise.reject();
    }
    return dispatch(thunkRemovePropertyType({ ...input, propertyId })).then(
      () => {}
    );
  };

  const create = async (input: any): Promise<Property> => {
    try {
      const res = await dispatch(thunkCreate(input)).unwrap();
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const update = (input: any) => {
    return dispatch(thunkUpdate(input)).then(() => {});
  };

  const remove = (input: any) => {
    return dispatch(thunkDelete(input)).then(() => {});
  };

  const addUser = (input: ThunkChangeUserInput) => {
    return dispatch(thunkAddUser(input)).then(() => {
      refresh();
    });
  };

  const editUser = (input: ThunkChangeUserInput) => {
    return dispatch(thunkEditUser(input)).then(() => {
      refresh();
    });
  };

  const deleteUser = (input: ThunkDeleteUserInput) => {
    return dispatch(thunkDeleteUser(input)).then(() => {});
  };

  return {
    loading,
    entities,
    selected,
    refresh,
    create,
    update,
    remove,
    addUser,
    editUser,
    deleteUser,
    selectProperty,
    addPropertyType,
    updatePropertyType,
    removePropertyType,
    prelude: {
      name: 'properties',
      status: loading ? PreludeStatus.Loading : PreludeStatus.Ready,
      lastError,
    },
  };
};
