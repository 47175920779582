import { createAsyncThunk } from '@reduxjs/toolkit';
import { type PaddockAction } from '@mobble/models/src/model/PaddockAction';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { builderAddExtStatusCasesForThunks } from '../lib/entitySliceFactory';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { FilterPerProperty } from '../lib/filter';
import { thunkGetAll as thunkGetAllPaddocks } from './paddocks';
import { thunkGetAll as thunkGetAllHistoricEvents } from './historicEvents';
import { thunkGetAll as thunkGetAllPaddockGeometries } from './paddockGeometry';

export interface PaddockActionsState {
  entities: PaddockAction[];
  extStatus: ExtStatusPerActionPerParent;
  filter: FilterPerProperty;
  sort: {};
}

export const defaultState: PaddockActionsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const name = 'paddockActions';
const toParentId = ({ propertyId }: PaddockAction) => propertyId;

const entitySlice = entitySliceFactory<PaddockActionsState>({
  name,
  initialState: defaultState,
  extraReducers: (builder) => {
    builderAddExtStatusCasesForThunks(toParentId, builder)([thunkGet]);
    builder.addCase(thunkGet.fulfilled, (state, action) => {
      state.entities = [
        ...state.entities.filter((entity) => entity.id !== action.payload.id),
        action.payload,
      ];
    });
  },
  toParentId,
  onGetAll: mobbleService.api.paddockActions.get,
  onCreate: mobbleService.api.paddockActions.create,
  onUpdate: mobbleService.api.paddockActions.update,
  onDelete: mobbleService.api.paddockActions.remove,
  afterMutation: async ({ entity }, dispatch) => {
    await dispatch(thunkGetAllPaddocks({ parentId: entity.propertyId }));
    await dispatch(
      thunkGetAllPaddockGeometries({ parentId: entity.propertyId })
    );
    dispatch(thunkGetAllHistoricEvents({ parentId: toParentId(entity) }));
  },
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export const thunkGet = createAsyncThunk<
  PaddockAction,
  { id: string; parentId: string }
>(`${name}/get`, ({ id, parentId }) => {
  return mobbleService.api.paddockActions.find({ id, parentId });
});

export default entitySlice.slice.reducer;
