import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { type IconName } from '@mobble/icon';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useMobs,
  usePaddock,
  usePaddocks,
  useProperties,
  useSale,
  useSales,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeaderProps } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';

const messages = defineMessages({
  saleDetail: {
    defaultMessage: 'Sale',
    description: 'screen.title.sale_detail',
  },
  saleEdit: {
    defaultMessage: 'Edit Sale',
    description: 'screen.title.sale_edit',
  },
  saleCreate: {
    defaultMessage: 'Sell Mob',
    description: 'screen.title.sale',
  },
  mobs: {
    defaultMessage: 'Mobs',
    description: 'screen.title.mobs',
  },
  summaries: {
    defaultMessage: 'Summaries',
    description: 'screen.title.summaries',
  },
  sales: {
    defaultMessage: 'Sales',
    description: 'screen.title.summary_sales',
  },
});

export const useSaleScreenHeader = (
  saleId?: string,
  isEdit?: boolean
): Pick<
  ScreenHeaderProps,
  'title' | 'breadcrumbs' | 'quickLinks' | 'actions' | 'onGoBack'
> => {
  const strings = useMessages(messages);
  const onGoBack = useNavigateBack();
  const properties = useProperties();
  const sales = useSales(properties.selected?.id);
  const sale = useSale(saleId);
  const isNew = !saleId;

  const saleName = sale.entity
    ? toMobDisplayName(sale.entity.mob)
    : strings.saleCreate;

  const title =
    !isEdit && !isNew
      ? saleName
      : isNew
      ? strings.saleCreate
      : strings.saleEdit;

  const breadcrumbs: ScreenHeaderProps['breadcrumbs'] = [
    {
      title: strings.summaries,
      href: ROUTE_NAME.SUMMARIES_LIST,
    },
    {
      title: strings.sales,
      href: ROUTE_NAME.SUMMARY_SALES_LIST,
    },
  ];

  if (isEdit) {
    breadcrumbs.push({
      title: saleName,
      href: toPath(ROUTE_NAME.SALE_DETAIL, {
        saleId,
      }),
    });
  }

  // Mobs list breadcrumb for a new sale
  if (isNew) {
    breadcrumbs.splice(0, breadcrumbs.length, {
      title: strings.mobs,
      href: toPath(ROUTE_NAME.MOBS_LIST),
    });
  }

  const quickLinks =
    !isEdit && !isNew
      ? sales.entities
          .map((p) => ({
            title: toMobDisplayName(p.mob),
            href: toPath(ROUTE_NAME.SALE_DETAIL, {
              saleId: p.id,
            }),
          }))
          .sort((a, b) => a.title.localeCompare(b.title))
      : undefined;

  const actions: ScreenHeaderProps['actions'] =
    !isEdit && !isNew
      ? [
          {
            icon: 'edit' as IconName,
            label: strings.saleEdit,
            href: toPath(ROUTE_NAME.SALE_EDIT, {
              saleId,
            }),
          },
        ]
      : undefined;

  return { title, breadcrumbs, quickLinks, actions, onGoBack };
};

export const useSaleScreen = (saleId?: string) => {
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const sale = useSale(saleId ?? '_', propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddock = usePaddock(sale.entity?.paddock?.id);
  const mobs = useMobs(propertyId);
  const isNew = !saleId;

  useEntitiesRefresher(
    [mobs, paddocks, paddock, !isNew ? sale : null].filter(Boolean),
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      mobs.prelude,
      !isNew ? paddock.prelude : null,
      !isNew ? sale.prelude : null,
    ].filter(Boolean),
    required: [properties.selected],
    notFound: [!isNew ? sale.entity : null].filter(Boolean),
  });

  return { Prelude, sale, mobs, paddocks, paddock, properties };
};
