import { useContext } from 'react';
import { DialogContext, type DialogContextProps } from '../context/dialog';

export { type DialogContextProps };

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within an DialogProvider');
  }
  return context;
};
