import React from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps {
  key?: string;
  children: React.ReactElement;
}

export const Portal: React.FC<PortalProps> = ({ children, key }) => {
  const mount = (() => {
    const $root = document.getElementById('portal');
    if (!$root) {
      throw new Error('could not find element #portal');
    }
    return $root;
  })();

  return createPortal(children, mount, key);
};
