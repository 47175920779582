import React from 'react';
import { MobAction } from '@mobble/models/src/model/MobAction';
import { useMobActions } from '@mobble/store/src/hooks/mobActions';
import mobbleService from '@mobble/service';
import { Provider } from '@src/stories/Views/Entities/types';

export const useGetIndividualMobActions = (
  mobId: string,
  propertyId?: string
) => {
  const mobActionsSlice = useMobActions(propertyId);

  const [individualMobActions, setIndividualMobActions] = React.useState<
    MobAction[]
  >([]);

  const mobActions: Provider<MobAction> = {
    ...mobActionsSlice,
    entities: individualMobActions,
    total: individualMobActions?.length,
  };

  React.useEffect(() => {
    if (mobId && propertyId) {
      mobbleService.api.mobActions
        .getIndividualMobActions({
          parentId: propertyId,
          mobId: mobId,
        })
        .then((e) => setIndividualMobActions(e.entities));
    }
  }, [mobId, propertyId]);

  return mobActions;
};
