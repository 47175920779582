import Fuse from 'fuse.js';
import { type RawDate, fromRawDate } from '@mobble/shared/src/core/Date';
import {
  MassVolumeUnits,
  type QuantityOfMassVolume,
} from '@mobble/shared/src/core/Quantity';
import { type Inventory, InventoryCategory } from './Inventory';
import { type Property } from './Property';
import { type User } from './User';
import { SortDirection } from './Sort';
import { type FilterItem, filterMatches, groupFilter } from './Filter';

export enum InventoryItemStatus {
  Active = 'active',
  Deleted = 'deleted',
  Finished = 'finished',
}

export interface BaseInventoryItem {
  id: string;
  inventoryId: Inventory['id'];
  propertyId: Property['id'];
  status: InventoryItemStatus;
  createdBy: User;
  name: string;
  notes?: string;
  quantity: QuantityOfMassVolume;
  lastUsed?: RawDate;
  dateReceived?: RawDate;
  shared?: {
    propertyName: string;
    propertyId: string;
  };
}

export type InventoryItemFeed = {
  category: InventoryCategory.Feed;
  pricePerUnitCents?: number;
  supplierName?: string;
} & BaseInventoryItem;

export type InventoryItemChemical = {
  category: InventoryCategory.Chemicals;
  esi: number;
  whp: number;
  woolWhp: number;
} & BaseInventoryItem;

export type InventoryItem = InventoryItemFeed | InventoryItemChemical;

export interface InventoryItemUsage {
  data: InventoryItemUsageNode[];
  id: string;
  propertyId: string;
}

export interface InventoryItemUsageNode {
  unit: MassVolumeUnits;
  value: number;
  date: string;
}

export const sortByDateReceived =
  (sortDirection: SortDirection) =>
  (a: InventoryItem, b: InventoryItem): number => {
    const f = (date?: RawDate) => (date ? fromRawDate(date).valueOf() : 0);

    return sortDirection === SortDirection.Ascending
      ? f(a.dateReceived) - f(b.dateReceived)
      : f(b.dateReceived) - f(a.dateReceived);
  };

export const findInventoryItem =
  (inventoryItems: InventoryItem[]) => (id: string) =>
    inventoryItems.find((i) => i.id === id);

export const filterInventoryItems = (
  inventoryItems: InventoryItem[],
  filter?: FilterItem[]
): InventoryItem[] => {
  if (!filter || filter.length === 0) {
    return inventoryItems;
  }
  const grouped = [...groupFilter(filter)];

  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredInventoryItems =
    searchQuery && searchQuery.type === 'search'
      ? searchInventoryItems(inventoryItems, searchQuery.value)
      : inventoryItems;

  return preFilteredInventoryItems.filter((task) =>
    grouped.every(([_, filters]) =>
      filters.some(inventoryItemsFilterItemMatchesInventoryItem(task))
    )
  );
};

export const searchInventoryItems = (
  inventoryItems: InventoryItem[],
  searchQuery: string
): InventoryItem[] => {
  const fuse = new Fuse(
    inventoryItems.map((t) => ({
      ...t,
    })),
    {
      keys: [
        { name: 'name', weight: 5 },
        { name: 'batchId', weight: 4 },
        { name: 'notes', weight: 3 },
      ],
      threshold: 0.5,
      shouldSort: true,
    }
  );

  return fuse.search(searchQuery).map((a) => a.item);
};

export const inventoryItemsFilterItemMatchesInventoryItem =
  (task: InventoryItem) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'inventory_item_status':
        return matches(task.status);
      case 'search':
        return true;
      default:
        return true;
    }
  };
