import logger from '@mobble/shared/src/core/Logger';
import { type ApiPrelude } from '../types';

const REGISTER_DEVICE = `
  mutation RegisterDevice($input: RegisterDeviceInput!) {
    registerDevice(input: $input) {
      success
    }
  }
`;

type RegisterDevice = {
  registerDevice: {
    __typename: 'RegisterDevicePayload';
    success: boolean;
  };
};

interface RegisterDeviceValues {
  input: {
    token: string;
    deviceId?: string | null;
    tz?: string | null;
    appVersion?: string | null;
    system?: string | null;
    systemVersion?: string | null;
    manufacturer?: string | null;
    model?: string | null;
    deviceType?: string | null;
  };
}

export const register =
  (prelude: ApiPrelude) => async (variables: RegisterDeviceValues) => {
    logger.info('registerDevice', JSON.stringify(variables));
    const response = await prelude.graphql({
      query: REGISTER_DEVICE,
      variables,
    });
    return response?.data;
  };
