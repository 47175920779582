import React from 'react';
import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import {
  pointsToBoundingBox,
  type Point,
} from '@mobble/models/src/model/MapGeometry';

import { Button } from '@src/components';
import { type MapPluginsListenersDispatchRef } from '@src/stories/Map/Context';

import { Box } from '../Layout/Box';
import { Spacer } from '../Layout/Spacer';
import { Map } from '../../Map';
import { CircularButton } from './CircularButton';
import { MAP_DETAILS } from '@mobble/models/src/model/MapDetail';
import { ModalFlyUp } from './ModalFlyUp';

import styles from './inputLocation.scss';

export interface InputMapCreatorProps {
  value?: null | Point[];
  single?: boolean;
  mode?: string;
  onChange: (value: Point[]) => void;
}

// TODO: This component and InputLocation can probably be merged
export const InputMapCreator: React.FC<InputMapCreatorProps> = ({
  value,
  single = true,
  mode,
  onChange,
}) => {
  const { formatMessage } = useI18n();
  const mapRef = React.useRef<MapPluginsListenersDispatchRef>(null);
  const [modalVisible, setModalVisible] = React.useState(false);
  const mapProperties = {
    boundingBox:
      value && value.length > 0
        ? pointsToBoundingBox(value, value.length === 1 ? 500 : 250)
        : undefined,
    mapDetails: [MAP_DETAILS.PADDOCK_BOUNDARIES, MAP_DETAILS.PADDOCK_LABELS],
  };

  const pointsState = {
    enabled: false,
    points: value ? value : [],
    options: { single, mode },
  };

  React.useEffect(() => {
    if (single && value?.length > 1) {
      onChange([value[0]]);
    }
  }, [single]);

  const currentValueDisplay = React.useMemo(
    () =>
      value ? (
        <Box flex className={styles.mapContainer}>
          <Box className={styles.buttonContainer}>
            <CircularButton
              icon="edit"
              fill={Color.Green}
              elevation="low"
              onClick={() => setModalVisible(true)}
            />
            <Spacer y={1} />
            <CircularButton
              icon="trash"
              fill={Color.Red}
              elevation="low"
              onClick={() => onChange(null)}
            />
          </Box>
          <Box flex>
            <Map
              types={['creator', 'items', 'camera']}
              overrideMapProperties={mapProperties}
              pointsState={pointsState}
            />
          </Box>
        </Box>
      ) : (
        <Box className={styles.inputContainer}>
          <Button
            id="input-map-creator"
            flex
            intent="secondary"
            icon="map"
            label={formatMessage({
              defaultMessage: 'Select on map',
              description:
                'generic.input.input_location.select_on_map.button.label',
            })}
            onClick={() => setModalVisible(true)}
            outline
          />
        </Box>
      ),
    [value]
  );

  return (
    <>
      <ModalFlyUp
        id="input-map-creator-modal"
        isOpen={modalVisible}
        size="large"
        title={formatMessage({
          defaultMessage: 'Select on map',
          description: 'generic.input.input_location.select_on_map.modal.title',
        })}
        footerProps={{
          closeLabel: formatMessage({
            defaultMessage: 'Cancel',
            description:
              'generic.input.input_location.select_on_map.modal.cancel.label',
          }),
          submitLabel: formatMessage({
            defaultMessage: 'Update',
            description:
              'generic.input.input_location.select_on_map.modal.select.label',
          }),
          onCloseClick: () => {
            setModalVisible(false);
          },
          onSubmitClick: () => {
            const newPoints = mapRef.current.getState().points;
            onChange(newPoints.length ? newPoints : null);
            setModalVisible(false);
          },
        }}
        onClose={() => {
          setModalVisible(false);
        }}
        className={styles.modal}
      >
        <div className={styles.modalMapContainer}>
          {modalVisible && (
            <Map
              stateRef={mapRef}
              key="input"
              types={['creator', 'items', 'camera']}
              overrideMapProperties={mapProperties}
              pointsState={{
                ...pointsState,
                enabled: true,
                points: value,
              }}
            />
          )}
        </div>
      </ModalFlyUp>
      <Box>{currentValueDisplay}</Box>
    </>
  );
};
