import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
  current,
} from '@reduxjs/toolkit';
import { type User } from '@mobble/models/src/model/User';
import mobbleService from '@mobble/service';

export interface AuthState {
  displayName: null | string;
  user: null | User;
}

export const defaultState: AuthState = {
  displayName: '',
  user: null,
};

const slice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    setUser: (state: AuthState, action: PayloadAction<User>) => {
      const tempDisplayName = current(state).displayName;

      state.user = {
        ...action.payload,
        name: action.payload.name || tempDisplayName,
      };

      if (tempDisplayName) {
        state.displayName = '';
      }
    },
    setDisplayName: (state: AuthState, action: PayloadAction<string>) => {
      state.displayName = action.payload;
    },
    unsetUser: (state: AuthState) => {
      state.user = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const { setUser, setDisplayName, unsetUser } = slice.actions;

export interface SignInInput {
  email: string;
  password: string;
}

export const updateUser = createAsyncThunk<User, User>(
  'auth/updateUser',
  mobbleService.api.me.update
);

export const { reducer } = slice;
