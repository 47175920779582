export interface MobbleApp {
  id: string;
  enabled: boolean;
}

export enum MobbleApps {
  AgWorld = 'agworld',
  AgWorldImportPaddocks = 'agworld-import-paddocks',
  DelayPay = 'delay-pay',
}

export enum CONTEXTS {
  IMPORT_PADDOCKS = 'import-paddocks',
  AUTHORISE_AGWORLD = 'authorise-agworld',
}

export const mobbleAppIds = [
  MobbleApps.AgWorld,
  MobbleApps.AgWorldImportPaddocks,
  MobbleApps.DelayPay,
];
