type PromiseQueueItem = () => Promise<any>;

export interface PromiseQueueInterface {
  enqueue: (fn: () => Promise<any>) => Promise<any>;
  setMaxSimultaneousRequests: (max: number) => void;
}

export class PromiseQueue implements PromiseQueueInterface {
  private static instance: PromiseQueue;
  //
  private queue: PromiseQueueItem[] = [];
  private currentInProgress: number = 0;
  private MAX_SIMULTANEOUS_REQUESTS = 4;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new PromiseQueue();
    }
    return this.instance;
  }

  public enqueue = (fn: () => Promise<any>) => {
    return new Promise((resolve, reject) => {
      this.queue.push(() => fn().then(resolve).catch(reject));
      this.processQueue();
    });
  };

  public setMaxSimultaneousRequests = (max: number) => {
    this.MAX_SIMULTANEOUS_REQUESTS = max;
  };

  private processQueue() {
    if (
      this.currentInProgress >= this.MAX_SIMULTANEOUS_REQUESTS ||
      this.queue.length === 0
    ) {
      return;
    }

    const request = this.queue.shift() as PromiseQueueItem; // can't be undefined since we just checked if the queue length is 0, silly Typescript
    this.currentInProgress++;

    request()
      .then((res) => {
        this.currentInProgress--;
        this.processQueue();
        return res;
      })
      .catch(() => {
        this.currentInProgress--;
        this.processQueue();
      });
  }
}

const promiseQueue = PromiseQueue.getInstance();

export default promiseQueue;
