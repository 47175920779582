import React from 'react';

import { useI18n } from '@mobble/i18n';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useMobs, useProperties } from '@mobble/store/src/hooks';

import { toPath } from '@src/interfaces/Routing';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { MobsViewer } from '@src/stories/Views/Mob/List/MobsViewer';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const MobsHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Mobs',
        description: 'screen.title.mobs',
      })}
      actions={[
        {
          label: formatMessage({
            defaultMessage: 'Event log',
            description: 'screen.title.summary_events',
          }),
          icon: 'activity',
          href: toPath(ROUTE_NAME.SUMMARY_EVENTS_LIST),
        },
      ]}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Mobs: React.FC = () => {
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const mobs = useMobs(propertyId);

  useEntitiesRefresher([mobs], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, mobs.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  return <MobsViewer provider={mobs} />;
};

export default {
  name: ROUTE_NAME.MOBS_LIST,
  header: MobsHeader,
  component: Mobs,
};
