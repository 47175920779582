import React from 'react';
import {
  ScenarioType,
  getUserActionsForScenario,
} from '@mobble/models/src/model/Intentions/Scenarios';
import { metaForUserActionType } from '@mobble/models/src/model/Intentions/UserActions';
import { type IconName } from '@mobble/icon';
import {
  Actions,
  type ActionsOption,
} from '@src/stories/Components/UX/Actions';
import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { userActionToRoute } from '@src/screens/config/userActionToRoute';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { toPath } from '@src/interfaces/Routing';
import { InventoryItem } from '@mobble/models/src/model/InventoryItem';

export interface ActionSheetOnInventoryItemSelectProps {
  inventoryItem: InventoryItem;
  onClose: () => void;
}

export const ActionSheetOnInventoryItemSelect: React.FC<
  ActionSheetOnInventoryItemSelectProps
> = ({ inventoryItem, onClose }) => {
  const { hasAccessToRoute } = useAccessHelper();

  const inventoryItemParams: Record<string, string | number> = {
    inventoryId: inventoryItem?.inventoryId,
    inventoryItemId: inventoryItem?.id,
  };

  const userActions = getUserActionsForScenario(
    ScenarioType.inventoryItemSelect
  );
  const options: ActionsOption[] = userActions.map((ua) => {
    const meta = metaForUserActionType(ua);
    const { route, params } = userActionToRoute(ua, inventoryItemParams);

    return {
      icon: meta.icon as IconName,
      label: { key: meta.titleKey },
      description: { key: meta.descriptionKey },
      disabled: !hasAccessToRoute(route),
      href: toPath(route, params),
    };
  });

  return (
    <ModalFlyUp title={inventoryItem?.name} isOpen={true} onClose={onClose}>
      <Actions options={options} onClose={onClose} />
    </ModalFlyUp>
  );
};
