import { arrayUnique } from '@mobble/shared/src/core/Array';
import { fromRawDate } from '@mobble/shared/src/core/Date';
import { Mob } from '@mobble/models/src/model/Mob';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { useNaturalIncreases } from '@mobble/store/src/hooks/naturalIncreases';

export const useNaturalIncreasesFilterItems = () => {
  const properties = useProperties();
  const naturalIncreases = useNaturalIncreases(properties.selected?.id);

  const getMobParamValues = (param: keyof Mob) => {
    const acc: string[] = [];

    const naturalIncreaseMobs = naturalIncreases.entities.map((e) => e.mob);

    naturalIncreaseMobs.forEach((mob) => {
      if (Array.isArray(mob[param])) {
        (mob[param] as string[]).forEach((value) => value && acc.push(value));
      } else if (
        typeof mob[param] === 'string' ||
        typeof mob[param] === 'number'
      ) {
        acc.push(String(mob[param]));
      }
    });
    return arrayUnique(acc).map((value) => ({ value }));
  };

  return () => [
    {
      type: 'date-range',
      group: 'dateRange',
      title: { key: 'naturalIncreases.filter.group.type.date_range.label' },
      meta: {
        years: Array.from(
          new Set(
            naturalIncreases.entities.map((entity) =>
              fromRawDate(entity.date).year()
            )
          )
        ).slice(0, 5),
      },
    },
    {
      title: { key: 'naturalIncreases.filter.group.breed.label' },
      type: 'select-multiple',
      group: 'breed',
      data: getMobParamValues('breed'),
    },
    {
      title: { key: 'naturalIncreases.filter.group.gender.label' },
      type: 'select-multiple',
      group: 'gender',
      data: getMobParamValues('gender'),
    },
  ];
};
