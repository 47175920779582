import React from 'react';

import { Mob } from '@mobble/models/src/model/Mob';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { type ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { Color } from '@mobble/theme';

import { MobCard } from '@src/components';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';

export interface MobCreateWizardSummaryProps {
  newMob: Mob;
  paddock: Paddock;
  propertyTypes: ConfiguredPropertyType[];
  children?: React.ReactChild;
}

export const MobCreateWizardSummary: React.FC<MobCreateWizardSummaryProps> = ({
  newMob,
  paddock,
  propertyTypes,
  children,
}) => {
  return (
    <Box>
      <Box spacing={2} background={Color.NeutralsBgDisabled}>
        <MobCard
          propertyTypes={propertyTypes}
          paddockName={paddock?.name}
          mob={newMob}
        />
      </Box>
      <Spacer y={2} />
      {children}
    </Box>
  );
};
