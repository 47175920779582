import React from 'react';
import { useI18n } from '@mobble/i18n';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { type Casualty } from '@mobble/models/src/model/Casualty';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useExtStatus,
  useProperties,
  useCasualty,
  usePaddocks,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import {
  CasualtyEditForm,
  CasualtyEditFormValues,
} from '@src/stories/Views/Casualty/CasualtyEditForm';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';
import { useFormError } from '@src/hooks/useFormError';

export const CasualtyEditHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const { casualtyId = '' } = props.route.params;
  const properties = useProperties();
  const casualty = useCasualty(casualtyId, properties.selected?.id);
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.casualty_edit',
        defaultMessage: 'Edit casualty',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.summaries',
            defaultMessage: 'Summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.summary_casualties',
            defaultMessage: 'Casualties',
          }),
          href: ROUTE_NAME.SUMMARY_CASUALTIES,
        },
        {
          title: casualty?.entity
            ? toMobDisplayName(casualty?.entity?.mob)
            : formatMessage({
                defaultMessage: 'Casualty',
                description: 'screen.title.casualty_detail',
              }),
          href: toPath(ROUTE_NAME.CASUALTY_DETAIL, { casualtyId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const CasualtyEdit: React.FC<ScreenRendererProps> = ({ route }) => {
  const { casualtyId } = route.params;
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddocks = usePaddocks(propertyId);
  const casualty = useCasualty(casualtyId);
  const linkTo = useLinking();
  const goBack = useNavigateBack();

  const extStatus = useExtStatus('casualties', 'update', casualtyId);
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus('casualties', 'delete', casualtyId);
  const deleteLoading = extStatusDelete?.loading;
  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Batch',
      description: 'screen.title.batch_detail',
    }),
    active: Boolean(extStatus?.error || extStatusDelete?.error),
  });

  useEntitiesRefresher([paddocks, casualty], propertyId);

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, paddocks.prelude, casualty.prelude],
    required: [properties.selected],
  });

  if (prelude) {
    return prelude;
  }

  const handleSubmit = (formValues: CasualtyEditFormValues) => {
    if (!casualty.entity || !properties.selected) {
      return;
    }

    const updatedCasualty: Casualty = {
      ...casualty.entity,
      number: Number(formValues.number),
      date: toISO8601(formValues.date),
      methodOfDisposal: formValues.methodOfDisposal,
      reason: formValues.reason,
    };

    casualty
      .update(updatedCasualty)
      .then(() => {
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    casualty
      .remove()
      .then(() => {
        linkTo(ROUTE_NAME.SUMMARY_CASUALTIES);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <CasualtyEditForm
        paddocks={paddocks.entities}
        casualty={casualty.entity}
        propertyTypes={properties.selected.types}
        error={formError}
        loading={formLoading}
        onCancel={goBack}
        onSubmit={handleSubmit}
      />
      <DeleteForm
        i18nRootKey="casualties.casualty.delete"
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.CASUALTY_EDIT,
  component: CasualtyEdit,
  header: CasualtyEditHeader,
};
