import { type Paddock } from '@mobble/models/src/model/Paddock';
import { type Mob } from '@mobble/models/src/model/Mob';
import { type HistoricEvent } from '@mobble/models/src/model/HistoricEvent';
import { type ApiPrelude, type ExtCursor } from '../types';

const decodeHistoricEvent =
  (propertyId: string, related?: { paddockId?: string; mobId?: string }) =>
  (data: any): HistoricEvent => {
    return {
      id: data?.id ?? '',
      propertyId: propertyId,
      date: data?.date ?? '',
      eventType: data?.eventType ?? '',
      title: data?.title ?? '',
      description: data?.description ?? '',

      createdBy: {
        id: data?.createdBy?.id ?? '',
        name: data?.createdBy?.displayName ?? '',
        email: data?.createdBy?.email ?? '',
      },

      related: related?.paddockId
        ? {
            paddockId: [related.paddockId],
          }
        : related?.mobId
        ? {
            mobId: [related.mobId],
          }
        : {},

      linksTo: data?.linksTo
        ? {
            type: data?.linksTo?.type ?? '',
            id: data?.linksTo?.id ?? '',
            parentId: data?.linksTo?.parentId ?? '',
            parentType: data?.linksTo?.parentType ?? '',
          }
        : undefined,
    };
  };

const decodeHistoricEvents =
  (propertyId: string, related?: { paddockId?: string; mobId?: string }) =>
  (data: any[]): HistoricEvent[] => {
    return data.map(decodeHistoricEvent(propertyId, related));
  };

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    extFilter,
    cursor,
  }: {
    parentId: string;
    extFilter?: {
      paddockId?: Paddock['id'];
      mobId?: Mob['id'];
      eventTypes?: string[];
    };
    cursor?: string;
  }): Promise<{ entities: HistoricEvent[]; extCursor: ExtCursor }> => {
    if (extFilter?.paddockId) {
      return apiGetHistoricEventsForPaddock(prelude)({
        parentId,
        paddockId: extFilter.paddockId,
        cursor,
      });
    } else if (extFilter?.mobId) {
      return apiGetHistoricEventsForMob(prelude)({
        parentId,
        mobId: extFilter.mobId,
        cursor,
      });
    }

    return apiGetHistoricEventsForProperty(prelude)({
      parentId,
      cursor,
      eventTypes: extFilter?.eventTypes,
    });
  };

const GET_EVENTS_FOR_PADDOCK = `
  query GetEventsByPaddock($propertyId: ID!, $paddockId: ID!, $after: String) {
    paddock(propertyId: $propertyId, id: $paddockId) {
      events(after: $after) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
        }
        nodes {
          id
          eventType
          date
          title
          description
          createdBy {
            id
            displayName
            email
          }
          linksTo {
            type
            id
          }
        }
      }
    }
  }
`;

const apiGetHistoricEventsForPaddock =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    paddockId,
    cursor,
  }: {
    parentId: string;
    paddockId: string;
    cursor?: string;
  }): Promise<{ entities: HistoricEvent[]; extCursor: ExtCursor }> => {
    const response = await prelude.graphql({
      query: GET_EVENTS_FOR_PADDOCK,
      variables: {
        propertyId: parentId,
        paddockId,
        after: cursor ?? null,
      },
    });

    return makeResponseApiGetHistoricEventsForPaddock(
      parentId,
      paddockId
    )(response?.data?.paddock?.events);
  };

const makeResponseApiGetHistoricEventsForPaddock =
  (propertyId: string, paddockId: string) =>
  (
    nameResponseData?: any
  ): { entities: HistoricEvent[]; extCursor: ExtCursor } => {
    const entities = decodeHistoricEvents(propertyId, {
      paddockId,
    })(nameResponseData?.nodes ?? []);

    const pageInfo = nameResponseData?.pageInfo;

    const extCursor = {
      total: nameResponseData?.totalCount ?? entities.length,
      cursorNext: pageInfo?.hasNextPage && pageInfo?.endCursor,
    };

    return {
      entities,
      extCursor,
    };
  };

const GET_EVENTS_FOR_MOB = `
  query GetEventsByMob($propertyId: ID!, $id: ID!, $after: String) {
    mob(propertyId: $propertyId, id: $id) {
      events(after: $after) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
        }
        nodes {
          id
          eventType
          date
          title
          description
          createdBy {
            id
            displayName
            email
          }
          linksTo {
            type
            id
          }
        }
      }
    }
  }
`;

const apiGetHistoricEventsForMob =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    mobId,
    cursor,
  }: {
    parentId: string;
    mobId: string;
    cursor?: string;
  }): Promise<{ entities: HistoricEvent[]; extCursor: ExtCursor }> => {
    const response = await prelude.graphql({
      query: GET_EVENTS_FOR_MOB,
      variables: {
        propertyId: parentId,
        id: mobId,
        after: cursor ?? null,
      },
    });

    return makeResponseApiGetHistoricEventsForMob(
      parentId,
      mobId
    )(response?.data?.mob?.events);
  };

const makeResponseApiGetHistoricEventsForMob =
  (propertyId: string, mobId: string) =>
  (
    nameResponseData?: any
  ): { entities: HistoricEvent[]; extCursor: ExtCursor } => {
    const entities = decodeHistoricEvents(propertyId, {
      mobId,
    })(nameResponseData?.nodes ?? []);

    const pageInfo = nameResponseData?.pageInfo;

    const extCursor = {
      total: nameResponseData?.totalCount ?? entities.length,
      cursorNext: pageInfo?.hasNextPage && pageInfo?.endCursor,
    };

    return {
      entities,
      extCursor,
    };
  };

export const GET_EVENTS_FOR_PROPERTY = `
  query GetEventsByProperty(
    $propertyId: ID!
    $after: String
    $filterBy: EventFilters
  ) {
    eventsByProperty(
      propertyId: $propertyId
      after: $after
      filterBy: $filterBy
    ) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        eventType
        title
        description
        date
        createdBy {
          id
          displayName
          email
        }
        linksTo {
          type
          id
          parentId
          parentType
        }
      }
    }
  }
`;

const apiGetHistoricEventsForProperty =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    eventTypes,
    cursor,
  }: {
    parentId: string;
    eventTypes?: string[];
    cursor?: string;
  }): Promise<{ entities: HistoricEvent[]; extCursor: ExtCursor }> => {
    const response = await prelude.graphql({
      query: GET_EVENTS_FOR_PROPERTY,
      variables: {
        propertyId: parentId,
        after: cursor ?? null,
        filterBy: eventTypes ? { eventTypes } : null,
      },
    });

    const entities = decodeHistoricEvents(parentId)(
      response?.data?.eventsByProperty?.nodes ?? []
    );

    const pageInfo = response?.data?.eventsByProperty?.pageInfo;

    const extCursor = {
      total: response?.data?.eventsByProperty?.totalCount ?? entities.length,
      cursorNext: pageInfo?.hasNextPage && pageInfo?.endCursor,
    };

    return {
      entities,
      extCursor,
    };
  };
