import { Casualty } from '@mobble/models/src/model/Casualty';
import { Property } from '@mobble/models/src/model/Property';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { type ApiPrelude, type ExtCursor } from '../types';
import { decodeMob } from './mobs';

const decodeCasualties =
  (propertyId: string) =>
  (raw: any[]): Casualty[] => {
    return raw.map(decodeCasualty(propertyId));
  };

const decodeCasualty =
  (propertyId: string) =>
  (raw: any): Casualty => {
    return {
      id: raw?.id ?? '',
      propertyId,
      number: raw?.number ?? 0,
      methodOfDisposal: raw?.methodOfDisposal ?? '',
      reason: raw?.reason ?? '',
      date: raw?.date ?? '',
      deleted: raw?.deleted ?? '',
      createdBy: {
        id: raw?.createdBy?.id ?? '',
        name: raw?.createdBy?.displayName ?? '',
        email: raw?.createdBy?.email ?? '',
      },
      mob: decodeMob(propertyId)(raw?.mob),
      paddock: raw?.paddock.id ?? '',
    };
  };

const GET_CASUALTIES = `
  query GetCasualties($propertyId: ID!) {
    casualties(propertyId: $propertyId) {
      totalCount
      nodes {
        id
        number
        methodOfDisposal
        reason
        date
        status
        createdBy {
          id
          displayName
          email
        }
        mob {
          id
          breed
          gender
          type
          DSE
          number
          classes
          ages
          deleted
          sold
          safeDate
        }
        paddock {
          id
        }
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    cursor,
  }: {
    parentId: string;
    cursor?: string;
  }): Promise<{ entities: Casualty[]; extCursor: ExtCursor }> => {
    const variables = {
      propertyId: parentId,
      after: cursor,
      pageSize: 9999,
    };

    const response = await prelude.graphql({
      query: GET_CASUALTIES,
      variables,
    });

    const entities = decodeCasualties(parentId)(response.data.casualties.nodes);

    const pageInfo = response?.data?.casualties?.pageInfo;
    const extCursor = {
      total: response?.data?.casualties?.totalCount ?? entities.length,
      nextCursor: pageInfo?.hasNextPage && pageInfo.endCursor,
    };

    return {
      entities,
      extCursor,
    };
  };

const CREATE_CASUALTY = `
  mutation AddCasualty($input: AddCasualtyInput!) {
    addCasualty(input: $input) {
      casualty {
        id
        number
        methodOfDisposal
        reason
        date
        status
        createdBy {
          id
          displayName
          email
        }
        mob {
          id
          breed
          gender
          type
          DSE
          number
          classes
          ages
          deleted
          sold
          safeDate
        }
        paddock {
          id
        }
      }
    }
  }
`;

interface CreateCasualtyActionInput {
  propertyId: Property['id'];
  number: number;
  mobId: string;
  date: any;
  methodOfDisposal: string | null;
  reason: string | null;
}

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<Casualty, 'id'> }): Promise<Casualty> => {
    const input: CreateCasualtyActionInput = {
      propertyId: entity.propertyId,
      number: entity.number,
      mobId: entity.mob.id,
      date: toISO8601(entity.date),
      methodOfDisposal: entity.methodOfDisposal,
      reason: entity.reason,
    };

    const response = await prelude.graphql({
      query: CREATE_CASUALTY,
      variables: { input },
    });

    const casualty = decodeCasualty(entity.propertyId)(
      response?.data?.addCasualty?.casualty
    );

    return casualty;
  };

const EDIT_CASUALTY = `
  mutation EditCasualty($input: EditCasualtyInput!) {
    editCasualty(input: $input) {
      casualty {
        id
        number
        methodOfDisposal
        reason
        date
        status
        createdBy {
          id
          displayName
          email
        }
        mob {
          id
          breed
          gender
          type
          DSE
          number
          classes
          ages
          deleted
          sold
          safeDate
        }
        paddock {
          id
        }
      }
    }
  }
`;

interface EditCasualtyActionInput {
  id: string;
  propertyId: Property['id'];
  number?: number | null;
  mobId?: string | null;
  date?: any | null;
  methodOfDisposal?: string | null;
  reason?: string | null;
}

export const update =
  (prelude: ApiPrelude) =>
  async ({ updatedEntity }: { updatedEntity: Casualty }): Promise<Casualty> => {
    const input: EditCasualtyActionInput = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      number: updatedEntity.number,
      mobId: updatedEntity.mob.id,
      date: toISO8601(updatedEntity.date),
      methodOfDisposal: updatedEntity.methodOfDisposal,
      reason: updatedEntity.reason,
    };

    const response = await prelude.graphql({
      query: EDIT_CASUALTY,
      variables: { input },
    });

    const casualty = decodeCasualty(updatedEntity.propertyId)(
      response?.data?.editCasualty?.casualty
    );

    return casualty;
  };

const DELETE_CASUALTY = `
  mutation DeleteCasualty($input: DeleteCasualtyInput!) {
    deleteCasualty(input: $input) {
      casualty {
        id
      }
    }
  }
`;

interface ApiDeleteCasualtyInput {
  id: string;
  propertyId: Property['id'];
}

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Casualty }): Promise<void> => {
    const input: ApiDeleteCasualtyInput = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({ query: DELETE_CASUALTY, variables: { input } });
    return;
  };
