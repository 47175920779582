import React from 'react';
import { type RainGauge } from '@mobble/models/src/model/RainGauge';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface RainGaugeEditFormProps {
  rainGauge: RainGauge;
  error?: string;
  loading?: boolean;

  onCancel: () => void;
  onSubmit: (formValues: RainGaugeEditFormValues) => void;
}

export interface RainGaugeEditFormValues {
  name: string;
}

export const RainGaugeEditForm: React.FC<RainGaugeEditFormProps> = ({
  rainGauge,
  error,
  loading,
  onCancel,
  onSubmit,
}) => {
  const form: FormBuilderProps<RainGaugeEditFormValues> = {
    flex: true,
    i18nRootKey: 'rain-gauges.rain-gauge.edit.form',
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
        initialValue: rainGauge.name,
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
