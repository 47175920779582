import { useRootSelector, useRootDispatch } from './root';
import {
  proxyUseEntity,
  thunkGetInventoryItemUsageOfDateRange,
} from '../reducers/inventoryItemUsage';
import { flush } from '../reducers/inventoryItemUsage';
import { InventoryCategory } from '@mobble/models';

export const useInventoryItemUsage = ({
  id,
  propertyId,
  type,
}: {
  id: string;
  propertyId?: string;
  type: InventoryCategory;
}) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.inventoryItemUsage);

  const proxied = proxyUseEntity({
    entityId: id,
    parentId: propertyId,
    dispatch,
    state,
  });

  const getDateRange = (startDate: string, endDate: string) => {
    dispatch(flush());
    if (proxied) {
      dispatch(
        thunkGetInventoryItemUsageOfDateRange({
          propertyId,
          id,
          type,
          startDate,
          endDate,
        })
      );
    }
  };

  return {
    ...proxied,
    getDateRange,
  };
};
