import React from 'react';
import { Color } from '@mobble/colors';
import { Text } from '@src/stories/Components/UI/Text';
import { Box } from '@src/stories/Components/Layout/Box';
import { Icon, type IconName } from '@src/stories/Components/UI/Icon';
import { Circle } from '@src/stories/Components/UI/Circle';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import styles from './drawerItem.scss';

export type DrawerItemProps = {
  label: string;
  bold?: boolean;
  active?: boolean;
  href?: string | (() => void);
  icon: IconName;
  iconAfter?: IconName;
  count?: number;
  collapsed?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const DrawerItem: React.FC<DrawerItemProps> = ({
  label,
  bold,
  active,
  icon,
  iconAfter,
  count,
  href,
  collapsed,
  disabled,
  onClick,
}) => {
  const classNames = (props) => [
    styles.drawerItem,
    disabled ? styles.disabled : null,
    collapsed ? styles.collapsed : null,
    active || props?.isActive ? styles.active : null,
  ];

  return (
    <Clickable href={href} fullWidth onClick={onClick}>
      {(props) => (
        <Box className={classNames(props)}>
          <Icon name={icon} />

          <Text bold={bold || active} variant="small" className={styles.label}>
            {label}
          </Text>

          {count ? (
            <Circle size="small" fill={Color.Red} className={styles.count}>
              <Text color={Color.White} bold variant="tiny">
                {count}
              </Text>
            </Circle>
          ) : null}

          {iconAfter && <Icon name={iconAfter} className={styles.iconAfter} />}
        </Box>
      )}
    </Clickable>
  );
};
