import React, { useState } from 'react';
import { useI18n } from '@mobble/i18n';

import { Text } from '@src/stories/Components/UI/Text';
import { Icon } from '@src/stories/Components/UI/Icon';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { Property } from '@mobble/models/src/model/Property';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { InlineOption } from '@src/stories/Components/UX/InlineOption';

import styles from './propertySelector.scss';

export interface DateRangeSelectorProps {
  properties: Property[];
  selectedPropertyIds: Property['id'][];
  setSelectedPropertyIds: (ids: Property['id'][]) => void;
}

export const PropertySelector: React.FC<DateRangeSelectorProps> = ({
  properties,
  selectedPropertyIds,
  setSelectedPropertyIds,
}) => {
  const { formatMessage } = useI18n();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onSelectProperty = (propertyId: Property['id']) => {
    setSelectedPropertyIds(
      selectedPropertyIds.includes(propertyId)
        ? selectedPropertyIds.filter((id) => id !== propertyId)
        : [...selectedPropertyIds, propertyId]
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inner}>
          <button
            className={styles.propertiesSelection}
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            <Icon name="farm" size="normal" />
            <Spacer x={1} />
            <Text
              i18n={{
                key: 'reports.properties_selected.count.label',
                params: { '%VALUE': selectedPropertyIds.length },
              }}
            />
          </button>
        </div>
      </div>
      <ModalFlyUp
        isOpen={modalIsOpen}
        title={formatMessage({
          defaultMessage: 'Select properties',
          description: 'properties_selector.modal.title',
        })}
        onClose={() => {
          setModalIsOpen(false);
        }}
      >
        {properties.map((property) => (
          <InlineOption
            key={property.id}
            type="checkbox"
            label={property.name}
            value={property.id}
            selected={selectedPropertyIds.includes(property.id)}
            onClick={() => onSelectProperty(property.id)}
          />
        ))}
      </ModalFlyUp>
    </>
  );
};
