import React, { useState } from 'react';
import * as Yup from 'yup';
import { Color, colorToHex } from '@mobble/colors';
import { useI18n, type I18nItem } from '@mobble/i18n';
import { ActionType } from '@mobble/models/src/model/Actions';
import {
  makeAppliedInventoryItem,
  type AppliedInventoryItem,
} from '@mobble/models/src/model/AppliedInventoryItem';
import {
  INVENTORY_TYPE,
  InventoryCategory,
  sortActiveProducts,
  type Inventory,
} from '@mobble/models/src/model/Inventory';
import {
  InventoryItemStatus,
  searchInventoryItems,
  type InventoryItem,
} from '@mobble/models/src/model/InventoryItem';
import {
  InventoryItemBatch,
  InventoryItemBatchChemical,
  getInventoryItemBatch,
  searchInventoryItemBatches,
} from '@mobble/models/src/model/InventoryItemBatch';
import { roundNumberTo } from '@mobble/shared/src/core/Number';
import {
  QuantityOfMassVolume,
  makeQuantity,
} from '@mobble/shared/src/core/Quantity';

import { useLinking } from '@src/hooks/useLinking';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import * as ROUTE_NAME from '@src/screens/config/routeNames';

import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Circle } from '@src/stories/Components/UI/Circle';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Text } from '@src/stories/Components/UI/Text';
import { Button } from '@src/stories/Components/UX/Button';
import { Input, InputTypes } from '@src/stories/Components/UX/Input';
import { InputError } from '@src/stories/Components/UX/InputError';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { InventoryCard } from '../Inventory/InventoryCard';
import { InventoryItemBatchCard } from '../InventoryItem/InventoryItemBatchCard';
import { InventoryItemCard } from '../InventoryItem/InventoryItemCard';

import styles from './inputInventoryItems.scss';

export interface InputInventoryItemsProps {
  onChange: (data: AppliedInventoryItem[]) => void;
  onTouched: () => void;
  value: AppliedInventoryItem[];
  totalValue?: PaddockTotalValue | MobTotalValue;
  error: any[];
  label: I18nItem | string;
  actionType?: ActionType;

  inventories: Inventory[];
  inventoryItems: InventoryItem[];
  inventoryItemBatches: InventoryItemBatch[];
}

export const InputInventoryItems: React.FC<InputInventoryItemsProps> = ({
  onChange,
  onTouched,
  value,
  totalValue,
  error,
  label,
  actionType,

  inventories = [],
  inventoryItems: allInventoryItems = [],
  inventoryItemBatches: allInventoryItemBatches = [],
}) => {
  const linkTo = useLinking();
  const { formatMessage } = useI18n();
  const [inventoryModalOpen, setInventoryModalOpen] = React.useState(false);
  const [selectedInventory, setSelectedInventory] = useState<Inventory | null>(
    null
  );
  const [selectedInventoryItem, setSelectedInventoryItem] =
    useState<InventoryItem>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const inventoryItems = allInventoryItems.filter(
    (i) => i.inventoryId === selectedInventory?.id
  );
  const inventoryItemBatches = allInventoryItemBatches.filter(
    (i) => i.inventoryItemId === selectedInventoryItem?.id
  );
  const activeInventoryItemBatches = inventoryItemBatches.filter(
    (i) =>
      i.status === InventoryItemStatus.Active &&
      !value.find((item) => item.batchId === i.id)
  );

  const activeProducts = inventoryItems.filter(
    (p) => p.status === InventoryItemStatus.Active
  );

  const getActiveProducts = sortActiveProducts(allInventoryItems);

  const goBack = () => {
    if (selectedInventoryItem) {
      setSelectedInventoryItem(null);
    } else if (selectedInventory) {
      setSelectedInventory(null);
    } else {
      handleUnselectInventory();
    }
  };

  const handleAddNewInventoryItem = () => {
    if (selectedInventory) {
      setInventoryModalOpen(false);
      linkTo(ROUTE_NAME.MODAL_INVENTORY_ITEM_CREATE, {
        inventoryId: selectedInventory.id,
      });
    }
  };

  const handleSelectInventoryItem = (inventoryItem: InventoryItem) => {
    setSearchQuery(null);
    setSelectedInventoryItem(inventoryItem);
  };

  const handleUnselectInventory = () => {
    setSelectedInventory(null);
    setSelectedInventoryItem(null);
    setSearchQuery(null);
  };

  const handleAddNewInventoryItembatch = () => {
    if (selectedInventoryItem) {
      setInventoryModalOpen(false);
      linkTo(ROUTE_NAME.MODAL_INVENTORY_ITEM_BATCH_CREATE, {
        inventoryId: selectedInventoryItem.inventoryId,
        inventoryItemId: selectedInventoryItem.id,
      });
    }
  };

  const handleSelectBatch = (inventoryItemBatch: InventoryItemBatch) => {
    const appliedInventoryItem = makeAppliedInventoryItem({
      inventoryItem: selectedInventoryItem,
      ...(inventoryItemBatch.category === InventoryCategory.Feed
        ? {
            supplierName: inventoryItemBatch.supplierName || '',
          }
        : {}),
      batchId: inventoryItemBatch.id,
    });

    onTouched();
    onChange([...value, appliedInventoryItem]);
    setInventoryModalOpen(false);
    setSelectedInventory(null);
    setSelectedInventoryItem(null);
    setSearchQuery(null);
  };

  const baseFlatListProps = {
    style: { backgroundColor: colorToHex(Color.AlmostWhite) },
    ListFooterComponent: <Spacer y={4} />,
  };

  return (
    <Box className={styles.inputInventoryItems}>
      <Box className={styles.inputInventoryItemsLabel}>
        <Text bold i18n={label} />
      </Box>
      <Box className={styles.inputInventorySelectedItems}>
        {value.map((item, index) => (
          <Box key={`${item.inventoryItemId}-${index}`}>
            <SelectedInventoryItemForm
              item={item}
              inventoryItem={
                allInventoryItems.find(
                  (i) => i.id === item.inventoryItemId
                ) as InventoryItem
              }
              inventoryItemBatches={allInventoryItemBatches.filter(
                (i) => i.inventoryItemId === item.inventoryItemId
              )}
              actionType={actionType}
              totalValue={totalValue}
              error={error[index]}
              onRemove={() => {
                onChange(value.filter((_, i) => i !== index));
              }}
              onChange={(updatedItem: AppliedInventoryItem) => {
                value[index] = updatedItem;
                onChange(value);
              }}
            />
            {index !== value.length - 1 && <Spacer y={0.5} />}
          </Box>
        ))}
      </Box>
      <Box className={styles.addProductButtonWrapper}>
        <Button
          flex
          icon="plus"
          outline
          label={{ key: 'input_inventory_item.button.add_product.label' }}
          onClick={() => {
            setInventoryModalOpen(true);
          }}
        />
      </Box>
      <ModalFlyUp
        title={formatMessage({
          defaultMessage: 'Select inventory',
          description: 'input_inventory_item.inventories.title',
        })}
        onClose={() => {
          setInventoryModalOpen(false);
        }}
        isOpen={inventoryModalOpen}
        header={
          selectedInventory && (
            <Clickable href={goBack}>
              <HStack>
                <Icon name="chevron-left" />
                <Spacer x={1} />
                <Text variant="card-title">{selectedInventory.name}</Text>
              </HStack>
            </Clickable>
          )
        }
        additionalHeader={
          selectedInventory && (
            <Box spacing={{ top: 1, right: 2, bottom: 1, left: 2 }}>
              <Box className={styles.filterTextWrapper}>
                <Input
                  showClear
                  onChange={setSearchQuery}
                  value={searchQuery}
                  placeholder={{
                    key: 'input_inventory_item.inventory_items.search.placeholder',
                  }}
                />
              </Box>
            </Box>
          )
        }
        listProps={
          selectedInventoryItem
            ? {
                items: searchQuery
                  ? searchInventoryItemBatches(
                      activeInventoryItemBatches,
                      searchQuery
                    )
                  : activeInventoryItemBatches,
                renderItem: (item) => (
                  <Box spacing={2}>
                    <InventoryItemBatchCard
                      inventoryItemBatch={item}
                      onClick={handleSelectBatch}
                    />
                  </Box>
                ),
                footer: (
                  <Box spacing={{ top: 2, right: 2, bottom: 4, left: 2 }}>
                    <Button
                      flex
                      outline
                      label={formatMessage({
                        defaultMessage: 'Add new batch',
                        description:
                          'input_inventory_item.button.add_batch.label',
                      })}
                      onClick={handleAddNewInventoryItembatch}
                    />
                  </Box>
                ),
              }
            : selectedInventory
            ? {
                ...baseFlatListProps,
                // refreshing: inventoryItems.loading,
                // onRefresh: inventoryItems.refresh,
                items: searchQuery
                  ? searchInventoryItems(activeProducts, searchQuery)
                  : activeProducts,
                renderItem: (item) => (
                  <Box spacing={2}>
                    <InventoryItemCard
                      inventoryItem={item as InventoryItem}
                      onClick={handleSelectInventoryItem}
                    />
                  </Box>
                ),
                footer: (
                  <Box spacing={{ top: 2, right: 2, bottom: 4, left: 2 }}>
                    <Button
                      flex
                      outline
                      label={{
                        key: 'input_inventory_item.button.add_inventory_item.label',
                      }}
                      onClick={handleAddNewInventoryItem}
                    />
                  </Box>
                ),
              }
            : {
                ...baseFlatListProps,
                // refreshing: inventories.loading,
                // onRefresh: inventories.refresh,
                items: actionType
                  ? actionType === ActionType.MobAction
                    ? inventories.filter(
                        (i) => i.type !== INVENTORY_TYPE.HERBICIDE_PESTICIDE
                      )
                    : inventories.filter(
                        (i) => i.type !== INVENTORY_TYPE.ANIMAL_HEALTH
                      )
                  : inventories,

                renderItem: (item) => (
                  <Box spacing={{ right: 2, bottom: 2, left: 2 }}>
                    <InventoryCard
                      inventory={item as Inventory}
                      onClick={setSelectedInventory}
                      activeProductsCount={
                        getActiveProducts((item as Inventory).id).length
                      }
                    />
                  </Box>
                ),
              }
        }
      />
    </Box>
  );
};

export interface PaddockTotalValue {
  type: ActionType.PaddockAction;
  paddockArea: number;
}

export interface MobTotalValue {
  type: ActionType.MobAction;
  mobCount: number;
}

interface SelectedInventoryItemFormProps {
  item: AppliedInventoryItem;
  inventoryItem: InventoryItem;
  inventoryItemBatches: InventoryItemBatch[];
  totalValue?: PaddockTotalValue | MobTotalValue;
  actionType?: ActionType;
  error?: any;
  onChange: (item: AppliedInventoryItem) => void;
  onRemove: () => void;
}

interface SelectedInventoryItemFormValues {
  quantity_total: QuantityOfMassVolume;
  quantity: QuantityOfMassVolume;
  whp?: number;
  esi?: number;
}

const SelectedInventoryItemForm: React.FC<SelectedInventoryItemFormProps> = ({
  item,
  inventoryItem,
  inventoryItemBatches = [],
  totalValue,
  actionType,
  error,
  onChange,
  onRemove,
}) => {
  const findBatch = getInventoryItemBatch(inventoryItemBatches);

  if (!inventoryItem) {
    return (
      <Box spacing={2}>
        <Text bold color={Color.DarkGrey}>
          {(item.category === InventoryCategory.Chemicals
            ? item.name
            : item.supplierName) || 'Unknown'}
        </Text>
        <Spinner color={Color.Green} />
      </Box>
    );
  }

  const handleFormChange = (formValues: SelectedInventoryItemFormValues) => {
    if (item.category === InventoryCategory.Chemicals) {
      if (actionType === ActionType.MobAction) {
        onChange({
          ...item,
          quantity_total: formValues.quantity_total,
          quantity: formValues.quantity,
          whp: Number(formValues.whp) ?? 0,
          esi: Number(formValues.esi) ?? 0,
        });
      } else {
        onChange({
          ...item,
          quantity_total: formValues.quantity_total,
          quantity: formValues.quantity,
          whp: Number(formValues.whp) ?? 0,
        });
      }
    } else {
      onChange({
        ...item,
        quantity_total: formValues.quantity_total,
        quantity: formValues.quantity,
      });
    }
  };

  const quantityTotal =
    (totalValue.type === ActionType.MobAction
      ? totalValue.mobCount
      : totalValue.paddockArea) || 1;

  const form: FormBuilderProps<SelectedInventoryItemFormValues> = {
    tagName: 'section',
    i18nRootKey:
      actionType === ActionType.MobAction
        ? 'input_inventory_item.mob-action.form'
        : 'input_inventory_item.paddock-action.form',
    fields: [
      ...(item.category === InventoryCategory.Chemicals
        ? [
            {
              name: 'batchId',
              type: 'display' as InputTypes,
              initialValue: (
                findBatch(item.batchId) as InventoryItemBatchChemical
              )?.name,
            },
          ]
        : [
            {
              name: 'supplierName',
              type: 'display' as InputTypes,
              initialValue: item.supplierName,
            },
          ]),
      {
        name: 'quantity',
        type: 'quantity-mass-volume',
        initialValue: item.quantity,
        min: 0,
        step: 0.0000001,
        onChange: (values) => {
          const value = Number(
            roundNumberTo(3)(values.quantity.value * quantityTotal)
          );
          return {
            ...values,
            quantity_total: makeQuantity(
              values.quantity.type,
              values.quantity.unit,
              value
            ),
          };
        },
      },
      {
        name: 'quantity_total',
        type: 'quantity-mass-volume',
        initialValue: makeQuantity(
          item.quantity.type,
          item.quantity.unit,
          item.quantity.value * quantityTotal
        ),
        min: 0,
        step: 0.0000001,
        onChange: (values) => {
          const value = Number(
            roundNumberTo(6)(values.quantity_total.value / quantityTotal)
          );
          return {
            ...values,
            quantity: makeQuantity(
              values.quantity_total.type,
              values.quantity_total.unit,
              value
            ),
          };
        },
      },
      ...(item.category === InventoryCategory.Chemicals
        ? [
            {
              name: 'whp',
              type: 'number' as InputTypes,
              initialValue: String(item.whp || 0),
              min: 0,
            },
          ]
        : []),

      ...(actionType === ActionType.MobAction &&
      item.category === InventoryCategory.Chemicals
        ? [
            {
              name: 'esi',
              type: 'number' as InputTypes,
              initialValue: String(item.esi),
              min: 0,
            },
          ]
        : []),
    ],
    onChange: handleFormChange,
    containerComponent: (props) => {
      return (
        <Box spacing={{ top: 2 }}>
          <Box className={styles.productInputWrapper}>
            <HStack alignment="center">
              <Box spacing={{ left: 1, right: 2 }}>
                <Text i18n={props.makeI18n('label')} />
              </Box>
              <Box flex className={styles.inputWrapper}>
                {props.input}
              </Box>
            </HStack>
          </Box>
          <InputError error={error && error[props.field.name]} />
        </Box>
      );
    },
  };

  return (
    <Box spacing={2} background={Color.LightGrey}>
      <HStack>
        <InventoryItemCard inventoryItem={inventoryItem} />

        <Clickable href={onRemove} spacing={{ left: 2 }}>
          <Circle
            size="normal"
            iconSize="small"
            stroke={Color.Red}
            color={Color.Red}
            icon="trash"
          />
        </Clickable>
      </HStack>
      <FormBuilder {...form} />
    </Box>
  );
};

export const validateInputInventoryItems = (args: {
  t: (...args: string[]) => I18nItem;
  requiredTranslated: (fieldName: string) => undefined | string;
}) => {
  const schema = Yup.array(
    Yup.object({
      quantity_total: Yup.object({
        value: Yup.number(),
      }).nullable(),
      quantity: Yup.object({
        value: Yup.number().required(args.requiredTranslated('quantity')),
      })
        .nullable()
        .required(args.requiredTranslated('quantity')),
      // whp: Yup.string().when('category', {
      //   is: InventoryCategory.Chemicals,
      //   then: Yup.string().required(args.requiredTranslated('whp')),
      // }),
      esi: Yup.string(),
    })
  );
  return schema;
};
