import React from 'react';

import { useI18n } from '@mobble/i18n';
import {
  type Point,
  pointIsInPolygon,
} from '@mobble/models/src/model/MapGeometry';
import {
  sortAnyContainingPaddocks,
  sortOptionsNameDistanceFromMe,
} from '@mobble/models/src/model/Paddock';
import { fromRawDate } from '@mobble/shared/src/core/Date';
import { usePaddockGeometries } from '@mobble/store/src/hooks/paddockGeometries';
import { useExtStatus } from '@mobble/store/src/hooks/root';

import { useFormError } from '@src/hooks/useFormError';
import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import { useMyLocation } from '../../hooks/useMyLocation';
import { usePaddockCardFactory } from '../../hooks/usePaddockCardFactory';
import {
  TaskEditForm,
  type TaskEditFormValues,
} from '../../stories/Views/Task/TaskEditForm';
import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import { useTaskScreen, useTaskScreenHeader } from './useTaskScreen';

export const TaskEditHeader: React.FC<ScreenRendererProps> = ({ route }) => {
  const { taskId = '' } = route.params;
  const props = useTaskScreenHeader(taskId, true);

  return <ScreenHeader {...props} />;
};

export const TaskEdit: React.FC<ScreenRendererProps> = (props) => {
  const { taskId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const { location } = useMyLocation();
  const linkTo = useLinking();
  const goBack = useNavigateBack();
  const { Prelude, task, paddocks, properties } = useTaskScreen(taskId);

  const paddockGeometries = usePaddockGeometries(properties.selected?.id);
  const makePaddockCard = usePaddockCardFactory({
    propertyId: properties.selected?.id,
    location,
  });
  const extStatus = useExtStatus('tasks', 'update', taskId);
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus('tasks', 'delete', taskId);
  const deleteLoading = extStatusDelete?.loading;
  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Task',
      description: 'screen.title.task_detail',
    }),
    active: Boolean(extStatus?.error || extStatusDelete?.error),
  });

  const paddockSortMeta = {
    origin: location || undefined,
    paddockGeometries: paddockGeometries.entities,
  };

  const sortPaddockOptions = sortAnyContainingPaddocks((o) => o.entity)(
    paddockSortMeta
  );

  const paddockOptions = paddocks.entities.map((p) => ({
    label: p.name,
    value: p.id,
    entity: p,
    component: makePaddockCard(p),
  }));

  if (Prelude) {
    return Prelude;
  }

  const findPaddockIdForPoint = (point: Point) => {
    return paddockGeometries.entities.find((pg) =>
      pointIsInPolygon(point[0], pg.polygon)
    )?.paddockId;
  };

  const handleSubmit = (formValues: TaskEditFormValues) => {
    if (!task.entity) {
      return;
    }

    const point = formValues?.location ? formValues?.location[0] : null;

    const updatedTask = {
      ...task.entity,
      title: formValues.title,
      description: formValues.description,
      dueDate: formValues.due_date
        ? fromRawDate(formValues.due_date)
        : undefined,
      paddockId: formValues.paddock_id,
      location: point,
      assignedToUsers: (formValues.assigned_to || []).map((id) => ({
        id,
        email: id,
        name: id,
      })),
    };

    task
      .update(updatedTask)
      .then(() => {
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    task
      .remove()
      .then(() => {
        linkTo(ROUTE_NAME.TASKS_LIST);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <TaskEditForm
        task={task.entity}
        paddockOptions={paddockOptions}
        paddockSortOptions={sortOptionsNameDistanceFromMe}
        sortPaddockOptions={sortPaddockOptions}
        users={properties.selected.users}
        findPaddockIdForPoint={findPaddockIdForPoint}
        error={formError}
        loading={formLoading}
        onCancel={goBack}
        onTouched={() => {}}
        onSubmit={handleSubmit}
      />

      <DeleteForm
        i18nRootKey="tasks.task.delete"
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.TASK_EDIT,
  header: TaskEditHeader,
  component: TaskEdit,
};
