import { type Chat } from '@mobble/models/src/model/Chat';
import { type ApiPrelude, type ExtCursor } from '../types';

const GET_CHATS = `
  query GetChatsPaginated($propertyId: ID!, $after: String) {
    commentsPaginated(propertyId: $propertyId, after: $after) {
      totalCount
      nodes {
        id
        body
        date
        user {
          id
          displayName
          email
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const decodeChats =
  (propertyId: string) =>
  (data?: any): Chat[] =>
    (data || []).map(decodeChat(propertyId));

const decodeChat =
  (propertyId: string) =>
  (data?: any): Chat => ({
    id: data?.id || '',
    body: data?.body || '',
    date: data?.date || '',
    propertyId,
    user: {
      id: data?.user.id || '',
      name: data?.user.displayName || '',
      email: data?.user.email || '',
    },
  });

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    cursor,
  }: {
    parentId: string;
    cursor?: string;
  }): Promise<{ entities: Chat[]; extCursor: ExtCursor }> => {
    const response = await prelude.graphql({
      query: GET_CHATS,
      variables: {
        propertyId: parentId,
        after: cursor || null,
      },
    });

    const entities = decodeChats(parentId)(
      response?.data?.commentsPaginated?.nodes || []
    );

    const pageInfo = response?.data?.commentsPaginated?.pageInfo;

    const extCursor = {
      total: response?.data?.commentsPaginated?.totalCount ?? entities.length,
      cursorNext: pageInfo?.hasNextPage && pageInfo?.endCursor,
    };

    return { entities, extCursor };
  };

const ADD_CHAT = `
  mutation AddChat($input: AddCommentInput!) {
    addComment(input: $input) {
      comment {
        id
        body
        date
        user {
          id
          displayName
          email
        }
      }
    }
  }
`;

interface ApiCreateChatInput {
  propertyId: string;
  body: string;
}

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<Chat, 'id'> }): Promise<Chat> => {
    const input: ApiCreateChatInput = {
      propertyId: entity.propertyId,
      body: entity.body,
    };

    const response = await prelude.graphql({
      query: ADD_CHAT,
      variables: { input },
    });
    const chat = decodeChat(input.propertyId)(response.data.addComment.comment);

    return chat;
  };
