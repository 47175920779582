import { QuantityOfMassVolume } from '@mobble/shared/src/core/Quantity';
import Fuse from 'fuse.js';
import { InventoryItemStatus } from './InventoryItem';
import { InventoryCategory } from './Inventory';

export interface BaseInventoryItemBatch {
  id: string;
  inventoryItemId: string;
  propertyId: string;

  quantity: QuantityOfMassVolume;

  dateReceived?: string;
  created: string;
  status: InventoryItemStatus;
  notes?: string;
}

export type InventoryItemBatchFeed = {
  category: InventoryCategory.Feed;
  supplierName?: string;
  pricePerUnitCents?: number;
} & BaseInventoryItemBatch;

export type InventoryItemBatchChemical = {
  category: InventoryCategory.Chemicals;
  name: string;
  dateOfManufacture?: string;
  dateExpiry?: string;
} & BaseInventoryItemBatch;

export type InventoryItemBatch =
  | InventoryItemBatchFeed
  | InventoryItemBatchChemical;

export const searchInventoryItemBatches = (
  inventoryItemBatches: InventoryItemBatch[],
  searchQuery: string
): InventoryItemBatch[] => {
  const fuse = new Fuse(
    inventoryItemBatches.map((t) => ({
      ...t,
    })),
    {
      keys: [{ name: 'name', weight: 5 }],
      threshold: 0.5,
      shouldSort: true,
    }
  );

  return fuse.search(searchQuery).map((a) => a.item);
};

export const getInventoryItemBatch =
  (inventoryItemBatches: InventoryItemBatch[]) =>
  (id: string): InventoryItemBatch =>
    inventoryItemBatches.find((p) => p.id === id);
