import React from 'react';
import * as Yup from 'yup';

import { useI18n } from '@mobble/i18n';
import { Casualty } from '@mobble/models/src/model/Casualty';
import { type Paddock, paddockForMob } from '@mobble/models/src/model/Paddock';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { Color } from '@mobble/theme';

import { MobCard } from '@src/components';
import { Box } from '@src/stories/Components/Layout/Box';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface CasualtyEditFormProps {
  paddocks: Paddock[];
  casualty: Casualty;
  propertyTypes: ConfiguredPropertyType[];
  error?: string;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (formValues: CasualtyEditFormValues) => void;
}

export interface CasualtyEditFormValues {
  id: string;
  date: any;
  number: number;
  propertyId: string;
  methodOfDisposal: string | null;
  reason: string | null;
}

export const CasualtyEditForm: React.FC<CasualtyEditFormProps> = ({
  paddocks,
  casualty,
  propertyTypes,
  error,
  loading,
  onCancel,
  onSubmit,
}) => {
  const { translate } = useI18n();
  const mob = {
    ...casualty.mob,
    size: casualty.number,
  };
  const form: FormBuilderProps<CasualtyEditFormValues> = {
    flex: true,
    i18nRootKey: 'casualty.edit.form',
    fields: [
      {
        name: 'selected_mobs_preview',
        type: 'custom',
        containerComponent: false,
        component: () => {
          return (
            <Box background={Color.NeutralsBgDisabled} spacing={2}>
              <MobCard
                propertyTypes={propertyTypes}
                paddockName={paddockForMob(paddocks)(mob)?.name}
                mob={mob}
              />
            </Box>
          );
        },
      },
      {
        name: 'number',
        type: 'number',
        required: true,
        initialValue: casualty.number,
        validation: Yup.number()
          .integer(
            translate({
              key: 'mob.casualty.edit.form.number.error.label',
            }) || ''
          )
          .required(),
        min: 1,
      },
      {
        name: 'date',
        type: 'date',
        initialValue: casualty.date,
        required: true,
      },
      {
        name: 'reason',
        type: 'textarea',
        initialValue: casualty.reason || '',
      },
      {
        name: 'methodOfDisposal',
        type: 'textarea',
        initialValue: casualty.methodOfDisposal || '',
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
