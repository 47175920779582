import { type Chat } from '@mobble/models/src/model/Chat';
import mobbleService from '@mobble/service';
import {
  ExtFilterParams,
  type ExtStatusPerActionPerParent,
} from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';

export interface ChatsState {
  extStatus: ExtStatusPerActionPerParent;
  entities: Chat[];
  filter: {};
  sort: {};
}

export const defaultState: ChatsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const merger = (args: {
  current: Chat[];
  incoming: Chat[];
  parentId: string;
  extFilter?: ExtFilterParams;
  cursor?: string;
}): Chat[] => {
  // cursor is defined when loading past messages
  const mergedData = !args.cursor
    ? args.incoming.concat(args.current)
    : args.current.concat(args.incoming);

  const map = new Map(mergedData.map((obj) => [obj.id, obj]));
  const combined = Array.from(map.values());
  return combined;
};

const entitySlice = entitySliceFactory<ChatsState>({
  name: 'chats',
  initialState: defaultState,
  merger,
  toParentId: ({ propertyId }: Chat) => propertyId,
  onGetAll: mobbleService.api.chats.get,
  onCreate: mobbleService.api.chats.create,
  onUpdate: () => Promise.reject(),
  onDelete: () => Promise.reject(),
});

export const {
  thunkGetAll,
  thunkCreate,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
