import Cattle from './cattle.svg';
import Goat from './goat.svg';
import Mixed from './mixed.svg';
import Sheep from './sheep.svg';

export default {
  Cattle,
  Goat,
  Sheep,
  Mixed,
};
