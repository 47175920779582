import { ComponentPropsWithoutRef } from 'react';
import { defineMessages } from 'react-intl';
import classNames from 'classnames/bind';

import { useMessages } from '@mobble/i18n';

import Button, { ButtonProps } from '../../Button';
import Icon, { IconName } from '../../Icon';

import styles from './Header.scss';
const cx = classNames.bind(styles);

interface DialogHeaderStrings {
  close: string;
}

type PickedProps = 'children' | 'className' | 'style';
export interface DialogHeaderProps
  extends Pick<ComponentPropsWithoutRef<'div'>, PickedProps> {
  heading?: string;
  icon?: IconName;
  hideCloseButton?: boolean;
  strings?: DialogHeaderStrings;
  onCloseClick?: ButtonProps['onClick'];
}

const messages = defineMessages({
  close: {
    defaultMessage: 'Close',
    description: 'Dialog close button label',
  },
});

/**
 * DialogHeader component displays heading text and close button
 * To be used in a Dialog component
 */
const DialogHeader = ({
  heading,
  icon,
  hideCloseButton,
  children,
  onCloseClick,
  className,
  ...others
}: DialogHeaderProps) => {
  const strings = useMessages(messages);
  const rootClasses = cx(
    {
      Header: true,
    },
    className
  );

  const hasHeading = Boolean(heading) || Boolean(icon);

  return (
    <header className={rootClasses} {...others}>
      {hasHeading && (
        <div className={styles.headingWrapper}>
          {icon && <Icon name={icon} size="medium" className={styles.icon} />}
          {heading && <h1>{heading}</h1>}
        </div>
      )}
      {children}
      {!hideCloseButton && (
        <Button
          aria-label={strings['close']}
          outline
          intent="neutral"
          size="small"
          icon="close"
          tabIndex={0}
          onClick={onCloseClick}
          className={styles.closeButton}
        />
      )}
    </header>
  );
};

export default DialogHeader;
