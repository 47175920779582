import { InventoryItemBatch } from '@mobble/models/src/model/InventoryItemBatch';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { FilterPerProperty } from '../lib/filter';

export interface InventoryItemBatchesState {
  extStatus: ExtStatusPerActionPerParent;
  entities: InventoryItemBatch[];
  filter: FilterPerProperty;
  sort: {};
}

export const defaultState: InventoryItemBatchesState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const name = 'inventoryItemBatches';

const entitySlice = entitySliceFactory<InventoryItemBatchesState>({
  name,
  initialState: defaultState,
  toParentId: ({ propertyId }) => propertyId,
  onGetAll: mobbleService.api.inventoryItemBatches.get,
  onCreate: mobbleService.api.inventoryItemBatches.create,
  onUpdate: mobbleService.api.inventoryItemBatches.update,
  onDelete: mobbleService.api.inventoryItemBatches.remove,
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;
export const { updateExtStatus } = entitySlice.slice.actions as any;

export default entitySlice.slice.reducer;
