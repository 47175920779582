import { polygonToArea } from '@mobble/models/src/model/MapGeometry';
import { type PaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import { convertArea } from '@mobble/shared/src/core/Quantity';
import { colorToHex } from '@mobble/colors';
import { type ApiPrelude } from '../types';

const GET_MAP_PADDOCKS = `
  query GetMapPaddocks($propertyId: ID!) {
    mapPaddocks(propertyId: $propertyId) {
      nodes {
        paddockId
        colour
        geometry
      }
    }
  }
`;

const decodePaddockGeometry =
  (propertyId: string) =>
  (raw: any): PaddockGeometry => {
    return {
      id: raw?.paddockId,
      propertyId,
      paddockId: raw?.paddockId,
      polygon: raw?.geometry,
      color: raw?.colour ? colorToHex(raw?.colour) : '',
    };
  };

const decodePaddockGeometries =
  (propertyId: string) =>
  (raw: any[]): PaddockGeometry[] =>
    raw.map(decodePaddockGeometry(propertyId));

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{ entities: PaddockGeometry[] }> => {
    const response = await prelude.graphql({
      query: GET_MAP_PADDOCKS,
      variables: {
        propertyId: parentId,
      },
    });
    const entities = decodePaddockGeometries(parentId)(
      response?.data?.mapPaddocks?.nodes ?? []
    );
    return { entities };
  };

const UPDATE_PADDOCK_GEOMETRY = `
  mutation EditPaddock($input: EditPaddockInput!) {
    editPaddock(input: $input) {
      paddock {
        geometry {
          type
          coordinates
        }
      }
    }
  }
`;

export const create =
  (prelude: ApiPrelude) =>
  async ({
    entity,
  }: {
    entity: Omit<PaddockGeometry, 'id'>;
  }): Promise<PaddockGeometry> => {
    return update(prelude)({
      updatedEntity: { ...entity, id: entity.paddockId },
    });
  };

export const update =
  (prelude: ApiPrelude) =>
  async ({
    updatedEntity,
  }: {
    updatedEntity: PaddockGeometry;
  }): Promise<PaddockGeometry> => {
    let input: any = {
      id: updatedEntity.paddockId,
      propertyId: updatedEntity.propertyId,
      geometry: updatedEntity.polygon,
    };

    if (updatedEntity.updateSizeOnGeometryChange) {
      const size = polygonToArea(updatedEntity.polygon);
      const sizeInHa = convertArea('ha')(size).value;

      input = {
        ...input,
        size: {
          value: sizeInHa,
          unit: 'ha',
        },
      };
    }

    await prelude.graphql({
      query: UPDATE_PADDOCK_GEOMETRY,
      variables: { input },
    });

    return updatedEntity;
  };

export const DELETE_PADDOCK_GEOMETRY = `
  mutation DeletePaddockGeom($input: DeletePaddockGeomInput!) {
    deletePaddockGeom(input: $input) {
      paddock {
        id
      }
    }
  }
`;

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: PaddockGeometry }): Promise<void> => {
    const input = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({
      query: DELETE_PADDOCK_GEOMETRY,
      variables: { input },
    });
    return;
  };
