import React from 'react';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface RainGaugeCreateFormProps {
  error?: string;
  loading?: boolean;

  onCancel: () => void;
  onSubmit: (formValues: RainGaugeCreateFormValues) => void;
}

export interface RainGaugeCreateFormValues {
  name: string;
}

export const RainGaugeCreateForm: React.FC<RainGaugeCreateFormProps> = ({
  error,
  loading,
  onCancel,
  onSubmit,
}) => {
  const form: FormBuilderProps<RainGaugeCreateFormValues> = {
    flex: true,
    i18nRootKey: 'rain-gauges.rain-gauge.create.form',
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
