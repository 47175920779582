import React from 'react';

export const reactIntersperse = (
  separator: JSX.Element,
  array: JSX.Element[]
): JSX.Element[] => {
  return array
    .reduce<React.ReactElement[]>((acc, curr, i) => {
      if (i === 0) {
        return [curr];
      }
      return [...acc, separator, curr];
    }, [])
    .map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>);
};
