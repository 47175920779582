import { roundNumberTo } from '@mobble/shared/src/core/Number';
import { BaseEntity } from './BaseEntity';

export interface PaddockGroupedStockingRate extends BaseEntity {
  id: string;
  propertyId: string;
  paddockId: string;
  stockingRate: {
    stockingRate: number;
    stockingRateByArea: number;
    totalSheep: number;
    totalCattle: number;
    totalGoats: number;
    totalOther: number;
  };
}

export interface PaddockGroupedStockingRateTotals {
  head: number;
  DSE: number;
  DSE_HA: number;
}

export const findPaddockGroupedStockingRate =
  (paddockGroupedStockingRates: PaddockGroupedStockingRate[]) =>
  (paddockId: string) =>
    paddockGroupedStockingRates.find(
      (paddockGroupedStockingRate) => paddockGroupedStockingRate.id === paddockId
    );

export const toTotals = (
  paddockGroupedStockingRate: PaddockGroupedStockingRate
): PaddockGroupedStockingRateTotals => {
  const { stockingRate } = paddockGroupedStockingRate;

  const totals = {
    head: 0,
    DSE: 0,
    DSE_HA: 0,
  };

  Object.entries(stockingRate).forEach(([key, value]) => {
    if (key.includes('total')) {
      totals.head += value;
    } else if (key === 'stockingRate') {
      totals.DSE = value;
    } else if (key === 'stockingRateByArea') {
      totals.DSE_HA = value;
    }
  });

  return {
    head: Number(roundNumberTo(2)(totals.head)),
    DSE: Number(roundNumberTo(2)(totals.DSE)),
    DSE_HA: Number(roundNumberTo(2)(totals.DSE_HA)),
  };
};
