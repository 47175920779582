import React, {
  ComponentPropsWithoutRef,
  KeyboardEvent,
  useState,
} from 'react';
import classNames from 'classnames/bind';
import { useI18n } from '@mobble/i18n';

import Button from '@src/components/Button';
import Input from '@src/components/Input';

import styles from './ChatForm.scss';
const cx = classNames.bind(styles);

export interface ChatFormProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onSubmit'> {
  /**
   * Returns a chat message's body text
   */
  onSubmit: (value: string) => void;
}

/**
 * ChatForm displays a text input and a submit button
 */
const ChatForm: React.FC<ChatFormProps> = ({
  onSubmit,
  className,
  ...others
}) => {
  const { formatMessage } = useI18n();
  const [value, setValue] = useState('');
  const rootClasses = cx(
    {
      ChatForm: true,
      showButton: !value.trim(),
    },
    className
  );

  const handleInputChange = (value: string) => {
    setValue(value);
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && event.code === 'Enter') {
      event.preventDefault();
      handleSendClick();
    }
  };

  const handleSendClick = () => {
    onSubmit(value);
    setValue('');
  };

  return (
    <div className={rootClasses} {...others}>
      <Input
        id="chat-input"
        type="textarea"
        value={value}
        maxLength={1024}
        placeholder={formatMessage({
          defaultMessage: 'Type a message...',
          description: 'chat.message.input.placeholder',
        })}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        className={styles.input}
      />

      <Button
        label=""
        disabled={!value.trim()}
        icon="chat"
        aria-label={formatMessage({
          defaultMessage: 'Send message',
          description: 'chat.message.input.button.label',
        })}
        onClick={handleSendClick}
      />
    </div>
  );
};

export default ChatForm;
