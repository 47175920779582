import { Box } from '@src/stories/Components/Layout/Box';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Text } from '@src/stories/Components/UI/Text';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Icon } from '@src/stories/Components/UI/Icon';

import styles from './dashboardTrialCountDown.scss';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { useI18n } from '@mobble/i18n/src';

export const DashboardTrialCountDown: React.FC<{
  daysLeft: number;
}> = ({ daysLeft }) => {
  const { formatMessage } = useI18n();
  return (
    <Box className={styles.countDownContainer}>
      <Icon name="date" size="large" />
      <Spacer x={2} />
      <Text className={styles.countDownDays}>{daysLeft}</Text>
      <Spacer x={2} />
      <Text
        className={styles.daysLeftText}
        i18n={formatMessage({
          defaultMessage: 'Days remaining in your trial',
          description: 'dashboard.trialCountDown.daysLeft',
        })}
      />
      <Box className={styles.billingPageButtonAlign}>
        <Clickable fullWidth href={ROUTE_NAME.SETTINGS_BILLING}>
          <HStack flex alignment="center">
            <Text
              className={styles.billingPageText}
              i18n={formatMessage({
                defaultMessage: 'Go to Billing page',
                description: 'dashboard.trialCountDown.goToBillingPage',
              })}
            />
            <Icon name="chevron-right" size="large" />
          </HStack>
        </Clickable>
      </Box>
    </Box>
  );
};
