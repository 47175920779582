// import {
//   type HeaderProps,
//   type RouteProps,
//   type RouteName,
// } from './config/types';

// import ActionQueue from './ActionQueue/ActionQueue';
import ForgotPassword from './Auth/ForgotPassword';
import InvitationSetUp from './Auth/InvitationSetUp';
// import NoPropertyAccess from './SetUp/NoPropertyAccess';
import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';
import Callback from './Callback/Callback';
import ImportAgWorldPaddocks from './Callback/ImportAgWorldPaddocks';
import CasualtyCreate from './Casualties/CasualtyCreate';
import CasualtyDetail from './Casualties/CasualtyDetail';
import CasualtyEdit from './Casualties/CasualtyEdit';
import Chat from './Chat/Chat';
import CustomMapLayerDetail from './CustomMapLayers/CustomMapLayerDetail';
import CustomMapLayerEdit from './CustomMapLayers/CustomMapLayerEdit';
import Dashboard from './Dashboard/Dashboard';
import Inventories from './Inventories/Inventories';
import InventoryEdit from './Inventories/InventoryEdit';
import InventoryItemBatchCreate from './Inventories/InventoryItemBatchCreate';
import InventoryItemBatchDetail from './Inventories/InventoryItemBatchDetail';
import InventoryItemBatchEdit from './Inventories/InventoryItemBatchEdit';
import InventoryItemCreate from './Inventories/InventoryItemCreate';
import InventoryItemDetail from './Inventories/InventoryItemDetail';
import InventoryItemEdit from './Inventories/InventoryItemEdit';
import InventoryItems from './Inventories/InventoryItems';
import Map from './Map/Map';
import MapAssetCreate from './MapAssets/MapAssetCreate';
import MapAssetDetail from './MapAssets/MapAssetDetail';
import MapAssetEdit from './MapAssets/MapAssetEdit';
import MapAssets from './MapAssets/MapAssets';
import MobActionCreate from './MobActions/MobActionCreate';
import MobActionDetail from './MobActions/MobActionDetail';
import MobActionEdit from './MobActions/MobActionEdit';
import MobCreate from './Mobs/MobCreate';
import MobDetail from './Mobs/MobDetail';
// import MobDetailHistory from './Mobs/MobDetailHistory';
// import MobDetailInfo from './Mobs/MobDetailInfo';
import MobEdit from './Mobs/MobEdit';
import Mobs from './Mobs/Mobs';
import NaturalIncreaseDetail from './NaturalIncreases/NaturalIncreaseDetail';
import NaturalIncreaseEdit from './NaturalIncreases/NaturalIncreaseEdit';
import PaddockActionCreate from './PaddockActions/PaddockActionCreate';
import PaddockActionDetail from './PaddockActions/PaddockActionDetail';
import PaddockActionEdit from './PaddockActions/PaddockActionEdit';
import PaddockCreate from './Paddocks/PaddockCreate';
import PaddockDetail from './Paddocks/PaddockDetail';
// import PaddockDetailHistory from './Paddocks/PaddockDetailHistory';
// import PaddockDetailInfo from './Paddocks/PaddockDetailInfo';
// import PaddockDetailMobs from './Paddocks/PaddockDetailMobs';
// import PaddockDetailTasks from './Paddocks/PaddockDetailTasks';
import PaddockEdit from './Paddocks/PaddockEdit';
import PaddockGroupCreate from './Paddocks/PaddockGroups/PaddockGroupCreate';
import PaddockGroupEdit from './Paddocks/PaddockGroups/PaddockGroupEdit';
import PaddockMergeMobs from './Paddocks/PaddockMergeMobs';
import PaddockMoveMob from './Paddocks/PaddockMoveMob';
import Paddocks from './Paddocks/Paddocks';
import PropertyCreate from './Properties/PropertyCreate';
import PurchaseDetail from './Purchases/PurchaseDetail';
import PurchaseEdit from './Purchases/PurchaseEdit';
import RainGaugeCreate from './RainGauge/RainGaugeCreate';
import RainGaugeDetail from './RainGauge/RainGaugeDetail';
import RainGaugeEdit from './RainGauge/RainGaugeEdit';
import RainGaugeReading from './RainGauge/RainGaugeReading';
import RainGaugeReadingCreate from './RainGauge/RainGaugeReadingCreate';
import RainGaugeReadingEdit from './RainGauge/RainGaugeReadingEdit';
import RainGauges from './RainGauge/RainGauges';
import ReferFarmer from './ReferFarmer/ReferFarmer';
import Reports from './Reports/Reports';
// import RainGaugeDetailHistory from './RainGauge/RainGaugeDetailHistory';
// import RainGaugeDetailInfo from './RainGauge/RainGaugeDetailInfo';
import SaleCreate from './Sales/SaleCreate';
import SaleDetail from './Sales/SaleDetail';
import SaleEdit from './Sales/SaleEdit';
import Settings from './Settings/Settings';
// import SettingsHelp from './Settings/SettingsHelp';
// import SettingsLogger from './Settings/SettingsLogger';
// import SettingsOffline from './Settings/SettingsOffline';
import SettingsBilling from './Settings/SettingsBilling';
import SettingsPropertyClassesTypes from './Settings/SettingsPropertyClassesTypes';
import SettingsPropertyClassesTypesCreate from './Settings/SettingsPropertyClassesTypesCreate';
import SettingsPropertyClassesTypesEdit from './Settings/SettingsPropertyClassesTypesEdit';
import SettingsPropertyClassesTypesGroup from './Settings/SettingsPropertyClassesTypesGroup';
import SettingsPropertyCustomMapLayers from './Settings/SettingsPropertyCustomMapLayers';
import SettingsPropertyInformation from './Settings/SettingsPropertyInformation';
import SettingsPropertyMobbleApps from './Settings/SettingsPropertyMobbleApps';
import SettingsPropertyMobbleConnectEditUser from './Settings/SettingsPropertyMobbleConnectEditUser';
import SettingsPropertyMobbleConnectInviteUser from './Settings/SettingsPropertyMobbleConnectInviteUser';
import SettingsPropertyMobbleConnectUser from './Settings/SettingsPropertyMobbleConnectUser';
import SettingsPropertyMobbleConnectUsers from './Settings/SettingsPropertyMobbleConnectUsers';
import SettingsPropertyUsers from './Settings/SettingsPropertyUsers';
import SettingsPropertyUsersInviteUser from './Settings/SettingsPropertyUsersInviteUser';
import SettingsPropertyUsersUserEdit from './Settings/SettingsPropertyUsersUserEdit';
import SettingsUnits from './Settings/SettingsUnits';
import SettingsUser from './Settings/SettingsUser';
import SetUpDisplayName from './SetUp/SetUpDisplayName';
import SetUpProperty from './SetUp/SetUpProperty';
import Summaries from './Summaries/Summaries';
import SummaryCasualties from './Summaries/SummaryCasualties';
import SummaryEvents from './Summaries/SummaryEvents';
// import SummaryLivestockNumbers from './Summaries/SummaryLivestockNumbers';
import SummaryMobActions from './Summaries/SummaryMobActions';
import SummaryNaturalIncreases from './Summaries/SummaryNaturalIncreases';
import SummaryPaddockActions from './Summaries/SummaryPaddockActions';
import SummaryPurchases from './Summaries/SummaryPurchases';
import SummarySales from './Summaries/SummarySales';
import TaskCreate from './Tasks/TaskCreate';
import TaskDetail from './Tasks/TaskDetail';
import TaskEdit from './Tasks/TaskEdit';
import Tasks from './Tasks/Tasks';

const screens = {
  // ActionQueue,
  CasualtyCreate,
  CasualtyDetail,
  CasualtyEdit,
  Callback,
  CustomMapLayerDetail,
  CustomMapLayerEdit,
  Dashboard,
  Chat,
  ForgotPassword,
  ImportAgWorldPaddocks,
  Inventories,
  InventoryEdit,
  InventoryItemCreate,
  InventoryItemDetail,
  InventoryItemEdit,
  InventoryItems,
  InventoryItemBatchCreate,
  InventoryItemBatchDetail,
  InventoryItemBatchEdit,
  InvitationSetUp,
  Map,
  MapAssetCreate,
  MapAssetDetail,
  MapAssetEdit,
  MapAssets,
  MobActionCreate,
  MobActionDetail,
  MobActionEdit,
  MobCreate,
  MobDetail,
  // MobDetailHistory,
  // MobDetailInfo,
  MobEdit,
  Mobs,
  NaturalIncreaseDetail,
  NaturalIncreaseEdit,
  PaddockActionCreate,
  PaddockActionDetail,
  PaddockActionEdit,
  PaddockCreate,
  PaddockDetail,
  // PaddockDetailHistory,
  // PaddockDetailInfo,
  // PaddockDetailMobs,
  // PaddockDetailTasks,
  PaddockEdit,
  PaddockGroupCreate,
  PaddockGroupEdit,
  PaddockMergeMobs,
  PaddockMoveMob,
  Paddocks,
  PropertyCreate,
  PurchaseDetail,
  PurchaseEdit,
  RainGaugeCreate,
  RainGaugeDetail,
  // RainGaugeDetailHistory,
  // RainGaugeDetailInfo,
  SaleCreate,
  SaleEdit,
  SaleDetail,
  RainGaugeEdit,
  RainGaugeReading,
  RainGaugeReadingCreate,
  RainGaugeReadingEdit,
  ReferFarmer,
  RainGauges,
  Reports,
  Settings,
  SettingsBilling,
  SettingsPropertyCustomMapLayers,
  // SettingsHelp,
  // SettingsLogger,
  // SettingsOffline,
  SettingsPropertyClassesTypes,
  SettingsPropertyClassesTypesCreate,
  SettingsPropertyClassesTypesEdit,
  SettingsPropertyClassesTypesGroup,
  SettingsPropertyMobbleConnectUser,
  SettingsPropertyMobbleConnectUsers,
  SettingsPropertyMobbleConnectEditUser,
  SettingsPropertyMobbleConnectInviteUser,
  SettingsPropertyInformation,
  SettingsPropertyUsers,
  SettingsPropertyUsersInviteUser,
  SettingsPropertyUsersUserEdit,
  SettingsPropertyMobbleApps,
  SettingsUnits,
  SettingsUser,
  SetUpProperty,
  SetUpDisplayName,
  // NoPropertyAccess,
  SignIn,
  SignUp,
  Summaries,
  SummaryCasualties,
  SummaryEvents,
  // SummaryLivestockNumbers,
  SummaryMobActions,
  SummaryNaturalIncreases,
  SummaryPaddockActions,
  SummaryPurchases,
  SummarySales,
  TaskCreate,
  TaskDetail,
  TaskEdit,
  Tasks,
} as Record<
  string,
  {
    name: string;
    component: React.FC;
    header?: React.FC;
    modal?: boolean;
  }
>;

export default screens;
