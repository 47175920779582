import React from 'react';
import * as Pkg from '@mobble/icon';
import { Color } from '@mobble/colors';
import { type BoxProps } from '../Layout/Box';
import styles from './icon.scss';

export type IconName = Pkg.IconName;

export type IconSize = 'tiny' | 'small' | 'normal' | 'large';

export type IconProps = Omit<BoxProps, 'children'> & {
  name: IconName;
  size?: IconSize;
  color?: Color;
  content?: number | string;
  className?: string;
};

export const Icon: React.FC<IconProps> = ({
  name,
  size = 'normal',
  color,
  className,
}) => {
  const classNames = [styles.iconContainer, className]
    .filter(Boolean)
    .join(' ');
  const iconClassNames = [styles.icon, styles[`size-${size}`]].join(' ');

  return (
    <div className={classNames}>
      <Pkg.Icon
        name={name}
        size={size}
        color={color}
        className={iconClassNames}
      />
    </div>
  );
};
