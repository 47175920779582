import React from 'react';
import styles from './progressbar.scss';

export interface ProgressBarProps {
  value: number;
  max: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ value, max }) => (
  <div className={styles.progressBar}>
    <progress max={max} value={max - value} />
  </div>
);
