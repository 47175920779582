import { useEffect } from 'react';
import { User } from '@mobble/models';
import mobbleService from '@mobble/service';
import { flush, useRootDispatch } from '@mobble/store';
import { setUser, unsetUser } from '@mobble/store/src/reducers/auth';

type authStateListenerProps = {
  purge: () => Promise<any>;
};

export const useAuthStateListener = (props: authStateListenerProps) => {
  const dispatch = useRootDispatch();

  useEffect(() => {
    let lastChange: string | null = null;
    const unsubscribe = mobbleService.auth.onAuthStateChanged(
      (user: null | User) => {
        const jsonUser = JSON.stringify(user);

        if (user && lastChange !== jsonUser) {
          lastChange = jsonUser;
          dispatch(setUser(user));
        } else if (!user) {
          lastChange = null;
          dispatch(unsetUser());
          flush(dispatch);
          props.purge();
        }
      }
    );
    return () => unsubscribe();
  }, [dispatch]);
};
