import React, { useState } from 'react';
import * as Yup from 'yup';

import { useI18n } from '@mobble/i18n';
import { Mob } from '@mobble/models/src/model/Mob';
import {
  type ConfiguredPropertyType,
  ConfiguredPropertyTypeGroup,
  getConfiguredPropertyTypes,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { useProperties, useSetting } from '@mobble/store/src/hooks';

import { BulkClassSelect, Input } from '@src/components';
import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';

import {
  FormBuilder,
  FormBuilderFieldProps,
  type FormBuilderProps,
} from '../Form/FormBuilder';

export interface MobBulkEditFormProps {
  propertyTypes: ConfiguredPropertyType[];

  mobs: Mob[];
  error?: string;
  loading: boolean;

  onTouched: (dirty: boolean) => void;
  onCancel: () => void;
  onSubmit: (formValues: MobBulkEditFormValues) => void;
}

export type MobBulkEditFormValues = Omit<[Mob], 'propertyId' | 'id'>;

const CustomBulkClassSelect = (
  props: FormBuilderFieldProps<MobBulkEditFormValues>
) => {
  const { onChange, values } = props;
  const properties = useProperties();
  const property = properties.selected;
  const mobs = values['bulk-classes'];
  const livestockType = mobs[0]?.type;
  const parentId = getLivestockParentIdForValue(livestockType, property.types);
  const allClassesForType = getConfiguredPropertyTypes(
    property,
    ConfiguredPropertyTypeGroup.class,
    parentId
  ).map((a) => a.label);

  const handleChange = (mobs: Mob[]) => {
    // @ts-expect-error -- mob array
    onChange(mobs);
  };

  return (
    <BulkClassSelect
      mobs={mobs}
      classes={allClassesForType}
      onChange={handleChange}
    />
  );
};

const CustomStockingRateInput = (
  props: FormBuilderFieldProps<MobBulkEditFormValues>
) => {
  const { onChange, values } = props;
  const { formatMessage } = useI18n();
  const value = values['bulk-stocking-rate'];

  const handleChange = (value: string) => {
    onChange(value);
  };

  const multiplePlaceholder = formatMessage({
    defaultMessage: 'Multiple',
    description: 'Multiple stocking rate placeholder',
  });

  return (
    <Input
      id="bulk-stocking-rate"
      type="float"
      value={value}
      placeholder={value || multiplePlaceholder}
      onChange={handleChange}
    />
  );
};

export const MobBulkEditForm: React.FC<MobBulkEditFormProps> = ({
  propertyTypes,
  mobs,
  error,
  loading,
  onCancel,
  onSubmit,
}) => {
  const { formatMessage } = useI18n();
  const [hasChanged, setHasChanged] = useState(false);

  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));

  const dseValues = mobs.map((mob) => mob.DSE);
  const stockingUnitsEqual = dseValues.every((DSE) => DSE === dseValues[0]);
  const stockingRateValue = stockingUnitsEqual
    ? formatStockingRate(dseValues[0], stockingUnit)
    : undefined;

  const handleTouched = (dirty: boolean) => {
    setHasChanged(dirty);
  };

  const form: FormBuilderProps<MobBulkEditFormValues> = {
    footer: false,
    reinitialize: true,
    i18nRootKey: 'mobs.mob.edit.form.mob_form',
    id: 'mob-bulk-edit-form',
    fields: [
      {
        name: 'bulk-classes',
        type: 'custom',
        label: 'Mob classes',
        component: CustomBulkClassSelect,
        props: {
          propertyTypes,
        },
        // @ts-expect-error -- mob array
        initialValue: mobs,
      },
      {
        name: 'bulk-stocking-rate',
        type: 'custom',
        label: formatMessage(
          {
            defaultMessage: '{STOCKING_UNIT}/head',
            description: 'Stocking rate per head',
          },
          {
            STOCKING_UNIT: stockingUnitLabel,
          }
        ),
        component: CustomStockingRateInput,
        initialValue: stockingRateValue,
        validation: Yup.number().min(
          0,
          formatMessage({
            defaultMessage: 'Stocking rate must be a number',
            description: 'Stocking rate number validation',
          })
        ),
      },
    ],
    error,
    loading,
    disabled: !hasChanged,
    onSubmit,
    onCancel,
    onTouched: handleTouched,
  };

  return <FormBuilder {...form} />;
};
