import { useRootSelector, useRootDispatch } from './root';
import {
  proxyUseEntities,
  proxyUseEntity,
} from '../reducers/rainGaugeReadings';

export const useRainGaugeReadings = (
  propertyId?: string,
  rainGaugeId?: string
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.rainGaugeReadings);

  const parentId =
    propertyId && rainGaugeId ? `${propertyId}_${rainGaugeId}` : undefined;

  return proxyUseEntities({
    parentId,
    dispatch,
    state,
  });
};

export const useRainGaugeReading = (
  rainGaugeReadingId: string,
  propertyId?: string
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.rainGaugeReadings);

  return proxyUseEntity({
    entityId: rainGaugeReadingId,
    parentId: propertyId,
    dispatch,
    state,
  });
};
