import { useState, useEffect } from 'react';
import { defineMessages } from 'react-intl';
import isEqual from 'lodash/isEqual';
import { useMessages } from '@mobble/i18n';
import { FilterItem, Mob } from '@mobble/models';
import { filterMobs } from '@mobble/models/src/model/Mob';
import { usePaddocks, useMobs } from '@mobble/store/src/hooks';
import { arrayUnique } from '../core/Array';

const messages = defineMessages({
  livestock_type: {
    defaultMessage: 'Livestock type',
    description: 'mobs.filter.sections.livestock_type.title',
  },
  breed: {
    defaultMessage: 'Breed',
    description: 'mobs.filter.sections.breed.title',
  },
  gender: {
    defaultMessage: 'Gender',
    description: 'mobs.filter.sections.gender.title',
  },
  class: {
    defaultMessage: 'Class',
    description: 'mobs.filter.sections.class.title',
  },
  age: {
    defaultMessage: 'Livestock ages',
    description: 'mobs.filter.sections.age.title',
  },
});

const getMobParamValues = (param: keyof Mob) => (entities: Mob[]) => {
  const acc: string[] = [];
  entities.forEach((mob) => {
    if (Array.isArray(mob[param])) {
      (mob[param] as string[]).forEach((value) => value && acc.push(value));
    } else if (
      typeof mob[param] === 'string' ||
      typeof mob[param] === 'number'
    ) {
      acc.push(String(mob[param]));
    }
  });

  return arrayUnique(acc).map((value) => ({ value }));
};

export const useMobsFilterItems = (mobs: Mob[]) => {
  const strings = useMessages(messages);

  if (!mobs?.length) {
    return () => [];
  }

  return () => [
    {
      title: strings.livestock_type,
      type: 'select-multiple',
      group: 'livestock_type',
      data: () => getMobParamValues('type')(mobs),
    },
    {
      title: strings.breed,
      type: 'select-multiple',
      group: 'breed',
      data: getMobParamValues('breed'),
    },
    {
      title: strings.gender,
      type: 'select-multiple',
      group: 'gender',
      data: getMobParamValues('gender'),
    },
    {
      title: strings.class,
      type: 'select-multiple',
      group: 'class',
      data: getMobParamValues('classes'),
    },
    {
      title: strings.age,
      type: 'select-multiple',
      group: 'age',
      data: (entities: Mob[]) =>
        getMobParamValues('ages')(entities).sort(
          (a, b) => Number(b.value) - Number(a.value)
        ),
    },
  ];
};

export const useMobsFilter = ({ propertyId, initialState, onChange }) => {
  const paddocks = usePaddocks(propertyId);
  const mobs = useMobs(propertyId);
  const mobsFilterItems = useMobsFilterItems(mobs.entities);

  const applyFilter = filterMobs(paddocks.entities);

  const [mobsFilter, setMobsFilter] = useState<FilterItem[]>(initialState);
  const [filterIsOpen, setFilterIsOpen] = useState(false);

  useEffect(() => {
    onChange(mobsFilter);
  }, [mobsFilter]);

  const handleClearFilter = () => {
    setMobsFilter([]);
  };

  const handleSetFilter = (filterItem: FilterItem) => {
    setMobsFilter((prevFilter) => {
      const equalsItem = (b: FilterItem) => filterItem.group === b.group;
      const doesNotEqualItem = (b: FilterItem) => !equalsItem(b);

      const updatedFilter = prevFilter.filter(doesNotEqualItem);

      if (filterItem.filter.value !== '') {
        return [...updatedFilter, filterItem];
      }

      return updatedFilter;
    });
  };

  const handleToggleFilter = (filterItem: FilterItem) => {
    const equalsItem = (b: FilterItem) => isEqual(filterItem, b);
    const doesNotEqualItem = (b: FilterItem) => !equalsItem(b);

    const exists = Boolean(mobsFilter.find(equalsItem));

    if (exists) {
      setMobsFilter((prevFilter) => prevFilter.filter(doesNotEqualItem));
    } else {
      setMobsFilter((prevFilter) => [...prevFilter, filterItem]);
    }
  };

  return {
    mobs: mobs.entities,
    mobsFilterItems,
    applyFilter,
    mobsFilter,
    filterIsOpen,
    setFilterIsOpen,
    handleClearFilter,
    handleSetFilter,
    handleToggleFilter,
  };
};
