import { MapAssetType, type MapAsset } from '@mobble/models/src/model/MapAsset';
import { type Property } from '@mobble/models/src/model/Property';
import { LineString, type Point } from '@mobble/models/src/model/MapGeometry';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { type ApiPrelude } from '../types';

const decodeMapAssets =
  (propertyId: Property['id']) =>
  (raw: any[]): MapAsset[] => {
    return raw.map(decodeMapAsset(propertyId));
  };

const decodeMapAsset =
  (propertyId: Property['id']) =>
  (raw: any): MapAsset => {
    return {
      id: raw?.id ?? '',
      propertyId,
      name: raw?.name ?? '',
      description: raw?.text ?? '',
      map: {
        type: raw?.type ?? '',
        geometry: raw?.geometry ?? null,
      },
    };
  };

interface MapAssetsInput {
  propertyId: Property['id'];
  type?: string;
}

const GET_MAP_ASSETS = `
  query GetMapAssets($propertyId: ID!, $type: String) {
    mapAssets(propertyId: $propertyId, type: $type) {
      nodes {
        id
        name
        text
        type
        geometry
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{ entities: MapAsset[] }> => {
    const response = await prelude.graphql({
      query: GET_MAP_ASSETS,
      variables: { propertyId: parentId },
    });

    const entities = decodeMapAssets(parentId)(
      response?.data?.mapAssets?.nodes || []
    );

    return {
      entities,
    };
  };

const GET_MAP_ASSET = `
  query GetMapAsset($propertyId: ID!, $id: ID!) {
    mapAsset(propertyId: $propertyId, id: $id) {
      id
      name
      text
      type
      geometry
    }
  }
`;

export const find =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    id,
  }: {
    parentId: string;
    id: MapAsset['id'];
  }): Promise<MapAsset> => {
    const response = await prelude.graphql({
      query: GET_MAP_ASSET,
      variables: { propertyId: parentId, id },
    });

    const mapAsset = decodeMapAsset(parentId)(response?.data?.mapAsset || []);

    return mapAsset;
  };

interface AddMapAssetInput {
  propertyId: string;
  name: string;
  text: string;
  type: string; // MapAssetType
  geometry: Point | LineString;
}

const CREATE_MAP_ASSET = `
  mutation AddMapAsset($input: AddMapAssetInput!) {
    addMapAsset(input: $input) {
      id
      name
      text
      type
      geometry
    }
  }
`;

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<MapAsset, 'id'> }): Promise<MapAsset> => {
    const input = {
      propertyId: entity.propertyId,
      name: entity.name,
      text: entity.description,
      type: entity.map.type,
      geometry: entity.map.geometry,
    };

    const response = await prelude.graphql({
      query: CREATE_MAP_ASSET,
      variables: { input },
    });

    const result = response?.data?.addMapAsset;

    const mapAsset = decodeMapAsset(input.propertyId)(result);

    return mapAsset;
  };

interface EditMapAssetInput {
  id: string;
  propertyId: string;
  name: string;
  text: string;
  type: string; // MapAssetType
  geometry: Point | LineString;
}

const UPDATE_MAP_ASSET = `
  mutation EditMapAsset($input: EditMapAssetInput!) {
    editMapAsset(input: $input) {
      id
      name
      text
      type
      geometry
    }
  }
`;

export const update =
  (prelude: ApiPrelude) =>
  async ({ updatedEntity }: { updatedEntity: MapAsset }): Promise<MapAsset> => {
    const input = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      name: updatedEntity.name,
      text: updatedEntity.description,
      type: updatedEntity.map.type,
      geometry: updatedEntity.map.geometry,
    };
    const response = await prelude.graphql({
      query: UPDATE_MAP_ASSET,
      variables: { input },
    });
    const result = response?.data?.editMapAsset;

    const mapAsset = decodeMapAsset(input.propertyId)(result);

    return mapAsset;
  };

const DELETE_MAP_ASSET = `
  mutation DeleteMapAsset($input: DeleteMapAssetInput!) {
    deleteMapAsset(input: $input) {
      id
    }
  }
`;

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: MapAsset }): Promise<void> => {
    const input = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({ query: DELETE_MAP_ASSET, variables: { input } });
    return;
  };
