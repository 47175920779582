import {
  INVENTORY_TYPE,
  InventoryCategory,
} from '@mobble/models/src/model/Inventory';
import {
  type InventoryItemChemical,
  type InventoryItemFeed,
  InventoryItemStatus,
  type InventoryItem,
  InventoryItemUsageNode,
  InventoryItemUsage,
} from '@mobble/models/src/model/InventoryItem';
import {
  type InventoryID,
  parseInventoryId,
} from '@mobble/models/src/model/Inventory';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import {
  defaultAmountReceived,
  makeQuantityOfMassVolume,
} from '@mobble/shared/src/core/Quantity';
import { type ApiPrelude } from '../types';

// Get

const GET_INVENTORY_ITEMS_FEEDS = `
  query GetFeeds($propertyId: ID!, $sharedPropertyId: String) {
    feeds(propertyId: $propertyId, sharedPropertyId: $sharedPropertyId) {
      nodes {
        id
        name
        dateReceived
        status
        supplierName
        pricePerUnitCents
        lastUsed
        amountReceived {
          unit
          value
        }
        notes
        createdBy {
          id
          displayName
          email
        }
      }
    }
  }
`;

const GET_INVENTORY_ITEMS_CHEMICALS = `
  query GetChemicalsCategorised(
    $propertyId: ID!
    $category: String!
    $sharedPropertyId: String
  ) {
    chemicalsCategorized(
      propertyId: $propertyId
      category: $category
      sharedPropertyId: $sharedPropertyId
    ) {
       nodes {
        id
        category
        productName
        dateReceived
        expiryDate
        dateOfManufacture
        esi
        whp
        woolWhp
        lastUsed
        quantity {
          unit
          value
        }
        finished
        status
        notes
        createdBy {
          id
          displayName
          email
        }
      }
    }
  }
`;

export const decodeInventoryItemsChemicals =
  (propertyId: string, inventoryId: string) =>
  (data: any[]): InventoryItemChemical[] =>
    data.map(decodeInventoryItemChemical(propertyId, inventoryId));

export const decodeInventoryItemChemical =
  (propertyId: string, inventoryId: string) =>
  (data?: any): InventoryItemChemical => ({
    category: InventoryCategory.Chemicals,
    id: data?.id ?? '',
    propertyId,
    inventoryId,
    status: data.status,
    createdBy: {
      id: data?.createdBy?.id ?? '',
      name: data?.createdBy?.displayName ?? '',
      email: data?.createdBy?.email ?? '',
    },
    name: data?.productName ?? '',
    notes: data?.notes ?? '',
    lastUsed: data?.lastUsed,
    quantity: makeQuantityOfMassVolume(
      data?.quantity?.unit,
      data?.quantity?.value
    ),
    dateReceived: data?.dateReceived,
    esi: data?.esi,
    whp: data?.whp,
    woolWhp: data?.woolWhp,
  });

export const decodeInventoryItemsFeeds =
  (propertyId: string, inventoryId: string) =>
  (data: any[]): InventoryItemFeed[] =>
    data.map(decodeInventoryItemFeed(propertyId, inventoryId));

export const decodeInventoryItemFeed =
  (propertyId: string, inventoryId: string) =>
  (data?: any): InventoryItemFeed => ({
    category: InventoryCategory.Feed,
    id: data?.id ?? '',
    propertyId,
    inventoryId,
    status: data.status,
    createdBy: {
      id: data?.createdBy?.id ?? '',
      name: data?.createdBy?.displayName ?? '',
      email: data?.createdBy?.email ?? '',
    },
    name: data?.name ?? '',
    notes: data?.notes ?? '',
    lastUsed: data?.lastUsed,
    quantity: makeQuantityOfMassVolume(
      data?.amountReceived?.unit,
      data?.amountReceived?.value
    ),
    dateReceived: data?.dateReceived,
    pricePerUnitCents: data?.pricePerUnitCents,
    supplierName: data?.supplierName,
  });

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: InventoryID;
  }): Promise<{ entities: InventoryItem[] }> => {
    const { propertyId, type, sharedPropertyId } = parseInventoryId(parentId);

    if (type === 'feed') {
      const entities = await prelude
        .graphql({
          query: GET_INVENTORY_ITEMS_FEEDS,
          variables: {
            propertyId,
            sharedPropertyId,
          },
        })
        .then((response) => response.data.feeds.nodes)
        .then(
          decodeInventoryItemsFeeds(sharedPropertyId ?? propertyId, parentId)
        );

      return { entities };
    }

    const entities = await prelude
      .graphql({
        query: GET_INVENTORY_ITEMS_CHEMICALS,
        variables: {
          propertyId,
          sharedPropertyId,
          category: type,
        },
      })
      .then((response) => response.data?.chemicalsCategorized?.nodes)
      .then(
        decodeInventoryItemsChemicals(sharedPropertyId ?? propertyId, parentId)
      );

    return { entities };
  };

// Find

const GET_CHEMICAL = `
  query GetChemical($propertyId: ID!, $id: ID!) {
    chemical(propertyId: $propertyId, id: $id) {
      id
      category
      productName
      dateReceived
      expiryDate
      dateOfManufacture
      esi
      whp
      woolWhp
      lastUsed
      quantity {
        unit
        value
      }
      finished
      status
      notes
      createdBy {
        id
        displayName
        email
      }
    }
  }
`;

const GET_FEED = `
  query GetFeed($propertyId: ID!, $id: ID!) {
    feed(propertyId: $propertyId, id: $id) {
      id
      name
      dateReceived
      status
      supplierName
      pricePerUnitCents
      lastUsed
      amountReceived {
        unit
        value
      }
      notes
      createdBy {
        id
        displayName
        email
      }
    }
  }
`;

export const find =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    id,
  }: {
    parentId: string;
    id: InventoryItem['id'];
  }): Promise<InventoryItem> => {
    const { type, propertyId } = parseInventoryId(parentId);

    const isChemical =
      type === INVENTORY_TYPE.ANIMAL_HEALTH ||
      type === INVENTORY_TYPE.HERBICIDE_PESTICIDE;

    const response = await prelude.graphql({
      query: isChemical ? GET_CHEMICAL : GET_FEED,
      variables: {
        propertyId,
        id,
      },
    });

    const result = response?.data[isChemical ? 'chemical' : 'feed'];

    return isChemical
      ? decodeInventoryItemChemical(propertyId, parentId)(result)
      : decodeInventoryItemFeed(propertyId, parentId)(result);
  };

// Create

export interface AddFeedInput {
  propertyId: string;
  name: string;
  dateReceived: any;
  status: FeedStatus;
  supplierName?: string | null;
  amountReceived?: SizeInput | null;
  pricePerUnitCents?: number | null;
  notes?: string | null;
  inventoryV2: true;
}

export interface AddChemicalInput {
  propertyId: string;
  category: InventoryProductType;
  productName: string;
  dateReceived?: any | null;
  expiryDate?: any | null;
  dateOfManufacture?: any | null;
  esi?: number | null;
  whp?: number | null;
  woolWhp?: number | null;
  quantity?: SizeInput | null;
  finished?: boolean | null;
  notes?: string | null;

  inventoryV2: true;
}

export type InventoryProductType =
  | 'feed'
  | 'animalHealth'
  | 'herbicidePesticide';

export enum FeedStatus {
  active = 'active',
  deleted = 'deleted',
  finished = 'finished',
}

export interface SizeInput {
  unit: string;
  value: number;
}

const CREATE_INVENTORY_ITEM_FEED = `
  mutation AddFeed($input: AddFeedInput!) {
    addFeed(input: $input) {
      feed {
        id
        name
        dateReceived
        status
        supplierName
        pricePerUnitCents
        lastUsed
        amountReceived {
          unit
          value
        }
        notes
        createdBy {
          id
          displayName
          email
        }
      }
    }
  }
`;

const CREATE_INVENTORY_ITEM_CHEMICALS = `
  mutation AddChemical($input: AddChemicalInput!) {
    addChemical(input: $input) {
      chemical {
        id
        category
        productName
        dateReceived
        expiryDate
        dateOfManufacture
        esi
        whp
        woolWhp
        lastUsed
        quantity {
          unit
          value
        }
        finished
        status
        notes
        createdBy {
          id
          displayName
          email
        }
      }
    }
  }
`;

export const create =
  (prelude: ApiPrelude) =>
  async ({
    entity,
  }: {
    entity: Omit<InventoryItem, 'id'>;
  }): Promise<InventoryItem> => {
    switch (entity.category) {
      case InventoryCategory.Feed:
        return apiCreateInventoryItemFeed(prelude)(
          entity as Omit<InventoryItemFeed, 'id'>
        );
      case InventoryCategory.Chemicals:
        return apiCreateInventoryItemChemicals(prelude)(
          entity as Omit<InventoryItemChemical, 'id'>
        );
    }
  };

const apiCreateInventoryItemFeed =
  (prelude: ApiPrelude) =>
  async (
    newInventoryItem: Omit<InventoryItemFeed, 'id'>
  ): Promise<InventoryItemFeed> => {
    const { propertyId, sharedPropertyId } = parseInventoryId(
      newInventoryItem.inventoryId
    );

    const input: AddFeedInput = {
      propertyId: sharedPropertyId ?? propertyId ?? newInventoryItem.propertyId,
      name: newInventoryItem.name,
      dateReceived: toISO8601(newInventoryItem.dateReceived || new Date()),
      status: FeedStatus.active,
      supplierName: newInventoryItem.supplierName,
      amountReceived: newInventoryItem.quantity
        ? {
            unit: newInventoryItem.quantity.unit,
            value: Number(newInventoryItem.quantity.value) || 0,
          }
        : defaultAmountReceived,
      pricePerUnitCents: newInventoryItem.pricePerUnitCents,
      notes: newInventoryItem.notes,

      inventoryV2: true,
    };
    const response = await prelude.graphql({
      query: CREATE_INVENTORY_ITEM_FEED,
      variables: { input },
    });
    const entity = decodeInventoryItemFeed(
      newInventoryItem.propertyId,
      newInventoryItem.inventoryId
    )(response?.data?.addFeed?.feed);

    return entity;
  };

const apiCreateInventoryItemChemicals =
  (prelude: ApiPrelude) =>
  async (
    newInventoryItem: Omit<InventoryItemChemical, 'id'>
  ): Promise<InventoryItemChemical> => {
    const { propertyId, type, sharedPropertyId } = parseInventoryId(
      newInventoryItem.inventoryId
    );

    const input: AddChemicalInput = {
      propertyId: sharedPropertyId ?? propertyId ?? newInventoryItem.propertyId,
      category: type as InventoryProductType,
      productName: newInventoryItem.name,
      dateReceived: toISO8601(newInventoryItem.dateReceived || new Date()),
      esi: Number(newInventoryItem.esi),
      whp: Number(newInventoryItem.whp),
      woolWhp: Number(newInventoryItem.woolWhp),
      quantity: newInventoryItem.quantity
        ? {
            unit: newInventoryItem.quantity.unit,
            value: Number(newInventoryItem.quantity.value) || 0,
          }
        : defaultAmountReceived,
      finished: false,
      notes: newInventoryItem.notes,

      inventoryV2: true,
    };

    const response = await prelude.graphql({
      query: CREATE_INVENTORY_ITEM_CHEMICALS,
      variables: {
        input,
      },
    });
    const entity = decodeInventoryItemChemical(
      newInventoryItem.propertyId,
      newInventoryItem.inventoryId
    )(response?.data?.addChemical?.chemical);

    return entity;
  };

// Update
type EditFeedInput = {
  id: string;
} & AddFeedInput;

type EditChemicalInput = {
  id: string;
} & AddChemicalInput;

const UPDATE_INVENTORY_ITEM_FEED = `
  mutation EditFeed($input: EditFeedInput!) {
    editFeed(input: $input) {
      feed {
        id
        name
        dateReceived
        status
        supplierName
        pricePerUnitCents
        lastUsed
        amountReceived {
          unit
          value
        }
        notes
        createdBy {
          id
          displayName
          email
        }
      }
    }
  }
`;

const UPDATE_INVENTORY_ITEM_CHEMICALS = `
  mutation EditChemical($input: EditChemicalInput!) {
    editChemical(input: $input) {
      chemical {
        id
        category
        productName
        dateReceived
        expiryDate
        dateOfManufacture
        esi
        whp
        woolWhp
        lastUsed
        quantity {
          unit
          value
        }
        finished
        status
        notes
        createdBy {
          id
          displayName
          email
        }
      }
    }
  }
`;

export const update =
  (prelude: ApiPrelude) =>
  async ({
    updatedEntity,
  }: {
    updatedEntity: InventoryItem;
  }): Promise<InventoryItem> => {
    switch (updatedEntity.category) {
      case InventoryCategory.Feed:
        return updateInventoryItemFeed(prelude)(
          updatedEntity as InventoryItemFeed
        );
      case InventoryCategory.Chemicals:
        return updateInventoryItemChemicals(prelude)(
          updatedEntity as InventoryItemChemical
        );
    }
  };

const updateInventoryItemFeed =
  (prelude: ApiPrelude) =>
  async (
    updatedInventoryItem: InventoryItemFeed
  ): Promise<InventoryItemFeed> => {
    const { propertyId, sharedPropertyId } = parseInventoryId(
      updatedInventoryItem.inventoryId
    );

    const input: EditFeedInput = {
      id: updatedInventoryItem.id,
      propertyId:
        sharedPropertyId ?? propertyId ?? updatedInventoryItem.propertyId,
      name: updatedInventoryItem.name,
      dateReceived: toISO8601(updatedInventoryItem.dateReceived || new Date()),
      status:
        updatedInventoryItem.status === InventoryItemStatus.Active
          ? FeedStatus.active
          : FeedStatus.finished,
      supplierName: updatedInventoryItem.supplierName,
      amountReceived: updatedInventoryItem.quantity
        ? {
            unit: updatedInventoryItem.quantity.unit,
            value: Number(updatedInventoryItem.quantity.value) || 0,
          }
        : defaultAmountReceived,
      pricePerUnitCents: updatedInventoryItem.pricePerUnitCents,
      notes: updatedInventoryItem.notes,

      inventoryV2: true,
    };

    const response = await prelude.graphql({
      query: UPDATE_INVENTORY_ITEM_FEED,
      variables: { input },
    });
    const entity = decodeInventoryItemFeed(
      updatedInventoryItem.propertyId,
      updatedInventoryItem.inventoryId
    )(response?.data?.editFeed?.feed);

    return entity;
  };

const updateInventoryItemChemicals =
  (prelude: ApiPrelude) =>
  async (
    updatedInventoryItem: InventoryItemChemical
  ): Promise<InventoryItemChemical> => {
    const { propertyId, type, sharedPropertyId } = parseInventoryId(
      updatedInventoryItem.inventoryId
    );

    const input: EditChemicalInput = {
      id: updatedInventoryItem.id,
      propertyId:
        sharedPropertyId ?? propertyId ?? updatedInventoryItem.propertyId,
      category: type as InventoryProductType,
      productName: updatedInventoryItem.name,
      dateReceived: toISO8601(updatedInventoryItem.dateReceived || new Date()),
      esi: Number(updatedInventoryItem.esi),
      whp: Number(updatedInventoryItem.whp),
      woolWhp: Number(updatedInventoryItem.woolWhp),
      quantity: updatedInventoryItem.quantity
        ? {
            unit: updatedInventoryItem.quantity.unit,
            value: Number(updatedInventoryItem.quantity.value) || 0,
          }
        : null,
      finished: updatedInventoryItem.status === InventoryItemStatus.Finished,
      notes: updatedInventoryItem.notes,

      inventoryV2: true,
    };

    const response = await prelude.graphql({
      query: UPDATE_INVENTORY_ITEM_CHEMICALS,
      variables: {
        input,
      },
    });

    const entity = decodeInventoryItemChemical(
      updatedInventoryItem.propertyId,
      updatedInventoryItem.inventoryId
    )(response?.data?.editChemical?.chemical);

    return entity;
  };

// Delete
export interface DeleteFeedInput {
  id: string;
  propertyId: string;
}

export interface DeleteChemicalInput {
  id: string;
  propertyId: string;
}

const DELETE_INVENTORY_ITEM_FEED = `
  mutation DeleteFeed($input: DeleteFeedInput!) {
    deleteFeed(input: $input) {
      feed {
        id
      }
    }
  }
`;

const DELETE_INVENTORY_ITEM_CHEMICALS = `
  mutation DeleteChemical($input: DeleteChemicalInput!) {
    deleteChemical(input: $input) {
      chemical {
        id
      }
    }
  }
`;

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: InventoryItem }): Promise<void> => {
    switch (entity.category) {
      case InventoryCategory.Feed:
        return removeInventoryItemFeed(prelude)(entity as InventoryItemFeed);
      case InventoryCategory.Chemicals:
        return removeInventoryItemChemicals(prelude)(
          entity as InventoryItemChemical
        );
    }
  };

const removeInventoryItemFeed =
  (prelude: ApiPrelude) =>
  async (inventoryItemToDelete: InventoryItemFeed): Promise<void> => {
    const { propertyId, sharedPropertyId } = parseInventoryId(
      inventoryItemToDelete.inventoryId
    );

    const input: DeleteFeedInput = {
      id: inventoryItemToDelete.id,
      propertyId:
        sharedPropertyId ?? propertyId ?? inventoryItemToDelete.propertyId,
    };
    await prelude.graphql({
      query: DELETE_INVENTORY_ITEM_FEED,
      variables: { input },
    });
    return;
  };

const removeInventoryItemChemicals =
  (prelude: ApiPrelude) =>
  async (inventoryItemToDelete: InventoryItemChemical): Promise<void> => {
    const { propertyId, sharedPropertyId } = parseInventoryId(
      inventoryItemToDelete.inventoryId
    );

    const input: DeleteChemicalInput = {
      id: inventoryItemToDelete.id,
      propertyId:
        sharedPropertyId ?? propertyId ?? inventoryItemToDelete.propertyId,
    };
    await prelude.graphql({
      query: DELETE_INVENTORY_ITEM_CHEMICALS,
      variables: {
        input,
      },
    });
    return;
  };

const GET_CHEMICAL_USAGE = `
  query GetChemicalUsage($propertyId: ID!, $id: ID! $startDate: Date, $endDate: Date) {
    chemicalUsage(
      propertyId: $propertyId
      id: $id
      startDate: $startDate
      endDate: $endDate
    ) {
      unit
      value
      date
    }
  }
`;

const GET_FEED_USAGE = `
  query GetFeedUsage($propertyId: ID!, $id: ID! $startDate: Date, $endDate: Date) {
    feedUsage(
      propertyId: $propertyId
      id: $id
      startDate: $startDate
      endDate: $endDate
    ) {
      unit
      value
      date
    }
  }
`;

const decodeInventoryItemUsage = (raw: any): InventoryItemUsageNode => ({
  unit: raw.unit,
  value: raw.value,
  date: raw.date,
});

const decodeInventoryItemUsages = (raw: any): InventoryItemUsageNode[] =>
  raw.map(decodeInventoryItemUsage);

export const getInventoryItemUsage =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    startDate,
    endDate,
  }: {
    parentId: string;
    startDate?: string;
    endDate?: string;
  }): Promise<{ entities: InventoryItemUsage[] }> => {
    const [propertyId, id, type] = parentId.split('__');

    const isChemical = type === InventoryCategory.Chemicals;

    const response = await prelude.graphql({
      query: isChemical ? GET_CHEMICAL_USAGE : GET_FEED_USAGE,
      variables: {
        propertyId,
        id,
        startDate: toISO8601(startDate),
        endDate: toISO8601(endDate),
      },
    });

    const result = response?.data[isChemical ? 'chemicalUsage' : 'feedUsage'];

    const entity = {
      id,
      propertyId,
      data: decodeInventoryItemUsages(result),
    };

    return {
      entities: [entity],
    };
  };
