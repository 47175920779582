import { toGroupValue } from '@mobble/models/src/model/Filter';
import { useRootSelector, useRootDispatch } from './root';
import {
  proxyUseEntities,
  proxyUseEntity,
  proxyUseFilter,
} from '../reducers/historicEvents';
import { ExtFilterParams } from '../lib/ExtStatus';

export const useHistoricEvents = (
  propertyId?: string,
  additionalExtFilter?: ExtFilterParams
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.historicEvents);
  const inventoryItems = useRootSelector((s) => s.inventoryItems).entities;
  const inventoryItemBatches = useRootSelector(
    (s) => s.inventoryItemBatches
  ).entities;

  const proxyFilter = proxyUseFilter({
    parentId: propertyId,
    dispatch,
    state,
  });

  const eventTypes = toGroupValue(proxyFilter.filter, 'event_type') as string[];
  const extFilter = {
    ...additionalExtFilter,
    eventTypes,
  };
  const data = proxyUseEntities({
    parentId: propertyId,
    extFilter,
    dispatch,
    state,
  });

  const parsedEntities = data.entities.map((entity) => {
    if (
      entity.eventType === 'ChemicalAddition' ||
      entity.eventType === 'ChemicalEdit' ||
      entity.eventType === 'FeedAddition' ||
      entity.eventType === 'FeedEdit' ||
      entity.eventType === 'FeedFinished'
    ) {
      const inventoryItemId = entity.linksTo?.id;
      const inventoryId = inventoryItems.find(
        (item) => item.id === inventoryItemId
      )?.inventoryId;

      return {
        ...entity,
        related: {
          inventoryId: inventoryId,
        },
      };
    } else if (
      entity.eventType === 'ChemicalBatchAddition' ||
      entity.eventType === 'ChemicalBatchEdit' ||
      entity.eventType === 'FeedBatchAddition' ||
      entity.eventType === 'FeedBatchEdit'
    ) {
      const batchId = entity.linksTo?.id;
      const batch = inventoryItemBatches.find((item) => item.id === batchId);

      if (!batch) {
        return entity;
      }

      const inventoryItemId = batch.inventoryItemId;
      const inventoryId = inventoryItems.find(
        (item) => item.id === inventoryItemId
      )?.inventoryId;

      if (!inventoryId) {
        return entity;
      }

      return {
        ...entity,
        related: {
          inventoryId: inventoryId,
        },
      };
    }
    return entity;
  });

  return {
    ...data,
    entities: parsedEntities,
  };
};

export const useHistoricEvent = (historicEventId: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.historicEvents);

  return proxyUseEntity({
    entityId: historicEventId,
    dispatch,
    state,
  });
};
