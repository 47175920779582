import React, { useState } from 'react';
import { useI18n } from '@mobble/i18n';
import { type GroupedProperties } from '@mobble/models/src/model/Property';

import { useDialog } from '@src/hooks/useDialog';

import * as ROUTE_NAME from '@src/screens/config/routeNames';

import Button from '@src/components/Button';
import { type ListProps } from '@src/components/List';

import { useLinking } from '@src/hooks/useLinking';

import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { DrawerItem } from '@src/stories/Components/UX/Drawer/DrawerItem';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';

import styles from './propertySelector.scss';

export interface PropertySelectorProps {
  collapsed?: boolean;
  groupedProperties: GroupedProperties;
  selectedPropertyId?: string;
  canCreate?: boolean;
  onSelect: (value: string) => void;
  onOpen: () => void;
}

export const PropertySelector: React.FC<PropertySelectorProps> = ({
  collapsed,
  groupedProperties,
  selectedPropertyId,
  canCreate,
  onSelect,
  onOpen,
}) => {
  const { alert, close } = useDialog();
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const [expanded, setExpanded] = useState(false);

  const onToggle = () => {
    if (!expanded) {
      onOpen();
    }
    setExpanded(!expanded);
  };

  const onItemSelect = (vale: string) => {
    onSelect(vale);
    setExpanded(false);
  };

  const handleNoAccessClick = () => {
    alert(
      formatMessage({
        description: 'Create new property access denied title',
        defaultMessage: 'Only organisation owners can create new properties',
      }),
      formatMessage({
        description: 'Create new property access denied message',
        defaultMessage:
          'Contact your organisation owner if you would like to create a new property for this account.',
      }),
      [
        {
          label: formatMessage({
            description: 'Create new property access denied close button text',
            defaultMessage: 'Close',
          }),
          outline: true,
          onClick: () => {
            close();
          },
        },
      ]
    );
  };

  const selectedLabel = groupedProperties.reduce((label, group) => {
    return (
      group.properties.find((p) => p.id === selectedPropertyId)?.name ?? label
    );
  }, '');

  const renderSectionHeader = (section: any) => {
    return (
      <header className={styles.sectionHeader}>
        <Text bold variant="body">
          {section.title}
        </Text>
      </header>
    );
  };

  const renderItem = (item) => {
    const selected = selectedPropertyId === item.value;
    const classes = [styles.item, selected ? styles.selected : null];
    return (
      <Clickable fullWidth href={() => onItemSelect(item.value)}>
        <Box
          style={{ flex: 1 }}
          spacing={2}
          className={classes.filter(Boolean).join(' ')}
        >
          <Text variant="body">{item.label}</Text>
        </Box>
      </Clickable>
    );
  };

  const items = groupedProperties.map((group) => ({
    title: group.name,
    data: group.properties.map((p) => ({
      label: p.name,
      value: p.id,
    })),
  }));

  const listProps: ListProps = {
    items,
    renderItem,
    renderSectionHeader,
    keyExtractor: (_, index) => String(index),
  };

  const Footer = (
    <Button
      icon="plus-circle"
      size="small"
      intent="neutral"
      label={formatMessage({
        description: 'screen.title.property_create',
        defaultMessage: 'Create new property',
      })}
      onClick={() => {
        canCreate
          ? linkTo(ROUTE_NAME.MODAL_PROPERTY_CREATE)
          : handleNoAccessClick();
        setExpanded(false);
      }}
      className={styles.footerButton}
    />
  );

  return (
    <Box className={styles.PropertySelector}>
      <DrawerItem
        label={selectedLabel}
        bold
        icon="farm"
        iconAfter={expanded ? 'chevron-up' : 'chevron-down'}
        href={onToggle}
        collapsed={collapsed}
      />
      <ModalFlyUp
        title={formatMessage({
          description: 'property_selector.modal.title',
          defaultMessage: 'Select property',
        })}
        isOpen={expanded}
        shouldCloseOnOutsideClick={true}
        onClose={() => {
          setExpanded(false);
        }}
        listProps={listProps}
        footer={Footer}
        // footerProps={{
        //   closeLabel: 'Close',
        //   submitLabel: formatMessage({
        //     description: 'screen.title.property_create',
        //     defaultMessage: 'Create new property',
        //   }),
        //   onCloseClick: () => setExpanded(false),
        //   onSubmitClick: () => {
        //     canCreate
        //       ? linkTo(ROUTE_NAME.MODAL_PROPERTY_CREATE)
        //       : handleNoAccessClick;
        //   },
        // }}
      />
    </Box>
  );
};
