import React from 'react';
import { useLocation } from 'react-router-dom';

export interface ScrollRestoreProps {
  children: React.ReactElement;
}

export const ScrollRestore: React.FC<ScrollRestoreProps> = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};
