import React from 'react';

import { Color } from '@mobble/colors';
import { timeZoneToStockingUnit } from '@mobble/models/src/model/Settings';
import { useAuthentication } from '@mobble/shared/src/hooks/useAuthentication';
import { useSettings } from '@mobble/store/src/hooks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useGetInvitationMeta } from '@src/hooks/useInvations';
import { useTitle } from '@src/hooks/useTitle';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import { Container } from '@src/stories/Components/Layout/Container';
import { Divider } from '@src/stories/Components/Layout/Divider';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Logo } from '@src/stories/Components/UI/Logo';
import { Text } from '@src/stories/Components/UI/Text';
import { LoadingScreen } from '@src/stories/Views/Misc/LoadingScreen';
import { NoPropertyAccessScreen } from '@src/stories/Views/Misc/NoPropertyAccessScreen';
import {
  PropertyCreateForm,
  PropertyCreateFormValues,
} from '@src/stories/Views/Property/PropertyCreateForm';

import { useSubscription } from '../Settings/hooks/useBilling';

const useDelay = (seconds: number) => {
  const [delaying, setDelaying] = React.useState(true);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDelaying(false);
    }, seconds * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return delaying;
};

export const SetUpProperty: React.FC = () => {
  const settings = useSettings();
  const properties = useProperties();
  const subscription = useSubscription();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { signOut } = useAuthentication();

  useTitle({
    key: 'setup.property.form.submit.label',
  });

  const invitationMeta = useGetInvitationMeta();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const stockingUnit = timeZoneToStockingUnit(timeZone);

  const initialValues: Omit<PropertyCreateFormValues, 'pic'> = {
    name: invitationMeta?.farmName,
    stockingUnit,
  };

  const onSubmit = (formValues: PropertyCreateFormValues) => {
    setLoading(true);

    const values: Omit<PropertyCreateFormValues, 'stockingUnit'> = {
      name: formValues.name,
      pic: formValues.pic,
    };

    const stockingUnit = formValues.stockingUnit;
    settings.update({ stockingUnit });

    properties
      .create(values)
      .then((newProperty) => {
        const stockingUnit = formValues.stockingUnit[0];
        settings.update({ stockingUnit });

        properties.selectProperty(newProperty.id);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  const onCancel = () => {
    signOut();
  };

  const initialLoad = useDelay(10);

  if (!subscription) {
    if (initialLoad) {
      return <LoadingScreen fullScreen />;
    }

    return <NoPropertyAccessScreen />;
  }

  return (
    <VStack flex>
      <Box spacing={{ top: 16, bottom: 8 }}>
        <VStack alignment="center">
          <Logo />
        </VStack>
      </Box>
      <Container width="small">
        <Box spacing={2}>
          <Text
            variant="card-title"
            i18n={{ key: 'setup.property.welcome.title' }}
          />
          <Spacer y={1} />
          <Text variant="body" i18n={{ key: 'setup.property.welcome.blurb' }} />
        </Box>
        <Spacer y={2} />
        <Divider bold />
        <Spacer y={2} />
        {error && (
          <Box spacing={2}>
            <HStack>
              <Box>
                <Icon name="error" color={Color.Red} />
              </Box>
              <Text
                variant="body"
                color={Color.Red}
                i18n={{ key: 'setup.property.error' }}
              />
            </HStack>
          </Box>
        )}
        <PropertyCreateForm
          loading={loading}
          initialValues={initialValues}
          onCancel={onCancel}
          onSubmit={onSubmit}
          requestStockingUnit
        />
      </Container>
    </VStack>
  );
};

export default {
  name: ROUTE_NAME.SETUP_PROPERTY,
  component: SetUpProperty,
};
