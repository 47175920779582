import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/purchases';

export const usePurchases = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.purchases);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const usePurchase = (mobActionId: string, propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.purchases);

  const proxied = proxyUseEntity({
    entityId: mobActionId,
    parentId: propertyId,
    dispatch,
    state,
  });

  return proxied;
};
