import {
  RainGaugeYearMonthTotal,
  type RainGauge,
} from '@mobble/models/src/model/RainGauge';
import { makeQuantityOfLength } from '@mobble/shared/src/core/Quantity';
import { type ApiPrelude } from '../types';

const decodeRainGauges =
  (propertyId: string) =>
  (raw: any[]): RainGauge[] => {
    return raw.map(decodeRainGauge(propertyId));
  };

const decodeRainGauge =
  (propertyId: string) =>
  (raw: any): RainGauge => {
    return {
      id: raw?.id ?? '',
      propertyId,
      lastReading: {
        createdByID: raw?.lastReading?.createdByID,
        date: raw?.lastReading?.date,
        id: raw?.lastReading?.id,
        value: makeQuantityOfLength('mm', raw?.lastReading?.value?.value ?? 0),
      },
      mtd: makeQuantityOfLength('mm', raw?.mtd?.value ?? 0),
      name: raw?.name,
      readingYears: raw?.readingYears,
      status: raw?.status,
      ytd: makeQuantityOfLength('mm', raw?.ytd?.value ?? 0),
      totals: decodeTotals(raw?.totals || []),
    };
  };

const decodeTotals = (totals: any[]): RainGaugeYearMonthTotal[] =>
  totals.map((raw: any) => ({
    year: raw.year,
    month: raw.month,
    total: makeQuantityOfLength('mm', raw?.total?.value ?? 0),
  }));

const GET_RAIN_GAUGES = `
  query GetRainGauges($propertyId: ID!, $year: Int) {
    rainGauges(propertyId: $propertyId) {
      id
      name
      ytd {
        unit
        value
      }
      mtd {
        unit
        value
      }
      status
      lastReading {
        id
        date
        value {
          unit
          value
        }
        createdByID
      }
      readingYears

      totals(year: $year) {
        year
        month
        total {
          value
          unit
        }
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{ entities: RainGauge[] }> => {
    const response = await prelude.graphql({
      query: GET_RAIN_GAUGES,
      variables: {
        propertyId: parentId,
      },
    });
    const entities = decodeRainGauges(parentId)(response.data.rainGauges);
    return { entities };
  };

const GET_RAIN_GAUGE_YEAR_MONTH_TOTAL = `
  query GetRainGauge($propertyId: ID!, $id: ID!, $year: Int) {
    rainGauge(propertyId: $propertyId, id: $id) { 
      id
      name
      totals(year: $year) {
        year
        month
        total {
          value
          unit
        }
      }
    }
  }
`;

export const getYearMonthTotal =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    rainGaugeId,
    year,
  }: {
    parentId: string;
    rainGaugeId: string;
    year: number;
  }): Promise<{ entities: RainGaugeYearMonthTotal[] }> => {
    const response = await prelude.graphql({
      query: GET_RAIN_GAUGE_YEAR_MONTH_TOTAL,
      variables: {
        propertyId: parentId,
        id: rainGaugeId,
        year,
      },
    });

    const entities = decodeTotals(response.data.rainGauge.totals);
    for (let i = 0; i < 12; i++) {
      const month = i + 1;
      const found = entities.find((e) => e.month === month);
      if (!found) {
        entities.push({
          year,
          month,
          total: makeQuantityOfLength('mm', 0),
        });
      }
    }

    return { entities };
  };

interface AddRainGaugeInput {
  propertyId: string;
  name: string;
}

const CREATE_RAIN_GAUGE = `
  mutation AddRainGauge($input: AddRainGaugeInput!) {
    addRainGauge(input: $input) {
      rainGauge {
        id
        name
        ytd {
          unit
          value
        }
        mtd {
          unit
          value
        }
        status
        lastReading {
          id
          date
          value {
            unit
            value
          }
          createdByID
        }
        readingYears
      }
    }
  }
`;

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<RainGauge, 'id'> }): Promise<RainGauge> => {
    const input: AddRainGaugeInput = {
      propertyId: entity.propertyId,
      name: entity.name,
    };

    const response = await prelude.graphql({
      query: CREATE_RAIN_GAUGE,
      variables: { input },
    });
    const rainGauge = decodeRainGauge(entity.propertyId)(
      response?.data?.addRainGauge?.rainGauge
    );

    return rainGauge;
  };

export interface EditRainGaugeInput {
  propertyId: string;
  id: string;
  name: string;
}

const EDIT_RAIN_GAUGE = `
  mutation EditRainGauge($input: EditRainGaugeInput!) {
    editRainGauge(input: $input) {
      rainGauge {
        id
        name
        ytd {
          unit
          value
        }
        mtd {
          unit
          value
        }
        status
        lastReading {
          id
          date
          value {
            unit
            value
          }
          createdByID
        }
        readingYears
      }
    }
  }
`;

export const update =
  (prelude: ApiPrelude) =>
  async ({
    updatedEntity,
  }: {
    updatedEntity: RainGauge;
  }): Promise<RainGauge> => {
    const input = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      name: updatedEntity.name,
    };

    const response = await prelude.graphql({
      query: EDIT_RAIN_GAUGE,
      variables: { input },
    });

    const rainGauge = decodeRainGauge(updatedEntity.propertyId)(
      response?.data?.editRainGauge?.rainGauge
    );

    return rainGauge;
  };

interface DeleteRainGaugeInput {
  propertyId: string;
  id: string;
}

const DELETE_RAIN_GAUGE = `
  mutation DeleteRainGauge($input: DeleteRainGaugeInput!) {
    deleteRainGauge(input: $input) {
      rainGauge {
        id
      }
    }
  }
`;

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: RainGauge }): Promise<void> => {
    const input: DeleteRainGaugeInput = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({ query: DELETE_RAIN_GAUGE, variables: { input } });
    return;
  };
