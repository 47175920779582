import Fuse from 'fuse.js';
import { FilterItem, filterMatches, groupFilter } from './Filter';
import {
  pointsToDistance,
  type LineString,
  type Point,
  distanceBetweenPointAndFeature,
  positionToPoint,
  Position,
  pointsToFeaturePolygon,
  polygonToLabelCenterPoint,
} from './MapGeometry';
import { type Property } from './Property';
import { SortDirection, SortOption, SortSetting } from './Sort';
import { MAP_DETAILS, type MapDetail } from './MapDetail';
import { QuantityOfDistance } from '@mobble/shared/src/core/Quantity';

export interface MapAsset {
  id: string;
  propertyId: Property['id'];
  name: string;
  description: string;
  map: MapAssetGeometry;
}

export enum MapAssetType {
  Bore = 'bore',
  Dam = 'dam',
  ElectricFence = 'electric-fence',
  ElectricFenceUnit = 'electric-fence-unit',
  Feeder = 'feeder',
  Gate = 'gate',
  Hazard = 'hazard',
  OtherLine = 'other-line',
  OtherPoint = 'other-point',
  Pipeline = 'pipeline',
  Road = 'road',
  Shed = 'shed',
  Silo = 'silo',
  Tank = 'tank',
  WaterTrough = 'water-trough',
}

export const mapAssetsTypes = [
  MapAssetType.Bore,
  MapAssetType.Dam,
  MapAssetType.ElectricFence,
  MapAssetType.ElectricFenceUnit,
  MapAssetType.Feeder,
  MapAssetType.Gate,
  MapAssetType.Hazard,
  MapAssetType.OtherLine,
  MapAssetType.OtherPoint,
  MapAssetType.Pipeline,
  MapAssetType.Road,
  MapAssetType.Shed,
  MapAssetType.Silo,
  MapAssetType.Tank,
  MapAssetType.WaterTrough,
];

export type MapAssetGeometryBore = {
  type: MapAssetType.Bore;
  geometry: Point;
};

export type MapAssetGeometryDam = {
  type: MapAssetType.Dam;
  geometry: Point;
};

export type MapAssetGeometryElectricFence = {
  type: MapAssetType.ElectricFence;
  geometry: LineString;
};

export type MapAssetGeometryElectricFenceUnit = {
  type: MapAssetType.ElectricFenceUnit;
  geometry: Point;
};

export type MapAssetGeometryFeeder = {
  type: MapAssetType.Feeder;
  geometry: Point;
};

export type MapAssetGeometryGate = {
  type: MapAssetType.Gate;
  geometry: Point;
};

export type MapAssetGeometryHazard = {
  type: MapAssetType.Hazard;
  geometry: Point;
};

export type MapAssetGeometryOtherLine = {
  type: MapAssetType.OtherLine;
  geometry: LineString;
};

export type MapAssetGeometryOtherPoint = {
  type: MapAssetType.OtherPoint;
  geometry: Point;
};

export type MapAssetGeometryPipeline = {
  type: MapAssetType.Pipeline;
  geometry: LineString;
};

export type MapAssetGeometryRoad = {
  type: MapAssetType.Road;
  geometry: LineString;
};

export type MapAssetGeometryShed = {
  type: MapAssetType.Shed;
  geometry: Point;
};

export type MapAssetGeometrySilo = {
  type: MapAssetType.Silo;
  geometry: Point;
};

export type MapAssetGeometryTank = {
  type: MapAssetType.Tank;
  geometry: Point;
};

export type MapAssetGeometryWaterTrough = {
  type: MapAssetType.WaterTrough;
  geometry: Point;
};

export type MapAssetGeometry =
  | MapAssetGeometryBore
  | MapAssetGeometryDam
  | MapAssetGeometryElectricFence
  | MapAssetGeometryElectricFenceUnit
  | MapAssetGeometryFeeder
  | MapAssetGeometryGate
  | MapAssetGeometryHazard
  | MapAssetGeometryOtherLine
  | MapAssetGeometryOtherPoint
  | MapAssetGeometryPipeline
  | MapAssetGeometryRoad
  | MapAssetGeometryShed
  | MapAssetGeometrySilo
  | MapAssetGeometryTank
  | MapAssetGeometryWaterTrough;

export const mapAssetTypeToGeometryFeatureType = (
  type: MapAssetType
): 'Point' | 'LineString' => {
  switch (type) {
    case MapAssetType.Bore:
    case MapAssetType.Dam:
    case MapAssetType.ElectricFenceUnit:
    case MapAssetType.Feeder:
    case MapAssetType.Gate:
    case MapAssetType.Hazard:
    case MapAssetType.OtherPoint:
    case MapAssetType.Shed:
    case MapAssetType.Silo:
    case MapAssetType.Tank:
    case MapAssetType.WaterTrough:
      return 'Point';
    case MapAssetType.ElectricFence:
    case MapAssetType.OtherLine:
    case MapAssetType.Pipeline:
    case MapAssetType.Road:
      return 'LineString';
  }
};

export const mapAssetTypeToColor = (type: MapAssetType): string => {
  switch (type) {
    case MapAssetType.Bore:
      return '#4c0045';
    case MapAssetType.Dam:
      return '#0000cc';
    case MapAssetType.ElectricFence:
      return '#ffff00';
    case MapAssetType.ElectricFenceUnit:
      return '#ffff00';
    case MapAssetType.Feeder:
      return '#ccff00';
    case MapAssetType.Gate:
      return '#dddddd';
    case MapAssetType.Hazard:
      return '#ff8800';
    case MapAssetType.OtherLine:
      return '#ffffff';
    case MapAssetType.OtherPoint:
      return '#ffffff';
    case MapAssetType.Pipeline:
      return '#0000cc';
    case MapAssetType.Road:
      return '#d4c651';
    case MapAssetType.Shed:
      return '#888888';
    case MapAssetType.Silo:
      return '#ccff00';
    case MapAssetType.Tank:
      return '#2222ff';
    case MapAssetType.WaterTrough:
      return '#55ffff';
  }
};

export const mapAssetTypeToRelevantMapDetail = (
  type: MapAssetType
): MapDetail => {
  switch (type) {
    case MapAssetType.Bore:
      return MAP_DETAILS.MAP_ASSETS__BORE;
    case MapAssetType.Dam:
      return MAP_DETAILS.MAP_ASSETS__DAM;
    case MapAssetType.ElectricFence:
      return MAP_DETAILS.MAP_ASSETS__ELECTRIC_FENCE;
    case MapAssetType.ElectricFenceUnit:
      return MAP_DETAILS.MAP_ASSETS__ELECTRIC_FENCE_UNIT;
    case MapAssetType.Feeder:
      return MAP_DETAILS.MAP_ASSETS__FEEDER;
    case MapAssetType.Gate:
      return MAP_DETAILS.MAP_ASSETS__GATE;
    case MapAssetType.Hazard:
      return MAP_DETAILS.MAP_ASSETS__HAZARD;
    case MapAssetType.OtherLine:
      return MAP_DETAILS.MAP_ASSETS__OTHER_LINE;
    case MapAssetType.OtherPoint:
      return MAP_DETAILS.MAP_ASSETS__OTHER_POINT;
    case MapAssetType.Pipeline:
      return MAP_DETAILS.MAP_ASSETS__PIPELINE;
    case MapAssetType.Road:
      return MAP_DETAILS.MAP_ASSETS__ROAD;
    case MapAssetType.Shed:
      return MAP_DETAILS.MAP_ASSETS__SHED;
    case MapAssetType.Silo:
      return MAP_DETAILS.MAP_ASSETS__SILO;
    case MapAssetType.Tank:
      return MAP_DETAILS.MAP_ASSETS__TANK;
    case MapAssetType.WaterTrough:
      return MAP_DETAILS.MAP_ASSETS__WATER_TROUGH;
  }
};

export const mapAssetToPoints = (mapAsset: MapAsset): Point[] => {
  switch (mapAssetTypeToGeometryFeatureType(mapAsset.map.type)) {
    case 'Point':
      return [mapAsset.map.geometry as Point];
    case 'LineString':
      return (mapAsset.map.geometry as LineString).coordinates.map((c) =>
        positionToPoint(c as Position)
      );
  }
};

export const mapAssetToCenterPoint = (mapAsset: MapAsset): Point => {
  const points = mapAssetToPoints(mapAsset);
  if (points.length === 1) {
    return points[0];
  }

  return polygonToLabelCenterPoint(pointsToFeaturePolygon(points) as any);
};

export const filterMapAssets =
  () =>
  (mapAssets: MapAsset[], filter?: FilterItem[]): MapAsset[] => {
    if (!filter || filter.length === 0) {
      return mapAssets;
    }
    const grouped = [...groupFilter(filter)];

    const searchQuery = filter.find((a) => a.group === 'search')?.filter;

    const preFilteredMapAssets =
      searchQuery && searchQuery.type === 'search'
        ? searchMapAssets()(mapAssets, searchQuery.value)
        : mapAssets;

    return preFilteredMapAssets.filter((mapAsset) =>
      grouped.every(([_, filters]) =>
        filters.some(mapAssetsFilterItemMatchesMapAsset()(mapAsset))
      )
    );
  };

export const mapAssetsFilterItemMatchesMapAsset =
  () => (mapAsset: MapAsset) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'type':
        return matches(mapAsset.map.type);
      case 'search':
        return true;
      default:
        return true;
    }
  };

export const searchMapAssets =
  () =>
  (mapAssets: MapAsset[], searchQuery: string): MapAsset[] => {
    const fuse = new Fuse(mapAssets, {
      keys: [
        { name: 'name', weight: 3 },
        { name: 'description', weight: 2 },
        { name: 'map.type', weight: 4 },
      ],
      threshold: 0.5,
      shouldSort: true,
    });
    return fuse.search(searchQuery).map((a) => a.item);
  };

export const availableSortOptions: SortOption[] = [
  {
    name: 'name_asc',
    settings: [{ direction: SortDirection.Ascending, column: 'name' }],
  },
  {
    name: 'name_desc',
    settings: [{ direction: SortDirection.Descending, column: 'name' }],
  },
  {
    name: 'type',
    settings: [{ direction: SortDirection.Ascending, column: 'type' }],
  },
  {
    name: 'distance',
    settings: [{ direction: SortDirection.Ascending, column: 'distance' }],
  },
];

export interface MapAssetSortMeta {
  origin?: Point;
}

export const sortAscendingBy =
  (meta?: MapAssetSortMeta) =>
  (column: string) =>
  (a: MapAsset, b: MapAsset) => {
    switch (column) {
      case 'name': {
        return a.name.localeCompare(b.name);
      }

      case 'type': {
        return a.map.type.localeCompare(b.map.type);
      }

      case 'distance': {
        const distanceToOrigin = (mapAsset: MapAsset) => {
          const distance =
            meta?.origin &&
            distanceBetweenPointAndFeature(meta.origin, mapAsset.map.geometry)
              ?.value;
          return distance;
        };

        const dA = distanceToOrigin(a);
        const dB = distanceToOrigin(b);
        if (dA && dB) {
          return dA - dB;
        } else if (dA) {
          return -1;
        } else if (dB) {
          return 1;
        }
        return 0;
      }
    }
  };

export const sortMapAssets =
  (meta?: MapAssetSortMeta) =>
  (paddocks: MapAsset[], sortSettings: SortSetting[]): MapAsset[] =>
    sortAnyContainingPaddocks((p) => p)(meta)(paddocks, sortSettings);

export const sortAnyContainingPaddocks =
  <A = MapAsset>(toMapAsset: (a: A) => MapAsset) =>
  (meta?: MapAssetSortMeta) =>
  (items: A[], sortSettings: SortSetting[]): A[] => {
    return items.sort((a, b) => {
      const mapAssetA = toMapAsset(a);
      const mapAssetB = toMapAsset(b);

      return sortSettings.reduce<number>((result, sortSetting) => {
        if (result === 0) {
          const asc =
            sortAscendingBy(meta)(sortSetting.column)(mapAssetA, mapAssetB) ??
            result;
          if (sortSetting.direction === SortDirection.Descending) {
            return asc < 0 ? 1 : asc > 0 ? -1 : 0;
          }
          return asc;
        }
        return result;
      }, 0);
    });
  };

export const findMapAsset =
  (mapAssets: MapAsset[]) =>
  (mapAssetId: MapAsset['id']): MapAsset | undefined =>
    mapAssets.find((p) => p.id === mapAssetId);

export const toLength = (mapAsset: MapAsset): null | QuantityOfDistance => {
  const featureType = mapAssetTypeToGeometryFeatureType(mapAsset.map.type);
  switch (featureType) {
    case 'LineString':
      return pointsToDistance(
        (mapAsset.map.geometry as LineString).coordinates.map((c) =>
          positionToPoint(c as Position)
        )
      );

    case 'Point':
    default:
      return null;
  }
};
