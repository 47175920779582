import { I18nLocale } from '@mobble/i18n';
import { User } from '@mobble/models';
import { ApiPrelude } from '../types';

const GET_ME = `
  query Me {
    me {
      id
      displayName
      email
      locale
      billingRegion
      maxProperties
      subcriptionStatus
      tz
      meta
    }
  }
`;

const decodeUser = (user: any): User => {
  return {
    id: user?.id ?? 'anonymous',
    name: user?.displayName ?? 'unknown',
    email: user?.email ?? 'unknown',
    locale: user?.locale ?? 'en-AU',
    billingRegion: user?.billingRegion,
    subcriptionStatus: user?.subcriptionStatus ?? undefined,
    tz: user?.tz ?? undefined,
    meta: user?.meta ?? {
      address: '',
      country: '',
      farmRole: '',
      phone: '',
    },
  };
};

export const get =
  (prelude: ApiPrelude) =>
  async (token?: string): Promise<User> => {
    const response = await prelude.graphql({ query: GET_ME, token });
    const user = decodeUser(response?.data?.me);

    return {
      ...user,
    };
  };

const EDIT_ME = `
  mutation EditMe($input: EditMeInput!) {
    editMe(input: $input) {
      user {
        id
        displayName
        email
        locale
      }
    }
  }
`;

export interface EditMeInput {
  id: string;
  email: string;
  name?: string | null;
  locale?: I18nLocale;
  address?: string | null;
  phone?: string | null;
  country?: string | null;
  farmRole?: string | null;
  tz?: string | null;
}

export const update =
  (prelude: ApiPrelude) =>
  async (updatedUser: EditMeInput): Promise<User> => {
    const input = {
      displayName: updatedUser.name,
      locale: updatedUser.locale,
      ...(updatedUser.phone &&
        updatedUser.country &&
        updatedUser.address &&
        updatedUser.farmRole && {
          phone: updatedUser.phone,
          country: updatedUser.country,
          address: updatedUser.address,
          farmRole: updatedUser.farmRole,
          tz: updatedUser.tz,
        }),
    };

    await prelude.graphql({
      query: EDIT_ME,
      variables: {
        input,
      },
    });

    const user: User = {
      id: updatedUser.id,
      name: updatedUser.name ?? '',
      email: updatedUser.email ?? '',
      locale: updatedUser.locale ?? 'en-au',
    };

    return user;
  };

const GET_EMAIL_BY_TOKEN = `
  query GetEmailByToken($input: GetEmailByTokenInput!) {
    getEmailByToken(input: $input) {
      email
    }
  }
`;

export const getEmailByToken =
  (prelude: ApiPrelude) =>
  async (token: string): Promise<string> => {
    const input = {
      token,
    };

    const response = await prelude.graphql({
      query: GET_EMAIL_BY_TOKEN,
      variables: {
        input,
      },
      token: false,
    });

    return response?.data?.getEmailByToken?.email;
  };
