import { useI18n } from '@mobble/i18n/src';
import { FilterItem } from '@mobble/models/src';
import { MapDetails } from '@mobble/models/src/model/MapDetail';
import { defaultBoundingBox } from '@mobble/models/src/model/MapGeometry';
import { MapStyle } from '@mobble/models/src/model/MapStyle';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useProperties,
  useSettings,
  useMapAssets,
  useTaskGeometries,
  usePaddocks,
  usePaddockGroups,
  usePaddockGeometries,
  usePaddockGroupGeometries,
} from '@mobble/store/src/hooks';
import { MapProperties } from '@src/hooks/useMapProperties';
import {
  makeMapItemPaddockBoundary,
  makeMapItemPaddockLabel,
  makeMapItemPaddockGroup,
} from '@src/stories/Map/Items/helper';
import { MapItem } from '@src/stories/Map/Items/MapItemType';
import { useMemo } from 'react';

export const useTaskMapView = (): MapProperties => {
  const properties = useProperties();
  const { settings, update } = useSettings();
  const { translate } = useI18n();
  const propertyId = properties.selected?.id;
  const mapAssets = useMapAssets(propertyId);
  const taskGeometries = useTaskGeometries(propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const paddockGeometries = usePaddockGeometries(propertyId);
  const paddockGroupGeometries = usePaddockGroupGeometries(propertyId);

  useEntitiesRefresher(
    [
      paddockGroups,
      paddocks,
      paddockGeometries,
      paddockGroupGeometries,
      taskGeometries,
    ],
    propertyId
  );

  const factoryMapItemPaddockBoundary = makeMapItemPaddockBoundary({
    propertyTypes: properties.selected?.types ?? [],
    paddockGeometries: paddockGeometries.entities,
  });
  const factoryMapItemPaddockLabel = makeMapItemPaddockLabel({
    paddockGeometries: paddockGeometries.entities,
    translate,
  });

  const factoryMapItemPaddockGroup = makeMapItemPaddockGroup({
    paddockGroupGeometries: paddockGroupGeometries.entities,
  });

  const items: MapItem[] = useMemo(() => {
    return [
      ...paddocks.entities.reduce<MapItem[]>((acc, paddock) => {
        return [
          ...acc,
          ...([
            factoryMapItemPaddockBoundary({ paddock }),
            factoryMapItemPaddockLabel({ paddock }),
          ].filter(Boolean) as MapItem[]),
        ];
      }, []),

      // MapItemPaddockGroup
      ...paddockGroups.entities.reduce<MapItem[]>((acc, paddockGroup) => {
        return [
          ...acc,
          ...([factoryMapItemPaddockGroup({ paddockGroup })].filter(
            Boolean
          ) as MapItem[]),
        ];
      }, []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paddocks.entities, paddockGroups.entities, mapAssets.entities]);

  const result: MapProperties = {
    propertyId,
    items: items,
    // additionalMapItems,
    boundingBox: defaultBoundingBox,
    // override?.mapStyle ??
    mapStyle: settings.mapStyle,
    mapDetails: [], // mapDetailsInUse,
    mobsFilter: [],
    customMapLayersFilter: [],
    updateMapStyle: (updatedMapStyle: MapStyle) => {
      update({ mapStyle: updatedMapStyle });
    },
    updateMapDetails: (updatedMapDetails: MapDetails) => {
      update({ mapDetails: updatedMapDetails });
    },
    updateMobsFilter: (filter: FilterItem[]) => {
      update({ mapMobsFilter: filter });
    },
    updateCustomMapLayersFilter: function (filter: FilterItem[]): void {
      update({ mapCustomLayersFilter: filter });
    },
    override: null,
    additionalMapItems: [],
  };

  return result;
};
