import { type Task } from '@mobble/models/src/model/Task';
import { type Property } from '@mobble/models/src/model/Property';
import { type Point } from '@mobble/models/src/model/MapGeometry';
import { toISO8601, type RawDate } from '@mobble/shared/src/core/Date';
import { type ApiPrelude } from '../types';

export const setTime = (rawDate: RawDate) => (minutesOfDay: number) => {
  const newDate = new Date(rawDate.toLocaleString());
  const hours = Math.floor(minutesOfDay / 60);
  const minutes = minutesOfDay % 60;
  newDate.setHours(hours);
  newDate.setMinutes(minutes);

  return newDate.toISOString();
};

const decodeTasks =
  (propertyId: Property['id']) =>
  (raw: any[]): Task[] => {
    return raw.map(decodeTask(propertyId));
  };

const decodeTask =
  (propertyId: Property['id']) =>
  (raw: any): Task => {
    return {
      id: raw?.id ?? '',
      propertyId,
      title: raw?.title ?? 'Untitled task',
      description: raw?.text,
      createdAt: raw?.createdAt ?? '',
      dueDate: raw?.dueDate,
      status: raw?.status,

      paddockId: raw?.paddock?.id,

      location: raw?.pointGeometry ?? null,

      assignedToUsers: (raw?.assignedTo ?? []).map((a: any) => ({
        id: a.id,
        name: a.displayName ?? `user ${a.id.slice(0, 5)}`,
        email: a.email ?? '',
      })),
      assignedByUser: {
        id: raw?.assignedBy?.id,
        name:
          raw?.assignedBy?.displayName ??
          `user ${raw?.assignedBy?.id.slice(0, 5)}`,
        email: raw?.assignedBy?.email ?? '',
      },
    };
  };

const GET_TASKS = `
  query GetTasks($propertyId: ID!) {
    tasks(propertyId: $propertyId) {
      nodes {
        id
        title
        text
        createdAt
        dueDate
        status

        assignedTo {
          id
          displayName
          email
        }

        assignedBy {
          id
          displayName
          email
        }

        paddock {
          id
        }
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({ parentId }: { parentId: string }): Promise<{ entities: Task[] }> => {
    const response = await prelude.graphql({
      query: GET_TASKS,
      variables: { propertyId: parentId },
    });

    const entities = decodeTasks(parentId)(response?.data?.tasks?.nodes || []);

    return {
      entities,
    };
  };

const GET_TASK = `
  query GetTask($propertyId: ID!, $id: ID!) {
    task(propertyId: $propertyId, id: $id) {
      id
      title
      text
      createdAt
      dueDate
      status

      assignedTo {
        id
        displayName
        email
      }

      assignedBy {
        id
        displayName
        email
      }

      paddock {
        id
      }

      pointGeometry {
        type
        coordinates
      }
    }
  }
`;

export const find =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    id,
  }: {
    parentId: string;
    id: Task['id'];
  }): Promise<Task> => {
    const response = await prelude.graphql({
      query: GET_TASK,
      variables: { propertyId: parentId, id },
    });

    const task = decodeTask(parentId)(response?.data?.task || []);

    return task;
  };

interface ApiCreateTaskInput {
  title?: string | null;
  text?: string | null;
  done: boolean;
  createdAt?: any | null;
  dueDate?: any | null;
  assignedToId: string[];
  paddockId?: string | null;
  pointGeometry?: Point;
  propertyId: string;
}

const CREATE_TASK = `
  mutation AddTask($input: AddTaskInput!) {
    addTask(input: $input) {
      task {
        id
        title
        text
        createdAt
        dueDate
        status

        assignedTo {
          id
          displayName
          email
        }

        assignedBy {
          id
          displayName
          email
        }

        paddock {
          id
        }

        pointGeometry {
          type
          coordinates
        }
      }
    }
  }
`;

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<Task, 'id'> }): Promise<Task> => {
    const input: ApiCreateTaskInput = {
      propertyId: entity.propertyId,
      title: entity.title,
      text: entity.description,
      createdAt: toISO8601(new Date()),
      dueDate: entity.dueDate ? setTime(entity.dueDate)(6 * 60 + 30) : null,
      paddockId: entity.paddockId || null,
      pointGeometry: entity.location || null,
      assignedToId: (entity.assignedToUsers || []).map((u) => u.id),
      done: false,
    };

    const response = await prelude.graphql({
      query: CREATE_TASK,
      variables: { input },
    });
    const result = response?.data?.addTask?.task;

    const task = decodeTask(input.propertyId)(result);

    return task;
  };

interface ApiUpdateTaskInput {
  id: string;
  title?: string | null;
  text?: string | null;
  done?: boolean | null;
  createdAt?: any | null;
  dueDate?: any | null;
  assignedToId?: string[] | null;
  paddockId?: string | null;
  pointGeometry?: Point;
  propertyId: string;
}

const UPDATE_TASK = `
  mutation EditTask($input: EditTaskInput!) {
    editTask(input: $input) {
      task {
        id
        title
        text
        createdAt
        dueDate
        status

        assignedTo {
          id
          displayName
          email
        }

        assignedBy {
          id
          displayName
          email
        }

        paddock {
          id
        }

        pointGeometry {
          type
          coordinates
        }
      }
    }
  }
`;

export const update =
  (prelude: ApiPrelude) =>
  async ({ updatedEntity }: { updatedEntity: Task }): Promise<Task> => {
    const input: ApiUpdateTaskInput = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      title: updatedEntity.title,
      text: updatedEntity.description,
      done: updatedEntity.status ? updatedEntity.status === 'done' : null,
      dueDate: updatedEntity.dueDate
        ? setTime(updatedEntity.dueDate)(6 * 60 + 30)
        : null,
      paddockId: updatedEntity.paddockId || null,
      pointGeometry: updatedEntity.location || null,
      assignedToId: (updatedEntity.assignedToUsers || []).map((u) => u.id),
    };
    const response = await prelude.graphql({
      query: UPDATE_TASK,
      variables: { input },
    });
    const result = response?.data?.editTask?.task;

    const task = decodeTask(input.propertyId)(result);

    return task;
  };

const DELETE_TASK = `
  mutation DeleteTask($input: DeleteTaskInput!) {
    deleteTask(input: $input) {
      task {
        id
      }
    }
  }
`;

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Task }): Promise<void> => {
    const input = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({ query: DELETE_TASK, variables: { input } });
    return;
  };
