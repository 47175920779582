import React from 'react';
import { Property } from '@mobble/models/src/model/Property';
import {
  ItemTotals,
  ReportData,
  combineTotals,
  propertyReportForRangeToRootItems,
} from './usePropertyReportForRange';
import { ChartWrapper } from '@src/stories/Components/Charts/ChartWrapper';
import { Pie } from '@src/stories/Components/Charts/Pie';

export const PropertyTotalsGraphs = ({
  propertiesReports,
  properties,
}: {
  propertiesReports: ReportData;
  properties: Property[];
}) => {
  if (
    propertiesReports.type === 'loading' ||
    propertiesReports.report.length <= 1 ||
    !properties.length
  ) {
    return null;
  }

  const getPropertyName = (id: Property['id']) =>
    properties.find((a) => a.id === id)?.name;

  const propertyTotals = propertiesReports.report
    .map((a) => ({
      data: propertyReportForRangeToRootItems(a.raw),
      propertyId: a.propertyId,
    }))
    .map((a) => ({
      data: a.data.reduce((a, b) => combineTotals(a, b.totals), {
        startingHead: 0,
        closingHead: 0,
        naturalIncrease: 0,
        purchased: 0,
        casualties: 0,
        sold: 0,
      }),
      propertyId: a.propertyId,
    }));

  const constructLivestockTotalDataFromAttribute = (item: keyof ItemTotals) =>
    propertyTotals
      .map((a) => ({
        id: a.propertyId,
        value: a.data[item],
        label: `${getPropertyName(a.propertyId)} (${a.data[item]})`,
      }))
      .filter((a) => a.value > 0);

  const livestockTotalsData =
    constructLivestockTotalDataFromAttribute('closingHead');
  const livestockTotalSalesData =
    constructLivestockTotalDataFromAttribute('sold');
  const livestockTotalCasualtiesData =
    constructLivestockTotalDataFromAttribute('casualties');
  const livestockPurchasedData =
    constructLivestockTotalDataFromAttribute('purchased');
  const livestockTotalNaturalIncreases =
    constructLivestockTotalDataFromAttribute('naturalIncrease');

  return (
    <>
      {livestockTotalsData.length ? (
        <ChartWrapper title={{ key: 'reports.totals.chart.closingHead.title' }}>
          <Pie data={livestockTotalsData} />
        </ChartWrapper>
      ) : null}
      {livestockTotalSalesData.length ? (
        <ChartWrapper title={{ key: 'reports.totals.chart.sold.title' }}>
          <Pie data={livestockTotalSalesData} />
        </ChartWrapper>
      ) : null}
      {livestockTotalCasualtiesData.length ? (
        <ChartWrapper title={{ key: 'reports.totals.chart.casualties.title' }}>
          <Pie data={livestockTotalCasualtiesData} />
        </ChartWrapper>
      ) : null}
      {livestockPurchasedData.length ? (
        <ChartWrapper title={{ key: 'reports.totals.chart.purchased.title' }}>
          <Pie data={livestockPurchasedData} />
        </ChartWrapper>
      ) : null}
      {livestockTotalNaturalIncreases.length ? (
        <ChartWrapper
          title={{ key: 'reports.totals.chart.naturalIncrease.title' }}
        >
          <Pie data={livestockTotalNaturalIncreases} />
        </ChartWrapper>
      ) : null}
    </>
  );
};
