import { type User } from '@mobble/models';
import mobbleService from '@mobble/service';
import { AuthProxyUser, SignUpValues } from '@mobble/service/src/ext/auth';
import { useRootDispatch } from '@mobble/store/src/hooks/root';
import { setUser } from '@mobble/store/src/reducers/auth';

export const useAuthentication = () => {
  const dispatch = useRootDispatch();

  const signIn = (email: string, password: string): Promise<AuthProxyUser> => {
    return mobbleService.auth
      .signIn(email, password)
      .then((user: AuthProxyUser) => {
        return user;
      });
  };

  const signOut = () => {
    return mobbleService.auth.signOut();
  };

  const signUpForTrial = async (params: SignUpValues): Promise<User> => {
    return mobbleService.auth.signUpForTrial(params).then((user: User) => {
      dispatch(setUser(user));
      return user;
    });
  };

  const forgotPassword = (email: string) => {
    return mobbleService.auth.forgotPassword(email);
  };

  return {
    signIn,
    signOut,
    signUpForTrial,
    forgotPassword,
  };
};
