import React, { useState } from 'react';

import { type Task } from '@mobble/models';
import { useGetUser } from '@mobble/store/src/hooks';

import { useLinking } from '@src/hooks/useLinking';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { Button, ButtonProps } from '@src/stories/Components/UX/Button';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { TaskInfoTable } from '@src/stories/Views/Task/TaskInfoTable';
import { TaskMap } from '@src/stories/Views/Task/TaskMap';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

import { useTaskScreen, useTaskScreenHeader } from './useTaskScreen';

import styles from './taskDetail.scss';

export const TaskDetailHeader: React.FC<ScreenRendererProps> = ({ route }) => {
  const { taskId = '' } = route.params;
  const props = useTaskScreenHeader(taskId);

  return <ScreenHeader {...props} />;
};

export const TaskDetail: React.FC<ScreenRendererProps> = ({ route }) => {
  const { taskId } = route.params;
  const linkTo = useLinking();
  const { Prelude, task, paddocks, taskGeometries } = useTaskScreen(taskId);
  const user = useGetUser();
  const point = task.entity?.location;

  const [formLoading, setFormLoading] = useState(false);

  if (Prelude) {
    return Prelude;
  }

  const handleUpdateStatus = (status: string) => {
    if (!task.entity) {
      return;
    }

    setFormLoading(true);

    task.update({ ...task.entity, status }).then(() => {
      setFormLoading(false);
    });
  };

  const handleMaximise = () => {
    linkTo(ROUTE_NAME.MAP_OVERVIEW, {
      mode: 'standard',
      goto: `task--${task.entity.id}`,
    });
  };

  const buttonProps: ButtonProps =
    task.entity?.status === 'done'
      ? {
          intent: 'secondary',
          onClick: () => handleUpdateStatus('pending'),
          label: { key: 'task.info.actions.mark_as_incomplete.label' },
        }
      : {
          intent: 'primary',
          onClick: () => handleUpdateStatus('done'),
          icon: 'check',
          label: { key: 'task.info.actions.mark_as_complete.label' },
        };

  return (
    <Box className={styles.taskDetail}>
      {point && (
        <>
          <TaskMap
            task={task.entity}
            taskGeometries={taskGeometries.entities}
            userId={user.id}
            onMaximise={handleMaximise}
          />
          <Spacer y={4} />
        </>
      )}
      <TaskInfoTable paddocks={paddocks.entities} task={task.entity as Task} />

      <Button flex loading={formLoading} {...buttonProps} />
    </Box>
  );
};

export default {
  name: ROUTE_NAME.TASK_DETAIL,
  header: TaskDetailHeader,
  component: TaskDetail,
};
