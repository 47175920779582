import React, { ComponentPropsWithoutRef, useState } from 'react';
import classNames from 'classnames/bind';

import { Color } from '@mobble/theme';

import Badge, { type BadgeProps } from '../Badge';
import Text from '../Text';

import styles from './BadgeList.scss';
const cx = classNames.bind(styles);

export interface BadgeListProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * Array of badges
   */
  badges: BadgeProps[];

  /**
   * Number of badges to show, defaults to 0 (show all)
   */
  badgesToShow?: number;

  /**
   * Override default color if badge color is not defined
   */
  defaultColor?: Color | string;

  /**
   * Text to display
   */
  label?: string;
}

/**
 * BadgeList display a list of badges with an optional label
 */
const BadgeList: React.FC<BadgeListProps> = ({
  label,
  badges,
  badgesToShow = 0,
  defaultColor,
  className,
  ...others
}) => {
  const [showAll, setShowAll] = useState(false);
  const rootClasses = cx(
    {
      BadgeList: true,
    },
    className
  );

  let badgesToRender = badges;

  if (badgesToShow && badges.length > badgesToShow) {
    badgesToRender = showAll
      ? badgesToRender
      : badgesToRender.slice(0, badgesToShow);
  }

  const handleShowAllClick = (event) => {
    event.stopPropagation();
    setShowAll(true);
  };

  return (
    <div className={rootClasses} {...others}>
      {label && (
        <Text tagName="h3" variant="small" className={styles.label}>
          {label}
        </Text>
      )}
      <ul>
        {badgesToRender.map((badge: BadgeProps) => (
          <li key={badge.label}>
            <Badge
              textVariant="small"
              color={badge.color ?? defaultColor}
              {...badge}
            />
          </li>
        ))}
        {badgesToShow && badges.length > badgesToShow && !showAll ? (
          <li>
            <Badge
              label={`+ ${badges.length - badgesToShow}`}
              textVariant="small"
              color={`var(--neutrals-bg-default)`}
              onClick={handleShowAllClick}
            />
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default BadgeList;
