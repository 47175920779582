import React from 'react';
import styles from './printer.scss';

export interface PrinterContextProps {
  printFromRef: (ref: React.RefObject<HTMLElement>) => void;
}

export const PrinterContext = React.createContext<PrinterContextProps>(
  null as any
);

export const PrinterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const printContentRef = React.useRef(null);
  const [printing, setPrinting] = React.useState(false);

  const printFromRef = (ref: React.RefObject<HTMLElement>) => {
    const html = ref.current?.innerHTML;
    printContentRef.current.innerHTML = html;

    setPrinting(true);
  };

  React.useEffect(() => {
    const onPrint = () => {
      window.print();
      printContentRef.current.innerHTML = '';
      setPrinting(false);
    };

    if (printing) {
      onPrint();
    }
  }, [printing]);

  const screenClassNames = [styles.screen, printing ? styles.active : null]
    .filter(Boolean)
    .join(' ');

  const printClassNames = [styles.print, printing ? styles.active : null]
    .filter(Boolean)
    .join(' ');

  return (
    <PrinterContext.Provider value={{ printFromRef }}>
      <div className={screenClassNames}>{children}</div>
      <div className={printClassNames} ref={printContentRef} />
    </PrinterContext.Provider>
  );
};

export const usePrinterContext = () => {
  return React.useContext(PrinterContext);
};
