import styles from './spacing.scss';
export const SPACING_BASE = 8;

export type Spacing =
  | 0
  | 0.25
  | 0.5
  | 1
  | 1.5
  | 2
  | 3
  | 4
  | 6
  | 8
  | 16
  | 24
  | 32
  | 64;

export const spacingToAbsolute = (spacing?: Spacing) =>
  (spacing || 0) * SPACING_BASE;

export interface Cardinal<T> {
  top?: T;
  right?: T;
  bottom?: T;
  left?: T;
}

const spacingNumberToString = (num: number): string => {
  return String(num).replace('.', '_');
};

export const classNameSpacing =
  (prefix: string, cardinal = false) =>
  (
    spacing?: Spacing | Cardinal<Spacing>,
    target?: 'mobile' | 'desktop'
  ): string => {
    const responsivePrefix = target
      ? `${target.slice(0, 1)}-${prefix}`
      : prefix;

    if (!spacing) {
      return '';
    } else if (typeof spacing === 'number') {
      return styles[`${responsivePrefix}-${spacingNumberToString(spacing)}`];
    } else if (cardinal) {
      return Object.entries(spacing)
        .reduce((acc, [direction, spacing]) => {
          if (spacing) {
            acc.push(
              styles[
                `${responsivePrefix}-${direction.slice(
                  0,
                  1
                )}-${spacingNumberToString(spacing)}`
              ]
            );
          }
          return acc;
        }, [])
        .join(' ');
    }
    return '';
  };
export const classNamePadding = classNameSpacing('p', true);
export const classNameWidth = classNameSpacing('w', false);
export const classNameHeight = classNameSpacing('h', false);
