import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/inventories';

export const useInventories = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.inventories);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const useInventory = (inventoryId: string, propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.inventories);

  return proxyUseEntity({
    entityId: inventoryId,
    parentId: propertyId,
    dispatch,
    state,
  });
};
