import { useCallback } from 'react';
import memoize from 'lodash/memoize';
import {
  createAsyncThunk,
  createSlice,
  Draft,
  current,
  type ValidateSliceCaseReducers,
  type SliceCaseReducers,
  type ActionReducerMapBuilder,
  type AsyncThunk,
} from '@reduxjs/toolkit';
import {
  type BaseEntity,
  type FilterItem,
  type SortSetting,
} from '@mobble/models';
import { formatError, RequestErrors } from '@mobble/shared/src/core/Error';

import { type State as RootState } from '../';
import {
  updateStatusForParentAndPayloadAction,
  updateStatusForPayloadAction,
  Status,
  getForPropertyAndAction,
  extStatusToPreludeStatus,
  checkEntityExpiration,
  type ExtStatus,
  type ExtStatusPerActionPerParent,
  type ExtStatusPerAction,
  type ExtFilterParams,
  type ExtCursor,
} from './ExtStatus';
import {
  type EntitySliceFactoryProxyPrelude,
  PreludeStatus,
} from './EntitySliceFactoryPrelude';
import * as FilterHelper from './filter';
import * as SortHelper from './sort';

const ENTITIES_MAX_AGE = 60 * 2; // minutes in seconds

export type BaseEntityState<Entity> = {
  entities: Entity[];
  extStatus: ExtStatusPerActionPerParent;
  filter: FilterHelper.FilterPerProperty;
  sort: SortHelper.SortPerProperty;
};

export interface EntitySliceFactoryArguments<
  State extends BaseEntityState<Entity>,
  Entity extends BaseEntity
> {
  name: string;
  initialState: State;
  //
  toParentId: (entity: Entity) => string;
  matchesExtFilter?: MatchesExtFilterF;
  merger?: (args: {
    current: Entity[];
    incoming: Entity[];
    parentId: string;
    extFilter?: ExtFilterParams;
    cursor?: string;
  }) => Entity[];

  //
  reducers?: ValidateSliceCaseReducers<State, SliceCaseReducers<State>>;
  extraReducers?: (builder: ActionReducerMapBuilder<State>) => void;
  defaultSort?: SortSetting[];

  //
  onGetAll: (
    args: {
      parentId: BaseEntity['id'];
      extFilter?: ExtFilterParams;
      cursor?: string;
    },
    dispatch: any
  ) => Promise<{ entities: Entity[]; extCursor?: ExtCursor }>;
  onFind?: (
    args: {
      parentId: BaseEntity['id'];
      id: Entity['id'];
    },
    dispatch: any
  ) => Promise<Entity>;
  onCreate: (
    args: {
      entity: Omit<Entity, 'id'>;
    },
    dispatch: any
  ) => Promise<Entity>;
  onUpdate: (
    args: {
      updatedEntity: Entity;
      originalEntity?: Entity;
    },
    dispatch: any
  ) => Promise<Entity>;
  onDelete: (args: { entity: Entity }, dispatch: any) => Promise<void>;
  //
  afterQuery?: (args: { entities: Entity[] }, dispatch: any) => Promise<void>;
  afterMutation?: (
    args: { entity: Entity },
    dispatch: any,
    type: 'create' | 'update' | 'delete'
  ) => Promise<void>;
}

export interface EntitySliceFactoryProxyEntitiesResponse<Entity> {
  allEntitiesAvailable: Entity[];
  entities: Entity[];
  refreshing: boolean;
  loading: boolean;
  failed: boolean;
  lastError?: string;
  lastChanged: number;
  total: number;
  refresh: (force?: boolean) => Promise<void>;
  loadMore: () => Promise<void>;
  hasNextPage: boolean;
  create: (entity: Omit<Entity, 'id'>) => Promise<Entity>;
  //
  filter: FilterItem[];
  toggleFilter: (filter: FilterItem) => void;
  setFilter: (filter: FilterItem) => void;
  clearFilter: () => void;
  sort: SortSetting[];
  setSortSetting: (sortSettings: SortSetting[]) => void;
  //
  prelude: EntitySliceFactoryProxyPrelude;
}

export interface EntitySliceFactoryProxyFilterResponse {
  filter: FilterItem[];
  toggleFilter: (filter: FilterItem) => void;
  setFilter: (filter: FilterItem) => void;
  clearFilter: () => void;
  sort: SortSetting[];
  setSortSetting: (sortSettings: SortSetting[]) => void;
}

export interface EntitySliceFactoryProxyEntityResponse<Entity> {
  entity: Entity | undefined;
  refreshing: boolean;
  loading: boolean;
  failed: boolean;
  refresh: (force?: boolean) => Promise<void>;
  create: (entity: Omit<Entity, 'id'>) => Promise<Entity>;
  update: (entity: Entity) => Promise<Entity>;
  remove: () => Promise<void>;
  //
  prelude: EntitySliceFactoryProxyPrelude;
}

type MatchesExtFilterF = (
  extFilter: ExtFilterParams
) => (entity: any) => boolean;

const defaultMatchesExtFilter =
  <Entity extends BaseEntity>(extFilter: ExtFilterParams) =>
  (entity: Entity) => {
    const extFilterKeys = Object.keys(extFilter);
    return extFilterKeys.every((key) => {
      const value = extFilter[key];
      const entityValue = (entity as any)[key];
      return entityValue === value;
    });
  };

//
export const entitySliceFactory = <
  State extends BaseEntityState<Entity>,
  Entity extends BaseEntity = State['entities'][0]
>({
  name,
  initialState,
  //
  toParentId,
  matchesExtFilter = defaultMatchesExtFilter,
  merger,
  defaultSort,
  //
  reducers = {},
  extraReducers,
  //
  onGetAll,
  onFind,
  onCreate,
  onUpdate,
  onDelete,
  //
  afterQuery,
  afterMutation,
}: EntitySliceFactoryArguments<State, Entity>) => {
  const hasParentId = (parentId: string) => (entity: Entity) => {
    return toParentId(entity) === parentId;
  };

  const thunkGetAll = createAsyncThunk<
    { entities: Entity[]; extCursor?: ExtCursor },
    { parentId: BaseEntity['id']; extFilter?: ExtFilterParams; cursor?: string }
  >(`${name}/getAll`, async (args, { dispatch }) => {
    const res = await onGetAll(args, dispatch);
    await afterQuery?.({ entities: res.entities }, dispatch);
    return res;
  });

  const thunkFind = onFind
    ? createAsyncThunk<
        Entity,
        { parentId: BaseEntity['id']; id: Entity['id'] }
      >(`${name}/find`, async (args, { dispatch }) => {
        const res = await onFind(args, dispatch);
        return res;
      })
    : undefined;

  const thunkCreate = createAsyncThunk<Entity, Omit<Entity, 'id'>>(
    `${name}/create`,
    async (entity, { dispatch }) => {
      const res = await onCreate({ entity }, dispatch).catch((r) => {
        return Promise.reject<Entity>(formatError(r));
      });
      await afterMutation?.({ entity: res }, dispatch, 'create');
      return res;
    }
  );

  const thunkUpdate = createAsyncThunk<Entity, Entity>(
    `${name}/update`,
    async (updatedEntity, { dispatch, getState }) => {
      const state = getState() as RootState;
      const originalEntity = (state as any)[name]?.entities?.find(
        (a: Entity) => a.id === updatedEntity.id
      ) as undefined | Entity;

      const res = await onUpdate(
        { updatedEntity, originalEntity },
        dispatch
      ).catch((r) => {
        return Promise.reject<Entity>(formatError(r));
      });
      await afterMutation?.({ entity: res }, dispatch, 'update');

      return res;
    }
  );

  const thunkDelete = createAsyncThunk<void, Entity>(
    `${name}/delete`,
    async (entity, { dispatch }) => {
      await onDelete({ entity }, dispatch).catch((r) => {
        return Promise.reject<Entity>(formatError(r));
      });
      await afterMutation?.({ entity }, dispatch, 'delete');
      return;
    }
  );

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      ...reducers,
      flush: () => {
        return initialState;
      },
      updateFilter: (state, action) => {
        const origState = current(state);
        const origStateFilter = current(state.filter);
        return {
          ...origState,
          filter: FilterHelper.reduce(origStateFilter, action.payload),
        };
      },
      updateSort: (state, action) => {
        const origState = current(state);
        const origStateFilter = current(state.sort);
        return {
          ...origState,
          sort: SortHelper.reduce(
            origStateFilter,
            action.payload.parentId,
            action.payload.sortSettings
          ),
        };
      },
    },
    extraReducers: (builder) => {
      if (thunkGetAll) {
        builder.addCase(thunkGetAll.fulfilled, (state, action) => {
          const cursor = action.meta.arg.cursor;
          const parentId = action.meta.arg.parentId;
          const extFilter = action.meta.arg.extFilter;

          state.extStatus = updateStatusForParentAndPayloadAction(
            state.extStatus
          )(
            parentId,
            extFilter
          )(action);

          const hasCursor = Boolean(action.meta.arg.cursor);
          const currentEntities = current(state.entities) as Entity[];

          // ignore current entities if an incoming entity exists with the same id
          const currentEntitiesFiltered = currentEntities.filter(
            (currentEntity) =>
              !action.payload.entities.some(
                (incomingEntity) => incomingEntity.id === currentEntity.id
              )
          );

          const [sameParentIdEntities, otherEntities] =
            currentEntitiesFiltered.reduce<[Entity[], Entity[]]>(
              ([a, b], entity) => {
                if (hasParentId(parentId)(entity)) {
                  return [[...a, entity], b];
                }
                return [a, [...b, entity]];
              },
              [[], []]
            );

          const deletedEntities = currentEntitiesFiltered.filter(
            (a) => a?.status === 'deleted'
          );

          if (merger) {
            state.entities = [
              ...(otherEntities as Draft<Entity[]>),
              ...(merger({
                current: sameParentIdEntities,
                incoming: action.payload.entities,
                parentId,
                extFilter,
                cursor,
              }) as Draft<Entity[]>),
            ];
          } else if (hasCursor) {
            state.entities = [
              ...state.entities,
              ...(action.payload.entities as Draft<Entity>[]),
            ];
          } else {
            state.entities = [
              ...new Set([
                ...(deletedEntities as Draft<Entity[]>),
                ...(otherEntities as Draft<Entity[]>),
                ...(action.payload.entities as Draft<Entity[]>),
              ]),
            ];
          }
        });
      }

      if (thunkFind) {
        builder.addCase(thunkFind.fulfilled, (state, action) => {
          state.extStatus = updateStatusForParentAndPayloadAction(
            state.extStatus
          )(action.meta.arg.id)(action);

          state.entities = [
            ...state.entities.filter((e) => e.id !== action.payload.id),
            action.payload as Draft<Entity>,
          ];
        });
      }

      if (thunkCreate) {
        builder.addCase(thunkCreate.fulfilled, (state, action) => {
          state.extStatus = updateStatusForParentAndPayloadAction(
            state.extStatus
          )(toParentId(action.payload))(action);

          state.entities = [action.payload as Draft<Entity>, ...state.entities];
        });
      }

      if (thunkUpdate) {
        builder.addCase(thunkUpdate.fulfilled, (state, action) => {
          state.extStatus = updateStatusForParentAndPayloadAction(
            state.extStatus
          )(action.meta.arg.id)(action);

          state.entities = state.entities.map((entity: Draft<Entity>) =>
            entity.id === action.payload.id
              ? (action.payload as Draft<Entity>)
              : (entity as Draft<Entity>)
          );
        });
      }

      if (thunkDelete) {
        builder.addCase(thunkDelete.fulfilled, (state, action) => {
          state.extStatus = updateStatusForParentAndPayloadAction(
            state.extStatus
          )(action.meta.arg.id)(action);
          state.entities = state.entities.map((entity: Draft<Entity>) =>
            entity.id === action.meta.arg.id
              ? { ...entity, status: 'deleted' }
              : entity
          );
        });
      }

      builderAddExtStatusCasesForThunks(
        toParentId,
        builder
      )(
        [thunkGetAll, thunkFind, thunkCreate, thunkUpdate, thunkDelete].filter(
          Boolean
        ) as AsyncThunk<any, any, any>[]
      );

      if (extraReducers) {
        extraReducers(builder);
      }
    },
  });

  const proxyUseFilter = ({
    parentId,
    dispatch,
    state,
  }: {
    parentId?: BaseEntity['id'];
    dispatch: any;
    state: State;
  }): EntitySliceFactoryProxyFilterResponse => {
    if (!parentId) {
      return {
        filter: [],
        toggleFilter: () => {},
        setFilter: () => {},
        clearFilter: () => {},
        sort: [],
        setSortSetting: () => {},
      };
    }

    const sort = (state.sort ?? {})[parentId] ?? defaultSort ?? [];

    const filter = state.filter[parentId] || [];

    const _updateFilter =
      (type: 'toggle' | 'set' | 'clear') => (filterItem?: any) => {
        dispatch(
          slice.actions.updateFilter(
            FilterHelper.makeUpdateAction(type, parentId, filterItem)
          )
        );
      };

    const toggleFilter = _updateFilter('toggle');
    const setFilter = _updateFilter('set');
    const clearFilter = () => _updateFilter('clear')();

    const setSortSetting = (sortSettings: SortSetting[]) =>
      dispatch(slice.actions.updateSort({ parentId, sortSettings }));

    return {
      filter,
      toggleFilter,
      setFilter,
      clearFilter,
      sort,
      setSortSetting,
    };
  };

  const proxyUseEntities = ({
    parentId,
    extFilter,
    dispatch,
    state,
  }: {
    parentId?: BaseEntity['id'];
    extFilter?: ExtFilterParams;
    dispatch: any;
    state: State;
  }): EntitySliceFactoryProxyEntitiesResponse<Entity> => {
    const allEntitiesAvailable = state.entities;

    const getExtStatus = (newState?: State) => {
      if (!parentId) {
        return undefined;
      }
      const result = getForPropertyAndAction(
        newState ? newState.extStatus : state.extStatus
      )(
        parentId,
        extFilter
      )(thunkGetAll.typePrefix);

      return result;
    };

    const extStatus: ExtStatus = memoize<any>(() => getExtStatus())([
      state,
      parentId,
      extFilter,
    ]);

    const entities = memoize<any>(() => {
      if (!parentId) {
        return [];
      }
      return extFilter
        ? allEntitiesAvailable.filter(
            (a) =>
              hasParentId(parentId)(a) &&
              matchesExtFilter(extFilter)(a) &&
              (a.status === undefined || a.status !== 'deleted')
          )
        : allEntitiesAvailable.filter(
            (a) =>
              hasParentId(parentId)(a) &&
              (a.status === undefined || a.status !== 'deleted')
          );
    })([extFilter, allEntitiesAvailable, parentId]);

    const refresh = useCallback(
      (force: boolean = true) => {
        // Refresh is forced by UI action (e.g. pull to refresh)
        // or if previous fetch data isn't available (e.g. page reload)
        // See `src/config/persist.ts` to see `extStatus` filtered
        const forceRefresh = force || !extStatus;
        if (forceRefresh) {
          return dispatch(thunkGetAll({ parentId, extFilter }));
        }

        // Only refresh the data if it's expired - this avoids querying too often
        // Ideally we'd have a way of knowing when updated data is available
        const { hasExpired } = checkEntityExpiration(
          ENTITIES_MAX_AGE,
          extStatus
        );
        if (hasExpired) {
          return dispatch(thunkGetAll({ parentId, extFilter }));
        }
        return Promise.resolve();
      },
      [parentId, JSON.stringify(extStatus), JSON.stringify(extFilter)]
    );

    if (!parentId) {
      return {
        allEntitiesAvailable,
        entities: [],
        loading: true, // TODO: this should be false?
        failed: false,
        total: 0,
        lastChanged: 0,
        refreshing: false,
        hasNextPage: false,
        refresh: () => {
          console.warn(
            `Unable to refresh - No parentId: ${thunkGetAll.typePrefix}`
          );
          return Promise.resolve();
        },
        loadMore: () => {
          console.warn(
            `Unable to loadMore - No parentId: ${thunkGetAll.typePrefix}`
          );
          return Promise.resolve();
        },
        create: () => {
          console.warn(
            `Unable to create - No parentId: ${thunkGetAll.typePrefix}`
          );
          return Promise.resolve(null);
        },
        //
        ...proxyUseFilter({
          parentId,
          dispatch,
          state,
        }),
        //
        prelude: {
          name,
          status: PreludeStatus.Loading,
        },
      };
    }

    const refreshing =
      extStatus?.status === Status.Loading && entities.length > 0;
    const loading =
      extStatus?.status === Status.Loading && entities.length === 0;

    const failed = extStatus?.status === Status.Failed;
    const lastError = extStatus?.error;
    const cursorNext = extStatus?.extCursor?.cursorNext;
    const hasNextPage = Boolean(cursorNext);
    const status = extStatusToPreludeStatus(extStatus, entities.length > 0);

    const total = extStatus?.extCursor?.total ?? entities.length;

    const loadMore = () => {
      if (!loading && !refreshing && cursorNext) {
        return dispatch(
          thunkGetAll({ parentId, extFilter, cursor: cursorNext })
        );
      }

      return Promise.resolve();
    };

    // @deprecated, use proxyUseEntity for create
    const create = (entity: Omit<Entity, 'id'>) => {
      return dispatch(thunkCreate(entity)).then((res: any) => {
        return res.payload;
      });
    };

    return {
      allEntitiesAvailable,
      refreshing,
      loading,
      failed,
      lastError,
      total,
      lastChanged: extStatus?.updatedAt ?? 0,
      entities,
      hasNextPage,
      refresh,
      loadMore,
      create,
      //
      ...proxyUseFilter({
        parentId,
        dispatch,
        state,
      }),
      //
      prelude: {
        name,
        status,
        lastError,
        onRetry: () => refresh(),
      },
    };
  };

  const proxyUseEntity = ({
    entityId,
    parentId,
    extFilter,
    dispatch,
    state,
    transformEntity,
  }: {
    entityId: Entity['id'];
    parentId?: string;
    extFilter?: ExtFilterParams;
    dispatch: any;
    state: State;
    transformEntity?: (entity: Entity) => Entity;
  }): EntitySliceFactoryProxyEntityResponse<Entity> => {
    const entity = memoize<(a: any) => Entity | null>(() => {
      const foundEntity = state.entities.find((p) => p.id === entityId);
      if (!foundEntity) {
        return null;
      }
      if (transformEntity) {
        return transformEntity(foundEntity);
      }
      return foundEntity;
    })([state, entityId]);

    const entityParentId = parentId ?? (entity ? toParentId(entity) : null);

    const extStatus = memoize<(a: any) => ExtStatus | null>(() => {
      if (thunkFind && entityId !== '_') {
        const result = getForPropertyAndAction(state.extStatus)(entityId)(
          thunkFind.typePrefix
        );
        if (!result) {
          return null;
        }
        return result;
      } else if (entityParentId) {
        const result = getForPropertyAndAction(state.extStatus)(
          entityParentId,
          extFilter
        )(thunkGetAll.typePrefix);
        if (!result) {
          return null;
        }
        return result;
      }
      return null;
    })([state.extStatus, extFilter, entityParentId]);

    const refreshing =
      extStatus?.status === Status.Loading && typeof entity !== 'undefined';
    const failed = extStatus?.status === Status.Failed;
    const loading = extStatus?.status === Status.Loading && !refreshing;
    const lastError = extStatus?.error;
    const status = extStatusToPreludeStatus(extStatus, Boolean(entity));

    const refresh = useCallback(
      (force?: boolean) => {
        if (entityParentId) {
          // Refresh is forced by UI action (e.g. pull to refresh)
          // or if previous fetch data isn't available (e.g. page reload)
          // See `src/config/persist.ts` to see `extStatus` filtered
          const forceRefresh = force || !extStatus;

          // Update the data if it's expired - this avoids querying too often
          // Ideally we'd have a way of knowing if updated data is available
          const { hasExpired } = checkEntityExpiration(
            ENTITIES_MAX_AGE,
            extStatus
          );

          if (forceRefresh || hasExpired) {
            if (thunkFind) {
              return dispatch(
                thunkFind({ parentId: entityParentId, id: entityId })
              ).then(() => {});
            }
            return dispatch(
              thunkGetAll({ parentId: entityParentId, extFilter })
            );
          }
        }

        return Promise.resolve();
      },
      [entityParentId, entityId, JSON.stringify(extStatus)]
    );

    const create = async (entity: Omit<Entity, 'id'>) => {
      try {
        const res = await dispatch(thunkCreate(entity)).unwrap();
        return Promise.resolve(res);
      } catch (error) {
        if (error.message === RequestErrors.NetworkError) {
          return Promise.resolve(entity);
        }
        return Promise.reject(error);
      }
    };

    const update = async (entity: Entity) => {
      try {
        const res = await dispatch(thunkUpdate(entity)).unwrap();
        return Promise.resolve(res);
      } catch (error) {
        if (error.message === RequestErrors.NetworkError) {
          return Promise.resolve(entity);
        }
        return Promise.reject(error);
      }
    };

    const remove = async () => {
      if (entity) {
        try {
          const res = await dispatch(thunkDelete(entity)).unwrap();
          return Promise.resolve(res);
        } catch (error) {
          if (error.message === RequestErrors.NetworkError) {
            return Promise.resolve(entity);
          }
          return Promise.reject(error);
        }
      }
      return Promise.resolve();
    };

    return {
      entity,
      refreshing,
      loading,
      failed,
      create,
      update,
      remove,
      refresh,
      prelude: {
        name,
        status,
        lastError,
      },
    };
  };

  return {
    slice,
    //
    proxyUseEntities,
    proxyUseFilter,
    proxyUseEntity,
    //
    thunkGetAll,
    thunkFind,
    thunkCreate,
    thunkUpdate,
    thunkDelete,
  };
};

export const builderAddExtStatusCasesForThunks =
  <Entity extends BaseEntity, State extends BaseEntityState<Entity>>(
    toParentId: (entity: Entity) => string,
    builder: ActionReducerMapBuilder<State>
  ) =>
  (thunks: AsyncThunk<any, any, any>[]) => {
    thunks
      .reduce<string[]>((acc, thunk) => {
        if (thunk) {
          return [...acc, ...[String(thunk.pending), String(thunk.rejected)]];
        }
        return acc;
      }, [])
      .forEach((thunk) => {
        builder.addCase(thunk, (state, action: any) => {
          const byId =
            action?.meta?.arg?.id ??
            action?.meta?.arg?.parentId ??
            toParentId(action?.meta?.arg);
          state.extStatus = updateStatusForParentAndPayloadAction(
            state.extStatus
          )(
            byId,
            action?.meta?.arg?.extFilter
          )(action);
        });
      });
  };

export const builderAddExtStatusPerActionCasesForThunks =
  <State extends { extStatus: ExtStatusPerAction }>(
    builder: ActionReducerMapBuilder<State>
  ) =>
  (thunks: AsyncThunk<any, any, any>[]) => {
    thunks
      .reduce<string[]>((acc, thunk) => {
        if (thunk) {
          return [...acc, ...[String(thunk.pending), String(thunk.rejected)]];
        }
        return acc;
      }, [])
      .forEach((thunk) => {
        builder.addCase(thunk, (state, action: any) => {
          state.extStatus = updateStatusForPayloadAction(state.extStatus)(
            action
          );
        });
      });
  };
