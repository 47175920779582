import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  checkHasMobbleConnectRolesBeenFilled,
  getPropertiesWhereUserHasAdminAccess,
  Property,
} from '@mobble/models/src/model/Property';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { useGetUser } from '@mobble/store/src/hooks/auth';

import {
  MobbleConnectInviteUserForm,
  MobbleConnectInviteUserFormValues,
} from '@src/stories/Views/Settings/SettingsPropertyMobbleConnectInviteUserForm';
import {
  checkEmailIsValid,
  findUserOfEmail,
  UserRole,
} from '@mobble/models/src/model/User';
import { ScreenRendererProps } from '@src/screens/config/types';
import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';

export const MobbleConnectInviteUserHeader: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const mobbleConnectRole = route.params.role as UserRole;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const roleString = formatMessage({
    id: `user_role.${mobbleConnectRole}`,
    defaultMessage: [],
  });

  return (
    <ScreenHeader
      title={formatMessage(
        {
          description: 'settings.property.mobble_connect.invite_user.title',
          defaultMessage: 'Invite {ROLE}',
        },
        {
          ROLE: roleString ?? mobbleConnectRole,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'settings.property.mobble_connect.title',
            defaultMessage: 'Mobble Connect',
          }),
          href: ROUTE_NAME.MOBBLE_CONNECT_USERS,
        },
        {
          title: { key: `user_role.${mobbleConnectRole}` },
          href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
            role: mobbleConnectRole,
          }),
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
          role: mobbleConnectRole,
        });
      }}
    />
  );
};

export const MobbleConnectInviteUser: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const mobbleConnectRole = route.params?.role as UserRole;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  const [emailInput, setEmailInput] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);

  const me = useGetUser();
  const properties = useProperties();

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  const property = properties.selected as Property;

  const propertiesWithAdminAccess = getPropertiesWhereUserHasAdminAccess(
    properties.entities
  )(me?.id ?? '');

  // filter out properties where the user role has been filled
  const propertiesWhereUserRoleNotFilled = propertiesWithAdminAccess.filter(
    (p) => !checkHasMobbleConnectRolesBeenFilled(p.users)(mobbleConnectRole)
  );

  // filter out properties where the user is already part of
  const propertiesUserIsNotPartOf = propertiesWhereUserRoleNotFilled.filter(
    (p) => !findUserOfEmail(p.users)(emailInput)
  );

  const isUserAlreadyPartOfProperty = Boolean(
    findUserOfEmail(property.users)(emailInput)
  );

  const propertyOptions = propertiesUserIsNotPartOf.map((p) => ({
    label: p.name,
    value: p.id,
  }));

  if (Prelude) {
    return Prelude;
  }

  const infoMessage =
    emailInput && isUserAlreadyPartOfProperty
      ? formatMessage({
          description:
            'settings.property.mobble_connect.invite_user.form.user.exists.message',
          defaultMessage: 'User is already part of the current property',
        })
      : null;

  const handleCancel = () => {
    linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
      role: mobbleConnectRole,
    });
  };

  const handleSubmit = ({
    email,
    propertyIds,
  }: MobbleConnectInviteUserFormValues) => {
    setLoading(true);

    properties
      .addUser({
        propertyIds,
        organisationId: property.organisationId,
        role: mobbleConnectRole,
        email,
      })
      .then(() => {
        setLoading(false);
        linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
          role: mobbleConnectRole,
        });
      });
  };

  const disabled =
    isUserAlreadyPartOfProperty || !checkEmailIsValid(emailInput);

  return (
    <MobbleConnectInviteUserForm
      loading={loading}
      properties={propertyOptions}
      currentPropertyId={property.id}
      emailErrorMessage={infoMessage}
      disabled={disabled}
      onChange={({ email: e }) => setEmailInput(e)}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MOBBLE_CONNECT_USER_INVITE,
  header: MobbleConnectInviteUserHeader,
  component: MobbleConnectInviteUser,
};
