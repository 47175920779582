import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { useMap } from './React/Context';
import { safeAddLayer, safeRemoveLayer } from './helpers';

export type LayerOptions = mapboxgl.AnyLayer;

export interface LayerProps {
  id: string;
  options: LayerOptions;
  before?: string;
  visible?: boolean;
}

export const Layer: React.FC<LayerProps> = ({
  id,
  options,
  before,
  visible = true,
}) => {
  const map = useMap();

  useEffect(() => {
    const cleanUp = () => {
      safeRemoveLayer(map)(id);
    };

    safeAddLayer(map)(
      {
        id: id,
        ...options,
      },
      before
    );

    return () => {
      cleanUp();
    };
  }, [id, JSON.stringify(options)]);

  useEffect(() => {
    if (map.getLayer(id)) {
      map.setLayoutProperty(id, 'visibility', visible ? 'visible' : 'none');
    }
  }, [id, visible]);

  return null;
};
