import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Color } from '@mobble/colors';
import { useI18n, useMessages } from '@mobble/i18n';

import { Button, Checkbox } from '@src/components';

import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { InputError } from '@src/stories/Components/UX/InputError';

import { FormItem } from './SignUpForm';

import styles from './inviteForm.scss';

export interface InviteFormProps {
  error?: null | string;
  loading?: boolean;
  initialValues?: InviteFormValues;
  email?: string;
  onSubmit: (args: InviteFormValues) => void;
}

export interface InviteFormValues {
  firstName: string;
  lastName: string;
  password: string;
  termsAgreed: boolean;
}

const messages = defineMessages({
  'firstName.error': {
    defaultMessage: 'Insert your first name',
    description: 'auth.invite.form.firstName.error',
  },
  'firstName.label': {
    defaultMessage: 'First name',
    description: 'auth.invite.form.firstName.label',
  },
  'lastName.error': {
    defaultMessage: 'Insert your last name',
    description: 'auth.invite.form.lastName.error',
  },
  'lastName.label': {
    defaultMessage: 'Last name',
    description: 'auth.invite.form.lastName.label',
  },

  'password.error': {
    defaultMessage: 'Insert your password',
    description: 'auth.invite.form.password.error',
  },
  'password.label': {
    defaultMessage: 'Password',
    description: 'auth.invite.form.password.label',
  },
  submit: {
    defaultMessage: 'Continue',
    description: 'auth.invite.form.submit',
  },
  'termsAgreed.error': {
    defaultMessage: 'Must Accept Terms and Conditions',
    description: 'auth.invite.form.termsAgreed.error',
  },
  'termsAgreed.message1': {
    defaultMessage: 'I have read and agree with the ',
    description: 'auth.invite.form.termsAgreed.message1',
  },
  'termsAgreed.message2': {
    defaultMessage: 'Mobble Customer Agreement',
    description: 'auth.invite.form.termsAgreed.message2',
  },
  'termsAgreed.message3': {
    defaultMessage: ' and ',
    description: 'auth.invite.form.termsAgreed.message3',
  },
  'termsAgreed.message4': {
    defaultMessage: 'Mobble Privacy Policy',
    description: 'auth.invite.form.termsAgreed.message4',
  },
});

export const InviteForm: React.FC<InviteFormProps> = ({
  error,
  loading,
  initialValues,
  email,
  onSubmit,
}) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages);

  const SignUpSchema = Yup.object().shape({
    firstName: Yup.string().required(strings['firstName.error']),
    lastName: Yup.string().required(strings['lastName.error']),
    password: Yup.string().required(strings['password.error']),
    termsAgreed: Yup.boolean().oneOf([true], strings['termsAgreed.error']),
  });

  return (
    <div className={styles.InviteForm}>
      <Text
        align="center"
        variant="card-title"
        color={Color.Green}
        tagName="h1"
      >
        {formatMessage({
          defaultMessage: "You've been invited to Mobble!",
          description: 'auth.invite.title',
        })}
      </Text>

      <Text align="center" variant="larger" color={Color.DarkGrey} tagName="h2">
        {formatMessage({
          defaultMessage:
            'Fill in your name and password to continue to the property.',
          description: 'auth.invite.description',
        })}
      </Text>

      <Formik
        initialValues={initialValues}
        validationSchema={SignUpSchema}
        enableReinitialize
        isInitialValid
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, errors, touched, setValues }) => {
          return (
            <>
              <Box spacing={1}>
                <Box
                  spacing={2}
                  background={Color.White}
                  className={styles.emailField}
                >
                  <Text color={Color.Green} bold>
                    {email}
                  </Text>
                </Box>
              </Box>
              <FormItem
                option={{
                  id: 'firstName',
                  type: 'text',
                  placeholder: strings['firstName.label'],
                  autoComplete: 'given-name',
                  onChange: (value: string) =>
                    setValues({ ...values, firstName: value }),
                  value: values.firstName,
                  error:
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : null,
                }}
              />
              <FormItem
                option={{
                  id: 'lastName',
                  type: 'text',
                  placeholder: strings['lastName.label'],
                  autoComplete: 'family-name',
                  onChange: (value: string) =>
                    setValues({ ...values, lastName: value }),
                  value: values.lastName,
                  error:
                    errors.lastName && touched.lastName
                      ? errors.lastName
                      : null,
                }}
              />

              <Box spacing={2}>
                <Box width={16} height={0.25} background={Color.LightGrey} />
              </Box>

              <FormItem
                option={{
                  id: 'password',
                  type: 'password',
                  placeholder: strings['password.label'],
                  autoComplete: 'new-password',
                  onChange: (value: string) =>
                    setValues({ ...values, password: value }),
                  value: values.password,
                  error:
                    errors.password && touched.password
                      ? errors.password
                      : null,
                }}
              />

              <Box spacing={2}>
                <Box spacing={1} flex>
                  <HStack alignment="center">
                    <Checkbox
                      id="termsAgreed"
                      label={
                        <span className={styles.termsLabel}>
                          {formatMessage(
                            {
                              description: 'auth.invite.form.termsAgreed.label',
                              defaultMessage:
                                'I have read and agree with the {customerAgreement} and {privacyPolicy}',
                            },
                            {
                              customerAgreement: (
                                <a
                                  href="https://www.mobble.io/legal/customer-agreement"
                                  target="blank"
                                  style={{ color: 'var(--primary-default)' }}
                                >
                                  <FormattedMessage
                                    description="Mobble Customer Agreement page title"
                                    defaultMessage="Mobble Customer Agreement"
                                  />
                                </a>
                              ),
                              privacyPolicy: (
                                <a
                                  href="https://www.mobble.io/legal/privacy-policy"
                                  target="blank"
                                  style={{ color: 'var(--primary-default)' }}
                                >
                                  <FormattedMessage
                                    description="Mobble Privacy Policy page title"
                                    defaultMessage="Mobble Privacy Policy"
                                  />
                                </a>
                              ),
                            }
                          )}
                        </span>
                      }
                      checked={values.termsAgreed}
                      size="small"
                      onChange={() => {
                        setValues({
                          ...values,
                          termsAgreed: !values.termsAgreed,
                        });
                      }}
                    />
                  </HStack>
                </Box>
                <InputError
                  error={
                    errors.termsAgreed && touched.termsAgreed
                      ? errors.termsAgreed
                      : null
                  }
                />
              </Box>
              <Spacer y={1} />

              <div role="alert">
                {error && (
                  <Box spacing={{ top: 2, right: 2, bottom: 4, left: 2 }}>
                    <Text
                      color={Color.Red}
                      i18n={{
                        key: 'auth.invite.form.error',
                        params: { '%ERROR': error },
                      }}
                    >
                      {formatMessage(
                        {
                          description: 'auth.invite.form.error',
                          defaultMessage: 'An error occurred: {error}',
                        },
                        {
                          error,
                        }
                      )}
                    </Text>
                  </Box>
                )}
              </div>

              <Box spacing={1} flex>
                <Button
                  label={strings.submit}
                  type="submit"
                  loading={loading}
                  flex
                  onClick={handleSubmit}
                />
              </Box>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
