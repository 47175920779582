import { type QueueAction } from '@mobble/models/src/model/QueueAction';
import { useRootDispatch, useRootSelector } from './root';
import { findReplayableThunk } from '../config/replayableThunks';
import { removeFromQueue } from '../reducers/actionQueue';

export interface UseActionQueueResult {
  lastRejectedReason?: string;
  actions?: QueueAction[];
  actionCount: number;
  discard: (queueAction: QueueAction) => void;
  replay: (queueAction: QueueAction) => Promise<void>;
  replayAll: () => Promise<void[]>;
}

export const useActionQueue = (propertyId?: string): UseActionQueueResult => {
  const dispatch = useRootDispatch();

  const stateActionQueue = useRootSelector((state) => state.actionQueue);
  const actions = stateActionQueue.actions.filter(
    (a) => !propertyId || a.propertyId === propertyId
  );

  const lastRejectedReason: string | undefined =
    stateActionQueue.lastRejectedReason;

  const discard = (queueAction: QueueAction) => {
    dispatch(removeFromQueue(queueAction.id));
  };

  const replay = async (queueAction: QueueAction) => {
    const thunk = findReplayableThunk(queueAction);
    if (!thunk) {
      discard(queueAction);
      return Promise.resolve();
    }

    try {
      const res = await dispatch(
        thunk({ ...queueAction.action.args, queueActionId: queueAction.id })
      ).unwrap();
      discard(queueAction);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const replayAll = () => {
    return Promise.all(actions.map((queueAction) => replay(queueAction)));
  };

  return {
    lastRejectedReason,
    actions: actions.sort((a, b) => b.timestamp - a.timestamp),
    actionCount: actions.length,
    discard,
    replay,
    replayAll,
  };
};
