import React from 'react';
import { Color, colorToHex, contrastingTextColor } from '@mobble/colors';
import { Box } from '../Layout/Box';
import { Text, TextVariants } from './Text';
import { Icon, type IconName } from './Icon';
import { HStack } from '../Layout/Stack';
import styles from './badge.scss';

export interface BadgeProps {
  color?: string | Color;
  label?: string;
  icon?: IconName;
  boxy?: boolean;
  textVariant?: TextVariants;
}

export const Badge: React.FC<BadgeProps> = ({
  color = Color.Green,
  label,
  icon,
  boxy,
  textVariant = 'small',
}) => {
  const classNames = [styles.badge, boxy ? styles.boxy : null]
    .filter(Boolean)
    .join(' ');
  return (
    <Box
      className={classNames}
      spacing={{
        top: 0.5,
        right: icon && boxy ? 1.5 : 1,
        bottom: 0.5,
        left: 1,
      }}
      style={color ? { backgroundColor: colorToHex(color) } : {}}
    >
      <HStack alignment="center">
        {icon && (
          <Box spacing={{ top: 0.5, right: 1, bottom: 0.5, left: 0.5 }}>
            <Icon
              size="small"
              color={contrastingTextColor(color)}
              name={icon}
            />
          </Box>
        )}
        <Text
          bold={boxy}
          color={contrastingTextColor(color)}
          variant={textVariant}
          i18n={label}
        />
      </HStack>
    </Box>
  );
};
