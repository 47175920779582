import React from 'react';
import { Point } from '@mobble/models/src/model/MapGeometry';
import geolocation from '../interfaces/Geolocation';

export const useMyLocation = (): { location: null | Point } => {
  const [location, setLocation] = React.useState<null | Point>(
    geolocation.getLastKnownLocation()
  );

  React.useEffect(() => {
    return geolocation.watch(setLocation);
  }, [setLocation]);

  return { location };
};
