import { roundNumberTo } from './Number';

export type Monetary = number | MonetaryCents;

export interface MonetaryCents {
  type: 'cents';
  value: number;
}

export const formatMonetary = (
  monetary: Monetary,
  currencySymbol: string
): string => {
  const renderCents = (value: number): string => {
    const valueString = roundNumberTo(2)(value / 100).toString();
    const [whole, decimal] = valueString.split('.');
    const wholeThousands = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (decimal) {
      return `${wholeThousands}.${decimal.padEnd(2, '0')}`;
    }

    return `${wholeThousands}.00`;
  };

  if (typeof monetary === 'number') {
    return `${currencySymbol}${renderCents(monetary)}`;
  }

  switch (monetary.type) {
    case 'cents':
      return `${currencySymbol}${renderCents(monetary.value)}`;
    default:
      return `${currencySymbol}0.00`;
  }
};
