import React from 'react';
import { Color } from '@mobble/colors';
import { RawDate } from '@mobble/shared/src/core/Date';
import { Box } from '../Layout/Box';
import { Text } from './Text';
import { LocaleDate } from '../Locale/LocaleDate';
import { LocaleTime } from '../Locale/LocaleTime';
import styles from './timelineEvent.scss';

export type TimelineEventProps = {
  date: RawDate;
  title?: string;
  last?: boolean;
  color?: Color;
  children?: React.ReactNode;
};

export const TimelineEvent: React.FC<TimelineEventProps> = ({
  title,
  last,
  date,
  color,
  children,
}) => {
  return (
    <Box className={styles.timelineEvent}>
      <Box className={styles.lineContainer}>
        <Box className={styles.dateLabel}>
          <LocaleDate variant="small" date={date} />
          <LocaleTime variant="small" date={date} />
        </Box>

        <Box spacing={1} className={styles.dotContainer}>
          <Box className={styles.dot} background={color || Color.Purple} />
        </Box>

        {!last ? <Box className={styles.line} /> : <></>}
      </Box>

      <Box className={styles.cardInner} spacing={2}>
        {title ? (
          <Text
            tagName="h1"
            variant="card-title"
            color={color}
            className={styles.cardTitle}
          >
            {title}
          </Text>
        ) : null}
        {children}
      </Box>
    </Box>
  );
};
