import { useRootSelector, useRootDispatch } from './root';
import {
  proxyUseEntities,
  proxyUseEntity,
  thunkGetRainGaugeYearTotal,
} from '../reducers/rainGauges';

export const useRainGauges = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.rainGauges);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const useRainGauge = (rainGaugeId: string, propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.rainGauges);

  const proxied = proxyUseEntity({
    entityId: rainGaugeId,
    parentId: propertyId,
    dispatch,
    state,
  });

  const getYearTotals = (year: number): Promise<void> => {
    if (proxied.entity) {
      dispatch(
        thunkGetRainGaugeYearTotal({
          propertyId: proxied.entity?.propertyId,
          rainGaugeId,
          year,
        })
      ).then(() => {});
    }
    return Promise.reject();
  };

  return {
    ...proxied,
    getYearTotals,
  };
};
