import { useIntl, type MessageDescriptor } from 'react-intl';

export interface IDefinedMessages {
  [key: string]: MessageDescriptor;
}

export interface IFormattedStrings {
  [key: string]: string;
}

/**
 * Returns an object of intl formatted message strings
 * @param messages
 * @param values
 */
export function useMessages(messages: IDefinedMessages, values?: any) {
  const intl = useIntl();
  const strings = {} as IFormattedStrings;

  // Return empty object if no messages provided
  if (!messages) {
    console.warn('messages not provided');
    return strings;
  }

  // Format messages to locale
  for (const key of Object.keys(messages)) {
    strings[key] = intl.formatMessage(messages[key], values);
  }
  return strings;
}
