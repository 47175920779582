import React, { useEffect, useState } from 'react';
import { useI18n } from '@mobble/i18n';
import {
  mapAssetTypeToGeometryFeatureType,
  type MapAssetType,
  mapAssetsTypes,
} from '@mobble/models/src/model/MapAsset';
import { useTitle } from '@src/hooks/useTitle';
import ListSelect from '@src/components/ListSelect';

import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { ModalSelectOption } from '@src/stories/Components/UX/ModalSelect';
import { Button } from '@src/stories/Components/UX/Button';
import { type MapStateComponentProps, type SharedState } from '../SharedTypes';

import styles from './style.scss';

export const Header: React.FC<MapStateComponentProps> = () => {
  const { formatMessage } = useI18n();
  const title = formatMessage({
    defaultMessage: 'Add new map asset',
    description: 'screen.title.map_asset_create',
  });
  useTitle(title);

  return (
    <Box className={styles.header}>
      <Text tagName="h1" variant="larger" bold>
        {title}
      </Text>
      <Text tagName="p">
        {formatMessage({
          description: 'map.overview.add_asset.instructions',
          defaultMessage: 'Select a point on the map to set Asset location.',
        })}
      </Text>
    </Box>
  );
};

export const AssetTypeSelect: React.FC<{
  selectedMapAssetType: SharedState['selectedMapAssetType'];
  updateState: MapStateComponentProps['updateState'];
}> = ({ selectedMapAssetType, updateState }) => {
  const { formatMessage } = useI18n();
  const typeToI18n = (type: MapAssetType) => ({
    id: `map_assets.type.${type}`,
    defaultMessage: [],
  });

  const typeToModeI18n = (type: MapAssetType) => {
    switch (mapAssetTypeToGeometryFeatureType(type)) {
      case 'Point':
        return formatMessage({
          defaultMessage: 'Point',
          description: 'map_asset.mode.point',
        });
      case 'LineString':
        return formatMessage({
          defaultMessage: 'Line',
          description: 'map_asset.mode.line_string',
        });
      default:
        return '';
    }
  };

  const onChange = ([type]: [MapAssetType]) => {
    updateState({
      selectedMapAssetType: type,
    });
  };

  const options: ModalSelectOption[] = mapAssetsTypes.map((type) => ({
    label: formatMessage(typeToI18n(type)),
    value: type,
    labelExtra: typeToModeI18n(type),
    selected: selectedMapAssetType === type,
  }));

  return (
    <ListSelect
      id="map-asset-type"
      menuPlacement="top"
      placeholder={formatMessage({
        defaultMessage: 'Select asset type:',
        description: 'map.overview.add_asset.header.map_asset_type.placeholder',
      })}
      options={options}
      onChange={onChange}
    />
  );
};

export const Footer: React.FC<MapStateComponentProps> = (props) => {
  const { formatMessage } = useI18n();
  const { stateRef, onSubmit, onCancel } = props;
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // const ref = mapPluginStateRef.current;
    const onUpdate = ({ points }) => {
      setReady(points.length > 0);
    };

    stateRef.current?.addListener(onUpdate);

    return () => {
      stateRef.current?.removeListener(onUpdate);
    };
  }, [stateRef]);

  return (
    <div className={styles.footer}>
      <div className={styles.typeSelect}>
        <AssetTypeSelect
          selectedMapAssetType={props.state.selectedMapAssetType}
          updateState={props.updateState}
        />
      </div>
      <div className={styles.footerButtons}>
        <Button
          flex
          intent="destructive"
          outline
          type="reset"
          onClick={onCancel}
          label={formatMessage({
            description: 'map.overview.add_asset.footer.cancel.label',
            defaultMessage: 'Cancel',
          })}
        />
        <Button
          flex
          type="submit"
          disabled={!ready}
          onClick={onSubmit}
          label={formatMessage({
            description: 'map.overview.add_asset.footer.submit.label',
            defaultMessage: 'Add asset',
          })}
        />
      </div>
    </div>
  );
};
