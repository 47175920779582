import React from 'react';
import { useI18n } from '@mobble/i18n';
import { RouteName, ScreenRendererProps } from '../config/types';
import * as ROUTE_NAME from '../config/routeNames';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { useAccessHelper } from '../../hooks/useAccessHelper';
import {
  CardedNavigationList,
  CardedNavigationListGroupProps,
} from '@src/stories/Components/UX/CardedNavigationList';
import { IconName } from '@src/stories/Components/UI/Icon';

export const SummariesHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.summaries',
        defaultMessage: 'Summaries',
      })}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Summaries: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const { hasAccessToRoute, showNoAccessAlert } = useAccessHelper();

  const items: CardedNavigationListGroupProps[] = [
    {
      title: formatMessage({
        description: 'summaries.toc.property',
        defaultMessage: 'Property',
      }),
      data: [
        {
          label: formatMessage({
            description: 'screen.title.summary_events',
            defaultMessage: 'Event Log',
          }),
          description: formatMessage({
            description: 'summaries.toc.events_description',
            defaultMessage: 'View events that have occurred',
          }),
          href: ROUTE_NAME.SUMMARY_EVENTS_LIST,
          icon: 'activity',
        },
        {
          label: formatMessage({
            description: 'screen.title.summary_map_assets',
            defaultMessage: 'Map Assets',
          }),
          description: formatMessage({
            description: 'summaries.toc.map_assets_description',
            defaultMessage: 'View map assets list',
          }),
          href: ROUTE_NAME.MAP_ASSETS_LIST,
          icon: 'map-pin',
        },
      ],
    },
    {
      title: formatMessage({
        description: 'summaries.toc.livestock',
        defaultMessage: 'Livestock',
      }),
      data: [
        {
          label: formatMessage({
            description: 'screen.title.summary_mob_actions',
            defaultMessage: 'Mob Actions',
          }),
          description: formatMessage({
            description: 'summaries.toc.mob_actions_description',
            defaultMessage: 'View mob action records',
          }),
          href: ROUTE_NAME.SUMMARY_MOB_ACTIONS_LIST,
          icon: 'check-circle',
        },
        {
          label: formatMessage({
            description: 'screen.title.summary_sales',
            defaultMessage: 'Sales',
          }),
          description: formatMessage({
            description: 'summaries.toc.sales_description',
            defaultMessage: 'View sale records',
          }),
          href: ROUTE_NAME.SUMMARY_SALES_LIST,
          icon: 'dollar-sign',
        },
        {
          label: formatMessage({
            description: 'screen.title.summary_purchases',
            defaultMessage: 'Purchases',
          }),
          description: formatMessage({
            description: 'summaries.toc.purchases_description',
            defaultMessage: 'View purchase records',
          }),
          href: ROUTE_NAME.SUMMARY_PURCHASES,
          icon: 'average',
        },
        {
          label: formatMessage({
            description: 'screen.title.summary_natural_increases',
            defaultMessage: 'Natural Increases',
          }),
          description: formatMessage({
            description: 'summaries.toc.natural_increases_description',
            defaultMessage: 'View natural increase records',
          }),
          href: ROUTE_NAME.SUMMARY_NATURAL_INCREASES_LIST,
          icon: 'arrow-up-circle',
        },
        {
          label: formatMessage({
            description: 'screen.title.summary_casualties',
            defaultMessage: 'Casualties',
          }),
          description: formatMessage({
            description: 'summaries.toc.casualties_description',
            defaultMessage: 'View casualty records',
          }),
          href: ROUTE_NAME.SUMMARY_CASUALTIES,
          icon: 'crosshair',
        },
      ],
    },
    {
      title: formatMessage({
        description: 'summaries.toc.paddocks',
        defaultMessage: 'Paddocks',
      }),
      data: [
        {
          label: formatMessage({
            description: 'screen.title.summary_paddock_actions',
            defaultMessage: 'Paddock Actions',
          }),
          description: formatMessage({
            description: 'summaries.toc.paddock_actions_description',
            defaultMessage: 'View paddock action records',
          }),
          href: ROUTE_NAME.SUMMARY_PADDOCK_ACTIONS_LIST,
          icon: 'paddock-action',
        },
      ],
    },
  ].map((item) => ({
    title: item.title,
    data: item.data.map((item) => ({
      title: item.label,
      subtitle: item.description,
      href: hasAccessToRoute(item.href as RouteName)
        ? (item.href as RouteName)
        : showNoAccessAlert,
      icon: item.icon as IconName,
      disabled: !hasAccessToRoute(item.href as RouteName),
    })),
  }));

  return <CardedNavigationList items={items} />;
};

export default {
  name: ROUTE_NAME.SUMMARIES_LIST,
  header: SummariesHeader,
  component: Summaries,
};
