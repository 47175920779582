import mapboxgl from 'mapbox-gl';
import { MapStyle } from '@mobble/models/src/model/MapStyle';

const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY;
const HERE_API_KEY = process.env.REACT_APP_HERE_API_KEY;

export interface MapBoxStylesItem {
  mapStyle: MapStyle;
  style: mapboxgl.Style | string;
}

export const MAPBOX_STYLES: MapBoxStylesItem[] = [
  {
    mapStyle: MapStyle.Satellite,
    style: 'mapbox://styles/mapbox/satellite-streets-v12?optimize=true',
  },
  {
    mapStyle: MapStyle.SatelliteMapTiler,
    style: {
      name: 'Maptiler Satellite Streets',
      version: 8,
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
      sources: {
        'satellite-maptiler': {
          type: 'raster',
          tiles: [
            `https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=${MAPTILER_API_KEY}`,
          ],
          tileSize: 256,
        },
      },
      layers: [
        {
          id: 'satellite-maptiler',
          type: 'raster',
          source: 'satellite-maptiler',
        },
      ],
    },
  },
  {
    mapStyle: MapStyle.SatelliteHere,
    style: {
      version: 8,
      name: 'HERE Satellite Streets',
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
      sources: {
        'satellite-here': {
          type: 'raster',
          tiles: [
            `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?style=satellite.day&apikey=${HERE_API_KEY}`,
          ],
          tileSize: 256,
        },
      },
      layers: [
        {
          id: 'satellite-here',
          type: 'raster',
          source: 'satellite-here',
        },
      ],
    },
  },
  {
    mapStyle: MapStyle.Outdoor,
    style: 'mapbox://styles/mapbox/outdoors-v11',
  },
];

export const getAvailableMapStyles = (): MapStyle[] =>
  MAPBOX_STYLES.map((style) => style.mapStyle);

export const getStyleUrlForMapStyle = (
  mapStyle: MapStyle
): MapBoxStylesItem['style'] => {
  const style = MAPBOX_STYLES.find((s) => s.mapStyle === mapStyle);
  return typeof style?.style === 'string'
    ? style.style
    : MAPBOX_STYLES[0].style;
};

export interface MapboxSourceAndLayers {
  sources: mapboxgl.RasterSource[];
  layers: mapboxgl.RasterLayer[];
}

export const getAvailableMapSourcesAndLayers = (): MapboxSourceAndLayers => {
  const sources: mapboxgl.RasterSource[] = [];
  const layers: mapboxgl.RasterLayer[] = [];

  MAPBOX_STYLES.forEach((styleItem) => {
    if (typeof styleItem.style !== 'string') {
      const sourceId = Object.keys(styleItem.style.sources)[0];
      const sourceData = styleItem.style.sources[
        sourceId
      ] as mapboxgl.RasterSource;
      sources.push({
        id: sourceId,
        tiles: sourceData.tiles,
        tileSize: sourceData.tileSize,
      } as mapboxgl.RasterSource);

      const layerId = styleItem.style.layers[0].id;
      const layerData = styleItem.style.layers[0] as mapboxgl.RasterLayer;
      layers.push({
        id: layerId,
        type: layerData.type,
        source: layerData.source,
      } as mapboxgl.RasterLayer);
    }
  });

  return {
    sources,
    layers,
  };
};
