import React, {
  ComponentPropsWithoutRef,
  KeyboardEvent,
  useState,
} from 'react';

import { useI18n } from '@mobble/i18n';

import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Box } from '@src/stories/Components/Layout/Box';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Color } from '@mobble/colors/src';
import { Input } from '@src/stories/Components/UX/Input';

import styles from './ChatForm.scss';

export interface ChatFormProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onSubmit'> {
  /**
   * Returns a chat message's body text
   */
  onSubmit: (value: string) => void;
}

/**
 * ChatForm displays a text input and a submit button
 */
export const ChatForm: React.FC<ChatFormProps> = ({
  onSubmit,
  className,
  ...others
}) => {
  const { formatMessage } = useI18n();
  const [value, setValue] = useState('');
  const rootClasses = [styles.ChatForm, className].join(' ');

  const handleInputChange = (value: string) => {
    setValue(value);
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && event.code === 'Enter') {
      event.preventDefault();
      handleSendClick();
    }
  };

  const handleSendClick = () => {
    onSubmit(value);
    setValue('');
  };

  return (
    <div className={rootClasses} {...others}>
      <Input
        type="textarea"
        value={value}
        maxLength={1024}
        placeholder={formatMessage({
          defaultMessage: 'Type a message...',
          description: 'chat.message.input.placeholder',
        })}
        autoFocus
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        className={styles.input}
      />

      <Clickable
        onClick={handleSendClick}
        disabled={!value.trim()}
        color={Color.Red}
        className={styles.buttonComtainer}
        aria-label={formatMessage({
          defaultMessage: 'Send message',
          description: 'chat.message.input.button.label',
        })}
      >
        <Box
          className={styles.button}
          background={value.trim() ? Color.Green : Color.LightGrey}
        >
          <Icon name="chat" color={Color.White} />
        </Box>
      </Clickable>
    </div>
  );
};
