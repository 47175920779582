import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n, useMessages } from '@mobble/i18n';
import { getLivestockAgeColor, type Mob } from '@mobble/models/src/model/Mob';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { formatDate } from '@mobble/shared/src/core/Date';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { BadgeList } from '@src/components';
import { useSetting } from '@src/context/settings';
import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';
import { InfoRow, type InfoRowProps } from '@src/stories/Components/UI/InfoRow';

export interface MobInfoTableProps {
  mob: Mob;
  paddock?: Paddock;
  onClickPaddock: (paddock: Paddock) => void;
}

const messages = defineMessages({
  ages: {
    description: 'mob.info.table.row.ages.label',
    defaultMessage: 'Ages',
  },
  breed: {
    description: 'mob.info.table.row.breed.label',
    defaultMessage: 'Breed',
  },
  gender: {
    description: 'mob.info.table.row.gender.label',
    defaultMessage: 'Gender',
  },
  name: {
    description: 'mob.info.table.row.name.label',
    defaultMessage: 'Name',
  },
  number: {
    description: 'mob.info.table.row.number.label',
    defaultMessage: 'Head',
  },
  paddock: {
    description: 'mob.info.table.row.paddock.label',
    defaultMessage: 'Paddock',
  },
  safedate: {
    description: 'mob.info.table.row.safedate.label',
    defaultMessage: 'Safe Date',
  },
  type: {
    description: 'mob.info.table.row.type.label',
    defaultMessage: 'Type',
  },
  classes: {
    description: 'mob.info.table.row.classes.label',
    defaultMessage: 'Classes',
  },
});

export const MobInfoTable: React.FC<MobInfoTableProps> = ({
  mob,
  paddock,
  onClickPaddock,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();
  const dateFormat = useSetting('dateFormat') as string;
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const properties = useProperties();
  const propertyTypes = properties.selected?.types;

  const rows: InfoRowProps[] = [
    {
      label: strings.name,
      value: `${mob.breed} ${mob.gender}`,
    },
    {
      label: strings.number,
      value: mob.size,
    },
    ...(paddock
      ? [
          {
            label: strings.paddock,
            value: paddock.name,
            href: () => {
              onClickPaddock(paddock);
            },
          },
        ]
      : []),
    {
      label: strings.type,
      value: mob.type,
    },
    {
      label: strings.gender,
      value: mob.gender,
    },
    {
      label: strings.breed,
      value: mob.breed,
    },
    {
      label: formatMessage(getStockingUnitI18n(stockingUnit)),
      value: formatStockingRate(mob.DSE, stockingUnit),
    },
    {
      label: strings.safedate,
      value: mob.safeDate ? formatDate(mob.safeDate, dateFormat) : ' - ',
    },
    {
      label: strings.ages,
      value: () => {
        return (
          <BadgeList
            badges={mob.ages.map((age) => ({
              label: `${age}`,
              color: getLivestockAgeColor(propertyTypes, mob.type)(age),
            }))}
          />
        );
      },
    },
    ...(mob.classes.length
      ? [
          {
            label: strings.classes,
            value: () => {
              return (
                <BadgeList
                  badges={mob.classes.map((label) => ({
                    label,
                  }))}
                  badgesToShow={3}
                  defaultColor="var(--neutrals-bg-default)"
                />
              );
            },
          },
        ]
      : []),
  ];

  return (
    <>
      {rows.map((row) => (
        <InfoRow key={row.label} {...row} />
      ))}
    </>
  );
};
