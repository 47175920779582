import React from 'react';
import { LngLatBoundsLike } from 'mapbox-gl';
import { Color } from '@mobble/colors';
import { Box } from '@src/stories/Components/Layout/Box';
import { LoadingScreen } from '@src/stories/Views/Misc/LoadingScreen';
import { Map as MapboxMap } from '@src/stories/Mapbox/Map';
import { Raster } from '@src/stories/Mapbox/lib/Source/Raster';
import { Layer } from '@src/stories/Mapbox/lib/Layer';
import {
  getStyleUrlForMapStyle,
  getAvailableMapSourcesAndLayers,
} from './config';
import { MapProvider, type MapProviderProps } from './Context';
import * as MapPlugins from './Plugins';

import styles from './map.scss';

export type MapProps = {
  types?: MapPlugins.MapPluginTypes;
  static?: boolean;
} & Omit<MapProviderProps, 'children' | 'types'>;

export const Map: React.FC<MapProps> = (props) => {
  const providerProps = {
    ...props,
    types: props.types ?? 'standard',
  };

  return (
    <MapProvider {...providerProps}>
      {({ boundingBox, mapStyle, onMapReady, mapIsReady }) => {
        const { sources, layers } = getAvailableMapSourcesAndLayers();
        const mapStyleUrl = getStyleUrlForMapStyle(mapStyle);
        const key = mapStyle.split('-')[0];

        return (
          <Box className={styles.container}>
            {!mapIsReady && (
              <Box className={styles.loadingContainer}>
                <LoadingScreen background={Color.Black} />
              </Box>
            )}
            <MapPlugins.ToolContents />
            <MapPlugins.Toolbar />
            <MapboxMap
              key={key}
              className={styles.mapViewContainer}
              accessToken="pk.eyJ1IjoibW9iYmxlIiwiYSI6ImNrcTkza3V4YTA0ZjUycXBqd2tzOWRvd2kifQ.Jk_NpNE4gk2e5FScS2dR4g"
              options={{
                style: mapStyleUrl,
                bounds: boundingBox as LngLatBoundsLike,
              }}
              onMapReady={onMapReady}
            >
              {key === 'satellite' ? (
                <>
                  {sources.map((source) => (
                    <Raster
                      key={source.id}
                      id={source.id}
                      options={{
                        ...source,
                      }}
                    />
                  ))}

                  {layers.map((layer) => (
                    <Layer
                      key={layer.id}
                      id={layer.id}
                      options={{
                        ...layer,
                        paint: {
                          ...layer.paint,
                          'raster-fade-duration': 0,
                        },
                      }}
                      // this layer is directly above
                      // the mapbox-satellite source layer
                      before="tunnel-minor-case"
                      visible={mapStyle === layer.source}
                    />
                  ))}
                </>
              ) : null}

              <MapPlugins.ToolEffects key={mapStyle} />
            </MapboxMap>
          </Box>
        );
      }}
    </MapProvider>
  );
};
