import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/naturalIncreases';

export const useNaturalIncreases = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.naturalIncreases);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const useNaturalIncrease = (
  naturalIncreaseId: string,
  propertyId?: string
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.naturalIncreases);

  const proxied = proxyUseEntity({
    entityId: naturalIncreaseId,
    parentId: propertyId,
    dispatch,
    state,
  });

  return proxied;
};
