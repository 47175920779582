import React from 'react';
import { type IntlFormatters } from 'react-intl';

import {
  getLivestockAgeColor,
  toMobDisplayName,
} from '@mobble/models/src/model/Mob';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { formatDate, fromRawDate, RawDate } from '@mobble/shared/src/core/Date';

import { Badge, Text } from '@src/components';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import { Clickable } from '@src/stories/Components/UX/Clickable';

import styles from './naturalIncreasesTableColumns.scss';

export const makeNaturalIncreasesTableColumns = ({
  propertyTypes,
  formatMessage,
}: {
  propertyTypes: ConfiguredPropertyType[];
  formatMessage: IntlFormatters['formatMessage'];
}): TableColumn[] => {
  const handleClick = (event: any) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'date',
      label: formatMessage({
        defaultMessage: 'Date',
        description: 'natural_increases.table.heading.column.date',
      }),
      totals: false,
      toValue: (naturalIncrease) =>
        naturalIncrease.date
          ? fromRawDate(naturalIncrease.date).toDate().getTime()
          : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'head',
      label: formatMessage({
        defaultMessage: 'Head',
        description: 'natural_increases.table.heading.column.head',
      }),
      totals: true,
      toValue: (naturalIncrease) => naturalIncrease.mob.size,
      render: (_, naturalIncrease) => <Text>{naturalIncrease.mob.size}</Text>,
    },
    {
      key: 'type',
      label: formatMessage({
        defaultMessage: 'Type',
        description: 'mobs.table.heading.column.type',
      }),
      totals: false,
      toValue: (naturalIncrease) => naturalIncrease.mob.type,
    },
    {
      key: 'mob',
      label: formatMessage({
        defaultMessage: 'Mob',
        description: 'natural_increases.table.heading.column.mob',
      }),
      totals: false,
      toValue: (naturalIncrease) => toMobDisplayName(naturalIncrease.mob),
      render: (_, naturalIncrease) => {
        return <Text>{toMobDisplayName(naturalIncrease.mob)}</Text>;
      },
    },
    {
      key: 'ages',
      label: formatMessage({
        defaultMessage: 'Ages',
        description: 'mobs.table.heading.column.ages',
      }),
      totals: false,
      toValue: (naturalIncrease) => naturalIncrease.mob.ages.join(', '),
      render: (_, naturalIncrease) => {
        return (
          <ul className={styles.agesList}>
            {naturalIncrease.mob.ages.map((age) => (
              <Box key={age}>
                <Badge
                  textVariant="small"
                  label={String(age)}
                  color={getLivestockAgeColor(
                    propertyTypes,
                    naturalIncrease.mob.type
                  )(age)}
                />
              </Box>
            ))}
          </ul>
        );
      },
    },
    {
      key: 'classes',
      label: formatMessage({
        defaultMessage: 'Classes',
        description: 'mobs.table.heading.column.classes',
      }),
      totals: false,
      toValue: (naturalIncrease) => naturalIncrease.mob.classes.join(', '),
    },
    {
      key: 'paddock',
      label: formatMessage({
        defaultMessage: 'Paddock',
        description: 'natural_increases.table.heading.column.paddock',
      }),
      totals: false,
      toValue: (naturalIncrease) => {
        return naturalIncrease;
      },
      toCsvValue: (naturalIncrease) => naturalIncrease.paddock.name,
      render: (_, naturalIncrease) => (
        <Clickable
          href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
            paddockId: naturalIncrease.paddock.id,
          })}
          onClick={handleClick}
        >
          <Text underline>{String(naturalIncrease.paddock.name)}</Text>
        </Clickable>
      ),
    },
    {
      key: 'notes',
      label: formatMessage({
        defaultMessage: 'Notes',
        description: 'natural_increases.table.heading.column.notes',
      }),
      totals: false,
      toValue: (natural_increase) => natural_increase.notes,
    },
  ];
};
