import { type Point } from '@mobble/models/src/model/MapGeometry';

class Geolocation {
  private static instance: Geolocation;
  private listeners: ((position: Point) => void)[] = [];
  private watchId: null | number = null;
  private lastKnownLocation: Point | null = null;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new Geolocation();
    }
    return this.instance;
  }

  private _watch = async () => {
    if (this.listeners.length === 0 && this.watchId !== null) {
      navigator.geolocation.clearWatch(this.watchId);
    }

    if (this.watchId === null) {
      this.watchId = navigator.geolocation.watchPosition(
        (position) => {
          const coordinates = [
            position.coords.longitude,
            position.coords.latitude,
          ];
          const point: Point = { type: 'Point', coordinates };
          this.lastKnownLocation = point;
          this.listeners.forEach((callback) => callback(point));
        },
        () => null,
        { enableHighAccuracy: true }
      );
    }
  };

  private _getCurrentPosition = async (): Promise<Point | null> => {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coordinates = [
            position.coords.longitude,
            position.coords.latitude,
          ];
          const point: Point = { type: 'Point', coordinates };
          this.lastKnownLocation = point;
          this.listeners.forEach((callback) => callback(point));
          resolve(point);
        },
        () => {
          resolve(this.lastKnownLocation);
        },
        { enableHighAccuracy: true, timeout: 2000 }
      );
    });
  };

  public watch = (callback: (position: Point) => void) => {
    this.listeners.push(callback);
    this._watch();

    return () => {
      this.listeners = this.listeners.filter((l) => l !== callback);
      this._watch();
    };
  };

  public getLastKnownLocation = (): Point | null => {
    return this.lastKnownLocation;
  };

  public getCurrentPosition = async (): Promise<Point | null> => {
    return this._getCurrentPosition();
  };

  public permissionGranted = async (): Promise<boolean> => {
    return true;
  };
}

const geolocation = Geolocation.getInstance();

export default geolocation;
