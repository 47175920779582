import React from 'react';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { type RainGauge } from '@mobble/models/src/model/RainGauge';
import { QuantityOfLength } from '@mobble/shared/src/core/Quantity';

export interface RainGaugeReadingCreateFormProps {
  loading?: boolean;
  rainGauges: RainGauge[];
  rainGaugeId?: string;
  initialValues?: Partial<RainGaugeReadingCreateFormValues>;
  onCancel: () => void;
  onChange: (formValues: RainGaugeReadingCreateFormValues) => void;
  onSubmit: (formValues: RainGaugeReadingCreateFormValues) => void;
}

export interface RainGaugeReadingCreateFormValues {
  rainGauge: string;
  value: QuantityOfLength;
  date: Date;
}

export const RainGaugeReadingCreateForm: React.FC<
  RainGaugeReadingCreateFormProps
> = ({
  rainGauges,
  rainGaugeId,
  initialValues,
  loading,
  onCancel,
  onChange,
  onSubmit,
}) => {
  const form: FormBuilderProps<RainGaugeReadingCreateFormValues> = {
    flex: true,
    i18nRootKey: 'rain-gauges.rain-gauge.reading.create.form',
    fields: [
      {
        name: 'rainGauge',
        type: 'select',
        required: true,
        initialValue: rainGaugeId,
        options: rainGauges.map((rainGauge: RainGauge) => ({
          value: rainGauge.id,
          label: rainGauge.name,
        })),
        show: () => rainGauges.length > 1,
      },
      {
        name: 'value',
        type: 'quantity-length',
        required: true,
        initialValue: initialValues?.value,
      },
      {
        name: 'date',
        type: 'date',
        required: true,
        initialValue: toISO8601(new Date()),
      },
    ],
    loading,
    onSubmit,
    onChange,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
