import * as uuid from 'uuid';
import { initializeApp } from 'firebase/app';
import {
  initializeAuth,
  signInWithCustomToken,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  browserLocalPersistence,
} from 'firebase/auth';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import mobbleService, { type AuthProxy } from '@mobble/service';
import { sleep } from '@mobble/shared/src/core/Promise';
import { getClientVersion } from './interfaces/Version';

const newRelicOptions = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  },
  info: {
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
    sa: 1,
  },
  loader_config: {
    accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
    agentID: '1386139046',
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
    trustKey: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
  },
};

// Initialise NewRelic
const newrelic = new BrowserAgent(newRelicOptions);
newrelic.setApplicationVersion(getClientVersion());

const firebaseConfig = {
  apiKey: 'AIzaSyCjwjUIufAibliTXM9XsM3PbvP1xGtgOdI',
  authDomain: 'mobble-production.firebaseapp.com',
  databaseURL: 'https://mobble-production.firebaseio.com',
  projectId: 'mobble-production',
  storageBucket: 'mobble-production.appspot.com',
  messagingSenderId: '531016214776',
  appId: '1:531016214776:web:be795c3853116e76',
  measurementId: 'G-C6E9REM3ZC',
};

// Initialise Firebase
const app = initializeApp(firebaseConfig);
const fbAuth = initializeAuth(app, {
  persistence: browserLocalPersistence,
});

fbAuth.onAuthStateChanged((user) => {
  newrelic.setUserId(user?.uid || null);

  if (window.vgo) {
    window.vgo('setEmail', user?.email || null);
    window.vgo('process');
  }
});

let cold = true;

const auth: AuthProxy = {
  getCurrentUser: () => {
    return new Promise((resolve, reject) => {
      sleep(cold && !fbAuth.currentUser ? 2000 : 0).then(() => {
        cold = false;
        if (fbAuth.currentUser) {
          return resolve(fbAuth.currentUser);
        }
        return reject(null);
      });
    });
  },
  signInWithCustomToken: (token) => signInWithCustomToken(fbAuth, token),
  createUserWithEmailAndPassword: (email, password) =>
    createUserWithEmailAndPassword(fbAuth, email, password),
  sendPasswordResetEmail: (email) => sendPasswordResetEmail(fbAuth, email),
  onAuthStateChanged: (cb) => {
    return fbAuth.onAuthStateChanged(cb);
  },
  signOut: () => fbAuth.signOut(),
};

const GRAPHQL_API_URI = process.env.REACT_APP_GRAPHQL_API_URI;
const REST_API_URI = process.env.REACT_APP_REST_API_URI;

mobbleService.configure({
  uri: GRAPHQL_API_URI,
  restUri: REST_API_URI,
  getIsOnline: () => {
    return Promise.resolve(true);
  },
  getClientVersion,
  getRequestId: () => Promise.resolve(`${uuid.v4()}`),
  auth,
  onUserAuthenticated: () => null,
});
