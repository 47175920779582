import uniq from 'lodash/uniq';

export const arrayIntersperse = <S, T>(
  separator: S | ((index: number) => S),
  array: T[]
): (T | S)[] =>
  array.reduce(
    (acc, cur, index) =>
      index > 0
        ? [
            ...acc,
            typeof separator === 'function'
              ? (separator as any)(index)
              : separator,
            cur,
          ]
        : [cur],
    [] as (T | S)[]
  );

export const arrayUnique = uniq;

export const insertAt = <T>(index: number, item: T, array: T[]): T[] =>
  index === 0
    ? [item, ...array]
    : [...array.slice(0, index), item, ...array.slice(index)];

export const removeAt = <T>(index: number, array: T[]): T[] => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];

export const updateAt = <T>(index: number, item: T, array: T[]): T[] => {
  const newArray = [...array];
  newArray[index] = item;
  return newArray;
};

export const pickOneRandom = <T>(arr: T[]): T => {
  if (!arr.length) {
    throw new Error('Cannot pick a random item from an empty array');
  }

  return arr[Math.floor(Math.random() * arr.length)];
};
