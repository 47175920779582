export const hexToRGB = (hex: string): [number, number, number] => {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    throw new Error('Invalid hex code');
  }

  const hexCode = hex.substring(1);

  let r: number, g: number, b: number;
  if (hexCode.length === 3) {
    r = parseInt(hexCode[0] + hexCode[0], 16);
    g = parseInt(hexCode[1] + hexCode[1], 16);
    b = parseInt(hexCode[2] + hexCode[2], 16);
  } else {
    r = parseInt(hexCode[0] + hexCode[1], 16);
    g = parseInt(hexCode[2] + hexCode[3], 16);
    b = parseInt(hexCode[4] + hexCode[5], 16);
  }

  return [r, g, b];
};

export const rgbToHex = (input: string) => {
  if (!input.startsWith('rgb')) {
    return input;
  }

  return (
    '#' +
    input
      .slice(4, -1)
      .split(',')
      .map((x) => (+x).toString(16).padStart(2, '0'))
      .join('')
  );
};

export const rgbToLuminance = ([r, g, b]: [
  r: number,
  g: number,
  b: number
]) => {
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance;
};

export const toContrastingTextColor = <A = string>(
  color: string,
  light: A,
  dark: A
): A => {
  if (!color) {
    return dark;
  }

  const rgb = hexToRGB(color);
  const luminance = rgbToLuminance(rgb);

  return luminance > 0.55 ? dark : light;
};

export const toDarkerColor = (color: string, intensity: number): string => {
  const rgb = hexToRGB(color);
  const darkerRgb = rgb.map((x) => Math.max(0, x - intensity));
  return `rgb(${darkerRgb.join(',')})`;
};
