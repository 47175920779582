import { ScenarioType } from '@mobble/models/src/model/Intentions/Scenarios';
import { base64decode } from '@mobble/shared/src/core/String';

import * as ROUTE_NAME from './routeNames';
import { type RouteName } from './types';

export const routeToScenario = (
  routeName: RouteName,
  params?: Record<string, string | number>
): null | ScenarioType => {
  switch (routeName) {
    case ROUTE_NAME.INVENTORY_ITEMS:
      return ScenarioType.InventoryItemsOverview;
    case ROUTE_NAME.MAP_ASSETS_LIST:
      return ScenarioType.MapAssetsOverview;
    case ROUTE_NAME.MAP_OVERVIEW:
      return ScenarioType.MapOverview;
    case ROUTE_NAME.SUMMARY_MOB_ACTIONS_LIST:
      return ScenarioType.MobActionsOverview;
    case ROUTE_NAME.MOB_DETAIL:
      return ScenarioType.MobDetail;
    case ROUTE_NAME.MOBS_LIST:
      return ScenarioType.MobsOverview;
    case ROUTE_NAME.SUMMARY_PADDOCK_ACTIONS_LIST:
      return ScenarioType.PaddockActionsOverview;
    case ROUTE_NAME.PADDOCK_DETAIL:
      return ScenarioType.PaddockDetail;
    case ROUTE_NAME.PADDOCKS_LIST:
      return ScenarioType.PaddocksOverview;
    case ROUTE_NAME.RAIN_GAUGE_DETAIL:
      return ScenarioType.RainGaugeDetail;
    case ROUTE_NAME.RAIN_GAUGES_LIST:
      return ScenarioType.RainGaugesOverview;
    case ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_GROUP: {
      const group = JSON.parse(base64decode(String(params?.classTypes)))?.group
      switch (group) {
        case 'paddockType':
          return ScenarioType.SettingsPropertyPaddockTypes;
        case 'breed':
          return ScenarioType.SettingsPropertyBreeds;
        case 'gender':
          return ScenarioType.SettingsPropertyGenders;
        case 'class':
          return ScenarioType.SettingsPropertyClasses;
        case 'paddockAction':
          return ScenarioType.SettingsPropertyPaddockActions;
        case 'mobAction':
          return ScenarioType.SettingsPropertyMobActions;
        default:
          return null;
      }}
    case ROUTE_NAME.SETTINGS_PROPERTY_USERS:
      return ScenarioType.SettingsPropertyUsersOverview;
    case ROUTE_NAME.TASKS_LIST:
      return ScenarioType.TasksOverview;
    case ROUTE_NAME.INVENTORY_ITEM_DETAIL: 
        return ScenarioType.InventoryItemDetail 
    default:
      return null;
  }
};
