import { type RawDate, fromRawDate } from '@mobble/shared/src/core/Date';
import { type QuantityOfLength } from '@mobble/shared/src/core/Quantity';
import { Property } from './Property';
import { LastRainGaugeReading } from './RainGauge';
import { SortDirection } from './Sort';

export interface RainGaugeReading {
  id: string;
  propertyId: Property['id'];
  rainGaugeId: string;
  createdByID: string | null;
  date: RawDate;
  value: QuantityOfLength;
}

export const sortBydDate =
  (sortDirection: SortDirection) =>
  (a: RainGaugeReading, b: RainGaugeReading): number => {
    const f = (date?: RawDate) => (date ? fromRawDate(date).valueOf() : 0);

    return sortDirection === SortDirection.Ascending
      ? f(a.date) - f(b.date)
      : f(b.date) - f(a.date);
  };

export const toLastReading = (
  readings: RainGaugeReading[]
): LastRainGaugeReading | undefined => {
  const sorted = readings.sort(sortBydDate(SortDirection.Descending));
  const last = sorted[0];
  return last
    ? {
        id: last.id,
        createdByID: last.createdByID,
        date: last.date,
        value: last.value,
      }
    : undefined;
};
