import React, { useEffect } from 'react';
import {
  useMapPluginsStateContextProps,
  useMapPluginsDispatchContext,
} from '../Context';
import { useMap } from '@src/stories/Mapbox/lib/React/Context';
import { ToolbarButton } from '../Shared/ToolbarButtons';

const MAPBOX_TERRAIN_DEM_URL = 'mapbox://mapbox.mapbox-terrain-dem-v1';

export const KEY_ENABLED = 'plugin.pan.enabled';

const Toolbar: React.FC = () => {
  const { updateState } = useMapPluginsDispatchContext();
  const { dict } = useMapPluginsStateContextProps();
  const enabled = Boolean(dict[KEY_ENABLED]);

  const onTogglePitch = () => {
    updateState({ [KEY_ENABLED]: !enabled });
  };

  return (
    <ToolbarButton hint={{ key: 'map.tools.pan.hint' }} onClick={onTogglePitch}>
      {/* <Text bold variant="card-title">
        {enabled ? '2D' : '3D'}
      </Text> */}
      {enabled ? '2D' : '3D'}
    </ToolbarButton>
  );
};

const Effect: React.FC = () => {
  const map = useMap();
  const { dict } = useMapPluginsStateContextProps();
  const enabled = Boolean(dict[KEY_ENABLED]);

  const enable3d = () => {
    disable3d();
    map.addSource('mapbox-dem', {
      type: 'raster-dem',
      url: MAPBOX_TERRAIN_DEM_URL,
      tileSize: 512,
      maxzoom: 14,
    });
    map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });

    map.setFog({
      color: 'rgb(186, 210, 235)',
      'horizon-blend': 0.5,
    });

    /* does not exist in source
    map.addLayer({
      id: 'sky',
      type: 'sky',
      paint: {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [0.0, 0.0],
        'sky-atmosphere-sun-intensity': 15,
      },
    });
    */

    map.easeTo({ pitch: 85 });
  };

  const disable3d = () => {
    try {
      if (map.getStyle() && map.getSource('mapbox-dem')) {
        map.removeSource('mapbox-dem');
      }
      map.setTerrain(null);
      map.setFog(null);
      // map.removeLayer('sky');
    } catch (e) {
      console.log(e);
    }

    map.setPitch(0);
  };

  useEffect(() => {
    if (enabled) {
      enable3d();
    } else {
      disable3d();
    }

    return () => {
      disable3d();
    };
  }, [enabled, map]);

  return null;
};

export default {
  Toolbar,
  Effect,
};
