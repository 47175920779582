import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  useRainGauge,
} from '@mobble/store/src/hooks';

import { useRainGaugeEditView } from '@mobble/shared/src/hooks/useRainGaugeEditView';

import { toPath } from '@src/interfaces/Routing';
import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import {
  RainGaugeEditForm,
} from '@src/stories/Views/RainGauge/RainGaugeEditForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const RainGaugeReadingEditHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { rainGaugeId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const rainGauge = useRainGauge(rainGaugeId);
  const goBack = useNavigateBack();

  const title = rainGauge.entity?.name
    ? formatMessage(
        {
          description: 'screen.title.rain-gauge_edit',
          defaultMessage: 'Edit {RAIN_GAUGE_NAME}',
        },
        {
          RAIN_GAUGE_NAME: rainGauge.entity.name,
        }
      )
    : formatMessage({
        description: 'screen.title.rain-gauge_detail',
        defaultMessage: 'Rain gauge',
      });

  return (
    <ScreenHeader
      modal
      title={title}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.rain-gauge',
            defaultMessage: 'Rain gauge',
          }),
          href: ROUTE_NAME.RAIN_GAUGE_DETAIL_EDIT,
        },
        {
          title: rainGauge?.entity?.name,
          href: toPath(ROUTE_NAME.RAIN_GAUGE_DETAIL, { rainGaugeId }),
        },
      ]}
      onClose={goBack}
    />
  );
};

export const RainGaugeReadingEdit: React.FC<ScreenRendererProps> = (props) => {
  const { rainGaugeId = '' } = props.route.params;
  const linkTo = useLinking();
  const goBack = useNavigateBack();

  const {
    Prelude,
    rainGauge,
    deleteLoading,
    formLoading,
    formError,
    handleDelete,
    handleSubmit,
  } = useRainGaugeEditView({
    rainGaugeId,
    EntitySliceFactoryPrelude,
    onDeleteSuccess: () => linkTo(ROUTE_NAME.RAIN_GAUGES_LIST),
    onSubmitSuccess: () => goBack(),
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <>
      <RainGaugeEditForm
        error={formError}
        loading={formLoading}
        rainGauge={rainGauge.entity}
        onCancel={goBack}
        onSubmit={handleSubmit}
      />
      <DeleteForm
        i18nRootKey="rain-gauges.rain-gauge.delete"
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.RAIN_GAUGE_DETAIL_EDIT,
  header: RainGaugeReadingEditHeader,
  component: RainGaugeReadingEdit,
};
