import React, { useEffect, useState } from 'react';

import { useI18n } from '@mobble/i18n';
import { InventoryItemBatch } from '@mobble/models/src/model/InventoryItemBatch';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useInventory,
  useInventoryItem,
  useInventoryItemBatch,
  useInventoryItemBatches,
  useProperties,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import { InventoryItemBatchInfoTable } from '@src/stories/Views/InventoryItem/InventoryItemBatchInfoTable';
import { InventoryItemBatchStatusForm } from '@src/stories/Views/InventoryItem/InventoryItemBatchStatusForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import {
  ScreenHeader,
  type ScreenHeaderProps,
} from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import { useGetBatchName } from './hooks/useBatchName';

import styles from './inventoryItemBatchDetail.scss';

export const InventoryItemDetailHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const {
    inventoryId = '',
    inventoryItemId = '',
    inventoryItemBatchId = '',
  } = props.route.params;
  const inventory = useInventory(inventoryId);
  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);
  const inventoryItemBatches = useInventoryItemBatches(
    inventoryItemId,
    inventoryItem.entity?.propertyId
  );
  const inventoryItemBatch = useInventoryItemBatch(
    inventoryItemBatchId,
    inventoryItemId,
    inventoryItem.entity?.propertyId
  );

  const { refreshIfExpired } = useEntitiesRefresher([inventoryItemBatches]);

  useEffect(() => {
    refreshIfExpired();
  }, []);

  if (!inventoryItemBatch?.entity) {
    return null;
  }

  const title = useGetBatchName(inventoryItemBatch.entity);

  const quickLinks = inventoryItemBatches.entities
    .map((item) => ({
      title,
      href: toPath(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
        inventoryId,
        inventoryItemId: item.id,
      }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  const breadcrumbs: ScreenHeaderProps['breadcrumbs'] = [
    {
      title: formatMessage({
        description: 'screen.title.inventories',
        defaultMessage: 'Inventories',
      }),
      href: ROUTE_NAME.INVENTORIES_LIST,
    },
  ];

  if (inventory.entity?.name) {
    breadcrumbs.push({
      title: inventory.entity?.name,
      href: toPath(ROUTE_NAME.INVENTORY_ITEMS, {
        inventoryId,
      }),
    });
    breadcrumbs.push({
      title: inventoryItem.entity?.name,
      href: toPath(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
        inventoryId,
        inventoryItemId,
      }),
    });
  }

  return (
    <ScreenHeader
      title={title}
      breadcrumbs={breadcrumbs}
      quickLinks={quickLinks}
      actions={[
        {
          label: formatMessage(
            {
              defaultMessage: 'Edit "{TITLE}"',
              description: 'screen.title.edit_x',
            },
            {
              TITLE: title,
            }
          ),
          icon: 'edit',
          href: toPath(ROUTE_NAME.INVENTORY_ITEM_BATCH_EDIT, {
            inventoryId,
            inventoryItemId,
            inventoryItemBatchId,
          }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const InventoryItemDetail: React.FC<ScreenRendererProps> = (props) => {
  const {
    inventoryId = '',
    inventoryItemId = '',
    inventoryItemBatchId = '',
  } = props.route.params;
  const properties = useProperties();
  const goBack = useNavigateBack();
  const propertyId = properties.selected?.id;
  const [formLoading, setFormLoading] = useState(false);

  const inventory = useInventory(inventoryId, propertyId);
  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);
  const inventoryItemBatch = useInventoryItemBatch(
    inventoryItemBatchId,
    inventoryItemId,
    inventoryItem?.entity?.propertyId
  );

  useEntitiesRefresher([inventory, inventoryItemBatch], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      inventory.prelude,
      inventoryItem.prelude,
      inventoryItemBatch.prelude,
    ],
    required: [inventoryItemBatch.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (updatedInventoryItem: InventoryItemBatch) => {
    setFormLoading(true);

    inventoryItemBatch.update(updatedInventoryItem).then(() => {
      if (updatedInventoryItem.status === 'finished') {
        goBack();
      }
      setFormLoading(false);
    });
  };

  return (
    <>
      <Box className={styles.inventoryItemInfoTabTable}>
        <InventoryItemBatchInfoTable
          inventory={inventory.entity}
          inventoryItem={inventoryItem.entity}
          inventoryItemBatch={inventoryItemBatch.entity}
        />
      </Box>
      <Box className={styles.inventoryItemStatusForm}>
        <InventoryItemBatchStatusForm
          inventoryItemBatch={inventoryItemBatch.entity}
          loading={formLoading}
          onSubmit={handleSubmit}
        />
      </Box>
    </>
  );
};

export default {
  name: ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL,
  header: InventoryItemDetailHeader,
  component: InventoryItemDetail,
};
