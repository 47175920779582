import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import classNames from 'classnames/bind';
import throttle from 'lodash/throttle';

import { useI18n } from '@mobble/i18n';
import { Color } from '@mobble/theme';

import { Spinner } from '@src/stories/Components/UI/Spinner';

import styles from './List.scss';
const cx = classNames.bind(styles);

export interface ListProps<Item = any>
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onClick'> {
  /**
   * An array of items or sectioned items
   */
  items: SectionedItems<Item>[] | Item[];

  /**
   * Render function for each item
   */
  renderItem?: (
    item: Item,
    index: number,
    section?: SectionedItems<Item>
  ) => React.ReactNode;

  /**
   * Render function for a custom section header
   */
  renderSectionHeader?: (section: SectionedItems<Item>) => React.ReactNode;

  /**
   * Custom empty state component
   */
  empty?: React.ReactNode;

  /**
   * Custom header component
   */
  header?: React.ReactNode;

  /**
   * Custom footer component
   */
  footer?: React.ReactNode;

  /**
   * Renders a loading indicator at the bottom of the list
   */
  loading?: boolean;

  /**
   * Adjust the `next` trigger threshold in `px`, defaults to 0
   */
  threshold?: number;

  /**
   * Triggered when the bottom of the list is reached
   */
  next?: () => void;

  /**
   * Return a unique key for each item
   */
  keyExtractor?: (item: Item, index: number) => string;
}

export interface SectionedItems<Item> {
  title: string;
  subtitle?: string;
  data: Item[];
}

const DEFAULT_THRESHOLD = 0;

/**
 * List is a component that displays a list of items or sectioned items.
 */
const List = forwardRef<HTMLDivElement, ListProps>(
  (
    {
      items,
      renderItem = (item: any) => <span key={item.id}>{item.name}</span>,
      renderSectionHeader: renderSectionHeaderProp,
      empty,
      header,
      footer,
      loading,
      threshold = DEFAULT_THRESHOLD,
      next,
      keyExtractor = (_: never, index: number) => index.toString(),
      className,
      ...others
    },
    ref
  ) => {
    const { formatMessage } = useI18n();
    const elRef = useRef<HTMLDivElement | null>(null);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const rootClasses = cx(
      {
        List: true,
      },
      className
    );

    const hasNext = typeof next === 'function';

    // Expose elRef to parent component
    useImperativeHandle(ref, () => elRef?.current as HTMLDivElement);

    const observeLastItem = useCallback(() => {
      if (!elRef.current || !next) {
        return;
      }

      observerRef.current = new IntersectionObserver(
        throttle((entries: IntersectionObserverEntry[]) => {
          if (entries[0].isIntersecting) {
            next();
          }
        }, 100),
        {
          root: null,
          rootMargin: `${threshold}px`,
          threshold: 1.0,
        }
      );

      observerRef.current.observe(elRef.current);
    }, [next, threshold]);

    const stopObservingLastItem = useCallback(() => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    }, []);

    useEffect(() => {
      if (next) {
        observeLastItem();
      }

      return () => {
        stopObservingLastItem();
      };
    }, [next, observeLastItem, stopObservingLastItem]);

    const renderItems = () => {
      const renderPlainItems = (items: any[], section?: SectionedItems<any>) =>
        items.map((item, index) => (
          <li key={keyExtractor(item, index)}>
            {renderItem(item, index, section)}
          </li>
        ));

      const renderSectionHeader = (section: SectionedItems<any>) => {
        if (renderSectionHeaderProp) {
          return renderSectionHeaderProp(section);
        }

        return (
          <header>
            <h1>{section.title}</h1>
            {section.subtitle ? <h2>{section.subtitle}</h2> : null}
          </header>
        );
      };

      const renderSection = (section: SectionedItems<any>, index: number) => {
        return (
          <section key={String(index)}>
            {renderSectionHeader(section)}
            <ul>{renderPlainItems(section.data, section)}</ul>
          </section>
        );
      };

      if (items.length === 0 && empty) {
        return empty;
      }

      if (items[0] && (items[0] as any)?.data) {
        return (items as SectionedItems<any>[]).map(renderSection);
      }
      return <ul>{renderPlainItems(items as any[])}</ul>;
    };

    const renderLoading = () => {
      if (!loading) {
        return null;
      }

      return (
        <div className={styles.loadingContainer}>
          <Spinner
            aria-label={formatMessage({
              description: 'Default List loading message',
              defaultMessage: 'Loading...',
            })}
            color={Color.BodyText}
          />
        </div>
      );
    };

    return (
      <div className={rootClasses} {...others}>
        {header ? <header>{header}</header> : null}
        {renderItems()}
        {renderLoading()}
        {footer ? <footer>{footer}</footer> : null}
        {hasNext ? (
          <div
            ref={elRef}
            data-testid="list-end-ref"
            role="presentation"
            className={styles.observable}
          />
        ) : null}
      </div>
    );
  }
);

List.displayName = 'List';

export default List;
