import { type PaddockGroupGeometry } from '@mobble/models/src/model/PaddockGroupGeometry';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';

export interface PaddockGroupGeometryState {
  extStatus: ExtStatusPerActionPerParent;
  entities: PaddockGroupGeometry[];
  filter: {};
  sort: {};
}

export const defaultState: PaddockGroupGeometryState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<PaddockGroupGeometryState>({
  name: 'paddockGroupGeometry',
  initialState: defaultState,
  toParentId: ({ propertyId }: PaddockGroupGeometry) => propertyId,
  onGetAll: mobbleService.api.paddockGroupGeometries.get,
  onCreate: () => Promise.reject(),
  onUpdate: () => Promise.reject(),
  onDelete: () => Promise.reject(),
  afterMutation: async ({ entity }, dispatch) => {
    dispatch(thunkGetAll({ parentId: entity.propertyId }));
  },
});

export const { thunkGetAll, proxyUseEntities, proxyUseEntity } = entitySlice;
export const { flush } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
