import React from 'react';
import { Color } from '@mobble/colors';
import { Box } from '@src/stories/Components/Layout/Box';
import { type I18nItem } from '@mobble/i18n';
import { formatNumber, roundNumber } from '@mobble/shared/src/core/Number';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import styles from './infoTile.scss';

interface BaseInfoTileProps {
  id: string;
  label: string;
}

interface InfoValueTileProps extends BaseInfoTileProps {
  value: string | number;
  previousValue?: number;
}

interface InfoListTilesProps extends BaseInfoTileProps {
  values: {
    label: string;
    value: string | number;
    previousValue?: number;
  }[];
}

export type InfoTileProps = InfoValueTileProps | InfoListTilesProps;

const InfoTile: React.FC<InfoValueTileProps> = ({
  label,
  value,
  previousValue,
}) => {
  const difference = (() => {
    if (!previousValue || previousValue === value) {
      return null;
    }

    const diff = Number(value) - previousValue;
    const percentage = roundNumber(Math.abs(diff / previousValue) * 100);

    if (diff < 0) {
      return (
        <Box className={[styles.tileDifference, styles.tileDifferenceDown]}>
          <Icon name="arrow-down" />
          <span>{percentage}%</span>
        </Box>
      );
    }
    return (
      <Box className={[styles.tileDifference, styles.tileDifferenceUp]}>
        <Icon name="arrow-up" />
        <span>{percentage}%</span>
      </Box>
    );
  })();

  return (
    <Box className={styles.tile}>
      <Text className={styles.tileHeading} i18n={label} />
      <Box className={styles.tileValue}>
        {typeof value === 'number' ? formatNumber(value) : value}
      </Box>
      {difference}
    </Box>
  );
};

const InfoTileValues: React.FC<InfoListTilesProps> = ({ label, values }) => {
  return (
    <Box className={styles.tile}>
      <Text className={styles.tileHeading} i18n={label} />
      <div>
        {values.map(({ value, previousValue, label }, index) => {
          const difference = (() => {
            if (!previousValue || previousValue === value) {
              return null;
            }

            const diff = Number(value) - previousValue;
            const percentage = roundNumber(
              Math.abs(diff / previousValue) * 100
            );

            if (diff < 0) {
              return (
                <Box
                  className={[
                    styles.labelDifference,
                    styles.labelDifferenceDown,
                  ]}
                >
                  (
                  <Icon name="arrow-down" />
                  <span>{percentage}%</span>)
                </Box>
              );
            }
            return (
              <Box
                className={[styles.labelDifference, styles.labelDifferenceUp]}
              >
                (
                <Icon name="arrow-up" />
                <span>{percentage}%</span>)
              </Box>
            );
          })();
          return (
            <div key={index} className={styles.tileListValue}>
              {label ? (
                <>
                  {label}
                  <span>:</span>
                </>
              ) : null}

              {typeof value === 'number' ? formatNumber(value) : value}

              {difference}
            </div>
          );
        })}
      </div>
    </Box>
  );
};

interface InfoTilesProps {
  items: InfoTileProps[];
}

export const InfoTiles: React.FC<InfoTilesProps> = ({ items }) => {
  return (
    <Box className={styles.tilesContainer}>
      {items.map((props) =>
        'value' in props ? (
          <InfoTile key={props.id} {...props} />
        ) : (
          <InfoTileValues key={props.id} {...props} />
        )
      )}
    </Box>
  );
};
