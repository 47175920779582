import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { useI18n } from '@mobble/i18n';
import { type Property } from '@mobble/models/src/model/Property';
import { UserRole } from '@mobble/models/src/model/User';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { Divider } from '@src/stories/Components/Layout/Divider';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  SettingsPropertyInformationForm,
  type SettingsPropertyInformationFormValues,
} from '@src/stories/Views/Settings/SettingsPropertyInformationForm';

export const SettingsPropertyInformationHeader: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const navigate = useNavigate();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Property information',
        description: 'screen.title.settings_property_information',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={() => {
        navigate(ROUTE_NAME.SETTINGS_LIST);
      }}
    />
  );
};

export const SettingsPropertyInformation: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const user = useGetUser();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const isOwner = user.role === UserRole.Owner;
  const onlyProperty = properties.entities.length === 1;
  const canDelete = isOwner && !onlyProperty;

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleCancel = () => {
    linkTo(ROUTE_NAME.SETTINGS_LIST);
  };

  const handleSubmit = (formValues: SettingsPropertyInformationFormValues) => {
    setLoading(true);
    properties
      .update({
        id: propertyId,
        name: formValues.name,
        pic: formValues.pic,
      })
      .then(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setDeleteLoading(true);

    properties
      .remove({ id: propertyId })
      .then(() => {
        const remainingProperties = properties.entities.filter(
          (p) => p.id !== propertyId
        );

        if (remainingProperties.length) {
          properties.selectProperty(remainingProperties[0].id);
        }

        linkTo(ROUTE_NAME.DASHBOARD);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <SettingsPropertyInformationForm
        property={properties.selected as Property}
        loading={loading}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />

      <DeleteForm
        i18nRootKey="properties.delete"
        disabled={!canDelete}
        loading={deleteLoading}
        onDelete={handleDelete}
      />

      {!canDelete && (
        <>
          <Spacer y={2} />
          <p>
            {!isOwner
              ? formatMessage({
                  defaultMessage: 'Only owners can delete a property.',
                  description: 'Delete Property unauthorised message',
                })
              : formatMessage({
                  defaultMessage:
                    'Unable to delete only property. Please create a new property first.',
                  description: 'Delete Property only property message',
                })}
          </p>
        </>
      )}
    </>
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_PROPERTY_INFORMATION,
  header: SettingsPropertyInformationHeader,
  component: SettingsPropertyInformation,
};
