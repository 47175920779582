import React from 'react';
import { useTitle } from '@src/hooks/useTitle';
import { useAuthentication } from '@mobble/shared/src/hooks/useAuthentication';
import { Text } from '@src/stories/Components/UI/Text';
import { VStack } from '@src/stories/Components/Layout/Stack';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { Logo } from '@src/stories/Components/UI/Logo';
import { Box } from '@src/stories/Components/Layout/Box';
import { Button } from '@src/stories/Components/UX/Button';
import styles from './NoPropertyAccessScreen.scss';

export const NoPropertyAccessScreen = () => {
  const { signOut } = useAuthentication();
  useTitle({
    key: 'no_property_access.screen.title',
  });

  return (
    <Box className={styles.noPropertyAccessScreen} flex>
      <VStack flex>
        <VStack alignment="center" className={styles.logoWrapper}>
          <VStack alignment="center">
            <Logo />
            <Spacer y={8} />
            <Text
              variant="larger"
              tagName="h1"
              bold
              align="center"
              i18n={{ key: 'no_property_access.screen.title' }}
            />
            <Spacer y={3} />
            <Box style={{ maxWidth: 400 }} spacing={3}>
              <VStack alignment="center">
                <Text
                  variant="small"
                  align="center"
                  i18n={{ key: 'no_property_access.screen.description' }}
                />
                <Spacer y={3} />
                <Button
                  label={{ key: 'settings.sign_out.button.label' }}
                  onClick={signOut}
                />
              </VStack>
            </Box>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};
