import React from 'react';
import { useI18n } from '@mobble/i18n';
import { usePaddockEditView } from '@mobble/shared/src/hooks/usePaddockEditView';
import { usePaddock } from '@mobble/store/src/hooks/paddocks';

import { useDialog } from '@src/hooks/useDialog';
import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';

import { Text } from '@src/stories/Components/UI/Text';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { PaddockEditForm } from '@src/stories/Views/Paddock/PaddockEditForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const PaddockEditHeader: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const paddock = usePaddock(paddockId);
  const goBack = useNavigateBack();
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage(
        {
          defaultMessage: 'Edit "{PADDOCK_NAME}"',
          description: 'screen.title.paddock_edit',
        },
        {
          PADDOCK_NAME: paddock.entity?.name,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Paddocks',
            description: 'screen.title.paddocks',
          }),
          href: ROUTE_NAME.PADDOCKS_LIST,
        },
        {
          title: paddock?.entity?.name,
          href: toPath(ROUTE_NAME.PADDOCK_DETAIL, { paddockId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const PaddockEdit: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const { alert, close } = useDialog();
  const goBack = useNavigateBack();
  const linkTo = useLinking();
  const {
    Prelude,
    paddock,
    paddockGeometry,
    paddockTypes,
    formLoading,
    deleteLoading,
    formError,
    paddockHasMobs,
    paddockHasOpenGates,
    handleCreateCustomField,
    handleDelete,
    handleSubmit,
  } = usePaddockEditView({
    paddockId: paddockId,
    EntitySliceFactoryPrelude,
    onSubmitSuccess: () => goBack(),
  });

  if (Prelude) {
    return Prelude;
  }

  const handleChange = (values, _, setValues, fieldName) => {
    if (fieldName === 'points') {
      const { total_area, grazeable_area } = values;

      const title = formatMessage({
        description: 'paddocks.paddock.edit.form.total_area.changed.title',
        defaultMessage: 'Paddock boundary changed',
      });

      const message = formatMessage(
        {
          description: 'paddocks.paddock.edit.form.total_area.changed.prompt',
          defaultMessage:
            'The paddock boundary has changed. Would you like to update the grazeable area ({GRAZEABLE_AREA}) to match the total area ({TOTAL_AREA})?',
        },
        {
          GRAZEABLE_AREA: `${grazeable_area.value} ${grazeable_area.unit}`,
          TOTAL_AREA: `${total_area.value} ${total_area.unit}`,
        }
      );

      const closeLabel = formatMessage({
        description:
          'paddocks.paddock.edit.form.total_area.changed.close.button.label',
        defaultMessage: 'Close',
      });

      const updateLabel = formatMessage({
        description:
          'paddocks.paddock.edit.form.total_area.changed.update.button.label',
        defaultMessage: 'Update Grazeable Area',
      });

      alert(title, message, [
        {
          label: closeLabel,
          intent: 'secondary',
          outline: true,
          onClick: () => {
            close();
          },
        },
        {
          label: updateLabel,
          type: 'submit',
          onClick: () => {
            setValues({ ...values, grazeable_area: total_area });
            close();
          },
        },
      ]);
    }

    return values;
  };

  const cantDeleteReasonLabel = paddockHasMobs
    ? formatMessage({
        description: 'paddocks.paddock.delete.form.can_not_delete.mobs',
        defaultMessage: "You can't delete a paddock that contains mobs.",
      })
    : paddockHasOpenGates
    ? formatMessage({
        description: 'paddocks.paddock.delete.form.can_not_delete.open_gates',
        defaultMessage:
          "You can't delete a paddock that is part of a paddock group.",
      })
    : '';

  const handleDeleteRequest = () => {
    linkTo(ROUTE_NAME.PADDOCKS_LIST);
    handleDelete();
  };

  return (
    <>
      <PaddockEditForm
        paddock={paddock.entity}
        paddockGeometry={paddockGeometry.entity || undefined}
        paddockTypes={paddockTypes}
        error={formError}
        loading={formLoading}
        onChange={handleChange}
        onCancel={goBack}
        onSubmit={handleSubmit}
        onCreateCustomField={handleCreateCustomField}
      />
      <DeleteForm
        i18nRootKey="paddocks.paddock.delete"
        disabled={paddockHasMobs || paddockHasOpenGates}
        loading={deleteLoading}
        onDelete={handleDeleteRequest}
      >
        {cantDeleteReasonLabel ? (
          <Text tagName="p" i18n={cantDeleteReasonLabel} />
        ) : null}
      </DeleteForm>
    </>
  );
};

export default {
  name: ROUTE_NAME.PADDOCK_DETAIL_EDIT,
  header: PaddockEditHeader,
  component: PaddockEdit,
};
