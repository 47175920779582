import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import classNames from 'classnames/bind';

import Button from '../Button';
import { type LabelLike } from '../ListSelect';

import styles from './Radio.scss';

const cx = classNames.bind(styles);

type TPickedProps =
  | 'aria-label'
  | 'autoFocus'
  | 'checked'
  | 'className'
  | 'disabled'
  | 'name'
  | 'onBlur'
  | 'onChange'
  | 'onFocus'
  | 'onKeyDown'
  | 'onKeyUp'
  | 'required'
  | 'style'
  | 'tabIndex'
  | 'value';

export interface RadioProps
  extends Pick<ComponentPropsWithoutRef<'input'>, TPickedProps> {
  /**
   * Used to bind input and label
   */
  id: string;

  /**
   * 	Radio label -- alternative set `aria-label`
   */
  label?: LabelLike;

  /**
   * Display a radio input as a button
   */
  button?: boolean;

  /**
   * Show error state
   */
  error?: boolean;

  /**
   * Size of the radio, defaults to medium
   */
  size?: 'small' | 'medium';
}

/**
 * Radio is a component that allows users to select one option from a set.
 */
const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    { id, label, button, error, size = 'medium', className, style, ...others },
    ref
  ) => {
    const inputEl = useRef<HTMLInputElement>(null);
    // Expose input ref to parent component
    useImperativeHandle(ref, () => inputEl?.current as HTMLInputElement);

    const rootClasses = cx(
      {
        Radio: true,
        error,
        button,
        [size]: true,
      },
      className
    );

    /**
     * Warning messages
     */
    const ariaLabel = others['aria-label'];
    useEffect(() => {
      if (!id) {
        console.warn('Radio: "id" property is missing. Please provide one.');
      }

      if (!label && !ariaLabel) {
        console.warn(
          'Radio: neither "label" nor "aria-label" property is provided. Please provide one.'
        );
      }
    }, [id, label, ariaLabel]);

    const labelElem =
      typeof label === 'string' ? (
        <span className={styles.labelText}>{label}</span>
      ) : (
        label
      );

    const handleButtonClick = () => {
      if (inputEl.current) {
        inputEl.current.click();
      }
    };

    return (
      <div className={rootClasses} style={style}>
        <label>
          <input
            id={id}
            type="radio"
            ref={inputEl}
            aria-invalid={error}
            {...others}
          />

          {button ? (
            <Button
              id={`${id}-button`}
              size={size}
              outline={!others.checked}
              intent="secondary"
              onClick={handleButtonClick}
            >
              {label}
            </Button>
          ) : (
            labelElem
          )}
        </label>
      </div>
    );
  }
);

Radio.displayName = 'Radio';

export default Radio;
