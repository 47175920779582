import isEqual from 'lodash/isEqual';
import { uniqueId } from '@mobble/shared/src/core/UniqueId';

import { type Property } from './Property';

const THUNK_TYPES = ['pending', 'fulfilled', 'rejected'];

type QueueActionStatus = (typeof THUNK_TYPES)[number] | 'queued';

type QueueActionType = string;

export interface QueueAction {
  id: string;
  timestamp: number;
  propertyId: Property['id'];
  error?: string;
  status?: QueueActionStatus;

  action: {
    type: QueueActionType;
    args?: any;
  };
}

export const queueActionIsSimilar =
  (a: QueueAction) =>
  (b: QueueAction): boolean =>
    a.action.type === b.action.type &&
    a.propertyId === b.propertyId &&
    isEqual(a.action.args, b.action.args);

export const queueActionIsNotSimilar =
  (a: QueueAction) =>
  (b: QueueAction): boolean =>
    !queueActionIsSimilar(a)(b);

export const createQueueAction = (
  actionType: QueueActionType,
  propertyId: Property['id'],
  args: any
): QueueAction =>
  ({
    id: uniqueId(),
    timestamp: new Date().getTime(),
    propertyId,
    status: 'queued',
    action: {
      type: actionType,
      args,
    },
  } as QueueAction);

export const getActionTypeWithoutSuffix = (type: string): string =>
  type.replace(new RegExp(`\/${type.split('/').pop()}$`), '');
