import React, { useState } from 'react';
import { useI18n } from '@mobble/i18n';
import { QuantityOfLength, Type } from '@mobble/shared/src/core/Quantity';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useGetUser,
  useProperties,
  useRainGauges,
  useRainGaugeReadings,
  useSetting,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';

import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  RainGaugeReadingCreateForm,
  RainGaugeReadingCreateFormValues,
} from '@src/stories/Views/RainGauge/RainGaugeReadingCreateForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';
import { Quantity } from '@mobble/shared/src';

export const RainGaugeReadingCreateHeader: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      modal
      title={formatMessage({
        description: 'root.modal.rain-gauge.reading_create',
        defaultMessage: 'Record a Reading',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.rain-gauges',
            defaultMessage: 'Rain gauges',
          }),
          href: ROUTE_NAME.RAIN_GAUGES_LIST,
        },
      ]}
      onClose={goBack}
    />
  );
};

export const RainGaugeReadingCreate: React.FC<ScreenRendererProps> = (
  props
) => {
  const { rainGaugeId: defaultRainGaugeId = '' } = props.route.params;
  const goBack = useNavigateBack();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const rainGauges = useRainGauges(propertyId);
  const lengthUnit = useSetting('lengthUnit') as Quantity.LengthUnits;

  const user = useGetUser();
  const [rainGaugeId, setRainGaugeId] = useState<string | null>(
    defaultRainGaugeId
  );

  const rainGaugeReadings = useRainGaugeReadings(propertyId, rainGaugeId);
  const [formLoading, setFormLoading] = useState(false);

  useEntitiesRefresher([rainGaugeReadings, rainGauges], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, rainGauges.prelude],
    required: [properties.selected, user],
  });

  if (Prelude) {
    return Prelude;
  }

  const initialValues: {
    value: QuantityOfLength;
  } = {
    value: {
      type: Type.Length,
      value: 0,
      unit: lengthUnit,
    },
  };

  const handleChange = (formValues: RainGaugeReadingCreateFormValues) => {
    setRainGaugeId(formValues.rainGauge);
  };

  const handleSubmit = (formValues: RainGaugeReadingCreateFormValues) => {
    if (!properties.selected) {
      return;
    }
    setFormLoading(true);

    rainGaugeReadings
      .create({
        propertyId: properties.selected.id,
        rainGaugeId: rainGaugeId ?? formValues.rainGauge,
        createdByID: String(user?.id),
        date: formValues.date,
        value: formValues.value,
      })
      .then(() => {
        setFormLoading(false);
        goBack();
      })
      .catch((err) => {
        console.log(err);
        setFormLoading(false);
      });
  };

  return (
    <RainGaugeReadingCreateForm
      rainGauges={rainGauges.entities}
      rainGaugeId={rainGaugeId}
      initialValues={initialValues}
      loading={formLoading}
      onCancel={goBack}
      onSubmit={handleSubmit}
      onChange={handleChange}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_RAIN_GAUGE_READING_CREATE,
  header: RainGaugeReadingCreateHeader,
  component: RainGaugeReadingCreate,
  modal: true,
};
