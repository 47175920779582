import { type TaskGeometry } from '@mobble/models/src/model/TaskGeometry';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';

export interface TaskGeometryState {
  extStatus: ExtStatusPerActionPerParent;
  entities: TaskGeometry[];
  filter: {};
  sort: {};
}

export const defaultState: TaskGeometryState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<TaskGeometryState>({
  name: 'taskGeometries',
  initialState: defaultState,
  toParentId: ({ propertyId }: TaskGeometry) => propertyId,
  onGetAll: mobbleService.api.taskGeometries.get,
  onCreate: () => Promise.reject(),
  onUpdate: () => Promise.reject(),
  onDelete: () => Promise.reject(),
});

export const { thunkGetAll, proxyUseEntities, proxyUseEntity } = entitySlice;
export const { flush } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
