import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities, proxyUseEntity } from '../reducers/paddockActions';

export const usePaddockActions = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddockActions);

  return proxyUseEntities({
    parentId: propertyId,
    dispatch,
    state,
  });
};

export const usePaddockAction = (
  paddockActionId: string,
  propertyId?: string
) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.paddockActions);

  return proxyUseEntity({
    entityId: paddockActionId,
    parentId: propertyId,
    dispatch,
    state,
  });
};
