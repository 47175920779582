import React from 'react';

import { Color, colorToHex } from '@mobble/colors';
import { type I18nItem } from '@mobble/i18n';

import { Icon, type IconName } from '../UI/Icon';
import { Spinner } from '../UI/Spinner';
import { Text } from '../UI/Text';

import { Box } from './Box';
import { Spacer } from './Spacer';
import { HStack } from './Stack';

import styles from './card.scss';

export type CardProps = {
  color?: string | Color;
  icon?: IconName;
  elevation?: 'none' | 'low' | 'high';
  title?: I18nItem | string | (() => React.ReactNode);
  loading?: boolean;
  topRight?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
};

export const Card: React.FC<CardProps> = ({
  color,
  elevation = 'high',
  title,
  icon,
  children,
  loading,
  topRight,
  className,
}) => {
  const classNames = [styles.card, styles[`elevation-${elevation}`], className]
    .filter(Boolean)
    .join(' ');

  return (
    <Box className={classNames}>
      <Box style={{ backgroundColor: color ? colorToHex(color) : undefined }}>
        <Box className={styles.cardInner}>
          {title ? (
            <Box spacing={{ top: 2, right: 2, bottom: 0, left: 2 }}>
              <HStack>
                {icon ? (
                  <>
                    <Icon name={icon} />
                    <Spacer x={1} />
                  </>
                ) : null}
                {loading ? (
                  <>
                    <Spinner color={Color.Black} />
                    <Spacer x={1} />
                  </>
                ) : null}

                {typeof title === 'function' ? (
                  title()
                ) : typeof title === 'string' ? (
                  <Text variant="card-title">{title}</Text>
                ) : (
                  <Text variant="card-title" i18n={title} />
                )}

                {topRight ? (
                  <>
                    <Spacer flex />
                    {topRight}
                  </>
                ) : null}
              </HStack>
            </Box>
          ) : (
            <></>
          )}
          <Box spacing={{ top: title ? 1 : 2, right: 2, bottom: 2, left: 2 }}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
