import { InventoryItemStatus } from '@mobble/models/src/model/InventoryItem';
import {
  InventoryItemBatchChemical,
  InventoryItemBatchFeed,
  type InventoryItemBatch,
} from '@mobble/models/src/model/InventoryItemBatch';
import { makeQuantityOfMassVolume } from '@mobble/shared/src/core/Quantity';
import { type ApiPrelude } from '../types';
import { InventoryCategory } from '@mobble/models';

const decodeInventoryItemBatchFeeds =
  (propertyId: string) =>
  (data: any[]): InventoryItemBatchFeed[] =>
    data.map(decodeInventoryItemBatchFeed(propertyId));

const decodeInventoryItemBatchFeed =
  (propertyId: string) =>
  (raw: any): InventoryItemBatchFeed => ({
    id: raw.id,
    inventoryItemId: raw.feedID,
    propertyId,

    quantity: makeQuantityOfMassVolume(
      raw?.quantity?.unit,
      raw?.quantity?.value
    ),

    created: raw.created,
    dateReceived: raw.dateReceived,
    status:
      raw.status === InventoryItemStatus.Finished
        ? InventoryItemStatus.Finished
        : InventoryItemStatus.Active,
    notes: raw.notes || null,

    category: InventoryCategory.Feed,
    supplierName: raw.supplierName,
    pricePerUnitCents: raw.pricePerUnitCents,
  });

const decodeInventoryItemBatchChemicals =
  (propertyId: string) =>
  (data: any[]): InventoryItemBatchChemical[] =>
    data.map(decodeInventoryItemBatchChemical(propertyId));

const decodeInventoryItemBatchChemical =
  (propertyId: string) =>
  (raw: any): InventoryItemBatchChemical => ({
    id: raw.id,
    inventoryItemId: raw.chemicalID,
    propertyId,

    quantity: makeQuantityOfMassVolume(
      raw?.quantity?.unit,
      raw?.quantity?.value
    ),

    created: raw.created,
    dateReceived: raw.dateReceived,
    status:
      raw.status === InventoryItemStatus.Finished
        ? InventoryItemStatus.Finished
        : InventoryItemStatus.Active,
    notes: raw.notes || null,

    category: InventoryCategory.Chemicals,
    name: raw.manufacturerBatchID,
    dateOfManufacture: raw.dateOfManufacture || null,
    dateExpiry: raw.dateExpiry || null,
  });

// Get

const GET_AGGREGATE_CHEMICAL_ITEM_BATCHES = `
  query GetAggregateChemicalBatches($propertyId: ID!) {
    allChemicalBatches(
    propertyId: $propertyId
    page_size: 1000
  ) {
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    nodes {
      id
      chemicalID
      status
      manufacturerBatchID
      dateExpiry
      dateReceived
      dateOfManufacture
      quantity {
        unit
        value
      }
      notes
    }
  }
}
`;

const GET_AGGREGATE_FEED_ITEM_BATCHES = `
  query GetAggregateFeedBatches($propertyId: ID!) {
    allFeedBatches(
    propertyId: $propertyId
    page_size: 1000
  ) {
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    nodes {
      id
      feedID
      propertyId
      supplierName
      quantity {
        unit
        value
      }
      created
      dateReceived
      status
      pricePerUnitCents
      notes
    }
  }
}
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{
    entities: InventoryItemBatch[];
  }> => {
    const feedResponses = await prelude.graphql({
      query: GET_AGGREGATE_FEED_ITEM_BATCHES,
      variables: {
        propertyId: parentId,
      },
    });

    const feedResult = decodeInventoryItemBatchFeeds(parentId)(
      feedResponses.data.allFeedBatches.nodes
    );

    const chemicalResponses = await prelude.graphql({
      query: GET_AGGREGATE_CHEMICAL_ITEM_BATCHES,
      variables: {
        propertyId: parentId,
      },
    });

    const chemicalResult = decodeInventoryItemBatchChemicals(parentId)(
      chemicalResponses.data.allChemicalBatches.nodes
    );

    return {
      entities: [...feedResult, ...chemicalResult],
    };
  };

// Create

const CREATE_CHEMICAL_ITEM_BATCH = `
    mutation AddChemicalBatch($input: AddChemicalBatchInput!) {
      addChemicalBatch(input: $input) {
        id
        propertyId
        manufacturerBatchID
        quantity {
          unit
          value
        }
        created
        dateOfManufacture
        dateExpiry
        dateReceived
        status
        notes
      }
    }
  `;

const CREATE_FEED_ITEM_BATCH = `
    mutation AddFeedBatch($input: AddFeedBatchInput!) {
      addFeedBatch(input: $input) {
        id
        feedID
        propertyId
        supplierName
        quantity {
          unit
          value
        }
        created
        dateReceived
        status
        pricePerUnitCents
        notes
      }
    }
  `;

interface CreateChemicalItemBatchInput {
  propertyId: string;
  chemicalId: string;
  manufacturerBatchID: string;
  quantity: {
    unit: string;
    value: number;
  };
  dateReceived: string;
  dateOfManufacture: string;
  dateExpiry: string;
  notes: string;
}

interface CreateFeedItemBatchInput {
  propertyId: string;
  feedId: string;
  quantity: {
    unit: string;
    value: number;
  };
  supplierName: string;
  pricePerUnitCents: number;
  dateReceived: string;
  notes: string;
}

const apiCreateInventoryItemFeedBatch =
  (prelude: ApiPrelude) =>
  async ({
    entity,
  }: {
    entity: Omit<InventoryItemBatchFeed, 'id'>;
  }): Promise<InventoryItemBatchFeed> => {
    const input: CreateFeedItemBatchInput = {
      propertyId: entity.propertyId,
      feedId: entity.inventoryItemId,
      quantity: {
        unit: entity.quantity.unit,
        value: entity.quantity.value,
      },
      supplierName: entity.supplierName,
      pricePerUnitCents: entity.pricePerUnitCents,
      dateReceived: entity.dateReceived,
      notes: entity.notes,
    };

    const response = await prelude.graphql({
      query: CREATE_FEED_ITEM_BATCH,
      variables: {
        input,
      },
    });

    const result = {
      ...response.data.addFeedBatch,
      feedID: entity.inventoryItemId,
    };

    const inventoryItemBatch = decodeInventoryItemBatchFeed(entity.propertyId)(
      result
    );

    return inventoryItemBatch;
  };

const apiCreateInventoryItemChemicalBatch =
  (prelude: ApiPrelude) =>
  async ({
    entity,
  }: {
    entity: Omit<InventoryItemBatchChemical, 'id'>;
  }): Promise<InventoryItemBatchChemical> => {
    const input: CreateChemicalItemBatchInput = {
      propertyId: entity.propertyId,
      chemicalId: entity.inventoryItemId,
      manufacturerBatchID: entity.name,
      quantity: {
        unit: entity.quantity.unit,
        value: entity.quantity.value,
      },
      dateReceived: entity.dateReceived,
      dateOfManufacture: entity.dateOfManufacture,
      dateExpiry: entity.dateExpiry,
      notes: entity.notes,
    };

    const entities = await prelude.graphql({
      query: CREATE_CHEMICAL_ITEM_BATCH,
      variables: {
        input,
      },
    });

    const result = {
      ...entities.data.addChemicalBatch,
      chemicalID: entity.inventoryItemId,
    };

    const inventoryItemBatch = decodeInventoryItemBatchChemical(
      entity.propertyId
    )(result);

    return inventoryItemBatch;
  };

export const create =
  (prelude: ApiPrelude) =>
  async ({
    entity,
  }: {
    entity: Omit<InventoryItemBatch, 'id'>;
  }): Promise<InventoryItemBatch> => {
    switch (entity.category) {
      case InventoryCategory.Feed:
        return apiCreateInventoryItemFeedBatch(prelude)({
          entity: entity as Omit<InventoryItemBatchFeed, 'id'>,
        });
      case InventoryCategory.Chemicals:
        return apiCreateInventoryItemChemicalBatch(prelude)({
          entity: entity as Omit<InventoryItemBatchChemical, 'id'>,
        });
    }
  };

// Update

const EDIT_CHEMICAL_ITEM_BATCH = `
    mutation EditChemicalBatch($input: EditChemicalBatchInput!){
      editChemicalBatch(input: $input) {
        id
        propertyId
        manufacturerBatchID
        quantity {
          unit
          value
        }
        created
        dateOfManufacture
        dateExpiry
        dateReceived
        status
        notes
      }
    }
  `;

const EDIT_FEED_ITEM_BATCH = `
    mutation EditFeedBatch($input: EditFeedBatchInput!){
      editFeedBatch(input: $input) {
        id
        feedID
        propertyId
        supplierName
        quantity {
          unit
          value
        }
        created
        dateReceived
        status
        pricePerUnitCents
        notes
      }
    }
  `;

interface EditChemicalItemBatchInput {
  id: string;
  propertyId: string;
  chemicalId: string;
  manufacturerBatchID: string;
  quantity: {
    unit: string;
    value: number;
  };
  dateReceived: string;
  dateOfManufacture: string;
  dateExpiry: string;
  notes: string;
  finished: boolean;
}

interface EditFeedItemBatchInput {
  id: string;
  propertyId: string;
  feedId: string;
  supplierName: string;
  quantity: {
    unit: string;
    value: number;
  };
  pricePerUnitCents: number;
  dateReceived: string;
  notes: string;
  finished: boolean;
}

const apiUpdateInventoryItemFeedBatch =
  (prelude: ApiPrelude) =>
  async ({
    entity,
  }: {
    entity: InventoryItemBatchFeed;
  }): Promise<InventoryItemBatchFeed> => {
    const input: EditFeedItemBatchInput = {
      id: entity.id,
      propertyId: entity.propertyId,
      feedId: entity.inventoryItemId,
      supplierName: entity.supplierName,
      quantity: {
        unit: entity.quantity.unit,
        value: entity.quantity.value,
      },
      pricePerUnitCents: entity.pricePerUnitCents,
      dateReceived: entity.dateReceived,
      notes: entity.notes,
      finished: entity.status === InventoryItemStatus.Finished,
    };

    const response = await prelude.graphql({
      query: EDIT_FEED_ITEM_BATCH,
      variables: {
        input,
      },
    });

    const result = {
      ...response.data.editFeedBatch,
      feedID: entity.inventoryItemId,
    };

    const inventoryItemBatch = decodeInventoryItemBatchFeed(entity.propertyId)(
      result
    );

    return inventoryItemBatch;
  };

const apiUpdateInventoryItemChemicalBatch =
  (prelude: ApiPrelude) =>
  async ({
    entity,
  }: {
    entity: InventoryItemBatchChemical;
  }): Promise<InventoryItemBatchChemical> => {
    const input: EditChemicalItemBatchInput = {
      id: entity.id,
      propertyId: entity.propertyId,
      chemicalId: entity.inventoryItemId,
      manufacturerBatchID: entity.name,
      quantity: {
        unit: entity.quantity.unit,
        value: entity.quantity.value,
      },
      dateReceived: entity.dateReceived,
      dateOfManufacture: entity.dateOfManufacture,
      dateExpiry: entity.dateExpiry,
      notes: entity.notes,
      finished: entity.status === InventoryItemStatus.Finished,
    };

    const response = await prelude.graphql({
      query: EDIT_CHEMICAL_ITEM_BATCH,
      variables: {
        input,
      },
    });

    const result = {
      ...response.data.editChemicalBatch,
      chemicalID: entity.inventoryItemId,
    };

    const inventoryItemBatch = decodeInventoryItemBatchChemical(
      entity.propertyId
    )(result);

    return inventoryItemBatch;
  };

export const update =
  (prelude: ApiPrelude) =>
  async ({
    updatedEntity,
  }: {
    updatedEntity: InventoryItemBatch;
  }): Promise<InventoryItemBatch> => {
    switch (updatedEntity.category) {
      case InventoryCategory.Feed:
        return apiUpdateInventoryItemFeedBatch(prelude)({
          entity: updatedEntity as InventoryItemBatchFeed,
        });
      case InventoryCategory.Chemicals:
        return apiUpdateInventoryItemChemicalBatch(prelude)({
          entity: updatedEntity as InventoryItemBatchChemical,
        });
    }
  };

// Remove

interface DeleteChemicalItemBatchInput {
  id: string;
  propertyId: string;
  chemicalId: string;
}

interface DeleteFeedItemBatchInput {
  id: string;
  propertyId: string;
  feedId: string;
}

const DELETE_CHEMICAL_ITEM_BATCH = `
    mutation DeleteChemicalBatch($input: DeleteChemicalBatchInput!){
      deleteChemicalBatch(input: $input) {
        id
      }
    }
  `;

const DELETE_FEED_ITEM_BATCH = `
    mutation DeleteFeedBatch($input: DeleteFeedBatchInput!){
      deleteFeedBatch(input: $input) {
        id
      }
    }
  `;

const removeFeedItemBatch =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: InventoryItemBatchFeed }) => {
    const input: DeleteFeedItemBatchInput = {
      id: entity.id,
      propertyId: entity.propertyId,
      feedId: entity.inventoryItemId,
    };

    await prelude.graphql({
      query: DELETE_FEED_ITEM_BATCH,
      variables: {
        input,
      },
    });
    return;
  };

const removeChemicalItemBatch =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: InventoryItemBatchChemical }) => {
    const input: DeleteChemicalItemBatchInput = {
      id: entity.id,
      propertyId: entity.propertyId,
      chemicalId: entity.inventoryItemId,
    };

    await prelude.graphql({
      query: DELETE_CHEMICAL_ITEM_BATCH,
      variables: {
        input,
      },
    });
    return;
  };

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: InventoryItemBatch }) => {
    switch (entity.category) {
      case InventoryCategory.Feed:
        return removeFeedItemBatch(prelude)({
          entity: entity as InventoryItemBatchFeed,
        });
      case InventoryCategory.Chemicals:
        return removeChemicalItemBatch(prelude)({
          entity: entity as InventoryItemBatchChemical,
        });
    }
  };
