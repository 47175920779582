import mobbleService from '@mobble/service';

export const useAuthenticateStateAndCode = () => {
  const authenticateMobbleApp = async (code: string, state: string) =>
    mobbleService.api.mobbleApps.authenticateStateAndCode({
      code,
      state,
    });
  return authenticateMobbleApp;
};
