import React from 'react';
import { useI18n } from '@mobble/i18n';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { useInventories } from '@mobble/store/src/hooks/inventories';
import { useInventoryItems } from '@mobble/store/src/hooks/inventoryItems';
import {
  sortActiveProducts,
  type Inventory,
} from '@mobble/models/src/model/Inventory';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { ScreenRendererProps } from '@src/screens/config/types';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { useLinking } from '@src/hooks/useLinking';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { EntitiesList } from '@src/stories/Views/Entities/EntitiesList';
import { InventoryCard } from '@src/stories/Views/Inventory/InventoryCard';
import { Box } from '@src/stories/Components/Layout/Box';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';

export const InventoriesHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.inventories',
        defaultMessage: 'Inventories',
      })}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Inventories: React.FC<ScreenRendererProps> = () => {
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const inventories = useInventories(propertyId);
  const inventoryItems = useInventoryItems();

  // TODO: can maybe move to selector
  // or return as part of useInventories
  const getActiveProducts = sortActiveProducts(
    inventoryItems.allEntitiesAvailable
  );

  const linkTo = useLinking();

  useEntitiesRefresher(
    [
      inventories,
      // inventoryItems, // can only be refresh if inventoryId is available
    ],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, inventories.prelude],
  });

  if (Prelude) {
    return Prelude;
  }

  const onClickInventory = (inventory: Inventory) => {
    linkTo(ROUTE_NAME.INVENTORY_ITEMS, { inventoryId: inventory.id });
  };

  return (
    <EntitiesList
      provider={inventories}
      renderEntity={(item) => (
        <Box spacing={1}>
          <InventoryCard
            inventory={item}
            onClick={onClickInventory}
            activeProductsCount={getActiveProducts(item.id).length}
          />
        </Box>
      )}
    />
  );
};

export default {
  name: ROUTE_NAME.INVENTORIES_LIST,
  header: InventoriesHeader,
  component: Inventories,
};
