import { QuantityOfDistance } from '@mobble/shared/src/core/Quantity';
import { type Property } from './Property';
import { type Paddock } from './Paddock';
import {
  type Polygon,
  type Point,
  type BoundingBox,
  polygonsToBoundingBox,
  polygonCenter,
  pointsToDistance,
  pointIsInPolygon,
} from './MapGeometry';

export interface PaddockGeometry {
  id: string;
  propertyId: Property['id'];
  paddockId: Paddock['id'];
  polygon: Polygon;
  color?: string;

  //
  updateSizeOnGeometryChange?: boolean;
}

export const findPaddockGeometry =
  (paddockGeometries: PaddockGeometry[]) =>
  (paddockId: Paddock['id']): PaddockGeometry | undefined =>
    paddockGeometries.find((p) => p.paddockId === paddockId);

export const findPaddockBoundingBox =
  (paddockGeometries: PaddockGeometry[]) =>
  (paddockId: Paddock['id']): undefined | BoundingBox => {
    const paddockGeometry = findPaddockGeometry(paddockGeometries)(paddockId);
    if (!paddockGeometry) {
      return undefined;
    }

    return polygonsToBoundingBox([paddockGeometry.polygon]);
  };

export const findPaddockCenterPoint =
  (paddockGeometries: PaddockGeometry[]) =>
  (paddockId: Paddock['id']): Point | undefined => {
    const paddockPolygon = paddockGeometries.find(
      (p) => p.paddockId === paddockId
    )?.polygon;
    return paddockPolygon && polygonCenter(paddockPolygon);
  };

export const distanceFrom = (
  origin: Point,
  paddockGeometry: PaddockGeometry
): QuantityOfDistance => {
  return pointsToDistance([origin, polygonCenter(paddockGeometry.polygon)]);
};

export const findPaddockGeometryByPoint =
  (paddockGeometries: PaddockGeometry[]) =>
  (point: Point): PaddockGeometry | undefined =>
    paddockGeometries.find((p) => pointIsInPolygon(point, p.polygon));
