import {
  configureStore,
  combineReducers,
  EnhancedStore,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'reduxjs-toolkit-persist';
import {
  type Persistor,
  type PersistorOptions,
  type Storage,
} from 'reduxjs-toolkit-persist/lib/types';

import { getPersistConfig } from './config/persist';
import { offlineMiddleware } from './config/offlineMiddleware';
import * as Reducers from './reducers';

export * from './hooks/root';
export * from './lib/EntitySliceFactoryPrelude';

export interface StoreConfiguration {
  storage?: Storage;
  enhancers?: PersistorOptions['enhancer'][];
}

export type State = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  actionQueue: Reducers.ActionQueue.reducer,
  auth: Reducers.Auth.reducer,
  casualties: Reducers.Casualties.reducer,
  chats: Reducers.Chats.reducer,
  customMapLayers: Reducers.CustomMapLayers.reducer,
  inventoryItemUsage: Reducers.InventoryItemUsage.reducer,
  historicEvents: Reducers.HistoricEvents.reducer,
  inventories: Reducers.Inventories.reducer,
  inventoryItems: Reducers.InventoryItems.reducer,
  inventoryItemBatches: Reducers.InventoryItemBatches.reducer,
  mapAssets: Reducers.MapAssets.reducer,
  mobActions: Reducers.MobActions.reducer,
  mobs: Reducers.Mobs.reducer,
  naturalIncreases: Reducers.NaturalIncreases.reducer,
  network: Reducers.Network.reducer,
  paddockActions: Reducers.PaddockActions.reducer,
  paddockGeometry: Reducers.PaddockGeometry.reducer,
  paddockGroupedStockingRate: Reducers.PaddockGroupedStockingRate.reducer,
  paddockGroupGeometry: Reducers.PaddockGroupGeometry.reducer,
  paddockGroups: Reducers.PaddockGroups.reducer,
  paddocks: Reducers.Paddocks.reducer,
  paddockStockingRates: Reducers.PaddockStockingRates.reducer,
  preferences: Reducers.Preferences.reducer,
  productApplications: Reducers.ProductApplications.reducer,
  properties: Reducers.Properties.reducer,
  purchases: Reducers.Purchases.reducer,
  rainGaugeReadings: Reducers.RainGaugeReadings.reducer,
  rainGauges: Reducers.RainGauges.reducer,
  sales: Reducers.Sales.reducer,
  settings: Reducers.Settings.reducer,
  taskGeometries: Reducers.TaskGeometries.reducer,
  tasks: Reducers.Tasks.reducer,
});

export const flush = (dispatch: any) => {
  return Promise.all([
    Object.values(Reducers).forEach((obj: any) => {
      if (obj.flush) {
        return dispatch(obj.flush());
      }
    }),
  ]);
};

export const makePersistedStore = (
  config: StoreConfiguration
): [EnhancedStore<State>, Persistor] => {
  const reducer = config.storage
    ? persistReducer(getPersistConfig(config.storage), rootReducer)
    : rootReducer;

  const middleware = (getDefaultMiddleware: any) => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    offlineMiddleware,
  ];

  const store = configureStore({
    reducer,
    middleware,
    enhancers: config.enhancers,
  });

  const persistor = persistStore(store);

  return [store, persistor];
};
