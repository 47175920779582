import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type Point, type Feature } from 'geojson';
import { Map, MapMouseEvent } from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { BoundingBox } from '@mobble/models/src/model/MapGeometry';
import { MapStyle } from '@mobble/models/src/model/MapStyle';
import { insertAt, removeAt } from '@mobble/shared/src/core/Array';
import { getDrawStyles } from '@src/components/MapDraw/helpers/getDrawStyles';
import { StaticMode } from '@src/components/MapDraw/helpers/drawStaticMode';
import {
  type MapProperties,
  type OnClickMapItemEvent,
  useMapProperties,
} from '@src/hooks/useMapProperties';
import { MapEvent } from './Events';
import { type MapPluginTypes } from './Plugins';
import { type MapItem } from './Items/MapItemType';

export type MapOnClick = (map: Map, ev: MapMouseEvent) => void;

export type MapOnRegionChanged = (feature: Feature<Point, any>) => void;

export type MapPluginStateDict = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type MapPluginStateListener = (dict: MapPluginStateDict) => void;

// Contexts

export interface MapPluginsContextProps {
  name?: string;
  map?: Map;
  draw?: MapboxDraw;
  types: MapPluginTypes;
  mapProperties: MapProperties;
}

export interface MapPluginsStateContextProps {
  dict: MapPluginStateDict;
}

export interface MapPluginsStateDispatchContextProps {
  updateState: (state: MapPluginStateDict) => MapPluginStateDict;
}

export interface MapPluginsPointsContextProps {
  enabled: boolean;
  activeIndex: number;
  points: Point[];
  options: PointsOptions;
}

type MapPluginsListenerCallback = (props: MapPluginsPointsContextProps) => void;

export interface MapPluginsListenersDispatchRef {
  getState: () => MapPluginsPointsContextProps;
  addListener: (f: MapPluginsListenerCallback) => void;
  removeListener: (f: MapPluginsListenerCallback) => void;
}

export interface PointsOptions {
  snap?: boolean;
  single?: boolean;
  mode?: string; // area
}

export interface MapPluginsPointsDispatchContextProps {
  insertPoint: (point: Point, index?: number) => void;
  updatePoint: (index: number, point: Point) => void;
  updatePoints: (points: Point[]) => void;
  removePoint: (index?: number) => void;
  clearPoints: () => void;
  enable: (enabled?: boolean, options?: Partial<PointsOptions>) => void;
  setOptions: (options?: Partial<PointsOptions>) => void;
  //
}

export interface MapEventsContextProps {
  onEvent?: (event: MapEvent) => void;
  hasActiveClickListeners: boolean;
  addOnClickListener: (callback: MapOnClick) => void;
  removeOnClickListener: (callback: MapOnClick) => void;
}

export interface MapPropertiesContextProps {
  properties: MapProperties;
}

export const MapPluginsContext = //
  createContext<MapPluginsContextProps>({} as never);

export const MapPluginsStateContext = //
  createContext<MapPluginsStateContextProps>({} as never);

export const MapPluginsStateDispatchContext = //
  createContext<MapPluginsStateDispatchContextProps>({} as never);

export const MapPluginsPointsContext = //
  createContext<MapPluginsPointsContextProps>({} as never);

export const MapPluginsPointsDispatchContext = //
  createContext<MapPluginsPointsDispatchContextProps>({} as never);

export const MapEventsContext = //
  createContext<MapEventsContextProps>({} as never);

export const MapPropertiesContext = //
  createContext<MapPropertiesContextProps>({} as never);

// ~
// Hooks

export const useMapPluginsContext = () =>
  //
  useContext(MapPluginsContext);

export const useMapPluginsStateContextProps = () =>
  //
  useContext(MapPluginsStateContext);

export const useMapPluginsDispatchContext = () =>
  //
  useContext(MapPluginsStateDispatchContext);

export const useMapPluginsPointsContext = () =>
  //
  useContext(MapPluginsPointsContext);

export const useMapPluginsPointsDispatchContext = () =>
  //
  useContext(MapPluginsPointsDispatchContext);

export const useMapEventsContext = () =>
  //
  useContext(MapEventsContext);

export const useMapPropertiesContext = () =>
  //
  useContext(MapPropertiesContext);

// ~
export interface MapProviderProps {
  name?: string;
  children: (props: MapProviderChildProps) => React.ReactNode;
  overrideMapProperties?: Partial<MapProperties>;
  mapItemFilterMap?: (mapItem: MapItem) => false | MapItem;
  onClickMapItem?: (ev: OnClickMapItemEvent) => void;
  // for plugins
  types: MapPluginTypes;
  pointsState?: Partial<MapPluginsPointsContextProps>;
  // for events
  onEvent?: (event: MapEvent) => void;
  // ref
  stateRef?: React.MutableRefObject<MapPluginsListenersDispatchRef>;
}

export interface MapProviderChildProps {
  mapStyle: MapStyle;
  boundingBox: BoundingBox;
  mapIsReady: boolean;
  onMapReady: (map: Map) => void;
}

export const MapProvider: React.FC<MapProviderProps> = ({
  name,
  children,
  types,
  mapItemFilterMap, // TODO: not used on web? only place in native?
  onClickMapItem,
  onEvent,
  overrideMapProperties,
  stateRef,
  ...props
}) => {
  const mapRef = useRef<Map>(null);
  const drawRef = useRef<MapboxDraw>(null);
  const [mapIsReady, setMapIsReady] = useState<boolean>(false);
  const mapProperties = useMapProperties({
    filterMap: mapItemFilterMap,
    onClickMapItem,
    override: overrideMapProperties,
  });

  const mapPropertiesContextProps: MapPropertiesContextProps = useMemo(
    () => ({
      properties: mapProperties,
    }),
    []
  );

  // plugins
  const [stateDict, setStateDict] = useState<MapPluginStateDict>({
    ts: Date.now(),
  });

  const updateStateDict = (stateDict: MapPluginStateDict) => {
    setStateDict({ ...stateDict, ts: Date.now() });
  };

  const mapPluginsContextProps: MapPluginsContextProps = useMemo(
    () => ({
      map: mapRef.current,
      draw: drawRef.current,
      name,
      mapProperties,
      types,
    }),
    [
      mapRef.current,
      drawRef.current,
      JSON.stringify(types),
      JSON.stringify(mapProperties.items),
      JSON.stringify(mapProperties.mapStyle),
      JSON.stringify(mapProperties.mapDetails),
      JSON.stringify(mapProperties.mobsFilter),
      JSON.stringify(mapProperties.customMapLayersFilter),
      mapProperties.additionalMapItems.length,
      mapProperties.propertyId,
    ]
  );

  // state
  const mapPluginsStateContextProps: MapPluginsStateContextProps = useMemo(
    () => ({
      dict: stateDict,
    }),
    [stateDict]
  );

  // state - dispatch

  const mapPluginsStateDispatchContextProps: MapPluginsStateDispatchContextProps =
    useMemo(
      () => ({
        updateState: (dict: { [key: string]: any }) => {
          const updated = { ...stateDict, ...dict };
          updateStateDict(updated);
          return updated;
        },
      }),
      []
    );

  // points storage
  const [pointsState, setPointsState] = useState<MapPluginsPointsContextProps>({
    points: [],
    activeIndex: 0,
    enabled: false,
    options: { snap: false, ...props.pointsState?.options },
    ...props.pointsState,
  });

  useEffect(() => {
    // Clear points when mode is changed
    let newPoints = pointsState.points || [];

    // Switch between line or point
    if (pointsState.options?.single !== props.pointsState?.options?.single) {
      newPoints = [];

      // incoming props differ to state
    } else if (
      props.pointsState?.points &&
      JSON.stringify(props.pointsState?.points) !==
        JSON.stringify(pointsState?.points) &&
      props.pointsState.activeIndex === undefined &&
      pointsState.activeIndex >= 0
    ) {
      newPoints = props.pointsState.points;
    }

    const newState = {
      ...pointsState,
      ...props.pointsState,
      options: { snap: false, ...props.pointsState?.options },
      points: newPoints,
    };
    setPointsState(newState);
  }, [JSON.stringify(props.pointsState)]);

  // ref
  const [mapPluginsListeners, setMapPluginsListeners] = useState<
    MapPluginsListenerCallback[]
  >([]);

  if (stateRef) {
    stateRef.current = {
      getState: () => pointsState,
      addListener: (f: MapPluginsListenerCallback) => {
        setMapPluginsListeners((listeners) => [...listeners, f]);
      },
      removeListener: (f: MapPluginsListenerCallback) => {
        setMapPluginsListeners((listeners) =>
          listeners.filter((listener) => listener !== f)
        );
      },
    };
  }

  useEffect(() => {
    mapPluginsListeners.forEach((f) => f(pointsState));
  }, [pointsState]);

  const mapPluginsPointsContextProps: MapPluginsPointsContextProps = useMemo(
    () => pointsState,
    [pointsState]
  );

  // points storage - dispatch

  const insertPoint = (point: Point, index?: number) => {
    setPointsState((ps) => {
      if (ps.options.single) {
        return {
          ...ps,
          activeIndex: 0,
          points: [point],
        };
      }

      const insertAtIndex = index ?? ps.activeIndex;
      const updatedPoints = insertAt(insertAtIndex, point, [...ps.points]);

      return {
        ...ps,
        activeIndex: insertAtIndex === 0 ? 0 : updatedPoints.length - 1,
        points: updatedPoints,
      };
    });
  };

  const updatePoint = (index: number, point: Point) => {
    setPointsState((ps) => {
      const updatedPoints = [...ps.points];
      updatedPoints[index] = point;

      return {
        ...ps,
        activeIndex: index,
        points: updatedPoints,
      };
    });
  };

  const removePoint = (index?: number) => {
    const updatedPoints = removeAt(
      index ?? pointsState.activeIndex,
      pointsState.points
    );

    setPointsState({
      ...pointsState,
      activeIndex: updatedPoints.length - 1,
      points: updatedPoints,
    });
  };

  const clearPoints = () => {
    setPointsState({
      ...pointsState,
      activeIndex: 0,
      points: [],
    });
  };

  const updatePoints = (points: Point[]) => {
    setPointsState((ps) => {
      return {
        ...ps,
        points: points,
      };
    });
  };

  const setOptions = (options?: PointsOptions) => {
    setPointsState((ps) => ({
      ...ps,
      options: { ...ps.options, ...options },
    }));
  };

  const enable = (enabled?: boolean, options?: PointsOptions) => {
    if (!mapRef.current || enabled === false) {
      clearPoints();
      setPointsState({
        ...pointsState,
        enabled: false,
        options: { ...pointsState.options, ...options },
      });
    } else {
      setPointsState({
        ...pointsState,
        enabled: enabled ?? true,
        options: { ...pointsState.options, ...options },
      });
    }
  };

  const mapPluginsPointsDispatchContextProps: MapPluginsPointsDispatchContextProps =
    useMemo(
      () => ({
        insertPoint,
        updatePoint,
        updatePoints,
        removePoint,
        clearPoints,
        enable,
        setOptions,
      }),
      [pointsState.points, pointsState.options, pointsState.enabled]
    );

  // events
  const onClickListeners = useRef<MapOnClick[]>([]);
  const hasActiveClickListeners = onClickListeners.current?.length > 0;

  const addOnClickListener = (callback: MapOnClick) => {
    onClickListeners.current.push(callback);
  };

  const removeOnClickListener = (callback: MapOnClick) => {
    onClickListeners.current = onClickListeners.current.filter(
      (listener) => listener !== callback
    );
  };

  const mapEventsContextProps: MapEventsContextProps = useMemo(
    () => ({
      onEvent,
      hasActiveClickListeners,
      addOnClickListener,
      removeOnClickListener,
    }),
    []
  );

  // children
  const rendered = useMemo(() => {
    const childrenProps = {
      boundingBox: mapProperties.boundingBox,
      mapStyle: mapProperties.mapStyle,
      mapIsReady,
      onMapReady: (map) => {
        mapRef.current = map;

        // Hide mapbox satellite layer if alt. satellite layer is selected
        if (
          mapProperties.mapStyle !== 'satellite' &&
          mapProperties.mapStyle.startsWith('satellite')
        ) {
          map.setLayoutProperty('satellite', 'visibility', 'none');
        }

        // Initialise Mapbox Draw with ref
        drawRef.current = new MapboxDraw({
          displayControlsDefault: false,
          styles: getDrawStyles(),
          modes: {
            ...MapboxDraw.modes,
            static: StaticMode,
          },
        });
        mapRef.current.addControl(drawRef.current, 'top-left');
        setMapIsReady(true);
      },
    };

    return children(childrenProps);
  }, [mapIsReady, mapProperties.mapStyle]);

  useEffect(() => {
    // toggle visibility of default satellite layer
    // this avoids flicker when showing alternate satellite layers
    if (mapIsReady && mapRef?.current?.getLayer('satellite')) {
      mapRef.current.setLayoutProperty(
        'satellite',
        'visibility',
        mapProperties.mapStyle === 'satellite' ? 'visible' : 'none'
      );
    }
  }, [mapIsReady, mapProperties.mapStyle]);

  return (
    <MapPropertiesContext.Provider value={mapPropertiesContextProps}>
      <MapPluginsContext.Provider value={mapPluginsContextProps}>
        <MapEventsContext.Provider value={mapEventsContextProps}>
          <MapPluginsStateContext.Provider value={mapPluginsStateContextProps}>
            <MapPluginsStateDispatchContext.Provider
              value={mapPluginsStateDispatchContextProps}
            >
              <MapPluginsPointsDispatchContext.Provider
                value={mapPluginsPointsDispatchContextProps}
              >
                <MapPluginsPointsContext.Provider
                  value={mapPluginsPointsContextProps}
                >
                  {rendered}
                </MapPluginsPointsContext.Provider>
              </MapPluginsPointsDispatchContext.Provider>
            </MapPluginsStateDispatchContext.Provider>
          </MapPluginsStateContext.Provider>
        </MapEventsContext.Provider>
      </MapPluginsContext.Provider>
    </MapPropertiesContext.Provider>
  );
};
