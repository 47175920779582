import { Color } from '@mobble/colors/src';
import { Box } from '@src/stories/Components/Layout/Box';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Text } from '@src/stories/Components/UI/Text';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import {
  billingRegionToPhoneNumber,
  contactEmail,
  toContactPhoneURL,
} from '@src/screens/Settings/hooks/useBilling';
import { useAuth } from '@mobble/store/src/hooks';
import { Icon } from '@src/stories/Components/UI/Icon';
import styles from './dashboardTrialEnded.scss';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { useI18n } from '@mobble/i18n/src';

export const DashboardTrialEnded: React.FC = () => {
  const { formatMessage } = useI18n();

  const me = useAuth();
  const billingRegion = me?.user.billingRegion;
  const phoneNumber = billingRegionToPhoneNumber(billingRegion);

  return (
    <Box className={styles.trialEndedContainer}>
      <Text
        className={styles.trialEndedTitle}
        i18n={formatMessage({
          defaultMessage: 'Your trial has ended',
          description: 'dashboard.trialEnded.title',
        })}
      />
      <Spacer y={3} />
      <Text
        className={styles.trialEndedText}
        i18n={formatMessage({
          defaultMessage:
            'Thank you for trialing Mobble! We hope we were able to help you manage your farm better. To keep using Mobble, you can subscribe via the Billing Page.',
          description: 'dashboard.trialEnded.text',
        })}
      />
      <Spacer y={2} />
      <Text
        className={styles.trialEndedText}
        i18n={formatMessage({
          defaultMessage:
            "Still have questions? Give us a call or send us an email to discuss what's right for you and your farm.",
          description: 'dashboard.trialEnded.contactUs',
        })}
      />
      <Spacer y={2} />
      <Clickable fullWidth href={`mailto:${contactEmail}`}>
        <Text className={styles.trialEndedText}>{contactEmail}</Text>
      </Clickable>
      <Spacer y={2} />
      <Clickable fullWidth href={toContactPhoneURL(phoneNumber)}>
        <Text className={styles.trialEndedText}>{phoneNumber}</Text>
      </Clickable>

      <Spacer y={4} />
      <Box flex>
        <Box className={styles.billingPageButtonAlign}>
          <Clickable fullWidth href={ROUTE_NAME.SETTINGS_BILLING}>
            <HStack flex alignment="center">
              <Text
                className={styles.billingPageText}
                i18n={formatMessage({
                  defaultMessage: 'Go to Billing page',
                  description: 'dashboard.trialEnded.goToBillingPage',
                })}
              />
              <Icon name="chevron-right" size="large" color={Color.White} />
            </HStack>
          </Clickable>
        </Box>
      </Box>
    </Box>
  );
};
