import React from 'react';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { type Mob } from '@mobble/models/src/model/Mob';
import { type ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { mapDetails } from '@mobble/models/src/model/MapDetail';
import { PaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import { type PaddockGroupGeometry } from '@mobble/models/src/model/PaddockGroupGeometry';
import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import { Map } from '@src/stories/Map';
import { Box } from '@src/stories/Components/Layout/Box';
import { CircularButton } from '@src/stories/Components/UX/CircularButton';
import { MAP_ITEM_PADDOCK_GROUP, MapItem } from '../../Map/Items/MapItemType';
import {
  makeMapItemMobs,
  makeMapItemPaddockBoundary,
  makeMapItemPaddockLabel,
  mapItemsToBoundaryBox,
} from '../../Map/Items/helper';
import styles from './paddockMap.scss';

export interface PaddockMapProps {
  propertyTypes: ConfiguredPropertyType[];
  paddocks: Paddock[];
  paddockGeometries: PaddockGeometry[];
  paddockGroupGeometry?: PaddockGroupGeometry | null;
  mobs: Mob[];
  onMaximise: () => void;
}

export const PaddockMap: React.FC<PaddockMapProps> = ({
  propertyTypes,
  paddocks,
  paddockGeometries,
  paddockGroupGeometry,
  mobs,
  onMaximise,
}) => {
  const { translate } = useI18n();

  const factoryMapItemPaddockBoundary = makeMapItemPaddockBoundary({
    propertyTypes,
    paddockGeometries,
  });
  const factoryMapItemPaddockLabel = makeMapItemPaddockLabel({
    paddockGeometries,
    translate,
  });
  const factoryMapItemMobs = makeMapItemMobs({
    propertyTypes,
    paddockGeometries,
    mobs,
  });

  const items: MapItem[] = [
    //  MapItemPaddockBoundary, MapItemPaddockLabel,  MapItemMob
    ...paddocks.reduce<MapItem[]>((acc, paddock) => {
      return [
        ...acc,
        ...([
          factoryMapItemPaddockBoundary({ paddock }),
          factoryMapItemPaddockLabel({ paddock }),
          factoryMapItemMobs({ paddock }),
        ].filter(Boolean) as MapItem[]),
      ];
    }, []),
  ];

  if (paddockGroupGeometry) {
    items.push({
      type: MAP_ITEM_PADDOCK_GROUP,
      id: paddockGroupGeometry.id,
      visible: true,
      polygon: paddockGroupGeometry.polygon,
    });
  }

  const boundingBox = mapItemsToBoundaryBox(items, 100);

  if (!boundingBox) {
    return null;
  }

  const overrideMapProperties = {
    boundingBox,
    items,
    mapDetails: [...mapDetails],
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.buttonContainer}>
        <CircularButton
          icon="maximise"
          fill={Color.White}
          elevation="low"
          onClick={onMaximise}
        />
      </Box>
      <Map
        static
        types="display"
        overrideMapProperties={overrideMapProperties}
      />
    </Box>
  );
};
