import React from 'react';
import { Reorder, useDragControls, useMotionValue } from 'framer-motion';
import styles from './reorderable.scss';

export interface ReorderableProps<T = undefined> {
  items: T[];
  onReorder: (items: T[]) => void;
  renderItem: (item: T, index: number) => React.ReactNode;
  keyExtractor: (item: T, index: number) => string;
}

export function Reorderable<T>({
  items,
  onReorder,
  renderItem,
  keyExtractor,
}: ReorderableProps<T>) {
  return (
    <Reorder.Group
      axis="y"
      values={items}
      onReorder={onReorder}
      initial={false}
      className={styles.group}
    >
      {items.map((item, index) => {
        const key = keyExtractor(item, index);
        return (
          <ReorderableItem item={item} key={key} id={key}>
            {renderItem(item, index)}
          </ReorderableItem>
        );
      })}
    </Reorder.Group>
  );
}

interface ReorderableItemProps<T> {
  id: string;
  item: T;
  children?: React.ReactNode;
}

function ReorderableItem<T>({ id, item, children }: ReorderableItemProps<T>) {
  const dragControls = useDragControls();
  const y = useMotionValue(0);

  return (
    <Reorder.Item
      id={id}
      value={item}
      style={{ y }}
      dragListener={false}
      dragControls={dragControls}
      className={styles.item}
    >
      <div className={styles.inner}>
        <div
          className={styles.handle}
          onPointerDown={(event) => dragControls.start(event)}
        >
          <div>
            <span />
            <span />
            <span />
          </div>
        </div>

        <div className={styles.content}>{children}</div>
      </div>
    </Reorder.Item>
  );
}
