import { UserActionType } from './UserActions';

export enum ScenarioType {
  InventoryItemsOverview = 'inventory_items-overview',
  MapAssetsOverview = 'map_assets-overview',
  MobActionsOverview = 'map_actions-overview',
  MapOverview = 'map-overview',
  MobDetail = 'mob-detail',
  MobsOverview = 'mobs-overview',
  MobSelect = 'mob-select',
  PaddockDetail = 'paddock-detail',
  PaddocksOverview = 'paddocks-overview',
  PaddockSelect = 'paddock-select',
  PaddockActionsOverview = 'paddock_actions-overview',
  RainGaugeDetail = 'rain_gauge-detail',
  RainGaugesOverview = 'rain_gauges-overview',
  RainGaugeReadingSelect = 'rain_gauge-detail-select',
  SettingsPropertyPaddockTypes = 'settings-property-paddock_types',
  SettingsPropertyBreeds = 'settings-property-breeds',
  SettingsPropertyGenders = 'settings-property-genders',
  SettingsPropertyClasses = 'settings-property-classes',
  SettingsPropertyTags = 'settings-property-tags',
  SettingsPropertyPaddockActions = 'settings-property-paddock_actions',
  SettingsPropertyMobActions = 'settings-property-mob_actions',
  SettingsPropertyUsersOverview = 'settings-property-users-overview',
  TasksOverview = 'tasks-overview',
  InventoryItemDetail = 'inventory_item-detail',
  inventoryItemSelect = 'inventory_item-select',
}

export interface ScenarioDetails {
  hasMobs?: boolean;
  hasGeometry?: boolean;
  hasOpenGate?: boolean;
  rainGaugeReadingId?: string;
}

export const getUserActionsForScenario = (
  scenarioType: ScenarioType,
  scenarioDetails?: ScenarioDetails
): UserActionType[] => {
  switch (scenarioType) {
    case ScenarioType.InventoryItemDetail:
      return [UserActionType.InventoryItem_Batch_Create];
    case ScenarioType.InventoryItemsOverview:
      return [UserActionType.Inventories_InventoryItem_Create];
    case ScenarioType.MapAssetsOverview:
      return [UserActionType.MapAsset_Create];
    case ScenarioType.MapOverview:
      return [
        UserActionType.Map_Task_Create,
        UserActionType.Map_MapAsset_Create,
        UserActionType.Map_Paddock_Create,
        UserActionType.Map_Mob_Create,
      ];
    case ScenarioType.MobActionsOverview:
      return [UserActionType.Mobs_MobAction_Create];
    case ScenarioType.MobsOverview:
      return [UserActionType.Mobs_Create, UserActionType.MobActions_Create];
    case ScenarioType.MobSelect:
      return [
        UserActionType.Mobs_View,
        UserActionType.Mobs_Move,
        UserActionType.Mobs_Edit,
        UserActionType.Mobs_MobAction_Create,
        UserActionType.Mobs_Casualty_Create,
        UserActionType.Mobs_Sale_Create,
      ];
    case ScenarioType.MobDetail:
      return [
        UserActionType.Mobs_Move,
        UserActionType.MobActions_Create,
        UserActionType.Mobs_Casualty_Create,
        UserActionType.Mobs_Sale_Create,
      ];
    case ScenarioType.PaddockActionsOverview:
      return [UserActionType.PaddockAction_Create];
    case ScenarioType.PaddocksOverview:
      return [
        UserActionType.Paddocks_Create,
        UserActionType.Mobs_Create,
        UserActionType.PaddockAction_Create,
      ];
    case ScenarioType.PaddockSelect:
      return [
        scenarioDetails?.hasMobs
          ? [UserActionType.Paddocks_Mobs_View, UserActionType.Mobs_Move]
          : [],
        UserActionType.Paddocks_View,
        UserActionType.Paddocks_PaddockAction_Create,
        UserActionType.Paddocks_Task_Create,
        scenarioDetails?.hasGeometry ? [UserActionType.Paddocks_Map_View] : [],
        scenarioDetails?.hasOpenGate
          ? [UserActionType.Paddocks_OpenGates_Edit]
          : [UserActionType.Paddocks_OpenGates_Create],
      ].flat();

    case ScenarioType.inventoryItemSelect:
      return [
        UserActionType.InventoryItem_Info,
        UserActionType.InventoryItem_Batch_Create,
        UserActionType.InventoryItem_Edit,
      ];

    case ScenarioType.PaddockDetail:
      return [
        UserActionType.Paddocks_PaddockAction_Create,
        UserActionType.Paddocks_Task_Create,
        UserActionType.Paddocks_Mob_Create,
      ];
    case ScenarioType.RainGaugesOverview:
      return [UserActionType.RainGauges_Create];
    case ScenarioType.RainGaugeDetail:
      return [UserActionType.RainGauges_RainGaugeReading_Create];
    case ScenarioType.RainGaugeReadingSelect:
      return [UserActionType.RainGauges_RainGaugeReading_Edit];
    case ScenarioType.SettingsPropertyPaddockTypes:
      return [UserActionType.Settings_Property_PaddockType_Create];
    case ScenarioType.SettingsPropertyBreeds:
      return [UserActionType.Settings_Property_Breed_Create];
    case ScenarioType.SettingsPropertyGenders:
      return [UserActionType.Settings_Property_Gender_Create];
    case ScenarioType.SettingsPropertyClasses:
      return [UserActionType.Settings_Property_Class_Create];
    case ScenarioType.SettingsPropertyPaddockActions:
      return [UserActionType.Settings_Property_PaddockAction_Create];
    case ScenarioType.SettingsPropertyMobActions:
      return [UserActionType.Settings_Property_MobAction_Create];
    case ScenarioType.SettingsPropertyUsersOverview:
      return [UserActionType.SettingsUserInvite];
    case ScenarioType.TasksOverview:
      return [UserActionType.Tasks_Create];
    default:
      return [];
  }
};
