import {
  type Property,
  type PropertyOrganisation,
  type ConfiguredPropertyType,
  ConfiguredPropertyTypeGroup,
} from '@mobble/models/src/model/Property';
import { User, UserRole } from '@mobble/models/src/model/User';
import { colorToHex } from '@mobble/colors';
import { type ApiPrelude } from '../types';

const decodeProperties = (data: any[]): Property[] =>
  data.map(decodeProperty).filter(Boolean) as Property[];

export const decodePaddockType = (data: any): ConfiguredPropertyType => ({
  group: ConfiguredPropertyTypeGroup.paddockType,
  id: data?.id ?? '',
  type: data?.title ?? '',
  label: data?.title ?? '',
  color: data?.color ? colorToHex(data?.color) : '',
});

const decodePaddockTypes = (data: any[]): ConfiguredPropertyType[] =>
  data.map(decodePaddockType);

export const decodePaddockActionType = (data: any): ConfiguredPropertyType => ({
  group: ConfiguredPropertyTypeGroup.paddockAction,
  id: data?.id ?? '',
  type: data?.title ?? '',
  label: data?.title ?? '',
});

const decodePaddockActionTypes = (data: any[]): ConfiguredPropertyType[] =>
  data.map(decodePaddockActionType);

export const decodeMobActionType = (data: any): ConfiguredPropertyType => ({
  group: ConfiguredPropertyTypeGroup.mobAction,
  id: data?.id ?? '',
  type: data?.title ?? '',
  label: data?.title ?? '',
});

const decodeMobActionTypes = (data: any[]): ConfiguredPropertyType[] =>
  data.map(decodeMobActionType);

export const decodeLivestockType = (data: any): ConfiguredPropertyType => ({
  group: ConfiguredPropertyTypeGroup.livestockType,
  id: data?.id ?? '',
  type: data?.animalType ?? '',
  label: data?.animalType ?? '',
});

export const decodeLivestockTypes = (data: any[]): ConfiguredPropertyType[] =>
  data.map(decodeLivestockType);

export const decodeBreed =
  (livestockTypeId: string) =>
  (data: any): ConfiguredPropertyType => ({
    group: ConfiguredPropertyTypeGroup.breed,
    parentId: livestockTypeId,
    id: data?.id ?? '',
    type: data?.title ?? '',
    label: data?.title ?? '',
  });

const decodeBreeds = (data: any[]): ConfiguredPropertyType[] =>
  data.reduce(
    (acc, livestockType) => [
      ...acc,
      ...livestockType.breeds.map(decodeBreed(livestockType.id)),
    ],
    []
  );

export const decodeGender =
  (livestockTypeId: string) =>
  (data: any): ConfiguredPropertyType => ({
    group: ConfiguredPropertyTypeGroup.gender,
    parentId: livestockTypeId,
    id: data?.id ?? '',
    type: data?.title ?? '',
    label: data?.title ?? '',
  });

const decodeGenders = (data: any[]): ConfiguredPropertyType[] =>
  data.reduce(
    (acc, livestockType) => [
      ...acc,
      ...livestockType.genders.map(decodeGender(livestockType.id)),
    ],
    []
  );

export const decodeTag =
  (livestockTypeId: string) =>
  (data: any): ConfiguredPropertyType => ({
    group: ConfiguredPropertyTypeGroup.tag,
    parentId: livestockTypeId,
    id: data?.id ?? '',
    type: data?.title ?? '',
    label: data?.title ?? '',
    color: data?.color ?? '',
  });

const decodeTags = (data: any[]): ConfiguredPropertyType[] =>
  data.reduce(
    (acc, livestockType) => [
      ...acc,
      ...livestockType.tags.map(decodeTag(livestockType.id)),
    ],
    []
  );

export const decodeClass =
  (livestockTypeId: string) =>
  (data: any): ConfiguredPropertyType => ({
    group: ConfiguredPropertyTypeGroup.class,
    parentId: livestockTypeId,
    id: data?.id ?? '',
    type: data?.title ?? '',
    label: data?.title ?? '',
  });

const decodeClasses = (data: any[]): ConfiguredPropertyType[] =>
  data.reduce(
    (acc, livestockType) => [
      ...acc,
      ...livestockType.classes.map(decodeClass(livestockType.id)),
    ],
    []
  );

const decodeUsers = (data: any[]): User[] =>
  data.map((a) => ({
    id: a?.user?.id ?? '',
    name: a?.user?.displayName ?? '',
    email: a?.user?.email ?? '',
    role: a?.role,
  }));

const decodeProperty = (data: any): Property => {
  if (!data?.id) {
    throw new Error('Property ID is required');
  }
  return {
    id: data.id,
    name: data?.name ?? 'Unknown',
    pic: data?.PIC,
    organisationId: data?.organisationId ?? '',
    organisation: undefined,
    currentUserRole: data?.currentUserRole ?? 'free',
    users: decodeUsers(data?.userRoles ?? []),
    types: [
      ...decodePaddockTypes(data?.paddockTypes ?? []),
      ...decodeLivestockTypes(data?.livestockTypes ?? []),
      ...decodeBreeds(data?.livestockTypes ?? []),
      ...decodeGenders(data?.livestockTypes ?? []),
      ...decodeTags(data?.livestockTypes ?? []),
      ...decodeClasses(data?.livestockTypes ?? []),
      ...decodePaddockActionTypes(data?.paddockActionTypes ?? []),
      ...decodeMobActionTypes(data?.mobActionTypes ?? []),
    ],
  };
};

const GET_PROPERTIES = `
  query GetProperties {
    properties {
      nodes {
        id
        name
        PIC
        organisationId

        currentUserRole

        paddockTypes {
          id
          title
          color
        }

        paddockActionTypes {
          id
          title
        }

        mobActionTypes {
          id
          title
        }

        livestockTypes {
          id
          animalType
          breeds {
            id
            title
          }
          genders {
            id
            title
          }
          tags {
            id
            title
            color
          }
          classes {
            id
            title
          }
        }

        userRoles {
          role
          user {
            id
            displayName
            email
          }
        }
      }
    }
  }
`;

export const get = (prelude: ApiPrelude) => async () => {
  const response = await prelude.graphql({ query: GET_PROPERTIES });
  return decodeProperties(response?.data?.properties?.nodes ?? []);
};

const GET_PROPERTY = `
  query GetProperty($id: ID!) {
    property(id: $id) {
      id
      name
      PIC

      paddockTypes {
        id
        title
        color
      }

      paddockActionTypes {
        id
        title
      }

      mobActionTypes {
        id
        title
      }

      livestockTypes {
        id
        animalType
        breeds {
          id
          title
        }
        genders {
          id
          title
        }
        tags {
          id
          title
          color
        }
        classes {
          id
          title
        }
      }

      userRoles {
        role
        user {
          id
          displayName
          email
        }
      }
    }
  }
`;

export const find = (prelude: ApiPrelude) => async (id: string) => {
  const response = await prelude.graphql({
    query: GET_PROPERTY,
    variables: { id },
  });
  return decodeProperty(response?.data?.property);
};

const ADD_PROPERTY = `
  mutation AddProperty($input: AddPropertyInput!) {
    addProperty(input: $input) {
      property {
        id
        name
        PIC
        organisationId

        currentUserRole

        paddockTypes {
          id
          title
          color
        }

        paddockActionTypes {
          id
          title
        }

        mobActionTypes {
          id
          title
        }

        livestockTypes {
          id
          animalType
          breeds {
            id
            title
          }
          genders {
            id
            title
          }
          tags {
            id
            title
            color
          }
          classes {
            id
            title
          }
        }

        userRoles {
          role
          user {
            id
            displayName
            email
          }
        }
      }
    }
  }
`;

interface AddPropertyInput {
  name: string;
  pic?: string | null;
}

export const create =
  (prelude: ApiPrelude) =>
  async (input: AddPropertyInput): Promise<Property> => {
    const response = await prelude.graphql({
      query: ADD_PROPERTY,
      variables: { input },
    });
    return decodeProperty(response?.data?.addProperty?.property);
  };

interface EditPropertyInput {
  id: string;
  name?: string | null;
  pic?: string | null;
  latitude?: number | null;
  longitude?: number | null;
}

const EDIT_PROPERTY = `
  mutation EditProperty($input: EditPropertyInput!) {
    editProperty(input: $input) {
      property {
        id
        name
        PIC
       }
    }
  }
`;

export const update =
  (prelude: ApiPrelude) =>
  async (input: EditPropertyInput): Promise<Partial<Property>> => {
    const response = await prelude.graphql({
      query: EDIT_PROPERTY,
      variables: {
        input,
      },
    });

    const data = response?.data?.editProperty?.property;

    const property: Partial<Property> = {
      id: data?.id ?? '',
      name: data?.name ?? '',
      pic: data?.PIC ?? '',
      types: [],
      users: [],
    };

    return property;
  };

const DELETE_PROPERTY = `
  mutation DeleteProperty($input: DeletePropertyInput!) {
    deleteProperty(input: $input) {
      property {
        id
      }
    }
  }
`;

export const remove =
  (prelude: ApiPrelude) =>
  async (input: Property): Promise<void> => {
    await prelude.graphql({ query: DELETE_PROPERTY, variables: { input } });
    return;
  };

export const GET_PROPERTY_ORGANISATION = `
  query GetPropertyOrganisation($id: ID!) {
    propertyOrganisation(id: $id) {
      id
      remainingUsers
      remainingProperties
      maxUsers
      maxProperties
    }
  }
`;

export interface getPropertyOrganisationInput {
  id: string;
}

export const getPropertyOrganisation =
  (prelude: ApiPrelude) =>
  async (
    input: getPropertyOrganisationInput
  ): Promise<PropertyOrganisation> => {
    const result = await prelude.graphql({
      query: GET_PROPERTY_ORGANISATION,
      variables: input,
    });

    return result.data.propertyOrganisation;
  };

export interface UserOfProperty {
  email: string;
  propertyId: string;
  organisationId: string;
  roles: UserRole[];
  expires?: string;
}

export interface ChangeUserInput {
  organisationId: string;
  users: UserOfProperty[];
}

export interface DeleteUserInput {
  organisationId: string;
  users: {
    email: string;
    propertyId: string;
    organisationId: string;
  }[];
}

const GET_ORGANISATION_USER_ROLES = `
  query GetOrganisationUserRoles($organisationId: ID!) {
    organisationUserRoles(organisationId: $organisationId) {
      users {
        userId
        email
        propertyId
        roles
        expires
      }
    }
  }
`;

const ADD_ORGANISATION_USER_ROLES = `
  mutation AddOrganisationUserRoles($input: OrganisationUsersInput!) {
    addOrganisationUserRoles(input: $input) {
      __typename
      users {
        userId
        email
        propertyId
        organisationId
        roles
        expires
      }
    }
  }
`;

const EDIT_ORGANISATION_USER_ROLES = `
  mutation EditOrganisationUserRoles($input: OrganisationUsersInput!) {
    editOrganisationUserRoles(input: $input) {
      __typename
      users {
        userId
        email
        propertyId
        organisationId
        roles
        expires
      }
    }
  }
`;

const DELETE_ORGANISATION_USER_ROLES = `
  mutation DeleteOrganisationUserRoles($input: OrganisationUsersInput!) {
    deleteOrganisationUserRoles(input: $input) {
      __typename
      users {
        userId
        email
        propertyId
        organisationId
        roles
        expires
      }
    }
  }
`;

export const getUsers =
  (prelude: ApiPrelude) =>
  async (organisationId: string): Promise<User[]> => {
    const response = await prelude.graphql({
      query: GET_ORGANISATION_USER_ROLES,
      variables: {
        organisationId,
      },
    });

    return response.data.organisationUserRoles.users.map((user: any) => ({
      id: user.userId,
      email: user.email,
      name: user.name || user.email,
      propertyId: user.propertyId,
      organisationId: organisationId,
      role: user.roles[0],
      expires: user.expires,
    }));
  };

export const addUser =
  (prelude: ApiPrelude) =>
  async (input: ChangeUserInput): Promise<User[]> => {
    const response = await prelude.graphql({
      query: ADD_ORGANISATION_USER_ROLES,
      variables: {
        input,
      },
    });

    return response.data.addOrganisationUserRoles.users.map((user: any) => ({
      id: user.userId,
      email: user.email,
      propertyId: user.propertyId,
      organisationId: user.organisationId,
      role: user.roles[0],
    }));
  };

export const editUser =
  (prelude: ApiPrelude) =>
  async (input: ChangeUserInput): Promise<User> => {
    const response = await prelude.graphql({
      query: EDIT_ORGANISATION_USER_ROLES,
      variables: {
        input,
      },
    });

    return response.data.organisationUserRoles.users.map((user: any) => ({
      id: user.userId,
      email: user.email,
      propertyId: user.propertyId,
      organisationId: user.organisationId,
      role: user.roles[0],
      expires: user.expires,
    }));
  };

export const deleteUser =
  (prelude: ApiPrelude) =>
  async (input: DeleteUserInput): Promise<UserOfProperty[]> => {
    const response = await prelude.graphql({
      query: DELETE_ORGANISATION_USER_ROLES,
      variables: {
        input,
      },
    });
    return response.data.deleteOrganisationUserRoles.users.map((user: any) => ({
      id: user.userId,
      email: user.email,
      propertyId: user.propertyId,
      organisationId: user.organisationId,
      role: [],
    }));
  };
